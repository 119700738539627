/* eslint-disable no-useless-escape */
export const US_ZIP_REGEX = new RegExp(
  /^(([0-9][0-9][0-9][0-9][0-9])|([0-9][0-9][0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]))$/
)
export const UPPERCASE_REGEX = new RegExp(/.*[A-Z]/)
export const NUMBER_REGEX = new RegExp(/.*\d/)
export const LENGTH_REGEX = new RegExp(/.{8,}$/)

// see: https://stackoverflow.com/questions/76285652/valid-with-the-regexp-u-flag-but-not-with-the-v-flag
export const SPECIAL_CHARS_REGEX = new RegExp(
  /.*[\-’\/`~!#*$@_%+=.,^&\(\)\{\}\[\]\|;:”<>?\\]/
)

// export const LEADING_SPACE = new RegExp(/^\S/) // no leading space
// export const TRAILING_SPACE = new RegExp(/\S$/) // no trailing space

// combine the two regexes above into one
export const NO_LEADING_OR_TRAILING_SPACE = new RegExp(/^\S.*\S$/)

export const combineRegExp = (expressions: RegExp[]) => {
  return new RegExp(
    `^(?=${expressions.map((exp) => exp.source).join(')(?=')}).*$`
  )
}
