import { FC, PropsWithChildren } from 'react'

import { IBody } from './types'

export const Body: FC<PropsWithChildren<IBody>> = ({
  contentStrings = [],
  children,
}) => {
  return (
    <div className="flex flex-col mb-6 gap-4 text-body-medium text-grey-40">
      {contentStrings.map((content, i) => (
        <p key={`${content}-${i}`}>{content}</p>
      ))}
      {children}
    </div>
  )
}
