/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-const */
// TODO: Investigate and remove all of above comments!!!
import { Detector } from '@abcam-web/shared/ui/content'
import cn from 'classnames'
import { useEffect, useReducer, useRef } from 'react'
import type { ReducerAction, ReducerState } from './aem-image-types'
import { AEMImageProps, AEMRenditions } from './aem-image-types'
import { imageResolver } from './constants'

/**
 * Convert strings that include px's
 */
function pxConverter(
  rendition: AEMRenditions,
  onlyNumber = false
): string | number {
  return onlyNumber
    ? parseInt(rendition.replace('px', ''))
    : rendition.replace('px', 'w')
}

/**
 * Calculate values to be used in generating srcSet
 */
function calculateUrl(
  renditionValue: number,
  forceRendition?: AEMRenditions | false
): [any, AEMRenditions] {
  if (forceRendition) {
    return [imageResolver[forceRendition], forceRendition]
  }

  // Its iteration will be ordered, so highest pixel value first
  for (const key in AEMRenditions) {
    if (pxConverter(AEMRenditions.xxxl, true) <= renditionValue) {
      return [imageResolver[AEMRenditions.xxxl], AEMRenditions.xxxl]
    }

    if (pxConverter(AEMRenditions.xxl, true) <= renditionValue) {
      return [imageResolver[AEMRenditions.xxl], AEMRenditions.xxl]
    }

    if (pxConverter(AEMRenditions[key], true) <= renditionValue) {
      return [imageResolver[AEMRenditions[key]], AEMRenditions[key]]
    }

    if (pxConverter(AEMRenditions.ty, true) >= renditionValue) {
      return [imageResolver[AEMRenditions.ty], AEMRenditions.ty]
    }
  }
}

function generateSource(
  src: string,
  widthValue: number,
  imageExtension: string,
  useRatio: boolean,
  forcePng: boolean,
  forceRendition?: AEMRenditions | false,
  isBackgroundImage?: boolean
) {
  let [url, rendition] = calculateUrl(widthValue, forceRendition)
  const isSmallOrBelow =
    pxConverter(rendition, true) <= pxConverter(AEMRenditions.sm, true)

  if (isSmallOrBelow) {
    url = imageResolver[rendition].filter((singleVal) => {
      if (useRatio) {
        return singleVal.ratio
      }
      return !singleVal.ratio
    })
  }

  const trueUrl = isSmallOrBelow ? url[0] : url

  const pngSourceLink = `${src}/_jcr_content/renditions/${trueUrl.png}.png`

  const pngSourceElement = (
    <source
      data-testid="aem-source"
      sizes={`${rendition}`}
      srcSet={`${pngSourceLink} ${pxConverter(rendition)}`}
      type="image/png"
    />
  )

  const jpegSourceLink = `${src}/_jcr_content/renditions/${trueUrl.jpg}.jpeg`

  return isBackgroundImage ? (
    (imageExtension === 'png' && pngSourceLink) ||
      (imageExtension === 'jpg' && jpegSourceLink)
  ) : (
    <>
      {forcePng ? pngSourceElement : null}
      <source
        data-testid="aem-source"
        sizes={`${rendition}`}
        srcSet={`${src}/_jcr_content/renditions/${
          trueUrl.webp
        }.webp ${pxConverter(rendition)}`}
        type="image/webp"
      />
      {imageExtension === 'png' && !forcePng && pngSourceElement}
      <source
        data-testid="aem-source"
        sizes={`${rendition}`}
        srcSet={`${src}/_jcr_content/renditions/${
          trueUrl.jpg
        }.jpeg ${pxConverter(rendition)}`}
        type="image/jpeg"
      />
    </>
  )
}

function reducer(state: ReducerState, action: ReducerAction) {
  const { type, payload } = action
  switch (type) {
    case 'containerWidth':
      return { ...state, containerWidth: payload }
    case 'isVisible':
      return { ...state, isVisible: true }
    case 'pictureError':
      return { ...state, pictureError: true }
    case 'default':
      return { ...state }
  }
}

/**
 * A component that uses AEM image renditions to get images from AEM efficiently
 */
export function AEMImage(props: AEMImageProps): JSX.Element {
  const {
    src,
    alt,
    useRatio = false,
    forcePng = false,
    forceRendition = false,
    fullHeight = false,
    pictureClassName,
    forceVisible,
    children,
    isBackgroundImage,
    backgroundSize,
    backgroundPosition,
    wrapperClasses,
    isFlexContainer,
    ...rest
  } = props

  const [state, dispatch] = useReducer<any>(reducer, {
    containerWidth: forceVisible?.containerWidth || null,
    isVisible: forceVisible?.isVisible || false,
    pictureError: false,
  })

  const imageExtension = src
    ?.split('.')
    [src.split('.').length - 1].toLowerCase()
  const isNotGif = imageExtension !== 'gif'

  const fragmentRef = useRef(null)

  useEffect(() => {
    if (fragmentRef.current && fragmentRef.current['offsetWidth'] !== 0) {
      // This should never set width to 0 as initial state should be hidden and should have offset after
      dispatch({
        type: 'containerWidth',
        payload: fragmentRef.current['offsetWidth'], // Get around possible type error
      })
    }
  }, [fragmentRef.current])

  const defaultImg = (
    <img alt={alt} data-testid="aem-img" loading="lazy" src={src} {...rest} />
  )

  const defaultBackgroundImg = (
    <div
      className="flex w-full h-full"
      style={{
        backgroundImage: `url(${src})`,
      }}
    >
      {children}
    </div>
  )

  return (
    <div
      ref={fragmentRef}
      className={cn(wrapperClasses, fullHeight ? 'h-full' : undefined)}
    >
      <Detector
        detectionValue={src}
        onChange={(event) => {
          if (event.visible) {
            dispatch({
              type: 'isVisible',
            })
          }
        }}
        className={cn(
          fullHeight || isBackgroundImage ? 'h-full' : undefined,
          isBackgroundImage && 'w-full'
        )}
      >
        {isBackgroundImage ? (
          <div
            className={cn('w-full h-full', isFlexContainer && 'flex')}
            data-testid="aem-backgroundimage"
            style={{
              backgroundImage: `url(${generateSource(
                src,
                state.containerWidth,
                imageExtension,
                useRatio,
                forcePng,
                forceRendition,
                isBackgroundImage
              )})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: backgroundSize,
              backgroundPosition: backgroundPosition,
            }}
          >
            {children}
          </div>
        ) : (
          state?.containerWidth &&
          state?.isVisible &&
          !state?.pictureError && (
            <picture
              data-testid="aem-picture"
              onError={() => {
                console.error(`AEMImage picture element error. Image: ${src}`)
                dispatch({
                  type: 'pictureError',
                })
              }}
              className={pictureClassName}
            >
              {isNotGif &&
                generateSource(
                  src,
                  state.containerWidth,
                  imageExtension,
                  useRatio,
                  forcePng,
                  forceRendition
                )}
              {defaultImg}
            </picture>
          )
        )}

        {state?.isVisible &&
          state?.pictureError &&
          (isBackgroundImage ? defaultBackgroundImg : defaultImg)}
      </Detector>
    </div>
  )
}
