import { useContext } from 'react'
import { ProductContext } from '../contexts/product.context'

export function useProduct() {
  const value = useContext(ProductContext)

  const product = value?.product
  const productCode = product?.productCode
  const productName = product?.name

  return {
    product,
    productCode,
    productName,
  }
}
