import type { ReactNode } from 'react'
import { createContext, useContext } from 'react'
import type { Environment } from './environment.type'

export const EnvironmentContext = createContext<Environment | undefined>(
  undefined
)

export const EnvironmentProvider = ({
  children,
  environment,
  ...otherProps
}: {
  children: ReactNode
  environment: Environment
}): React.ReactElement => {
  return (
    <EnvironmentContext.Provider value={environment} {...otherProps}>
      {children}
    </EnvironmentContext.Provider>
  )
}

export function useEnvironment(): Environment {
  const value = useContext(EnvironmentContext)
  if (value === undefined) throw new Error('no value provided')
  return value
}