import type { SearchPageProps } from './search-types'
import type { ParsedUrlQuery } from 'querystring'
import type { CurrentView, SelectedProductViewState } from './search-types'

export const getQueryParamsFromQueryString = (queryString: string) => {
  return queryString
    .split('&')
    .reduce<
      Pick<
        SearchPageProps,
        'filters' | 'keywords' | 'productSorting' | 'resourceSorting'
      >
    >(
      (acc, cv) => {
        const [key, val] = cv.split('=')
        if (key.includes('facets')) {
          const type = key.split('.')[1]
          return {
            ...acc,
            filters: [
              ...acc.filters,
              ...decodeURIComponent(val) // take the value
                .split('|') // make into array (in case multiple values)
                .map((label) => ({ type, label })), // return filter
            ],
          }
        }
        if (key === 'productSorting') {
          return { ...acc, productSorting: val }
        }
        if (key === 'resourceSorting') {
          return { ...acc, resourceSorting: val }
        }
        if (key === 'keywords') {
          return { ...acc, keywords: val }
        }
        return acc
      },
      {
        filters: [],
        keywords: '',
        productSorting: 'relevance',
        resourceSorting: 'relevance',
      }
    )
}

export const getFiltersFromQueryString = (queryString: string) =>
  getQueryParamsFromQueryString(queryString).filters

export function getSelectedProductFromQueryParams(
  obj: ParsedUrlQuery
): SelectedProductViewState | null {
  try {
    const view =
      (
        [
          'publications',
          'quickview',
          'reviews',
          'highlights',
          'hazards',
        ] as CurrentView[]
      ).find((v) => v === obj['view']) || 'quickview'
    const productcode = Array.isArray(obj['productcode'])
      ? null
      : obj['productcode'] || null
    if (!productcode) {
      return null
    }
    const result: SelectedProductViewState = {
      productcode,
      view,
    }
    return result
  } catch (e) {
    return null
  }
}
