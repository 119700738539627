import {
  MultipleOrgDropdown,
  SignInController,
} from '@abcam-web/ecommerce/components'
import { useOrderContext } from '@abcam-web/ecommerce/utilities'
import {
  getBasketQueryKey,
  useAddLinesInBasketMutation,
  useAssetDefinitionQuery,
  useBasketQuery,
} from '@abcam-web/shared/data-access/ecommerce-api-hooks'
import {
  AssetDefinitionModel,
  AssetModel,
  PurchaseType,
  PurchaseTypeEnum,
} from '@abcam-web/shared/data-access/ecommerce-schema'
import {
  ContactUsParameters,
  EventName,
  getContactUsContext,
  getSelectAddToBasketParameters,
  getSelectProductQuantityParameters,
  getSelectProductSizeParameters,
  getSelectPurchasingOrganisationParameters,
  GTMPageType,
  LocationID,
  PageSubtype,
  SelectAddToBasketEvent,
  SelectOrganisationEvent,
  SelectProductQuantityEvent,
  SelectProductSizeEvent,
  useGtm,
} from '@abcam-web/shared/data-access/tracking'
import { toast } from '@abcam-web/shared/ecommerce/components'
import {
  AssetStatusCode,
  useBasketId,
  useMarketInfo,
  useOrganisation,
  usePrevious,
  useShoppingExperience,
} from '@abcam-web/shared/ecommerce/utilities'
import { useCountry } from '@abcam-web/shared/utilities/country'
import { useToggle } from '@lego/hooks/toggle'
import { Modal } from '@lego/ui/modal'
import { MultipleOrgLocation } from 'libs/ecommerce/components/src/lib/multiple-organisations-dropdown/multiple-org-dropdown.type'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'

import { AddProductConfirmation } from '../add-product-confirmation/add-product-confirmation'
import { AssetInfo } from '../asset-info/asset-info'
import { ErrorHandler } from '../error-handler/error-handler'
import { ProductRestriction } from '../product-restriction/product-restriction'

const inputTypes = { TEXT_FIELD: 'TextField', SELECT: 'Select' }

type InputType = (typeof inputTypes)[keyof typeof inputTypes]

interface InputDataRef {
  value: number
  inputType?: InputType
}

export interface BuyBoxProps {
  assetDefinitionNumber: string
}

const BuyBox: FC<BuyBoxProps> = ({ assetDefinitionNumber }) => {
  const [showModal, toggleModal] = useToggle(false)
  const [assetQuantity, setAssetQuantity] = useState(1)
  const [assetDefinition, setAssetDefinition] = useState<AssetDefinitionModel>()
  const [selectedAsset, setSelectedAsset] = useState<
    AssetModel & { quantity: number }
  >()
  const [assetRestricted, setAssetRestricted] = useState<{
    header: string
    message: string
    description: string
    recoveryLink?: string
    recoveryHint?: string
  }>()

  const inputDataRef = useRef<InputDataRef>({
    value: 1,
    inputType: inputTypes.SELECT,
  })

  const { country, countryName, countryIsLoaded } = useCountry()

  const router = useRouter()
  const [storageBasketId, setStorageBasketId, deleteStorageBasketId] =
    useBasketId()
  const gtm = useGtm()
  const { cache, setContext } = useOrderContext()

  const {
    data: marketInfo,
    isLoading: loadingMarketInfo,
    error: marketInfoError,
    refetch: refetchMarketInfo,
  } = useMarketInfo()

  const purchaseType = marketInfo?.purchaseType as PurchaseType
  const isDistributorFlow = purchaseType === PurchaseTypeEnum.Distributor
  const isMixedMarket = purchaseType === PurchaseTypeEnum.Mixed

  const prevAssetDefinitionNumber = usePrevious(assetDefinitionNumber)
  const reloadBuyBox = prevAssetDefinitionNumber !== assetDefinitionNumber

  const {
    ui,
    user,
    checkout: navigateToCheckoutOrSignIn,
  } = useShoppingExperience()

  const isLogged = !!user

  const { formatMessage } = useIntl()

  useEffect(() => {
    setAssetQuantity(1)
  }, [country, assetDefinitionNumber, assetDefinition?.assetDefinitionNumber])
  useEffect(() => {
    if (reloadBuyBox) {
      setAssetDefinition(undefined)
      setAssetRestricted(undefined)
    }
  }, [reloadBuyBox])

  const {
    currentOrganisationId,
    setCurrentOrganisationId,
    organisations,
    enabled: multipleOrganisationsEnabled,
    loading: loadingOrganisations,
  } = useOrganisation()

  const {
    data,
    isInitialLoading: assetLoading,
    isSuccess: isAssetSuccess,
    refetch: refetchAssetDefinition,
    error: assetDefinitionError,
  } = useAssetDefinitionQuery({
    country,
    assetDefinitionNumber,
    quantity: assetQuantity,
    organisationRegistryId: cache.organisationRegistryId || undefined,
  })

  useEffect(() => {
    if (isAssetSuccess && data) {
      const defaultAsset = data?.sizes?.find((asset) => {
        return asset?.assetNumber?.startsWith(`${assetDefinitionNumber}-`)
      }) as AssetModel | undefined

      const initialSelectedAsset = (data?.sizes?.find((asset) => {
        return asset?.assetNumber === selectedAsset?.assetNumber
      }) || defaultAsset) as AssetModel | undefined

      if (initialSelectedAsset) {
        setSelectedAsset({
          ...initialSelectedAsset,
          quantity: assetQuantity,
          assetDefinitionNumber: assetDefinition?.assetDefinitionNumber,
          lineDescription:
            initialSelectedAsset.lineDescription ||
            assetDefinition?.assetDefinitionName,
        })
      }
      const discontinuedWithQuantityZero = data?.sizes?.filter((size) => {
        const isDiscontinued = !!size?.status?.find(
          (item) => item.code === AssetStatusCode.DISCONTINUED
        )
        return isDiscontinued && size.availableQuantity === 0
      })
      const isAssetRestricted = data?.sizes?.[0]?.status?.find(
        (s) => s.code === AssetStatusCode.NOT_AVAILABLE_IN_COUNTRY
      )
      if (isAssetRestricted) {
        setAssetRestricted({
          header: formatMessage({
            id: 'buyBox.assetQuery.onSuccess.assetRestricted.header',
          }),
          message: formatMessage({
            id: 'buyBox.assetQuery.onSuccess.assetRestricted.message',
          }),
          description: formatMessage(
            { id: 'buyBox.assetQuery.onSuccess.assetRestricted.description' },
            { country: countryName }
          ),
        })
      } else if (discontinuedWithQuantityZero?.length === data?.sizes.length) {
        setAssetRestricted({
          header: formatMessage({
            id: 'buyBox.assetQuery.onSuccess.discontinued.header',
          }),
          message: formatMessage({
            id: 'buyBox.assetQuery.onSuccess.discontinued.message',
          }),
          description: formatMessage({
            id: 'buyBox.assetQuery.onSuccess.discontinued.description',
          }),
        })
      } else {
        const assetWithNoDiscontinuedAndNotAvailableSizes = {
          ...data,
          sizes: data?.sizes.filter((size) => {
            const isDiscontinued = !!size?.status?.find(
              (item) => item.code === AssetStatusCode.DISCONTINUED
            )
            return !(isDiscontinued && size.availableQuantity === 0)
          }),
        }
        setAssetDefinition(assetWithNoDiscontinuedAndNotAvailableSizes)
        setAssetRestricted(undefined)
      }
    }
  }, [
    assetDefinition?.assetDefinitionName,
    assetDefinition?.assetDefinitionNumber,
    assetDefinitionNumber,
    isAssetSuccess,
    assetQuantity,
    countryName,
    data,
    formatMessage,
    selectedAsset?.assetNumber,
  ])
  const onInputValueChange = useCallback((value: number) => {
    inputDataRef.current.value = value
  }, [])

  const onInputTypeChange = useCallback((value: string) => {
    const isKnownType = Object.values(inputTypes).includes(value)

    if (isKnownType) {
      inputDataRef.current.inputType = value
    } else {
      inputDataRef.current.inputType = undefined
    }
  }, [])

  const basketQueryKey = getBasketQueryKey({
    basketId: storageBasketId,
    country,
    organisationRegistryId: cache.organisationRegistryId || undefined,
    isLogged,
  })

  const { data: basket } = useBasketQuery({
    queryKey: basketQueryKey,
    basketId: storageBasketId,
    country,
    organisationRegistryId: cache.organisationRegistryId || undefined,
  })

  const quantityChangeHandler = useCallback(
    (selectedQuantity: number) => {
      setAssetQuantity(selectedQuantity)
      onInputValueChange(selectedQuantity)
      if (selectedAsset) {
        const { quantity, ...asset } = selectedAsset
        gtm.track<SelectProductQuantityEvent>(
          getSelectProductQuantityParameters({
            location_id: LocationID.PAGE,
            page_type: GTMPageType.PDP,
            page_subtype: PageSubtype.OVERVIEW,
            page_path: router.asPath,
            ecommerceParameters: {
              assets: [asset],
              basketId: storageBasketId || '',
              itemsInCart: basket?.items?.length || -1,
              quantity: selectedQuantity,
              marketType: marketInfo?.marketType,
            },
            country: countryName,
            purchasingOrganisations: cache.organisationName || '',
          })
        )
      }
    },
    [
      onInputValueChange,
      selectedAsset,
      gtm,
      router.asPath,
      storageBasketId,
      basket?.items?.length,
      marketInfo?.marketType,
      countryName,
      cache.organisationName,
    ]
  )

  const addLinesInBasket = useAddLinesInBasketMutation(basketQueryKey, {
    onSuccess(data) {
      if (selectedAsset) {
        gtm.track<SelectAddToBasketEvent>(
          getSelectAddToBasketParameters({
            location_id: LocationID.PAGE,
            page_type: GTMPageType.PDP,
            page_subtype: PageSubtype.OVERVIEW,
            page_path: router.asPath,
            ecommerceParameters: {
              assets: selectedAsset ? [selectedAsset] : [],
              basketId: storageBasketId || '',
              itemsInCart: data?.basket?.items?.length,
              quantity: selectedAsset.quantity,
              marketType: marketInfo?.marketType,
            },
            country: countryName,
            purchasingOrganisations: cache.organisationName || '',
          })
        )
      }

      if (data?.basket?.id && data.basket.id !== storageBasketId) {
        setStorageBasketId(data.basket.id)
      }
      toggleModal()
    },
    onError: (errorInfo) => {
      const isLocked = errorInfo?.response?.status === 423
      const errorMessage = isLocked
        ? 'buyBox.addLinesInBasketWith423.toastError'
        : 'buyBox.addLinesInBasket.toastError'

      if (isLocked) {
        deleteStorageBasketId()
      }

      toast.error({
        content: formatMessage({ id: errorMessage }),
        errorInfo,
        options: {
          autoClose: 3000,
        },
      })
    },
  })

  const addToBasketCallback = useCallback(async () => {
    let isRefetchError = false
    const { value, inputType } = inputDataRef.current
    const isTextField = inputType === inputTypes.TEXT_FIELD

    if (isTextField && value !== assetQuantity) {
      setAssetQuantity(value)
      const { isError } = await refetchAssetDefinition()
      isRefetchError = isError
    }

    if (selectedAsset && !isRefetchError) {
      addLinesInBasket.mutate({
        basketId: storageBasketId as string,
        country: country as string,
        lines: [
          {
            assetId: selectedAsset.publicAssetCode,
            quantity: isTextField ? value : assetQuantity,
          },
        ],
        organisationRegistryId: cache.organisationRegistryId || undefined,
      })
    }
  }, [
    assetQuantity,
    selectedAsset,
    refetchAssetDefinition,
    addLinesInBasket,
    storageBasketId,
    country,
    cache.organisationRegistryId,
  ])

  const trackContactUsClick = (item_cta: string) => {
    const trackingParameters: ContactUsParameters = {
      click_id: 'contact us',
      page_type: GTMPageType.PDP,
      page_subtype: 'Overview',
      page_path: '/contact-us#emailUs',
      item_cta,
      outbound: 'FALSE',
      location_id: LocationID.PAGE,
      country: countryName,
      event: EventName.LINK_CLICK,
    }

    const contactUsContext = getContactUsContext(trackingParameters)

    gtm.track(contactUsContext)
  }

  const navigateToContactDistributor = () => {
    router.push(`/inquiry/${storageBasketId}`)
  }

  const navigateToBasket = () => {
    router.push(`/shopping-basket/${storageBasketId}`)
  }

  const navigateToContactUs = () => {
    trackContactUsClick('Need technical support?')
    router.push(`/contact-us#emailUs`)
  }

  const trackSizeChange = useCallback(
    (asset: AssetModel) => {
      gtm.track<SelectProductSizeEvent>(
        getSelectProductSizeParameters({
          location_id: LocationID.PAGE,
          page_type: GTMPageType.PDP,
          page_subtype: PageSubtype.OVERVIEW,
          page_path: router.asPath,
          ecommerceParameters: {
            assets: [asset],
            basketId: storageBasketId || '',
            itemsInCart: basket?.items?.length || -1,
            quantity: assetQuantity,
            marketType: marketInfo?.marketType,
          },
          country: countryName,
          purchasingOrganisations: cache.organisationName || '',
        })
      )
    },
    [
      assetQuantity,
      basket?.items?.length,
      cache.organisationName,
      countryName,
      gtm,
      marketInfo?.marketType,
      router.asPath,
      storageBasketId,
    ]
  )

  const onSizeChange = useCallback(
    (asset: AssetModel) => {
      setSelectedAsset({ ...asset, quantity: assetQuantity })
      trackSizeChange(asset)
    },
    [assetQuantity, trackSizeChange]
  )

  const isDataLoading = assetLoading || loadingMarketInfo

  const hasError = !isDataLoading && (marketInfoError || assetDefinitionError)

  const isLoading = !isDataLoading || reloadBuyBox

  // Enabled only if the the country from the cookie has been loaded client-side
  const shouldDisplayAssetInfo =
    countryIsLoaded && !assetRestricted && (assetDefinition || isDataLoading)

  const onReloadCallback = useCallback(() => {
    if (assetDefinitionError) {
      refetchAssetDefinition()
    }

    if (marketInfoError) {
      refetchMarketInfo()
    }
  }, [
    assetDefinitionError,
    marketInfoError,
    refetchAssetDefinition,
    refetchMarketInfo,
  ])

  if (hasError) {
    return (
      <div data-cy="buy-box" data-testid="buy-box">
        <ErrorHandler
          title={formatMessage({ id: 'buyBox.assetQuery.onError.header' })}
          description={formatMessage({
            id: 'buyBox.assetQuery.onError.description',
          })}
          buttonLabel={formatMessage({
            id: 'buyBox.assetQuery.onError.button',
          })}
          onReload={onReloadCallback}
        />
      </div>
    )
  }

  return (
    <div
      data-cy="buy-box"
      data-testid="buy-box"
      data-track="buy-box"
      className="ml-auto w-80 smd:min-w-[415px]"
      data-track-product-code={assetDefinition?.assetDefinitionNumber}
    >
      {assetRestricted && (
        <ProductRestriction
          header={assetRestricted.header}
          message={assetRestricted.message}
          description={assetRestricted.description}
          recoveryLink={assetRestricted.recoveryLink}
          recoveryHint={assetRestricted.recoveryHint}
        />
      )}
      <div className="font-semibold rounded bg-blue-5 text-grey-dark">
        {multipleOrganisationsEnabled && !loadingOrganisations && (
          <MultipleOrgDropdown
            organisations={organisations}
            title={formatMessage({ id: 'buyBox.multipleOrganisations.title' })}
            fullWidth={true}
            location={MultipleOrgLocation.BUY_BOX}
            selectedOrganisationRegistryId={currentOrganisationId}
            onChange={(organisationName, organisationRegistryId) => {
              if (organisationRegistryId) {
                setContext([
                  {
                    path: 'cache.organisationRegistryId',
                    value: organisationRegistryId,
                  },
                  { path: 'cache.organisationName', value: organisationName },
                  { path: 'order.addresses.billing', value: undefined },
                  { path: 'order.addresses.shipping', value: undefined },
                  { path: 'order.customers.billTo', value: undefined },
                  { path: 'order.customers.shipTo', value: undefined },
                  { path: 'order.shipping', value: undefined },
                  { path: 'order.billing', value: undefined },
                  { path: 'order.notes', value: undefined },
                ])
                setCurrentOrganisationId(organisationRegistryId)
                gtm.track<SelectOrganisationEvent>(
                  getSelectPurchasingOrganisationParameters({
                    location_id: LocationID.PAGE,
                    page_path: router.asPath,
                    page_type: GTMPageType.PDP,
                    page_subtype: PageSubtype.OVERVIEW,
                    ecommerceParameters: {
                      basketId: storageBasketId,
                      quantity: assetQuantity,
                      itemsInCart: basket?.items.length || -1,
                      assets: selectedAsset ? [selectedAsset] : [],
                    },
                    country,
                    purchasingOrganisations: organisationName || '',
                  })
                )
              }
            }}
          />
        )}
        {shouldDisplayAssetInfo && (
          <AssetInfo
            quantity={assetQuantity}
            loadingAsset={isDataLoading}
            loadingButton={addLinesInBasket.isLoading}
            distributorFlow={isDistributorFlow}
            onQuantityChange={quantityChangeHandler}
            onInputChange={onInputValueChange}
            onInputTypeChange={onInputTypeChange}
            onSizeChange={onSizeChange}
            assets={assetDefinition?.sizes}
            country={country}
            onAddToBasket={addToBasketCallback}
            onNavigateToTechSupport={navigateToContactUs}
            selectedAsset={selectedAsset}
          />
        )}
      </div>
      <Modal hideHeader onClose={toggleModal} width="600px" show={showModal}>
        {showModal && (
          <div className="p-6">
            <AddProductConfirmation
              onContactDistributor={navigateToContactDistributor}
              onShoppingBasket={navigateToBasket}
              onGoToCheckoutOrSignIn={navigateToCheckoutOrSignIn}
              onClose={toggleModal}
              purchaseType={purchaseType}
              isLogged={!!isLogged}
              isPunchout={ui.showPunchoutCheckoutButton}
            />
          </div>
        )}
      </Modal>
      {isMixedMarket && (
        <div className="mt-5 border rounded p-layout-1 text-body-xmedium grid gap-y-4 text-grey-20">
          <p>{formatMessage({ id: 'buyBox.distributorInfo.line1' })}</p>
          <p>{formatMessage({ id: 'buyBox.distributorInfo.line2' })}</p>
        </div>
      )}
      {!isLoading && assetDefinition && (
        <div className="mdd:hidden">
          {isDistributorFlow ? (
            <SignInController
              linkTextOverrideId="buyBox.signin.textOverride"
              classNames="px-10 py-8"
            />
          ) : (
            <div className="px-10 mt-5 text-center text-link-small">
              {formatMessage(
                { id: 'buyBox.contactus.message' },
                {
                  link: (
                    <Link
                      href="/contact-us#emailUs"
                      className="underline cursor-pointer text-blue-default"
                      onClick={() => trackContactUsClick('Contact us')}
                    >
                      {formatMessage({ id: 'buyBox.contactus.link' })}
                    </Link>
                  ),
                }
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export { BuyBox }
