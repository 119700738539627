import { DeliveryPreference } from '@abcam-web/shared/data-access/ecommerce-schema'
import { RadioInput } from '@lego/ui/radio-input'
import React, { FC, useEffect, useState } from 'react'

const deliveryPreferences = [
  {
    label: 'Multiple deliveries',
    value: { message: 'Deliver items as they become available.' },
    key: DeliveryPreference.MULTIPLE,
  },
  {
    label: 'Combined delivery',
    value: { message: 'Deliver all items on the same day' },
    key: DeliveryPreference.SINGLE,
  },
]

interface DeliveryPreferencesProps {
  className?: string
  titleClassName?: string
  onChange: (value: DeliveryPreference) => void
  deliveryPreference?: DeliveryPreference | null
}

const DeliveryPreferences: FC<DeliveryPreferencesProps> = ({
  className,
  titleClassName,
  deliveryPreference: deliveryPreferenceProp,
  onChange,
}) => {
  const [deliveryPreference, setDeliveryPreference] = useState<string>(
    deliveryPreferenceProp || deliveryPreferences[0].key
  )

  useEffect(() => {
    if (deliveryPreferenceProp) {
      setDeliveryPreference(deliveryPreferenceProp)
    }
  }, [deliveryPreferenceProp])

  return (
    <div className="grid grid-cols-2">
      <div className="col-span-1">
        <h3 className="font-semibold text-body-large text-black-0">
          Delivery preferences
        </h3>
      </div>
      <div className="col-span-1">
        {deliveryPreferences.map((item, index) => (
          <div key={index}>
            <RadioInput
              checked={item.key === deliveryPreference}
              fullWidth
              onChecked={() => {
                onChange(item.key)
                setDeliveryPreference(item.key)
              }}
              value={item.label}
              displayValue={
                <div className="">
                  <div>{item.label}</div>
                  <span className="font-normal text-grey-20 text-body-small">
                    {item.value.message}
                  </span>
                </div>
              }
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default DeliveryPreferences
