import { useOrderContext } from '@abcam-web/ecommerce/utilities'
import {
  getBasketQueryKey,
  useBasketQuery,
  useDeleteLineInBasketMutation,
} from '@abcam-web/shared/data-access/ecommerce-api-hooks'
import {
  AssetModel,
  BasketChangeResultModel,
  BasketItemModel,
} from '@abcam-web/shared/data-access/ecommerce-schema'
import {
  ClickId,
  DrawerType,
  EventName,
  getBaseWebEngagementProperties,
  getDynamicPageSubType,
  getDynamicPageType,
  getSelectAddToBasketParameters,
  LocationID,
  SelectAddToBasketEvent,
  useGtm,
} from '@abcam-web/shared/data-access/tracking'
import {
  useBasketId,
  useIsLogged,
  useMarketInfo,
  useShoppingExperience,
} from '@abcam-web/shared/ecommerce/utilities'
import { useCountry } from '@abcam-web/shared/utilities/country'
import { useToggle } from '@lego/hooks/toggle'
import { BUTTON_VARIANT } from '@lego/ui/button'
import { Dropdown } from '@lego/ui/dropdown'
import { Cart, ErrorIcon, SpinnerOffset } from '@lego/ui/icons'
import { Modal } from '@lego/ui/modal'
import { QuantityCounter } from '@lego/ui/quantity-counter'
import { ResponsiveButton } from '@lego/ui/responsive-button'
import { QuickAddToBasket } from 'libs/shared/ecommerce/components/src/lib/quick-add-to-basket'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { FC, useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'

import { FloatingBasket } from '../floating-basket'
import { OnDeleteTrackingInfo } from '../floating-basket/floating-basket'
import { ShoppingBasketDropdownProps } from './shopping-basket-dropdown.type'

const ShoppingBasketDropdownBody: FC<ShoppingBasketDropdownProps> = ({
  useRouterForNavigation = true, // if your app router has a different base path than pass this as false
}) => {
  const router = useRouter()
  const { locale, formatMessage } = useIntl()
  const { country, countryName } = useCountry()

  const marketInfo = useMarketInfo()
  const purchaseType = marketInfo?.data?.purchaseType
  const gtm = useGtm()
  const { isLogged } = useIsLogged()
  const [addLinesInBasketLoading, setAddLinesInBasketLoading] = useState(false)

  const [storageBasketId, setStorageBasketId, deleteStorageBasketId] =
    useBasketId()
  const [showFloatingBasket, toggleFloatingBasket] = useToggle(false)
  const [showQuickAdd, toggleQuickAdd] = useToggle(false)
  const [addedAssets, setAddedAssets] = useState<string[]>([])
  const [quickAddFreeText, setQuickAddFreeText] = useState<string | undefined>()

  const { cache } = useOrderContext()
  const shoppingExperience = useShoppingExperience()

  const basketQueryKey = getBasketQueryKey({
    basketId: storageBasketId,
    country,
    organisationRegistryId: cache.organisationRegistryId || undefined,
    isLogged,
  })

  const getShoppingBasket = useBasketQuery(
    {
      queryKey: basketQueryKey,
      basketId: storageBasketId,
      country,
      organisationRegistryId: cache.organisationRegistryId || undefined,
    },
    {
      disabled: !storageBasketId,
      onSuccess: (data) => {
        if (data.id && storageBasketId !== data.id) {
          setStorageBasketId(data.id)
        }
      },
      onError: (err) => {
        if (err?.response?.status === 404 || err?.response?.status === 423) {
          deleteStorageBasketId()
        }
      },
    }
  )

  const deleteLineInBasket = useDeleteLineInBasketMutation(basketQueryKey)

  const onAddLineSuccessCallback = useCallback(
    (data: BasketChangeResultModel) => {
      if (data?.basket?.id && data.basket.id !== storageBasketId) {
        setStorageBasketId(data.basket.id)
      }

      const addedItems = addedAssets
        .map((assetId) =>
          (data?.basket?.items || []).find(
            (item: BasketItemModel) =>
              item.assetNumber.toLowerCase() === assetId.toLowerCase() ||
              item.assetDefinitionNumber.toLowerCase() ===
                assetId.toLowerCase() ||
              item.publicAssetCode.toLowerCase() === assetId.toLowerCase()
          )
        )
        // addedItems is of type basketItem which includes types AssetModel hence this type assumption
        .filter(Boolean) as AssetModel[]
      if (addedItems !== undefined && addedItems.length > 0) {
        gtm.track<SelectAddToBasketEvent>(
          getSelectAddToBasketParameters({
            location_id: LocationID.FOOTER,
            page_type: getDynamicPageType(router.asPath),
            page_subtype: getDynamicPageSubType(router.asPath),
            page_path: router.asPath,
            ecommerceParameters: {
              assets: addedItems,
              basketId: storageBasketId,
              itemsInCart: data?.basket?.items?.length,
              quantity: 1,
              marketType: marketInfo.data?.marketType,
              freeText: quickAddFreeText,
            },
            country: countryName,
            purchasingOrganisations: cache.organisationName || '',
          })
        )
      }
    },
    [
      addedAssets,
      cache.organisationName,
      gtm,
      locale,
      marketInfo.data?.marketType,
      quickAddFreeText,
      router.asPath,
      setStorageBasketId,
      storageBasketId,
    ]
  )

  const loading =
    getShoppingBasket.isInitialLoading ||
    addLinesInBasketLoading ||
    deleteLineInBasket.isLoading ||
    marketInfo.isLoading

  const navigateToContactDistributor = () => {
    toggleFloatingBasket()
    shoppingExperience.goTo(`/inquiry/${storageBasketId}`, {
      useBrowser: !useRouterForNavigation,
    })
  }

  const itemsLength = getShoppingBasket?.data?.items?.length

  const trackProceedToCheckoutOrSignIn = () => {
    gtm.track({
      event: EventName.LINK_CLICK,
      click_id: isLogged
        ? ClickId.PROCEED_TO_CHECKOUT
        : ClickId.SIGN_IN_TO_CHECKOUT,
      page_path: router.asPath,
      item_cta: isLogged ? 'Proceed to checkout' : 'Sign in to checkout',
      outbound: false,
      location_id: LocationID.DRAWER,
      destination_path: isLogged
        ? `/shipping-details/${storageBasketId}`
        : `/sign-in?redirect=/shipping-details/${storageBasketId}`,
      button_language: locale,
    })
  }

  const navigateToCheckout = () => {
    trackProceedToCheckoutOrSignIn()
    shoppingExperience.checkout({ useBrowser: !useRouterForNavigation })
  }

  const trackViewBasket = () => {
    gtm.track({
      event: EventName.LINK_CLICK,
      click_id: ClickId.VIEW_BASKET,
      page_path: router.asPath,
      outbound: false,
      item_cta: 'Go to basket',
      location_id: LocationID.DRAWER,
      destination_path: `/shopping-basket/${storageBasketId}`,
      button_language: locale,
    })
  }

  const navigateToBasket = () => {
    toggleFloatingBasket()
    trackViewBasket()
    shoppingExperience.goTo(`/shopping-basket/${storageBasketId}`, {
      useBrowser: !useRouterForNavigation,
    })
  }

  const trackQuickAdd = () => {
    gtm.track({
      ...getBaseWebEngagementProperties(),
      click_id: ClickId.QUICK_ADD,
      location_id: LocationID.DRAWER,
      page_path: router.asPath,
      drawer_type: DrawerType.FLOATING_BASKET,
      main_country: countryName,
    })
  }

  const trackRemoveFromCart = (trackingInfo: OnDeleteTrackingInfo) => {
    gtm.track({
      ...getBaseWebEngagementProperties(),
      click_id: ClickId.REMOVE_FROM_CART,
      location_id: LocationID.DRAWER,
      page_path: router.asPath,
      drawer_type: DrawerType.FLOATING_BASKET,
      item_id: trackingInfo.assetDefinitionNumber,
      price: trackingInfo.price,
      quantity: trackingInfo.quantity,
      size: `${trackingInfo.size?.unit} ${trackingInfo.size?.value}`,
    })
  }

  const trackQuickAddClose = useCallback(() => {
    gtm.track({
      ...getBaseWebEngagementProperties(),
      click_id: ClickId.CLOSE_QUICK_ADD,
      location_id: LocationID.DRAWER,
      page_path: router.asPath,
      drawer_type: DrawerType.FLOATING_BASKET,
      main_country: countryName,
    })
  }, [countryName, gtm, router.asPath])

  const onQuickAddCallback = useCallback(
    (assetNumbers: string[], assetDefinitions: string[], freeText: string) => {
      setAddedAssets([...assetNumbers, ...assetDefinitions])
      setQuickAddFreeText(freeText)
    },
    []
  )

  const onQuickAddModalClose = useCallback(() => {
    toggleQuickAdd()
    trackQuickAddClose()
  }, [toggleQuickAdd, trackQuickAddClose])

  const quickAddQueryVariables = useMemo(
    () => ({
      basketId: storageBasketId,
      country,
      organisationRegistryId: cache.organisationRegistryId || undefined,
    }),
    [cache.organisationRegistryId, country, storageBasketId]
  )

  const isDropdownOpen = showFloatingBasket && !getShoppingBasket.error

  const basket = getShoppingBasket?.data

  return (
    <>
      <Dropdown
        data-testid="shopping-basket-dropdown"
        parentElement={
          <ResponsiveButton
            dark={true}
            size="small"
            variant={BUTTON_VARIANT.tertiaryOutline}
            icon={<Cart />}
            iconPosition={'left'}
            disableResponsive
          >
            {!!getShoppingBasket.error && <ErrorIcon />}
            {loading && <SpinnerOffset className="animate-spin" />}
            {!loading && !getShoppingBasket.error && !!itemsLength && (
              <QuantityCounter count={itemsLength} />
            )}
          </ResponsiveButton>
        }
        displayPosition="left"
        isOpen={isDropdownOpen}
        onVisibilityChange={toggleFloatingBasket}
      >
        <FloatingBasket
          purchaseType={purchaseType}
          onContactDistributor={navigateToContactDistributor}
          onBasketLinkClick={navigateToBasket}
          onQuickAddClick={() => {
            toggleFloatingBasket()
            trackQuickAdd()
            toggleQuickAdd()
          }}
          disableInteraction={loading}
          onCheckoutLinkClick={navigateToCheckout}
          shoppingBasket={basket}
          onDeleteItem={(lineNumber, trackingInfo) => {
            deleteLineInBasket.mutate({
              basketId: storageBasketId as string,
              lineNumber: lineNumber,
              country: country as string,
              organisationRegistryId: cache.organisationRegistryId || undefined,
            })
            trackRemoveFromCart(trackingInfo)
          }}
          checkingOutExternal={shoppingExperience.loading}
        />
      </Dropdown>
      <Modal
        header={formatMessage({ id: 'floatingBasket.quickAdd' })}
        onClose={toggleQuickAdd}
        overlayOnClickRequired={false}
        padded={true}
        show={showQuickAdd}
        width="560px"
      >
        {showQuickAdd && (
          <QuickAddToBasket
            addButtonClickCB={onQuickAddCallback}
            onClose={onQuickAddModalClose}
            countryName={countryName}
            queryKey={basketQueryKey}
            queryVariables={quickAddQueryVariables}
            addLinesLoadingChangeCB={setAddLinesInBasketLoading}
            onSuccess={onAddLineSuccessCallback}
          />
        )}
      </Modal>
    </>
  )
}

ShoppingBasketDropdownBody.displayName = 'ShoppingBasketDropdownBody'

const ShoppingBasketDropdown = dynamic(
  Promise.resolve(ShoppingBasketDropdownBody),
  { ssr: false }
)

export { ShoppingBasketDropdown }
