import { useSearch } from '@abcam-web/search/utilities'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import { isSearchPath } from './is-search-path'

const DynamicSearchOverlay = dynamic(() => import('./search-overlay'))

export const Search = () => {
  const { send, isActive } = useSearch()
  const [renderSearchOverlay, setRenderSearchOverlay] = useState(false)
  const { pathname, events } = useRouter()
  const [currentRoute, setCurrentRoute] = useState<string>('/')

  // close overlay when navigation is complete
  useEffect(() => {
    const handleRouteChange = (nextRoute: string) => {
      if (nextRoute !== currentRoute) {
        setCurrentRoute(nextRoute)
      }
      send('DEACTIVATE')
    }
    events.on('routeChangeComplete', handleRouteChange)
    return () => {
      events.off('routeChangeComplete', handleRouteChange)
    }
  }, [events, send, currentRoute])

  // Search bar should default to empty
  // when not on routes in the search journey
  useEffect(() => {
    !isSearchPath(pathname) && send('CLEAR')
  }, [pathname, send])

  useEffect(() => {
    if (isActive) {
      setRenderSearchOverlay(true)
    }
  }, [isActive])

  return renderSearchOverlay ? <DynamicSearchOverlay /> : null
}
