import { Select, SelectOption } from '@lego/ui/select'
import React, { FC } from 'react'

import styles from './basket-item-list.module.css'

interface SizeSelectorProps {
  sizeOnChange: (key: string) => void
  options: SelectOption[]
  disableInteractions: boolean
  className?: string
}

const SizeSelector: FC<SizeSelectorProps> = ({
  sizeOnChange,
  options: defaultOptions,
  className,
  disableInteractions,
}) => {
  return (
    <div data-cy="size-selector" className={`${styles.cell} ${className}`}>
      {defaultOptions?.length > 1 ? (
        <Select
          options={defaultOptions}
          onChange={({ key }) => {
            // quantity cast to 1 as changing the size is changing the asset
            if (key) {
              sizeOnChange(key as string)
            }
          }}
          disabled={disableInteractions}
        />
      ) : (
        <p className="text-center text-body-medium text-black-0">
          {defaultOptions?.[0].displayValue}
        </p>
      )}
    </div>
  )
}

export { SizeSelector }
