import { useGtm } from '@abcam-web/shared/data-access/tracking'
import type { LinkButtonAssetModel } from '@abcam-web/shared/ui/content'
import { LinkButton } from '@abcam-web/shared/ui/content'
import { useRouter } from 'next/router'
import { useRef } from 'react'
import { gtmTracking } from './utils/gtmTracking'

type ButtonLink = LinkButtonAssetModel & {
  onClick: (event: string) => void // Meant to be used with a useState with string type
  language: string
}

export const MegaNavButton = (aemButton: ButtonLink) => {
  const gtm = useGtm()

  const ref = useRef<HTMLAnchorElement>(null)

  const { asPath } = useRouter()
  return (
    <LinkButton
      onKeyDown={(e) => e.key === 'Enter' && e.stopPropagation()}
      linkButton={aemButton}
      linkProps={{
        className: 'mt-4 leading-none',
        ref: ref,
        onClick: () => {
          aemButton.onClick('')
          gtmTracking(
            gtm,
            asPath,
            aemButton.language,
            aemButton.buttonLabel,
            ref.current
          )
        },
      }}
      buttonProps={{
        dark: true,
        variant: 'secondary',
        size: 'small',
      }}
    />
  )
}
