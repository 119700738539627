import { FilterableListProps } from '@lego/ui/filterable-list'
import { SimpleSelectProps } from '@lego/ui/form-elements'
import { ReactElement } from 'react'

export enum CountrySelectorVariant {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
}

export enum CountrySelectorSize {
  small = 'small',
  medium = 'medium',
}

export enum CountrySelectorPosition {
  left = 'left',
  right = 'right',
}

export type CountrySelectorDropdownProps = {
  defaultSelectedCountry: string
  shouldDisplayCountryName?: boolean
  variant?: CountrySelectorVariant
  displayPosition?: keyof typeof CountrySelectorPosition
  className?: string
  size?: CountrySelectorSize
  persistSelection?: boolean
  countries?: string[]
  excludedCountries?: string[]
  isMainSiteSwitcher?: boolean
} & (
  | {
      withLanguageSelector: true
      onChange?: (_: { country: string; language: string | undefined }) => void
    }
  | {
      withLanguageSelector?: false
      onChange?: (_: { country: string }) => void
    }
)

export type CountryLanguageSelectorProps<T = boolean | undefined> = {
  shouldDisplayCountryName?: boolean
  variant?: CountrySelectorVariant
  persistSelection?: boolean
  countries?: string[]
  excludedCountries?: string[]
  isMainSiteSwitcher?: boolean
  confirmOnSelect?: boolean
  selectedCountry: string | undefined
  onChangeSelectedCountryCallback: (val: string | undefined) => void
  onChangeIsOpenCallback?: (val: boolean) => void
  countrySelectorOverrides?: Partial<FilterableListProps>
  languageSelectorOverrides?: Partial<SimpleSelectProps>
  withLanguageSelector?: T
  ApplyLanguageButton?: (_: {
    [key: string]: any
    onClickHandler: () => void
  }) => ReactElement
} & (T extends true
  ? {
      onChange?: (_: { country: string; language: string | undefined }) => void
    }
  : {
      onChange?: (_: { country: string }) => void
    })

export type CountrySelectorOption = {
  key: string
  value: string
  displayValue: JSX.Element
  shortDisplayValue?: JSX.Element
  search: string
  [other: string]: string | number | JSX.Element | undefined
}
