/* eslint-disable @nx/enforce-module-boundaries */
import { KeyValueStorage } from '../keyValueStorage/keyValueStorage'
import {
  USER_COUNTRY_SELECTION_KEY,
  USER_LOCALE_SELECTION_KEY,
} from './constants'

export class CountryStorage {
  protected static USER_COUNTRY_SELECTION = USER_COUNTRY_SELECTION_KEY
  protected static USER_LOCALE_SELECTION = USER_LOCALE_SELECTION_KEY

  static setSelectedCountry(countryCode: string) {
    // Need to delete the cookie first, otherwise doesn't update the expires/maxage values
    KeyValueStorage.deleteValue(this.USER_COUNTRY_SELECTION)

    KeyValueStorage.setValue(this.USER_COUNTRY_SELECTION, countryCode, {
      expires: new Date(Date.now() + 31536000), // chrome sometimes ignores maxAge
      maxAge: 31536000, // 1 year
      sameSite: 'lax',
    })
  }

  static getSelectedCountry() {
    return KeyValueStorage.getValue(this.USER_COUNTRY_SELECTION)
  }

  static setSelectedLocale(locale: string) {
    // Need to delete the cookie first, otherwise doesn't update the expires/maxage values
    KeyValueStorage.deleteValue(this.USER_LOCALE_SELECTION)

    KeyValueStorage.setValue(this.USER_LOCALE_SELECTION, locale, {
      expires: new Date(Date.now() + 31536000), // chrome sometimes ignores maxAge
      maxAge: 31536000, // 1 year
      sameSite: 'lax',
    })
  }

  static getSelectedLocale() {
    return KeyValueStorage.getValue(this.USER_LOCALE_SELECTION)
  }
}
