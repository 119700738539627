import { ResourcePageTypes } from '../../resource-page-types'

export const ContentPaths = {
  [ResourcePageTypes.AboutUs]: 'about/index',
  [ResourcePageTypes.Careers]: 'https://careers.abcam.com',
  [ResourcePageTypes.ContactUs]: 'contact-us/index',
  [ResourcePageTypes.LearningGuide]:
    'technical-resources/guides/[slug]/[chapterSlug]',
  [ResourcePageTypes.Legal]: 'legal/[slug]',
  [ResourcePageTypes.MarkerSelectionGuide]:
    'technical-resources/research-areas/marker-guides/[slug]/index',
  [ResourcePageTypes.OffersInitiatives]: 'offers-and-initiatives/[slug]',
  [ResourcePageTypes.Pathways]: 'technical-resources/pathways/[slug]',
  [ResourcePageTypes.ProductOverviews]:
    'technical-resources/product-overview/[slug]',
  [ResourcePageTypes.Protocols]: 'technical-resources/protocols/[slug]',
  [ResourcePageTypes.Support]: 'support/[slug]/index',
  [ResourcePageTypes.Troubleshooting]:
    'technical-resources/troubleshooting/[slug]',
} as const satisfies Record<ResourcePageTypes, string>

export const getContentPagesUrls = (contentType: string, slug: string) => {
  if (
    (Object.values(ResourcePageTypes) as unknown as string[]).indexOf(
      contentType
    ) === -1
  )
    return

  const contentPath = ContentPaths[contentType as ResourcePageTypes]

  if (!contentPath) return

  // Special case for external links
  if (slug.startsWith('http')) {
    return slug
  }

  // Special case for static paths (about, contact-us)
  if (!contentPath.includes('slug')) {
    return contentPath.replace('index', '')
  }

  // Special case for Learning Guides
  if (contentType === ResourcePageTypes.LearningGuide) {
    return contentPath.replace('[slug]/[chapterSlug]', slug)
  }

  // Cases when [slug] is a folder with an index file
  if (contentPath.includes('[slug]/index')) {
    return contentPath.replace('[slug]/index', slug)
  }

  // Support special case
  if (contentType === ResourcePageTypes.Support) {
    return contentPath.replace('[topic]/index', slug)
  }

  // Special case for Offers and initiatives landing page
  if (
    contentType === 'Offers and initiatives' &&
    slug === 'offers-and-initiatives'
  ) {
    return slug
  }

  return contentPath.replace('[slug]', slug)
}
