import type {
  CountryOverrides,
  Translations,
} from '@abcam-web/shared/utilities/localisation'
import en from './en.json'
import ja from './ja.json'
import zh from './zh.json'
import enOverrides from './country-overrides-en.json'
import jaOverrides from './country-overrides-ja.json'

export const translations: Translations = { en, ja, zh }
export const countryOverrides: CountryOverrides = {
  en: enOverrides,
  ja: jaOverrides,
  zh: {},
}
