import { components } from '../components'

export const viewOrder = {
  basket: {
    container: 'order-basket',
    title: 'order-basket-title',
    collapseAllLines: 'order-basket-collapse-all-lines',
    expandAllLines: 'order-basket-expand-all-lines',
    total: {
      title: 'order-basket-total-title',
      value: 'order-basket-total-value',
    },
    lines: {
      title: 'order-basket-lines-title',
      value: 'order-basket-lines-value',
    },
    taxStatement: 'order-basket-tax-statement',
    items: {
      container: 'order-basket-items',
      header: {
        item: 'order-basket-item-header',
        quantity: 'order-basket-qty-header',
        unitPrice: 'order-basket-unit-price-header',
        itemTotal: 'order-basket-item-total-header',
        requestLotNumber: 'order-basket-req-lot-header',
        trackingNumber: 'order-basket-tracking-number-header',
        actions: 'order-basket-actions-header',
      },
    },
    item: {
      priceError: 'order-basket-item-price-error',
      lineNumber: {
        title: 'order-basket-item-line-number-title',
        value: 'order-basket-item-line-number-value',
      },
      assetNumber: {
        title: 'order-basket-item-asset-number-title',
        value: 'order-basket-item-asset-number-value',
      },
      lineDescription: {
        title: 'order-basket-item-line-description-title',
        value: 'order-basket-item-line-description-value',
      },
      lineQuantity: 'order-basket-item-line-quantity',
      unitPrice: 'order-basket-item-unit-price',
      total: 'order-basket-item-total',
      lotNumber: 'order-basket-item-lot-no',
      wmsNumber: 'order-basket-item-wms-number',
      actions: {
        container: 'order-basket-item-actions',
        contractLine: 'order-basket-item-contract-line',
        expandLine: 'order-basket-item-expand-line',
      },
      detail: {
        largeQuantityLine: 'order-basket-detail-large-quantity-line',
        shipmentDetails: {
          title: 'order-basket-detail-shipment-detail-title',
          status: 'order-basket-detail-shipment-status',
          trackingNumber: 'order-basket-detail-shipment-tracking-number',
          serviceLevel: 'order-basket-detail-shipment-service-level',
          courier: 'order-basket-detail-shipment-courier',
          accountNumber: 'order-basket-detail-shipment-account-number',
          requestedShipmentDate: 'order-basket-detail-shipment-requested-date',
          promisedShipmentDate: 'order-basket-detail-shipment-promised-date',
          scheduledShipmentDate: 'order-basket-detail-shipment-scheduled-date',
          actualShipDate: 'order-basket-detail-shipment-actual-date',
          promisedArrivalDate:
            'order-basket-detail-shipment-promised-arrival-date',
          scheduledArrivalDate:
            'order-basket-detail-shipment-scheduled-arrival-date',
          actualArrivalDate: 'order-basket-detail-shipment-actual-arrival-date',
          discount: 'order-basket-detail-shipment-discount',
          discountReason: 'order-basket-detail-shipment-discount-reason',
          tax: 'order-basket-detail-shipment-tax',
        },
        otherDetails: {
          title: 'order-basket-detail-other-title',
          customerPurchaseOrderNumber: 'order-basket-detail-other-po-number',
          destinationHsCode: 'order-basket-detail-other-destination-hs-code',
          sourceHsCode: 'order-basket-detail-other-source-hs-code',
          wmsId: 'order-basket-detail-other-wms-id',
          wmsReturnId: 'order-basket-detail-other-wms-return-id',
          primaryWarehouse: 'order-basket-detail-other-primary-warehouse',
          fulfilmentStrategy: 'order-basket-detail-other-fulfilment-strategy',
          fulfillingWarehouse: 'order-basket-detail-other-fulfilling-warehouse',
          workOrderNumber: 'order-basket-detail-other-wo-number',
          transferOrderNumber: 'order-basket-detail-other-to-number',
          requestedLotNumber: 'order-basket-detail-other-req-lot-number',
          shippedLotNumber: 'order-basket-detail-other-shipped-lot-number',
          invoiceNumber: 'order-basket-detail-other-invoice-number',
          creditNoteNumber: 'order-basket-detail-other-credit-note-number',
        },
        lineNotes: {
          title: 'order-basket-detail-line-notes-title',
          value: 'order-basket-detail-line-notes-value',
        },
        focr: {
          orderNumber: 'order-basket-detail-focr-order-number',
          orderLine: 'order-basket-detail-focr-order-line',
        },
      },
    },
    tags: 'order-basket-tags',
  },
  courier: {
    trackingNumber: 'order-basket-item-tracking-number',
    trackingUrl: 'tracking-url-link',
  },
  addresses: {
    shipTo: 'order-ship-to-address',
    billTo: 'order-bill-to-address',
  },
  section: {
    customer: {
      title: 'customer-title',
      value: 'customer-value',
    },
    contact: {
      title: 'contact-title',
      value: 'contact-value',
    },
    fao: {
      title: 'fao-title',
      value: 'fao-value',
    },
    address: {
      title: 'address-title',
      value: 'address-value',
    },
  },
  customer: {
    title: 'order-customer-title',
    organisationName: 'order-customer-organisation-name',
    contact: {
      title: 'order-customer-contact-title',
      registryId: 'order-customer-contact-registry-id',
    },
    businessUnit: {
      title: 'order-customer-business-unit-title',
      value: 'order-customer-business-unit-value',
    },
    partnerType: {
      title: 'order-customer-partner-type-title',
      value: 'order-customer-partner-type-value',
    },
    notes: {
      title: 'order-customer-notes-title',
      value: 'order-customer-notes-value',
    },
  },
  header: {
    container: 'order-header-container',
    title: 'order-header-title',
    creationDate: 'order-header-creation-date',
    lastModificationDate: 'order-header-last-modification-date',
    lastModifiedBy: 'order-header-last-modified-by',
    source: 'order-header-source',
    purchaseOrderNumber: 'order-header-purchase-order-number',
    contextMenu: {
      button: 'order-header-context-menu-button',
      viewOrderLogs: {
        icon: 'order-header-context-menu-view-order-logs-icon',
        button: 'order-header-context-menu-view-order-logs-button',
      },
      changeOrderStatus: {
        icon: 'order-header-context-menu-change-order-status-icon',
        button: 'order-header-context-menu-change-order-status-button',
      },
      cancelOrder: {
        icon: 'order-header-context-menu-cancel-order-icon',
        button: 'order-header-context-menu-cancel-order-button',
      },
      issueReviewAndPayEmail: {
        icon: 'order-header-context-menu-issue-review-and-pay-email-icon',
        button: 'order-header-context-menu-issue-review-and-pay-email-button',
      },
    },
    editButton: components.actionButtons.editButton,
    submittedButton: components.actionButtons.submittedButton,
    opportunityNumber: 'order-header-opportunity-number',
  },
  incidentNumber: 'order-incident-number',
  status: {
    tag: 'order-status-tag',
  },
  notification: {
    doNotEditOrder: 'order-header-do-not-edit-order-notification',
  },
  paymentAndDiscounts: {
    container: 'order-payment-and-discounts',
    discountsApplied: 'order-payment-and-discounts-discount-applied',
    promoCode: 'order-payment-and-discounts-promo-code',
    freeShipping: 'order-payment-and-discounts-free-shipping',
    paymentMethod: 'order-payment-and-discounts-payment-method',
    purchaseOrderNo: 'order-payment-and-discounts-purchase-order-number',
    paymentTerms: 'order-payment-and-discounts-payment-terms',
    paymentTermsTooltip: 'order-payment-and-discounts-payment-terms-tool-tip',
  },
  summary: {
    container: 'order-summary-container',
    basketDiscount: 'order-summary-basket-discount',
    itemTotalDiscount: 'order-summary-item-total-discount',
    appliedDiscounts: 'order-summary-applied-discount',
    contextMenu: {
      button: 'order-summary-context-menu-button',
      viewOrderLogs: {
        button: 'order-summary-context-menu-view-order-logs-button',
        icon: 'order-summary-context-menu-view-order-logs-icon',
      },
    },
    discounts: 'order-summary-discounts',
    paymentAmount: 'order-summary-payment-amount',
    salesTax: 'order-summary-sales-tax',
    salesTaxLabel: 'order-summary-sales-tax-label',
    shippingAndHandling: {
      container: 'order-summary-shipping-and-handling',
      totalDiscount: 'order-summary-shipping-and-handling-total-discount',
      price: 'order-summary-shipping-and-handling-price',
      freeShippingDiscount:
        'order-summary-shipping-and-handling-free-shipping-discount',
    },
    subTotal: 'order-summary-sub-total',
    editButton: components.actionButtons.editButton,
    submittedButton: components.actionButtons.submittedButton,
    closeButton: 'order-summary-close-button',
  },
  goToTopButton: 'order-go-to-top-button',
  notes: {
    container: 'order-notes-container',
    title: 'order-notes-title',
    subTitle: 'order-notes-subtitle',
    noNotesText: 'order-notes-no-notes-text',
    addedBy: 'order-notes-added-by',
    message: 'order-notes-message',
    list: 'order-notes-list',
  },
  termsAndConditions: {
    container: 'order-terms-and-conditions-container',
    validFor: {
      title: 'order-terms-and-conditions-valid-for-title',
      value: 'order-terms-and-conditions-valid-for-value',
    },
    paymentTerms: {
      title: 'order-terms-and-conditions-payment-terms-title',
      value: 'order-terms-and-conditions-payment-terms-value',
    },
    additionalTerms: {
      title: 'order-terms-and-conditions-additional-terms-title',
      value: 'order-terms-and-conditions-additional-terms-value',
    },
  },
  promotionsDiscountsAndTax: {
    container: 'order-promotions-discounts-and-tax',
    heading: 'order-promotions-discounts-and-tax-heading',
    subHeading: 'order-promotions-discounts-and-tax-sub-heading',
    promoCode: 'order-promotions-discounts-and-tax-promo-code',
    freeShipping: 'order-promotions-discounts-and-tax-free-shipping',
    taxNumber: 'order-promotions-discounts-and-tax-vat-or-sales-tax-number',
    taxExempt: 'order-promotions-discounts-and-tax-vat-exempt',
    purchaseOrderNumber:
      'order-promotions-discounts-and-tax-purchase-order-number',
  },
}
