import {
  FeatureTogglesDocument,
  FeatureTogglesQuery,
  FeatureToggle,
  FeatureToggleListQuery,
  FeatureToggleListDocument,
} from '@abcam-web/shared/data-access/feature-toggle-schema'

import type { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import type { FeatureToggleService } from '@feature-toggles/interfaces/feature-toggle.interface'

export class ExternalFeatureToggle implements FeatureToggleService {
  private client!: ApolloClient<NormalizedCacheObject>

  constructor(client: ApolloClient<NormalizedCacheObject>) {
    this.client = client
  }

  public async getAllToggles(): Promise<FeatureToggle[]> {
    const { data: legacyToggleData, error: legacyToggleError } =
      await this.client.query<FeatureTogglesQuery>({
        query: FeatureTogglesDocument,
      })

    const { data: togglesData, error: togglesError } =
      await this.client.query<FeatureToggleListQuery>({
        query: FeatureToggleListDocument,
      })

    if (legacyToggleError || togglesError) {
      console.error(
        'Error fetching feature toggles! The error is: ',
        legacyToggleError?.message || togglesError?.message
      )
      return []
    }

    const togglesOutput = [
      ...(legacyToggleData?.featureToggles || []),
      ...(togglesData?.featureToggleList || []),
    ]

    return togglesOutput
  }
}
