import { usePunchout } from '@abcam-web/auth-shared/aws-cognito/hooks/usePunchout'
import { BasketItemModel } from '@abcam-web/shared/data-access/ecommerce-schema'
import {
  isDiscontinued,
  onAssetUpdate,
} from '@abcam-web/shared/ecommerce/utilities'
import { Button } from '@lego/ui/button'
import { ContentLoader } from '@lego/ui/content-loader'
import { Cart, Plus } from '@lego/ui/icons'
import { FC, ReactNode, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { BasketItem } from './basket-item'
import styles from './basket-item-list.module.css'

export interface BasketItemListProps {
  availabilityComponent?: (assetNumber: string) => ReactNode
  billingCountry?: string
  deleteOnClick: ({
    itemToDelete,
    price,
  }: {
    itemToDelete: BasketItemModel
    price: string
  }) => void
  disableHyperlink?: boolean
  disableInteractions?: boolean
  distributorFlow?: boolean
  editPrice?: boolean
  editPriceRequiresReason?: boolean
  enableActionDropdown?: boolean
  indexUpdated?: string
  isLoading?: boolean
  isQuote?: boolean
  isUpdating?: boolean
  items: BasketItemModel[]
  onAssetUpdate?: (assetUpdates: onAssetUpdate, callback?: () => void) => void
  onQuickAddClick?: () => void
  restrictedItemsCount?: number
  shippingCountry?: string
  showLQL?: boolean
  simplified?: boolean
  priceDiscrepancyItems?: BasketItemModel[]
}

const LoadingBasket = () => {
  return (
    <div>
      <ContentLoader
        height="30"
        className="mt-3"
        uniqueKey="basket-loader-1"
        isLoading
      />
      <ContentLoader
        height="30"
        className="mt-3"
        uniqueKey="basket-loader-2"
        isLoading
      />
      <ContentLoader
        height="30"
        className="mt-3"
        uniqueKey="basket-loader-3"
        isLoading
      />
    </div>
  )
}

const EmptyBasket: FC<{
  distributorFlow: boolean
  onQuickAddClick: () => void
}> = ({ distributorFlow, onQuickAddClick }) => {
  const { formatMessage } = useIntl()

  const emptyBasketMessage = distributorFlow
    ? formatMessage({
        id: 'basketItemList.emptyInquiryBasket',
      })
    : formatMessage({
        id: 'basketItemList.emptyShoppingBasket',
      })

  return (
    <section className="text-center">
      <div
        className={`${styles.emptyBasketWrapper} inline-flex flex-col items-center font-normal text-grey-15 text-body-medium gap-y-2`}
      >
        <Cart />
        <p>{emptyBasketMessage}</p>
        {onQuickAddClick && (
          <Button
            className="mt-1 ml-5"
            bodyClassName="text-blue-default"
            variant="tertiaryFilled"
            size="medium"
            onClick={onQuickAddClick}
            iconLeft={<Plus className="text-blue-default" />}
          >
            {formatMessage({
              id: 'basketItemList.quickAdd',
            })}
          </Button>
        )}
      </div>
    </section>
  )
}

const BasketItemList: FC<BasketItemListProps> = ({
  availabilityComponent,
  billingCountry,
  deleteOnClick,
  disableHyperlink = false,
  disableInteractions,
  distributorFlow = false,
  editPrice = false,
  editPriceRequiresReason = false,
  enableActionDropdown = false,
  isLoading,
  isQuote = false,
  items,
  onAssetUpdate,
  onQuickAddClick = () => null,
  restrictedItemsCount = 0,
  shippingCountry,
  showLQL = false,
  simplified = false,
  priceDiscrepancyItems,
}) => {
  const isEmptyBasket = !isLoading && !items?.length
  const isNotEmptyBasket = !isLoading && !!items?.length
  const punchoutSession = usePunchout()
  const showBasePrice = useMemo(() => {
    const isPriceTaxInclusive = items.every(
      (i) => i.prices?.tax?.includedInPrice
    )
    return punchoutSession.active && isPriceTaxInclusive
  }, [items, punchoutSession])

  return (
    <>
      {isLoading && <LoadingBasket />}
      {isEmptyBasket && (
        <EmptyBasket
          onQuickAddClick={onQuickAddClick}
          distributorFlow={distributorFlow}
        />
      )}
      {isNotEmptyBasket && (
        <>
          {items.map((item, index) => {
            const priceDiscrepancyError = priceDiscrepancyItems?.some(
              ({ assetDefinitionNumber }) =>
                assetDefinitionNumber === item.assetDefinitionNumber
            )

            return (
              <BasketItem
                availabilityComponent={availabilityComponent}
                billingCountry={billingCountry}
                deleteOnClick={deleteOnClick}
                disableHyperlink={disableHyperlink}
                disableInteractions={disableInteractions}
                distributorFlow={distributorFlow}
                editPrice={editPrice}
                enableActionDropdown={enableActionDropdown}
                hideSeparator={
                  isDiscontinued(item?.status) ||
                  (items.length - 1 === index && simplified) ||
                  items.length === 1
                }
                index={restrictedItemsCount + index}
                isQuote={isQuote}
                item={item}
                key={restrictedItemsCount + index}
                onAssetUpdate={onAssetUpdate}
                shippingCountry={shippingCountry}
                showBasePrice={showBasePrice}
                showLQL={showLQL}
                showValidatePriceEditNotification={editPriceRequiresReason}
                simplified={simplified}
                priceDiscrepancyError={priceDiscrepancyError}
              />
            )
          })}
        </>
      )}
    </>
  )
}

export default BasketItemList
