import { POSTCODE_REGEXES } from '../data'
import { BaseAddressSchema } from './base'

export const INAddressSchema = {
  ...BaseAddressSchema,
  state: {
    type: 'string',
    required: true,
    oneOf: [
      'ANDHRA PRADESH',
      'ARUNACHAL PRADESH',
      'ASSAM',
      'BIHAR',
      'CHHATTISGARH',
      'DELHI',
      'GOA',
      'GUJARAT',
      'HARYANA',
      'HIMACHAL PRADESH',
      'JHARKHAND',
      'KARNATAKA',
      'KERALA',
      'MADHYA PRADESH',
      'MAHARASHTRA',
      'MANIPUR',
      'MEGHALAYA',
      'MIZORAM',
      'NAGALAND',
      'NEW DELHI',
      'ODISHA',
      'PUNJAB',
      'RAJASTHAN',
      'SIKKIM',
      'TAMIL NADU',
      'TELANGANA',
      'TRIPURA',
      'UTTARAKHAND',
      'UTTAR PRADESH',
      'WEST BENGAL',
    ],
  },
  postcode: {
    type: 'string',
    required: true,
    // combining all regexes in logical OR
    pattern: new RegExp(
      POSTCODE_REGEXES['IN'].map((exp) => `^${exp}$`).join('|'),
      'i'
    ),
  },
} as const

export type INAddressSchemaType = typeof INAddressSchema

export default INAddressSchema
