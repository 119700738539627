import { EyeClosedIcon, EyeIcon } from '@lego/ui/icons'
import React, { FormEvent, useCallback, useMemo } from 'react'
import { FC, useState } from 'react'
import { SimpleTextInput } from '../simpleTextInput/simpleTextInput'
import type { SimpleTextInputProps } from '../simpleTextInput/simpleTextInput'
import { CheckList } from '../checkList/checkList'
import {
  localisedValidationRule,
  combineRegExp,
} from '@abcam-web/shared/utilities/password-validation'
import { useIntl } from 'react-intl'
interface PasswordInputProps
  extends Omit<SimpleTextInputProps, 'pattern' | 'type' | 'pattern' | 'ref'> {
  validationRules?: localisedValidationRule[]
  dataCy?: string
  validationSuccessMessage?: string
  enableBlurValidation?: boolean
}

const PlainTextToggle = (
  isPlainText: boolean,
  setIsPlainText: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const { formatMessage } = useIntl()

  return (
    <button
      type="button"
      onClick={() => setIsPlainText(!isPlainText)}
      aria-label="Show password"
      data-testid="show-password-button"
    >
      <span className="flex items-center flex-nowrap gap-2">
        {isPlainText ? (
          <>
            <span className="text-lg">
              <EyeClosedIcon />
            </span>
            {formatMessage({
              id: 'components.simplePassword.hidePassword',
            })}
          </>
        ) : (
          <>
            <span className="text-lg">
              <EyeIcon />
            </span>
            {formatMessage({
              id: 'components.simplePassword.showPassword',
            })}
          </>
        )}
      </span>
    </button>
  )
}

const SimplePasswordInput: FC<PasswordInputProps> = React.memo(
  ({
    onChange,
    validationRules,
    required,
    dataCy,
    validationSuccessMessage,
    enableBlurValidation = false,
    ...rest
  }) => {
    const [isPlainText, setIsPlainText] = useState(false)
    const [password, setPassword] = useState('')

    const updatePassword = useCallback(
      (e: FormEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement
        setPassword(target.value)
        onChange && onChange(e)
      },
      [onChange]
    )

    const passwordValidRegExp = useMemo(() => {
      return validationRules
        ? combineRegExp(validationRules.map((rule) => rule.pattern))
        : undefined
    }, [validationRules])

    return (
      <div className="flex flex-col gap-4">
        <SimpleTextInput
          {...rest}
          required={required}
          pattern={passwordValidRegExp?.source}
          onChange={updatePassword}
          type={isPlainText ? 'text' : 'password'}
          RhsComponent={PlainTextToggle(isPlainText, setIsPlainText)}
          enableBlurValidation={enableBlurValidation}
          dataCy={dataCy}
        />
        {validationRules && (
          <CheckList
            password={password}
            rules={validationRules}
            validationSuccessMessage={validationSuccessMessage}
          />
        )}
      </div>
    )
  }
)

SimplePasswordInput.displayName = 'SimplePasswordInput'
export { SimplePasswordInput }
export type { PasswordInputProps }
