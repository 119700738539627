import { useRef, useEffect } from 'react'
import { InView } from 'react-intersection-observer'
import type { DetectorProps } from './detector.types'

/**
 * Check whether an html element is in the browser viewport
 */
export function isInViewport(element: HTMLElement): boolean {
  const elementRect = element.getBoundingClientRect()
  return (
    elementRect.top >= 0 &&
    elementRect.left >= 0 &&
    elementRect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    elementRect.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  )
}

/**
 * Detects whether wrapped children are in the viewport
 */
export function Detector({
  children,
  detectionValue,
  onChange,
  testId,
  className,
}: DetectorProps): JSX.Element {
  const detectorRef = useRef(null)

  // Check whether detector element is already in viewport on load
  useEffect(() => {
    if (detectorRef.current) {
      if (isInViewport(detectorRef.current)) {
        onChange({ value: detectionValue, visible: true })
      }
    }
  }, [detectorRef.current])

  return (
    <InView
      onChange={(boolVal, entry) => {
        if (entry.intersectionRatio > 0) {
          onChange({ value: detectionValue, visible: true })
        } else {
          onChange({ value: detectionValue, visible: false })
        }
      }}
      className={className}
    >
      <span data-testid={testId ? testId : null} ref={detectorRef}></span>
      {children}
    </InView>
  )
}
