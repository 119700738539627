import { AEMHTMLContent } from '@abcam-web/shared/ui/content'
import { ChevronDown } from '@lego/ui/icons'
import cn from 'classnames'
import { useState } from 'react'
import {
  featureColumnType,
  productTabTitle,
  resourcesTabTitle,
  supportTabTitle,
} from '../constants'
import { MegaNavButton } from '../mega-nav-button'
import { MegaNavImage } from '../mega-nav-image'
import { MegaNavLinks } from '../mega-nav-links'
import type { MegaNavProps, NavItem } from '../mega-nav.types'
import Link from 'next/link'
import ConditionalWrap from 'conditional-wrap'

const MegaNavItem = ({
  item,
  closeMenuFunction,
  index,
}: {
  item: NavItem
  closeMenuFunction?: () => void
  index: number
}) => {
  const { title, sections } = item
  const [open, setOpen] = useState(false)

  const navLanguage = item.language
  return (
    <div className="border-b-[1px] border-stroke-darkbg-dark last:border-0">
      <div
        className="flex flex-row items-center justify-between py-6"
        onClick={() => setOpen(!open)}
        onKeyDown={() => setOpen(!open)}
        role="button"
        tabIndex={index}
      >
        <span className="font-semibold text-heading-small">{title}</span>
        {open ? (
          <ChevronDown className="transform rotate-180 [&>path]:fill-white" />
        ) : (
          <ChevronDown className="[&>path]:fill-white" />
        )}
      </div>
      {open && (
        <div
          className={cn(
            (item.originalTitle === supportTabTitle.en ||
              item.originalTitle === resourcesTabTitle.en ||
              sections.length === 5) &&
              'tyu:grid tyu:grid-cols-3 gap-4 tyu:border-t-[1px] md:border-stroke-darkbg-medium'
          )}
        >
          {sections.map(({ blocks }) => {
            return (
              <div
                className={cn(
                  item.originalTitle === productTabTitle.en &&
                    'flex flex-col sm:flex-row sm:border-t-[1px] md:border-stroke-darkbg-medium',
                  blocks &&
                    ((blocks[0]?.type === featureColumnType.en &&
                      'tyu:w-full') ||
                      (blocks[0]?.type === 'ProductFeaturedColumn' && 'gap-4'))
                )}
              >
                {blocks &&
                  blocks.map((block, index) => {
                    const {
                      type,
                      title,
                      titleUrl,
                      links,
                      blockCopy,
                      button,
                      image,
                    } = block

                    const isFeaturedColumn = type === featureColumnType.en
                    return (
                      <ConditionalWrap
                        condition={isFeaturedColumn}
                        wrap={(children) => (
                          <div className="w-full py-6 border-t-[1px] border-stroke-darkbg-medium first:border-stroke-darkbg-dark">
                            {children}
                          </div>
                        )}
                      >
                        <>
                          <div
                            className={cn(
                              type === 'ProductFeaturedColumn' &&
                                !isFeaturedColumn
                                ? 'py-6 w-full'
                                : 'tyd:border-t-[1px] border-stroke-darkbg-medium w-full py-6'
                            )}
                          >
                            {titleUrl ? (
                              <Link
                                className="mb-2 font-semibold text-heading-x-small"
                                href={titleUrl}
                              >
                                {title}
                              </Link>
                            ) : (
                              <div className="mb-2 font-semibold text-heading-x-small">
                                {title}
                              </div>
                            )}
                            {image?._path && (
                              <MegaNavImage image={image} isMobile={true} />
                            )}
                            {blockCopy && (
                              <AEMHTMLContent
                                classNames={cn(image?._path && 'w-full')}
                                render={blockCopy}
                              />
                            )}
                            <div className="flex flex-col">
                              {links && (
                                <ul>
                                  <MegaNavLinks
                                    isMobile={true}
                                    links={links}
                                    onClick={() =>
                                      closeMenuFunction && closeMenuFunction()
                                    }
                                    language={navLanguage}
                                    isFeatureColumn={isFeaturedColumn}
                                  />
                                </ul>
                              )}
                            </div>
                            {button && (
                              <MegaNavButton
                                {...button}
                                onClick={() =>
                                  closeMenuFunction && closeMenuFunction()
                                }
                                language={navLanguage}
                              />
                            )}
                          </div>

                          {!isFeaturedColumn &&
                            blocks.length === 2 &&
                            index === 1 && <div className="w-full grow"></div>}
                        </>
                      </ConditionalWrap>
                    )
                  })}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export const MegaNavMobile = ({ items, closeMenuFunction }: MegaNavProps) => {
  return (
    <div className="text-white bg-black-0">
      {items.map((item, i) => (
        <MegaNavItem
          item={item}
          closeMenuFunction={closeMenuFunction}
          index={i}
          key={`mega-nav-item-${item.title}`}
        />
      ))}
    </div>
  )
}
