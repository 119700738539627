import getConfig from 'next/config'

/**
 * Find which environment we are in and other utility functions
 */
export const EnvironmentFinder = {
  /**
   *Current environment
   */
  currentEnv: (): string | undefined => {
    return getConfig().publicRuntimeConfig.STATUS_ENVIRONMENTNAME
  },
  /**
   * Are we at prod?
   */
  isProd: (): boolean => {
    const currentEnv = EnvironmentFinder.currentEnv()
    return currentEnv === 'prod'
  },
  /**
   * Are we at preprod?
   */
  isPreProd: (): boolean => {
    const currentEnv = EnvironmentFinder.currentEnv()
    return currentEnv === 'preprod'
  },
  /**
   * Are we at staging?
   */
  isStaging: (): boolean => {
    const currentEnv = EnvironmentFinder.currentEnv()
    return currentEnv === 'staging'
  },
  /**
   * Are we at test?
   */
  isTest: (): boolean => {
    const currentEnv = EnvironmentFinder.currentEnv()
    return currentEnv === 'test'
  },
  /**
   * Are we at dev?
   */
  isDev: (): boolean => {
    const currentEnv = EnvironmentFinder.currentEnv()
    return currentEnv === 'dev'
  },
  /**
   * Are we at preprod or prod?
   */
  isProdLike: (): boolean => {
    return EnvironmentFinder.isProd() || EnvironmentFinder.isPreProd()
  },
  /**
   * Are we on a non prop and non preprod environment?
   */
  isDevLike: (): boolean => {
    return ! EnvironmentFinder.isProdLike()
  },
}
