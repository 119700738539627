import { addDays, addMonths, parse } from 'date-fns'
import { dateFormats } from './dateFormats'
import { defaultCountry } from '@abcam-web/shared/config'

export const getDateFormat = (locale: string) => {
  const [languageCode, _countryCode] = locale.split('-')
  const countryCode = _countryCode ?? defaultCountry
  const localeKey = `${languageCode.toLowerCase()}-${countryCode.toUpperCase()}`
  return dateFormats[localeKey] || 'dd/MM/yyyy'
}

export const curateDate = (
  d: string | Date | undefined | null,
  locale: string
) => {
  let result: Date | null
  try {
    const date = d
      ? !(d instanceof Date)
        ? parse(d, getDateFormat(locale), new Date())
        : d
      : null
    result = date && !isNaN(date.getTime()) ? date : null
  } catch {
    result = null
  }
  return result
}

export const today = () => new Date()

export const tomorrow = () => addDays(new Date(), 1)

export const nextWeek = () => addDays(new Date(), 7)

export const nextMonth = () => addMonths(new Date(), 1)

export const dateFormatOptions: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
}
