import { IconChecked } from '@lego/ui/icons'
import { BaseSwitch } from '../_base_switch/base_switch'
import { BaseSwitchProps } from '../_base_switch/base_switch.type'

import style from './checkbox.module.css'

type Props = Omit<BaseSwitchProps, 'styles' | 'SwitchUi'>

const SwitchComponent = () => (
  <div className={style.tickBox}>
    <IconChecked />
  </div>
)

const Checkbox = ({ size = 'small', ...props }: Props) => {
  return (
    <BaseSwitch
      styles={style}
      size={size}
      SwitchUi={<SwitchComponent />}
      {...props}
    />
  )
}

export { Checkbox }
