import { LotSpecificOrderType } from '@abcam-web/shared/data-access/ecommerce-schema'
import { FC } from 'react'
import { useIntl } from 'react-intl'

import { EditLinePropertyInputModal } from '../edit-line-property-input-modal/edit-line-property-input-modal'

interface Props {
  cancelText?: string
  subheaderText?: string
  inputLabelText?: string
  inputPlaceholderText?: string
  loading?: boolean
  isOpen: boolean
  onSave: (customerLineNumber: string) => void
  onClose: () => void
  defaultValue?: string
  requestLotModalType: LotSpecificOrderType
}

export type LotSpecificOrderTypeForRequest = Extract<
  LotSpecificOrderType,
  | LotSpecificOrderType.THIS_SPECIFIC_LOT
  | LotSpecificOrderType.NOT_THIS_SPECIFIC_LOT
>

const EditLotNumberModal: FC<Props> = ({
  loading,
  isOpen,
  onSave,
  onClose,
  defaultValue = '',
  requestLotModalType,
}) => {
  const { formatMessage } = useIntl()

  return (
    <EditLinePropertyInputModal
      testTagPrefix="lot-number"
      headerText={
        requestLotModalType === LotSpecificOrderType.THIS_SPECIFIC_LOT
          ? formatMessage({ id: 'lotRequestsModal.requestSpecificLot' })
          : requestLotModalType === LotSpecificOrderType.NOT_THIS_SPECIFIC_LOT
          ? formatMessage({ id: 'lotRequestsModal.excludeSpecificLot' })
          : ''
      }
      inputLabelText={
        requestLotModalType === LotSpecificOrderType.THIS_SPECIFIC_LOT
          ? formatMessage({ id: 'lotRequestsModal.lotNumberToRequest' })
          : requestLotModalType === LotSpecificOrderType.NOT_THIS_SPECIFIC_LOT
          ? formatMessage({ id: 'lotRequestsModal.lotNumberToBeExcluded' })
          : ''
      }
      saveText={
        requestLotModalType === LotSpecificOrderType.THIS_SPECIFIC_LOT
          ? formatMessage({ id: 'lotRequestsModal.requestLot' })
          : requestLotModalType === LotSpecificOrderType.NOT_THIS_SPECIFIC_LOT
          ? formatMessage({ id: 'lotRequestsModal.excludeThisLot' })
          : ''
      }
      cancelText={formatMessage({ id: 'lotRequestsModal.cancel' })}
      isOpen={isOpen}
      onClose={onClose}
      defaultValue={defaultValue}
      loading={loading}
      onSave={onSave}
    />
  )
}

export { EditLotNumberModal }
