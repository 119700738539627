import { gql } from '@apollo/client'
import getConfig from 'next/config'

const getDirective = () => {
  if (getConfig()?.publicRuntimeConfig?.STAGE === 'local') {
    return ''
  }

  return '@endpoint(name: "punchoutgateway")'
}

export const PUNCHOUT_DATA_QUERY = gql`
  query PunchoutData($sessionId: String, $punchoutId: String) ${getDirective()} {
    punchoutSession(sessionId: $sessionId) {
      pos
    }
    punchoutClient(punchoutId: $punchoutId) {
      punchoutId
      countryCode
      errorFeedback
      errorText
      welcomeIconUrl
      welcomeText
      arePromotionsEnabled
    }
  }
`

export const PUNCHOUT_BASKET_MUTATION = gql`
  mutation PunchoutBasket($params: PunchoutOrderInput!) ${getDirective()} {
    punchoutOrder(params: $params) {
      redirectUrl
    }
  }
`
