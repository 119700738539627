import { AmplifyConfig } from 'aws-amplify/lib/Common/types/types'
import { STAGE } from '../constants/stage-type'
import { cognitoConfig } from '@abcam-web/shared/utilities/auth'

const getAwsAmplifyConfig = (stage: STAGE) => {
  if (!cognitoConfig[stage]) {
    console.error('Stage value not found: ', stage)
    return null
  }
  return {
    Auth: {
      region: 'eu-west-1',
      userPoolId: cognitoConfig[stage].COGNITO_USER_POOL_ID,
      userPoolWebClientId: cognitoConfig[stage].COGNITO_USER_POOL_WEB_CLIENT_ID,
      mandatorySignIn: false,
      signUpVerificationMethod: 'code',
      authenticationFlowType: 'USER_PASSWORD_AUTH',
    },
  } as AmplifyConfig
}

export { getAwsAmplifyConfig }
