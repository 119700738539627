import { FieldSchema, Schema, SchemaOverride } from "@abcam-web/address-validator"

function isKey<T extends object>(x: T, k: PropertyKey): k is keyof T {
    return k in x
  }

/**
 * Applies a schema override to a the passed selected schema in place.
 * overrides are expected to be removing a field or altering a field configuration
 * No field adding is expected.
 * 
 * @param schemaOverride - The schema override to apply.
 * @param selectedSchema - The selected schema to apply the override to.
 */
export function applySchemaOverride(
    schemaOverride: SchemaOverride,
    selectedSchema?: Schema
  ) {
    Object.keys(schemaOverride).forEach((schemaFieldOverride) => {
      if (selectedSchema && isKey(selectedSchema, schemaFieldOverride)) {
        if (schemaOverride[schemaFieldOverride]) {
          const fieldOverrideSchema = {
            ...selectedSchema[schemaFieldOverride],
            ...(schemaOverride[schemaFieldOverride] || {}),
          } as FieldSchema
          selectedSchema[schemaFieldOverride] = fieldOverrideSchema
        }
        if (typeof schemaOverride[schemaFieldOverride] === 'undefined') {
          delete selectedSchema[schemaFieldOverride]
        }
      }
    })
  }