import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Date: any
  Long: any
}

export enum AdjustmentType {
  DISCOUNT_AMOUNT = 'DISCOUNT_AMOUNT',
  DISCOUNT_PERCENTAGE = 'DISCOUNT_PERCENTAGE',
}

export type Asset = {
  __typename?: 'Asset'
  assetAvailabilityStatus: AssetAvailabilityStatus
  assetName: Scalars['String']
  assetNumber: Scalars['String']
  prices?: Maybe<ItemPrices>
  primarySize: Size
  publicAssetCode?: Maybe<Scalars['String']>
  size: Size
  stock?: Maybe<Scalars['Int']>
  taxes?: Maybe<Tax>
}

export type AssetAvailabilityStatus = {
  __typename?: 'AssetAvailabilityStatus'
  assetDefinitionStatus: Scalars['String']
  assetStatus: Scalars['String']
  lineOfBusiness: Scalars['String']
  marketabilityStatus: Scalars['String']
}

export type AssetDefinition = {
  __typename?: 'AssetDefinition'
  assetDefinitionName: Scalars['String']
  assetDefinitionNumber: Scalars['String']
  assets?: Maybe<Array<Maybe<Asset>>>
  distributors?: Maybe<Array<Maybe<Distributor>>>
  restriction?: Maybe<Scalars['String']>
}

export type Basket = {
  __typename?: 'Basket'
  distributors?: Maybe<Array<Maybe<Distributor>>>
  id: Scalars['String']
  items: Array<Maybe<BasketItem>>
  largeQuantityOrder?: Maybe<Scalars['Boolean']>
  largeValueOrder?: Maybe<Scalars['Boolean']>
  lines?: Maybe<Array<Maybe<BasketGroupedItem>>>
  /**
   * Promotions applied to the basket
   *
   * @deprecated(reason: "Use orders.promotions instead")
   */
  promotions: Array<BasketPromotion>
  summary?: Maybe<BasketSummary>
}

export type BasketDiscounts = {
  __typename?: 'BasketDiscounts'
  adjustments: Array<BasketItemPriceAdjustment>
  /** @deprecated 'items' field is deprecated, please use 'adjustments' */
  items: Array<Maybe<PriceAdjustment>>
  totalPrice: Price
}

export type BasketGroupedItem = {
  __typename?: 'BasketGroupedItem'
  /** @deprecated This field will be removed in the future */
  assetDefinition?: Maybe<AssetDefinition>
  assetDefinitionNumber: Scalars['String']
  assetNumber: Scalars['String']
  customerLineNumber?: Maybe<Scalars['String']>
  customerPurchaseOrderNumber?: Maybe<Scalars['String']>
  dates?: Maybe<BasketItemDates>
  items: Array<Maybe<BasketItem>>
  largeQuantityLine: Scalars['Boolean']
  lineDescription: Scalars['String']
  lineNumber: Array<Maybe<Scalars['Int']>>
  lotSpecificOrder?: Maybe<BasketItemLotSpecificOrder>
  prices: ItemPrices
  primaryUnitOfMeasure: Scalars['String']
  primaryWarehouse?: Maybe<Warehouse>
  quantity: Scalars['Int']
  sizeMultiplier: Scalars['Int']
  sizeValue: Scalars['Float']
  unitOfMeasure: Scalars['String']
}

export type BasketItem = {
  __typename?: 'BasketItem'
  /** @deprecated This field will be removed in the future */
  assetDefinition?: Maybe<AssetDefinition>
  assetDefinitionNumber: Scalars['String']
  assetNumber: Scalars['String']
  basketItemDates?: Maybe<BasketItemDates>
  basketItemLotSpecificOrder?: Maybe<BasketItemLotSpecificOrder>
  customerLineNumber?: Maybe<Scalars['String']>
  customerPurchaseOrderNumber?: Maybe<Scalars['String']>
  dates?: Maybe<BasketItemDates>
  destinationHsCode?: Maybe<Scalars['Float']>
  displayLineNumber?: Maybe<Scalars['String']>
  freeOfChargeReplacement: Scalars['Boolean']
  fulfilLineSequenceNumber?: Maybe<Scalars['String']>
  fulfilment?: Maybe<Fulfilment>
  invoiceNumber?: Maybe<Scalars['String']>
  largeQuantityLine: Scalars['Boolean']
  lineDescription: Scalars['String']
  lineNumber: Scalars['Int']
  lineStatus?: Maybe<LineStatus>
  lotSpecificOrder?: Maybe<BasketItemLotSpecificOrder>
  notes?: Maybe<Scalars['String']>
  orderNumber?: Maybe<Scalars['String']>
  prices?: Maybe<ItemPrices>
  primaryUnitOfMeasure: Scalars['String']
  primaryWarehouse?: Maybe<Warehouse>
  /** Item level discounts that affect the item. It does not include basket level and shipping discounts. */
  promotions?: Maybe<Array<PromotionDiscount>>
  publicAssetCode: Scalars['String']
  quantity: Scalars['Int']
  sizeMultiplier?: Maybe<Scalars['Int']>
  sizeValue: Scalars['Float']
  sourceHsCode?: Maybe<Scalars['Float']>
  transferOrderNumber?: Maybe<Scalars['Float']>
  unitOfMeasure: Scalars['String']
  /** @deprecated This field was returning incorrect values. Use line > asset attributes instead */
  unitsOfMeasure?: Maybe<UnitsOfMeasure>
  workOrderNumber?: Maybe<Scalars['Float']>
}

export type BasketItemDates = {
  __typename?: 'BasketItemDates'
  promisedArrivalDate?: Maybe<Scalars['Date']>
  /** @deprecated 'promisedShipDate' field is deprecated, please use 'promisedShipmentDate' */
  promisedShipDate: Scalars['Date']
  promisedShipmentDate?: Maybe<Scalars['Date']>
  /** @deprecated 'requestedShipDate' field is deprecated, please use 'requestedShipmentDate' */
  requestedShipDate: Scalars['Date']
  requestedShipmentDate?: Maybe<Scalars['Date']>
  scheduledArrivalDate?: Maybe<Scalars['Date']>
  /** @deprecated 'scheduledShipDate' field is deprecated, please use 'scheduledShipmentDate' */
  scheduledShipDate: Scalars['Date']
  scheduledShipmentDate?: Maybe<Scalars['Date']>
}

export type BasketItemInput = {
  assetDefinitionNumber: Scalars['String']
  assetNumber: Scalars['String']
  customerLineNumber?: InputMaybe<Scalars['String']>
  customerPurchaseOrderNumber?: InputMaybe<Scalars['String']>
  dates?: InputMaybe<BasketItemInputDates>
  lineDescription?: InputMaybe<Scalars['String']>
  lineNumber: Scalars['Int']
  lotSpecificOrder?: InputMaybe<BasketItemInputLotSpecificOrder>
  prices?: InputMaybe<BasketItemInputPrices>
  primaryUnitOfMeasure?: InputMaybe<Scalars['String']>
  publicAssetCode?: InputMaybe<Scalars['String']>
  quantity: Scalars['Int']
  sizeMultiplier?: InputMaybe<Scalars['Int']>
  sizeValue?: InputMaybe<Scalars['Float']>
  unitOfMeasure?: InputMaybe<Scalars['String']>
}

export type BasketItemInputDates = {
  promisedArrivalDate?: InputMaybe<Scalars['Date']>
  promisedShipDate?: InputMaybe<Scalars['Date']>
  requestedShipDate: Scalars['Date']
  scheduledArrivalDate?: InputMaybe<Scalars['Date']>
  scheduledShipDate?: InputMaybe<Scalars['Date']>
}

export type BasketItemInputLotDetails = {
  lotNumber?: InputMaybe<Scalars['String']>
}

export type BasketItemInputLotSpecificOrder = {
  fulfilmentLotNumber?: InputMaybe<Scalars['String']>
  fulfilmentStrategy?: InputMaybe<FulfilmentStrategy>
  fulfilmentWarehouse?: InputMaybe<ManufacturingWarehouse>
  lotNumber?: InputMaybe<Scalars['String']>
  lotSpecificOrderType: LotSpecificOrderType
}

export type BasketItemInputPrices = {
  adjustment?: InputMaybe<PriceAdjustmentInput>
  base: PriceInput
}

export type BasketItemLotDetails = {
  __typename?: 'BasketItemLotDetails'
  lotNumber?: Maybe<Scalars['String']>
}

export type BasketItemLotSpecificOrder = {
  __typename?: 'BasketItemLotSpecificOrder'
  fulfilmentLotNumber?: Maybe<Scalars['String']>
  fulfilmentStrategy?: Maybe<FulfilmentStrategy>
  fulfilmentWarehouse?: Maybe<ManufacturingWarehouse>
  lotNumber?: Maybe<Scalars['String']>
  lotSpecificOrderType: LotSpecificOrderType
}

export type BasketItemPriceAdjustment = {
  __typename?: 'BasketItemPriceAdjustment'
  id?: Maybe<Scalars['String']>
  lines: Array<BasketItem>
  percentage?: Maybe<Scalars['Float']>
  price?: Maybe<Price>
  reason: PriceAdjustmentReason
  source?: Maybe<PriceAdjustmentSource>
  type: AdjustmentType
}

export type BasketPromotion = {
  __typename?: 'BasketPromotion'
  /**
   * Coupon code that customer used to apply the promotion
   *
   * @deprecated(reason: "Use PromotionDiscount defined in Order type")
   */
  code: Scalars['String']
}

export type BasketSummary = {
  __typename?: 'BasketSummary'
  /** @deprecated 'appliedDiscounts' field is deprecated, please use 'discounts' */
  appliedDiscounts?: Maybe<Price>
  discounts: BasketDiscounts
  promotionDiscounts?: Maybe<Price>
  shippingAndHandling?: Maybe<Price>
  subtotal?: Maybe<Price>
  /** @deprecated 'tax' field is deprecated, please use 'taxes' */
  tax?: Maybe<TaxInfo>
  taxes: Array<TaxSummary>
  total?: Maybe<Price>
}

export enum BusinessUnitCode {
  BUS_AEBST = 'BUS_AEBST',
  BUS_Australia = 'BUS_Australia',
  BUS_Boston = 'BUS_Boston',
  BUS_BostonFirefly = 'BUS_BostonFirefly',
  BUS_Branford = 'BUS_Branford',
  BUS_Burlingame = 'BUS_Burlingame',
  BUS_Eugene = 'BUS_Eugene',
  BUS_Hangzhou = 'BUS_Hangzhou',
  BUS_HongKong = 'BUS_HongKong',
  BUS_Japan = 'BUS_Japan',
  BUS_Netherlands = 'BUS_Netherlands',
  BUS_Shanghai = 'BUS_Shanghai',
  BUS_Singapore = 'BUS_Singapore',
  BUS_TGRAustralia = 'BUS_TGRAustralia',
  BUS_Taiwan = 'BUS_Taiwan',
  BUS_UK = 'BUS_UK',
}

export type CarrierDetails = {
  __typename?: 'CarrierDetails'
  courier?: Maybe<Scalars['String']>
  fulfilled?: Maybe<Scalars['Boolean']>
  serviceLevel?: Maybe<Scalars['String']>
  shipmentType?: Maybe<Scalars['String']>
}

export type Charges = {
  __typename?: 'Charges'
  cost: Price
  type: ShippingChargesType
}

export type ContactSearchFilter = {
  limit: Scalars['Int']
  offset: Scalars['Int']
  searchTerm: Scalars['String']
}

export type ContactSearchResults = {
  __typename?: 'ContactSearchResults'
  contacts: Array<ContactSearchResultsItem>
  meta: ContactSearchResultsMeta
}

export type ContactSearchResultsItem = {
  __typename?: 'ContactSearchResultsItem'
  contactRegistryId: Scalars['String']
  emailAddress: Scalars['String']
  fullName: Scalars['String']
  isRedListed: Scalars['Boolean']
}

export type ContactSearchResultsMeta = {
  __typename?: 'ContactSearchResultsMeta'
  limit: Scalars['Int']
  offset: Scalars['Int']
  searchedTerm: Scalars['String']
  totalNumberOfResults: Scalars['Int']
}

export enum Courier {
  BIOCARE = 'BIOCARE',
  BREAKAWAY = 'BREAKAWAY',
  CHEMI_LOGI = 'CHEMI_LOGI',
  CUSTOMER = 'CUSTOMER',
  DHL = 'DHL',
  DIMEX = 'DIMEX',
  EXPEDITORS = 'EXPEDITORS',
  FEDEX = 'FEDEX',
  FTN = 'FTN',
  GLOBE_EXPRESS = 'GLOBE_EXPRESS',
  KWE = 'KWE',
  LEXINGTON_FREIGHT = 'LEXINGTON_FREIGHT',
  NIPPON_EXPRESS = 'NIPPON_EXPRESS',
  SF_EXPRESS = 'SF_EXPRESS',
  TAMURA = 'TAMURA',
  TNT = 'TNT',
  UPS = 'UPS',
  YAMATO = 'YAMATO',
  YHX = 'YHX',
  YUN_DA_EXPRESS = 'YUN_DA_EXPRESS',
  ZEDIFY = 'ZEDIFY',
}

export enum Currency {
  AUD = 'AUD',
  CAD = 'CAD',
  CNY = 'CNY',
  EUR = 'EUR',
  GBP = 'GBP',
  HKD = 'HKD',
  JPY = 'JPY',
  NZD = 'NZD',
  SGD = 'SGD',
  USD = 'USD',
}

export type Delivery = {
  __typename?: 'Delivery'
  charges?: Maybe<Array<Maybe<Charges>>>
  deliveries?: Maybe<Array<Maybe<DeliveryResponseItem>>>
  /** @deprecated 'methods' field is deprecated, please use 'deliveries'->'deliveryType' */
  methods?: Maybe<Array<Maybe<DeliveryMethod>>>
  /** @deprecated 'ownCourierOptions' field is deprecated no data passed for this field all data is available in 'deliveries' */
  ownCourierOptions?: Maybe<Array<Maybe<DeliveryCouriers>>>
  /** @deprecated 'standardOptions' field is deprecated no data passed for this field all data is available in 'deliveries' */
  standardOptions?: Maybe<Array<Maybe<DeliveryCouriers>>>
}

export type DeliveryCouriers = {
  __typename?: 'DeliveryCouriers'
  cost: Price
  courierId: Scalars['Int']
  inStockItemsOnly?: Maybe<Scalars['Boolean']>
  name: Scalars['String']
  requiresCustomerAccountNumber?: Maybe<Scalars['Boolean']>
}

export type DeliveryMethod = {
  __typename?: 'DeliveryMethod'
  assetNumbers?: Maybe<Array<Scalars['String']>>
  type: DeliveryMethodType
}

export enum DeliveryMethodType {
  BIOCHEMICAL = 'BIOCHEMICAL',
  CELLLINE = 'CELLLINE',
  DRYICE = 'DRYICE',
  EQ = 'EQ',
  FD2 = 'FD2',
  FULLDG = 'FULLDG',
  LN2 = 'LN2',
  LQ = 'LQ',
  SERA = 'SERA',
  SIX_DOT_ONE = 'SIX_DOT_ONE',
  STANDARD = 'STANDARD',
  TOXIN = 'TOXIN',
  USDA = 'USDA',
}

export enum DeliveryPreference {
  MULTIPLE = 'MULTIPLE',
  SINGLE = 'SINGLE',
}

export type DeliveryResponseItem = {
  __typename?: 'DeliveryResponseItem'
  assetNumber?: Maybe<Scalars['String']>
  availableQtyToReserve?: Maybe<Scalars['Int']>
  courier?: Maybe<Scalars['String']>
  deliveryType?: Maybe<DeliveryMethodType>
  freightItem?: Maybe<Scalars['String']>
  modeOfTransport?: Maybe<Scalars['String']>
  ownCourier?: Maybe<Scalars['Boolean']>
  primaryWarehouse?: Maybe<Warehouse>
  quantity?: Maybe<Scalars['Int']>
  scheduleShipDate?: Maybe<Scalars['String']>
  scheduledArrivalDate?: Maybe<Scalars['String']>
  serviceLevel?: Maybe<Scalars['String']>
  shipFromCountry?: Maybe<Scalars['String']>
}

export enum DiscountReason {
  ORA_ERROR_CORRECTION = 'ORA_ERROR_CORRECTION',
  ORA_OTHER = 'ORA_OTHER',
  ORA_PRICE_MATCH = 'ORA_PRICE_MATCH',
  ORA_SALES_NEGOTIATION = 'ORA_SALES_NEGOTIATION',
  XXAB_ABTRIAL_ABREVIEW = 'XXAB_ABTRIAL_ABREVIEW',
  XXAB_CAP_PRICE = 'XXAB_CAP_PRICE',
  XXAB_COLLABORATOR = 'XXAB_COLLABORATOR',
  XXAB_CS_TRAINING = 'XXAB_CS_TRAINING',
  XXAB_CUSTOMER_REQUEST_PRICE = 'XXAB_CUSTOMER_REQUEST_PRICE',
  XXAB_DISTRIBUTOR = 'XXAB_DISTRIBUTOR',
  XXAB_ENTERED_IN_ERROR = 'XXAB_ENTERED_IN_ERROR',
  XXAB_FOCR_COURIER_ERROR = 'XXAB_FOCR_COURIER_ERROR',
  XXAB_FOCR_CUSTOMS_ISSUE = 'XXAB_FOCR_CUSTOMS_ISSUE',
  XXAB_FOCR_DATASHEET_ERROR = 'XXAB_FOCR_DATASHEET_ERROR',
  XXAB_FOCR_GOODSOUT_ERROR = 'XXAB_FOCR_GOODSOUT_ERROR',
  XXAB_FOCR_GOODWILL = 'XXAB_FOCR_GOODWILL',
  XXAB_FOCR_INCORRECT_CONCEN = 'XXAB_FOCR_INCORRECT_CONCEN',
  XXAB_FOCR_MISSING_COMPONENT = 'XXAB_FOCR_MISSING_COMPONENT',
  XXAB_FOCR_ORDER_ERROR_BYCS = 'XXAB_FOCR_ORDER_ERROR_BYCS',
  XXAB_FOCR_PAN = 'XXAB_FOCR_PAN',
  XXAB_FOCR_PRODUCT_QUALITY = 'XXAB_FOCR_PRODUCT_QUALITY',
  XXAB_FOCR_PRODUCT_QUALITY_GUAR = 'XXAB_FOCR_PRODUCT_QUALITY_GUAR',
  XXAB_FOCR_VOLUME_SHORTAGE = 'XXAB_FOCR_VOLUME_SHORTAGE',
  XXAB_FOCR_WARM_VIAL = 'XXAB_FOCR_WARM_VIAL',
  XXAB_FREE_SAMPLE = 'XXAB_FREE_SAMPLE',
  XXAB_GOODWILL = 'XXAB_GOODWILL',
  XXAB_LVO_DISCOUNT = 'XXAB_LVO_DISCOUNT',
  XXAB_PROMOTION = 'XXAB_PROMOTION',
  XXAB_TECHNICAL = 'XXAB_TECHNICAL',
}

export type Distributor = {
  __typename?: 'Distributor'
  address: Scalars['String']
  countries: Array<Scalars['String']>
  email: Scalars['String']
  fax?: Maybe<Scalars['String']>
  id: Scalars['String']
  name: Scalars['String']
  phone?: Maybe<Scalars['String']>
  website: Scalars['String']
}

export type DistributorsInput = {
  countryCode: Scalars['String']
}

export type DuplicateOrdersFilter = {
  orderNumber?: InputMaybe<Scalars['String']>
}

export type DuplicateOrdersMeta = {
  __typename?: 'DuplicateOrdersMeta'
  totalNumberOfResults: Scalars['Int']
}

export type DuplicateOrdersResult = {
  __typename?: 'DuplicateOrdersResult'
  meta: DuplicateOrdersMeta
  orders: Array<Order>
}

export type EcomOrganisationAddress = {
  __typename?: 'EcomOrganisationAddress'
  accountNumber?: Maybe<Scalars['String']>
  accountSiteFusionId?: Maybe<Scalars['String']>
  accountSiteUseFusionId?: Maybe<Scalars['String']>
  addressFusionId: Scalars['Long']
  addressLine1?: Maybe<Scalars['String']>
  addressLine2?: Maybe<Scalars['String']>
  addressLine3?: Maybe<Scalars['String']>
  addressLine4?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  contactGoodsOutNote?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  county?: Maybe<Scalars['String']>
  customsNumber?: Maybe<Scalars['String']>
  incoTerms?: Maybe<Scalars['String']>
  invoiceNotes?: Maybe<Scalars['String']>
  locationNumber?: Maybe<Scalars['String']>
  orgGoodsOutNote?: Maybe<Scalars['String']>
  organisationName?: Maybe<Scalars['String']>
  organisationRegistryId: Scalars['String']
  postcode?: Maybe<Scalars['String']>
  primaryOrganisationRegistryId?: Maybe<Scalars['String']>
  province?: Maybe<Scalars['String']>
  sendHardCopyOfDocuments?: Maybe<Scalars['Boolean']>
  siteNumber?: Maybe<Scalars['String']>
  siteNumbers?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  vatNumber?: Maybe<Scalars['String']>
}

export type EcomOrganisationAddressContact = {
  __typename?: 'EcomOrganisationAddressContact'
  contactFusionPartyId?: Maybe<Scalars['Long']>
  contactRegistryId: Scalars['String']
  emailAddress?: Maybe<Scalars['String']>
  emailContactPointId?: Maybe<Scalars['Long']>
  firstname: Scalars['String']
  goodsOutNote?: Maybe<Scalars['String']>
  internalCustomerNote?: Maybe<Scalars['String']>
  lastname?: Maybe<Scalars['String']>
  primaryPhoneNumber?: Maybe<Scalars['String']>
}

export type EcomOrganisationForContact = {
  __typename?: 'EcomOrganisationForContact'
  contactFusionPartyId?: Maybe<Scalars['Long']>
  contactOrgRelationshipFusionId?: Maybe<Scalars['Long']>
  contactPointId?: Maybe<Scalars['Long']>
  emailContactPointId?: Maybe<Scalars['Long']>
  internalCustomerNote?: Maybe<Scalars['String']>
  isRedListed?: Maybe<Scalars['Boolean']>
  organisationName?: Maybe<Scalars['String']>
  organisationRegistryId?: Maybe<Scalars['String']>
  partnerType?: Maybe<Scalars['String']>
}

export type Fulfilment = {
  __typename?: 'Fulfilment'
  actualArrivalDate?: Maybe<Scalars['Date']>
  actualShipDate?: Maybe<Scalars['Date']>
  carrierDetails?: Maybe<CarrierDetails>
  lotDetails: Array<BasketItemLotDetails>
  /** @deprecated 'shippingCarrier' field is deprecated, please use 'carrierDetails.courier' */
  shippingCarrier?: Maybe<Scalars['String']>
  /** @deprecated 'shippingType' field is deprecated, please use 'carrierDetails.type' */
  shippingType?: Maybe<Scalars['String']>
  tracking?: Maybe<Tracking>
  trackingNumber?: Maybe<Scalars['String']>
  waybillNumber?: Maybe<Scalars['String']>
  wmsNumber?: Maybe<Scalars['Float']>
  wmsReturnNumber?: Maybe<Scalars['Float']>
}

export enum FulfilmentStrategy {
  MANUFACTURING_WAREHOUSE = 'MANUFACTURING_WAREHOUSE',
  NON_PRIMARY_WAREHOUSE = 'NON_PRIMARY_WAREHOUSE',
  PRIMARY_WAREHOUSE = 'PRIMARY_WAREHOUSE',
  SISTER_LOT_NON_PRIMARY_WAREHOUSE = 'SISTER_LOT_NON_PRIMARY_WAREHOUSE',
  SISTER_LOT_PRIMARY_WAREHOUSE = 'SISTER_LOT_PRIMARY_WAREHOUSE',
}

export enum HoldType {
  ABCAM_NOSTOCK_ALLOCATION_HOLD = 'ABCAM_NOSTOCK_ALLOCATION_HOLD',
  ABCAM_STOCK_ALLOCATION_FINANCE = 'ABCAM_STOCK_ALLOCATION_FINANCE',
  ABCAM_STOCK_ALLOCATION_HOLD = 'ABCAM_STOCK_ALLOCATION_HOLD',
  CONFIRMATION_RPT_HOLD = 'CONFIRMATION_RPT_HOLD',
  MANUAL_HOLD = 'MANUAL_HOLD',
  OPERATIONAL_HOLD_ADDRESS_FAIL = 'OPERATIONAL_HOLD_ADDRESS_FAIL',
  OPERATIONAL_HOLD_DISTRIBUTOR = 'OPERATIONAL_HOLD_DISTRIBUTOR',
  OPERATIONAL_HOLD_GENERAL = 'OPERATIONAL_HOLD_GENERAL',
  OPERATIONAL_HOLD_LOT_SPECIFIC = 'OPERATIONAL_HOLD_LOT_SPECIFIC',
  OPERATIONAL_HOLD_NOT_THIS_LOT = 'OPERATIONAL_HOLD_NOT_THIS_LOT',
  OPERATIONAL_HOLD_SAME_LOT = 'OPERATIONAL_HOLD_SAME_LOT',
  TC_CHECK_LN_HOLD_PCK = 'TC_CHECK_LN_HOLD_PCK',
  TC_CHECK_ORD_HOLD_CR = 'TC_CHECK_ORD_HOLD_CR',
  TC_CHECK_ORD_HOLD_PCK = 'TC_CHECK_ORD_HOLD_PCK',
  TC_ORD_CR = 'TC_ORD_CR',
  TC_ORD_LINE_PICK = 'TC_ORD_LINE_PICK',
  TC_ORD_PICK = 'TC_ORD_PICK',
  TC_OTH_SCR_PICK = 'TC_OTH_SCR_PICK',
  TC_PRD_CLASS_PICK = 'TC_PRD_CLASS_PICK',
  TC_RPLS_PICK = 'TC_RPLS_PICK',
  TC_SANC_CTRY_PICK = 'TC_SANC_CTRY_PICK',
  TC_TRD_CTRL_PICK = 'TC_TRD_CTRL_PICK',
  XXAB_ORD_CR_PREPAYMENT = 'XXAB_ORD_CR_PREPAYMENT',
}

export type IncidentNumber = {
  __typename?: 'IncidentNumber'
  number?: Maybe<Scalars['String']>
  reason?: Maybe<Scalars['String']>
}

export enum IncoTerms {
  DAP = 'DAP',
  DDP = 'DDP',
  EXW = 'EXW',
  FCA = 'FCA',
}

export type ItemPrices = {
  __typename?: 'ItemPrices'
  adjustments?: Maybe<Array<Maybe<PriceAdjustment>>>
  base: Price
  /** @deprecated 'lineTotalWithDiscount' field is deprecated, please use 'totalWithDiscount' */
  lineTotalWithDiscount?: Maybe<Price>
  /** @deprecated 'lineTotalWithoutDiscount' field is deprecated, please use 'totalWithoutDiscount' */
  lineTotalWithoutDiscount?: Maybe<Price>
  tax?: Maybe<TaxInfo>
  totalWithDiscount?: Maybe<Price>
  totalWithoutDiscount?: Maybe<Price>
  unitPriceWithDiscount?: Maybe<Price>
  unitPriceWithoutDiscount?: Maybe<Price>
}

export enum LineStatus {
  AWAITING_BILLING = 'AWAITING_BILLING',
  AWAITING_RECEIVING = 'AWAITING_RECEIVING',
  AWAITING_SHIPPING = 'AWAITING_SHIPPING',
  BACKORDERED = 'BACKORDERED',
  BILLED = 'BILLED',
  CANCELED = 'CANCELED',
  CANCELLATION_PENDING = 'CANCELLATION_PENDING',
  CANCELLATION_REQUESTED = 'CANCELLATION_REQUESTED',
  CHANGE_PENDING = 'CHANGE_PENDING',
  CLOSED = 'CLOSED',
  CREATED = 'CREATED',
  DELIVERED = 'DELIVERED',
  MANUAL_SCHEDULING_REQUIRED = 'MANUAL_SCHEDULING_REQUIRED',
  NOT_STARTED = 'NOT_STARTED',
  PICKED = 'PICKED',
  RECEIVED = 'RECEIVED',
  RECEIVING_COMPLETE = 'RECEIVING_COMPLETE',
  SCHEDULED = 'SCHEDULED',
  SHIPPED = 'SHIPPED',
  UNSCHEDULED = 'UNSCHEDULED',
  WAITING = 'WAITING',
  WAITING_FOR_TO_CREATION = 'WAITING_FOR_TO_CREATION',
  WAITING_FOR_WO_CREATION = 'WAITING_FOR_WO_CREATION',
  WAIT_FOR_SHIPPABLE_ITEMS = 'WAIT_FOR_SHIPPABLE_ITEMS',
}

export type LotAvailability = {
  __typename?: 'LotAvailability'
  availableToReserve: Scalars['Int']
  lotNumber: Scalars['String']
  lotType: Scalars['String']
  uom: Scalars['String']
  warehouse: Scalars['String']
}

export enum LotSpecificOrderType {
  ALL_ONE_LOT = 'ALL_ONE_LOT',
  ALTERNATIVE_LOT = 'ALTERNATIVE_LOT',
  NON_PARENT_BULK_LOT = 'NON_PARENT_BULK_LOT',
  NOT_THIS_SPECIFIC_LOT = 'NOT_THIS_SPECIFIC_LOT',
  ORIGINAL_LOT = 'ORIGINAL_LOT',
  SAME_LOT = 'SAME_LOT',
  THIS_SPECIFIC_LOT = 'THIS_SPECIFIC_LOT',
  WHOLE_BATCH_OF_THIS_LOT = 'WHOLE_BATCH_OF_THIS_LOT',
}

export enum ManufacturingWarehouse {
  INV_100_Cambridge = 'INV_100_Cambridge',
  INV_101_FG_Burlingame = 'INV_101_FG_Burlingame',
  INV_102_FG_Boston = 'INV_102_FG_Boston',
  INV_103_FG_Amsterdam = 'INV_103_FG_Amsterdam',
  INV_104_FG_Tokyo = 'INV_104_FG_Tokyo',
  INV_200_Boston = 'INV_200_Boston',
  INV_210_Eugene = 'INV_210_Eugene',
  INV_230_Burlingame = 'INV_230_Burlingame',
  INV_240_BostonFirefly = 'INV_240_BostonFirefly',
  INV_250_Branford = 'INV_250_Branford',
  INV_300_Shanghai = 'INV_300_Shanghai',
  INV_301_FG_Hangzhou = 'INV_301_FG_Hangzhou',
  INV_310_Hangzhou = 'INV_310_Hangzhou',
  INV_400_HongKong = 'INV_400_HongKong',
  INV_450_Tokyo = 'INV_450_Tokyo',
  INV_500_Singapore = 'INV_500_Singapore',
  INV_510_Melbourne = 'INV_510_Melbourne',
  INV_515_Adelaide = 'INV_515_Adelaide',
  INV_520_Taipei = 'INV_520_Taipei',
  INV_600_Amsterdam = 'INV_600_Amsterdam',
}

export type Market = {
  __typename?: 'Market'
  type: Scalars['String']
}

export type MarketInput = {
  countryCode: Scalars['String']
}

export type Mutation = {
  __typename?: 'Mutation'
  order: Order
}

export type MutationOrderArgs = {
  order: OrderInput
}

export type Office = {
  __typename?: 'Office'
  abcamRegion?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  addressNative?: Maybe<Scalars['String']>
  companyName?: Maybe<Scalars['String']>
  companyNameNative?: Maybe<Scalars['String']>
  countryCode?: Maybe<Scalars['String']>
  email?: Maybe<OfficeEmail>
  fax?: Maybe<Scalars['String']>
  faxClick?: Maybe<Scalars['String']>
  freefax?: Maybe<Scalars['String']>
  freefaxClick?: Maybe<Scalars['String']>
  freephone?: Maybe<Scalars['String']>
  freephoneClick?: Maybe<Scalars['String']>
  openingDays?: Maybe<Scalars['String']>
  openingHrs?: Maybe<Scalars['String']>
  openingHrsLangText?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  phoneClick?: Maybe<Scalars['String']>
  phoneLangMsg?: Maybe<Scalars['String']>
  supportLocationCode?: Maybe<Scalars['String']>
  supportOffice?: Maybe<Scalars['String']>
  supportOfficeCode?: Maybe<Scalars['String']>
  timeZone?: Maybe<Scalars['String']>
}

export type OfficeEmail = {
  __typename?: 'OfficeEmail'
  custom?: Maybe<Scalars['String']>
  customerService?: Maybe<Scalars['String']>
  finance?: Maybe<Scalars['String']>
  licensing?: Maybe<Scalars['String']>
  technicalSupport?: Maybe<Scalars['String']>
}

export type OfficeInput = {
  countryCode: Scalars['String']
}

export enum OracleStatus {
  CANCELED = 'CANCELED',
  CLOSED = 'CLOSED',
  DOO_CANCEL_REQUESTED = 'DOO_CANCEL_REQUESTED',
  DOO_CREDIT_REVIEW_PENDING = 'DOO_CREDIT_REVIEW_PENDING',
  DOO_DRAFT = 'DOO_DRAFT',
  DOO_REFERENCE = 'DOO_REFERENCE',
  OPEN = 'OPEN',
  PARTIAL_CLOSE = 'PARTIAL_CLOSE',
  Partial = 'Partial',
  Processing = 'Processing',
  Reference = 'Reference',
}

export enum OracleStatusCollated {
  CANCELED = 'CANCELED',
  CLOSED = 'CLOSED',
  DOO_CANCEL_REQUESTED = 'DOO_CANCEL_REQUESTED',
  DOO_CREDIT_REVIEW_PENDING = 'DOO_CREDIT_REVIEW_PENDING',
  DOO_DRAFT = 'DOO_DRAFT',
  DOO_REFERENCE = 'DOO_REFERENCE',
  OPEN = 'OPEN',
  PARTIAL_CLOSE = 'PARTIAL_CLOSE',
  Processing = 'Processing',
}

export type Order = {
  __typename?: 'Order'
  addresses?: Maybe<OrderAddresses>
  basket?: Maybe<Basket>
  billing?: Maybe<OrderBilling>
  cancellationReason?: Maybe<OrderCancellationReason>
  creationDate?: Maybe<Scalars['Date']>
  customers: OrderCustomers
  id: Scalars['String']
  lastModificationDate?: Maybe<Scalars['Date']>
  lastModifiedBy?: Maybe<Scalars['String']>
  lines: Array<BasketItem>
  meta: OrderMetadata
  notes?: Maybe<OrderNotes>
  oracleStatus?: Maybe<Scalars['String']>
  orderNumber?: Maybe<Scalars['String']>
  /**
   * Promotions and their discounts applied to the order. Contains basket level and shipping charges promotions.
   * Item level promotions are not included. Instead item level promotions are included in each order's lines.
   */
  promotions?: Maybe<Promotions>
  quotation?: Maybe<Quotation>
  shipping?: Maybe<OrderShipping>
  source?: Maybe<SalesChannel>
  status: OrderStatus
  summary?: Maybe<BasketSummary>
  type?: Maybe<OrderType>
  validationErrors?: Maybe<Array<ValidationError>>
}

export type OrderAddress = {
  __typename?: 'OrderAddress'
  addressLine1?: Maybe<Scalars['String']>
  addressLine2?: Maybe<Scalars['String']>
  addressLine3?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  county?: Maybe<Scalars['String']>
  district?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  organisationName?: Maybe<Scalars['String']>
  postcode?: Maybe<Scalars['String']>
  province?: Maybe<Scalars['String']>
  siteNumber?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
}

export type OrderAddresses = {
  __typename?: 'OrderAddresses'
  billing?: Maybe<OrderAddress>
  selling?: Maybe<OrderAddress>
  shipping?: Maybe<OrderAddress>
}

export type OrderBillToCustomer = {
  __typename?: 'OrderBillToCustomer'
  accountNumber: Scalars['String']
  accountRoleFusionId?: Maybe<Scalars['Long']>
  accountSiteUseFusionId: Scalars['Long']
  contactRegistryId?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  emails?: Maybe<Array<Scalars['String']>>
  firstName?: Maybe<Scalars['String']>
  forAttentionOf?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  locationNumber?: Maybe<Scalars['String']>
  /** Original value of BillTo.OrganisationName supplied by Buyer upon order creation. */
  organisationName?: Maybe<Scalars['String']>
  organisationRegistryId?: Maybe<Scalars['String']>
  organisationSiteNumber?: Maybe<Scalars['String']>
  phone?: Maybe<OrderPhone>
  phoneNumber?: Maybe<Scalars['String']>
  preferredContactPointId?: Maybe<Scalars['Long']>
}

export type OrderBilling = {
  __typename?: 'OrderBilling'
  creditCardPaymentAuthorised?: Maybe<Scalars['Boolean']>
  onlineTransaction?: Maybe<OrderOnlineTransaction>
  paymentMethod?: Maybe<Scalars['String']>
  paymentTerms?: Maybe<Scalars['String']>
  prepay?: Maybe<Scalars['Boolean']>
  promoCode?: Maybe<Scalars['String']>
  purchaseOrderNumber?: Maybe<Scalars['String']>
  siteNumber?: Maybe<Scalars['String']>
  taxExempt: Scalars['Boolean']
  taxName?: Maybe<Scalars['String']>
  taxNumber?: Maybe<Scalars['String']>
  taxValue?: Maybe<Scalars['Float']>
}

export type OrderCancellationReason = {
  __typename?: 'OrderCancellationReason'
  code?: Maybe<Scalars['String']>
  reason?: Maybe<Scalars['String']>
}

export type OrderCustomers = {
  __typename?: 'OrderCustomers'
  billTo?: Maybe<OrderBillToCustomer>
  shipTo?: Maybe<OrderShipToCustomer>
  soldTo: OrderSoldToCustomer
}

export enum OrderEventSource {
  GRAPHQL = 'GRAPHQL',
  INTERNAL = 'INTERNAL',
  ORACLE_AR1 = 'ORACLE_AR1',
  ORACLE_AR3 = 'ORACLE_AR3',
  ORACLE_OM2 = 'ORACLE_OM2',
  REST = 'REST',
}

export enum OrderEventStatus {
  ERROR = 'ERROR',
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
}

export enum OrderEventType {
  BASKET_CREATION = 'BASKET_CREATION',
  CREDIT_NOTE_CREATION = 'CREDIT_NOTE_CREATION',
  INVOICE_CREATION = 'INVOICE_CREATION',
  INVOICE_PAYMENT = 'INVOICE_PAYMENT',
  INVOICE_PAYMENT_NOTIFICATION = 'INVOICE_PAYMENT_NOTIFICATION',
  INVOICE_PAYMENT_REVERSAL = 'INVOICE_PAYMENT_REVERSAL',
  ORDER_CANCELLATION = 'ORDER_CANCELLATION',
  ORDER_CHECKOUT = 'ORDER_CHECKOUT',
  ORDER_CLEANUP = 'ORDER_CLEANUP',
  ORDER_CONFIRMATION = 'ORDER_CONFIRMATION',
  ORDER_CREATION = 'ORDER_CREATION',
  ORDER_ENRICHMENT = 'ORDER_ENRICHMENT',
  ORDER_PAYMENT_REQUEST = 'ORDER_PAYMENT_REQUEST',
  ORDER_RESTORE = 'ORDER_RESTORE',
  ORDER_SUBMISSION = 'ORDER_SUBMISSION',
  ORDER_UPDATE = 'ORDER_UPDATE',
  ORDER_VALIDATION = 'ORDER_VALIDATION',
  QUOTE_CONVERTED_TO_ORDER = 'QUOTE_CONVERTED_TO_ORDER',
  QUOTE_DOCUMENT_DOWNLOADED = 'QUOTE_DOCUMENT_DOWNLOADED',
  QUOTE_EMAILED_TO_CUSTOMER = 'QUOTE_EMAILED_TO_CUSTOMER',
}

export type OrderFilter = {
  id?: InputMaybe<Scalars['String']>
  orderNumber?: InputMaybe<Scalars['String']>
}

export type OrderFiltersCreatedAt = {
  from: Scalars['Date']
  until: Scalars['Date']
}

export type OrderInput = {
  addresses?: InputMaybe<OrderInputAddresses>
  billing?: InputMaybe<OrderInputBilling>
  customers?: InputMaybe<OrderInputCustomers>
  id?: InputMaybe<Scalars['String']>
  lines?: InputMaybe<Array<BasketItemInput>>
  meta?: InputMaybe<OrderInputMetadata>
  notes?: InputMaybe<OrderInputNotes>
  orderNumber?: InputMaybe<Scalars['String']>
  shipping?: InputMaybe<OrderInputShipping>
  source?: InputMaybe<SalesChannel>
  status: OrderStatus
}

export type OrderInputAddress = {
  addressLine1?: InputMaybe<Scalars['String']>
  addressLine2?: InputMaybe<Scalars['String']>
  addressLine3?: InputMaybe<Scalars['String']>
  city?: InputMaybe<Scalars['String']>
  country?: InputMaybe<Scalars['String']>
  county?: InputMaybe<Scalars['String']>
  district?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['String']>
  organisationName?: InputMaybe<Scalars['String']>
  postcode?: InputMaybe<Scalars['String']>
  province?: InputMaybe<Scalars['String']>
  siteNumber?: InputMaybe<Scalars['String']>
  state?: InputMaybe<Scalars['String']>
}

export type OrderInputAddresses = {
  billing?: InputMaybe<OrderInputAddress>
  selling?: InputMaybe<OrderInputAddress>
  shipping?: InputMaybe<OrderInputAddress>
}

export type OrderInputBillToCustomer = {
  accountNumber: Scalars['String']
  accountRoleFusionId?: InputMaybe<Scalars['Long']>
  accountSiteUseFusionId: Scalars['Long']
  contactRegistryId?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  emails?: InputMaybe<Array<Scalars['String']>>
  firstName?: InputMaybe<Scalars['String']>
  forAttentionOf?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  locationNumber?: InputMaybe<Scalars['String']>
  /** Original value of BillTo.OrganisationName supplied by Buyer upon order creation. */
  organisationName?: InputMaybe<Scalars['String']>
  organisationRegistryId?: InputMaybe<Scalars['String']>
  organisationSiteNumber?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<OrderInputPhone>
  phoneNumber?: InputMaybe<Scalars['String']>
  preferredContactPointId?: InputMaybe<Scalars['Long']>
}

export type OrderInputBilling = {
  creditCardPaymentAuthorised?: InputMaybe<Scalars['Boolean']>
  onlineTransaction?: InputMaybe<OrderInputOnlineTransaction>
  paymentMethod?: InputMaybe<Scalars['String']>
  paymentTerms?: InputMaybe<Scalars['String']>
  prepay?: InputMaybe<Scalars['Boolean']>
  promoCode?: InputMaybe<Scalars['String']>
  purchaseOrderNumber?: InputMaybe<Scalars['String']>
  siteNumber?: InputMaybe<Scalars['String']>
  taxExempt: Scalars['Boolean']
  taxName?: InputMaybe<Scalars['String']>
  taxNumber?: InputMaybe<Scalars['String']>
  taxValue?: InputMaybe<Scalars['Float']>
}

export type OrderInputCustomers = {
  billTo?: InputMaybe<OrderInputBillToCustomer>
  shipTo?: InputMaybe<OrderInputShipToCustomer>
  soldTo: OrderInputSoldToCustomer
}

export type OrderInputIncidentNumber = {
  number?: InputMaybe<Scalars['String']>
  reason?: InputMaybe<Scalars['String']>
}

export type OrderInputMetadata = {
  businessUnit?: InputMaybe<BusinessUnitCode>
  eInvoiceEnabled?: InputMaybe<Scalars['Boolean']>
  holds: Array<InputMaybe<HoldType>>
  incidentNumbers?: InputMaybe<Array<Scalars['String']>>
  incidentNumbersWithReasons?: InputMaybe<
    Array<InputMaybe<OrderInputIncidentNumber>>
  >
  linkedOrderId?: InputMaybe<Scalars['String']>
  punchOutId?: InputMaybe<Scalars['String']>
}

export type OrderInputNote = {
  admin?: InputMaybe<Scalars['String']>
  customer?: InputMaybe<Scalars['String']>
}

export type OrderInputNoteSettings = {
  ignoreCustomerNote?: InputMaybe<Scalars['Boolean']>
}

export type OrderInputNotes = {
  billing?: InputMaybe<OrderInputNote>
  customer?: InputMaybe<Scalars['String']>
  delivery?: InputMaybe<OrderInputNote>
  general?: InputMaybe<OrderInputNote>
  goodsOut?: InputMaybe<Scalars['String']>
  internalCustomer?: InputMaybe<Scalars['String']>
  progress?: InputMaybe<Array<OrderInputProgressNote>>
  settings?: InputMaybe<OrderInputNoteSettings>
}

export type OrderInputOnlineTransaction = {
  merchantId: Scalars['String']
  paymentAuthorisationAmount?: InputMaybe<Scalars['Float']>
  paymentAuthorisationToken?: InputMaybe<Scalars['String']>
  subscriptionId: Scalars['String']
}

export type OrderInputPhone = {
  countryCode?: InputMaybe<Scalars['String']>
  telephoneNumber?: InputMaybe<Scalars['String']>
}

export type OrderInputProgressNote = {
  message: Scalars['String']
  timestamp: Scalars['Date']
  user: Scalars['String']
}

export type OrderInputShipToCustomer = {
  contactOrgRelationshipFusionId: Scalars['Long']
  contactRegistryId?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  emails?: InputMaybe<Array<Scalars['String']>>
  firstName?: InputMaybe<Scalars['String']>
  forAttentionOf?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  locationNumber?: InputMaybe<Scalars['String']>
  orgRegistryId?: InputMaybe<Scalars['String']>
  /** Original value of ShipTo.OrganisationName supplied by Buyer upon order creation. */
  organisationName?: InputMaybe<Scalars['String']>
  organisationRegistryId: Scalars['String']
  partySiteFusionId: Scalars['Long']
  phone?: InputMaybe<OrderInputPhone>
  phoneNumber?: InputMaybe<Scalars['String']>
  preferredContactPointId: Scalars['Long']
}

export type OrderInputShipping = {
  brokerRegistryId?: InputMaybe<Scalars['String']>
  courier?: InputMaybe<Courier>
  courierAccountNumber?: InputMaybe<Scalars['String']>
  customsCode?: InputMaybe<Scalars['String']>
  deliveryPreference?: InputMaybe<DeliveryPreference>
  freeShipping: Scalars['Boolean']
  freightForwarderRegistryId?: InputMaybe<Scalars['String']>
  incoTerms?: InputMaybe<IncoTerms>
  sendHardCopy: Scalars['Boolean']
  shippingCosts?: InputMaybe<Scalars['Float']>
  shippingTaxed: Scalars['Boolean']
  siteNumber?: InputMaybe<Scalars['String']>
}

export type OrderInputSoldToCustomer = {
  accountManager?: InputMaybe<Scalars['String']>
  accountTier?: InputMaybe<Scalars['String']>
  contactOrgRelationshipFusionId: Scalars['Long']
  contactPointId: Scalars['Long']
  contactRegId?: InputMaybe<Scalars['String']>
  contactRegistryId: Scalars['String']
  email?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  orgRegistryId?: InputMaybe<Scalars['String']>
  organisationName?: InputMaybe<Scalars['String']>
  organisationRegistryId: Scalars['String']
  partnerType?: InputMaybe<PartnerType>
  phone?: InputMaybe<OrderInputPhone>
  phoneNumber?: InputMaybe<Scalars['String']>
}

export type OrderLink = {
  __typename?: 'OrderLink'
  id?: Maybe<Scalars['ID']>
  linkedNumber?: Maybe<Scalars['String']>
  reason?: Maybe<OrderLinkReason>
  type?: Maybe<OrderLinkType>
}

export enum OrderLinkReason {
  ALTERNATIVE_QUOTE = 'ALTERNATIVE_QUOTE',
  DUPLICATE = 'DUPLICATE',
  FOCR = 'FOCR',
  OPPORTUNITY = 'OPPORTUNITY',
  QUOTE_ORDER = 'QUOTE_ORDER',
}

export enum OrderLinkType {
  OPPORTUNITY = 'OPPORTUNITY',
  ORDER = 'ORDER',
}

export type OrderLog = {
  __typename?: 'OrderLog'
  createdAt: Scalars['Date']
  description: Scalars['String']
  event?: Maybe<Scalars['String']>
  id: Scalars['String']
  orderId: Scalars['String']
  orderNumber?: Maybe<Scalars['String']>
  orderSnapshot: Scalars['String']
  source: OrderEventSource
  status: OrderEventStatus
  type: OrderEventType
  user?: Maybe<Scalars['String']>
}

export type OrderLogFilter = {
  id: Scalars['String']
}

export enum OrderLogSortBy {
  CREATED_AT = 'CREATED_AT',
  DESCRIPTION = 'DESCRIPTION',
  SOURCE = 'SOURCE',
  STATUS = 'STATUS',
  TYPE = 'TYPE',
  USER = 'USER',
}

export type OrderLogsFilter = {
  orderNumber: Scalars['String']
  sortBy?: InputMaybe<OrderLogSortBy>
  sortDirection?: InputMaybe<SortDirection>
}

export type OrderMetadata = {
  __typename?: 'OrderMetadata'
  businessUnit?: Maybe<BusinessUnitCode>
  currency?: Maybe<Currency>
  eInvoiceEnabled?: Maybe<Scalars['Boolean']>
  freeOfChargeReplacement?: Maybe<Scalars['Boolean']>
  holds: Array<Maybe<HoldType>>
  /** @deprecated 'incidentNumbers' field is deprecated, please use 'incidentNumbersWithReasons' as we're supporting the IncidentNumber type over an array of strings */
  incidentNumbers?: Maybe<Array<Scalars['String']>>
  incidentNumbersWithReasons?: Maybe<Array<Maybe<IncidentNumber>>>
  largeQuantityOrder?: Maybe<Scalars['Boolean']>
  largeValueOrder?: Maybe<Scalars['Boolean']>
  linkedOrderId?: Maybe<Scalars['String']>
  links?: Maybe<Array<Maybe<OrderLink>>>
  punchOutId?: Maybe<Scalars['String']>
}

export type OrderNote = {
  __typename?: 'OrderNote'
  admin?: Maybe<Scalars['String']>
  customer?: Maybe<Scalars['String']>
}

export type OrderNoteSettings = {
  __typename?: 'OrderNoteSettings'
  ignoreCustomerNote?: Maybe<Scalars['Boolean']>
}

export type OrderNotes = {
  __typename?: 'OrderNotes'
  billing?: Maybe<OrderNote>
  customer?: Maybe<Scalars['String']>
  delivery?: Maybe<OrderNote>
  general?: Maybe<OrderNote>
  goodsOut?: Maybe<Scalars['String']>
  internalCustomer?: Maybe<Scalars['String']>
  progress?: Maybe<Array<OrderProgressNote>>
  settings?: Maybe<OrderNoteSettings>
}

export type OrderOnlineTransaction = {
  __typename?: 'OrderOnlineTransaction'
  merchantId: Scalars['String']
  paymentAuthorisationAmount?: Maybe<Scalars['Float']>
  paymentAuthorisationToken?: Maybe<Scalars['String']>
  subscriptionId: Scalars['String']
}

export type OrderPhone = {
  __typename?: 'OrderPhone'
  countryCode?: Maybe<Scalars['String']>
  telephoneNumber?: Maybe<Scalars['String']>
}

export type OrderProgressNote = {
  __typename?: 'OrderProgressNote'
  message: Scalars['String']
  timestamp: Scalars['Date']
  user: Scalars['String']
}

export type OrderSearchCriteria = {
  customerId?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['String']>
}

export type OrderShipToCustomer = {
  __typename?: 'OrderShipToCustomer'
  contactOrgRelationshipFusionId: Scalars['Long']
  contactRegistryId?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  emails?: Maybe<Array<Scalars['String']>>
  firstName?: Maybe<Scalars['String']>
  forAttentionOf?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  locationNumber?: Maybe<Scalars['String']>
  orgRegistryId?: Maybe<Scalars['String']>
  /** Original value of ShipTo.OrganisationName supplied by Buyer upon order creation. */
  organisationName?: Maybe<Scalars['String']>
  organisationRegistryId: Scalars['String']
  partySiteFusionId: Scalars['Long']
  phone?: Maybe<OrderPhone>
  phoneNumber?: Maybe<Scalars['String']>
  preferredContactPointId: Scalars['Long']
}

export type OrderShipping = {
  __typename?: 'OrderShipping'
  brokerRegistryId?: Maybe<Scalars['String']>
  courier?: Maybe<Courier>
  courierAccountNumber?: Maybe<Scalars['String']>
  customsCode?: Maybe<Scalars['String']>
  deliveryPreference?: Maybe<DeliveryPreference>
  flatShippingCosts?: Maybe<Scalars['Float']>
  freeShipping: Scalars['Boolean']
  freightForwarderRegistryId?: Maybe<Scalars['String']>
  incoTerms?: Maybe<IncoTerms>
  sendHardCopy: Scalars['Boolean']
  shippingCosts?: Maybe<Scalars['Float']>
  shippingTaxed: Scalars['Boolean']
  shippingTaxes?: Maybe<Scalars['Float']>
  siteNumber?: Maybe<Scalars['String']>
}

export type OrderSoldToCustomer = {
  __typename?: 'OrderSoldToCustomer'
  accountManager?: Maybe<Scalars['String']>
  accountTier?: Maybe<Scalars['String']>
  contactOrgRelationshipFusionId: Scalars['Long']
  contactPointId: Scalars['Long']
  contactRegId?: Maybe<Scalars['String']>
  contactRegistryId: Scalars['String']
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  orgRegistryId?: Maybe<Scalars['String']>
  organisationName?: Maybe<Scalars['String']>
  organisationRegistryId: Scalars['String']
  partnerType?: Maybe<PartnerType>
  phone?: Maybe<OrderPhone>
  phoneNumber?: Maybe<Scalars['String']>
}

export enum OrderStatus {
  ABCAM_INTERVENTION = 'ABCAM_INTERVENTION',
  ACCEPTED_UPDATED = 'ACCEPTED_UPDATED',
  APPROVED = 'APPROVED',
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  CANCELLED = 'CANCELLED',
  CMO_INTERVENTION = 'CMO_INTERVENTION',
  CONFIRMED = 'CONFIRMED',
  CUSTOMER_ACTION = 'CUSTOMER_ACTION',
  DRAFT = 'DRAFT',
  DUPLICATE = 'DUPLICATE',
  EXPIRED = 'EXPIRED',
  IN_PROGRESS = 'IN_PROGRESS',
  ONLINE_BETA = 'ONLINE_BETA',
  PAYMENT_PENDING = 'PAYMENT_PENDING',
  PAYMENT_PROCESSING = 'PAYMENT_PROCESSING',
  READY = 'READY',
  SUBMITTED = 'SUBMITTED',
  VALIDATED = 'VALIDATED',
  WITH_CUSTOMER = 'WITH_CUSTOMER',
}

export enum OrderType {
  ORDER = 'ORDER',
  QUOTE = 'QUOTE',
}

export type OrdersFilter = {
  PONumber?: InputMaybe<Scalars['String']>
  accountManager?: InputMaybe<Scalars['String']>
  businessUnit?: InputMaybe<Array<BusinessUnitCode>>
  createdAt?: InputMaybe<OrderFiltersCreatedAt>
  incidentNumber?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  opportunityNumber?: InputMaybe<Scalars['String']>
  oracleStatus?: InputMaybe<Array<OracleStatusCollated>>
  orderNumber?: InputMaybe<Scalars['String']>
  productCodes?: InputMaybe<Array<Scalars['String']>>
  searchTerm?: InputMaybe<Scalars['String']>
  soldToCustomer?: InputMaybe<Array<Scalars['String']>>
  soldToOrganisation?: InputMaybe<Array<Scalars['String']>>
  sortBy?: InputMaybe<Scalars['String']>
  sortDirection?: InputMaybe<SortDirection>
  source?: InputMaybe<Array<SalesChannel>>
  status?: InputMaybe<Array<OrderStatus>>
  trackingNumber?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Array<OrderType>>
  wmsNumber?: InputMaybe<Scalars['String']>
}

export type OrdersResult = {
  __typename?: 'OrdersResult'
  meta: OrdersResultMeta
  orders: Array<Order>
}

export type OrdersResultMeta = {
  __typename?: 'OrdersResultMeta'
  limit: Scalars['Int']
  offset: Scalars['Int']
  sortBy: Scalars['String']
  sortDirection: SortDirection
  totalNumberOfResults: Scalars['Int']
}

export enum PartnerType {
  BROKER = 'BROKER',
  DISTRIBUTOR_EXCLUSIVE = 'DISTRIBUTOR_EXCLUSIVE',
  DISTRIBUTOR_NON_EXCLUSIVE = 'DISTRIBUTOR_NON_EXCLUSIVE',
  FREIGHT_FORWARDER = 'FREIGHT_FORWARDER',
  GREY_RESELLER = 'GREY_RESELLER',
  PURCHASER = 'PURCHASER',
  RESELLER = 'RESELLER',
  SUB_DEALER = 'SUB_DEALER',
  SUSPECTED_GREY_RESELLER = 'SUSPECTED_GREY_RESELLER',
}

export type Price = {
  __typename?: 'Price'
  currency: Currency
  /** @deprecated A line price can't be part of a price model */
  line?: Maybe<Scalars['Float']>
  value: Scalars['Float']
}

export type PriceAdjustment = {
  __typename?: 'PriceAdjustment'
  id?: Maybe<Scalars['String']>
  percentage?: Maybe<Scalars['Float']>
  price?: Maybe<Price>
  reason: PriceAdjustmentReason
  source?: Maybe<PriceAdjustmentSource>
  type: AdjustmentType
}

export type PriceAdjustmentInput = {
  percentage?: InputMaybe<Scalars['Float']>
  price?: InputMaybe<PriceInput>
  reason: PriceAdjustmentInputReason
  type: AdjustmentType
}

export type PriceAdjustmentInputReason = {
  replacement?: InputMaybe<PriceAdjustmentInputReplacement>
  type: DiscountReason
}

export type PriceAdjustmentInputReplacement = {
  lineNumber: Scalars['String']
  orderNumber: Scalars['String']
}

export type PriceAdjustmentReason = {
  __typename?: 'PriceAdjustmentReason'
  replacement?: Maybe<PriceAdjustmentReplacement>
  type: DiscountReason
}

export type PriceAdjustmentReplacement = {
  __typename?: 'PriceAdjustmentReplacement'
  lineNumber: Scalars['String']
  orderNumber: Scalars['String']
}

export enum PriceAdjustmentSource {
  CMO = 'CMO',
  CUSTOMER = 'CUSTOMER',
  PRICE = 'PRICE',
}

export type PriceInput = {
  currency: Currency
  value: Scalars['Float']
}

/**
 * Represent a discount in absolute value derived from a promotion code
 *
 * @note: The shape of promotion data is the same as in the REST API implements. That way we can
 * use the same FrontEnd component to consume GQL and REST APIs
 */
export type PromotionDiscount = {
  __typename?: 'PromotionDiscount'
  discount: Price
  name?: Maybe<Scalars['String']>
  promotionCode: Scalars['String']
  sessionId: Scalars['String']
}

/**
 * Summary of promotions applied to the order.
 *
 * @note: Item level promotions are not included. Instead item level promotions are
 * included in each order's item
 *
 * @note: The shape of promotion data is the same as in the REST API implements. That way we can
 * use the same FrontEnd component to consume GQL and REST APIs
 */
export type Promotions = {
  __typename?: 'Promotions'
  basketLevel: Array<PromotionDiscount>
  shippingCharges: Array<PromotionDiscount>
}

export type Query = {
  __typename?: 'Query'
  _service: _Service
  contactSearch: ContactSearchResults
  distributors: Array<Distributor>
  duplicateOrders: DuplicateOrdersResult
  getDeliveryOptions?: Maybe<Delivery>
  market: Market
  office: Office
  order: Order
  orderLog?: Maybe<OrderLog>
  orderLogs: Array<OrderLog>
  orders: OrdersResult
  organisationAddressContacts: Array<Maybe<EcomOrganisationAddressContact>>
  organisationAddresses: Array<Maybe<EcomOrganisationAddress>>
  organisationsForContact: Array<Maybe<EcomOrganisationForContact>>
}

export type QueryContactSearchArgs = {
  filter: ContactSearchFilter
}

export type QueryDistributorsArgs = {
  criteria: DistributorsInput
}

export type QueryDuplicateOrdersArgs = {
  filter: DuplicateOrdersFilter
}

export type QueryGetDeliveryOptionsArgs = {
  orderId: Scalars['String']
}

export type QueryMarketArgs = {
  criteria: MarketInput
}

export type QueryOfficeArgs = {
  criteria: OfficeInput
}

export type QueryOrderArgs = {
  filter: OrderFilter
}

export type QueryOrderLogArgs = {
  filter: OrderLogFilter
}

export type QueryOrderLogsArgs = {
  filter: OrderLogsFilter
}

export type QueryOrdersArgs = {
  filter?: InputMaybe<OrdersFilter>
}

export type QueryOrganisationAddressContactsArgs = {
  addressFusionId: Scalars['Long']
  addressType: Scalars['String']
  organisationRegistryId: Scalars['String']
  soldToContactRegistryId?: InputMaybe<Scalars['String']>
  soldToOrganisationRegistryId?: InputMaybe<Scalars['String']>
}

export type QueryOrganisationAddressesArgs = {
  addressFusionId?: InputMaybe<Scalars['Long']>
  addressType: Scalars['String']
  soldToContactRegistryId?: InputMaybe<Scalars['String']>
  soldToOrganisationRegistryId?: InputMaybe<Scalars['String']>
}

export type QueryOrganisationsForContactArgs = {
  contactRegistryId?: InputMaybe<Scalars['String']>
}

export type Quotation = {
  __typename?: 'Quotation'
  additionalTerms?: Maybe<Scalars['String']>
  documentType?: Maybe<QuotationDocumentType>
  expirationDate?: Maybe<Scalars['Date']>
  pdfGenerated: Scalars['Boolean']
  validForDays?: Maybe<Scalars['Int']>
}

export enum QuotationDocumentType {
  PROFORMA = 'PROFORMA',
  QUOTE = 'QUOTE',
}

export enum Role {
  CATALOGUE_CATALOGUES_GET = 'CATALOGUE_CATALOGUES_GET',
  CATALOGUE_CATALOGUES_UPDATE = 'CATALOGUE_CATALOGUES_UPDATE',
  CUSTOMER_CREATE = 'CUSTOMER_CREATE',
  CUSTOMER_CUSTOMERS_CREATE = 'CUSTOMER_CUSTOMERS_CREATE',
  CUSTOMER_CUSTOMERS_LIST = 'CUSTOMER_CUSTOMERS_LIST',
  CUSTOMER_CUSTOMERS_UPDATE = 'CUSTOMER_CUSTOMERS_UPDATE',
  CUSTOMER_DELETE = 'CUSTOMER_DELETE',
  CUSTOMER_GET = 'CUSTOMER_GET',
  CUSTOMER_SELF_LIST = 'CUSTOMER_SELF_LIST',
  CUSTOMER_SELF_UPDATE = 'CUSTOMER_SELF_UPDATE',
  CUSTOMER_UPDATE = 'CUSTOMER_UPDATE',
  ECOMMERCE_ORDERS_CREATE = 'ECOMMERCE_ORDERS_CREATE',
  ECOMMERCE_ORDERS_LIST = 'ECOMMERCE_ORDERS_LIST',
  ECOMMERCE_ORDERS_MANAGE = 'ECOMMERCE_ORDERS_MANAGE',
  ECOMMERCE_ORDERS_MANAGE_INTERNAL = 'ECOMMERCE_ORDERS_MANAGE_INTERNAL',
  ECOMMERCE_ORDERS_SEND_BASKET = 'ECOMMERCE_ORDERS_SEND_BASKET',
  ECOMMERCE_PAYMENTS_MANAGE = 'ECOMMERCE_PAYMENTS_MANAGE',
  ECOMMERCE_PRIVATE_APPLY = 'ECOMMERCE_PRIVATE_APPLY',
  ECOMMERCE_PRIVATE_GET = 'ECOMMERCE_PRIVATE_GET',
  ECOMMERCE_QUOTES_CREATE = 'ECOMMERCE_QUOTES_CREATE',
  ECOMMERCE_QUOTES_MANAGE = 'ECOMMERCE_QUOTES_MANAGE',
  PRODUCT_REVIEWS_LIST = 'PRODUCT_REVIEWS_LIST',
  PRODUCT_REVIEWS_SUBMIT = 'PRODUCT_REVIEWS_SUBMIT',
  PRODUCT_REVIEWS_UPDATE = 'PRODUCT_REVIEWS_UPDATE',
  PUNCHOUT_SESSION_CREATE = 'PUNCHOUT_SESSION_CREATE',
  REVIEW_INTERNAL_GET = 'REVIEW_INTERNAL_GET',
  REVIEW_INTERNAL_UPDATE = 'REVIEW_INTERNAL_UPDATE',
}

export enum SalesChannel {
  COLLABORATOR = 'COLLABORATOR',
  EDI = 'EDI',
  EMAIL = 'EMAIL',
  FAX = 'FAX',
  ONLINE = 'ONLINE',
  PO2GO = 'PO2GO',
  POST = 'POST',
  TELEPHONE = 'TELEPHONE',
}

export enum ShippingChargesType {
  FlatShipping = 'FlatShipping',
  HandlingOnly = 'HandlingOnly',
  Local = 'Local',
  Standard = 'Standard',
}

export type Size = {
  __typename?: 'Size'
  displayName: Scalars['String']
  unit: Scalars['String']
  value: Scalars['String']
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type StockLevelAvailability = {
  __typename?: 'StockLevelAvailability'
  availableToReserveQty: Scalars['Int']
  destinationCountry: Scalars['String']
  item: Scalars['String']
  organizationName?: Maybe<Scalars['String']>
}

export type Tax = {
  __typename?: 'Tax'
  message?: Maybe<Scalars['String']>
  name: Scalars['String']
  rate: Scalars['String']
  shippingTaxed: Scalars['Boolean']
  taxNumberRequired: Scalars['Boolean']
  total?: Maybe<Price>
  value: Scalars['Float']
}

export type TaxInfo = {
  __typename?: 'TaxInfo'
  includedInPrice?: Maybe<Scalars['Boolean']>
  price?: Maybe<Price>
  rate?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['Float']>
}

export type TaxSummary = {
  __typename?: 'TaxSummary'
  group?: Maybe<Array<Scalars['Float']>>
  includedInPrice?: Maybe<Scalars['Boolean']>
  price?: Maybe<Price>
  rate?: Maybe<Scalars['String']>
  type?: Maybe<TaxesType>
  value?: Maybe<Scalars['Float']>
}

export enum TaxesType {
  GOODS = 'GOODS',
  SERVICE = 'SERVICE',
}

export type Tracking = {
  __typename?: 'Tracking'
  number?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export type UnitsOfMeasure = {
  __typename?: 'UnitsOfMeasure'
  displayName: Scalars['String']
  primaryUnitOfMeasure: Scalars['String']
  unitOfMeasure: Scalars['String']
}

export type ValidationError = {
  __typename?: 'ValidationError'
  message: Scalars['String']
}

export enum Warehouse {
  INV_100_Cambridge = 'INV_100_Cambridge',
  INV_101_FG_Burlingame = 'INV_101_FG_Burlingame',
  INV_102_FG_Boston = 'INV_102_FG_Boston',
  INV_103_FG_Amsterdam = 'INV_103_FG_Amsterdam',
  INV_104_FG_Tokyo = 'INV_104_FG_Tokyo',
  INV_300_Shanghai = 'INV_300_Shanghai',
  INV_301_FG_Hangzhou = 'INV_301_FG_Hangzhou',
}

export type _Service = {
  __typename?: '_Service'
  /** The sdl representing the federated service capabilities. Includes federation directives, removes federation types, and includes rest of full schema after schema directives have been applied */
  sdl?: Maybe<Scalars['String']>
}

export type UpdateOnlineOrderMutationVariables = Exact<{
  order: OrderInput
}>

export type UpdateOnlineOrderMutation = {
  __typename?: 'Mutation'
  order: { __typename?: 'Order'; id: string }
}

export type GetOrderOptionsQueryVariables = Exact<{
  orderId: Scalars['String']
}>

export type GetOrderOptionsQuery = {
  __typename?: 'Query'
  delivery?: {
    __typename?: 'Delivery'
    deliveries?: Array<{
      __typename?: 'DeliveryResponseItem'
      assetNumber?: string | null
      courier?: string | null
      scheduledArrivalDate?: string | null
      scheduleShipDate?: string | null
      quantity?: number | null
      availableQtyToReserve?: number | null
      ownCourier?: boolean | null
      deliveryType?: DeliveryMethodType | null
      modeOfTransport?: string | null
      freightItem?: string | null
      shipFromCountry?: string | null
      primaryWarehouse?: Warehouse | null
      serviceLevel?: string | null
    } | null> | null
    charges?: Array<{
      __typename?: 'Charges'
      type: ShippingChargesType
      cost: { __typename?: 'Price'; value: number; currency: Currency }
    } | null> | null
  } | null
}

export type GetOrganisationAddressesQueryVariables = Exact<{
  addressType: Scalars['String']
  addressFusionId?: InputMaybe<Scalars['Long']>
  soldToContactRegistryId?: InputMaybe<Scalars['String']>
  soldToOrganisationRegistryId?: InputMaybe<Scalars['String']>
}>

export type GetOrganisationAddressesQuery = {
  __typename?: 'Query'
  organisationAddresses: Array<{
    __typename?: 'EcomOrganisationAddress'
    addressFusionId: any
    addressLine1?: string | null
    addressLine2?: string | null
    addressLine3?: string | null
    addressLine4?: string | null
    city?: string | null
    state?: string | null
    province?: string | null
    county?: string | null
    postcode?: string | null
    country?: string | null
    organisationName?: string | null
    organisationRegistryId: string
    accountSiteUseFusionId?: string | null
    accountSiteFusionId?: string | null
    accountNumber?: string | null
    customsNumber?: string | null
    incoTerms?: string | null
    primaryOrganisationRegistryId?: string | null
    contactGoodsOutNote?: string | null
    orgGoodsOutNote?: string | null
    invoiceNotes?: string | null
    siteNumbers?: string | null
    sendHardCopyOfDocuments?: boolean | null
    locationNumber?: string | null
    siteNumber?: string | null
    vatNumber?: string | null
  } | null>
}

export type GetOrganisationAddressContactsQueryVariables = Exact<{
  addressType: Scalars['String']
  addressFusionId: Scalars['Long']
  organisationRegistryId: Scalars['String']
  soldToContactRegistryId?: InputMaybe<Scalars['String']>
  soldToOrganisationRegistryId?: InputMaybe<Scalars['String']>
}>

export type GetOrganisationAddressContactsQuery = {
  __typename?: 'Query'
  organisationAddressContacts: Array<{
    __typename?: 'EcomOrganisationAddressContact'
    firstname: string
    lastname?: string | null
    contactRegistryId: string
    emailAddress?: string | null
    primaryPhoneNumber?: string | null
    contactFusionPartyId?: any | null
    emailContactPointId?: any | null
    goodsOutNote?: string | null
    internalCustomerNote?: string | null
  } | null>
}

export type OrganisationsForContactQueryVariables = Exact<{
  contactRegistryId?: InputMaybe<Scalars['String']>
}>

export type OrganisationsForContactQuery = {
  __typename?: 'Query'
  organisationsForContact: Array<{
    __typename?: 'EcomOrganisationForContact'
    organisationRegistryId?: string | null
    organisationName?: string | null
    partnerType?: string | null
  } | null>
}

export const UpdateOnlineOrderDocument = gql`
  mutation updateOnlineOrder($order: OrderInput!)
  @endpoint(name: "ecommerce/graphql") {
    order(order: $order) {
      id
    }
  }
`
export type UpdateOnlineOrderMutationFn = Apollo.MutationFunction<
  UpdateOnlineOrderMutation,
  UpdateOnlineOrderMutationVariables
>

/**
 * __useUpdateOnlineOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOnlineOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnlineOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnlineOrderMutation, { data, loading, error }] = useUpdateOnlineOrderMutation({
 *   variables: {
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUpdateOnlineOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOnlineOrderMutation,
    UpdateOnlineOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateOnlineOrderMutation,
    UpdateOnlineOrderMutationVariables
  >(UpdateOnlineOrderDocument, options)
}
export type UpdateOnlineOrderMutationHookResult = ReturnType<
  typeof useUpdateOnlineOrderMutation
>
export type UpdateOnlineOrderMutationResult =
  Apollo.MutationResult<UpdateOnlineOrderMutation>
export type UpdateOnlineOrderMutationOptions = Apollo.BaseMutationOptions<
  UpdateOnlineOrderMutation,
  UpdateOnlineOrderMutationVariables
>
export const GetOrderOptionsDocument = gql`
  query getOrderOptions($orderId: String!)
  @endpoint(name: "ecommerce/graphql") {
    delivery: getDeliveryOptions(orderId: $orderId) {
      deliveries {
        assetNumber
        courier
        scheduledArrivalDate
        scheduleShipDate
        quantity
        availableQtyToReserve
        ownCourier
        deliveryType
        modeOfTransport
        freightItem
        shipFromCountry
        primaryWarehouse
        serviceLevel
      }
      charges {
        cost {
          value
          currency
        }
        type
      }
    }
  }
`

/**
 * __useGetOrderOptionsQuery__
 *
 * To run a query within a React component, call `useGetOrderOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderOptionsQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetOrderOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrderOptionsQuery,
    GetOrderOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetOrderOptionsQuery, GetOrderOptionsQueryVariables>(
    GetOrderOptionsDocument,
    options
  )
}
export function useGetOrderOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrderOptionsQuery,
    GetOrderOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetOrderOptionsQuery,
    GetOrderOptionsQueryVariables
  >(GetOrderOptionsDocument, options)
}
export type GetOrderOptionsQueryHookResult = ReturnType<
  typeof useGetOrderOptionsQuery
>
export type GetOrderOptionsLazyQueryHookResult = ReturnType<
  typeof useGetOrderOptionsLazyQuery
>
export type GetOrderOptionsQueryResult = Apollo.QueryResult<
  GetOrderOptionsQuery,
  GetOrderOptionsQueryVariables
>
export const GetOrganisationAddressesDocument = gql`
  query getOrganisationAddresses(
    $addressType: String!
    $addressFusionId: Long
    $soldToContactRegistryId: String
    $soldToOrganisationRegistryId: String
  ) @endpoint(name: "ecommerce/graphql") {
    organisationAddresses(
      addressType: $addressType
      addressFusionId: $addressFusionId
      soldToContactRegistryId: $soldToContactRegistryId
      soldToOrganisationRegistryId: $soldToOrganisationRegistryId
    ) {
      addressFusionId
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      city
      state
      province
      county
      postcode
      country
      organisationName
      organisationRegistryId
      accountSiteUseFusionId
      accountSiteFusionId
      accountNumber
      customsNumber
      incoTerms
      primaryOrganisationRegistryId
      contactGoodsOutNote
      orgGoodsOutNote
      invoiceNotes
      siteNumbers
      sendHardCopyOfDocuments
      locationNumber
      siteNumber
      vatNumber
    }
  }
`

/**
 * __useGetOrganisationAddressesQuery__
 *
 * To run a query within a React component, call `useGetOrganisationAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationAddressesQuery({
 *   variables: {
 *      addressType: // value for 'addressType'
 *      addressFusionId: // value for 'addressFusionId'
 *      soldToContactRegistryId: // value for 'soldToContactRegistryId'
 *      soldToOrganisationRegistryId: // value for 'soldToOrganisationRegistryId'
 *   },
 * });
 */
export function useGetOrganisationAddressesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrganisationAddressesQuery,
    GetOrganisationAddressesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetOrganisationAddressesQuery,
    GetOrganisationAddressesQueryVariables
  >(GetOrganisationAddressesDocument, options)
}
export function useGetOrganisationAddressesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganisationAddressesQuery,
    GetOrganisationAddressesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetOrganisationAddressesQuery,
    GetOrganisationAddressesQueryVariables
  >(GetOrganisationAddressesDocument, options)
}
export type GetOrganisationAddressesQueryHookResult = ReturnType<
  typeof useGetOrganisationAddressesQuery
>
export type GetOrganisationAddressesLazyQueryHookResult = ReturnType<
  typeof useGetOrganisationAddressesLazyQuery
>
export type GetOrganisationAddressesQueryResult = Apollo.QueryResult<
  GetOrganisationAddressesQuery,
  GetOrganisationAddressesQueryVariables
>
export const GetOrganisationAddressContactsDocument = gql`
  query getOrganisationAddressContacts(
    $addressType: String!
    $addressFusionId: Long!
    $organisationRegistryId: String!
    $soldToContactRegistryId: String
    $soldToOrganisationRegistryId: String
  ) @endpoint(name: "ecommerce/graphql") {
    organisationAddressContacts(
      addressType: $addressType
      addressFusionId: $addressFusionId
      organisationRegistryId: $organisationRegistryId
      soldToContactRegistryId: $soldToContactRegistryId
      soldToOrganisationRegistryId: $soldToOrganisationRegistryId
    ) {
      firstname
      lastname
      contactRegistryId
      emailAddress
      primaryPhoneNumber
      contactFusionPartyId
      emailContactPointId
      goodsOutNote
      internalCustomerNote
    }
  }
`

/**
 * __useGetOrganisationAddressContactsQuery__
 *
 * To run a query within a React component, call `useGetOrganisationAddressContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationAddressContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationAddressContactsQuery({
 *   variables: {
 *      addressType: // value for 'addressType'
 *      addressFusionId: // value for 'addressFusionId'
 *      organisationRegistryId: // value for 'organisationRegistryId'
 *      soldToContactRegistryId: // value for 'soldToContactRegistryId'
 *      soldToOrganisationRegistryId: // value for 'soldToOrganisationRegistryId'
 *   },
 * });
 */
export function useGetOrganisationAddressContactsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrganisationAddressContactsQuery,
    GetOrganisationAddressContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetOrganisationAddressContactsQuery,
    GetOrganisationAddressContactsQueryVariables
  >(GetOrganisationAddressContactsDocument, options)
}
export function useGetOrganisationAddressContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganisationAddressContactsQuery,
    GetOrganisationAddressContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetOrganisationAddressContactsQuery,
    GetOrganisationAddressContactsQueryVariables
  >(GetOrganisationAddressContactsDocument, options)
}
export type GetOrganisationAddressContactsQueryHookResult = ReturnType<
  typeof useGetOrganisationAddressContactsQuery
>
export type GetOrganisationAddressContactsLazyQueryHookResult = ReturnType<
  typeof useGetOrganisationAddressContactsLazyQuery
>
export type GetOrganisationAddressContactsQueryResult = Apollo.QueryResult<
  GetOrganisationAddressContactsQuery,
  GetOrganisationAddressContactsQueryVariables
>
export const OrganisationsForContactDocument = gql`
  query OrganisationsForContact($contactRegistryId: String)
  @endpoint(name: "ecommerce/graphql") {
    organisationsForContact(contactRegistryId: $contactRegistryId) {
      organisationRegistryId
      organisationName
      partnerType
    }
  }
`

/**
 * __useOrganisationsForContactQuery__
 *
 * To run a query within a React component, call `useOrganisationsForContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationsForContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationsForContactQuery({
 *   variables: {
 *      contactRegistryId: // value for 'contactRegistryId'
 *   },
 * });
 */
export function useOrganisationsForContactQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrganisationsForContactQuery,
    OrganisationsForContactQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    OrganisationsForContactQuery,
    OrganisationsForContactQueryVariables
  >(OrganisationsForContactDocument, options)
}
export function useOrganisationsForContactLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganisationsForContactQuery,
    OrganisationsForContactQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    OrganisationsForContactQuery,
    OrganisationsForContactQueryVariables
  >(OrganisationsForContactDocument, options)
}
export type OrganisationsForContactQueryHookResult = ReturnType<
  typeof useOrganisationsForContactQuery
>
export type OrganisationsForContactLazyQueryHookResult = ReturnType<
  typeof useOrganisationsForContactLazyQuery
>
export type OrganisationsForContactQueryResult = Apollo.QueryResult<
  OrganisationsForContactQuery,
  OrganisationsForContactQueryVariables
>
