import { BaseAddressSchema } from './base'

export const OptionalPostcode = {
  ...BaseAddressSchema,

  postcode: {
    type: 'string',
    required: false,
  },
} as const

export type OptionalPostcode = typeof OptionalPostcode

export default OptionalPostcode
