import { CrossIcon, LogoAbcamWhite } from '@lego/ui/icons'
import {
  CountrySelectorDropdown,
  CountrySelectorPosition,
  CountrySelectorSize,
} from '@lego/ui/country-selector-dropdown'
import { MegaNavMobile } from '../mega-nav/mobile/mega-nav-mobile'
import type { MobileMenuProps } from './mobile-menu.type'
import { MOBILE_MENU_CLOSE_LABEL } from './constants'
import { tags } from '@tagging-shared/browse'
import { getCountryNameByCountryCode } from '@abcam-web/shared/utilities/localisation'
import { useSwitchLocale } from '@abcam-web/shared/utilities/switch-locale'
import { useRouter } from 'next/router'
import Link from 'next/link'
import {
  LocationID,
  getCountrySelectorParameters,
  useGtm,
  getPageType,
  getPageSubType,
} from '@abcam-web/shared/data-access/tracking'
import { useCountry } from '@abcam-web/shared/utilities/country'

const MobileMenu = ({
  onCloseCallback,
  shoppingBasketDropdown,
  hideCountrySelector,
  navigation,
  onLogoClickUrl,
}: MobileMenuProps) => {
  const router = useRouter()
  const { country } = useCountry()
  const { switchLocale } = useSwitchLocale()

  const gtm = useGtm()
  const pathname = router.pathname

  return (
    <div className="fixed top-0 left-0 w-screen h-screen p-4 overflow-y-scroll z-mobileMenu bg-black-0">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-4">
          <div
            className="cursor-pointer"
            data-cy={tags.commonTags.mobileMenuCloseButton}
          >
            <CrossIcon
              className="[&>path]:fill-white"
              aria-label={MOBILE_MENU_CLOSE_LABEL}
              onClick={onCloseCallback}
            />
          </div>
          <Link
            href={onLogoClickUrl || '/'}
            onClick={onCloseCallback}
            aria-label="Homepage link"
          >
            <LogoAbcamWhite
              data-cy={tags.commonTags.abcamLogo}
              data-testid="abcam-logo"
              className="cursor-pointer"
            />
          </Link>
        </div>
        <div className="flex flex-row items-center gap-4">
          <CountrySelectorDropdown
            defaultSelectedCountry={country}
            className={hideCountrySelector ? 'hidden' : ''}
            displayPosition={CountrySelectorPosition.right}
            size={CountrySelectorSize.small}
            withLanguageSelector
            persistSelection
            isMainSiteSwitcher
            onChange={({ country, language }) => {
              gtm.track(
                getCountrySelectorParameters({
                  click_id: 'country-language dropdown',
                  location_id: LocationID.MAIN_NAV,
                  page_path: pathname,
                  page_type: getPageType(pathname),
                  page_subtype: getPageSubType(pathname),
                  item_cta: null,
                  country: getCountryNameByCountryCode(country),
                  destination_path: null,
                  toggle: null,
                  widget: null,
                  country_selection: null,
                  index: null,
                  purchasing_organisations: null,
                  distributor_name: null,
                  drawer_type: null,
                })
              )

              switchLocale(language, country)
            }}
          />

          {shoppingBasketDropdown}
        </div>
      </div>
      {navigation && (
        <MegaNavMobile items={navigation} closeMenuFunction={onCloseCallback} />
      )}
    </div>
  )
}

export { MobileMenu }
