import { getCountrySchema } from '../country-schemas'
import { InputType, validateSchema } from './validate-schema'
import { COUNTRY_CODES } from '../data'

export type AddressInput = {
  countryCode: COUNTRY_CODES
} & InputType

type ValidateAddress = (
  input?: Partial<AddressInput>
) => undefined | { [key: string]: string[] }

export const validateAddress: ValidateAddress = (input) => {
  if (!input || !input.countryCode) {
    return {
      countryCode: ['error-not-supported'],
    }
  }
  const countrySchema = getCountrySchema(input.countryCode)

  if (countrySchema) {
    return validateSchema(countrySchema, input)
  }
  return {
    countryCode: ['error-not-supported'],
  }
}
