import {
  Select,
  SELECT_SIZE,
  SELECT_VARIANT,
  SelectOption,
} from '@lego/ui/select'
import cn from 'classnames'
import type { FC } from 'react'
import { useMemo } from 'react'

import {
  MultipleOrgLocation,
  OrganisationSelectorDropdownProps,
} from './multiple-org-dropdown.type'

const MultipleOrgDropdown: FC<OrganisationSelectorDropdownProps> = ({
  title,
  organisations,
  selectedOrganisationRegistryId,
  location = MultipleOrgLocation.BASKET,
  fullWidth,
  onChange,
}) => {
  const orgOptions: SelectOption[] = useMemo(
    () =>
      (organisations || []).map((organisation) => ({
        key: organisation?.organisationRegistryId || '',
        displayValue: organisation?.organisationName || '',
        isPreselected:
          organisation?.organisationRegistryId ===
          selectedOrganisationRegistryId,
      })),
    [organisations, selectedOrganisationRegistryId]
  )

  const containerStyles = cn(
    'flex mdd:flex-col mdd:gap-[5px] font-semibold text-grey-40 text-body-xmedium',
    {
      'flex-col gap-[5px] px-6 pt-6 pb-[15px]':
        location === MultipleOrgLocation.BUY_BOX,
    }
  )
  const labelStyles = cn('my-auto mx-[5px]', {
    'text-center': location === MultipleOrgLocation.BUY_BOX,
  })
  return (
    <>
      <div className={containerStyles}>
        {!!title && (
          <div className={labelStyles}>
            <span>{title} </span>
          </div>
        )}
        <Select
          variant={
            location === MultipleOrgLocation.CHECKOUT
              ? SELECT_VARIANT.primary
              : SELECT_VARIANT.tertiary
          }
          position="left"
          size={SELECT_SIZE.small}
          fullWidth={fullWidth}
          className="border-stroke-medium text-body-small"
          selectedValueContainerClassName="!py-2 !pl-4 truncate"
          onChange={(option) => {
            onChange(option.displayValue, option.key as string)
          }}
          options={orgOptions}
          disableMaxOption
        />
      </div>
      {location === MultipleOrgLocation.BUY_BOX && (
        <hr className="mx-6 text-lightbox" />
      )}
    </>
  )
}

export { MultipleOrgDropdown }
