import { ChevronRight } from '@abcam-web/lego/ui/icons'
import { useToggle } from '@lego/hooks/toggle'
import { Button } from '@lego/ui/button'
import { Dropdown } from '@lego/ui/dropdown'
import { FC } from 'react'
import { useIntl } from 'react-intl'

enum ItemAction {
  LinePONumber = 'LinePONumber',
  CustomerLineNumber = 'CustomerLineNumber',
  RequestSpecificLot = 'RequestSpecificLot',
  ExcludeSpecificLot = 'ExcludeSpecificLot',
  AllWithinSameLot = 'AllWithinSameLot',
}

interface ItemActionsDropdownProps {
  disabled?: boolean
  onActionClick: (action: ItemAction) => void
}

const ItemActionsDropdown: FC<ItemActionsDropdownProps> = ({
  disabled = false,
  onActionClick,
}) => {
  const { formatMessage } = useIntl()

  const [actionsModal, toggleActionsModal] = useToggle()
  const [lotDropdownOpen, toggleLotDropdown] = useToggle()

  return (
    <Dropdown
      onVisibilityChange={() => {
        if (!disabled) {
          toggleActionsModal()
          if (lotDropdownOpen) {
            toggleLotDropdown()
          }
        }
      }}
      displayPosition="left"
      disabled={disabled}
      parentElement={
        <Button
          variant="tertiaryOutline"
          size="xsmall"
          data-testid="line-actions-button"
          disabled={disabled}
          iconButton={true}
          bodyClassName="mb-1"
        >
          ...
        </Button>
      }
      isOpen={actionsModal}
    >
      <div className="py-2 bg-white rounded shadow-lg space-y-2">
        <Button
          onClick={() => {
            if (!disabled) {
              onActionClick(ItemAction.LinePONumber)
              toggleActionsModal()
            }
          }}
          size="small"
          variant="textOnly"
        >
          {formatMessage({ id: 'itemActionsDropdown.linePoNumber' })}
        </Button>
        <Dropdown
          onVisibilityChange={() => {
            if (!disabled) {
              toggleLotDropdown()
            }
          }}
          displayPosition="left"
          disabled={disabled}
          parentElement={
            <Button
              size="small"
              iconRight={<ChevronRight className="fill-black" />}
              variant="textOnly"
            >
              {formatMessage({ id: 'itemActionsDropdown.lotRequests' })}
            </Button>
          }
          isOpen={lotDropdownOpen}
        >
          <div className="py-2 bg-white rounded shadow-lg space-y-2">
            <Button
              onClick={() => {
                if (!disabled) {
                  onActionClick(ItemAction.RequestSpecificLot)
                  toggleLotDropdown()
                  toggleActionsModal()
                }
              }}
              size="small"
              variant="textOnly"
            >
              {formatMessage({
                id: 'itemActionsDropdown.requestSpecificLot',
              })}
            </Button>
            <Button
              onClick={() => {
                if (!disabled) {
                  onActionClick(ItemAction.ExcludeSpecificLot)
                  toggleLotDropdown()
                  toggleActionsModal()
                }
              }}
              size="small"
              variant="textOnly"
            >
              {formatMessage({
                id: 'itemActionsDropdown.excludeSpecificLot',
              })}
            </Button>
            <Button
              onClick={() => {
                if (!disabled) {
                  onActionClick(ItemAction.AllWithinSameLot)
                  toggleLotDropdown()
                  toggleActionsModal()
                }
              }}
              size="small"
              variant="textOnly"
            >
              {formatMessage({
                id: 'itemActionsDropdown.allWithinSingleLot',
              })}
            </Button>
          </div>
        </Dropdown>
        <Button
          onClick={() => {
            if (!disabled) {
              onActionClick(ItemAction.CustomerLineNumber)
              toggleActionsModal()
            }
          }}
          size="small"
          variant="textOnly"
        >
          {formatMessage({ id: 'itemActionsDropdown.customerLineNumber' })}
        </Button>
      </div>
    </Dropdown>
  )
}

export { ItemAction, ItemActionsDropdown }
