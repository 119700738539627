import { getAuthenticationHost } from '@abcam-web/auth-shared/aws-cognito'
import { STAGE } from '@abcam-web/auth-shared/constants/stage-type'

type UrlInfo = {
  type: 'local' | 'remote'
  url: string
}

function isAuthPath(pathname: string) {
  return pathname.startsWith('/auth/')
}

export function currentlyInMyAccount() {
  return window.location.pathname.startsWith('/my-account')
}

function isCurrentOrigin(url: URL) {
  return url.origin === window.location.origin
}

export function makeAbsolute(pathname: string) {
  return new URL(pathname, window.location.origin).href
}

export function resolvePath(urlOrPath: string): UrlInfo {
  try {
    const url = new URL(urlOrPath) // This causes an exception for relative paths

    // It's an absolute path but it's on the same origin, we can use local navigation
    if (isCurrentOrigin(url) && !isAuthPath(url.pathname)) {
      return { type: 'local', url: url.pathname }
    }

    return { type: 'remote', url: url.href }
  } catch {
    // Auth is a special case: it's a separate application so we treat it as an absolute
    // path even if the requested URL contains only a pathname
    if (isAuthPath(urlOrPath) && !isAuthPath(window.location.pathname)) {
      return {
        type: 'remote',
        url: makeAbsolute(urlOrPath),
      }
    }

    // Accounts app has a base-path set to '/my-account'. We need
    // strip this out before navigating to another app.

    if(currentlyInMyAccount()) {
      return {
        type: 'remote',
        url: makeAbsolute(urlOrPath),
      }
    }

    return { type: 'local', url: urlOrPath }
  }
}

export function resolveLoginUrl(stage: STAGE, redirectTo?: string) {
  const url = `${getAuthenticationHost(stage)}/login`

  if (redirectTo) {
    return `${url}?redirect=${encodeURIComponent(redirectTo)}`
  }

  return url
}
