import { StatusCodes } from 'http-status-codes'

export interface Distributor {
  id: string
  name: string
  email: string
  address: string
  fax?: string
  phone?: string
  website: string
  countries: string[]
  regions?: string[]
}

// adapted from libs/assist/pages/contact-us-page/src/lib/utils/find-distributors.utils.ts
class DistributorApiClient {
  private stage: string
  private baseUrl: string

  constructor(stage: string) {
    this.stage = stage
    this.baseUrl = ['local', 'dev', 'test'].includes(this.stage)
      ? 'https://reference-data-api-branch.dev.abcam.net'
      : `https://reference-data-api.${this.stage}.abcam.net`
  }

  async fetchDistributors(countryCode: string): Promise<Distributor[]> {
    const country = countryCode.toLowerCase()

    try {
      const res = await fetch(`${this.baseUrl}/${country}/${country}.json`)
      if (res.status !== StatusCodes.OK) {
        throw new Error(
          `Received ${res.status} response from distributor api: ${res.statusText}`
        )
      }

      return (await res.json()).map((distributor: Distributor) => ({
        id: distributor.id ?? '',
        name: distributor.name ?? '',
        address: distributor.address ?? '',
        phone: distributor?.phone ?? '',
        fax: distributor?.fax ?? '',
        email: distributor.email ?? '',
        website: distributor.website ?? '',
        countries: distributor.countries,
        regions: distributor.regions ?? [],
      }))
    } catch (err: any) {
      // TODO: Fix the service hosting these files to not return 403 when it's actually a 404
      if (!err.toString().includes('403')) {
        console.error(
          `Could not retrieve distributors for country code: ${country}`,
          err
        )
      }
      return []
    }
  }
}

export const DISTRIBUTOR_API_CLIENT = (stage = 'prod') =>
  new DistributorApiClient(stage)
