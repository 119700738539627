import { getCountryNameByCountryCode } from '@abcam-web/shared/utilities/localisation'
import {
  AssetModel,
  MarketTypeEnum,
} from '@abcam-web/shared/data-access/ecommerce-schema'
import type { ContentType, LinkClasses } from './gtm.enums'
import {
  ClickId,
  CtaText,
  DrawerSubtype,
  DrawerType,
  EventName,
  GTMListType,
  GTMPageType,
  LocationID,
  PageSubtype,
} from './gtm.enums'
import { getDaysToDeliver, getItemSize } from './helpers'
import {
  AnchorLinksParameters,
  BatchConcentrationFinderParameters,
  CancelledPaymentModalParameters,
  CancelledPaymentParametersProps,
  CloseSearchOverlayParameters,
  ContactUsParameters,
  ContentCTAParameters,
  CopyAbIdParameters,
  CountrySelectorParameters,
  CustomerAccountParameters,
  EcommerceField,
  EcommerceFieldParameters,
  EcommerceFields,
  EntityImpressionsParameters,
  EventType,
  FacetsAndSortFields,
  FilterDropdownOpenedParameters,
  FilterSelectedParameters,
  FooterParameters,
  GeolocationParameters,
  GetCardPaymentOutcomeParametersProps,
  GetPlaceOrderParametersProps,
  ImageOverlayParameters,
  LanguageSelectorParameters,
  LozengeClickParameters,
  MegaNavParameters,
  OpenDrawerParameters,
  PaginationParameters,
  SearchOverlayParameters,
  SelectAddToBasketEvent,
  SelectItem,
  SelectOrganisationEvent,
  SelectProductQuantityEvent,
  SelectProductSizeEvent,
  TabClickParameters,
  TrackingEventParameters,
  UserSessionTrackingParameters,
  VirtualPageViewParameters,
  ScrollDepthParameters,
  WebEngagementTrackingEventParameters,
  BuyPdpParameters,
  BuyPdpClickParameters,
} from './gtm.types'

declare global {
  interface Window {
    dataLayer?: {
      push(event: EventType): void
    }
  }
}

export function trackEvents<Events extends EventType | EventType[]>(
  context: Events
): void {
  if (typeof window === 'undefined') {
    return
  }

  window.dataLayer = window.dataLayer || []
  if (Array.isArray(context)) {
    for (const c of context) {
      window.dataLayer.push(c)
    }
  } else {
    window.dataLayer.push(context)
  }
}

const formatProductType = (productType: string | null): string | null => {
  return productType
    ? productType.replace('Asset Definition|Scientific|', '')
    : null
}

export const getViewReviewsContext = (
  productCode: string,
  pagePath: string,
  country?: string
): WebEngagementTrackingEventParameters & {
  drawer_subtype: DrawerSubtype
} => ({
  ...getBaseWebEngagementProperties(),
  click_id: ClickId.ABREVIEWS,
  item_id: productCode,
  page_type: GTMPageType.PDP,
  page_path: pagePath,
  location_id: LocationID.PAGE,
  page_subtype: PageSubtype.OVERVIEW,
  drawer_type: DrawerType.REVIEWS,
  drawer_subtype: DrawerSubtype.REVIEWS_OVERVIEW,
  main_country_selection: country || '',
})

export const getProductTrackingContext = (
  content: ContentType,
  page_type: GTMPageType.FULL_DATASHEET | GTMPageType.QUICK_VIEW,
  productCode: string
): TrackingEventParameters => ({
  event: EventName.COPY_TITLE_CLICK,
  content: content,
  page_type: page_type,
  item_id: productCode,
})

export const getSelectItemContext = (
  productCode: string,
  index: number,
  productName?: string
): TrackingEventParameters => ({
  // 1-based indexing. 1 is first, 2 is second etc
  event: EventName.SELECT_ITEM,
  item_list_name: GTMListType.ONSITE_SEARCH,
  items: [
    {
      item_id: productCode,
      item_name: productName || productCode,
      index,
    },
  ],
})

export const getViewItemsContext = (
  products: SelectItem[]
): TrackingEventParameters => ({
  event: EventName.VIEW_ITEM_LIST,
  item_list_name: GTMListType.ONSITE_SEARCH,
  items: products,
})

export const getSearchFiltersContext = (
  filters: FacetsAndSortFields
): TrackingEventParameters => ({
  event: EventName.VIEW_SEARCH_RESULTS,
  ...filters,
})

export const getBannerTrackingContext = (
  pageType: GTMPageType,
  pageLocation: string,
  link: LinkClasses
): TrackingEventParameters => ({
  event: EventName.BETA_BANNER_LINK_CLICK,
  page_type: pageType,
  pageLocation,
  link,
})

export const getBaseWebEngagementProperties =
  (): WebEngagementTrackingEventParameters => ({
    event: EventName.WEB_ENGAGEMENT,
    page_subtype: '',
    page_path: '',
    drawer_type: '',
    item_cta: '',
    location_id: '',
    click_id: '',
    country: '',
    country_selection: '',
    toggle: '',
    distributor_name: '',
    outbound: '',
    widget: '',
    page_type: '',
    ecommerce_items: [],
    purchasing_organisations: '',
    destination_path: '',
    index: '',
    entity_suggestions: '',
    entity_free_text: null,
    sub_country_selection: '',
    main_country_selection: '',
  })

export const getContactUsContext = (
  parameters: ContactUsParameters
): TrackingEventParameters => {
  const baseProps = getBaseWebEngagementProperties()

  const response = {
    ...baseProps,
    page_type: GTMPageType.CONTACT_US,
    ...parameters,
  }

  return response
}

export const getPdpPageSubType = (asPath: string) => {
  let pageSubType
  if (asPath.includes('#tab=datasheet')) {
    pageSubType = PageSubtype.DATA_SHEET
  } else if (asPath.includes('#tab=support')) {
    pageSubType = PageSubtype.SUPPORT
  } else if (asPath.includes('#tab=images')) {
    pageSubType = PageSubtype.IMAGES
  } else {
    pageSubType = PageSubtype.OVERVIEW
  }
  return pageSubType
}

export const getSearchOverlayPageTypes = (asPath: string) => {
  let pageType
  let pageSubType = ''

  if (asPath.includes('/search')) {
    pageType = GTMPageType.SEARCH
    pageSubType = PageSubtype.SEARCH_RESULTS_PAGE
  } else if (asPath.includes('/products')) {
    pageType = GTMPageType.PDP
    pageSubType = getPdpPageSubType(asPath)
  } else {
    pageType = GTMPageType.CONTENT
  }

  return {
    pageType,
    pageSubType,
  }
}

export const getClickFullProductInfoContext = ({
  page_path,
  destination_path,
  drawer_subtype,
  product_type,
  item_code,
}: {
  drawer_subtype: DrawerSubtype
  page_path: string
  destination_path: string
  product_type: string
  item_code: string
}) => ({
  ...getBaseWebEngagementProperties(),
  event: EventName.LINK_CLICK,
  click_id: 'Full product info',
  page_type: GTMPageType.SEARCH,
  page_subtype: PageSubtype.QUICK_VIEW,
  page_path,
  item_cta: 'full product info',
  outbound: false,
  location_id: 'drawer',
  distributor_name: '',
  drawer_type: DrawerType.QUICK_VIEW,
  drawer_subtype,
  destination_path,
  item_url: '',
  item_code,
})

export const getClickDirectToPdpFromCard = ({
  page_path,
  destination_path,
  product_type,
  click_id,
}: {
  page_path: string
  destination_path: string
  product_type: string
  click_id: string
}) => ({
  ...getBaseWebEngagementProperties(),
  page_type: GTMPageType.PDP,
  event: EventName.LINK_CLICK,
  click_id,
  page_subtype: PageSubtype.OVERVIEW,
  page_path,
  item_cta: 'full product info',
  outbound: false,
  location_id: 'drawer',
  distributor_name: '',
  destination_path,
  item_url: '',
  drawer_subtype: '',
  product_type,
})

export const getClickEntityPageTabContext = ({
  click_id,
  destination_path,
  page_path,
  item_cta,
  main_country_selection,
}: {
  click_id: 'overview tab' | 'products tab'
  destination_path: string
  page_path: string
  item_cta: 'Overview' | 'Products'
  main_country_selection: string
}) => ({
  ...getBaseWebEngagementProperties(),
  event: EventName.WEB_ENGAGEMENT,
  location_id: LocationID.PAGE,
  page_type: GTMPageType.ENTITY,
  click_id,
  destination_path,
  page_path,
  item_cta,
  main_country_selection,
})

/**
 * Generates ecommerce-related fields for add to basket web engagement event.
 * @param asset - The asset to be added.
 * @param basketId - The ID of the basket to which the asset is being added.
 * @param itemsInCart - The total number of items currently in the basket.
 * @param quantity - The quantity of the asset being added to the basket.
 * @param marketType - Indicates what market is the user in direct, mixed or distributor market.
 * @param freeText - free text user provided via quick add.
 * @param order - The order associated with the basket if available.
 * @returns An object containing ecommerce-related fields for the given asset.
 */
export const getEcommerceField = ({
  asset,
  basketId,
  quantity,
  itemsInCart,
  marketType,
  freeText,
  order,
}: EcommerceFieldParameters): EcommerceField => {
  const ecommerce: EcommerceField = {
    basket_id: basketId || '',
    item_id: '',
    price: -1,
    quantity: quantity || -1,
    item_size: '',
    freeText: freeText || '',
    // TODO: check if we can get this value on buy box
    coupon: '',
    discount: -1,
    items_in_stock: -1,
    days_to_deliver: -1,
    items_in_cart: -1,
    large_quantity_order: 'N/A',
    large_value_order: 'N/A',
    market_type: marketType || '',
    // TODO: check if we can get this value on buy box
    item_category: '',
    item_category2: '',
    item_category3: '',
    // TODO: where do we get this value from
    partner_type: '',
    currency: '',
    product_availability: '',
  }
  const {
    prices,
    assetNumber,
    publicAssetCode,
    assetDefinitionNumber,
    size,
    dates,
    availableQuantity,
  } = asset
  if (typeof itemsInCart === 'number') {
    ecommerce.items_in_cart = itemsInCart
  }

  if (
    prices &&
    prices.unitPriceWithoutDiscount &&
    prices.unitPriceWithoutDiscount.value
  ) {
    ecommerce.discount =
      prices?.unitPriceWithoutDiscount.value -
      (prices.unitPriceWithDiscount?.value || 0)
    ecommerce.currency = prices.unitPriceWithoutDiscount.currency
    ecommerce.price =
      prices.unitPriceWithDiscount?.value ||
      prices.unitPriceWithoutDiscount?.value
  }

  const assetId = assetNumber || publicAssetCode || assetDefinitionNumber

  if (typeof assetId === 'string') {
    ecommerce.item_id = assetId.split('-')[0]
  }

  if (size && size.value) {
    ecommerce.item_size = getItemSize(size)
  }

  if (availableQuantity) {
    ecommerce.items_in_stock = availableQuantity
  }

  if (dates?.scheduledArrivalDate) {
    ecommerce.days_to_deliver = getDaysToDeliver(
      new Date(dates.scheduledArrivalDate)
    )
  }

  if (typeof order?.meta?.largeQuantityOrder === 'boolean') {
    ecommerce.large_quantity_order = order.meta.largeQuantityOrder
  }

  if (typeof order?.meta?.largeValueOrder === 'boolean') {
    ecommerce.large_value_order = order.meta.largeValueOrder
  }

  ecommerce.product_availability = asset.availableQuantity
    ? 'available'
    : 'unavailable'

  return ecommerce
}

export type EcommerceBaseParameters = Omit<
  EcommerceFieldParameters,
  'asset'
> & {
  assets: AssetModel[]
}

export function getEcommerceBaseProps({
  assets,
  ...ecommerceParameters
}: EcommerceBaseParameters): WebEngagementTrackingEventParameters & {
  ecommerce_items: EcommerceFields
} {
  const baseProps = getBaseWebEngagementProperties()
  const ecommerce_items: EcommerceFields = Array.isArray(assets)
    ? assets.map((asset) =>
        getEcommerceField({
          asset,
          ...ecommerceParameters,
        })
      )
    : []
  return { ...baseProps, ecommerce_items }
}

export const getSelectAddToBasketParameters = ({
  location_id,
  page_subtype,
  page_type,
  page_path,
  ecommerceParameters,
  country,
  purchasingOrganisations,
}: {
  location_id: LocationID
  page_subtype: string
  page_type: GTMPageType | ''
  page_path: string
  ecommerceParameters: EcommerceBaseParameters
  country: string
  purchasingOrganisations: string
}): SelectAddToBasketEvent => {
  return {
    ...getEcommerceBaseProps(ecommerceParameters),
    click_id: ClickId.ADD_TO_BASKET,
    item_cta:
      ecommerceParameters.marketType === MarketTypeEnum.Distributor
        ? CtaText.ADD_TO_INQUIRY_BASKET
        : CtaText.ADD_TO_BASKET,
    page_subtype: page_subtype || '',
    location_id: location_id || '',
    page_type: page_type || '',
    page_path: page_path || '',
    country: country || '',
    sub_country_selection: country || '',
    purchasing_organisations: purchasingOrganisations || '',
  }
}

export const getSelectProductSizeParameters = ({
  location_id,
  page_subtype,
  page_type,
  page_path,
  ecommerceParameters,
  country,
  purchasingOrganisations,
  productCode,
}: {
  location_id: LocationID
  page_subtype: SelectProductSizeEvent['page_subtype']
  page_type: SelectProductSizeEvent['page_type']
  page_path: string
  ecommerceParameters: EcommerceBaseParameters
  country: string
  purchasingOrganisations: string
  productCode?: string
}): SelectProductSizeEvent => {
  return {
    ...getEcommerceBaseProps(ecommerceParameters),
    click_id: ClickId.ITEM_SIZE,
    item_cta: getItemSize(ecommerceParameters.assets?.[0]?.size),
    page_subtype: page_subtype || '',
    location_id: location_id || '',
    page_type: page_type || '',
    page_path: page_path || '',
    country: country || '',
    purchasing_organisations: purchasingOrganisations || '',
    item_id: productCode || '',
  }
}

export const getSelectProductQuantityParameters = ({
  location_id,
  page_subtype,
  page_type,
  page_path,
  ecommerceParameters,
  country,
  purchasingOrganisations,
  productCode,
}: {
  location_id: SelectProductQuantityEvent['location_id']
  page_subtype: SelectProductQuantityEvent['page_subtype']
  page_type: SelectProductQuantityEvent['page_type']
  page_path: string
  ecommerceParameters: EcommerceBaseParameters
  country: string
  purchasingOrganisations: string
  productCode?: string
}): SelectProductQuantityEvent => {
  return {
    ...getEcommerceBaseProps(ecommerceParameters),
    click_id: ClickId.QUANTITY,
    item_cta: `${ecommerceParameters.quantity}`,
    page_subtype: page_subtype || '',
    location_id: location_id || '',
    page_type: page_type || '',
    page_path: page_path || '',
    country: country || '',
    purchasing_organisations: purchasingOrganisations || '',
    item_id: productCode || '',
  }
}

export const getSelectPurchasingOrganisationParameters = ({
  location_id,
  page_subtype,
  page_type,
  page_path,
  ecommerceParameters,
  country,
  purchasingOrganisations,
}: {
  location_id: SelectOrganisationEvent['location_id']
  page_subtype: SelectOrganisationEvent['page_subtype']
  page_type: SelectOrganisationEvent['page_type']
  page_path: string
  ecommerceParameters: EcommerceBaseParameters
  country: string
  purchasingOrganisations: string
}): SelectOrganisationEvent => {
  return {
    ...getEcommerceBaseProps(ecommerceParameters),
    click_id: ClickId.SELECT_PURCHASING_ORGANISATION,
    item_cta: `${purchasingOrganisations}`,
    page_subtype: page_subtype || '',
    location_id: location_id || '',
    page_type: page_type || '',
    page_path: page_path || '',
    country: country || '',
    purchasing_organisations: purchasingOrganisations || '',
  }
}

export const getPlaceOrderParameters = ({
  paymentMethod,
  merchant,
  countryName,
  item_cta,
  billToCountry,
  shipToCountry,
  basketId,
  itemsInCart,
  largeQuantityOrder,
  largeValueOrder,
  marketType,
  partnerType,
  orderId,
  orderNumber,
  itemsTotal,
  shippingAndHandling,
  tax,
  orderTotal,
  numberOfShipments,
}: GetPlaceOrderParametersProps) => ({
  payment_option: paymentMethod,
  merchant: merchant ?? '',
  main_country_selection: countryName,
  click_id: 'Place order',
  location_id: LocationID.PAGE,
  item_cta,
  event: EventName.PLACE_ORDER,
  page_type: GTMPageType.CHECKOUT,
  page_subtype: PageSubtype.REVIEW_AND_PAY,
  billToCountry: getCountryNameByCountryCode(billToCountry),
  shipToCountry: getCountryNameByCountryCode(shipToCountry),
  ecommerce_items: [
    {
      basket_id: basketId,
    },
  ],
  items_in_cart: itemsInCart,
  large_quantity_order: largeQuantityOrder,
  large_value_order: largeValueOrder,
  market_type: marketType,
  partner_type: partnerType,
  order_id: orderId,
  order_number: orderNumber,
  items_total: itemsTotal,
  shipping_and_handling: shippingAndHandling,
  tax,
  order_total: orderTotal,
  number_of_shipments: numberOfShipments,
})

export const getCardPaymentOutcomeParameters = ({
  basketId,
  decision,
  reasonCode,
  merchant,
  billToCountry,
  countryName,
  isMyAccountFlow,
}: GetCardPaymentOutcomeParametersProps) => ({
  basket_id: basketId,
  card_transaction_response_decision: decision,
  card_transaction_response_reason_code: reasonCode,
  merchant,
  billToCountry: getCountryNameByCountryCode(billToCountry),
  main_country_selection: countryName,
  event: EventName.CARD_PAYMENT_OUTCOME,
  page_type: GTMPageType.CHECKOUT,
  page_subtype: PageSubtype.PAYMENT_PROCESSING,
  location_id: LocationID.PAGE,
  payment_flow: isMyAccountFlow ? 'my-account' : 'online',
})

const getCommonShowCancelledModalParameters = ({
  merchant,
  countryName,
  billToCountry,
  shipToCountry,
  basketId,
}: CancelledPaymentParametersProps) => {
  return {
    event: EventName.MODAL,
    modal_name: 'cancelled_card_payment_modal',
    merchant,
    billToCountry: getCountryNameByCountryCode(billToCountry),
    shipToCountry: getCountryNameByCountryCode(shipToCountry),
    basket_id: basketId,
    main_country_selection: countryName,
  }
}

export const getShowCancelledPaymentModalParameters = ({
  merchant,
  countryName,
  billToCountry,
  shipToCountry,
  basketId,
}: CancelledPaymentParametersProps): CancelledPaymentModalParameters => ({
  ...getCommonShowCancelledModalParameters({
    merchant,
    countryName,
    billToCountry,
    shipToCountry,
    basketId,
  }),
  modal_action: 'displayed',
})

export const getCancelledPaymentModalReviewClickedParameters = ({
  merchant,
  countryName,
  billToCountry,
  shipToCountry,
  basketId,
}: CancelledPaymentParametersProps): CancelledPaymentModalParameters => ({
  ...getCommonShowCancelledModalParameters({
    merchant,
    countryName,
    billToCountry,
    shipToCountry,
    basketId,
  }),
  modal_action: 'review clicked',
  destination_path: `/checkout/${basketId}`,
  item_cta: 'Review your order',
})

export const getCancelledPaymentModalCancelClickedParameters = ({
  merchant,
  countryName,
  billToCountry,
  shipToCountry,
  basketId,
}: CancelledPaymentParametersProps): CancelledPaymentModalParameters => ({
  ...getCommonShowCancelledModalParameters({
    merchant,
    countryName,
    billToCountry,
    shipToCountry,
    basketId,
  }),
  modal_action: 'cancel clicked',
  destination_path: `/shopping-basket/${basketId}`,
  item_cta: 'Cancel and go to basket',
})

export const getVirtualPageViewParameters = ({
  pageUrl,
  pageTitle,
  pagePath,
  pageType,
  item_name,
  item_code,
  product_type,
}: {
  pageUrl: string
  pageTitle: string
  pagePath: string
  pageType: GTMPageType | ''
  item_name: string
  item_code: string
  product_type: null | string
}): VirtualPageViewParameters => ({
  event: EventName.VIRTUAL_PAGEVIEW,
  pageUrl,
  pageTitle,
  page_path: pagePath,
  page_type: pageType,
  page_subtype: null,
  item_name,
  item_code,
  product_type: formatProductType(product_type),
})

export const getScrollDepthParameters = ({
  scrollThreshold,
  pagePath,
  totalHeight,
  scrollPosition,
}: {
  scrollThreshold: number
  pagePath: string
  totalHeight: number
  scrollPosition: number
}): ScrollDepthParameters => ({
  event: EventName.VIRTUAL_SCROLL_DEPTH,
  scroll_threshold: scrollThreshold,
  page_path: pagePath,
  scroll_position: scrollPosition,
  total_height: totalHeight,
})

export const getTabClickContext = (
  parameters: TabClickParameters
): WebEngagementTrackingEventParameters => ({
  ...getBaseWebEngagementProperties(),
  ...parameters,
})

export const getLozengeClickContext = (
  parameters: LozengeClickParameters
): WebEngagementTrackingEventParameters => ({
  ...getBaseWebEngagementProperties(),
  ...parameters,
  page_type: GTMPageType.PDP,
  page_subtype: PageSubtype.OVERVIEW,
  click_id: 'lozenge',
  location_id: LocationID.PAGE,
})

export const getPdpBuyButtonContext = (parameters: BuyPdpParameters) => ({
  ...getBaseWebEngagementProperties(),
  click_id: 'show sticky buy button',
  ...parameters,
})

export const getPdpBuyButtonClickContext = (
  parameters: BuyPdpClickParameters
) => ({
  ...getBaseWebEngagementProperties(),
  click_id: 'sticky buy button',
  ...parameters,
})

export const getSearchOverlayContext = (
  parameters: SearchOverlayParameters
) => ({
  ...getBaseWebEngagementProperties(),
  ...parameters,
})

export const getCloseSearchOverlayContext = (
  parameters: CloseSearchOverlayParameters
) => ({
  ...getBaseWebEngagementProperties(),
  ...parameters,
  click_id: 'Exited search',
  location_id: LocationID.OVERLAY,
  item_cta: 'Close',
})

export const getEntityImpressionsContext = (
  parameters: EntityImpressionsParameters
) => ({
  ...getBaseWebEngagementProperties(),
  ...parameters,
  click_id: 'search entity click',
  location_id: LocationID.OVERLAY,
  page_type: GTMPageType.SEARCH,
  page_subtype: PageSubtype.OVERLAY,
})

export const getOpenDrawerParameters = ({
  ...parameters
}: OpenDrawerParameters) => {
  return {
    ...parameters,
    event: EventName.OPEN_DRAWER,
    product_type: formatProductType(parameters.product_type),
  }
}

export const getCopyAbIdContext = (parameters: CopyAbIdParameters) => ({
  ...getBaseWebEngagementProperties(),
  ...parameters,
  click_id: 'copy abId',
  item_cta: 'Click to copy',
})

export const getCopyProductNameContext = (parameters: CopyAbIdParameters) => ({
  ...getBaseWebEngagementProperties(),
  ...parameters,
  click_id: 'copy product name',
  item_cta: 'Click to copy',
})

export const getFilterDropdownOpenedContext = ({
  filterType,
  ...parameters
}: FilterDropdownOpenedParameters) => ({
  ...getBaseWebEngagementProperties(),
  ...parameters,
  click_id: `${filterType} dropdown opened`,
})

export const getFilterSelectedContext = ({
  filterType,
  ...parameters
}: FilterSelectedParameters) => ({
  ...getBaseWebEngagementProperties(),
  ...parameters,
  click_id: `${filterType} filter dropdown selected`,
})

export const getImageOverlayContext = (parameters: ImageOverlayParameters) => ({
  ...getBaseWebEngagementProperties(),
  ...parameters,
  click_id: 'image overlay',
})

export const getBatchConcentrationFinderContext = (
  parameters: BatchConcentrationFinderParameters
) => ({
  ...getBaseWebEngagementProperties(),
  ...parameters,
  click_id: 'batch concentration finder',
  location_id: LocationID.PAGE,
  page_type: GTMPageType.PDP,
  page_subtype: PageSubtype.SUPPORT,
  item_cta: 'Search',
})

export const getPaginationContext = (parameters: PaginationParameters) => ({
  ...getBaseWebEngagementProperties(),
  ...parameters,
  click_id: 'pagination',
})

export const getMegaNavClickContext = (parameters: MegaNavParameters) => ({
  ...parameters,
  event: EventName.LINK_CLICK,
})

export const getCustomerAccountParameters = ({
  click_id,
  error_id,
  error_type,
  page_subtype,
  destination_path,
  page_path,
  location_id,
  enroll_in_marketing,
  country_name,
  contact_registry_id,
  event,
}: {
  click_id: string
  error_id?: string
  error_type?: string
  page_subtype: PageSubtype.LOGIN | PageSubtype.REGISTER
  destination_path: string
  page_path: string
  location_id: LocationID | '' | null
  enroll_in_marketing: string
  country_name: string
  contact_registry_id: string
  event: EventName
}): CustomerAccountParameters => {
  return {
    click_id,
    error_id: error_id || '',
    error_type: error_type || '',
    page_type: GTMPageType.ACCOUNT,
    page_subtype,
    location_id,
    destination_path,
    page_path,
    enroll_in_marketing,
    main_country_selection: country_name,
    contact_registry_id,
    event,
  }
}

export const getFooterClickContext = (parameters: FooterParameters) => ({
  ...parameters,
  event: EventName.LINK_CLICK,
})

export const getCountrySelectorParameters = (
  parameters: CountrySelectorParameters
) => ({
  ...parameters,
  event: EventName.WEB_ENGAGEMENT,
})

export const getLanguageSelectorParameters = (
  parameters: LanguageSelectorParameters
) => ({
  ...parameters,
  event: EventName.WEB_ENGAGEMENT,
})

export const getAnchorLinksParameters = (
  parameters: AnchorLinksParameters
) => ({
  ...parameters,
  event: EventName.WEB_ENGAGEMENT,
})

export const getContentCTAParameters = (parameters: ContentCTAParameters) => ({
  ...parameters,
  event: EventName.LINK_CLICK,
})

export const getUserSessionParameters = (
  parameters: UserSessionTrackingParameters
) => ({
  ...parameters,
})

export const getCheckoutEngagementParameters = ({
  click_id,
  item_cta,
  country_name,
  page_path,
  page_subtype,
  basket_id,
}: {
  click_id: ClickId
  item_cta: string
  country_name: string
  page_path: string
  page_subtype: PageSubtype
  basket_id: string
}) => {
  return {
    ...getBaseWebEngagementProperties(),
    click_id,
    item_cta,
    location_id: LocationID.PAGE,
    page_type: GTMPageType.CHECKOUT,
    page_subtype,
    page_path: page_path || '',
    country: country_name,
    main_country_selection: country_name,
    ecommerce_items: [
      {
        basket_id,
      },
    ],
  }
}

export const getGeolocationParameters = (
  parameters: GeolocationParameters
) => ({
  ...parameters,
  event: EventName.WEB_ENGAGEMENT,
})
