export enum ResourcePageTypes {
  AboutUs = 'About us',
  Careers = 'Careers',
  ContactUs = 'Contact us',
  LearningGuide = 'Learning guide',
  Legal = 'Legal',
  MarkerSelectionGuide = 'Marker selection guide',
  OffersInitiatives = 'Offers and initiatives',
  Pathways = 'Pathways',
  ProductOverviews = 'Product overviews',
  Protocols = 'Protocols',
  Support = 'Support',
  Troubleshooting = 'Troubleshooting',
}
