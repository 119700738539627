import { AssetModel } from '@abcam-web/shared/data-access/ecommerce-schema'
import { ContentLoader } from '@lego/ui/content-loader'
import { FC, useMemo } from 'react'

import {
  AssetMultiplePrices,
  AssetMultiplePricesProps,
} from './asset-multiple-prices'
import { AssetSinglePrice, AssetSinglePriceProps } from './asset-single-price'

export interface AssetPriceProps {
  isLoading: boolean
  quantity: number
  country: string
  prices?: AssetModel['prices']
}

export const countryVariant: Record<
  string,
  FC<AssetMultiplePricesProps & AssetSinglePriceProps>
> = {
  JP: AssetMultiplePrices,
  default: AssetSinglePrice,
}

export const AssetPrice: FC<AssetPriceProps> = ({
  isLoading,
  quantity,
  country,
  prices,
}) => {
  const PriceVariant = useMemo(
    () =>
      countryVariant[country?.toLocaleUpperCase()] || countryVariant.default,
    [country]
  )

  return (
    <ContentLoader
      isLoading={isLoading}
      height="30"
      width="150"
      className="flex items-center justify-center mt-3"
      innerClassName="rounded-lg"
      uniqueKey="stock-loader3"
    >
      <PriceVariant prices={prices} quantity={quantity} />
    </ContentLoader>
  )
}
