export const srp = {
  sliderImageContainer: 'slider-image-container',
  sliderImage: 'slider-image',
  quickView: 'quickview',
  imageInfoTitle: 'image-info-title',
  searchTitle: 'search-title',
  reactiveSpeciesColumnText: 'reactive-species-column-text',
  sortingTab: 'srp-sorting-tab',
  productGridRow: 'product-grid-row',
  assayTimeColumnText: 'assay-time-column-text',
  generalRangeColumnText: 'general-range-column-text',
  conjugationColumnText: 'conjugation-column-text',
  detectionMethodColumnText: 'detection-method-column-text',
  applicationColumnText: 'application-column-text',
  sampleTypeColumnText: 'sample-type-column-text',
  assayPlatformColumnText: 'assay-platform-column-text',
  speciesOfOriginColumnText: 'species-of-origin-column-text',
  speciesOrOrganismColumnText: 'species-or-organism-column-text',
  natureColumnText: 'nature-column-text',
  expressionSystemColumnText: 'expression-system-column-text',
  hostSpeciesColumnText: 'host-species-column-text',
  biologicalActionColumnText: 'biological-action-column-text',
  solubilityColumnText: 'solubility-column-text',
  formColumnText: 'form-column-text',
  assayTypeColumnText: 'assay-type-column-text',
  cellTypeColumnText: 'cell-type-column-text',
  targetColumnText: 'target-column-text',
  allFilterButton: 'all-filter-button',
  filterDrawerBody: 'filter-drawer-body',
  categoryDropdownListItem: 'categoryType-select-list-item',
  categorySelectName: 'categoryType--name',
  sortDrawerHeading: 'sort-drawer-heading',
  sortDrawerToggleButton: 'sort-drawer-toggle-button',
  sortDrawerOption: 'sort-drawer-option',
  sortAscArrow: 'sort-asc-arrow',
  sortDescArrow: 'sort-desc-arrow',
  sortDropdownBtn: 'sort-dropdown-btn',
  sortDropdownOption: 'sort-dropdown-option',
  sortByReviewsText: 'Star rating',
  resultsSpinner: '.animate-spin',
  drawerFullProductInfoText: 'Full product info',
  resourceHighlightsTag: 'resource-highlights-tag',
}
