import getConfig from 'next/config'

import { isToggleEnabled } from '@feature-toggles/is-toggle-enabled.predicate'
import { ExternalFeatureToggleBootstrapable } from './external-feature-toggles/external-feature-toggle-bootstrapable'
import { LocalFeatureToggleBootstrapable } from './local-feature-toggles/local-feature-toggle-bootstrapable'

import type { FeatureToggleService as FeatureToggleServiceInterface } from '@feature-toggles/interfaces/feature-toggle.interface'
import type { Bootstrapable } from '@feature-toggles/interfaces/bootstrapable'

export class FeatureToggleServer
  implements Bootstrapable<FeatureToggleServiceInterface>
{
  public readonly featureToggleService = this.localFeatureTogglesEnabled
    ? new LocalFeatureToggleBootstrapable()
    : new ExternalFeatureToggleBootstrapable()

  constructor(
    private localFeatureTogglesEnabled = isToggleEnabled(
      getConfig()?.publicRuntimeConfig.FEATURE_TOGGLES_LOCAL_SERVICE_ENABLED
    )
  ) {}

  public bootstrap(): FeatureToggleServiceInterface {
    return this.featureToggleService.bootstrap()
  }

  public teardown() {
    return this.featureToggleService.teardown()
  }
}

export const featureToggleService = new FeatureToggleServer()
