import type { ReactNode } from 'react'
import { createContext, useContext, useState, useEffect } from 'react'
import type { Country } from './country.type'
import {
  getCountryFromCookie,
  getCountryNameByCountryCode,
} from '@abcam-web/shared/utilities/localisation'

function SetDefaultCountry() {
  /**
   * Runs client-side, set the site's active country from the "NEXT_COUNTRY" cookie.
   */
  const { loadCountry } = useCountry()

  useEffect(() => {
    loadCountry()
  }, [])

  // Intentionally returning null – we are only interested in this component setting the default country.
  return null
}

export const CountryContext = createContext<Country | undefined>(undefined)

export const CountryProvider = ({
  children,
  initialCountry,
  countries,
  ...otherProps
}: {
  children: ReactNode
  initialCountry: string
  countries: string[]
}): React.ReactElement => {
  const [state, setState] = useState({
    countries,
    country: initialCountry,
    countryName: getCountryNameByCountryCode(initialCountry),
    countryIsLoaded: false,
  })

  /**
   * Loads the country code from the cookie
   * This is called once from the client-side only in the SetDefaultCountry component above
   *
   * @returns country code
   */
  const loadCountry = (): string => {
    const country = getCountryFromCookie()

    if (country && country !== state.country) {
      setState({
        ...state,
        countryIsLoaded: true,
        country,
        countryName: getCountryNameByCountryCode(country),
      })
      return country
    } else {
      // Don't override the country to preserve the original initialCountry value
      setState({
        ...state,
        countryIsLoaded: true,
      })
    }

    return state.country
  }

  const setCountry = (country: string) => {
    setState({
      ...state,
      country,
      countryName: getCountryNameByCountryCode(country),
    })
  }

  const value: Country = {
    ...state,
    loadCountry,
    setCountry,
  }

  return (
    <CountryContext.Provider value={value} {...otherProps}>
      {/*
        Country is an intentionally client-side piece of state.

        We cannot fetch the country cookie on the server-side, it will have to be
        passed as props via getInitialProps and this will pollute the CDN cache.

        The next visitor to this specific page will now have the preset country
        value which could be incorrect.
      */}
      <SetDefaultCountry />
      {children}
    </CountryContext.Provider>
  )
}

export function useCountry(): Country {
  const value = useContext(CountryContext)
  if (value === undefined) throw new Error('no value provided')
  return value
}
