import { useState, useEffect, useRef, useCallback } from 'react'

export const useSticky = <T extends HTMLElement>() => {
  const [isSticky, setIsSticky] = useState(false)

  const stickyRef = useRef<T>(null)

  const toggleSticky = useCallback(
    (rectangle?: DOMRect) => {
      if (rectangle?.top === 0) {
        !isSticky && setIsSticky(true)
      } else {
        isSticky && setIsSticky(false)
      }
    },
    [isSticky]
  )

  useEffect(() => {
    const handleScroll = () => {
      toggleSticky(stickyRef.current?.getBoundingClientRect())
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [toggleSticky])

  return { stickyRef, isSticky }
}
