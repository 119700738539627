import { useContext } from 'react'
import { ProductOverviewContext } from '../contexts/product-overview.context'

export function useProductOverview() {
  const { productOverview } = useContext(ProductOverviewContext)

  return {
    productOverview,
  }
}
