import { Spinner } from '@lego/ui/icons'
import { Button, ButtonProps } from '@lego/ui/button'
import { FormContext } from '../../form/formContext'
import { useContext } from 'react'

const SubmitButton = ({
  type = 'submit',
  size = 'small',
  children,
  ...rest
}: ButtonProps) => {
  const { isSubmitting } = useContext(FormContext)

  return (
    <Button
      iconRight={isSubmitting && <Spinner className={'animate-spin'} />}
      disabled={isSubmitting}
      type={type}
      size={size}
      {...rest}
    >
      {children}
    </Button>
  )
}

export { SubmitButton }
