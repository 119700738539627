import {
  EventName,
  getUserSessionParameters,
  LocationID,
} from '@abcam-web/shared/data-access/tracking'

const USER_SESSION_TRACKING_KEY = 'userSessionTracking'
const THIRTY_MINUTES_IN_MS = 1000 * 60 * 30

/**
 * Send a user_session_started event to GTM when a session starts
 * Don't send another event in 30 minutes.
 * @param contact_registry_id
 * @param track
 */
export function trackUserSessionStartedEvent(
  contact_registry_id: string,
  track: (event: any) => void
) {
  try {
    if (typeof window !== 'undefined' && contact_registry_id) {
      let sendTrackingEvent = false

      if (localStorage.getItem(USER_SESSION_TRACKING_KEY)) {
        const existingTracking = JSON.parse(
          localStorage.getItem(USER_SESSION_TRACKING_KEY)!
        )
        const isWithin30Mins =
          existingTracking.lastActiveAt + THIRTY_MINUTES_IN_MS > Date.now()
        if (!isWithin30Mins) {
          sendTrackingEvent = true
        }
      } else {
        sendTrackingEvent = true
      }

      if (sendTrackingEvent) {
        const event = getUserSessionParameters({
          event: EventName.USER_SESSION_STARTED,
          location_id: LocationID.PAGE,
          contact_registry_id,
        })
        track(event)
      }

      localStorage.setItem(
        USER_SESSION_TRACKING_KEY,
        JSON.stringify({
          contact_registry_id,
          lastActiveAt: Date.now(),
        })
      )
    }
  } catch (err) {
    console.error('Error while tracking user', err)
  }
}

/**
 * Send a user_session_logged_out to gtm when user logged out.
 * Also remove related key from local storage
 * @param track
 */
export function trackUserSessionLoggedOutEvent(track: (event: any) => void) {
  try {
    if (localStorage.getItem(USER_SESSION_TRACKING_KEY)) {
      const existingTracking = JSON.parse(
        localStorage.getItem(USER_SESSION_TRACKING_KEY)!
      )

      const event = getUserSessionParameters({
        event: EventName.USER_SESSION_LOGGED_OUT,
        location_id: LocationID.PAGE,
        contact_registry_id: existingTracking.contact_registry_id,
      })

      track(event)
      localStorage.removeItem(USER_SESSION_TRACKING_KEY)
    }
  } catch (err) {
    console.error('Error while tracking user', err)
  }
}
