import { COUNTRY_CODES } from '.'
import { POSTCODE_REGEXES } from './postcode-regexes'

const restrictedCountries: {
  code: keyof typeof POSTCODE_REGEXES
  name: string
}[] = [
  { code: 'IR', name: 'Iran' },
  { code: 'CU', name: 'Cuba' },
  { code: 'SD', name: 'Sudan' },
  { code: 'KP', name: 'North Korea' },
  { code: 'IQ', name: 'Iraq' },
  { code: 'MM', name: 'Burma' },
  { code: 'LR', name: 'Liberia' },
  { code: 'SO', name: 'Somalia' },
  { code: 'VE', name: 'Venezuela' },
  // the following countries are not included in the postcode regexes already
  // { code: 'SY', name: 'Syria' },
  // { code: 'CD', name: 'Democratic Republic of the Congo' },
  // { code: 'LY', name: 'Libya' },
  // { code: 'YE', name: 'Yemen' },
  // { code: 'ZW', name: 'Zimbabwe' },
]

// the supported countries are the ones coming from the POSTCODE_REGEXES object that are not restricted
const whitelistedCountryCodes = Object.keys(POSTCODE_REGEXES).filter(
  (code) => !restrictedCountries.find((c) => c.code === code)
) as COUNTRY_CODES[]
export { whitelistedCountryCodes as countryCodes }
