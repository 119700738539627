export const commonTags = {
  loadingSpinner: 'loading-spinner',
  showButton: 'show-button',
  abcamLogo: 'abcam-logo',
  backButton: 'back-button',
  viewAll: 'view-all',
  pdpDrawer: 'pdp-drawer',
  closeButton: 'close-button',
  searchLozenge: 'search-lozenge',
  searchButton: 'search-button',
  searchInput: 'search-input',
  pageName: 'page-name',
  footWrapper: 'footer-wrapper',
  footSocial: 'footer-social',
  searchTitle: 'search-title',
  productCount: 'product-count',
  productCode: 'product-code',
  productTitle: 'product-title',
  categoryHeader: 'category-header',
  productsTab: 'products-tab',
  dropDown: 'drop-down',
  productFacetCategory: 'product-facet-category',
  goHomeButton: 'go-home-button',
  errorText: 'error-text',
  publicationsButton: 'publications-button',
  searchCategoriesResultsContainer: 'search-categories-results-container',
  searchSuggestionsDropdown: 'search-suggestions-dropdown',
  searchSuggestionsDropdownTitle: 'search-suggestions-dropdown-title',
  badgeTitle: 'badge-title',
  overlayBackground: 'overlay-background',
  burgerMenuButton: 'burger-menu-button',
  mobileMenuCloseButton: 'mobile-menu-close-button',
  pagination: 'pagination',
  drawerBody: 'drawer-body',
  tagDrawerBody: 'tag-drawer-body',
  crpFilters: 'crip-filters',
  recommendedAlternativesImages: 'recommended-alternatives-images',
  recommendedAlternativesPublications: 'recomended-alternatives-publications',
  showMoreLozenge: 'show-more-lozenge',
  searchCategoryTypeButton: 'search-category-type-button',
  searchContentTypeButton: 'search-content-type-button',
  searchCategoryHeading: 'search-category-heading',
  dialog: 'dialog',
  searchOverlayInstructions: 'search-overlay-instructions',
  noResultPageHeading: 'no-results-page-heading',
  noResultsPageDescription: 'no-result-page-description',
  overviewTab: 'overview-tab',
  countOfProducts: 'count-of-products',
}
