import { mappedTranslations } from '@abcam-web/shared/utilities/localisation'
import {
  billingDetailsPageTranslations,
  checkoutPageTranslations,
  confirmationPageTranslations,
  inquiryPageTranslations,
  shippingDetailsPageTranslations,
  shoppingBasketPageTranslations,
  orderPageTranslations,
} from './pages'
import {
  ecommerceComponentTranslations,
  sharedComponentTranslations,
} from './components'

export * from './components'
export const ecommerceTranslations = mappedTranslations([
  shoppingBasketPageTranslations,
  inquiryPageTranslations,
  shippingDetailsPageTranslations,
  billingDetailsPageTranslations,
  checkoutPageTranslations,
  confirmationPageTranslations,
  sharedComponentTranslations,
  ecommerceComponentTranslations,
])

export const shoppingBasketTranslations = mappedTranslations([
  shoppingBasketPageTranslations,
  sharedComponentTranslations,
  ecommerceComponentTranslations,
])

export const inquiryTranslations = mappedTranslations([
  inquiryPageTranslations,
  sharedComponentTranslations,
  ecommerceComponentTranslations,
])

export const shippingDetailsTranslations = mappedTranslations([
  shippingDetailsPageTranslations,
  sharedComponentTranslations,
  ecommerceComponentTranslations,
])

export const billingDetailsTranslations = mappedTranslations([
  billingDetailsPageTranslations,
  sharedComponentTranslations,
  ecommerceComponentTranslations,
])

export const checkoutTranslations = mappedTranslations([
  checkoutPageTranslations,
  sharedComponentTranslations,
  ecommerceComponentTranslations,
])

export const confirmationTranslations = mappedTranslations([
  confirmationPageTranslations,
  sharedComponentTranslations,
  ecommerceComponentTranslations,
])

export const orderTranslations = mappedTranslations([
  orderPageTranslations,
  sharedComponentTranslations,
  ecommerceComponentTranslations,
])
