import { Button } from '@lego/ui/button'

import { directions, justifyContent } from './constants'
import { IFooter } from './types'

export const Footer = ({
  actionButtons,
  direction = 'row',
  justify = 'end',
  gap = 2,
}: IFooter) => {
  return actionButtons.length ? (
    <div
      className={`flex ${directions[direction]} ${justifyContent[justify]} gap-${gap}`}
    >
      {actionButtons.map(({ label, variant, onClick, disabled }, i) => {
        return (
          <Button
            variant={variant}
            onClick={onClick}
            key={`${label}-${i}`}
            disabled={disabled}
          >
            {label}
          </Button>
        )
      })}
    </div>
  ) : null
}
