import { User } from '@abcam-web/auth-shared/contexts/user'
import { Icon } from './authentication-button'

export function getUserIcon(user: User | null | undefined) {
  if (user === undefined) {
    return Icon.loading
  }

  return user ? Icon.loggedIn : Icon.loggedOut
}

export function getDisplayName(
  user: User | null | undefined,
  formatMessage: ({ id }: { id: string }) => string
) {
  if (!user) {
    return formatMessage({ id: 'components.accountDropDown.myAccount' })
  }

  const isNameDefined = user?.given_name

  if (!isNameDefined) {
    return formatMessage
      ? formatMessage({ id: 'components.accountDropDown.myAccount' })
      : ''
  }

  if (user.given_name.length > 10) {
    return `${user.given_name.slice(0, 10)}...`
  }

  return user.given_name
}
