import { createAemContentService } from '@abcam-web/browse/aem'
import { transformData } from '@abcam-web/browse/header'
import { getNextLocaleFromCookie } from '@abcam-web/shared/utilities/localisation'
import { FeatureToggleServer } from '@feature-toggles/feature-toggle-server'
import App from 'next/app'
import getConfig from 'next/config'
import type {
  HeaderNavigationResponse,
  NavItem,
} from '@abcam-web/browse/header'
import type { Environment } from '@abcam-web/shared/utilities/environment'
import type { AppContext } from 'next/app'
import type { MyAppGetInitialPropsOutputType } from './types/_app.type'

export const getInitialProps = async (
  context: AppContext
): Promise<MyAppGetInitialPropsOutputType | Record<string, never>> => {
  const { publicRuntimeConfig } = getConfig()
  const appProps = await App.getInitialProps(context)

  const featureToggleServer = new FeatureToggleServer().bootstrap()
  const featureToggles = await featureToggleServer.getAllToggles()
  const environment: Environment = publicRuntimeConfig

  const onetrust = context.ctx.req?.headers['x-onetrust']

  const locale = context.ctx.locale || getNextLocaleFromCookie(context.ctx.req)

  let navigation: NavItem[] | null
  if (typeof window === 'undefined') {
    const aemContentService = await createAemContentService(locale)

    navigation = await aemContentService.getContentFor<
      HeaderNavigationResponse,
      NavItem[] | null
    >('GetHeaderNavigation', transformData)
  }

  return {
    ...appProps,
    environment: {
      ...environment,
    },
    featureToggles,
    isOneTrustEnabled: onetrust === 'true',
    navigation: navigation ?? undefined,
    locale: locale,
  }
}
