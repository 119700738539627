import { useEffect, useState } from 'react'
import Link from 'next/link'
import { Button } from '@lego/ui/button'
import { HomeIcon, MagnifierIcon, Envelope } from '@lego/ui/icons'
import type { ErrorProps, Code, Errors, ErrorCode } from './error.type'
import { useSearch } from '@abcam-web/search/utilities'
import { useIntl } from 'react-intl'

export function useErrorsConfig(errorCode: ErrorCode, onClick?: () => void) {
  const [errorConfig, setErrorConfig] = useState({
    title: '',
    button: null,
    description: '',
    movedDescription: '',
  } as ErrorProps)

  const search = useSearch()
  const { send } = search
  const { formatMessage } = useIntl()

  const defaultButtons = (
    <>
      <Link href="/">
        <Button iconLeft={<HomeIcon />} size="medium" variant="primary">
          {formatMessage({ id: 'error.goHome' })}
        </Button>
      </Link>

      <Button
        iconLeft={<MagnifierIcon />}
        onClick={() => {
          // Launch the search overlay
          send('ACTIVATE')
        }}
        size="medium"
        variant="secondary"
      >
        {formatMessage({ id: 'error.search' })}
      </Button>

      <Link href="/contact-us">
        <Button
          iconLeft={<Envelope />}
          onClick={onClick}
          size="medium"
          variant="tertiaryOutline"
        >
          {formatMessage({ id: 'error.contactUs' })}
        </Button>
      </Link>
    </>
  )

  const errors: Errors = {
    404: {
      title: formatMessage({ id: 'error.mislabeledTube' }),
      subTitle: formatMessage({ id: 'error.contentNotFound' }),
      description: formatMessage({ id: 'error.sorry' }),
      button: defaultButtons,
    },
    500: {
      title: formatMessage({ id: 'error.hmm' }),
      subTitle: formatMessage({ id: 'error.serverError' }),
      description: formatMessage({ id: 'error.sorry' }),
      button: defaultButtons,
    },
  }

  useEffect(() => {
    setErrorConfig(errors[errorCode as Code])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCode])

  return errorConfig
}
