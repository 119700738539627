import { Modal } from '@lego/ui/modal'
import { useMemo } from 'react'

import { Body } from './body'
import { Footer } from './footer'
import { Lead } from './lead'
import { IDialog } from './types'

export const Dialog = ({
  headerTitle,
  headerPrefixIcon,
  onClose,
  isOpen,
  testId = 'dialog',
  children,
  testIdPrefix = '',
}: IDialog) => {
  const Header = useMemo(() => {
    return <Lead headerPrefixIcon={headerPrefixIcon} content={headerTitle} />
  }, [headerTitle, headerPrefixIcon])

  return (
    <Modal
      header={Header}
      className="pt-1"
      onClose={onClose}
      show={isOpen}
      width="650px"
      dataCy={testId}
      testIdPrefix={testIdPrefix}
    >
      <div className="p-6">{children}</div>
    </Modal>
  )
}

Dialog.Body = Body
Dialog.Footer = Footer
