import { useReducer, useEffect, useCallback } from 'react'
import { useRouter } from 'next/router'
import {
  PDP_DEFAULT_STATE,
  stateToHash,
  TAB_INDEX_MAP,
  useProduct,
} from '@abcam-web/search/utilities'
import { slugify } from './../routes/helpers/slugify'
import type {
  ApplicationTab,
  DrawerView,
  Drawers,
  PdpUIState,
  ProductTab,
} from '../pdp'
import type { Product } from '@abcam-web/search/public/utilities/product.type'

type PdpAction =
  | {
      type: 'init'
      state: PdpUIState
    }
  | {
      type: 'alterState'
      state: Partial<PdpUIState>
    }
  | {
      type: 'switchTabs'
      tab: ProductTab
    }
  | {
      type: 'setReviewsDrawer'
      open: boolean
    }
  | {
      type: 'selectReactivityApplication'
      application: ApplicationTab['slug']
    }
  | {
      type: 'setDrawer'
      drawer: Drawers
      application: string | null
    }
  | {
      type: 'setOverlay'
      overlay: 'images' | 'alternative-product' | null
    }
  | {
      type: 'openCrossSellDrawer'
      productCode: string
      drawerView: DrawerView
    }
  | {
      type: 'closePdpCardDrawer'
    }
  | {
      type: 'openRecentlyViewedDrawer'
      productCode: string
      drawerView: DrawerView
    }
  | {
      type: 'setProductDrawerView'
      drawerView: DrawerView
    }
  | {
      type: 'setTagDrawerView'
      drawerView: DrawerView | null
    }

export const getApplicationsTabs = (product?: Product): ApplicationTab[] => [
  applicationsTabAll,
  ...(product?.reactivity?.applications || []).map((application) => ({
    ...application,
    slug: slugify(application.name),
  })),
]

const applicationsTabAll = { name: 'all', slug: 'all' } as ApplicationTab

const assignToState = <K extends keyof PdpUIState, V extends PdpUIState[K]>(
  value: V,
  obj: PdpUIState,
  prop: K
) => {
  obj[prop] = value
}

const hashToState = (hash = ''): PdpUIState => {
  const params = new URLSearchParams(hash)
  const state = { ...PDP_DEFAULT_STATE }
  for (const [key, value] of params.entries()) {
    if (key in state) {
      assignToState(value, state, key as keyof PdpUIState)
    }
  }
  return state
}

const reducer = (state: PdpUIState, action: PdpAction) => {
  const delta = () => {
    switch (action.type) {
      case 'init':
        return action.state
      case 'alterState':
        return action.state
      case 'switchTabs':
        return { tab: action.tab }
      case 'setReviewsDrawer':
        return {
          drawer: action.open ? 'reviews' : (null as Drawers),
          reviewId: action.open ? state.reviewId : null,
        }
      case 'selectReactivityApplication':
        return { application: action.application }
      case 'setDrawer':
        return {
          drawer: action.drawer,
          application: action.application,
        }
      case 'setOverlay':
        return {
          overlay: action.overlay,
        }
      case 'openCrossSellDrawer':
        return {
          previewProductCode: action.productCode,
          drawer: 'product-recommendations' as Drawers,
          drawerView: action.drawerView,
        }
      case 'openRecentlyViewedDrawer':
        return {
          previewProductCode: action.productCode,
          drawer: 'recently-viewed' as Drawers,
          drawerView: action.drawerView,
        }
      case 'closePdpCardDrawer':
        return {
          previewProductCode: null,
          drawer: null,
        }
      case 'setProductDrawerView':
        return {
          drawerView: action.drawerView,
        }
      case 'setTagDrawerView':
        return {
          drawerView: action.drawerView,
        }
      default:
        return state
    }
  }
  return { ...state, ...delta() }
}

const tabToIndex = (tab: ProductTab): number => {
  const entries =
    TAB_INDEX_MAP && typeof TAB_INDEX_MAP === 'object'
      ? Object.entries(TAB_INDEX_MAP)
      : []
  return Number(entries.find(([_, v]) => v === tab)?.[0] ?? 0)
}

export const usePdpUI = ({ isChina }: { isChina?: boolean }) => {
  const router = useRouter()
  const [pdpState, dispatch] = useReducer(reducer, PDP_DEFAULT_STATE)

  const { product } = useProduct()
  const [basePath, hash] = router.asPath.split('#')

  // Ensure that every state change is reflected in the URL
  const update = useCallback(
    async (action: PdpAction) => {
      try {
        const newState = reducer(pdpState, action)
        const newHash = stateToHash(newState)
        if (hash === newHash) {
          return
        }

        await router.replace(
          `${router.asPath.split('#')[0]}${'#' + newHash}`,
          undefined,
          { shallow: true, scroll: false }
        )
        dispatch(action)
        return newHash
      } catch (error) {
        console.log('error in pdp update', error)
      }
      return
    },
    [hash, pdpState, router]
  )

  // Ensure that the hash is reflected in the state upon page load
  useEffect(() => {
    dispatch({
      type: 'init',
      state: hashToState(router.asPath.split('#')[1] || ''),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePath])

  const activeMainTabIndex = tabToIndex(pdpState.tab)
  const applicationTabs = getApplicationsTabs(product)
  const activeApplicationTabIndex = applicationTabs.findIndex(
    (applicationTab) => applicationTab.slug === pdpState.application
  )
  const activeApplication =
    applicationTabs[activeApplicationTabIndex] || applicationsTabAll

  return {
    switchTabs: (tab: ProductTab) =>
      update({
        type: 'switchTabs',
        tab,
      }),
    setReviewsDrawer: (open: boolean) => {
      return update({ type: 'setReviewsDrawer', open })
    },
    selectReactivityApplication: (idx: number) => {
      return update({
        type: 'selectReactivityApplication',
        application: applicationTabs[idx].slug,
      })
    },
    togglePublicationsDrawer() {
      return update({
        type: 'setDrawer',
        drawer: pdpState.drawer === 'publications' ? null : 'publications',
        application: pdpState.application,
      })
    },
    setShowImageOverlay: (show: boolean) => {
      return update({
        type: 'setOverlay',
        overlay: show ? 'images' : null,
      })
    },
    setShowAlternativeProductOverlay: (show: boolean) => {
      return update({
        type: 'setOverlay',
        overlay: show ? 'alternative-product' : null,
      })
    },
    openCrossSellDrawer: ({
      productCode,
      drawerView,
    }: {
      productCode: string
      drawerView: DrawerView
    }) => {
      return update({
        type: 'openCrossSellDrawer',
        productCode,
        drawerView,
      })
    },
    openRecentlyViewedDrawer({
      productCode,
      drawerView,
    }: {
      productCode: string
      drawerView: DrawerView
    }) {
      return update({
        type: 'openRecentlyViewedDrawer',
        productCode,
        drawerView,
      })
    },
    closePdpCardDrawer() {
      return update({ type: 'closePdpCardDrawer' })
    },
    setProductDrawerView(drawerView: DrawerView) {
      return update({ type: 'setProductDrawerView', drawerView })
    },
    setTagDrawerView(drawerView: DrawerView | null) {
      return update({ type: 'setTagDrawerView', drawerView })
    },
    setReview(reviewId: string | null) {
      return update({ type: 'alterState', state: { reviewId } })
    },
    pdpState,
    activeMainTabIndex,
    applicationTabs,
    activeApplicationTabIndex,
    activeApplication,
    isChina,
  }
}
