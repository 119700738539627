import { countries } from '@abcam-web/shared/config'
import {
  getCountryAlternativeNameFromCountryCode,
  getCountryNameByCountryCode,
} from '@abcam-web/shared/utilities/localisation'
import { CountryFlag } from '@lego/ui/country-flag'
import type { CountrySelectorOption } from './countrySelectorDropdown.type'
import { CountrySelectorVariant } from './countrySelectorDropdown.type'

type CountryShortDisplayProps = {
  countryCode: string | undefined
  countryName?: string
}

export const CountryShortDisplay = ({
  countryCode,
}: CountryShortDisplayProps) => {
  return (
    <span
      data-cy={`countryselector-item-${countryCode}`}
      className={`flex flex-row items-center w-6 h-4`}
    >
      {countryCode && (
        <CountryFlag
          key={`flag-${countryCode}`}
          countryCode={countryCode}
          countryName={getCountryNameByCountryCode(countryCode)}
          className="object-cover min-h-full border-[0.5px]"
        />
      )}
    </span>
  )
}

type CountryDisplayProps = {
  countryCode: string | undefined
  variant?: string
}

export const CountryDisplay = ({
  countryCode,
  variant,
}: CountryDisplayProps): JSX.Element => {
  return (
    <span
      data-cy={`countryselector-item-${countryCode}`}
      className={`flex flex-row items-center font-semibold
    ${variant === CountrySelectorVariant.tertiary ? 'text-black-0' : ''}
  `}
    >
      {countryCode && (
        <>
          <CountryFlag
            key={`flag-${countryCode}`}
            className="object-cover w-6 h-4 border-[0.5px] mr-1.5"
            countryCode={countryCode}
            countryName={getCountryNameByCountryCode(countryCode)}
          />
          {getCountryNameByCountryCode(countryCode)}
        </>
      )}
    </span>
  )
}

type getCountrySelectorOptionProps = {
  countryCode: string
  variant?: string
  shouldDisplayCountryName?: boolean
}

export const getCountrySelectorOption = ({
  countryCode,
  variant,
  shouldDisplayCountryName,
}: getCountrySelectorOptionProps) => {
  const countryName = getCountryNameByCountryCode(countryCode)
  const countryAlternative =
    getCountryAlternativeNameFromCountryCode(countryCode)

  const res: CountrySelectorOption = {
    key: countryCode,
    value: `${countryName}`,
    search: `${countryName} ${countryAlternative}`,
    countryCode: countryCode,
    displayValue: (
      <CountryDisplay countryCode={countryCode} variant={variant} />
    ),
  }

  if (!shouldDisplayCountryName) {
    res.shortDisplayValue = <CountryShortDisplay countryCode={countryCode} />
  }

  return res
}

export const getLanguagesGroupedByCountry = (locales?: string[]) => {
  const response: { [index: string]: string[] } = {}

  for (const country of countries) {
    const countryCode = country.toLowerCase()

    if (country == 'CN' && locales?.includes('zh-cn')) {
      response[countryCode] = ['zh', 'en']
    } else if (country == 'JP' && locales?.includes('ja-jp')) {
      response[countryCode] = ['ja', 'en']
    } else {
      response[countryCode] = ['en']
    }
  }

  return response
}
