/* eslint-disable @typescript-eslint/ban-ts-comment */
import parse, { domToReact } from 'html-react-parser'
import classNames from 'classnames'
import type {
  HTMLReactParserOptions,
  Element,
  DOMNode,
} from 'html-react-parser'
import Link from 'next/link'

const ONLY_WHITESPACE = /^\s+$/

/**
 * Filter rowspan out ot all other possible attributes
 */
export const filterRowspan = (tableAttributes: Record<string, string>) => {
  if (tableAttributes['rowspan']) {
    return { rowSpan: Number(tableAttributes['rowspan']) }
  }

  return
}

const options: HTMLReactParserOptions = {
  // @ts-ignore-next-line
  replace: ({ attribs, name, children }: Element) => {
    if (attribs && name === 'table') {
      return <table>{domToReact(children as DOMNode[], options)}</table>
    }
    if (attribs && name === 'th') {
      return (
        <th {...filterRowspan(attribs)}>
          {domToReact(children as DOMNode[], options)}
        </th>
      )
    }
    if (attribs && name === 'td') {
      return (
        <td {...filterRowspan(attribs)}>
          {domToReact(children as DOMNode[], options)}
        </td>
      )
    }
    if (attribs && name === 'ol') {
      return (
        <ol className={classNames(attribs['className'], 'list-decimal')}>
          {domToReact(children as DOMNode[], options)}
        </ol>
      )
    }
    if (attribs && name === 'ul') {
      return (
        <ul className={classNames(attribs['className'], 'list-disc')}>
          {domToReact(children as DOMNode[], options)}
        </ul>
      )
    }
    if (
      attribs &&
      name === 'p' &&
      Array.isArray(children) &&
      children.length > 0 &&
      // @ts-ignore-next-line
      children[0]['data']?.match(ONLY_WHITESPACE)
    ) {
      return <></>
    }
    // Convert hyperlinks to native Next.js links to automatically include the locale
    if (attribs && name === 'a' && attribs.href) {
      let disableLocale = false

      if (attribs.href.includes('abcam.com')) {
        // Don't automatically prepend a locale for absolute abcam.com URLs
        disableLocale = true

        try {
          const url = new URL(attribs.href)

          // Handle url's that are missing the www subdomain
          if (url.hostname === 'abcam.com') {
            let hostname = 'www.abcam.com'

            if (typeof window !== 'undefined') {
              hostname = window.location.hostname
            }

            attribs.href = attribs.href.replace('abcam.com', hostname)
          }

          // Handle url's that are linking to non-us en- countries
          if (
            !url.pathname.includes('/en-us') &&
            url.pathname.includes('/en-')
          ) {
            attribs.href = attribs.href.replace(/\/en-[a-zA-Z]{2}/, '/en-us')
          }
        } catch (err) {
          // URL was a relative link or invalid
        }
      }

      // URL is relative
      if (
        attribs.href.indexOf('://') === -1 &&
        attribs.href.indexOf('//') === -1
      ) {
        // Strip off ".html" suffix added to the path component by AEM, whilst preserving any query string
        const [path, queryString] = attribs.href.split('?')
        attribs.href =
          path.replace(/\.html$/, '') + (queryString ? `?${queryString}` : '')
      }

      if (disableLocale) {
        return (
          <Link href={attribs.href} locale={false}>
            {domToReact(children as DOMNode[], options)}
          </Link>
        )
      }
      else {
        return (
          <Link href={attribs.href}>
            {domToReact(children as DOMNode[], options)}
          </Link>
        )
      }
    }
  },
}

export const cleanHTML = (html: string) => {
  return parse(html, options)
}
