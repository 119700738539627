import MaterialUITooltip from '@material-ui/core/Tooltip'
import { StylesProvider } from '@material-ui/core/styles'

import cn from 'classnames'
import type { PropsWithChildren } from 'react'
import { TooltipProps } from './tooltip.type'

const Tooltip = ({
  children,
  text,
  id,
  autoWidth = false,
  enabled = true,
  className = '',
  ...rest
}: PropsWithChildren<TooltipProps>) =>
  enabled ? (
    <div className="relative flex flex-row items-center group">
      <StylesProvider injectFirst>
        <MaterialUITooltip
          id={id}
          title={text}
          classes={{
            tooltip: cn(
              'bg-white text-grey-20 text-xs xxxl:text-sm font-body px-3 py-2 shadow-elevation-5 drop-shadow-2xl font-semibold',
              { 'max-w-none': autoWidth },
              className
            ),
          }}
          {...rest}
        >
          <div>{children}</div>
        </MaterialUITooltip>
      </StylesProvider>
    </div>
  ) : (
    <div>{children}</div>
  )

export { Tooltip }
