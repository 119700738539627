import { components } from '../components'

export const listOrders = {
  title: 'orders-title',
  new: 'orders-new',
  table: {
    container: 'orders-table-container',
    body: {
      container: 'orders-table-body-container',
      row: {
        container: 'orders-table-body-row-container',
        columns: {
          icon: 'orders-table-body-row-column-icon',
          orderNumber: 'orders-table-body-row-column-order-number',
          dateTime: 'orders-table-body-row-column-date-time',
          sourceAndType: 'orders-table-body-row-column-source-and-type',
          status: 'orders-table-body-row-column-status',
          businessUnit: 'orders-table-body-row-column-business-unit',
          organisation: 'orders-table-body-row-column-organisation',
          contact: 'orders-table-body-row-column-contact',
          additionalInformation:
            'orders-table-body-row-column-additional-information',
          actions: {
            container: 'orders-table-body-row-column-actions',
            viewButton: components.actionButtons.viewButton,
            editButton: components.actionButtons.editButton,
            submittedButton: components.actionButtons.submittedButton,
            actionRequired: {
              button: components.actionButtons.actionRequiredButton,
              count:
                'orders-table-body-row-column-actions-action-required-count',
            },
          },
        },
      },
    },
  },
}
