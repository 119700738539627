import type { MouseEvent } from 'react'
import { useIntl } from 'react-intl'

type DismissButtonProps = {
  closeToast: (e: MouseEvent<HTMLElement>) => void
}

export const DismissButton = ({ closeToast }: DismissButtonProps) => {
  const { formatMessage } = useIntl()
  return (
    <p
      className="flex items-center justify-center underline cursor-pointer text-body-xmedium"
      onClick={closeToast}
    >
      {formatMessage({ id: 'toast.buttons.dismiss' })}
    </p>
  )
}
