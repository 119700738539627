import { FC } from 'react'
import { QuantityCounterProps } from './quantityCounter.type'
import styles from './quantityCounter.module.css'

const QuantityCounter: FC<QuantityCounterProps> = (props) => {
  const { count } = props
  return (
    <div className={styles.quantityCounter}>
      <p className={styles.quantity}>{count}</p>
    </div>
  )
}

export { QuantityCounter }
