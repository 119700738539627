import cx from 'classnames'
import Link from 'next/link'
import { FC } from 'react'
import { useIntl } from 'react-intl'

interface SignInBoxProps {
  distributorFlow: boolean
  signInUrl: string
  classNames?: string
  linkTextOverrideId?: string
  fullTextLink?: boolean
}

const SignInBox: FC<SignInBoxProps> = ({
  distributorFlow,
  signInUrl,
  classNames,
  linkTextOverrideId = undefined,
  fullTextLink = false,
}) => {
  const { formatMessage } = useIntl()
  const SigninLinkComponent = () =>
    fullTextLink ? (
      <Link
        href={signInUrl}
        locale={false}
        className="underline cursor-pointer text-blue-default"
      >
        {formatMessage(
          {
            id: linkTextOverrideId ?? 'signInBox.link.fullText.default',
          },
          { signin: formatMessage({ id: 'signInBox.link.text' }) }
        )}
      </Link>
    ) : (
      <>
        {formatMessage(
          { id: linkTextOverrideId ?? 'signInBox.link.fullText.default' },
          {
            signin: (
              <Link
                href={signInUrl}
                locale={false}
                className="underline cursor-pointer text-blue-default"
              >
                {formatMessage({ id: 'signInBox.link.text' })}
              </Link>
            ),
          }
        )}
      </>
    )
  return (
    <div
      data-cy="sign-in"
      className={cx('text-center text-link-small font-normal text-black-0', {
        [classNames || '']: !!classNames,
      })}
    >
      <div>
        {distributorFlow &&
          `${formatMessage({ id: 'signInBox.distributor.message' })} `}
        <SigninLinkComponent />
      </div>
    </div>
  )
}

export { SignInBox }
