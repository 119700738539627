import { useEffect, useState } from 'react'
import { ChevronDown, ChevronUp } from '@lego/ui/icons'
import type { AccordionProps, AccordionItemInternal } from './accordion.types'

export const Accordion = ({
  items,
  classOverrides,
  testTag,
  chevronColour,
}: AccordionProps) => {
  const [accordionItems, setAccordionItems] =
    useState<AccordionItemInternal[]>()

  useEffect(
    () => {
      setAccordionItems(
        items.map((item) => {
          const isOpen = !!accordionItems?.find(
            (ai) => ai.accordionItem.title === item.title
          )?.isOpen

          return {
            isOpen,
            accordionItem: item,
          }
        })
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  )

  const wrapperClassOverrides = classOverrides && classOverrides?.wrapper

  return (
    <ul
      className={`${wrapperClassOverrides ?? 'list-none m-0 p-0'}`}
      data-testid={testTag}
    >
      {accordionItems?.map((item, i) => {
        const {
          isOpen,
          accordionItem: { title, total, onClick, body },
        } = item

        const onAccordionTabClick = () => {
          onClick && onClick(!isOpen)
          item.isOpen = !isOpen
          setAccordionItems([...accordionItems])
        }

        return (
          <li
            className={`${
              classOverrides?.itemWrapper ?? 'm-0 p-0 my-6 first-of-type:my-0'
            }`}
            data-testid={item.accordionItem.testTag}
            key={i}
          >
            <div
              className={`${
                classOverrides?.itemHeader ??
                'flex justify-between items-center cursor-pointer text-lg text-black-0'
              }`}
              onClick={onAccordionTabClick}
              onKeyUp={(evt) => {
                if (evt.key === 'Enter' || evt.key === ' ') {
                  onAccordionTabClick()
                }
              }}
              tabIndex={0}
            >
              <div>
                {title}
                {total && (
                  <span className="h-4 px-1 ml-3 font-semibold text-white rounded-lg min-w-4 bg-blue-active text-body-xx-small">
                    {total}
                  </span>
                )}
              </div>
              {isOpen ? (
                <ChevronUp fill={chevronColour ?? '#2A3C3C'} />
              ) : (
                <ChevronDown fill={chevronColour ?? '#2A3C3C'} />
              )}
            </div>

            <div
              className={`overflow-hidden ${!isOpen ? 'max-h-0' : ''}`}
              hidden={!isOpen}
            >
              {body}
            </div>
          </li>
        )
      })}
    </ul>
  )
}
