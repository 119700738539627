import { IconTick, InfoIcon } from '@lego/ui/icons'
import { Tooltip } from '@lego/ui/tooltip'
import classNames from 'classnames'
import type { FormEvent, HTMLProps } from 'react'
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react'
import { FormContext } from '../form/formContext'
import { CustomValidationMessage } from '../helpers/customValidatonMessage'
import style from './simpleTextInput.module.css'

interface SimpleTextInputProps
  extends Omit<HTMLProps<HTMLInputElement>, 'label'> {
  className?: string
  apiError?: boolean
  label?: React.ReactNode
  labelClassName?: string
  labelHint?: React.ReactNode
  hint?: string | React.ReactNode
  errorText?: string
  errorObject?: Record<string, string>
  validationFunction?: (value: string) => string
  RhsComponent?: JSX.Element
  required?: boolean
  dataCy?: string
  inputWrapperClassName?: string
  inputClassName?: string
  wrapperClassName?: string
  hintClassName?: string
  enableBlurValidation?: boolean
}

const SimpleTextInput = memo(
  ({
    className,
    label,
    labelClassName,
    labelHint,
    hint,
    inputWrapperClassName = '',
    inputClassName = '',
    hintClassName = '',
    errorText,
    errorObject,
    RhsComponent,
    required,
    apiError,
    dataCy,
    type = 'text',
    wrapperClassName = '',
    validationFunction,
    onInput,
    id: newId,
    enableBlurValidation = false,
    disabled,
    ...rest
  }: SimpleTextInputProps) => {
    const { isSubmissionAttempted } = useContext(FormContext)
    const id = useId()
    const inputRef = useRef<HTMLInputElement>(null)

    const [errorCode, setErrorCode] = useState<string>()
    const [isValid, setIsValid] = useState<boolean>(true)
    const [hasBlurred, setHasBlurred] = useState<boolean>(false)

    const updateErrorCode = useCallback(() => {
      let errorKey = ''
      if (inputRef.current) {
        //can't use Object.keys here
        for (const key in inputRef.current.validity) {
          if (inputRef.current.validity[key as keyof ValidityState]) {
            errorKey = key
            break
          }
        }

        if (inputRef.current.validity.customError) {
          errorKey = inputRef.current.validationMessage
        }

        setErrorCode(errorKey === 'valid' ? '' : errorKey)
      }
    }, [setErrorCode, required])

    const handleOnInvalid = useCallback(() => {
      updateErrorCode()
    }, [updateErrorCode])

    // In the wild it's been observed that when the component first mounts the
    // inputRef doesn't always have a target value, despite a prop being defined
    // for this. Adding a dependency on the value prop to ensure any initial
    // state is correctly validated.
    const validateInput = useCallback(() => {
      if (inputRef.current) {
        const target = inputRef.current
        if (validationFunction) {
          const message = validationFunction(target.value)
          target.setCustomValidity(message)
        }
        updateErrorCode()
      }
    }, [validationFunction, updateErrorCode, rest?.value])

    const handleInput = (e: FormEvent<HTMLInputElement>) => {
      if (!enableBlurValidation) {
        validateInput()
      }
      setIsValid(true)
      onInput && onInput(e)
    }

    const handleBlur = () => {
      setHasBlurred(true)
      if (enableBlurValidation) {
        if (inputRef.current) {
          validateInput()
          setIsValid(inputRef.current.validity.valid)
        }
      }
    }

    const wrapperCn = classNames(
      className,
      { hidden: type === 'hidden' },
      `flex flex-col w-full gap-1 text-body-xmedium ${wrapperClassName}`
    )

    useEffect(() => {
      // running the validation on mount to set the initial validity state
      validateInput()
    }, [validateInput])

    const inputStateClassName = classNames(
      isSubmissionAttempted && errorCode ? style.invalid : '',
      enableBlurValidation && isValid && hasBlurred
        ? style.validBackground
        : '',
      enableBlurValidation && !isValid && hasBlurred ? style.invalidText : '',
      disabled && enableBlurValidation && isValid ? style.validBackground : ''
    )

    return (
      <div className={wrapperCn}>
        <div>
          {label && (
            <div className={style.labelWrapper}>
              <label
                className={`mr-2 font-semibold ${labelClassName}`}
                htmlFor={newId || id}
              >
                {label}
              </label>
              {labelHint && (
                <Tooltip data-testid="input-label-tooltip" text={[labelHint]}>
                  <InfoIcon />
                </Tooltip>
              )}
            </div>
          )}
          {hint && <p className={`text-grey50 ${hintClassName}`}>{hint}</p>}
        </div>
        <div
          className={`
            relative
            ${inputWrapperClassName}
          `}
        >
          <input
            ref={inputRef}
            id={newId || id}
            className={`${style.input} ${inputStateClassName} ${inputClassName}`}
            required={required}
            aria-required={required}
            data-cy={dataCy}
            type={type}
            onInvalid={handleOnInvalid}
            onInput={handleInput}
            onBlur={handleBlur}
            {...rest}
          />

          {enableBlurValidation &&
            isValid &&
            (hasBlurred || disabled) &&
            !RhsComponent && (
              <div className="absolute right-4 top-1/2 transform -translate-y-1/2">
                <IconTick />
              </div>
            )}

          {RhsComponent && (
            <div className="absolute bottom-0 flex items-center h-full right-4">
              {RhsComponent}
            </div>
          )}
        </div>

        {inputRef.current && isSubmissionAttempted && errorCode && (
          <CustomValidationMessage
            htmlElement={inputRef.current}
            errorText={validationFunction ? undefined : errorText}
            errorObject={validationFunction ? undefined : errorObject}
          />
        )}
      </div>
    )
  }
)

SimpleTextInput.displayName = 'SimpleTextInput'

export { SimpleTextInput }
export type { SimpleTextInputProps }
