const { pixelsToRem } = require('./pixelsToRem')

// This is not actively used by browse in active components, only here for archived + ecommerce-fe
const backgroundImageUrl = process.env.AEM_DAM_URL
  ? `${process.env.AEM_DAM_URL}/content/dam/abcam-ssr-poc/images/home-bg.png`
  : `https://publish-p24061-e70035.adobeaemcloud.com/content/dam/abcam-ssr-poc/images/home-bg.png`

const theme = {
  backgroundImage: () => ({
    main: `url(${backgroundImageUrl})`,
    'gradient-to-r':
      'linear-gradient(to right, #4ba6b3 0%, #c9d3b7 35%, #ff8730 70%, #c54428 100%)',
  }),
  backgroundColor: (theme) => ({
    ...theme('colors'),
  }),
  backgroundSize: {
    main: 'cover',
  },
  extend: {
    animation: {
      'spin-slow': 'spin 2s linear infinite',
    },
    rotate: {
      135: '135deg',
    },
    flexBasis: {
      full: '100%',
      '2/3': '66.6666667%',
      '1/7': '14.2857143%',
    },
    border: {
      DEFAULT: '1px',
    },
    borderRadius: {
      DEFAULT: '10px',
      xs: '3px',
      sm: '16px',
      md: '20px',
      lg: '28px',
      none: '0',
      '4px': '4px',
      '8px': '8px',
      '32px': '32px',
      '40px': '40px',
    },
    boxShadow: (theme) => ({
      'elevation-1': '0 2px 4px 0 rgba(0,0,0,0.1)',
      'elevation-2': '0 3px 8px 0 rgba(0,0,0,0.11)',
      'elevation-3': '0 6px 12px 0 rgba(0,0,0,0.12)',
      'elevation-4': '0 12px 16px 0 rgba(0,0,0,0.15)',
      'elevation-5': '0 16px 32px 0 rgba(0,0,0,0.20)',
      'elevation-6': '0 3px 8px 0 rgba(0,0,0,0.11)',

      interactiveElementContrast: `0 0 0 3px currentColor`,
      interactiveElementInsetContrast: `0 0 0 3px currentColor inset`,

      interactiveElement: `0 0 0 3px ${theme('colors.blue.40')}`,
      interactiveElementInset: `0 0 0 3px ${theme('colors.blue.40')} inset`,
      interactiveElementError: '0 0 0 3px #d43737',
      'interactiveElement-small': `0 0 0 1px ${theme('colors.blue.40')}`,
      interactiveListElement: `0 0 0 4px ${theme('colors.blue.40')}`,
      'tab-active-thin': `
          inset -1px 0px ${theme('colors.white')},
          inset 1px 0px ${theme('colors.white')},
          1px 0px ${theme('colors.white')},
          1px 0px ${theme('colors.white')},
          inset 0 -6px ${theme('colors.orange.500')}
      `,
      'tab-active-thin-hover': `
          inset -1px 0px ${theme('colors.white')},
          inset 1px 0px ${theme('colors.white')},
          1px 0px ${theme('colors.white')},
          1px 0px ${theme('colors.white')},
          inset 0 -6px ${theme('colors.orange.500')}4d
      `,
      'tab-active': `
          inset -1px 0px ${theme('colors.white')},
          inset 1px 0px ${theme('colors.white')},
          1px 0px ${theme('colors.white')},
          1px 0px ${theme('colors.white')},
          inset 0 -8px ${theme('colors.orange.500')}
        `,

      interactiveTabElementFocus: `inset 0 0 0 3px ${theme(
        'colors.interactive-black-transparent-hover'
      )}`,
      interactiveTabElementHover: `inset 0 -5px 0 0 ${theme(
        'colors.interactive-black-transparent-hover'
      )}`,
      interactiveTabElementSelected: `inset 0 -5px 0 0 ${theme(
        'colors.orange.0'
      )}`,
      sideNav: `2px 0 3px -1px rgba(0,0,0,0.15)`,
      reset: 'none',
    }),
    borderWidth: {
      '1_2': '1.5px',
      3: '3px',
      10: '10px',
    },
    padding: {
      '1_1': '1.1rem',
      '1_2': '1.2rem',
      '1_5': '1.5rem',
      '2_2': '2.2rem',
      '2%': '2%',
      '5%': '5%',
      '2.5%': '2.5%',
      '5.25%': '5.25%',
      '6.25%': '6.25%',
      '12.5%': '12.5%',
    },
    colors: {
      // https://www.color-blindness.com/color-name-hue/
      green: '#0F8554',
      'green-20': '#0b5b3a',
      negative: '#D43737',
      cautionary: '#FFF7E8',
      red: '#ff0000',
      lightbox: 'rgba(0, 0, 0, 0.5)',

      failure: '#faebeb',
      informative: '#edf1f7',
      positive: '#e8fcf4',

      white: '#fff',
      'interactive-white-transparent-10': 'rgba(255, 255, 255, 0.1)',
      'interactive-white-transparent-15': 'rgba(255, 255, 255, 0.15)',
      'interactive-white-transparent-hover': 'rgba(255, 255, 255, 0.25)',
      'interactive-white-transparent-active': 'rgba(255, 255, 255, 0.35)',
      'seal-grey': '#ffffff4d',

      grey: {
        0: '#CDCFD0',
        5: '#F2F2F2',
        10: '#E6E7E7',
        15: '#9CA0A0',
        17: '#939D9D',
        20: '#65797C',
        30: '#435656',
        35: '#454545',
        40: '#2a3c3c',
        neutral: '#6c7979',
        dark: '#071112',
      },
      'grey-transparent': 'rgba(7, 17, 18, 0.05)',
      'interactive-light-grey': '#F1F3F3',
      'interactive-grey-hover': '#F2F3F3',
      'interactive-grey-active': '#DDE1E1',
      'interactive-grey-transparent-hover': 'rgba(7, 17, 18, 0.05)',
      'interactive-grey-transparent-active': 'rgba(7, 17, 18, 0.1)',
      'interactive-grey-darker-transparent-active': 'rgba(7, 17, 18, 0.15)',

      black: {
        0: '#000000',
        hover: '#3B3B3B',
      },
      'interactive-black-transparent-hover': 'rgba(0, 0, 0, 0.05)',
      'interactive-black-transparent-active': 'rgba(0, 0, 0, 0.1)',

      orange: {
        0: '#FF7223',
        5: '#FFF1E9',
        10: '#FFE3D3',
        15: '#FFD5BD',
        20: '#FFC7A7',
        30: '#FFAA7B',
        50: '#FF8E4F',
        70: '#994415',
        90: '#662E0E',
      },

      blue: {
        active: '#39868E',
        default: '#378189',
        hover: '#2A5F65',
        0: '#49A8B2',
        5: '#EDF6F7',
        10: '#DBEEF0',
        15: '#C8E5E8',
        20: '#B6DCE0',
        30: '#92CBD1',
        40: '#6DB9C1',
        60: '#3A868E',
        70: '#2C656B',
        80: '#1D4347',
        90: '#0F2224',
        95: '#071112',
      },

      'interactive-blue-transparent': 'rgba(55, 129, 137, 0.3)',
      'interactive-blue-transparent-hover': 'rgba(55, 129, 137, 0.05)',
      'interactive-blue-transparent-active': 'rgba(55, 129, 137, 0.1)',

      // STROKES

      'stroke-light': 'rgba(0, 0, 0, 0.1)',
      'stroke-medium': 'rgba(0, 0, 0, 0.2)',
      'stroke-dark': 'rgba(0, 0, 0, 0.4)',
      'stroke-darkbg-medium': 'rgba(255, 255, 255, 0.25)',
      'stroke-darkbg-dark': 'rgba(255, 255, 255, 0.4)',
      'stroke-opaque': '#273F3F',
      'stroke-grey': 'rgba(7, 17, 18, 0.2)',
      'stroke-grey-light': 'rgba(7, 17, 18, 0.1)',

      // ALIASES

      buttonPrimary: '#378189',
      buttonPrimaryHover: '#2A5F65',
      buttonSecondaryHover: '#3B3B3B',
      buttonSecondaryDarkHover: '#E7EAEA',
    },
    fill: (theme) => ({
      black: theme('colors.black.0'),
      white: theme('colors.white'),
      negative: theme('colors.negative'),
      cross: theme('colors.orange.5'),
      grey15: theme('colors.grey.15'),
      blue70: theme('colors.blue.70'),
      blueDefault: theme('colors.blue.default'),
      orange: theme('colors.orange.0'),
      grey20: theme('colors.grey.20'),
      greyLight: theme('colors.interactive-black-transparent-active'),
      printLogo: theme('colors.black.0'),
    }),
    fontFamily: {
      'mono-regular': ['Courier New'],
      header: ['lubalingraphstd', 'Century Gothic', 'Futura', 'Helvetica'],
      heading: ['lubalingraphstd', 'Century Gothic', 'Futura', 'Helvetica'],
      body: ['noto-sans', 'Open Sans', 'Roboto', 'Helvetica', 'sans-serif'],
      'body-cjk': ['noto-sans-cs'],
    },
    fontSize: {
      'heading-xxxlarge': [
        pixelsToRem(72),
        {
          letterSpacing: pixelsToRem(2),
          lineHeight: 1,
        },
      ],
      'heading-xxlarge': [
        pixelsToRem(56),
        {
          letterSpacing: pixelsToRem(1.4),
          lineHeight: 1,
        },
      ],
      'heading-xlarge': [
        pixelsToRem(40),
        {
          letterSpacing: pixelsToRem(0.9),
          lineHeight: 1.1,
        },
      ],
      'heading-large': [
        pixelsToRem(32),
        {
          letterSpacing: pixelsToRem(1),
          lineHeight: 1.25,
        },
      ],
      'heading-medium': [
        pixelsToRem(24),
        {
          letterSpacing: pixelsToRem(-0.5),
          lineHeight: 1.33,
        },
      ],
      'heading-small': [
        pixelsToRem(21),
        {
          lineHeight: 1.29,
        },
      ],
      'heading-x-small': [
        pixelsToRem(18),
        {
          lineHeight: 1.5,
        },
      ],
      'heading-xx-small': [
        pixelsToRem(16),
        {
          lineHeight: 1.69,
        },
      ],
      'body-large': [
        pixelsToRem(18),
        {
          lineHeight: 1.5,
          letterSpacing: pixelsToRem(0.3),
        },
      ],
      'body-medium': [
        pixelsToRem(16),
        {
          lineHeight: 1.44,
          letterSpacing: pixelsToRem(0.4),
        },
      ],
      'body-xmedium': [
        pixelsToRem(14),
        {
          letterSpacing: pixelsToRem(0.5),
          lineHeight: 1.5,
        },
      ],
      'body-small': [
        pixelsToRem(12),
        {
          letterSpacing: pixelsToRem(0.5),
          lineHeight: 1.5,
        },
      ],
      'body-x-small': [
        pixelsToRem(12),
        {
          letterSpacing: pixelsToRem(0.4),
          lineHeight: 1.42,
        },
      ],
      'body-xx-small': [
        pixelsToRem(10),
        {
          letterSpacing: pixelsToRem(0.5),
          lineHeight: 1.5,
        },
      ],
      'ui-xx-large': [
        pixelsToRem(32),
        {
          letterSpacing: pixelsToRem(0.2),
          lineHeight: 1.25,
        },
      ],
      'ui-x-large': [
        pixelsToRem(18),
        {
          letterSpacing: pixelsToRem(0.2),
          lineHeight: 1.5,
        },
      ],
      'ui-large': [
        pixelsToRem(16),
        {
          letterSpacing: pixelsToRem(0.2),
          lineHeight: 1.5,
        },
      ],
      'ui-medium': [
        pixelsToRem(14),
        {
          letterSpacing: pixelsToRem(0.2),
          lineHeight: 1.25,
        },
      ],
      'ui-small': [
        pixelsToRem(12),
        {
          letterSpacing: pixelsToRem(0.2),
          lineHeight: 1,
        },
      ],
      'button-small': [
        pixelsToRem(10),
        {
          letterSpacing: pixelsToRem(0.5),
          lineHeight: 1.33,
        },
      ],
      'link-small': [
        pixelsToRem(14),
        {
          letterSpacing: pixelsToRem(0.4),
          lineHeight: 1.5,
        },
      ],
      'link-medium': [
        pixelsToRem(16),
        {
          letterSpacing: pixelsToRem(0.4),
          lineHeight: '23px',
        },
      ],
      'size-lozenge-alt-label': '0.625rem',
    },
    flex: {
      0.1: '0.1',
      0.2: '0.2',
      0.3: '0.3',
      0.5: '0.5',
      0.7: '0.7',
      2: '2',
      3: '3',
      4: '4',
      20: '20',
    },
    gridTemplateColumns: {
      unpublished: '3rem auto',
      'unpublished-lg': '4rem auto',
      pdpCols: 'auto 1fr',
      pdpDataSheetTable: '320px 1fr 1fr',
      printPdpDataSheetTable: '200px 1fr 1fr',
      pdpTabs: '1fr auto',
      'search-overlay-l': '12.5% auto 12.5%',
      'search-overlay-s': '6.25% fit-content(1345px) 6.25%',
      'search-categories': 'repeat(auto-fit, minmax(0, 1fr))',
    },
    gridRow: {
      'span-12': 'span 12 / span 12',
    },
    height: {
      '8rem': '8rem',
      pdpContactDistributorModal: '550px',
    },
    inset: {
      initial: 'initial',
      100: '100px',
      70: '70px',
      '-18': '-4.5rem',
      'pdp-sm-dl-btn': '92px',
    },
    lineHeight: {
      1.15: '1.15',
      11: '3rem',
    },
    margin: {
      '6.25%': '6.25%',
      '12.5%': '12.5%',
      '2.5%': '2.5%',
      '50%': '50%',
    },
    marginRight: {
      '1/4': '25%',
      '1/5': '20%',
    },
    minWidth: {
      dropdown: '14rem',
      searchSuggestions: '20rem',
      sortBy: '11.5rem',
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      8: '2rem',
      12: '3rem',
      48: '12rem',
      68: '17rem',
      215: '215px',
      340: '340px',
    },
    maxWidth: {
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      260: '260px',
      '360px': '360px',
      '400px': '400px',
      initial: 'initial',
      desktop: '1280px',
      pdp: '1120px',
      opro: '1120px',
      'opro-wide': '1376px',
      'opro-superwide': '1680px',
      'search-bar': '60rem',
      '1/5': '20%',
    },
    maxHeight: {
      25.625: '25.625rem',
    },
    minHeight: {
      pdpTabs: '40px',
      0: '0',
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      8: '2rem',
      16: '4rem',
      20: '5rem',
      32: '8rem',
      '1/4': '25%',
      '1/2': '50%',
      '3/4': '75%',
      full: '100%',
    },
    spacing: {
      0.5: '0.125rem',
      22: '5.5rem',
      76: '20rem',
      104: '26rem',
      120: '30rem',
      136: '34rem',
      'layout-1': '16px',
      'layout-2': '24px',
      'layout-3': '32px',
      'layout-4': '40px',
      'layout-5': '64px',
      'layout-6': '80px',
      'layout-7': '160px',
      '1px': '1px',
    },
    scale: {
      70: '.70',
      80: '.80',
    },
    screens: {
      xs: '530px',
      sm: '680px',
      md: '800px',
      lg: '960px',
      xl: '1280px',
      xxl: '1440px',
      xxxl: '1920px',
      tyu: '476px',
      smu: '641px',
      mdu: '769px',
      lgu: '1025px',
      xlu: '1281px',
      xxlu: '1441px',
      xxxlu: '1921px',
      xxxld: { max: '1920px' },
      xxld: { max: '1440px' },
      xld: { max: '1280px' },
      lgd: { max: '1024px' },
      mdd: { max: '768px' },
      smd: { max: '640px' },
      tyd: { max: '475px' },
      print: { raw: 'print' },
      mn_lg: '1024px',
      mn_xl: '1280px',
      mn_xxl: '1965px',
      srp: '980px',
    },
    tracking: {
      letterSpacing: '-1px',
    },
    width: {
      initial: 'initial',
      pdpContactDistributorModal: '500px',
      'fit-content': 'fit-content',
      desktop: '1280px',
      68: '17rem',
      84: '346px',
    },
    letterSpacing: {
      tighter: '-.05em',
      tight: '-.025em',
      normal: '0',
      wide: '.025em',
      wider: '.05em',
      widest: '.25em',
    },
    zIndex: {
      homepage: '0',
      autoSuggestionTerm: '-1',
      hidden: '-1',
      feedbackPanel: '1',
      pdpTabs: '1',
      aboutDisclaimer: '2',
      searchCategoryResult: '3',
      previousSearches: '4',
      searchMiddle: '5',
      suggestionsWrapper: '6',
      footer: '7',
      search: '9',
      modal: '10',
      modelZElement: '11',
      searchWrapper: '12',
      stickyHeader: '13',
      modalOverlay: '14',
      meganavOverlay: '99',
      mobileMenu: '100',
      modalOverlay: '101',
      dropdown: '101',
      drawer: '9999999990', // This ridiculously high number really is required because of the chat feedback box
      imageOverlay: '9999999991',
    },
  },
  transitionDuration: {
    200: '200ms',
    300: '300ms',
    400: '400ms',
    500: '500ms',
  },
  transitionProperty: {
    all: 'all',
    height: 'height',
    opacity: 'opacity',
    nav: 'opacity, left, width',
    none: 'none',
  },
}

module.exports = { theme }
