import { POSTCODE_REGEXES } from '../data'
import { BaseAddressSchema } from './base'

export const CAAddressSchema = {
  ...BaseAddressSchema,

  province: {
    type: 'string',
    required: true,
    oneOf: [
      'AB',
      'BC',
      'MB',
      'NB',
      'NL',
      'NS',
      'NT',
      'NU',
      'ON',
      'PE',
      'QC',
      'SK',
      'YT',
    ],
  },

  postcode: {
    type: 'string',
    required: true,
    // combining all regexes in logical OR
    pattern: new RegExp(
      POSTCODE_REGEXES['CA'].map((exp) => `^${exp}$`).join('|'),
      'i'
    ),
  },
} as const

export type CAAddressSchemaType = typeof CAAddressSchema

export default CAAddressSchema
