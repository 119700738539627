import { STAGE } from '@abcam-web/auth-shared/constants/stage-type'
import { User } from '@abcam-web/auth-shared/contexts/user'
import getConfig from 'next/config'
import { getCurrentAuthenticatedUser } from './get-current-authenticated-user'

const getCurrentUserCS = async (): Promise<User | null> => {
  const stage = getConfig().publicRuntimeConfig?.STAGE as STAGE

  return getCurrentAuthenticatedUser(stage)
}

export { getCurrentUserCS }
