import type { Locale, LocalePrimitive } from '../types/locale'

const localeRegex = /^[a-z]{2}-[A-Z]{2}$/

const isValidLocale = (
  localePrimitive: LocalePrimitive
): localePrimitive is Locale => {
  const locale = `${localePrimitive.language}-${localePrimitive.country}`

  return localeRegex.test(locale)
}

export const normalizeLocale = ({
  language,
  country,
}: LocalePrimitive): Locale | undefined => {
  const normalizedLanguage = language.toLocaleLowerCase()
  const normalizedCountry = country.toLocaleUpperCase()

  if (
    !isValidLocale({ language: normalizedLanguage, country: normalizedCountry })
  ) {
    return
  }

  return { language: normalizedLanguage, country: normalizedCountry } as Locale
}
