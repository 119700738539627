import { ApolloLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { getAuthHeaders } from '@abcam-web/shared/utilities/auth'

export const basicHeaderLink = new ApolloLink((operation, forward) => {
  const authHeaders = getAuthHeaders()
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'x-abcam-app-id': 'b2c-public-website',
      ...authHeaders,
    },
  }))

  return forward(operation)
})

export const basicErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map((message) => console.error('[GraphQL error]:', message))

  if (networkError) console.error(`[Network error]: ${networkError}`)
})

export const basicRetryLink = new RetryLink({
  attempts: {
    max: 5,
  },
  delay: {
    initial: 300,
    max: 1000,
    jitter: true,
  },
})
