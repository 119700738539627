import { buildToggle } from '@feature-toggles/factories/feature-toggle-factory'
import { featureToggleMappings } from '@feature-toggles/local-feature-toggles/local-feature-toggle-mappings'
import { isToggleEnabled } from '@feature-toggles/is-toggle-enabled.predicate'

import type { FeatureToggle } from '@abcam-web/shared/data-access/feature-toggle-schema'
import type { FeatureToggleService } from '@feature-toggles/interfaces/feature-toggle.interface'
import type { FeatureToggleName } from '@feature-toggles/feature-toggle-name.enum'

export class LocalFeatureToggle implements FeatureToggleService {
  private featureToggles = featureToggleMappings

  public async getAllToggles(): Promise<FeatureToggle[]> {
    return Object.entries(this.featureToggles).map(([key, value]) =>
      this.buildToggle(key as FeatureToggleName, value)
    )
  }

  private buildToggle(
    featureToggleName: FeatureToggleName,
    enabled: boolean | string
  ): FeatureToggle {
    return buildToggle(featureToggleName, isToggleEnabled(enabled))
  }
}
