import { createContext, useCallback, useEffect, useState } from 'react'
import { FeatureToggleClientService } from './feature-toggle-client.service'

import type { ReactElement, ReactNode } from 'react'
import type { FeatureToggle } from '@abcam-web/shared/data-access/feature-toggle-schema'

export const FeatureTogglesContext = createContext<FeatureToggle[] | undefined>(
  undefined
)

export const FeatureTogglesProvider = ({
  children,
  featureToggles,
  ...otherProps
}: {
  children: ReactNode
  featureToggles: FeatureToggle[]
}): ReactElement => {
  return (
    <FeatureTogglesContext.Provider value={featureToggles} {...otherProps}>
      {children}
    </FeatureTogglesContext.Provider>
  )
}

export const FeatureTogglesSpaProvider = ({
  children,
  featureToggleServiceUrl,
  ...otherProps
}: {
  children: ReactNode
  featureToggleServiceUrl: string
}): ReactElement => {
  const [featureToggles, setFeatureToggles] = useState<FeatureToggle[]>([])
  const fetchToggles = useCallback(async () => {
    const featureToggleClientService = new FeatureToggleClientService(
      featureToggleServiceUrl
    )
    const featureToggles = await featureToggleClientService.getAllToggles()

    if (featureToggles.length > 0) setFeatureToggles(featureToggles)
  }, [featureToggleServiceUrl])

  useEffect(() => {
    fetchToggles()
  }, [fetchToggles])

  return (
    <FeatureTogglesContext.Provider value={featureToggles} {...otherProps}>
      {children}
    </FeatureTogglesContext.Provider>
  )
}
