import { Button } from '@lego/ui/button'
import { Card, CloseButton } from '../common'
import { Dialog } from '@headlessui/react'

interface ConfirmProps {
  title?: React.ReactNode
  description?: React.ReactNode
  onConfirm: () => void
  onCancel: () => void
  confirmText: React.ReactNode
  cancelText: React.ReactNode
  className?: string
  dataCy?: string
}

const ConfirmContent = ({
  title,
  description,
  onConfirm,
  onCancel,
  confirmText,
  cancelText,
  className,
  dataCy,
}: ConfirmProps) => {
  return (
    <Card className={className} dataCy={dataCy}>
      {title && (
        <Dialog.Title>
          <span className="block mr-8 text-2xl">{title}</span>
        </Dialog.Title>
      )}

      {description && <Dialog.Description>{description}</Dialog.Description>}
      <div className="flex justify-end gap-4">
        <Button size="small" onClick={onConfirm} dataCy="confirm-modal-button">
          {confirmText}
        </Button>
        <Button
          size="small"
          variant="tertiaryOutline"
          onClick={onCancel}
          dataCy="cancel-modal-button"
        >
          {cancelText}
        </Button>
      </div>
      <CloseButton onClick={onCancel} dataCy="close-modal-button" />
    </Card>
  )
}

export { ConfirmContent }
export type { ConfirmProps }
