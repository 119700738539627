import { CheckIcon } from '@lego/ui/icons'
import { FC, PropsWithChildren, ReactNode } from 'react'

import styles from './menu-list.module.css'

interface OptionProps {
  contentClassName?: string
}

type OptionResetProps = OptionProps & { onClick?: () => void }

type OptionsVariant = 'default' | 'selectedOption' | 'optionReset'

const Option: FC<PropsWithChildren & { contentClassName?: string }> = ({
  children,
  contentClassName,
}) => (
  <div
    data-cy="option-details"
    className={`flex ${
      contentClassName ? contentClassName : 'text-body-medium'
    }`}
  >
    {children}
  </div>
)

const DefaultOption: FC<PropsWithChildren<OptionProps>> = ({
  children,
  contentClassName,
}) => <Option contentClassName={contentClassName}>{children}</Option>

const SelectedOption: FC<PropsWithChildren<OptionProps>> = ({
  children,
  contentClassName,
}) => (
  <Option contentClassName={contentClassName}>
    <div className={styles.checkIcon}>
      <CheckIcon />
    </div>
    {children}
  </Option>
)

const OptionReset: FC<PropsWithChildren<OptionResetProps>> = ({
  children,
  onClick,
  contentClassName,
}) => (
  <div
    data-cy="reset-button"
    className={`${contentClassName ? contentClassName : 'text-body-medium'}`}
    onClick={onClick}
  >
    {children}
  </div>
)

export const MenuListHeader: FC<{
  children: ReactNode
}> = ({ children }) => (
  <div
    data-cy="menulist-header"
    className="sticky top-0 flex-col px-4 py-3 bg-grey-5"
  >
    {children}
  </div>
)

export const menuListOptions: Record<
  OptionsVariant,
  FC<PropsWithChildren<OptionProps & OptionResetProps>>
> = {
  selectedOption: SelectedOption,
  default: DefaultOption,
  optionReset: OptionReset,
}
