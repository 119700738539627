import { useGtm } from '@abcam-web/shared/data-access/tracking'
import cn from 'classnames'
import { useRouter } from 'next/router'
import { useRef } from 'react'
import { useIntl } from 'react-intl'
import { gtmTracking } from './utils/gtmTracking'
import ConditionalWrap from 'conditional-wrap'
type LinkProps = {
  isFeatureColumn?: boolean
  isMobile?: boolean
  onClick: (event: string) => void // Meant to be used with a useState with string type
  links: {
    title: string
    url: string
    linkCopyText?: string
  }[]
  language: string
}

export const MegaNavLinks = ({
  links,
  onClick,
  isMobile,
  isFeatureColumn,
  language,
}: LinkProps) => {
  const gtm = useGtm()
  const linkRefs = useRef<Array<HTMLAnchorElement | null>>([])
  const { asPath } = useRouter()

  // Note: can't use getNextLocaleFromCookie() here, it creates a race condition
  // when you switch between English/Chinese/Japanese and back again.
  const { locale } = useIntl()

  return (
    <>
      {links.map(({ title, url, linkCopyText }, index) => {
        const href = url.startsWith('http') ? url : `/${locale}${url}`
        const key = title + url + index
        return linkCopyText ? (
          <p className="mb-8" key={key}>
            <ConditionalWrap
              condition={!!isMobile}
              wrap={(children) => (
                <span className="mb-2 last:mb-0 text-body-medium hover:underline">
                  {children}
                </span>
              )}
            >
              <a
                className={cn(
                  isFeatureColumn && 'underline',
                  !isMobile &&
                    'mb-2 underline cursor-pointer text-body-medium last:mb-0'
                )}
                ref={(ref) => (linkRefs.current[index] = ref)}
                onClick={() => {
                  onClick('')
                  gtmTracking(
                    gtm,
                    asPath,
                    language,
                    title,
                    linkRefs.current[index]
                  )
                }}
                href={href}
                onKeyDown={(e) => e.key === 'Enter' && e.stopPropagation()}
              >
                {title}
              </a>
            </ConditionalWrap>
            <br />
            {linkCopyText}
          </p>
        ) : (
          <li className="mb-2" key={key}>
            <a
              ref={(ref) => (linkRefs.current[index] = ref)}
              onClick={() => {
                onClick('')
                gtmTracking(
                  gtm,
                  asPath,
                  language,
                  title,
                  linkRefs.current[index]
                )
              }}
              onKeyDown={(e) => e.key === 'Enter' && e.stopPropagation()}
              href={href}
              className={cn(
                isFeatureColumn && 'underline',
                'cursor-pointer text-body-medium last:mb-0 hover:underline'
              )}
            >
              {title}
            </a>
          </li>
        )
      })}
    </>
  )
}
