import 'react-toastify/dist/ReactToastify.css'

import { useUser } from '@abcam-web/auth-shared/contexts/user'
import {
  AlertInfo,
  AlertType,
  logAlertsEvent,
} from '@abcam-web/shared/ecommerce/utilities'
import { CheckIcon, InfoIcon, WarningFilled } from '@lego/ui/icons'
import cx from 'classnames'
import React, { FC, ReactNode, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import {
  toast as superToast,
  ToastContainer,
  ToastContainerProps,
  ToastContent,
  ToastContentProps,
  ToastOptions,
} from 'react-toastify'

import { DismissButton } from './dismiss-button'
import styles from './toast.module.css'

const THREE_SECONDS = 3000

const LocalisedMessage: FC<{
  contentId: string
  variables?: Record<string, string | number | boolean>
}> = ({ contentId, variables }) => {
  const { formatMessage } = useIntl()

  return <>{formatMessage({ id: contentId }, { ...(variables || {}) })}</>
}

const Toast: FC<ToastContainerProps> = (props) => {
  const defaultProps: ToastContainerProps = {
    autoClose: false,
    hideProgressBar: true,
    ...props,
  }

  return (
    <div className={cx(styles.toastify)}>
      <ToastContainer theme="colored" {...defaultProps} />
    </div>
  )
}

export const extractTextContent = (element: ReactNode, separator = ' - ') => {
  let text = ''

  if (typeof element === 'string') {
    // If the element is a string, it represents a text node
    text += element
  } else if (React.isValidElement(element)) {
    // If the element is a valid React element, recursively extract text content from its children
    React.Children.forEach(element.props.children, (child) => {
      const childText = extractTextContent(child)
      if (childText.length > 0) {
        text += text === '' ? childText : separator + childText
      }
    })
  }

  return text.trim()
}

const ObservableAlertToast = ({
  alertType,
  content,
  toastProps,
  alertInfo,
}: {
  alertType: AlertType
  content: ToastContent
  toastProps: ToastContentProps
  alertInfo?: AlertInfo
}) => {
  const user = useUser()
  const alertToastRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    logAlertsEvent({
      alertType,
      attributes: {
        errorHandlingType: 'toast',
        errorStringContent: alertToastRef.current?.innerText || '',
        isAuthenticated: Boolean(user),
      },
      alert: alertInfo,
    })
  }, [alertInfo, alertType, user])

  return (
    <div ref={alertToastRef}>
      {typeof content === 'function' ? content(toastProps) : content}
    </div>
  )
}

const toast = {
  ...superToast,
  error: ({
    content,
    options,
    errorInfo,
  }: {
    content: ToastContent
    options?: ToastOptions | undefined
    errorInfo?: AlertInfo
  }): React.ReactText => {
    return superToast.error(
      (toastProps) => (
        <ObservableAlertToast
          alertType="error"
          content={content}
          toastProps={toastProps}
          alertInfo={errorInfo}
        />
      ),
      {
        ...options,
        icon: <WarningFilled />,
      }
    )
  },
  success: (
    content: ToastContent,
    options?: ToastOptions | undefined
  ): React.ReactText => {
    return superToast.success(content, {
      ...options,
      icon: <CheckIcon />,
    })
  },
  warning: ({
    content,
    options,
    alertInfo,
  }: {
    content: ToastContent
    options?: ToastOptions | undefined
    alertInfo?: AlertInfo
  }): ReactNode => {
    return superToast.warning(
      (toastProps) => (
        <ObservableAlertToast
          alertType="warning"
          content={content}
          toastProps={toastProps}
          alertInfo={alertInfo}
        />
      ),
      {
        icon: <InfoIcon className="w-4 h-4" />,
        closeButton: DismissButton,
        ...options,
      }
    )
  },
  orderGenericError: (errorInfo?: AlertInfo) =>
    superToast.error(
      (toastProps) => (
        <ObservableAlertToast
          alertType="error"
          content={
            <LocalisedMessage contentId="toastError.order.generic.message" />
          }
          alertInfo={errorInfo}
          toastProps={toastProps}
        />
      ),
      { icon: <WarningFilled /> }
    ),
  userNotLoggedWarning: (): ReactNode => {
    return superToast.warning(
      (toastProps) => (
        <ObservableAlertToast
          alertType="warning"
          content={
            <LocalisedMessage contentId="toastError.unauthenticatedUser.message" />
          }
          toastProps={toastProps}
          alertInfo={{ message: 'User is not logged in' }}
        />
      ),
      {
        icon: <InfoIcon className="w-4 h-4" />,
        closeButton: DismissButton,
        toastId: 'userNotAuth',
        autoClose: THREE_SECONDS,
      }
    )
  },
  orderLockedWarning: (): ReactNode => {
    return superToast.warning(
      (toastProps) => (
        <ObservableAlertToast
          alertType="warning"
          content={
            <LocalisedMessage contentId="toastError.lockedBasket.message" />
          }
          toastProps={toastProps}
          alertInfo={{ message: 'order is locked' }}
        />
      ),
      {
        icon: <InfoIcon className="w-4 h-4" />,
        closeButton: DismissButton,
        toastId: 'warning-toast-id-for-locked',
      }
    )
  },
}

const showUserNotAuthenticatedToast = () => {
  toast.userNotLoggedWarning()
}

export { LocalisedMessage, showUserNotAuthenticatedToast, Toast, toast }
