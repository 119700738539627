import type { ImgHTMLAttributes, ReactNode } from 'react'

/**
 * Image renditions created on AEM side
 */
export enum AEMRenditions {
  xxxl = '3000px', // Meant for 4K
  xxl = '1920px',
  xl = '1280px',
  lg = '1024px',
  md = '768px',
  sm = '640px',
  ty = '475px',
  ratio1 = '800px',
  ratio2 = '600px',
}

type BackgroundStyleProps =
  | {
      isBackgroundImage: true
      backgroundPosition: string
      backgroundSize: 'cover' | 'contain' | 'auto'
      children?: ReactNode
      isFlexContainer?: boolean
    }
  | {
      isBackgroundImage?: false
      backgroundPosition?: never
      backgroundSize?: never
    }

type AEMImageCommonProps = ImgHTMLAttributes<HTMLImageElement> & {
  src: string
  useRatio?: boolean
  forcePng?: boolean
  forceRendition?: AEMRenditions
  fullHeight?: boolean
  pictureClassName?: string
  isBackgroundImage?: boolean
  wrapperClasses?: string
  forceVisible?: {
    containerWidth: number | null
    isVisible: boolean
  }
}

export type AEMImageProps = AEMImageCommonProps & BackgroundStyleProps

export type ReducerState = {
  containerWidth: number | null
  isVisible: boolean
  pictureError: boolean
}

export type ReducerAction = {
  type: 'containerWidth' | 'isVisible' | 'pictureError' | 'default'
  payload?: number
}
