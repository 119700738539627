/*
  Get the language for a provided locale string
  Supports the longer 'en-**' form and the shorter 'en' forms
*/
export const getLanguageFromLocale = (
  locale: string | undefined
): string | undefined => {
  try {
    const intlLocale = new Intl.Locale(locale ?? '')
    return intlLocale.language
  } catch {
    return undefined
  }
}
