import { createSearchService } from '@abcam-web/search/data-access'
import type { Product } from '@abcam-web/search/public/utilities/product.type'
import type { ProductIdentifiersRecord } from './get-product-identifiers.type'

export const getProductIdentifiers = async (
  product: Product
): Promise<ProductIdentifiersRecord> => {
  const searchService = createSearchService(false)

  const currentProductCode = product.productCode.toLowerCase()

  const productIDs = findUniqueProductIDsOnPDP(product).filter(
    (id) => id !== currentProductCode
  )

  const productIdentifiersRecord: ProductIdentifiersRecord = {}

  if (productIDs.length > 0) {
    const productIdentifiers = await searchService.products({
      productCodes: productIDs,
    })
    productIdentifiers.forEach((product) => {
      productIdentifiersRecord[product.productCode.toLowerCase()] = {
        ...product,
        productCode: product.productCode.toLowerCase(),
      }
    })
  }

  return productIdentifiersRecord
}

const findUniqueProductIDsOnPDP = (product: Product): string[] => {
  const { notes, images, kitAttributes } = product
  const notesString = notes.map((note) => note.statement ?? '').join(' ')
  const targetSummaryNotes = product.targetSummaryNotes
    .map((summary) => summary.statement)
    .join(' ')

  const imageLegendsString = images.map((image) => image.legend ?? '').join(' ')
  const imageUsageString = images
    .map((image) => image.imageUsage?.legend ?? '')
    .join(' ')
  const imageLaneDescriptions = images
    .flatMap(
      (image) =>
        image.imageUsage?.imageBlocks.flatMap((block) =>
          block.lanes.map((lane) => lane.description)
        ) ?? ''
    )
    .join(' ')

  const assayTargets = kitAttributes?.assayTargets
    .map(({ target, sensitivity, range, interWellCv }) =>
      [target, sensitivity, range, interWellCv].join(' ')
    )
    .join(' ')

  const miRnaTargets = kitAttributes?.miRnaTargets
    .map(({ rowOrderNumber, homology, mimatId, miRnaSequence }) =>
      [rowOrderNumber, homology, mimatId, miRnaSequence].join(' ')
    )
    .join(' ')

  const recovery = kitAttributes?.recovery
    .map(({ average, range, sample }) => [average, range, sample].join(' '))
    .join(' ')

  const fullString =
    `${notesString} ${targetSummaryNotes} ${imageLegendsString} ${imageUsageString} ${imageLaneDescriptions} ${assayTargets} ${miRnaTargets} ${recovery}`.toLowerCase()

  const regex = /\bab\d+/g
  const productIDs = [...new Set(fullString.match(regex))]

  return productIDs
}
