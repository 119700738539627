import { countryCodes } from '../data'

const BaseAddressSchema = {
  organisationName: {
    type: 'string',
    required: true,
  },
  addressLine1: {
    type: 'string',
    maxLength: 35,
    required: true,
  },
  addressLine2: {
    type: 'string',
    maxLength: 35,
  },
  city: {
    type: 'string',
    maxLength: 20,
    required: true,
  },
  country: {
    type: 'string',
    oneOf: countryCodes,
    required: true,
    formatValue: true,
    sortValue: true,
  },
} as const

export type BaseAddressSchemaType = typeof BaseAddressSchema
export { BaseAddressSchema }
