import {
  DistributorInquiryModel,
  InquiryAsset,
  InquiryTypeAsEnum,
} from '@abcam-web/shared/data-access/ecommerce-schema'
import { createContext, ReactNode, useReducer } from 'react'


type DistributorInquiryFeModel = DistributorInquiryModel & {
  customerFirstName?: string
  customerLastName?: string
}
interface SetInquiryAction {
  type: 'SET_INQUIRY'
  field: keyof DistributorInquiryFeModel
  value: DistributorInquiryFeModel[keyof DistributorInquiryFeModel]
}

interface SetDistributorCountryCodeAction {
  type: 'SET_DISTRIBUTOR_COUNTRY_CODE'
  countryCode: string
}

interface SetCustomerCountryCodeAction {
  type: 'SET_CUSTOMER_COUNTRY_CODE'
  countryCode: string
}

interface ClearAction {
  type: 'CLEAR'
}

type InquiryAction =
  | SetInquiryAction
  | SetDistributorCountryCodeAction
  | SetCustomerCountryCodeAction
  | ClearAction

const inquiryInitialState = {
  inquiry: {
    customerFirstName: '',
    customerLastName: '',
    customerName: '',
    customerEmail: '',
    customerPhoneNumber: '',
    customerCountry: '',
    inquiryType: InquiryTypeAsEnum.General,
    customerMessage: '',
    inquiryDistributorIds: [] as string[],
    inquiryAssets: [] as InquiryAsset[],
  },
  distributorCountryCode: '',
  customerCountryCode: '',
  setInquiry: <InquiryKey extends keyof DistributorInquiryFeModel>({
    field,
    value,
  }: {
    field: InquiryKey
    value: (DistributorInquiryModel & {
      customerFirstName?: string
      customerLastName?: string
    })[InquiryKey]
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  }) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setDistributorCountryCode: (countryCode: string) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCustomerCountryCode: (countryCode: string) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  clearContext: () => {},
}

const inquiryReducer = (
  state: typeof inquiryInitialState,
  action: InquiryAction
) => {
  switch (action.type) {
    case 'CLEAR':
      return {
        ...state,
        inquiry: { ...inquiryInitialState.inquiry },
        distributorCountryCode: '',
        customerCountryCode: '',
      }
    case 'SET_DISTRIBUTOR_COUNTRY_CODE':
      return {
        ...state,
        distributorCountryCode: action.countryCode,
      }
    case 'SET_CUSTOMER_COUNTRY_CODE':
      return {
        ...state,
        customerCountryCode: action.countryCode,
      }
    case 'SET_INQUIRY':
      return {
        ...state,
        inquiry: { ...state.inquiry, [action.field]: action.value },
      }
    default:
      return state
  }
}

const InquiryContext = createContext(inquiryInitialState)
InquiryContext.displayName = 'InquiryContext'

const InquiryProvider = ({
  children,
}: {
  children: ReactNode[] | ReactNode
}) => {
  const [state, dispatch] = useReducer(inquiryReducer, inquiryInitialState)

  const { inquiry, distributorCountryCode, customerCountryCode } = state

  const value = {
    inquiry,
    distributorCountryCode,
    customerCountryCode,
    setInquiry: ({
      field,
      value,
    }: {
      field: keyof DistributorInquiryFeModel
      value: DistributorInquiryFeModel[keyof DistributorInquiryFeModel]
    }) => dispatch({ type: 'SET_INQUIRY', field, value }),
    setDistributorCountryCode: (countryCode: string) =>
      dispatch({
        type: 'SET_DISTRIBUTOR_COUNTRY_CODE',
        countryCode,
      }),
    setCustomerCountryCode: (countryCode: string) =>
      dispatch({
        type: 'SET_CUSTOMER_COUNTRY_CODE',
        countryCode,
      }),
    clearContext: () => dispatch({ type: 'CLEAR' }),
  }

  return (
    <InquiryContext.Provider value={value}>{children}</InquiryContext.Provider>
  )
}

export { InquiryContext, inquiryInitialState, InquiryProvider, inquiryReducer }
