import { useEffect, useLayoutEffect } from 'react'
import type { MutableRefObject } from 'react'

const useIsomorphicLayoutEffect =
  typeof document === 'undefined' ? useEffect : useLayoutEffect

export const useResizeObserver = (
  ref: MutableRefObject<Element | null>,
  callback: ResizeObserverCallback,
  options: {
    enabled?: boolean
  } = {}
) => {
  const { enabled = true } = options

  useIsomorphicLayoutEffect(() => {
    if (!enabled) return

    // Create an observer instance linked to the callback function
    if (ref.current) {
      const observer = new ResizeObserver(callback)

      // Start observing the target node for resize
      observer.observe(ref.current)

      return () => {
        observer.disconnect()
      }
    }
    return
  }, [callback, ref, enabled])
}
