import { getCurrentUserCS } from '@abcam-web/auth-shared/aws-cognito'
import axios, { InternalAxiosRequestConfig } from 'axios'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import getConfig from 'next/config'
import { logger } from '@abcam-web/logger'
import {
  getAuthHeaders,
  getTokensFromLocalStorage,
} from '@abcam-web/shared/utilities/auth'
import { STAGE } from '@abcam-web/auth-shared/constants/stage-type'

const PROXY_GATEWAY_URL = getConfig()?.publicRuntimeConfig?.PROXY_GATEWAY_URL

const headers = {
  'x-abcam-app-id': 'b2c-public-website ',
  'Content-Type': 'application/json',
}

const THIRTY_SECONDS = 30000
const axiosPaymentsInstance = axios.create({
  withCredentials: true,
  baseURL: `${PROXY_GATEWAY_URL}/payments/`,
  timeout: THIRTY_SECONDS,
  headers,
})

const axiosEcommerceInstance = axios.create({
  withCredentials: true,
  baseURL: `${PROXY_GATEWAY_URL}/ecommerce/rest/v1`,
  timeout: THIRTY_SECONDS,
  headers,
})

const THREE_MINUTES = 3
const sessionStorageRefreshTokenKey = 'refresh_token_timestamp'

export const refreshTokenInterceptor = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config: InternalAxiosRequestConfig<any>
) => {
  try {
    const stage = getConfig().publicRuntimeConfig?.STAGE as STAGE
    const dateNow = new Date()
    let shouldRefreshToken

    const tokensInStorage = getTokensFromLocalStorage(stage)
    if (tokensInStorage?.refreshToken && tokensInStorage.expiresAt) {
      // if new token management system is enabled, then use it to check token expiration
      shouldRefreshToken = tokensInStorage.expiresAt < dateNow.getTime()
    } else {
      // last time token has been refreshed
      const refreshTokenTimestamp = window.sessionStorage.getItem(
        sessionStorageRefreshTokenKey
      )

      const timeDiffInMinutes = differenceInMinutes(
        dateNow,
        new Date(Number.parseInt(refreshTokenTimestamp || ''))
      )
      shouldRefreshToken =
        !refreshTokenTimestamp || timeDiffInMinutes >= THREE_MINUTES
    }

    if (shouldRefreshToken) {
      // refresh token and set the new timestamp
      const currentUser = await getCurrentUserCS()

      if (currentUser) {
        window.sessionStorage.setItem(
          sessionStorageRefreshTokenKey,
          dateNow.getTime().toString()
        )
      } else {
        logger.error(
          'axiosPaymentsInstance interceptor could not get the current user'
        )
      }
    }
  } catch {
    logger.error(
      'axiosPaymentsInstance interceptor was unable to refresh the user token and set the new timestamp'
    )
  }

  config.timeout = THIRTY_SECONDS

  // Get tokens from local storage
  const headers = getAuthHeaders()
  Object.keys(headers).forEach((key) => (config.headers[key] = headers[key]))

  return config
}

axiosPaymentsInstance.interceptors.request.use(refreshTokenInterceptor)
axiosEcommerceInstance.interceptors.request.use(refreshTokenInterceptor)

export { axiosPaymentsInstance, axiosEcommerceInstance }
