import styles from './countrySelectorDropdown.module.css'
import { ChevronDown } from '@lego/ui/icons'
import * as Popover from '@radix-ui/react-popover'
import classnames from 'classnames'
import { Key, useCallback, useEffect, useState } from 'react'
import { CountryFlag } from '@lego/ui/country-flag'
import {
  getCountryFromCookie,
  getCountryNameByCountryCode,
  getLanguageFromLocale,
} from '@abcam-web/shared/utilities/localisation'
import { FilterableList, FilterableListItem } from '@lego/ui/filterable-list'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { RegionalCountry, regions } from './constants'

export interface Region {
  name: string
  alternative: string
  countryCode: string
}

const getRegionsFromCountryCode = (countryCode: string) => {
  if (!['CN', 'JP'].includes(countryCode)) {
    throw new Error(`Regions not supported for country "${countryCode}"`)
  }

  return regions[countryCode.toLowerCase() as RegionalCountry] ?? []
}

type RegionSelectorDropdownProps = {
  country: string
  region?: string
  variant?: 'primary' | 'secondary' | 'tertiary'
  displayPosition?: 'right' | 'left'
  size?: 'small' | 'medium'
  className?: string
  onChange: (_: { region: string }) => void
}

/**
 * This is a region selector (for Chinese provinces and Japanese prefectures), modelled on the CountrySelectorDropdown component
 * @todo the CountrySelectorDropdown is not very generic/versatile/adaptable - would be good to make at least the styling blocks shared
 */
const RegionSelectorDropdown = ({
  country,
  variant = 'primary',
  size = 'small',
  displayPosition,
  className = '',
  onChange,
}: RegionSelectorDropdownProps) => {
  const { formatMessage } = useIntl()

  const [isOpen, setIsOpen] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState(country)

  const defaultRegionValue =
    selectedCountry === 'CN'
      ? formatMessage({ id: 'regionSelector.defaultSelection.province' })
      : selectedCountry === 'JP'
      ? formatMessage({ id: 'regionSelector.defaultSelection.prefecture' })
      : formatMessage({ id: 'regionSelector.defaultSelection.region' })

  const [selectedRegion, setSelectedRegion] = useState(defaultRegionValue)

  const onVisibilityChange = useCallback((visibility: boolean) => {
    setIsOpen(visibility)
  }, [])

  useEffect(() => {
    setSelectedCountry(country)
  }, [country])

  // when country selection changes, set selected region to country's default region
  useEffect(() => {
    setSelectedRegion(defaultRegionValue)
  }, [selectedCountry, defaultRegionValue])

  const onChangeIsOpenCallback = useCallback(
    (val: boolean) => {
      setIsOpen(val)
    },
    [setIsOpen]
  )

  const onChangeSelectedCallback = useCallback(
    (val: string | undefined) => {
      if (val) {
        setSelectedRegion(val)
        onChange({ region: val })
      }
    },
    [onChange]
  )

  const getParentElementVariantStyle = useCallback(
    (variant: 'primary' | 'secondary' | 'tertiary') => {
      switch (variant) {
        // primary intentionally missing
        case 'secondary':
          return 'bg-interactive-black-transparent-hover border-interactive-black-transparent-hover text-black-0 hover:shadow-interactiveElement hover:bg-interactive-black-transparent-hover'
        case 'tertiary':
          return '!border-black-0 justify-between text-black-0 hover:shadow-interactiveElement hover:bg-interactive-black-transparent-hover'
        default:
          return ''
      }
    },
    []
  )

  const popoverTriggerCn = `
    flex flex-row items-center gap-2
    ${variant === 'tertiary' ? '' : 'w-fit-content'}
    py-2 px-4
    rounded-sm
    border border-solid border-white
    hover:bg-stroke-darkbg-medium
    text-body-x-small text-white
    ${size === 'medium' ? 'gap-3 py-2.5 px-6' : ''}
    ${getParentElementVariantStyle(variant)}
    ${classnames(styles.parentComponent)}
    ${className}
  `

  const chevronDownCn = `
    w-4 h-4
    border-stroke-darkbg-medium
    ${isOpen ? 'transform rotate-180' : ''}
    ${styles.openIcon}
    ${variant === 'tertiary' ? 'text-black-0' : ''}
  `

  const regionSelectorTitle =
    selectedCountry === 'CN'
      ? formatMessage({ id: 'regionSelector.title.province' })
      : selectedCountry === 'JP'
      ? formatMessage({ id: 'regionSelector.title.prefecture' })
      : formatMessage({ id: 'regionSelector.title.region' })

  const regionSelectorSearchPlaceholder =
    selectedCountry === 'CN'
      ? formatMessage({ id: 'regionSelector.searchPlaceholder.province' })
      : selectedCountry === 'JP'
      ? formatMessage({ id: 'regionSelector.searchPlaceholder.prefecture' })
      : formatMessage({ id: 'regionSelector.searchPlaceholder.region' })

  return (
    <Popover.Root open={isOpen} onOpenChange={onVisibilityChange}>
      <Popover.Trigger asChild>
        <button
          data-cy="regionselector-dropdown"
          data-testid="regionselector-dropdown"
          className={popoverTriggerCn}
        >
          <RegionDisplay
            countryCode={selectedCountry}
            region={selectedRegion}
            variant={variant}
          />
          <ChevronDown className={chevronDownCn} aria-label="Open dropdown" />
        </button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          align={displayPosition === 'right' ? 'start' : 'end'}
          className="relative"
          sideOffset={6}
        >
          <RegionSearchSelector
            regions={getRegionsFromCountryCode(selectedCountry)}
            title={regionSelectorTitle}
            searchPlaceholder={regionSelectorSearchPlaceholder}
            variant={variant}
            onChangeSelectedRegionCallback={onChangeSelectedCallback}
            onChangeIsOpenCallback={onChangeIsOpenCallback}
            onChange={onChange}
          />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}

type RegionSearchSelectorProps = {
  regions: Region[]
  title: string
  searchPlaceholder: string
  variant: 'primary' | 'secondary' | 'tertiary'
  onChangeSelectedRegionCallback: (val: string | undefined) => void
  onChangeIsOpenCallback: (val: boolean) => void
  onChange: (_: { region: string }) => void
}

// the search bar and subsequent dropdown list of regions matching the search text
export const RegionSearchSelector = ({
  regions,
  title,
  searchPlaceholder,
  variant = 'primary',
  onChangeSelectedRegionCallback,
  onChangeIsOpenCallback,
  onChange,
}: RegionSearchSelectorProps) => {
  const { locale } = useIntl()
  const langCode = getLanguageFromLocale(locale)
  const cookieCountry = getCountryFromCookie()

  const regionOptions = useMemo(() => {
    return regions.map((region) => ({
      key: region.name,
      value: `${
        region.countryCode === cookieCountry && langCode !== 'en'
          ? region.alternative
          : region.name
      }`,
      search: `${region.name} ${region.alternative}`,
      countryCode: region.countryCode,
      displayValue: (
        <RegionDisplay
          countryCode={region.countryCode}
          region={
            region.countryCode === cookieCountry && langCode !== 'en'
              ? region.alternative
              : region.name
          }
          variant={variant}
        />
      ),
    }))
  }, [regions, variant, langCode, cookieCountry])

  const onChangeRegionCallback = useCallback(
    async (key: Key | null) => {
      const region = regionOptions.find((r) => r.key === key)
      if (!region) return
      onChangeSelectedRegionCallback(region.value)
      onChangeIsOpenCallback(false)
      onChange({ region: region.key })
    },
    [
      regionOptions,
      onChange,
      onChangeIsOpenCallback,
      onChangeSelectedRegionCallback,
    ]
  )

  return (
    <FilterableList
      cy="regionselector"
      filterKey={'search'}
      title={title}
      options={regionOptions}
      onValueSelect={onChangeRegionCallback}
      standalone={false}
      searchPlaceholder={searchPlaceholder}
    >
      {(item) => {
        return (
          <FilterableListItem key={item.key} textValue={item.value}>
            {item.displayValue}
          </FilterableListItem>
        )
      }}
    </FilterableList>
  )
}

type RegionDisplayProps = {
  countryCode: string
  region: string | undefined
  variant?: 'primary' | 'secondary' | 'tertiary'
}

// individual items in the dropdown region list
export const RegionDisplay = ({
  countryCode,
  region,
  variant,
}: RegionDisplayProps): JSX.Element => {
  return (
    <span
      data-cy={`regionselector-item-${countryCode}`}
      className={`flex flex-row items-center font-semibold
        ${variant === 'tertiary' ? 'text-black-0' : ''}
      `}
    >
      <CountryFlag
        key={`flag-${countryCode}`}
        className="object-cover w-6 h-4 border-[0.5px] mr-1.5"
        countryCode={countryCode}
        countryName={getCountryNameByCountryCode(countryCode)}
      />
      {region}
    </span>
  )
}

export { RegionSelectorDropdown }
