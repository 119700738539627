import type { PropsWithChildren } from 'react'
import { useCallback, useState, useEffect } from 'react'
import cn from 'classnames'
import { LinesIcon, LogoAbcamWhite } from '@lego/ui/icons'
import {
  CountrySelectorDropdown,
  CountrySelectorPosition,
  CountrySelectorSize,
} from '@lego/ui/country-selector-dropdown'
import { Button, BUTTON_SIZE, BUTTON_VARIANT } from '@lego/ui/button'
import { MobileMenu } from '../mobile-menu/mobile-menu'
import { ShoppingBasketDropdown } from '@abcam-web/shared/ecommerce/components'
import { MOBILE_MENU_OPEN_LABEL } from './constants'
import { CognitoAccountDropdown } from '@abcam-web/auth-shared/aws-cognito'
import { tags } from '@tagging-shared/browse'
import type { AppHeaderProps } from './app-header.types'
import { useRouter } from 'next/router'
import { defaultLocale } from '@abcam-web/shared/config'
import {
  getCountryNameByCountryCode,
  getNextLocaleFromCookie,
} from '@abcam-web/shared/utilities/localisation'
import { useSwitchLocale } from '@abcam-web/shared/utilities/switch-locale'
import { CountryStorage } from '@abcam-web/shared/utilities/storage'
import {
  LocationID,
  getCountrySelectorParameters,
  useGtm,
  getPageType,
  getPageSubType,
} from '@abcam-web/shared/data-access/tracking'
import { LaunchSearchOverlayButtonWithSearchInfo } from '@abcam-web/search/search-overlay'
import { useCountry } from '@abcam-web/shared/utilities/country'

const AppHeader = ({
  hideAllInteractions = false,
  hideCountrySelector,
  isSticky = false,
  shoppingBasketDropdown: shoppingDropdownProp,
  customActionButtons,
  accountDropdownStage,
  style,
  classOverrides,
  children,
  navigation,
  showSeoHeading = false,
  hideLogoMobile = false,
  onLogoClickUrl,
  hideMobileMenu = false,
  hideSearchBar = false,
}: PropsWithChildren<AppHeaderProps>) => {
  const { country } = useCountry()
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false)
  const router = useRouter()
  const locale = router.locale || getNextLocaleFromCookie()
  const gtm = useGtm()
  const { switchLocale } = useSwitchLocale()
  const { pathname } = router

  const homePageUrl = `/${locale}`

  useEffect(() => {
    if (locale) {
      CountryStorage.setSelectedLocale(locale)
    } else if (defaultLocale) {
      CountryStorage.setSelectedLocale(defaultLocale)
    }
  }, [locale])

  const burgerMenuClickCallback = useCallback(() => {
    setMobileMenuVisible(!mobileMenuVisible)
  }, [mobileMenuVisible])

  if (mobileMenuVisible && !hideAllInteractions) {
    return (
      <MobileMenu
        hideCountrySelector={hideCountrySelector}
        onCloseCallback={burgerMenuClickCallback}
        shoppingBasketDropdown={
          shoppingDropdownProp && typeof shoppingDropdownProp !== 'boolean' ? (
            shoppingDropdownProp
          ) : (
            <ShoppingBasketDropdown />
          )
        }
        navigation={navigation}
        onLogoClickUrl={onLogoClickUrl || homePageUrl}
      />
    )
  }

  return (
    <header
      style={style}
      className={cn(
        'bg-black-0 py-3 flex justify-center flex-col',
        isSticky ? 'sticky top-0 bg-black z-[100]' : '',
        classOverrides?.wrapper
      )}
    >
      {showSeoHeading && <h1 className="sr-only">Abcam Limited</h1>}
      <div
        className={cn(
          'w-full my-0 mx-auto flex flex-row mdu:gap-6 gap-0 justify-between items-center',
          classOverrides?.headerContent
        )}
      >
        <div
          className={cn(
            'basis-1/4 flex flex-row gap-4 tyd:gap-2',
            classOverrides?.brandContainer
          )}
        >
          {!hideAllInteractions && hideMobileMenu !== true && (
            <div className="flex gap-x-4 tyd:gap-x-2 mn_lg:hidden">
              <Button
                data-cy={tags.commonTags.burgerMenuButton}
                dark={true}
                aria-label={MOBILE_MENU_OPEN_LABEL}
                variant={BUTTON_VARIANT.textOnly}
                size={BUTTON_SIZE.small}
                iconBurger
                onClick={burgerMenuClickCallback}
              >
                <LinesIcon />
              </Button>
              {!hideSearchBar && (
                <LaunchSearchOverlayButtonWithSearchInfo
                  placeHolder={'searchBarPlaceHolder'}
                  className="smu:hidden"
                  cyTag={tags.homePage.headerSearchInput}
                  isCompactMode
                />
              )}
            </div>
          )}
          {/* Using a regular <a> tag rather than <Link> so it doesn't add basePath, e.g. for /my-account */}
          <a
            className="tyd:self-center"
            href={onLogoClickUrl || homePageUrl}
            aria-label="Homepage link"
          >
            <LogoAbcamWhite
              data-cy={tags.commonTags.abcamLogo}
              data-testid="abcam-logo"
              className={cn(
                'cursor-pointer tyd:w-[100px]', // Prevent logo from clipping country selector
                classOverrides?.logo,
                !hideLogoMobile || 'mdd:hidden'
              )}
            />
          </a>
        </div>
        {!hideAllInteractions && (
          <>
            {/* Accessibility: country selector first in source order */}
            <div
              className={cn(
                'basis-1/4 flex flex-row items-center justify-end gap-4 order-3',
                classOverrides?.actionsContainer
              )}
            >
              {customActionButtons ? (
                customActionButtons
              ) : (
                <>
                  <CountrySelectorDropdown
                    defaultSelectedCountry={country}
                    className={cn(
                      hideCountrySelector ? 'hidden' : 'flex',
                      'rounded'
                    )}
                    displayPosition={CountrySelectorPosition.left}
                    size={CountrySelectorSize.small}
                    withLanguageSelector
                    persistSelection
                    isMainSiteSwitcher
                    onChange={({
                      country,
                      language,
                    }: {
                      country: string
                      language: string | undefined
                    }) => {
                      gtm.track(
                        getCountrySelectorParameters({
                          click_id: 'country-language dropdown',
                          location_id: LocationID.MAIN_NAV,
                          page_path: pathname,
                          page_type: getPageType(pathname),
                          page_subtype: getPageSubType(pathname),
                          item_cta: null,
                          country: getCountryNameByCountryCode(country),
                          destination_path: null,
                          toggle: null,
                          widget: null,
                          country_selection: null,
                          index: null,
                          purchasing_organisations: null,
                          distributor_name: null,
                          drawer_type: null,
                        })
                      )

                      switchLocale(language, country)
                    }}
                  />
                  {accountDropdownStage && (
                    <CognitoAccountDropdown stage={accountDropdownStage} />
                  )}
                  {!!shoppingDropdownProp &&
                  typeof shoppingDropdownProp === 'boolean' ? (
                    <ShoppingBasketDropdown />
                  ) : (
                    shoppingDropdownProp
                  )}
                </>
              )}
            </div>

            {/* Mega nav */}
            {children}
          </>
        )}
      </div>
    </header>
  )
}

export { AppHeader }
