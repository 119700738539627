import { AssetModel } from '@abcam-web/shared/data-access/ecommerce-schema'
import { Button } from '@lego/ui/button'
import { Before } from '@lego/ui/icons'
import classNames from 'classnames'
import { orderBy } from 'lodash'
import { FC } from 'react'
import { useIntl } from 'react-intl'

import styles from './sizes-box.module.css'

interface SizeButtonProps {
  value: AssetModel
  selected?: boolean
  onClick: (item: AssetModel) => void
  className?: string
  tagValue?: string
}
interface SizesBoxProps {
  assets: AssetModel[]
  selectedAsset?: AssetModel
  onChange?: (selectedSizeItem: AssetModel) => void
}

const SizeButton: FC<SizeButtonProps> = ({
  value,
  selected,
  tagValue,
  onClick,
}) => {
  return (
    <div>
      <Button
        bodyClassName={styles.buttonMessage}
        className={classNames({
          'whitespace-nowrap': true,
          [styles.regularButton]: !selected,
          [styles.selectedButton]: selected,
        })}
        onClick={(): void => {
          onClick(value)
        }}
        size="medium"
        rounded
        variant="tertiaryOutline"
        dataCy="size-button"
      >
        <div data-cy="size-button-content" className={styles.content}>
          {selected && (
            <Before className={styles.ico} data-cy="buybox-selected-size" />
          )}
          {value.size?.value} {value.size?.unit}
          {tagValue}
        </div>
      </Button>
    </div>
  )
}

const SizesBox: FC<SizesBoxProps> = ({
  assets,
  onChange = (): null => null,
  selectedAsset,
}) => {
  const hasOneSize = assets?.length === 1
  const sortedAssets = orderBy(
    assets,
    ['size.unit', (item) => Number(item.size?.value)],
    ['asc', 'asc']
  )
  const { formatMessage } = useIntl()

  return (
    <div className={styles.SizesBox} data-testid="asset-sizes-box">
      <div className={styles.title}>
        {formatMessage({
          id: hasOneSize ? 'sizesBox.title.single' : 'sizesBox.title.multiple',
        })}
      </div>
      {sortedAssets.length > 1 && (
        <div className={styles.sizeList}>
          {sortedAssets.map((asset, index) => (
            <SizeButton
              key={index}
              onClick={(selected): void => {
                onChange(selected)
              }}
              value={asset}
              selected={selectedAsset?.assetNumber === asset.assetNumber}
            />
          ))}
        </div>
      )}
      {hasOneSize && (
        <div data-cy="single-size" className={styles.singleSize}>
          {assets[0].size?.value}
          {assets[0].size?.unit}
        </div>
      )}
    </div>
  )
}

export { SizesBox }
