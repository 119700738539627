export const snapshot = {
  loadingMessage: 'order-snapshot-loading-message',
  heading: 'order-snapshot-heading',
  subHeading: 'order-snapshot-sub-heading',
  goToOrdersButton: 'order-snapshot-go-to-orders-button',
  collapseAllLines: 'order-snapshot-collapse-all-lines',
  expandAllLines: 'order-snapshot-expand-all-lines',
  user: 'order-snapshot-sub-heading-user',
  modifiedAt: 'order-snapshot-sub-heading-modified-at',
  jsonResponse: 'order-snapshot-json-response',
  jsonError: 'order-snapshot-json-error',
}
