import { MarketInfoModel } from '@abcam-web/shared/data-access/ecommerce-schema'
import { getAuthHeaders } from '@abcam-web/shared/utilities/auth'
import { useCountry } from '@abcam-web/shared/utilities/country'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import axios from 'axios'
import getConfig from 'next/config'

const PROXY_GATEWAY_URL = getConfig()?.publicRuntimeConfig?.PROXY_GATEWAY_URL
const baseURL = `${PROXY_GATEWAY_URL}/ecommerce/rest/v1`

const headers = {
  'x-abcam-app-id': 'b2c-public-website ',
}

const axiosInstance = axios.create({
  baseURL,
  headers,
  withCredentials: true,
})

const useMarketInfo = (
  countryCode?: string
): UseQueryResult<MarketInfoModel> => {
  const { country: activeCountryCode, countryIsLoaded } = useCountry()
  const headers = getAuthHeaders()
  const country = countryCode ?? activeCountryCode

  // Enabled only if the countryCode override argument is passed in
  // or the country from the cookie has been loaded client-side
  const enabled = !!countryCode || countryIsLoaded

  const marketInfoQuery = useQuery<MarketInfoModel>({
    enabled,
    queryKey: ['market-info', country],
    refetchOnMount: false,
    queryFn: () =>
      axiosInstance
        .get(`market-info?country=${country}`, { headers })
        .then(({ data }) => data),
  })

  return marketInfoQuery
}

export { useMarketInfo }
