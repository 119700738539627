import {
  ApolloLink,
  ApolloClient,
  HttpLink,
  InMemoryCache,
  from,
  TypePolicy,
  split,
} from '@apollo/client'
import { removeDirectivesFromDocument } from '@apollo/client/utilities'
import getConfig from 'next/config'
import { visit, BREAK } from 'graphql'
import fetch from 'isomorphic-fetch'
import uniqBy from 'lodash/uniqBy'
import type { Operation, NormalizedCacheObject } from '@apollo/client'
import type { StringValueNode } from 'graphql'
import {
  basicErrorLink,
  basicHeaderLink,
  basicRetryLink,
} from './apollo-client-links'

// Helper function to process the @endpoint directive and remove it from the operation
const processEndpointDirective = (operation: Operation) => {
  let endpointName = null
  const endpointDirective = 'endpoint'

  // Traverse the GraphQL document and look for the @endpoint directive
  visit(operation.query, {
    Directive(node) {
      if (node.name.value === endpointDirective) {
        // Extract the endpoint name from the directive and remove the directive from the document
        endpointName = (
          node?.arguments?.find((arg) => arg.name.value === 'name')
            ?.value as StringValueNode
        )?.value
        operation.query =
          removeDirectivesFromDocument(
            [{ name: endpointDirective }],
            operation.query
          ) || operation.query
        return BREAK
      }
    },
  })

  return endpointName
}

// Create an ApolloLink to process the @endpoint directive and set the clientName in the context
const endpointLink = new ApolloLink((operation, forward) => {
  const endpointName = processEndpointDirective(operation)

  if (endpointName) {
    operation.setContext({ clientName: endpointName })
  }

  return forward(operation)
})

// Memoize the apolloClient function to avoid creating multiple instances of the client
// This is necessary because the apolloClient factory function might be called more than
// once with different arguments, and we want to avoid creating multiple instances of the client
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const memoize = <T extends (...args: any[]) => any>(fn: T): T => {
  const cache = new Map<string, ReturnType<T>>()
  return ((...args: Parameters<T>): ReturnType<T> => {
    const key = JSON.stringify(args)
    // important!: Server side we don't want to cache the client
    // because we don't want to share the same client between requests
    if (typeof window !== 'undefined' && cache.has(key)) {
      return cache.get(key) as ReturnType<T>
    }
    const result = fn(...args)
    cache.set(key, result)
    return result
  }) as T
}

const getApolloClient = (
  proxyGatewayUri = '',
  apis: string[] = [],
  additionalApolloLinks?: ApolloLink
): ApolloClient<NormalizedCacheObject> => {
  const httpLinks = apis.map(
    (cv: string) =>
      new HttpLink({
        uri: `${proxyGatewayUri}/${cv}`,
        fetch,
        credentials: 'include',
        headers: {
          'x-abcam-app-id': 'b2c-public-website',
        },
      })
  )

  const combinedHttpLinks = httpLinks.reduce(
    (acc: ApolloLink, httpLink: HttpLink) => {
      return split(
        (operation: Operation) => {
          return (
            (httpLink.options.uri as string).replace(
              `${proxyGatewayUri}/`,
              ''
            ) === operation.getContext()['clientName']
          )
        },
        httpLink,
        acc
      )
    },
    ApolloLink.empty() // Starting with an empty ApolloLink instead of a legacy gateway link
  )

  const link = from([
    ...(additionalApolloLinks ? [additionalApolloLinks] : []),
    endpointLink,
    basicRetryLink,
    basicErrorLink,
    basicHeaderLink,
    combinedHttpLinks,
  ])

  return new ApolloClient({
    cache: new InMemoryCache({
      typePolicies,
    }),
    link,
  })
}

export type Client = ApolloClient<NormalizedCacheObject>

export const apolloClient = memoize(getApolloClient)

const typePolicies: Record<string, TypePolicy> = {
  Query: {
    fields: {
      product: {
        keyArgs: ['id'],
        merge(existing, incoming, ctx) {
          return ctx.mergeObjects(existing, incoming)
        },
      },
      office: {
        merge: true,
      },
    },
  },
  Product: {
    fields: {
      publications: {
        keyArgs: ['productCode', 'filter', ['species', 'application']],
        read(existing) {
          return {
            ...existing,
            items: uniqBy(existing?.items || [], 'pubmedId'),
          }
        },
        merge(existing, incoming) {
          if (!incoming) return existing
          if (!existing) return incoming
          return {
            ...incoming,
            items: [...(existing?.items || []), ...(incoming?.items || [])],
          }
        },
      },
    },
  },
}

export const getProxyClient = (additionalApolloLinks?: ApolloLink) => {
  const proxyGatewayUrl = getConfig().publicRuntimeConfig.PROXY_GATEWAY_URL

  return apolloClient(
    proxyGatewayUrl,
    getConfig().publicRuntimeConfig.PROXY_GATEWAY_APIS?.split(',') || [],
    additionalApolloLinks
  )
}
