import { BasketItemModel } from '@abcam-web/shared/data-access/ecommerce-schema'
import { productLink } from '@abcam-web/shared/ecommerce/utilities'
import { getNextLocaleFromCookie } from '@abcam-web/shared/utilities/localisation'
import { asAbsoluteUrl, hasBasePath } from '@abcam-web/shared/utilities/next-js'
import { Tooltip } from '@lego/ui/tooltip'
import { tags } from '@tagging-shared/orderprocessing'
import cx from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC } from 'react'

import styles from './item.module.css'

interface ItemProps {
  simplified: boolean
  disabled: boolean
  lineNumber: number
  definitionNumber: string | undefined
  definitionName: string | undefined
  tagValue: string
  promotions?: Pick<
    NonNullable<BasketItemModel['promotions']>[number],
    'promotionCode' | 'name' | 'discount'
  >[]
}

const Item: FC<ItemProps> = ({
  simplified,
  disabled,
  lineNumber,
  definitionNumber,
  definitionName,
  tagValue,
  promotions = [],
}) => {
  const disableLink = cx({
    'pointer-events-none': disabled,
  })

  const router = useRouter()
  const productUrl = (() => {
    if (!definitionNumber) return undefined
    const relativeUrl = productLink(definitionNumber)
    return hasBasePath(router)
      ? asAbsoluteUrl({
          url: relativeUrl,
          router,
          locale: getNextLocaleFromCookie(),
        })
      : relativeUrl
  })()

  return (
    <div
      className="py-1 text-body-xmedium text-grey-20"
      data-cy="basket-product-info"
    >
      <div className="flex ml-2">
        <div
          className="mr-2"
          data-testid={tags.editOrder.basket.item.lineNumber}
        >
          #{lineNumber}
        </div>
        <span
          className="lowercase "
          data-testid={tags.editOrder.basket.item.definitionNumber}
        >
          {definitionNumber}
        </span>
        {tagValue && (
          <div
            className={styles.tag}
            data-testid={tags.editOrder.basket.item.tagValue}
          >
            {tagValue}
          </div>
        )}

        {promotions?.filter((p) => !!p.discount?.value).length > 0 &&
          promotions.map((promotion) => (
            <Tooltip
              key={promotion.promotionCode}
              text={promotion.name || promotion.promotionCode}
              data-cy="price-promotion-tag"
            >
              <div
                className={styles.discountTag}
                data-testid={tags.editOrder.basket.item.promotionCode}
              >
                {promotion.promotionCode}
              </div>
            </Tooltip>
          ))}
      </div>
      {simplified && (
        <p
          className="truncate text-black-0"
          data-testid={tags.editOrder.basket.item.definitionName}
        >
          {definitionName}
        </p>
      )}
      {!simplified && productUrl && (
        <Link
          data-testid={`product-link-${lineNumber}`}
          href={productUrl}
          className={disableLink}
        >
          <p
            className="truncate text-blue-default"
            data-testid={tags.editOrder.basket.item.definitionName}
          >
            {definitionName}
          </p>
        </Link>
      )}
    </div>
  )
}

export { Item }
