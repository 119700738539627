import { ILead } from './types'

export const Lead = ({ content, headerPrefixIcon }: ILead) => {
  return (
    <div className="flex flex-row gap-2 text-heading-medium">
      {headerPrefixIcon && headerPrefixIcon}
      <h3 className="text-heading-medium text-grey-40">{content}</h3>
    </div>
  )
}
