import styles from './responsiveButton.module.css'
import { Button, BUTTON_SIZE, BUTTON_VARIANT } from '@lego/ui/button'
import classnames from 'classnames'
import {
  RESPONSIVE_BUTTON_ICON_POSITION,
  ResponsiveButtonProps,
} from './responsiveButton.type'
import { PropsWithChildren } from 'react'

const ResponsiveButton = (props: PropsWithChildren<ResponsiveButtonProps>) => {
  const {
    size = BUTTON_SIZE.medium,
    variant = BUTTON_VARIANT.tertiaryOutline,
    icon,
    dark = false,
    iconPosition,
    className,
    children,
    iconLeft,
    iconRight,
    disableResponsive = false,
    disabled = false,
    onClick,
  } = props

  const responsiveButtonWithTextClasses = classnames(
    { [styles.responsiveButtonWithText]: !disableResponsive },
    className
  )

  const responsiveButtonWithIconClasses = classnames(
    styles.buttonWithIcon,
    { [styles.responsiveButtonWithIcon]: !disableResponsive },
    className
  )

  if (!children) {
    return (
      <Button
        dark={dark}
        size={size}
        variant={variant}
        iconButton
        disabled={disabled}
        onClick={onClick}
      >
        {icon}
      </Button>
    )
  }

  return (
    <>
      <span className={responsiveButtonWithTextClasses}>
        <Button
          dark={dark}
          size={size}
          variant={variant}
          iconLeft={
            iconLeft ??
            (iconPosition === RESPONSIVE_BUTTON_ICON_POSITION.left && icon)
          }
          iconRight={
            iconRight ??
            (iconPosition === RESPONSIVE_BUTTON_ICON_POSITION.right && icon)
          }
          disabled={disabled}
          onClick={onClick}
        >
          {children}
        </Button>
      </span>
      <span className={responsiveButtonWithIconClasses}>
        <Button
          dark={dark}
          size={size}
          variant={variant}
          iconButton
          disabled={disabled}
          onClick={onClick}
        >
          {icon}
        </Button>
      </span>
    </>
  )
}

export { ResponsiveButton }
