import { slugify } from '../routes/helpers/slugify'
import { ProductTabOptions } from './pdp.type'
import type { ProductTab } from './pdp.type'
import type { ApplicationReactivity } from '@abcam-web/search/public/utilities/product.type'

export type Drawers =
  | null
  | 'reviews'
  | 'publications'
  | 'product-recommendations'
  | 'recently-viewed'
  | 'product-tags'

export type DrawerView =
  | 'quickview'
  | 'publications'
  | 'reviews'
  | 'highlights'
  | 'hazards'

export type ApplicationTab = ApplicationReactivity & { slug: string }

export type PdpUIState = {
  tab: ProductTab
  drawer: Drawers
  reviewId: null | string
  overlay: null | 'images' | 'alternative-product'
  application: null | string
  reactiveSpecies: null | string
  previewProductCode: null | string
  drawerView: DrawerView | null
}

export const PDP_DEFAULT_STATE: PdpUIState = {
  tab: ProductTabOptions.OVERVIEW,
  drawer: null,
  reviewId: null,
  overlay: null,
  application: 'all',
  reactiveSpecies: null,
  previewProductCode: null,
  drawerView: null,
}

export const stateToHash = (state: PdpUIState): string =>
  new URLSearchParams(
    Object.entries(state)
      .filter(
        ([key, value]) =>
          value && value !== PDP_DEFAULT_STATE[key as keyof PdpUIState]
      )
      .map(([key, value]) => [key, slugify((value || '').toLowerCase()) ?? ''])
  ).toString()

export const getPDPHash = (partialState: Partial<PdpUIState>) => {
  const hash = stateToHash({ ...PDP_DEFAULT_STATE, ...partialState })
  return hash ? `#${hash}` : ''
}
