import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
}

export type CategoriesSearchResults = {
  __typename?: 'CategoriesSearchResults'
  header: HeaderSearchResults
  items: Array<CategorySearchResults>
  totalCategories: Scalars['Int']
  totalProducts: Scalars['Int']
}

export type CategorySearchResults = {
  __typename?: 'CategorySearchResults'
  categoryType: Scalars['String']
  facetCategories: Array<FacetSearchResults>
  totalProducts: Scalars['Int']
}

export type ConjugationSearchResults = {
  __typename?: 'ConjugationSearchResults'
  emission?: Maybe<Scalars['Int']>
  excitation?: Maybe<Scalars['Int']>
  label?: Maybe<Scalars['String']>
}

export type ContentFacetRecommendationResults = {
  __typename?: 'ContentFacetRecommendationResults'
  count: Scalars['Int']
  facets: Array<FacetSearchResults>
}

export type ContentItem = {
  __typename?: 'ContentItem'
  contentType: Scalars['String']
  country: Scalars['String']
  introduction?: Maybe<Scalars['String']>
  language: Scalars['String']
  lastModified: Scalars['String']
  slug: Scalars['String']
  summary?: Maybe<Scalars['String']>
  title: Scalars['String']
}

export type ContentSearchResults = {
  __typename?: 'ContentSearchResults'
  facets: Array<FacetSearchResults>
  header: HeaderSearchResults
  items: Array<ContentItem>
}

export type EntitiesSearchResults = {
  __typename?: 'EntitiesSearchResults'
  categories: Array<CategorySearchResults>
  entitySynonyms: Array<Scalars['String']>
  label: Scalars['String']
  slug?: Maybe<Scalars['String']>
  totalCategories: Scalars['Int']
  totalProducts: Scalars['Int']
  type: Scalars['String']
}

export type FacetInput = {
  label: Scalars['String']
  type: Scalars['String']
}

export type FacetRecommendationResults = {
  __typename?: 'FacetRecommendationResults'
  count: Scalars['Int']
  facets: Array<FacetSearchResults>
}

export type FacetSearchResults = {
  __typename?: 'FacetSearchResults'
  displayOption?: Maybe<Scalars['String']>
  type: Scalars['String']
  values: Array<ValueSearchResults>
}

export type HandlingHazard = {
  __typename?: 'HandlingHazard'
  label: Scalars['String']
  type: Scalars['String']
  value: Scalars['String']
}

export type HeaderSearchResults = {
  __typename?: 'HeaderSearchResults'
  count: Scalars['Int']
}

export type LocalisedField = {
  __typename?: 'LocalisedField'
  en: Scalars['String']
  ja: Scalars['String']
  zh: Scalars['String']
}

export type ProductSearchResults = {
  __typename?: 'ProductSearchResults'
  applications: Array<Scalars['String']>
  assayPlatform?: Maybe<Scalars['String']>
  assayTime?: Maybe<Scalars['String']>
  assayType?: Maybe<Scalars['String']>
  biologicalAction: Array<Scalars['String']>
  carrierFree?: Maybe<Scalars['String']>
  casNumber?: Maybe<Scalars['String']>
  categoryType: Scalars['String']
  cellPermeable?: Maybe<Scalars['String']>
  cellType?: Maybe<Scalars['String']>
  chemicalType: Array<Scalars['String']>
  conjugation?: Maybe<ConjugationSearchResults>
  detectionMethod?: Maybe<Scalars['String']>
  expressionSystem?: Maybe<Scalars['String']>
  form?: Maybe<Scalars['String']>
  generalRange?: Maybe<Scalars['String']>
  handlingHazards: Array<HandlingHazard>
  hostSpecies?: Maybe<Scalars['String']>
  imageCount: Scalars['Int']
  itemClass: Scalars['String']
  localisedName: LocalisedField
  measurementMethod?: Maybe<Scalars['String']>
  nature?: Maybe<Scalars['String']>
  preAdsorbed?: Maybe<Scalars['String']>
  productCode: Scalars['String']
  productName: Scalars['String']
  productSlug: Scalars['String']
  productTags: Array<ProductTagSearchResults>
  publicationCount: Scalars['Int']
  reactiveSpecies: Array<Scalars['String']>
  reviewsSummary: ReviewsSummary
  sampleTypes: Array<Scalars['String']>
  seoClassLevelOne: Scalars['String']
  solubility?: Maybe<Array<Scalars['String']>>
  speciesOfOrigin?: Maybe<Scalars['String']>
  speciesOrOrganism?: Maybe<Scalars['String']>
  targetIsotypes: Array<Scalars['String']>
  targetSpecies?: Maybe<Scalars['String']>
  targetSpecificity?: Maybe<Scalars['String']>
  targets: Array<Scalars['String']>
  trialSizeExclusiveCountries: Array<Scalars['String']>
}

export type ProductTagSearchResults = {
  __typename?: 'ProductTagSearchResults'
  tagCode: Scalars['String']
}

export type ProductsSearchResults = {
  __typename?: 'ProductsSearchResults'
  facets: Array<FacetSearchResults>
  header: HeaderSearchResults
  items: Array<ProductSearchResults>
  resultsCategory: Scalars['String']
}

export type Query = {
  __typename?: 'Query'
  _service: _Service
  contentFacetRecommendations: ContentFacetRecommendationResults
  contentSearch: ContentSearchResults
  facetRecommendations: FacetRecommendationResults
  search: SearchResults
  searchBySlug: EntitiesSearchResults
  suggestions: Array<SuggestionSearchResult>
}

export type QueryContentFacetRecommendationsArgs = {
  query: SimpleSearchRequestInput
}

export type QueryContentSearchArgs = {
  query: SearchRequestInput
}

export type QueryFacetRecommendationsArgs = {
  query: SimpleSearchRequestInput
}

export type QuerySearchArgs = {
  options?: InputMaybe<SearchRequestOptions>
  query: SearchRequestInput
}

export type QuerySearchBySlugArgs = {
  slug: Scalars['String']
  targetIdentifier?: InputMaybe<Scalars['String']>
  type: Scalars['String']
}

export type QuerySuggestionsArgs = {
  query: SimpleSearchRequestInput
}

export type ReviewsSummary = {
  __typename?: 'ReviewsSummary'
  aggregatedRating: Scalars['Float']
  numberOfReviews: Scalars['Int']
}

export type SearchRequestInput = {
  facets?: InputMaybe<Array<FacetInput>>
  keywords?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  sort?: InputMaybe<Scalars['String']>
  sortOrder?: InputMaybe<Scalars['String']>
}

export type SearchRequestOptions = {
  mixed?: InputMaybe<Scalars['Boolean']>
}

export type SearchResults =
  | CategoriesSearchResults
  | EntitiesSearchResults
  | ProductsSearchResults

export type SimpleSearchRequestInput = {
  facets?: InputMaybe<Array<FacetInput>>
  keywords?: InputMaybe<Scalars['String']>
}

export type SuggestionSearchResult = {
  __typename?: 'SuggestionSearchResult'
  alternativeLabel?: Maybe<Scalars['String']>
  label: Scalars['String']
  type: Scalars['String']
}

export type ValueSearchResults = {
  __typename?: 'ValueSearchResults'
  count: Scalars['Int']
  label: Scalars['String']
}

export type _Service = {
  __typename?: '_Service'
  /** The sdl representing the federated service capabilities. Includes federation directives, removes federation types, and includes rest of full schema after schema directives have been applied */
  sdl?: Maybe<Scalars['String']>
}

export type ContentFacetRecommendationsQueryVariables = Exact<{
  query: SimpleSearchRequestInput
}>

export type ContentFacetRecommendationsQuery = {
  __typename?: 'Query'
  contentFacetRecommendations: {
    __typename?: 'ContentFacetRecommendationResults'
    count: number
    facets: Array<{
      __typename?: 'FacetSearchResults'
      displayOption?: string | null
      type: string
      values: Array<{
        __typename?: 'ValueSearchResults'
        label: string
        count: number
      }>
    }>
  }
}

export type ContentSearchFragmentFragment = {
  __typename?: 'ContentSearchResults'
  items: Array<{
    __typename?: 'ContentItem'
    contentType: string
    slug: string
    title: string
    summary?: string | null
    introduction?: string | null
    language: string
    country: string
    lastModified: string
  }>
  facets: Array<{
    __typename?: 'FacetSearchResults'
    type: string
    displayOption?: string | null
    values: Array<{
      __typename?: 'ValueSearchResults'
      label: string
      count: number
    }>
  }>
  header: { __typename?: 'HeaderSearchResults'; count: number }
}

export type ProductsSearchFragmentFragment = {
  __typename?: 'ProductsSearchResults'
  header: { __typename?: 'HeaderSearchResults'; count: number }
  items: Array<{
    __typename?: 'ProductSearchResults'
    productCode: string
    productName: string
    categoryType: string
    assayType?: string | null
    imageCount: number
    publicationCount: number
    reactiveSpecies: Array<string>
    applications: Array<string>
    generalRange?: string | null
    assayTime?: string | null
    trialSizeExclusiveCountries: Array<string>
    assayPlatform?: string | null
    sampleTypes: Array<string>
    detectionMethod?: string | null
    measurementMethod?: string | null
    itemClass: string
    nature?: string | null
    expressionSystem?: string | null
    speciesOfOrigin?: string | null
    speciesOrOrganism?: string | null
    cellType?: string | null
    hostSpecies?: string | null
    biologicalAction: Array<string>
    solubility?: Array<string> | null
    form?: string | null
    cellPermeable?: string | null
    productSlug: string
    seoClassLevelOne: string
    targets: Array<string>
    conjugation?: {
      __typename?: 'ConjugationSearchResults'
      label?: string | null
    } | null
    productTags: Array<{
      __typename?: 'ProductTagSearchResults'
      tagCode: string
    }>
    reviewsSummary: {
      __typename?: 'ReviewsSummary'
      aggregatedRating: number
      numberOfReviews: number
    }
    handlingHazards: Array<{
      __typename?: 'HandlingHazard'
      label: string
      value: string
      type: string
    }>
  }>
  facets: Array<{
    __typename?: 'FacetSearchResults'
    type: string
    displayOption?: string | null
    values: Array<{
      __typename?: 'ValueSearchResults'
      count: number
      label: string
    }>
  }>
}

export type EntitiesSearchFragmentFragment = {
  __typename?: 'EntitiesSearchResults'
  label: string
  slug?: string | null
  type: string
  totalProducts: number
  categories: Array<{
    __typename?: 'CategorySearchResults'
    categoryType: string
    totalProducts: number
    facetCategories: Array<{
      __typename?: 'FacetSearchResults'
      type: string
      values: Array<{
        __typename?: 'ValueSearchResults'
        label: string
        count: number
      }>
    }>
  }>
}

export type CategoriesSearchFragmentFragment = {
  __typename?: 'CategoriesSearchResults'
  header: { __typename?: 'HeaderSearchResults'; count: number }
  items: Array<{
    __typename?: 'CategorySearchResults'
    categoryType: string
    totalProducts: number
    facetCategories: Array<{
      __typename?: 'FacetSearchResults'
      type: string
      values: Array<{
        __typename?: 'ValueSearchResults'
        label: string
        count: number
      }>
    }>
  }>
}

export type Browse_SearchQueryVariables = Exact<{
  query: SearchRequestInput
  options?: InputMaybe<SearchRequestOptions>
}>

export type Browse_SearchQuery = {
  __typename?: 'Query'
  search:
    | {
        __typename: 'CategoriesSearchResults'
        header: { __typename?: 'HeaderSearchResults'; count: number }
        items: Array<{
          __typename?: 'CategorySearchResults'
          categoryType: string
          totalProducts: number
          facetCategories: Array<{
            __typename?: 'FacetSearchResults'
            type: string
            values: Array<{
              __typename?: 'ValueSearchResults'
              label: string
              count: number
            }>
          }>
        }>
      }
    | {
        __typename: 'EntitiesSearchResults'
        label: string
        slug?: string | null
        type: string
        totalProducts: number
        categories: Array<{
          __typename?: 'CategorySearchResults'
          categoryType: string
          totalProducts: number
          facetCategories: Array<{
            __typename?: 'FacetSearchResults'
            type: string
            values: Array<{
              __typename?: 'ValueSearchResults'
              label: string
              count: number
            }>
          }>
        }>
      }
    | {
        __typename: 'ProductsSearchResults'
        header: { __typename?: 'HeaderSearchResults'; count: number }
        items: Array<{
          __typename?: 'ProductSearchResults'
          productCode: string
          productName: string
          categoryType: string
          assayType?: string | null
          imageCount: number
          publicationCount: number
          reactiveSpecies: Array<string>
          applications: Array<string>
          generalRange?: string | null
          assayTime?: string | null
          trialSizeExclusiveCountries: Array<string>
          assayPlatform?: string | null
          sampleTypes: Array<string>
          detectionMethod?: string | null
          measurementMethod?: string | null
          itemClass: string
          nature?: string | null
          expressionSystem?: string | null
          speciesOfOrigin?: string | null
          speciesOrOrganism?: string | null
          cellType?: string | null
          hostSpecies?: string | null
          biologicalAction: Array<string>
          solubility?: Array<string> | null
          form?: string | null
          cellPermeable?: string | null
          productSlug: string
          seoClassLevelOne: string
          targets: Array<string>
          conjugation?: {
            __typename?: 'ConjugationSearchResults'
            label?: string | null
          } | null
          productTags: Array<{
            __typename?: 'ProductTagSearchResults'
            tagCode: string
          }>
          reviewsSummary: {
            __typename?: 'ReviewsSummary'
            aggregatedRating: number
            numberOfReviews: number
          }
          handlingHazards: Array<{
            __typename?: 'HandlingHazard'
            label: string
            value: string
            type: string
          }>
        }>
        facets: Array<{
          __typename?: 'FacetSearchResults'
          type: string
          displayOption?: string | null
          values: Array<{
            __typename?: 'ValueSearchResults'
            count: number
            label: string
          }>
        }>
      }
}

export type ContentSearchQueryVariables = Exact<{
  query: SearchRequestInput
}>

export type ContentSearchQuery = {
  __typename?: 'Query'
  contentSearch: {
    __typename?: 'ContentSearchResults'
    items: Array<{
      __typename?: 'ContentItem'
      contentType: string
      slug: string
      title: string
      summary?: string | null
      introduction?: string | null
      language: string
      country: string
      lastModified: string
    }>
    facets: Array<{
      __typename?: 'FacetSearchResults'
      type: string
      displayOption?: string | null
      values: Array<{
        __typename?: 'ValueSearchResults'
        label: string
        count: number
      }>
    }>
    header: { __typename?: 'HeaderSearchResults'; count: number }
  }
}

export type ProductAndContentSearchQueryVariables = Exact<{
  query: SearchRequestInput
  options?: InputMaybe<SearchRequestOptions>
}>

export type ProductAndContentSearchQuery = {
  __typename?: 'Query'
  search:
    | {
        __typename: 'CategoriesSearchResults'
        header: { __typename?: 'HeaderSearchResults'; count: number }
        items: Array<{
          __typename?: 'CategorySearchResults'
          categoryType: string
          totalProducts: number
          facetCategories: Array<{
            __typename?: 'FacetSearchResults'
            type: string
            values: Array<{
              __typename?: 'ValueSearchResults'
              label: string
              count: number
            }>
          }>
        }>
      }
    | {
        __typename: 'EntitiesSearchResults'
        label: string
        slug?: string | null
        type: string
        totalProducts: number
        categories: Array<{
          __typename?: 'CategorySearchResults'
          categoryType: string
          totalProducts: number
          facetCategories: Array<{
            __typename?: 'FacetSearchResults'
            type: string
            values: Array<{
              __typename?: 'ValueSearchResults'
              label: string
              count: number
            }>
          }>
        }>
      }
    | {
        __typename: 'ProductsSearchResults'
        header: { __typename?: 'HeaderSearchResults'; count: number }
        items: Array<{
          __typename?: 'ProductSearchResults'
          productCode: string
          productName: string
          categoryType: string
          assayType?: string | null
          imageCount: number
          publicationCount: number
          reactiveSpecies: Array<string>
          applications: Array<string>
          generalRange?: string | null
          assayTime?: string | null
          trialSizeExclusiveCountries: Array<string>
          assayPlatform?: string | null
          sampleTypes: Array<string>
          detectionMethod?: string | null
          measurementMethod?: string | null
          itemClass: string
          nature?: string | null
          expressionSystem?: string | null
          speciesOfOrigin?: string | null
          speciesOrOrganism?: string | null
          cellType?: string | null
          hostSpecies?: string | null
          biologicalAction: Array<string>
          solubility?: Array<string> | null
          form?: string | null
          cellPermeable?: string | null
          productSlug: string
          seoClassLevelOne: string
          targets: Array<string>
          conjugation?: {
            __typename?: 'ConjugationSearchResults'
            label?: string | null
          } | null
          productTags: Array<{
            __typename?: 'ProductTagSearchResults'
            tagCode: string
          }>
          reviewsSummary: {
            __typename?: 'ReviewsSummary'
            aggregatedRating: number
            numberOfReviews: number
          }
          handlingHazards: Array<{
            __typename?: 'HandlingHazard'
            label: string
            value: string
            type: string
          }>
        }>
        facets: Array<{
          __typename?: 'FacetSearchResults'
          type: string
          displayOption?: string | null
          values: Array<{
            __typename?: 'ValueSearchResults'
            count: number
            label: string
          }>
        }>
      }
  contentSearch: {
    __typename?: 'ContentSearchResults'
    items: Array<{
      __typename?: 'ContentItem'
      contentType: string
      slug: string
      title: string
      summary?: string | null
      introduction?: string | null
      language: string
      country: string
      lastModified: string
    }>
    facets: Array<{
      __typename?: 'FacetSearchResults'
      type: string
      displayOption?: string | null
      values: Array<{
        __typename?: 'ValueSearchResults'
        label: string
        count: number
      }>
    }>
    header: { __typename?: 'HeaderSearchResults'; count: number }
  }
}

export type Browse_RecommendedFacetsQueryVariables = Exact<{
  query: SimpleSearchRequestInput
}>

export type Browse_RecommendedFacetsQuery = {
  __typename?: 'Query'
  facetRecommendations: {
    __typename?: 'FacetRecommendationResults'
    count: number
    facets: Array<{
      __typename?: 'FacetSearchResults'
      type: string
      values: Array<{
        __typename?: 'ValueSearchResults'
        count: number
        label: string
      }>
    }>
  }
}

export type Browse_SuggestionsQueryVariables = Exact<{
  query: SimpleSearchRequestInput
}>

export type Browse_SuggestionsQuery = {
  __typename?: 'Query'
  suggestions: Array<{
    __typename?: 'SuggestionSearchResult'
    label: string
    type: string
    alternativeLabel?: string | null
  }>
}

export type Browse_SearchBySlugQueryVariables = Exact<{
  slug: Scalars['String']
  type: Scalars['String']
  targetIdentifier?: InputMaybe<Scalars['String']>
}>

export type Browse_SearchBySlugQuery = {
  __typename?: 'Query'
  searchBySlug: {
    __typename: 'EntitiesSearchResults'
    label: string
    slug?: string | null
    type: string
    totalProducts: number
    categories: Array<{
      __typename?: 'CategorySearchResults'
      categoryType: string
      totalProducts: number
      facetCategories: Array<{
        __typename?: 'FacetSearchResults'
        type: string
        values: Array<{
          __typename?: 'ValueSearchResults'
          label: string
          count: number
        }>
      }>
    }>
  }
}

export const ContentSearchFragmentFragmentDoc = gql`
  fragment ContentSearchFragment on ContentSearchResults {
    items {
      contentType
      slug
      title
      summary
      introduction
      language
      country
      lastModified
    }
    facets {
      type
      displayOption
      values {
        label
        count
      }
    }
    header {
      count
    }
  }
`
export const ProductsSearchFragmentFragmentDoc = gql`
  fragment ProductsSearchFragment on ProductsSearchResults {
    header {
      count
    }
    items {
      productCode
      productName
      categoryType
      assayType
      imageCount
      publicationCount
      conjugation {
        label
      }
      reactiveSpecies
      applications
      generalRange
      assayTime
      productTags {
        tagCode
      }
      trialSizeExclusiveCountries
      assayPlatform
      sampleTypes
      detectionMethod
      measurementMethod
      itemClass
      nature
      expressionSystem
      speciesOfOrigin
      speciesOrOrganism
      cellType
      hostSpecies
      biologicalAction
      solubility
      form
      cellPermeable
      productSlug
      seoClassLevelOne
      targets
      reviewsSummary {
        aggregatedRating
        numberOfReviews
      }
      handlingHazards {
        label
        value
        type
      }
    }
    facets {
      type
      displayOption
      values {
        count
        label
      }
    }
  }
`
export const EntitiesSearchFragmentFragmentDoc = gql`
  fragment EntitiesSearchFragment on EntitiesSearchResults {
    label
    slug
    type
    totalProducts
    categories {
      categoryType
      totalProducts
      facetCategories {
        type
        values {
          label
          count
        }
      }
    }
  }
`
export const CategoriesSearchFragmentFragmentDoc = gql`
  fragment CategoriesSearchFragment on CategoriesSearchResults {
    header {
      count
    }
    items {
      categoryType
      totalProducts
      facetCategories {
        type
        values {
          label
          count
        }
      }
    }
  }
`
export const ContentFacetRecommendationsDocument = gql`
  query ContentFacetRecommendations($query: SimpleSearchRequestInput!)
  @endpoint(name: "search") {
    contentFacetRecommendations(query: $query) {
      count
      facets {
        displayOption
        type
        values {
          label
          count
        }
      }
    }
  }
`

/**
 * __useContentFacetRecommendationsQuery__
 *
 * To run a query within a React component, call `useContentFacetRecommendationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentFacetRecommendationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentFacetRecommendationsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useContentFacetRecommendationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContentFacetRecommendationsQuery,
    ContentFacetRecommendationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ContentFacetRecommendationsQuery,
    ContentFacetRecommendationsQueryVariables
  >(ContentFacetRecommendationsDocument, options)
}
export function useContentFacetRecommendationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContentFacetRecommendationsQuery,
    ContentFacetRecommendationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ContentFacetRecommendationsQuery,
    ContentFacetRecommendationsQueryVariables
  >(ContentFacetRecommendationsDocument, options)
}
export type ContentFacetRecommendationsQueryHookResult = ReturnType<
  typeof useContentFacetRecommendationsQuery
>
export type ContentFacetRecommendationsLazyQueryHookResult = ReturnType<
  typeof useContentFacetRecommendationsLazyQuery
>
export type ContentFacetRecommendationsQueryResult = Apollo.QueryResult<
  ContentFacetRecommendationsQuery,
  ContentFacetRecommendationsQueryVariables
>
export const Browse_SearchDocument = gql`
  query BROWSE_Search(
    $query: SearchRequestInput!
    $options: SearchRequestOptions
  ) @endpoint(name: "search") {
    search(query: $query, options: $options) {
      __typename
      ... on ProductsSearchResults {
        ...ProductsSearchFragment
      }
      ... on EntitiesSearchResults {
        ...EntitiesSearchFragment
      }
      ... on CategoriesSearchResults {
        ...CategoriesSearchFragment
      }
    }
  }
  ${ProductsSearchFragmentFragmentDoc}
  ${EntitiesSearchFragmentFragmentDoc}
  ${CategoriesSearchFragmentFragmentDoc}
`

/**
 * __useBrowse_SearchQuery__
 *
 * To run a query within a React component, call `useBrowse_SearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrowse_SearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrowse_SearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useBrowse_SearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    Browse_SearchQuery,
    Browse_SearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Browse_SearchQuery, Browse_SearchQueryVariables>(
    Browse_SearchDocument,
    options
  )
}
export function useBrowse_SearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Browse_SearchQuery,
    Browse_SearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Browse_SearchQuery, Browse_SearchQueryVariables>(
    Browse_SearchDocument,
    options
  )
}
export type Browse_SearchQueryHookResult = ReturnType<
  typeof useBrowse_SearchQuery
>
export type Browse_SearchLazyQueryHookResult = ReturnType<
  typeof useBrowse_SearchLazyQuery
>
export type Browse_SearchQueryResult = Apollo.QueryResult<
  Browse_SearchQuery,
  Browse_SearchQueryVariables
>
export const ContentSearchDocument = gql`
  query ContentSearch($query: SearchRequestInput!) @endpoint(name: "search") {
    contentSearch(query: $query) {
      ...ContentSearchFragment
    }
  }
  ${ContentSearchFragmentFragmentDoc}
`

/**
 * __useContentSearchQuery__
 *
 * To run a query within a React component, call `useContentSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useContentSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContentSearchQuery,
    ContentSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ContentSearchQuery, ContentSearchQueryVariables>(
    ContentSearchDocument,
    options
  )
}
export function useContentSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContentSearchQuery,
    ContentSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ContentSearchQuery, ContentSearchQueryVariables>(
    ContentSearchDocument,
    options
  )
}
export type ContentSearchQueryHookResult = ReturnType<
  typeof useContentSearchQuery
>
export type ContentSearchLazyQueryHookResult = ReturnType<
  typeof useContentSearchLazyQuery
>
export type ContentSearchQueryResult = Apollo.QueryResult<
  ContentSearchQuery,
  ContentSearchQueryVariables
>
export const ProductAndContentSearchDocument = gql`
  query ProductAndContentSearch(
    $query: SearchRequestInput!
    $options: SearchRequestOptions
  ) @endpoint(name: "search") {
    search(query: $query, options: $options) {
      __typename
      ... on ProductsSearchResults {
        ...ProductsSearchFragment
      }
      ... on EntitiesSearchResults {
        ...EntitiesSearchFragment
      }
      ... on CategoriesSearchResults {
        ...CategoriesSearchFragment
      }
    }
    contentSearch(query: $query) {
      ...ContentSearchFragment
    }
  }
  ${ProductsSearchFragmentFragmentDoc}
  ${EntitiesSearchFragmentFragmentDoc}
  ${CategoriesSearchFragmentFragmentDoc}
  ${ContentSearchFragmentFragmentDoc}
`

/**
 * __useProductAndContentSearchQuery__
 *
 * To run a query within a React component, call `useProductAndContentSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductAndContentSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductAndContentSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useProductAndContentSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProductAndContentSearchQuery,
    ProductAndContentSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ProductAndContentSearchQuery,
    ProductAndContentSearchQueryVariables
  >(ProductAndContentSearchDocument, options)
}
export function useProductAndContentSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductAndContentSearchQuery,
    ProductAndContentSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ProductAndContentSearchQuery,
    ProductAndContentSearchQueryVariables
  >(ProductAndContentSearchDocument, options)
}
export type ProductAndContentSearchQueryHookResult = ReturnType<
  typeof useProductAndContentSearchQuery
>
export type ProductAndContentSearchLazyQueryHookResult = ReturnType<
  typeof useProductAndContentSearchLazyQuery
>
export type ProductAndContentSearchQueryResult = Apollo.QueryResult<
  ProductAndContentSearchQuery,
  ProductAndContentSearchQueryVariables
>
export const Browse_RecommendedFacetsDocument = gql`
  query BROWSE_RecommendedFacets($query: SimpleSearchRequestInput!)
  @endpoint(name: "search") {
    facetRecommendations(query: $query) {
      count
      facets {
        type
        values {
          count
          label
        }
      }
    }
  }
`

/**
 * __useBrowse_RecommendedFacetsQuery__
 *
 * To run a query within a React component, call `useBrowse_RecommendedFacetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrowse_RecommendedFacetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrowse_RecommendedFacetsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useBrowse_RecommendedFacetsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Browse_RecommendedFacetsQuery,
    Browse_RecommendedFacetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Browse_RecommendedFacetsQuery,
    Browse_RecommendedFacetsQueryVariables
  >(Browse_RecommendedFacetsDocument, options)
}
export function useBrowse_RecommendedFacetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Browse_RecommendedFacetsQuery,
    Browse_RecommendedFacetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Browse_RecommendedFacetsQuery,
    Browse_RecommendedFacetsQueryVariables
  >(Browse_RecommendedFacetsDocument, options)
}
export type Browse_RecommendedFacetsQueryHookResult = ReturnType<
  typeof useBrowse_RecommendedFacetsQuery
>
export type Browse_RecommendedFacetsLazyQueryHookResult = ReturnType<
  typeof useBrowse_RecommendedFacetsLazyQuery
>
export type Browse_RecommendedFacetsQueryResult = Apollo.QueryResult<
  Browse_RecommendedFacetsQuery,
  Browse_RecommendedFacetsQueryVariables
>
export const Browse_SuggestionsDocument = gql`
  query BROWSE_Suggestions($query: SimpleSearchRequestInput!)
  @endpoint(name: "search") {
    suggestions(query: $query) {
      label
      type
      alternativeLabel
    }
  }
`

/**
 * __useBrowse_SuggestionsQuery__
 *
 * To run a query within a React component, call `useBrowse_SuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrowse_SuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrowse_SuggestionsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useBrowse_SuggestionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Browse_SuggestionsQuery,
    Browse_SuggestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Browse_SuggestionsQuery,
    Browse_SuggestionsQueryVariables
  >(Browse_SuggestionsDocument, options)
}
export function useBrowse_SuggestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Browse_SuggestionsQuery,
    Browse_SuggestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Browse_SuggestionsQuery,
    Browse_SuggestionsQueryVariables
  >(Browse_SuggestionsDocument, options)
}
export type Browse_SuggestionsQueryHookResult = ReturnType<
  typeof useBrowse_SuggestionsQuery
>
export type Browse_SuggestionsLazyQueryHookResult = ReturnType<
  typeof useBrowse_SuggestionsLazyQuery
>
export type Browse_SuggestionsQueryResult = Apollo.QueryResult<
  Browse_SuggestionsQuery,
  Browse_SuggestionsQueryVariables
>
export const Browse_SearchBySlugDocument = gql`
  query BROWSE_SearchBySlug(
    $slug: String!
    $type: String!
    $targetIdentifier: String
  ) @endpoint(name: "search") {
    searchBySlug(
      slug: $slug
      type: $type
      targetIdentifier: $targetIdentifier
    ) {
      __typename
      label
      slug
      type
      totalProducts
      categories {
        categoryType
        totalProducts
        facetCategories {
          type
          values {
            label
            count
          }
        }
      }
    }
  }
`

/**
 * __useBrowse_SearchBySlugQuery__
 *
 * To run a query within a React component, call `useBrowse_SearchBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrowse_SearchBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrowse_SearchBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      type: // value for 'type'
 *      targetIdentifier: // value for 'targetIdentifier'
 *   },
 * });
 */
export function useBrowse_SearchBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    Browse_SearchBySlugQuery,
    Browse_SearchBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Browse_SearchBySlugQuery,
    Browse_SearchBySlugQueryVariables
  >(Browse_SearchBySlugDocument, options)
}
export function useBrowse_SearchBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Browse_SearchBySlugQuery,
    Browse_SearchBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Browse_SearchBySlugQuery,
    Browse_SearchBySlugQueryVariables
  >(Browse_SearchBySlugDocument, options)
}
export type Browse_SearchBySlugQueryHookResult = ReturnType<
  typeof useBrowse_SearchBySlugQuery
>
export type Browse_SearchBySlugLazyQueryHookResult = ReturnType<
  typeof useBrowse_SearchBySlugLazyQuery
>
export type Browse_SearchBySlugQueryResult = Apollo.QueryResult<
  Browse_SearchBySlugQuery,
  Browse_SearchBySlugQueryVariables
>
