import { GTMPageType, PageSubtype } from './gtm.enums'

const getPageType = (path: string): GTMPageType | '' => {
  const localePrefix = '(?:/[a-z]{2}-[a-z]{2})?'
  const slug = '[a-z0-9].+'

  const pageTypeMap = {
    [GTMPageType.HOMEPAGE]: [new RegExp(`^${localePrefix}/$`)],
    [GTMPageType.ABOUT]: [new RegExp(`^${localePrefix}/about`)],
    [GTMPageType.CONTACT_US]: [new RegExp(`^${localePrefix}/contact-us`)],
    [GTMPageType.AUTHENTICATION]: [new RegExp(`^/auth`)],
    [GTMPageType.ACCOUNT]: [new RegExp(`^/my-account`)],
    [GTMPageType.CHECKOUT]: [new RegExp(`^/shopping-basket`)],
    [GTMPageType.PDP]: [
      new RegExp(`^${localePrefix}/printed/`),
      new RegExp(`^${localePrefix}/products/${slug}/${slug}$`),
    ],
    [GTMPageType.SEARCH]: [
      new RegExp(`^${localePrefix}/search`),
      new RegExp(`^${localePrefix}/category/`),
      new RegExp(`^${localePrefix}/host-species/`),
    ],
    [GTMPageType.PRODUCT_CATEGORY_LP]: [
      new RegExp(`^${localePrefix}/products`),
      new RegExp(`^${localePrefix}/products/${slug}$`),
    ],
    [GTMPageType.ENTITY]: [new RegExp(`^${localePrefix}/targets/`)],
    [GTMPageType.LEGAL]: [new RegExp(`^${localePrefix}/legal`)],
    [GTMPageType.TECHNICAL_RESOURCES]: [
      new RegExp(`^${localePrefix}/technical-resources`),
    ],
    [GTMPageType.OFFERS_AND_INITIATIVES]: [
      new RegExp(`^${localePrefix}/offers-and-initiatives`),
    ],
  }

  const res = Object.entries(pageTypeMap).find(([_, regexes]) =>
    regexes.some((regex) => regex.test(path))
  )
  return res ? (res[0] as GTMPageType) : ''
}

const getPageSubType = (path: string): PageSubtype | null => {
  switch (true) {
    case path.includes('/search?'):
      return PageSubtype.SEARCH_RESULTS_PAGE
    case path.includes('/products/') &&
      !path.includes('#tab=datasheet') &&
      !path.includes('#tab=support'):
      return PageSubtype.OVERVIEW
    case path.includes('#tab=datasheet'):
      return PageSubtype.DATA_SHEET
    case path.includes('#tab=support'):
      return PageSubtype.SUPPORT
    case path.includes('/shopping-basket'):
      return PageSubtype.BASKET
    case path.includes('/legal/privacy-policy'):
      return PageSubtype.PRIVATE_POLICY
    case path.includes('/legal/cookie-policy'):
      return PageSubtype.COOKIE_POLICY
    case path.includes('/legal/terms-and-conditions'):
      return PageSubtype.TERMS_AND_CONDITIONS
    case path.includes('/legal/modern-slavery-statement'):
      return PageSubtype.MODERN_SLAVERY_STATEMENT
    case path.includes('/offers-and-initiatives/product-promise'):
      return PageSubtype.PRODUCT_PROMISE
    case path.includes('/offers-and-initiatives/product-reviews'):
      return PageSubtype.PRODUCT_REVIEWS
    case path.includes('/offers-and-initiatives/trial-program'):
      return PageSubtype.TRIAL_PROGRAM
    case path.includes('/technical-resources/applications'):
      return PageSubtype.APPLICATIONS
    case path.includes('/technical-resources/guides'):
      return PageSubtype.GUIDES
    case path.includes('/technical-resources/pathways'):
      return PageSubtype.PATHWAYS
    case path.includes('/technical-resources/protocols'):
      return PageSubtype.PROTOCOLS
    case path.includes('/technical-resources/troubleshooting'):
      return PageSubtype.TROUBLESHOOTING
    case path.includes('/technical-resources/product-overview'):
      return PageSubtype.PRODUCT_OVERVIEW
    default:
      return null
  }
}

export { getPageType, getPageSubType }
