import en from './en.json'
import ja from './ja.json'
import zh from './zh.json'

const messages = { en, ja, zh }

export default messages

// we need to keep this up to date with the languages we support
export type LangCode = 'en' | 'zh' // | 'fr' | 'de' | 'es' | 'it' | 'pt' | 'ja' | | 'ko'
