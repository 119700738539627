import { STAGE } from './stage-type'
import {
  Book,
  BookFilled,
  Cart,
  CartFilled,
  Page,
  PageFilled,
  Envelope,
  EnvelopeFilled,
  HomeIcon,
  SpeechBubbleIcon,
  SpeechBubbleFilledIcon,
  StarUnfilled2,
  StarFilled2,
  PersonIcon,
  PersonFilledIcon,
} from '@abcam-web/lego/ui/icons'

export type Routes =
  | 'account'
  | 'addressBook'
  | 'addressBookOld'
  | 'orders'
  | 'ordersV2'
  | 'billingHistory'
  | 'profile'
  | 'reviews'
  | 'inquiries'
  | 'rewardPoints'
  | 'impersonate'
  | 'whoami'

export interface NavMenuItem {
  label?: string
  labelId?: Routes
  href: string
  selected?: boolean
  className?: string
  urlPath: string
  hiddenOnStage?: STAGE[] // doesn't show an entry in the Nav Menu on these stages (the route is reachable if you type the url in the browser tho)
  unavailbleOnStage?: STAGE[] // redirects to home page on these stages
  hiddenOnDropdown?: boolean
  icon?: typeof HomeIcon
  filledIcon?: typeof HomeIcon
}

// TODO: update the hrefs
const navMenuItems: NavMenuItem[] = [
  {
    labelId: 'orders',
    href: '/orders',
    urlPath: 'orders',
    icon: Cart,
    filledIcon: CartFilled,
  },
  {
    labelId: 'ordersV2',
    href: '/orders-v2',
    hiddenOnStage: ['prod', 'preprod'],
    unavailbleOnStage: ['prod'],
    urlPath: 'orders-v2',
    icon: Cart,
    filledIcon: CartFilled,
  },
  {
    labelId: 'billingHistory',
    href: '/billing-history',
    hiddenOnStage: ['prod', 'preprod'],
    unavailbleOnStage: ['prod'],
    urlPath: 'billing-history',
    icon: Page,
    filledIcon: PageFilled,
  },
  {
    labelId: 'addressBook',
    href: '/address-book',
    urlPath: 'address-book',
    icon: Book,
    filledIcon: BookFilled,
  },
  {
    labelId: 'addressBookOld',
    href: '/address-book-old',
    urlPath: 'address-book-old',
    hiddenOnStage: ['prod', 'preprod'],
    unavailbleOnStage: ['prod'],
    icon: Book,
    filledIcon: BookFilled,
  },
  {
    labelId: 'inquiries',
    href: '/inquiries',
    urlPath: 'inquiries',
    icon: Envelope,
    filledIcon: EnvelopeFilled,
  },
  {
    labelId: 'reviews',
    href: '/reviews',
    urlPath: 'reviews',
    icon: SpeechBubbleIcon,
    filledIcon: SpeechBubbleFilledIcon,
  },
  {
    labelId: 'rewardPoints',
    href: '/reward-points',
    urlPath: 'reward-points',
    unavailbleOnStage: [],
    icon: StarUnfilled2,
    filledIcon: StarFilled2,
  },
  {
    labelId: 'profile',
    href: '/profile',
    urlPath: 'profile',
    icon: PersonIcon,
    filledIcon: PersonFilledIcon,
  },
  {
    labelId: 'impersonate',
    href: '/impersonate',
    urlPath: 'impersonate',
    hiddenOnStage: ['prod', 'preprod'],
    unavailbleOnStage: ['prod'],
  },
  {
    labelId: 'whoami',
    href: '/whoami',
    urlPath: 'whoami',
    hiddenOnStage: ['prod', 'preprod'],
    unavailbleOnStage: ['prod'],
  },
]

export { navMenuItems }
