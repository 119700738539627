export type UnknownObject = Record<string, unknown>

export const isDefined = (val: unknown): boolean => val != null

export const hasProperty = (obj: UnknownObject) => (property: string) =>
  isDefined(obj[property])

export const hasProperties = (obj: UnknownObject, properties: string[]) =>
  properties.every(hasProperty(obj))

export const isOfType =
  <T extends UnknownObject>(properties: string[]) =>
  (obj: UnknownObject): obj is T =>
    hasProperties(obj, properties)

export const isObject = (x: unknown) =>
  '[object Object]' === Object.prototype.toString.call(x)

export const isFunction = (x: unknown) =>
  '[object Function]' === Object.prototype.toString.call(x)
