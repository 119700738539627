import { isEntityType } from './entity/entity-type'
import { isCasNumber } from './product/is-cas-number.predicate'
import { isProductCode } from './product/is-product-code.predicate'
import type { FacetInput } from '@abcam-web/shared/data-access/search-schema'

type QueryParams = {
  facets: FacetInput[]
  keywords: string
}

export const isEntitiesSearchQuery = ({ facets, keywords }: QueryParams) => {
  return !keywords && facets.length === 1 && isEntityType(facets[0]?.type)
}

export const isCasNumberQuery = ({ facets, keywords }: QueryParams) => {
  return !facets.length && isCasNumber(keywords.trim())
}

export const isProductCodeQuery = ({ facets, keywords }: QueryParams) => {
  return !facets.length && isProductCode(keywords.trim())
}

export const isQueryWithCategory = ({ facets }: QueryParams) => {
  return Boolean(facets.find(({ type }) => type === 'categoryType'))
}
