import { useMutation, useQuery } from '@tanstack/react-query'
import {
  MerchantModel,
  CybersourceFieldModel,
  Options,
  ProcessUnresolvedOrdersRequest,
} from './api-hooks.types'
import { axiosPaymentsInstance, axiosEcommerceInstance } from './axios-client'
import { axiosECOMInstance } from '@abcam-web/shared/data-access/ecommerce-api-hooks'
import getConfig from 'next/config'

const PROXY_GATEWAY_URL = getConfig()?.publicRuntimeConfig?.PROXY_GATEWAY_URL
const PAYMENTS_URL = `${PROXY_GATEWAY_URL}/payments`

export const useMerchantQuery = (
  variables: { billToCountry: string },
  options?: Options
) => {
  const { billToCountry } = variables

  const merchantQuery = useQuery<MerchantModel>({
    enabled: (options && options.enabled) ?? true,
    queryKey: ['payments-get-merchant', { billToCountry }],
    refetchOnMount: false,
    queryFn: () =>
      axiosPaymentsInstance
        .post<MerchantModel>('get-merchant', { billToCountry })
        .then(({ data }) => {
          options?.onSuccess && options.onSuccess(data, { billToCountry })
          return data
        })
        .catch((error) => {
          options?.onError && options.onError(error)
          return {
            currency: '',
            merchantCode: '',
            supportedCards: [],
          }
        }),
  })

  return merchantQuery
}

export const useMerchantQueryOpro = (
  variables: { billToCountry: string },
  options?: Options
) => {
  const { billToCountry } = variables

  const merchantQuery = useQuery<MerchantModel>({
    enabled: (options && options.enabled) ?? true,
    queryKey: ['payments-get-merchant-opro', { billToCountry }],
    refetchOnMount: false,
    queryFn: () =>
      axiosECOMInstance
        .post<MerchantModel>(
          'get-merchant',
          { billToCountry },
          { baseURL: PAYMENTS_URL }
        )
        .then(({ data }) => {
          options?.onSuccess && options.onSuccess(data, { billToCountry })
          return data
        })
        .catch((error) => {
          options?.onError && options.onError(error)
          return {
            currency: '',
            merchantCode: '',
            supportedCards: [],
          }
        }),
  })

  return merchantQuery
}

export const useCybersourceQuery = (
  variables: {
    basketId: string
    locale: string
    isServedFromMyAccount?: boolean
    isServedByCheckout?: boolean
  },
  options?: Options
) => {
  const { basketId, locale, isServedByCheckout, isServedFromMyAccount } =
    variables

  const cybersourceFieldQuery = useQuery<CybersourceFieldModel>({
    enabled: (options && options.enabled) ?? true,
    queryKey: [
      'payments-get-secureacceptance-request',
      { basketId, locale, isServedByCheckout },
    ],
    queryFn: async () => {
      const { data } = await axiosPaymentsInstance.post<CybersourceFieldModel>(
        'get-secureacceptance-request',
        {
          basketId,
          locale,
          isServedFromMyAccount,
          ...(isServedByCheckout && { isServedByCheckout }),
        }
      )

      return data
    },
  })

  return cybersourceFieldQuery
}

export const useOrderQuery = (
  variables: { orderId: string },
  options?: Options
) => {
  const { orderId } = variables
  const orderQuery = useQuery({
    enabled: (options && options.enabled) ?? true,
    queryKey: ['payments-order', orderId],
    queryFn: async () => {
      const { data } = await axiosEcommerceInstance.get(`order/${orderId}`)

      return data
    },
  })
  return orderQuery
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const usePopulatePaymentDetailsMutation = (variables: { data: any }) => {
  const { data } = variables

  const populatePaymentDetailsMutation = useMutation({
    mutationKey: ['process-secureacceptance-response', data],
    mutationFn: async () =>
      await axiosPaymentsInstance.post(
        'process-secureacceptance-response',
        data
      ),
  })

  return populatePaymentDetailsMutation
}

export const useProcessUnresolvedOrdersMutation = (variables: {
  data: ProcessUnresolvedOrdersRequest[]
}) => {
  const { data } = variables

  const processUnresolvedOrdersMutation = useMutation({
    mutationKey: ['process-unresolved-orders', data],
    mutationFn: async () =>
      await axiosPaymentsInstance.post('process-unresolved-orders', data),
  })

  return processUnresolvedOrdersMutation
}
