import { getLanguageNameFromCode } from '@abcam-web/shared/utilities/localisation'
import { ProductService } from './product.service'
import type { SelectOption } from '@lego/ui/select'

export class SdsService {
  private productService: ProductService

  constructor() {
    this.productService = new ProductService()
  }

  public async getLanguages(
    productCode: string,
    countryCode: string
  ): Promise<SelectOption[]> {
    const payload = {
      productCode,
      countryCode,
    }
    const result = await this.productService.getSDS(payload)

    if (!result?.data?.document?.sds) {
      return []
    }

    const sdsDocuments = result?.data.document?.sds
    const unique = [...new Set(sdsDocuments.map((item) => item.languageCode))]

    return unique.map((doc) => ({
      key: doc,
      displayValue: getLanguageNameFromCode(doc || '') as string,
    })) as SelectOption[]
  }

  public async getSds(
    productCode: string,
    countryCode: string,
    languageCode: string
  ) {
    const payload = { productCode, countryCode }
    const result = await this.productService.getSDS(payload)

    if (result.error) {
      return []
    }

    return (
      result.data.document?.sds
        ?.filter((doc) => doc.languageCode === languageCode)
        .map((sds) => {
          return { name: sds.name, displayName: sds.displayName, url: sds.url }
        }) || []
    )
  }
}
