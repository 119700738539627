import { createSearchService } from '@abcam-web/search/data-access/search.service'
import { SearchMachine } from '@abcam-web/search/utilities/search-machine'
import { useInterpret } from '@xstate/react'
import { useRouter } from 'next/router'
import { createContext, useRef, useState } from 'react'

import type { NextRouter } from 'next/router'
import type { PropsWithChildren, RefObject } from 'react'
import type { ActorRefFrom } from 'xstate'

interface SearchStateContextType {
  searchService: ActorRefFrom<typeof SearchMachine>
  searchInput: SearchInput
  searchCount: number
  incrementSearchCount: () => void
}

type SearchInput = RefObject<HTMLInputElement>

let searchMachine: ReturnType<typeof SearchMachine>

const getSM = (router: NextRouter, inputRef: SearchInput) => {
  const searchService = createSearchService()

  if (!searchMachine)
    searchMachine = SearchMachine(searchService, router, inputRef)
  return searchMachine
}

export const SearchContext = createContext({} as SearchStateContextType)

export const SearchProvider = ({ children }: PropsWithChildren) => {
  const router = useRouter()
  const inputRef = useRef<HTMLInputElement>(null)
  const [searchCount, setSearchCount] = useState(0)

  const incrementSearchCount = () => {
    setSearchCount(searchCount + 1)
  }

  const machine = useInterpret(getSM(router, inputRef), {
    devTools: process.env.NODE_ENV !== 'production',
  })

  return (
    <SearchContext.Provider
      value={{
        searchService: machine,
        searchInput: inputRef,
        searchCount,
        incrementSearchCount,
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}
