export const TEST_KEYBOARD_EVENTS = {
  space: { key: ' ', charCode: 32 },
  enter: { key: 'Enter', charCode: 13 },
}

export const code = {
  ENTER: 'Enter',
  ARROW_DOWN: 'ArrowDown',
  ARROW_UP: 'ArrowUp',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  SPACE: 'Space',
  ESCAPE: 'Escape',
  TAB: 'Tab',
}

export const key = {
  ENTER: 'Enter',
  ARROW_DOWN: 'ArrowDown',
  ARROW_UP: 'ArrowUp',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  SPACE: ' ',
  ESCAPE: 'Escape',
  TAB: 'Tab',
}

export const event = {
  KEYUP: 'keyup',
  KEYDOWN: 'keydown',
}

export const isKeyupEvent = (evt: KeyboardEvent) => {
  return evt?.type === event.KEYUP
}

export const isKeydownEvent = (evt: KeyboardEvent) => {
  return evt?.type === event.KEYDOWN
}

export const isEnterEvent = (
  evt: KeyboardEvent | React.KeyboardEvent<unknown>
) => {
  return evt?.code === code.ENTER || evt?.key === key.ENTER
}

export const isTabEvent = (evt: KeyboardEvent) => {
  return evt?.code === code.TAB || evt?.key === key.TAB
}

export const isSpaceEvent = (evt: KeyboardEvent | React.KeyboardEvent) => {
  return evt?.code === code.SPACE || evt?.key === key.SPACE
}

export const isEscapeEvent = (evt: React.KeyboardEvent | KeyboardEvent) => {
  return evt?.code === code.ESCAPE || evt?.key === key.ESCAPE
}

export const isArrowUpEvent = (evt: KeyboardEvent) => {
  return evt?.code === code.ARROW_UP || evt?.key === key.ARROW_UP
}

export const isArrowDownEvent = (evt: React.KeyboardEvent | KeyboardEvent) => {
  return evt?.code === code.ARROW_DOWN || evt?.key === key.ARROW_DOWN
}

export const isArrowLeftEvent = (evt: KeyboardEvent) => {
  return evt?.code === code.ARROW_LEFT || evt?.key === key.ARROW_LEFT
}

export const isArrowRightEvent = (evt: KeyboardEvent) => {
  return evt?.code === code.ARROW_RIGHT || evt?.key === key.ARROW_RIGHT
}

export const keyboardVerticalNavigationHandler = (
  evt: KeyboardEvent,
  itemIndex: number,
  setItemIndex: (val: number) => void,
  maxLength: number
) => {
  const maxSelectedIndex = maxLength
  const minSelectedIndex = 0

  evt.preventDefault()
  evt.stopPropagation()

  if (isArrowUpEvent(evt) && itemIndex > minSelectedIndex) {
    return setItemIndex(itemIndex - 1)
  }
  if (isArrowDownEvent(evt) && itemIndex < maxSelectedIndex) {
    return setItemIndex(itemIndex + 1)
  }
}
