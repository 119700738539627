import cn from 'classnames'
import xss from 'xss'
import { cleanHTML } from './html-cleaner/html-cleaner'
import styles from './aem-html-content.module.css'
import type { AEMHTMLContentProps } from './aem-html-content.type'

export const AEMHTMLContent = ({
  render,
  classNames,
  testId,
  disableBuiltInStyles,
}: AEMHTMLContentProps) => {
  const safeHTML = xss(render, {
    // eslint-disable-next-line
    // @ts-ignore
    onTagAttr: (tag, name, value, isWhiteAttr) => {
      if (tag === 'a' && name === "href") {
        // Prepend "/" for relative links if not there
        if (value.indexOf('://') === -1 && value.indexOf('//') === -1 && !value.startsWith("/")) {
          return `href="/${value}"`
        }
      } 
    }
  })
  const parsedHTML = cleanHTML(safeHTML)
  return (
    <div
      data-testid={testId}
      className={cn(
        disableBuiltInStyles ? null : styles.aemHtmlContent,
        classNames
      )}
    >
      {parsedHTML}
    </div>
  )
}
