import { CheckIcon } from '@lego/ui/icons'
import { FC, PropsWithChildren, ReactNode } from 'react'

import styles from './menu-list.module.css'

interface OptionProps {
  content: ReactNode
}

type OptionsVariant = 'default' | 'selectedOption'

const Option: FC<PropsWithChildren> = ({ children }) => (
  <div data-cy="option-details" className="flex text-ui-medium">
    {children}
  </div>
)

const DefaultOption: FC<OptionProps> = ({ content }) => (
  <Option>{content}</Option>
)

const SelectedOption: FC<OptionProps> = ({ content }) => (
  <Option>
    <div className={styles.checkIcon}>
      <CheckIcon />
    </div>
    {content}
  </Option>
)

export const MenuListHeader: FC<OptionProps> = ({ content }) => (
  <div
    data-cy="menulist-header"
    className="sticky top-0 flex-col px-4 py-3 bg-grey-5 text-ui-medium"
  >
    {content}
    <p>{'(Added to order)'}</p>
  </div>
)

export const menuListOptions: Record<OptionsVariant, FC<OptionProps>> = {
  selectedOption: SelectedOption,
  default: DefaultOption,
}
