import { theme } from '@tailwind-preset-theme'
import { TWBreakpoint } from './viewport.types'

const breakpoints: Record<string, TWBreakpoint> = {
  ...theme.extend.screens,
  small: 680,
  medium: 800,
  large: 960,
}

export { breakpoints }
