import { LocalFeatureToggle } from './local-feature-toggle'
import type { Bootstrapable } from '@feature-toggles/interfaces/bootstrapable'
import type { FeatureToggleService as FeatureToggleServiceInterface } from '@feature-toggles/interfaces/feature-toggle.interface'

export class LocalFeatureToggleBootstrapable
  implements Bootstrapable<FeatureToggleServiceInterface>
{
  public bootstrap(): FeatureToggleServiceInterface {
    return new LocalFeatureToggle()
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public teardown() {}
}
