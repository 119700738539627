import { POSTCODE_REGEXES } from '../data'
import { BaseAddressSchema } from './base'

export const ThailandAddressSchema = {
  ...BaseAddressSchema,
  province: {
    type: 'string',
    required: true,
    oneOf: [
      'AMNAT CHAROEN',
      'ANG THONG',
      'BANGKOK',
      'BUENG KAN',
      'BURIRAM',
      'CHACHOENGSAO',
      'CHAI NAT',
      'CHAIYAPHUM',
      'CHANTHABURI',
      'CHIANG MAI',
      'CHIANG RAI',
      'CHONBURI',
      'CHUMPHON',
      'KALASIN',
      'KAMPHAENG PHET',
      'KANCHANABURI',
      'KHON KAEN',
      'KRABI',
      'LAMPANG',
      'LAMPHUN',
      'LOEI',
      'LOPBURI',
      'MAE HONG SON',
      'MAHA SARAKHAM',
      'MUKDAHAN',
      'NAKHON NAYOK',
      'NAKHON PATHOM',
      'NAKHON PHANOM',
      'NAKHON RATCHASIMA',
      'NAKHON SAWAN',
      'NAKHON SI THAMMARAT',
      'NAN',
      'NARATHIWAT',
      'NONG BUA LAMPHU',
      'NONG KHAI',
      'NONTHABURI',
      'PATHUM THANI',
      'PATTANI',
      'PHANG NGA',
      'PHATTHALUNG',
      'PHAYAO',
      'PHETCHABUN',
      'PHETCHABURI',
      'PHICHIT',
      'PHITSANULOK',
      'PHRA NAKHON SI AYUTTHAYA',
      'PHRAE',
      'PHUKET',
      'PRACHINBURI',
      'PRACHUAP KHIRI KHAN',
      'RANONG',
      'RATCHABURI',
      'RAYONG',
      'ROI ET',
      'SA KAEO',
      'SAKON NAKHON',
      'SAMUT PRAKAN',
      'SAMUT SAKHON',
      'SAMUT SONGKHRAM',
      'SARABURI',
      'SATUN',
      'SING BURI',
      'SISAKET',
      'SONGKHLA',
      'SUKHOTHAI',
      'SUPHAN BURI',
      'SURAT THANI',
      'SURIN',
      'TAK',
      'TRANG',
      'TRAT',
      'UBON RATCHATHANI',
      'UDON THANI',
      'UTHAI THANI',
      'UTTARADIT',
      'YALA',
      'YASOTHON',
    ],
  },

  postcode: {
    type: 'string',
    required: true,
    // combining all regexes in logical OR
    pattern: new RegExp(
      POSTCODE_REGEXES['TH'].map((exp) => `^${exp}$`).join('|'),
      'i'
    ),
  },
} as const

export type ThailandAddressSchemaType = typeof ThailandAddressSchema

export default ThailandAddressSchema
