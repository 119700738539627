import { createContext, useContext } from 'react'
import type { ApplicationTab } from '@abcam-web/search/utilities'
import type { usePdpUI } from './use-pdp-ui'

export const PDPContext = createContext<ReturnType<typeof usePdpUI>>({
  switchTabs: async () => undefined,
  setReviewsDrawer: async () => undefined,
  selectReactivityApplication: async () => undefined,
  togglePublicationsDrawer: async () => undefined,
  setShowImageOverlay: async () => undefined,
  openCrossSellDrawer: async () => undefined,
  openRecentlyViewedDrawer: async () => undefined,
  closePdpCardDrawer: async () => undefined,
  setProductDrawerView: async () => undefined,
  setTagDrawerView: async () => undefined,
  setReview: async () => undefined,
  setShowAlternativeProductOverlay: async () => undefined,
  pdpState: {
    tab: 'overview',
    drawer: null,
    reviewId: null,
    overlay: null,
    application: null,
    reactiveSpecies: null,
    previewProductCode: null,
    drawerView: null,
  },
  activeMainTabIndex: 0,
  applicationTabs: [],
  activeApplicationTabIndex: 0,
  activeApplication: {} as ApplicationTab,
  isChina: false,
})

export const usePdp = () => useContext(PDPContext)
