export enum FeatureToggleName {
  ContentSearch = 'content_search',
  CrossSellPdp = 'cross-sell-pdp',
  DistributorMarketInStockMessage = 'fe_distributor_market_in_stock_message',
  EnableAuth = 'enable_auth_browse',
  EnableMyAccountOrderLinkOnOrderConfirmation = 'enable_my_account_order_link_on_order_confirmation',
  EnableNonDirectMarkets = 'enable-non-direct-markets',
  EntityPageContentFetch = 'entity-page-content-fetch',
  GtmSetupInHead = 'gtm-setup-in-head',
  ManualPriceAdjustmentValidation = 'fe_be_manual_price_adjustment_validation',
  MultipleOrganisationsEnabled = 'fe_multiple_organisations_enabled',
  NewSearchOverlay = 'new_search_overlay',
  OproUseAppCues = 'opro-use-app-cues',
  ProxyGatewayEnabled = 'fe_proxy_gateway_enabled',
  SubmitPrepayOrderToOracle = 'submit-prepay-order-to-oracle',
  TempAssistContactUs = 'temp-assist-contact-us',
  TempBrowseAbreviews = 'temp-browse-abreviews',
  TempEnableDuplicateOrderCheckerModal = 'temp-enable-duplicate-order-checker-modal',
  TempEnablePromotions = 'temp-enable-promotions',
  TempOproAddPaymentCard = 'temp-opro-add-payment-card',
  TempOproChangeDeliveryDateEnabled = 'temp-opro-change-delivery-date-enabled',
  TempOproEnableDanaherAzureTenant = 'temp-opro-enable-danaher-azure-tenant',
  TempOproSpecifyQuoteExpiration = 'temp-opro-specify-quote-expiration',
  TempPaymentsCards = 'temp-payments-cards',
  TempPaymentsFailedFlow = 'temp-payments-failed-flow',
  TempPaymentsMyAccountFlow = 'temp-payments-my-account-flow',
  TempPaymentsPaymentPendingOrderStatus = 'temp-payments-payment-pending-order-status',
  TempRestProgressNotes = 'temp-rest-progress-notes',
}
