import { ConfirmContent, ConfirmProps } from './content/confirmContent'
import { Overlay, OverlayProps } from './overlay'

type Props = OverlayProps & ConfirmProps

const Confirm = (props: Props) => {
  const { show, ...rest } = props

  // this is a modal overlay, i.e. it cannot be closed by clicking outside of it (or using the escape key)
  return (
    <Overlay show={show}>
      <ConfirmContent {...rest} />
    </Overlay>
  )
}

export { Confirm }
export type { Props as ConfirmProps }
