import { useState, useEffect, RefObject } from 'react'

export function useIsVisible<T extends HTMLElement>(
  ref: RefObject<T> | null,
  options?: IntersectionObserverInit
): boolean {
  const [isVisible, setIsVisible] = useState<boolean>(false)

  useEffect(() => {
    const observable = ref?.current
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting)
      },
      options || {
        root: null, // Use the viewport as the root
        rootMargin: '-60px',
        threshold: 0.8, // Trigger if at least 10% of the element is visible
      }
    )

    if (observable) {
      observer.observe(ref.current)
    }

    return () => {
      if (observable) {
        observer.unobserve(observable)
      }
    }
  }, [ref, options])

  return isVisible
}
