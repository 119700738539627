import { createContext, useContext } from 'react'
import type { ProductIdentifiersRecord } from '../product-identifiers/get-product-identifiers.type'
import type { PropsWithChildren } from 'react'

export const ProductIdentifiersContext = createContext<{
  productIdentifiers: ProductIdentifiersRecord
}>({
  productIdentifiers: {},
})

export const ProductIdentifiersProvider = ({
  productIdentifiers,
  children,
}: PropsWithChildren<{
  productIdentifiers?: ProductIdentifiersRecord | null
}>) => (
  <ProductIdentifiersContext.Provider
    value={{
      productIdentifiers: productIdentifiers ?? {},
    }}
  >
    {children}
  </ProductIdentifiersContext.Provider>
)

export const useProductIdentifiersContext = () =>
  useContext(ProductIdentifiersContext)
