import { FormEvent, useContext, useState } from 'react'
import { BaseSwitchProps, SWITCH_SIZES } from './base_switch.type'

import cn from 'classnames'
import { twMerge } from 'tailwind-merge'
import { FormContext } from '../form/formContext'

const BaseSwitch = (props: BaseSwitchProps) => {
  const {
    className,
    checked,
    size = SWITCH_SIZES.regular,
    errorText,
    label,
    labelOnTheLeft = false,
    styles,
    SwitchUi,
    type = 'checkbox',
    dataCy,
    controlled = false,
    ...rest
  } = props
  const [validationMessage, setValidationMessage] = useState<string>()
  const { isSubmissionAttempted } = useContext(FormContext)

  const onInvalid = (e: FormEvent) => {
    const target = e.target as HTMLInputElement
    setValidationMessage(target.validationMessage)
  }

  const onChange = (e: FormEvent) => {
    if (isSubmissionAttempted) {
      const target = e.target as HTMLInputElement
      setValidationMessage(target.validationMessage)
    }
  }

  const sectionCn = twMerge('inline-block', className, styles && styles.section)
  const switchCn = cn(styles && styles.switch, styles && styles[size])
  const switchWrapper = cn(styles && styles.switchWrapper)
  return (
    <section className={sectionCn}>
      <label className="inline-flex cursor-pointer items-top gap-4">
        {labelOnTheLeft && label}
        <div className={switchCn}>
          <input
            type={type}
            checked={controlled ? checked : undefined}
            defaultChecked={controlled ? undefined : checked}
            onInvalid={onInvalid}
            onChange={onChange}
            data-cy={dataCy}
            data-testid="marketing-preferences-switch"
            {...rest}
          />
          <div className={switchWrapper}>{SwitchUi}</div>
        </div>
        {!labelOnTheLeft && label}
      </label>
      {!!validationMessage && (
        <div
          data-testid="simple-text-validation-message"
          className="text-negative text-body-small"
        >
          {errorText === undefined ? validationMessage : errorText}
        </div>
      )}
    </section>
  )
}

export { BaseSwitch }
