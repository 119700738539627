import cx from 'classnames'
import React, { FC } from 'react'

import styles from './badge.module.css'

interface BadgeProps {
  content: string
  className?: string
  size?: 'small' | 'medium' | 'large'
  bg?: 'grey' | 'yellow'
}

const Bagde: FC<BadgeProps> = ({
  content,
  className,
  size = 'small',
  bg = 'grey',
}) => {
  return (
    <div
      className={cx(
        styles.badgeContainer,
        className,
        styles[`${bg}`],
        styles[`${size}`]
      )}
      style={{ borderRadius: '5px' }}
    >
      {content}
    </div>
  )
}

export default Bagde
