import { ResourcePageTypes } from './resource-page-types'

export const ResourceCategoriesPaths = {
  [ResourcePageTypes.LearningGuide]: '/technical-resources/guides',
  [ResourcePageTypes.Legal]: '/legal',
  [ResourcePageTypes.MarkerSelectionGuide]:
    '/technical-resources/marker-guides',
  [ResourcePageTypes.OffersInitiatives]: '/offers-and-initiatives',
  [ResourcePageTypes.Pathways]: '/technical-resources/pathways',
  [ResourcePageTypes.ProductOverviews]: '/technical-resources/product-overview',
  [ResourcePageTypes.Protocols]: '/technical-resources/protocols',
  [ResourcePageTypes.Support]: '/support',
  [ResourcePageTypes.Troubleshooting]: '/technical-resources/troubleshooting',
  [ResourcePageTypes.AboutUs]: '/about',
  [ResourcePageTypes.Careers]: 'https://careers.abcam.com',
  [ResourcePageTypes.ContactUs]: '/contact-us',
} as const satisfies Record<ResourcePageTypes, string>
