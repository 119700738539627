/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { tags } from '@tagging-shared/lego-shared-components'
import classNames from 'classnames'
import { useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Cross, ChevronLeft } from '@lego/ui/icons'
import { useViewport } from '@lego/hooks/viewport'
import { event, isEnterEvent, key } from '@lego/utilities'
import { ModalProps } from './modal.type'
import styles from './modal.module.css'
import hiddenText from '@abcam-web/common-assets/styles/hidden-text.module.css'
import { MODAL_OUTLET_ID } from './constants'
import classnames from 'classnames'
import type { PropsWithChildren, SyntheticEvent } from 'react'
import FocusTrap from 'focus-trap-react'

export const Modal = (props: PropsWithChildren<ModalProps>) => {
  const {
    children,
    header,
    headerClassName = '',
    onClose,
    show,
    height,
    width,
    padded = false,
    overlayOnClickRequired = true,
    hideHeader = false,
    className = '',
    overlayStyle = {},
    contentStyle = {},
    closeOnOutsideClick = true,
    closeOnEscape = true,
    dataCy,
    focusTrapped,
    focusTrapInitialFocus,
    testIdPrefix = '',
    closeIconClassName = '',
    disableCloseButton = false,
    footer,
  } = props

  const { matchQuery } = useViewport()
  const fullScreen = matchQuery('tyd')

  const overlayClassName = classNames(
    styles.overlay,
    show ? styles.overlayShow : styles.overlayHide
  )
  const modalClassName = classNames(
    styles.modal,
    show && styles.modalShow,
    fullScreen && styles.fullScreen,
    className
  )
  const [isBrowser, setIsBrowser] = useState<boolean>(false)
  const modalRef = useRef<HTMLDivElement>(null)

  const close = useCallback(() => {
    document.body.style.height = ''
    document.body.style.overflowY = ''
    onClose?.()
  }, [onClose])

  const overlayOnClick = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any) => {
      if (!closeOnOutsideClick) {
        e.preventDefault()
        return
      }
      if (!modalRef?.current?.contains(e.target as Element)) {
        close()
      }
    },
    [close, closeOnOutsideClick]
  )

  const overlayOnKeyboard = useCallback(
    (e: KeyboardEvent | Event) => {
      if (closeOnEscape && (e as KeyboardEvent).key === key.ESCAPE && show) {
        close()
      }
    },
    [close, closeOnEscape, show]
  )

  useEffect(() => {
    setIsBrowser(true)

    window.addEventListener(
      event.KEYUP as keyof WindowEventMap,
      overlayOnKeyboard,
      false
    )

    if (show) {
      document.body.style.height = '100vh'
      document.body.style.overflowY = 'hidden'
    }

    return () => {
      document.body.style.height = ''
      document.body.style.overflowY = ''
      window.removeEventListener(event.KEYUP, overlayOnKeyboard, false)
    }
  }, [overlayOnClick, overlayOnKeyboard, show])

  const handleCloseClick = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault()
      close()
    },
    [close]
  )

  const handleCloseButtonKeypress = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (evt: any) => {
      if (isEnterEvent(evt)) {
        handleCloseClick(evt)
      }
    },
    [handleCloseClick]
  )

  const inlineStyle = fullScreen
    ? { height: '100%', width: '100%' }
    : { height: height || 'max-content', width: width || 'max-content' }

  const modalContent = (
    <div
      data-cy={dataCy}
      data-testid={testIdPrefix + tags.modal.container}
      className={overlayClassName}
      onClick={overlayOnClickRequired ? overlayOnClick : undefined}
      tabIndex={0}
      onKeyPress={() => false}
      aria-label={'modal'}
      style={overlayStyle}
    >
      <div
        data-testid={testIdPrefix + tags.modal.content}
        style={{ ...inlineStyle, ...contentStyle }}
        className={modalClassName}
        ref={modalRef}
        role="dialog"
        aria-label={'modal content'}
      >
        {!hideHeader && (
          <div
            className={classnames(
              styles.header,
              {
                [styles.withTitle]: header,
              },
              headerClassName
            )}
          >
            {fullScreen && (
              <div
                tabIndex={0}
                className={styles.chevronContainer}
                onClick={handleCloseClick}
                role={'button'}
                onKeyPress={handleCloseButtonKeypress}
              >
                <ChevronLeft />
              </div>
            )}
            {!hideHeader && header && (
              <h3
                data-cy="modal-title"
                data-testid={testIdPrefix + tags.modal.title}
                className={styles.title}
              >
                {header}
              </h3>
            )}
            {!hideHeader && !fullScreen && (
              <button
                tabIndex={0}
                className={classnames(
                  styles.crossContainer,
                  closeIconClassName
                )}
                data-testid={testIdPrefix + tags.modal.closeButton}
                onClick={handleCloseClick}
                disabled={disableCloseButton}
                onKeyPress={handleCloseButtonKeypress}
              >
                <span className={hiddenText['hidden-text']}>close</span>{' '}
                <Cross />
              </button>
            )}
          </div>
        )}
        <div
          className={classnames(styles.body, {
            [styles.padded]: padded,
          })}
          data-testid={testIdPrefix + tags.modal.body}
        >
          {children}
        </div>

        {footer}
      </div>
    </div>
  )

  const focusTrappedModal = (
    <FocusTrap
      active={show}
      focusTrapOptions={{
        allowOutsideClick: true,
        escapeDeactivates: false,
        initialFocus: focusTrapInitialFocus,
      }}
    >
      {modalContent}
    </FocusTrap>
  )

  if (isBrowser) {
    const modalElement = document.getElementById(MODAL_OUTLET_ID)
    const modelContentType = focusTrapped ? focusTrappedModal : modalContent
    return modalElement && createPortal(modelContentType, modalElement)
  }

  return null
}
