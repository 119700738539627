import { apolloClient } from './apollo-client'
import {
  FeatureTogglesDocument,
  FeatureToggleListDocument,
  FeatureToggle,
  FeatureToggleListQuery,
} from '@abcam-web/shared/data-access/feature-toggle-schema'
import type { FeatureToggleClient as FeatureToggleClientInterface } from './interfaces/feature-toggle.interface'
import type { FeatureToggleQuery } from './feature-toggle.type'

export class FeatureToggleClientService
  implements FeatureToggleClientInterface {
  constructor(private baseUrl: string) { }
  public async getAllToggles(): Promise<FeatureToggle[]> {
    try {
      const { data: legacyToggleData, error: legacyToggleError } =
        await apolloClient(this.baseUrl).query<FeatureToggleQuery>({
          query: FeatureTogglesDocument,
        })

      const { data: togglesData, error: togglesError } = await apolloClient(
        this.baseUrl
      ).query<FeatureToggleListQuery>({
        query: FeatureToggleListDocument,
      })

      if (legacyToggleError || togglesError) {
        console.error(
          'Error fetching feature toggles! The error is: ',
          legacyToggleError?.message || togglesError?.message
        )
        return []
      }

      const togglesOutput = [
        ...(legacyToggleData?.featureToggles || []),
        ...(togglesData?.featureToggleList || []),
      ]

      return togglesOutput
    } catch (err) {
      console.error(
        'Exception caught whilst fetching feature toggles!, The error is: ',
        err
      )
      return []
    }
  }
}
