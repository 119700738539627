import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { isEnvironmentClient } from '../helpers'

/**
 * Reads the value stored in the local storage with the given key.
 *
 * @param key - The key used to store the value in the local storage.
 * @param validator - An optional function that validates the value. It takes the value as a parameter and returns a boolean indicating if the value is valid.
 * @returns The value stored in the local storage with the given key if it exists and is valid. Returns null if there is no value stored with the given key or if the value is not valid.
 *
 * @example
 * const basketId = readStorageValue('basketId', validateBasketId);
 * console.log(basketId); // Output: '12345'
 */
export function readStorageValue<TValue>(
  key: string,
  validator?: (value: TValue) => boolean
): TValue | null {
  try {
    if (!isEnvironmentClient()) {
      throw new Error(
        `You are reading ${key} data from a local storage on server local storage is only accessible on client`
      )
    }
    const item = window.localStorage.getItem(key)
    if (item && validator && !validator(JSON.parse(item))) {
      throw new Error(`Found a non valid value as '${key}': '${item}'.`)
    }
    return item ? JSON.parse(item) : null
  } catch (error) {
    console.warn(`Error reading ${key} from local storage:`, error)
    return null
  }
}

/**
 * Deletes a specific key from the local storage.
 *
 * @param key - The key of the value to be deleted from the local storage.
 * @returns `true` if the specified key is successfully deleted from the local storage, `false` otherwise including if it is not run on client side.
 * @example
 * const success = deleteStorageValue('basketId');
 * console.log(success); // true or false
 */
export function deleteStorageValue(key: string): boolean {
  try {
    if (!isEnvironmentClient()) {
      throw new Error(
        `Tried deleting localStorage “${key}” even though environment is not a client`
      )
    }
    window.localStorage.removeItem(key)
  } catch (error) {
    console.warn(`Error deleting localStorage “${key}”:`, error)
    return false
  }
  return true
}

/**
 * Type representing a function to set a value of type TValue with React useState.
 */
type SetValue<TValue> = Dispatch<SetStateAction<TValue>>

export type LocalStorageHookReturn<TValue> = [
  TValue,
  SetValue<TValue>,
  SetValue<void>
]
type LocalStorageHook<TValue> = (
  initialValue: TValue
) => LocalStorageHookReturn<TValue>
/**
 * Creates a custom React hook for reading, updating, and deleting values stored in the browser's local storage.
 *
 * @param {string} key - The key used to store the value in local storage.
 * @param {(value: TValue) boolean} [validator] - (optional) A function that validates the value before storing it in local storage.
 * @returns {LocalStorageHook<TValue>} A custom React hook, that can be used to read, update and delete a valid value of type TValue for the provided key param from local storage.
 *
 * @example
 * // Example usage of createLocalStorageHook
 * const useCounter = createLocalStorageHook<number>('counter');
 * const [counter, setCounter, resetCounter] = useCounter(0);
 * console.log(counter); // Output: 0
 * setCounter(5);
 * console.log(counter); // Output: 5
 * resetCounter();
 * console.log(counter); // Output: 0
 */
export function createLocalStorageHook<TValue>(
  key: string,
  validator?: (value: TValue) => boolean
): LocalStorageHook<TValue> {
  /**
   * A react hook for reading, updating, and deleting the value of type TValue from localStorage by the provided key from the hook creater function createLocalStorageHook.
   * @param {TValue} fallbackValue - The value used when no localStorage value is found for the provided key.
   * @returns {LocalStorageHookReturn<TValue>} An array with the stored value, a setter function, and a delete function.
   */
  return (fallbackValue: TValue): LocalStorageHookReturn<TValue> => {
    const readValue = useCallback((): TValue => {
      return readStorageValue(key, validator) ?? fallbackValue
    }, [fallbackValue])

    const [storedValue, setStoredValue] = useState<TValue>(readValue)

    const setValue: SetValue<TValue> = (value) => {
      if (!isEnvironmentClient()) {
        console.warn(
          `Tried setting localStorage “${key}” even though environment is not a client`
        )
      }

      try {
        const newValue = value instanceof Function ? value(storedValue) : value
        if (validator && !validator(newValue)) {
          throw new Error(`Not a valid value for ${key}: ${newValue}`)
        }
        if (newValue !== storedValue) {
          if (isEnvironmentClient()) {
            window.localStorage.setItem(key, JSON.stringify(newValue))
          }
          setStoredValue(newValue)
        }
      } catch (error) {
        console.warn(`Error setting localStorage "${key}" “${value}”:`, error)
      }
    }

    const deleteValue: SetValue<void> = useCallback(() => {
      if (deleteStorageValue(key)) {
        setStoredValue(readValue())
      }
    }, [readValue])

    useEffect(() => {
      const handleStorageChange = () => {
        setStoredValue(readValue())
      }

      if (isEnvironmentClient()) {
        window.addEventListener('storage', handleStorageChange)
        window.addEventListener('local-storage', handleStorageChange)
      }

      return () => {
        if (isEnvironmentClient()) {
          window.removeEventListener('storage', handleStorageChange)
          window.removeEventListener('local-storage', handleStorageChange)
        }
      }
    }, [readValue])

    return [storedValue, setValue, deleteValue]
  }
}
