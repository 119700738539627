import {
  DmdDomain,
  GetProductDocument,
  GetProductsByFilterDocument,
} from '@abcam-web/shared/data-access/product-schema'
import {
  ContentFacetRecommendationsDocument,
  RecommendedFacetsDocument,
  SearchBySlugDocument,
  SearchDocument,
  ContentSearchDocument,
  SuggestionsDocument,
} from '@abcam-web/shared/data-access/search-schema'
import { getProxyClient } from '@abcam-web/shared/data-access/apollo'
import type {
  RecommendedFacetsQuery,
  SearchBySlugQuery,
  SuggestionsQuery,
  SearchRequestInput,
  SimpleSearchRequestInput,
  SearchQuery,
  ContentSearchQuery,
  SearchBySlugQueryVariables,
  ContentFacetRecommendationsQuery,
  ContentFacetRecommendationResults,
  FacetRecommendationResults,
} from '@abcam-web/shared/data-access/search-schema'
import type {
  GetProductsByFilterQuery,
  GetProductQuery,
  ProductsFilter,
} from '@abcam-web/shared/data-access/product-schema'
import type { Client } from '@abcam-web/shared/data-access/apollo'

export type AllFacetRecommendationResults = {
  searchFacetRecommendations: FacetRecommendationResults | null
  contentFacetRecommendations: ContentFacetRecommendationResults | null
}

export class SearchService {
  client: Client
  mixed: boolean

  constructor(mixed: boolean) {
    this.client = getProxyClient()
    this.mixed = mixed
  }

  public async searchBySlug(variables: SearchBySlugQueryVariables) {
    const res = await this.client.query<SearchBySlugQuery>({
      query: SearchBySlugDocument,
      variables,
      errorPolicy: 'all',
    })
    return res.data?.searchBySlug
  }

  public async search({
    keywords,
    facets,
    sort = 'relevance',
    sortOrder,
    offset = 0,
    limit = 20,
  }: SearchRequestInput) {
    const res = await this.client.query<SearchQuery>({
      query: SearchDocument,
      variables: {
        query: {
          keywords,
          facets,
          sort,
          sortOrder,
          offset,
          limit,
        },
        options: { mixed: this.mixed },
      },
      errorPolicy: 'all',
    })
    return res.data?.search
  }

  public async contentSearch({
    keywords,
    facets,
    sort = 'relevance',
    sortOrder,
    offset = 0,
    limit = 20,
  }: SearchRequestInput) {
    const res = await this.client.query<ContentSearchQuery>({
      query: ContentSearchDocument,
      variables: {
        query: {
          keywords,
          facets,
          sort,
          sortOrder,
          offset,
          limit,
        },
      },
      errorPolicy: 'all',
    })
    return res.data?.contentSearch
  }

  public async recommendFacets(payload?: SimpleSearchRequestInput) {
    const res = await Promise.all([
      this.client.query<RecommendedFacetsQuery>({
        query: RecommendedFacetsDocument,
        variables: { query: { ...payload } },
        errorPolicy: 'all',
      }),
      this.client.query<ContentFacetRecommendationsQuery>({
        query: ContentFacetRecommendationsDocument,
        variables: {
          query: {
            facets: null,
            keywords: payload
              ? `${payload.keywords} ${payload.facets
                  ?.map((facet) => facet.label)
                  .join(' ')}`.trim()
              : null,
          },
        },
        errorPolicy: 'all',
      }),
    ])

    return {
      searchFacetRecommendations: res[0].data.facetRecommendations || null,
      contentFacetRecommendations:
        res[1].data.contentFacetRecommendations || null,
    }
  }

  public async suggest(payload: SimpleSearchRequestInput) {
    const res = await this.client.query<SuggestionsQuery>({
      query: SuggestionsDocument,
      variables: { query: { ...payload } },
      errorPolicy: 'all',
    })
    return res.data.suggestions
  }

  public async product(payload: string, locale: string) {
    const domain = DmdDomain.COM
    const res = await this.client.query<GetProductQuery>({
      query: GetProductDocument,
      variables: { abId: payload, domain, locale },
    })

    return res.data.product
  }

  public async products(filter: ProductsFilter) {
    const res = await this.client.query<GetProductsByFilterQuery>({
      query: GetProductsByFilterDocument,
      variables: { filter },
    })
    return res.data.products
  }
}

export const createSearchService = (mixed = true) => new SearchService(mixed)

export const searchService = createSearchService(false)
