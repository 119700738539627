// We have this special setup here to avoid installing next-intl and bloat the dependencies more
const featureColumnType = {
  en: 'FeaturedColumn',
  zh: 'FeaturedColumn',
  ja: 'FeaturedColumn',
}
const productTabTitle = {
  en: 'Products',
  zh: '产品',
  ja: '製品',
}
const aboutTabTitle = {
  en: 'About us',
  zh: '关于我们',
  ja: 'アブカムについて',
}
const supportTabTitle = {
  en: 'Support',
  zh: '技术支持',
  ja: 'サポート',
}
const resourcesTabTitle = {
  en: 'Resources',
  zh: '资源',
  ja: 'リソース',
}

const productTabSlug = '/products'
const resourcesTabSlug = '/technical-resources'
const supportTabSlug = '/support'
const aboutTabSlug = '/about'

export {
  featureColumnType,
  productTabTitle,
  aboutTabTitle,
  supportTabTitle,
  resourcesTabTitle,
  productTabSlug,
  resourcesTabSlug,
  supportTabSlug,
  aboutTabSlug,
}
