import { Button } from '@lego/ui/button'
import { Calendar, CircleArrows, CloseIcon } from '@lego/ui/icons'
import cn from 'classnames'
import { FC } from 'react'

import { ButtonBase } from '../types'

export const DropdownButton: FC<
  Pick<ButtonBase, 'label'> & { isOpen: boolean }
> = ({ isOpen, label }) => (
  <Button
    className={cn(
      'py-2 text-ui-medium bg-interactive-grey-transparent-active !no-underline ',
      {
        '!bg-interactive-grey-darker-transparent-active': isOpen,
      }
    )}
    iconLeft={
      isOpen ? (
        <CloseIcon aria-label="Close dropdown" />
      ) : (
        <Calendar aria-label="Open dropdown" />
      )
    }
    size="medium"
    tabIndex={-1}
    variant="textOnly"
  >
    {label}
  </Button>
)

export const ResetButton: FC<ButtonBase> = ({ onClick, label }) => (
  <Button
    bodyClassName="text-negative"
    onClick={onClick}
    responsiveSizing
    size="small"
    variant="textOnly"
    iconLeft={<CircleArrows aria-label="Reset changes" />}
  >
    {label}
  </Button>
)

export const CancelButton: FC<ButtonBase> = ({ onClick, label }) => (
  <Button onClick={onClick} responsiveSizing size="small" variant="textOnly">
    {label}
  </Button>
)

export const SaveButton: FC<ButtonBase> = ({ onClick, label }) => (
  <Button onClick={onClick} responsiveSizing size="small" variant="primary">
    {label}
  </Button>
)

export const CloseButton: FC<ButtonBase> = ({ onClick, label }) => (
  <Button onClick={onClick} responsiveSizing size="small" variant="primary">
    {label}
  </Button>
)
