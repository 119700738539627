import { Confirm, ConfirmProps } from '@lego/ui/modals'
import { FormContext } from '../../form/formContext'
import { useToggle } from '@lego/hooks/toggle'
import { useNavigationObserver } from '../../use-navigation-observer/useNavigationObserver'
import { useCallback, useContext } from 'react'

interface DirtyFormModalProps
  extends Omit<ConfirmProps, 'onCancel' | 'onConfirm' | 'confirmText'> {
  message: string
  title: string
  leaveText: string
  cancelText: string
}

const DirtyFormModal = ({
  message,
  title,
  leaveText,
  cancelText,
  ...rest
}: DirtyFormModalProps) => {
  const { isDirty } = useContext(FormContext)
  const [showConfirm, toggleConfirm] = useToggle(false)
  const onNavigate = useCallback(() => {
    toggleConfirm(true)
  }, [toggleConfirm])
  const allowNavigation = useNavigationObserver({
    shouldStopNavigation: isDirty,
    onNavigate,
  })
  const onConfirmNavigation = () => {
    toggleConfirm(false)
    allowNavigation()
  }

  const onCancelNavigation = () => {
    toggleConfirm(false)
  }
  return (
    <Confirm
      show={showConfirm}
      onCancel={onCancelNavigation}
      onConfirm={onConfirmNavigation}
      confirmText={leaveText}
      cancelText={cancelText}
      title={title}
      description={message}
      {...rest}
    />
  )
}

export { DirtyFormModal }
export type { DirtyFormModalProps }
