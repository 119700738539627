import { useFreshchat } from './useFreshchat'
import FreshchatProps from './freshchat.types'

export const Freshchat = ({
  environment,
  isOneTrustEnabled,
  featureToggles,
}: FreshchatProps) => {
  useFreshchat(environment, isOneTrustEnabled, featureToggles)

  // Intentionally returning null - We are only interested in this component starting FreshChat via the hook.
  // Note that the useFreshchat hook is situated here so that it can be nested within the Provider
  // tree in the Browse app (at this time of writing at least!)
  return null
}
