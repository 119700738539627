import {
  CountryOverrides,
  mappedTranslations,
  type Translations,
} from '@abcam-web/shared/utilities/localisation'
import {
  translations as addressFieldsetMessages,
  countryOverrides as addressFieldsetCountryOverrides,
} from '../address-fieldset/i18n'
import en from './en.json'
import ja from './ja.json'
import zh from './zh.json'
import enOverrides from './country-overrides-en.json'
import jaOverrides from './country-overrides-ja.json'
import zhOverrides from './country-overrides-zh.json'

const sharedComponentTranslations: Translations = mappedTranslations([
  { en, ja, zh },
  addressFieldsetMessages,
])
const sharedComponentTranslationsOverrides: CountryOverrides = {
  en: enOverrides,
  ja: jaOverrides,
  zh: zhOverrides
}

export {
  addressFieldsetMessages,
  addressFieldsetCountryOverrides,
  sharedComponentTranslations,
  sharedComponentTranslationsOverrides
}
