import hiddenTextStyle from '@abcam-web/common-assets/styles/hidden-text.module.css'
import { ChevronDown, IconTick } from '@lego/ui/icons'
import { useSelect } from 'downshift'
import {
  FocusEventHandler,
  FormEvent,
  HTMLProps,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react'
import style from './simpleSelect.module.css'

export type Option = {
  key: string | number
  displayValue: string
  checked?: boolean
}

export type SimpleSelectProps = Omit<
  HTMLProps<HTMLSelectElement>,
  'onChange'
> & {
  className?: string
  options: Option[]
  label?: string
  labelClassName?: string
  inputClassName?: string
  optionClassName?: string
  highlightedOptionClassName?: string
  hint?: string | JSX.Element
  errorText?: string
  required?: boolean
  testTag?: string
  menuClassName?: string
  inputWrapperClassName?: string
  onChange?: (event: FormEvent<HTMLSelectElement>) => void
  autoFocus?: boolean
  hideLabel?: boolean // for accessibility you may wish to have a label in the markup but hide it
  enableBlurValidation?: boolean // New prop for enabling blur-based validation
}

export const findNextIndex = (
  highlightedIndex: number,
  options: Option[],
  inputValue: string
): number => {
  const lowercaseInput = inputValue.toLowerCase()
  let nextIndex

  if (highlightedIndex > -1) {
    // check if there is a group of items that start with the typed input
    // and if so, make highlight cycle through
    const matchingGroup = options.filter((o) =>
      o.displayValue.toLowerCase().startsWith(lowercaseInput, 0)
    )

    if (matchingGroup.length > 0) {
      const indexWithinGroup = matchingGroup.findIndex(
        (o) => o.displayValue === options[highlightedIndex].displayValue
      )

      if (indexWithinGroup < matchingGroup.length - 1) {
        nextIndex = options.findIndex(
          (o) =>
            o.displayValue === matchingGroup[indexWithinGroup + 1].displayValue
        )
      } else {
        nextIndex = options.findIndex(
          (o) => o.displayValue === matchingGroup[0].displayValue
        )
      }
    }
  } else {
    nextIndex = options.findIndex((o) =>
      o.displayValue.toLowerCase().startsWith(lowercaseInput, 0)
    )
  }

  if (!nextIndex && lowercaseInput.length > 1) {
    // If exact match not found then do search again by first letter typed
    nextIndex = findNextIndex(
      highlightedIndex,
      options,
      lowercaseInput.substring(0, 1)
    )
  }

  return nextIndex ?? -1
}

export const SimpleSelect = ({
  label,
  hint,
  className = '',
  labelClassName = '',
  inputClassName = '',
  optionClassName = '',
  highlightedOptionClassName = '',
  options,
  testTag,
  errorText,
  required,
  inputWrapperClassName = '',
  menuClassName = '',
  onChange,
  autoFocus,
  hideLabel,
  enableBlurValidation = false,
  tabIndex = 0,
  ...rest
}: SimpleSelectProps) => {
  const defaultSelectedItem = useMemo(
    () =>
      options.find((option) => option.checked) ||
      options.find((option) => !option.key) ||
      options[0],
    [options]
  )

  const menuOptions = options.filter((option) => option.key)

  const id = useId()
  const {
    getItemProps,
    getMenuProps,
    getToggleButtonProps,
    isOpen,
    highlightedIndex,
    selectItem,
    setHighlightedIndex,
  } = useSelect({
    id,
    items: menuOptions,
    defaultSelectedItem,
    onSelectedItemChange(changes) {
      const item = changes.selectedItem

      if (item && selectRef.current && item.key !== selectRef.current.value) {
        selectRef.current.value = item.key as string
        selectRef.current.dispatchEvent(new Event('change', { bubbles: true }))
      }
    },
    onStateChange: ({ type, inputValue }) => {
      let nextIndex: number
      switch (type) {
        case useSelect.stateChangeTypes.ToggleButtonKeyDownCharacter:
          nextIndex = findNextIndex(
            highlightedIndex,
            menuOptions,
            inputValue || ''
          )

          if (nextIndex > -1) {
            setHighlightedIndex(nextIndex)
          }
          break
        default:
          break
      }
    },
  })
  const [validationMessage, setValidationMessage] = useState<string>()
  const [isValid, setIsValid] = useState(false) // Track if the selection is valid

  const dropdownRef = useRef<HTMLDivElement>(null)
  const selectRef = useRef<HTMLSelectElement>(null)

  const btnWrapperCn = `relative flex flex-col w-full gap-1 text-body-xmedium ${className}`
  const testTagPrefix = testTag ? `${testTag}-` : ''

  const changeHandler = (e: FormEvent<HTMLSelectElement>) => {
    onChange && onChange(e)
    const target = e.target as HTMLSelectElement
    target.checkValidity()
    setValidationMessage(target.validationMessage)

    const item = menuOptions.find((o) => o.key === target.value)
    selectItem(item || null)

    if (enableBlurValidation) {
      setIsValid(!!item)
    }
  }

  const onInvalid = (e: FormEvent) => {
    const target = e.target as HTMLSelectElement
    setValidationMessage(target.validationMessage)
  }

  const focusSibling: FocusEventHandler<HTMLSelectElement> = (e) => {
    (e.target.nextElementSibling as HTMLDivElement).focus()
  }

  useEffect(() => {
    selectItem(options.find((opt) => opt.checked) || null)
  }, [options, selectItem])

  const maxHeight = useMemo(() => {
    if (!isOpen || !selectRef.current) {
      return 'auto'
    }

    const bottomOfViewport = window.innerHeight
    const selectBottom = selectRef.current.getBoundingClientRect().bottom
    const availableHeight = bottomOfViewport - selectBottom - 50

    return availableHeight > 100 ? `${availableHeight}px` : 'auto'
  }, [selectRef, isOpen])

  // Style to be applied to the input wrapper when valid
  const inputStateClassName =
    enableBlurValidation && isValid ? style.validBackground : ''

  // instead of using reset to display defaultSelectedItem when it is changed we are falling back to defaultSelectedItem when the ref is null
  const selectedHtmlOption = selectRef.current?.selectedOptions[0] || {
    text: defaultSelectedItem?.key ? defaultSelectedItem.displayValue : '',
    value: defaultSelectedItem?.key,
  }

  return (
    <div className={`relative flex flex-col gap-1 ${style.mainWrapper}`}>
      <div className={btnWrapperCn}>
        {label && (
          <label
            className={`mr-2 font-semibold ${labelClassName} ${
              hideLabel ? hiddenTextStyle['hidden-text'] : ''
            }`}
            htmlFor={id}
          >
            {label}
          </label>
        )}
        {hint && <p className="text-grey-20">{hint}</p>}
        <div
          className={`${style.selectWrapper} ${inputWrapperClassName}`}
          data-testid={`simpleSelectWrapper-${label}`}
        >
          <select
            autoFocus={autoFocus}
            id={id}
            aria-hidden="true"
            className="sr-only"
            tabIndex={-1}
            ref={selectRef}
            onInvalid={onInvalid}
            required={required}
            aria-required={required}
            data-testid={`${testTagPrefix}select-element`}
            defaultValue={defaultSelectedItem?.key}
            {...rest}
            onChange={changeHandler}
            onFocus={focusSibling}
          >
            {options.map((option) => {
              const isDisabled = option.key === ''
              return (
                <option
                  key={option.key}
                  value={option.key}
                  disabled={isDisabled}
                >
                  {option.displayValue}
                </option>
              )
            })}
          </select>

          <div
            className={`relative ${style.inputWrapper} ${inputClassName} ${inputStateClassName}`}
            data-testid={`${testTagPrefix}select-btn`}
            {...getToggleButtonProps({ 'aria-labelledby': undefined })}
            tabIndex={tabIndex}
          >
            <span
              className={`${style.label} ${
                !selectedHtmlOption?.value && style.disabled
              }`}
              data-testid={`${testTagPrefix}-name`}
            >
              {selectedHtmlOption?.text || <>&nbsp;</>}
            </span>

            <div className="absolute bottom-0 flex items-center h-full right-4">
              {enableBlurValidation && selectedHtmlOption?.value ? (
                <IconTick className="w-4 h-4 pointer-events-none" />
              ) : (
                <ChevronDown
                  className={`
        w-4 h-4 pointer-events-none${isOpen ? ' transform rotate-180' : ''}
      `}
                />
              )}
            </div>
          </div>
        </div>

        <ul
          className={`
          bg-grey-5 shadow-elevation-5
          bottom-0 transform translate-y-full
          overflow-y-auto
          absolute${isOpen ? '' : ' hidden'}
          z-10
          min-w-full
          ${menuClassName}
        `}
          data-testid={`${testTagPrefix}select-list`}
          {...getMenuProps({ ref: dropdownRef, style: { maxHeight } })}
        >
          {menuOptions.map((option, idx) => (
            <li
              className={`
              py-2 px-4 mx-1 first:mt-1 last:mb-1
              ${
                highlightedIndex === idx
                  ? `rounded border-blue-40 ring-blue-40 ring-[3px] bg-grey-10 ${highlightedOptionClassName}`
                  : ''
              }
              ${optionClassName}
            `}
              data-testid={`${testTagPrefix}select-list-item`}
              key={`${option.key}-${idx}`}
              {...getItemProps({
                item: option,
                index: idx,
              })}
            >
              {option.displayValue}
            </li>
          ))}
        </ul>
      </div>

      {!!validationMessage && (
        <div
          data-testid="simple-select-validation-message"
          className={`text-negative text-body-small ${style.validationMessage}`}
        >
          {errorText === undefined ? validationMessage : errorText}
        </div>
      )}
    </div>
  )
}
