import { AlertIcon, CheckIcon } from '@abcam-web/lego/ui/icons'
import { RadioInput } from '@lego/ui/radio-input'
import { TextInput } from '@lego/ui/text-input'
import classNames from 'classnames'
import { FC, useCallback } from 'react'

const taxOptions = [
  {
    key: 'no_tax_exempt',
    displayValue: 'No',
  },
  {
    key: 'tax_exempt',
    displayValue: 'Yes',
  },
]

interface VatTaxImportsProps {
  label?: string
  leftLabel?: boolean
  className?: string
  onTaxChange: (value: string) => void
  onTaxExemptChange: (value: boolean) => void
  taxExempt?: boolean
  taxExemptInternal?: boolean
  disabledTaxExempt?: boolean
  taxValue?: string
  taxExemptMessageOverride?: string
  hasTaxExemptionCertificate?: boolean
  hasTaxExemptionStatement?: boolean
}

export const taxExemptMessage =
  'Please confirm there is a valid exemption certificate for this order.'

export const taxExemptVerifiedMessage =
  'Verified - certificate and statement provided.'

export const taxExemptWithCertWithoutStatementMessage =
  'Please confirm there is a valid statement for this order.'

export const taxExemptWithoutCertOrStatementMessage =
  'Please confirm there is a valid exemption certificate and statement for this order.'

export const taxExemptInternalMessage =
  'This information is provided by tax service and cannot be changed.'

interface TaxMessageProps {
  message?: string
  isWarning?: boolean
}

const TaxMessage: FC<TaxMessageProps> = ({ message, isWarning = true }) => (
  <div
    className={`flex items-center px-3 py-1 text-left rounded text-body-small ${
      isWarning ? 'bg-cautionary' : 'bg-[#e8fcf4] text-[#0f8554]'
    }`}
    data-testid="promotion-error-message"
  >
    {isWarning ? (
      <AlertIcon className="mb-auto mr-2 icon-current-color" />
    ) : (
      <CheckIcon className="mb-auto mr-2 icon-current-color" />
    )}
    <span className="mt-1">{message}</span>
  </div>
)

const VatTaxImports: FC<VatTaxImportsProps> = ({
  label = 'VAT or Tax No.',
  leftLabel = false,
  onTaxChange = () => null,
  onTaxExemptChange = () => null,
  disabledTaxExempt = false,
  className,
  taxExempt = false,
  taxExemptInternal = false,
  taxValue = '',
  taxExemptMessageOverride = undefined,
  hasTaxExemptionCertificate,
  hasTaxExemptionStatement,
}) => {
  const onTaxExemptChangeCallback = useCallback(
    ({ value }: { value: string | number }) => {
      if (value === 'tax_exempt') {
        onTaxExemptChange(true)
      } else if (value === 'no_tax_exempt') {
        onTaxExemptChange(false)
      }
    },
    [onTaxExemptChange]
  )

  const taxExemptValue =
    taxExempt || taxExemptInternal ? 'tax_exempt' : 'no_tax_exempt'

  let warningMessage = taxExemptMessage
  let isWarning = true

  if (taxExemptInternal) {
    warningMessage = taxExemptInternalMessage
  } else if (
    typeof hasTaxExemptionCertificate === 'boolean' &&
    hasTaxExemptionCertificate &&
    !hasTaxExemptionStatement
  ) {
    warningMessage = taxExemptWithCertWithoutStatementMessage
  } else if (
    typeof hasTaxExemptionCertificate === 'boolean' &&
    !hasTaxExemptionCertificate &&
    !hasTaxExemptionStatement
  ) {
    warningMessage = taxExemptWithoutCertOrStatementMessage
  } else if (hasTaxExemptionCertificate && hasTaxExemptionStatement) {
    warningMessage = taxExemptVerifiedMessage
    isWarning = false
  }

  return (
    <div
      className={classNames('pr-layout-2', className, {
        'grid grid-cols-3': leftLabel,
      })}
    >
      <div
        className={classNames('font-semibold text-heading-x-small', {
          'col-span-1 mr-10': leftLabel,
        })}
      >
        {label}
      </div>
      <div
        className={classNames({
          'col-span-2': leftLabel,
        })}
      >
        <TextInput
          name="tax-number-input"
          fullWidth
          dataCy="tax-number-input"
          value={taxValue}
          inputType="text"
          onChange={({ value }) => {
            onTaxChange(value)
          }}
        />
        <label className="font-semibold text-ui-medium">
          Is this order VAT/Sales Tax exempt?
          <div
            className={classNames('flex', {
              'cursor-not-allowed opacity-30': disabledTaxExempt,
            })}
          >
            {taxOptions.map(({ key, displayValue }) => (
              <RadioInput
                dataCy="tax-exempt-radio"
                key={key}
                disabled={disabledTaxExempt}
                value={key}
                displayValue={<span className="ml-2">{displayValue}</span>}
                radioLabelClassNameOverride="font-semibold text-ui-medium"
                onChecked={onTaxExemptChangeCallback}
                checked={key === taxExemptValue}
                data-testid="tax-exempt-radio"
              />
            ))}
          </div>
        </label>
        {(taxExempt || taxExemptInternal) && (
          <TaxMessage
            message={taxExemptMessageOverride || warningMessage}
            isWarning={isWarning}
          />
        )}
      </div>
    </div>
  )
}

export { VatTaxImports }
