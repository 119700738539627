export const POSTCODE_REGEXES = {
  AD: ['AD[0-9][0-9][0-9]'],
  AE: [],
  AL: ['[0-9][0-9][0-9][0-9]'],
  AM: ['[0-9][0-9][0-9][0-9]'],
  AR: ['[a-z][0-9][0-9][0-9][0-9][a-z][a-z][a-z]'],
  AS: ['[0-9][0-9][0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]'],
  AT: ['[0-9][0-9][0-9][0-9]'],
  AU: ['[0-9][0-9][0-9][0-9]'],
  AX: ['[0-9][0-9][0-9][0-9][0-9]'],
  AZ: ['AZ [0-9][0-9][0-9][0-9]'],
  BA: ['[0-9][0-9][0-9][0-9][0-9]'],
  BB: ['BB[0-9][0-9][0-9][0-9][0-9]'],
  BD: ['[0-9][0-9][0-9][0-9]'],
  BE: ['[0-9][0-9][0-9][0-9]'],
  BG: ['[0-9][0-9][0-9][0-9]'],
  BH: ['[0-9][0-9][0-9]', '[0-9][0-9][0-9][0-9]'],
  BL: ['[0-9][0-9][0-9][0-9][0-9]'],
  BM: ['[a-z][a-z] [0-9][0-9]'],
  BN: ['[a-z][a-z][0-9][0-9][0-9][0-9]'],
  BR: ['[0-9][0-9][0-9][0-9][0-9]-[0-9][0-9][0-9]'],
  BW: [],
  BY: ['[0-9][0-9][0-9][0-9][0-9][0-9]'],
  CA: ['[a-z][0-9][a-z] [0-9][a-z][0-9]'],
  CH: ['[0-9][0-9][0-9][0-9]'],
  CI: [],
  CL: ['[0-9][0-9][0-9][0-9][0-9][0-9][0-9]'],
  CM: [],
  CN: ['[0-9][0-9][0-9][0-9][0-9][0-9]'],
  CO: ['[0-9][0-9][0-9][0-9][0-9][0-9]'],
  CR: ['[0-9][0-9][0-9][0-9][0-9]'],
  CU: ['CP [0-9][0-9][0-9][0-9][0-9]'],
  CV: ['[0-9][0-9][0-9][0-9]'],
  CX: ['[0-9][0-9][0-9][0-9]'],
  CY: ['[0-9][0-9][0-9][0-9]', '[0-9][0-9][0-9][0-9][0-9]'],
  CZ: ['[0-9][0-9][0-9] [0-9][0-9]'],
  DE: ['[0-9][0-9][0-9][0-9][0-9]'],
  DK: ['[0-9][0-9][0-9][0-9]'],
  DO: ['[0-9][0-9][0-9][0-9][0-9]'],
  DZ: ['[0-9][0-9][0-9][0-9][0-9]'],
  EC: ['[0-9][0-9][0-9][0-9][0-9][0-9]'],
  EE: ['[0-9][0-9][0-9][0-9][0-9]'],
  EG: ['[0-9][0-9][0-9][0-9][0-9]'],
  ES: ['[0-9][0-9][0-9][0-9][0-9]'],
  ET: ['[0-9][0-9][0-9][0-9]'],
  FI: ['[0-9][0-9][0-9][0-9][0-9]'],
  FM: ['[0-9][0-9][0-9][0-9][0-9]'],
  FO: ['[0-9][0-9][0-9]'],
  FR: ['[0-9][0-9][0-9][0-9][0-9]'],
  GA: [],
  GB: [
    '[a-z][0-9] [0-9][a-z][a-z]',
    '[a-z][0-9][0-9] [0-9][a-z][a-z]',
    '[a-z][0-9][a-z] [0-9][a-z][a-z]',
    '[a-z][a-z][0-9] [0-9][a-z][a-z]',
    '[a-z][a-z][0-9][0-9] [0-9][a-z][a-z]',
    '[a-z][a-z][0-9][a-z] [0-9][a-z][a-z]',
    'GIR0AA',
  ],
  GD: [],
  GE: ['[0-9][0-9][0-9][0-9]'],
  GF: ['[0-9][0-9][0-9][0-9][0-9]'],
  GG: [
    '[a-z][0-9] [0-9][a-z][a-z]',
    '[a-z][0-9][0-9] [0-9][a-z][a-z]',
    '[a-z][0-9][a-z] [0-9][a-z][a-z]',
    '[a-z][a-z][0-9] [0-9][a-z][a-z]',
    '[a-z][a-z][0-9][0-9] [0-9][a-z][a-z]',
    '[a-z][a-z][0-9][a-z] [0-9][a-z][a-z]',
    'GIR0AA',
  ],
  GL: ['[0-9][0-9][0-9][0-9]'],
  GM: [],
  GP: ['[0-9][0-9][0-9][0-9][0-9]'],
  GR: ['[0-9][0-9][0-9] [0-9][0-9]'],
  GT: ['[0-9][0-9][0-9][0-9][0-9]'],
  GU: ['969[0-9][0-9]'],
  GW: ['[0-9][0-9][0-9][0-9]'],
  GY: [],
  HK: [],
  HN: ['[0-9][0-9][0-9][0-9][0-9]'],
  HR: ['[0-9][0-9][0-9][0-9][0-9]'],
  HT: ['HT[0-9][0-9][0-9][0-9]'],
  HU: ['[0-9][0-9][0-9][0-9]'],
  ID: ['[0-9][0-9][0-9][0-9][0-9]'],
  IE: [],
  IL: ['[0-9][0-9][0-9][0-9][0-9][0-9][0-9]'],
  IM: [
    '[a-z][0-9] [0-9][a-z][a-z]',
    '[a-z][0-9][0-9] [0-9][a-z][a-z]',
    '[a-z][0-9][a-z] [0-9][a-z][a-z]',
    '[a-z][a-z][0-9] [0-9][a-z][a-z]',
    '[a-z][a-z][0-9][0-9] [0-9][a-z][a-z]',
    '[a-z][a-z][0-9][a-z] [0-9][a-z][a-z]',
    'GIR0AA',
  ],
  IN: ['[0-9][0-9][0-9][0-9][0-9][0-9]'],
  IQ: ['[0-9][0-9][0-9][0-9][0-9]'],
  IR: ['[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]'],
  IS: ['[0-9][0-9][0-9]'],
  IT: ['[0-9][0-9][0-9][0-9][0-9]'],
  JE: [
    '[a-z][0-9] [0-9][a-z][a-z]',
    '[a-z][0-9][0-9] [0-9][a-z][a-z]',
    '[a-z][0-9][a-z] [0-9][a-z][a-z]',
    '[a-z][a-z][0-9] [0-9][a-z][a-z]',
    '[a-z][a-z][0-9][0-9] [0-9][a-z][a-z]',
    '[a-z][a-z][0-9][a-z] [0-9][a-z][a-z]',
    'GIR0AA',
  ],
  JO: ['[0-9][0-9][0-9][0-9][0-9]'],
  JP: ['[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]'],
  KE: ['[0-9][0-9][0-9][0-9][0-9]'],
  KG: ['[0-9][0-9][0-9][0-9][0-9][0-9]'],
  KH: ['[0-9][0-9][0-9][0-9][0-9]'],
  KP: ['[0-9][0-9][0-9]-[0-9][0-9][0-9]'],
  KR: ['[0-9][0-9][0-9][0-9][0-9]', 'SEOUL [0-9][0-9][0-9]-[0-9][0-9][0-9]'],
  KW: ['[0-9][0-9][0-9][0-9][0-9]'],
  KZ: ['[0-9][0-9][0-9][0-9][0-9][0-9]'],
  LA: ['[0-9][0-9][0-9][0-9][0-9]'],
  LB: ['[0-9][0-9][0-9][0-9]', '[0-9][0-9][0-9][0-9] [0-9][0-9][0-9][0-9]'],
  LI: ['[0-9][0-9][0-9][0-9]'],
  LK: ['[0-9][0-9][0-9][0-9][0-9]'],
  LR: ['[0-9][0-9][0-9][0-9]'],
  LS: ['[0-9][0-9][0-9]'],
  LT: ['LT-[0-9][0-9][0-9][0-9][0-9]'],
  LU: ['L-[0-9][0-9][0-9][0-9]'],
  LV: ['LV-[0-9][0-9][0-9][0-9]'],
  MA: ['[0-9][0-9][0-9][0-9][0-9]'],
  MC: ['[0-9][0-9][0-9][0-9][0-9]'],
  MD: ['MD-[0-9][0-9][0-9][0-9]'],
  ME: ['[0-9][0-9][0-9][0-9][0-9]'],
  MF: ['[0-9][0-9][0-9][0-9][0-9]'],
  MG: ['[0-9][0-9][0-9]'],
  MH: ['[0-9][0-9][0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]'],
  MK: ['[0-9][0-9][0-9][0-9]'],
  MM: ['[0-9][0-9][0-9][0-9][0-9]'],
  MN: ['[0-9][0-9][0-9][0-9][0-9][0-9]'],
  MP: ['[0-9][0-9][0-9][0-9][0-9]'],
  MQ: ['[0-9][0-9][0-9][0-9][0-9]'],
  MT: ['[a-z][a-z][a-z] [0-9][0-9][0-9][0-9]'],
  MV: ['[0-9][0-9][0-9][0-9][0-9]'],
  MX: ['[0-9][0-9][0-9][0-9][0-9]'],
  MY: ['[0-9][0-9][0-9][0-9][0-9]'],
  MZ: ['[0-9][0-9][0-9][0-9]'],
  NC: ['[0-9][0-9][0-9][0-9][0-9]'],
  NE: ['[0-9][0-9][0-9][0-9]'],
  NF: ['[0-9][0-9][0-9][0-9]'],
  NG: ['[0-9][0-9][0-9][0-9][0-9][0-9]'],
  NI: ['[0-9][0-9][0-9][0-9][0-9]'],
  NL: ['[0-9][0-9][0-9][0-9] ?[a-z][a-z]'],
  NO: ['[0-9][0-9][0-9][0-9]'],
  NP: ['[0-9][0-9][0-9][0-9][0-9]'],
  NZ: ['[0-9][0-9][0-9][0-9]'],
  OM: ['[0-9][0-9][0-9]'],
  PA: ['[0-9][0-9][0-9][0-9]'],
  PE: ['[0-9][0-9][0-9][0-9][0-9]'],
  PF: ['[0-9][0-9][0-9][0-9][0-9]'],
  PG: ['[0-9][0-9][0-9]'],
  PH: ['[0-9][0-9][0-9][0-9]'],
  PK: ['[0-9][0-9][0-9][0-9][0-9]'],
  PL: ['[0-9][0-9]-[0-9][0-9][0-9]'],
  PM: ['[0-9][0-9][0-9][0-9][0-9]'],
  PR: [
    '[0-9][0-9][0-9][0-9][0-9]',
    '[0-9][0-9][0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]',
  ],
  PT: ['[0-9][0-9][0-9][0-9]-[0-9][0-9][0-9]'],
  PW: ['96940'],
  PY: ['[0-9][0-9][0-9][0-9]'],
  QA: [],
  RE: ['[0-9][0-9][0-9][0-9][0-9]'],
  RO: ['[0-9][0-9][0-9][0-9][0-9][0-9]'],
  RS: ['[0-9][0-9][0-9][0-9][0-9]', '[0-9][0-9][0-9][0-9][0-9][0-9]'],
  RU: ['[0-9][0-9][0-9][0-9][0-9][0-9]'],
  SA: ['[0-9][0-9][0-9][0-9][0-9]'],
  SD: ['[0-9][0-9][0-9][0-9][0-9]'],
  SE: ['[0-9][0-9][0-9] [0-9][0-9]'],
  SG: ['[0-9][0-9][0-9][0-9][0-9][0-9]'],
  SH: ['STHL 1ZZ'],
  SI: ['[0-9][0-9][0-9][0-9]'],
  SJ: ['[0-9][0-9][0-9][0-9]'],
  SK: ['[0-9][0-9][0-9] [0-9][0-9]'],
  SM: ['4789[0-9]'],
  SN: ['[0-9][0-9][0-9][0-9][0-9]'],
  SO: ['[a-z][a-z]  [0-9][0-9][0-9][0-9][0-9]'],
  SV: ['CP [0-9][0-9][0-9][0-9]'],
  SZ: ['[a-z][0-9][0-9][0-9]'],
  TC: ['TKCA 1ZZ'],
  TH: ['[0-9][0-9][0-9][0-9][0-9]'],
  TJ: ['[0-9][0-9][0-9][0-9][0-9][0-9]'],
  TM: ['[0-9][0-9][0-9][0-9][0-9][0-9]'],
  TN: ['[0-9][0-9][0-9][0-9]'],
  TR: ['[0-9][0-9][0-9][0-9][0-9]'],
  TT: [],
  TW: ['[0-9][0-9][0-9]', '[0-9][0-9][0-9][0-9][0-9]'],
  UA: ['[0-9][0-9][0-9][0-9][0-9]'],
  UG: [],
  US: [
    '[0-9][0-9][0-9][0-9][0-9]',
    '[0-9][0-9][0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]',
  ],
  UY: ['[0-9][0-9][0-9][0-9][0-9]'],
  UZ: ['[0-9][0-9][0-9][0-9][0-9][0-9]'],
  VA: ['[0-9][0-9][0-9][0-9][0-9]'],
  VE: ['[0-9][0-9][0-9][0-9]'],
  VI: ['[0-9][0-9][0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]'],
  VN: ['[0-9][0-9][0-9][0-9][0-9][0-9]'],
  WF: ['[0-9][0-9][0-9][0-9][0-9]'],
  YT: ['[0-9][0-9][0-9][0-9][0-9]'],
  ZA: ['[0-9][0-9][0-9][0-9]'],
  ZM: ['[0-9][0-9][0-9][0-9][0-9]'],
  AG: [],
  AW: [],
  BS: [],
  BZ: [],
  BJ: [],
  BO: [],
  KY: [],
  DM: [],
  GH: [],
  JM: [],
  MO: [],
  MW: [],
  AN: [],
  KN: [],
  TZ: [],
  TG: [],
}
