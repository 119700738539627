import { decode } from 'html-entities'
import parse, { domToReact } from 'html-react-parser'
import DOMPurify from 'isomorphic-dompurify'
import React from 'react'
import type {
  DOMNode,
  Element,
  HTMLReactParserOptions,
} from 'html-react-parser'
import type { ReactElement } from 'react'

const LINKS_ONLY_ATTRIBUTES = ['target', 'rel']

const linkLocaliser = (content: string, locale: string) => {
  return content.replace(/href="(.*?)"/g, (_matcher, $1) => {
    if (!$1.includes('://') && !$1.includes('//') && $1.startsWith('/')) {
      return `href="/${locale}${$1}"`
    }
    return `href="${$1}"`
  })
}

const sanitisedHTML = (description: string) =>
  DOMPurify.sanitize(description, {
    FORBID_TAGS: ['div'],

    // Appends attributes to the list permitted for any element.
    // Requires uponSanitizeElement hook to further limit to specific elements
    ADD_ATTR: LINKS_ONLY_ATTRIBUTES,
  })

DOMPurify.addHook('uponSanitizeElement', (node, _data, _config) => {
  // strip rel and target attributes from any elements that are not anchor elements
  if (node.nodeName.toUpperCase() !== 'A' && node.attributes?.length) {
    LINKS_ONLY_ATTRIBUTES.forEach((att) => {
      node.removeAttribute(att)
    })
  }
})

export const formattedHTML = (
  descriptionRaw: string,
  keysPrefix: string,
  locale: string
) => {
  let unboundedPhrasingContent: DOMNode[] = []
  const phrasingTags = [
    'em',
    'sup',
    'sub',
    'var',
    'time',
    'strong',
    'span',
    'wbr',
    'small',
    'samp',
    'ruby',
    'q',
    'math',
    'kbd',
    'i',
    'dfn',
    'data',
    'code',
    'cite',
    'bdo',
    'bdi',
    'b',
    'abbr',
    'a',
    'img',
  ]
  const containerTags = ['p', 'ul', 'ol', 'li', 'td', 'th']

  const isPhrasing = (domNode: Element) =>
    ['tag', 'text'].includes(domNode.type) &&
    phrasingTags.includes(domNode.name)

  const isLineBreak = (domNode: Element) =>
    domNode.type === 'tag' && domNode.name === 'br'

  const isUnenclosedText = (domNode: DOMNode) =>
    domNode.type === 'text' && domNode.parent === null

  const isContainerElement = (domNode: Element): boolean =>
    domNode && containerTags.includes(domNode.name)

  const isInsideAContainerEventually = (domNode: Element): boolean => {
    if (isContainerElement(domNode)) {
      return true
    }

    return domNode.parent
      ? isInsideAContainerEventually(domNode.parent as Element)
      : false
  }

  const hasContent = (node: DOMNode) => {
    if (isLineBreak(node as Element)) {
      return false
    }

    if (node.type === 'text') {
      const textContent = (node as unknown as Text).data

      if (
        textContent.replace(/(\r\n|\n|\r|\t|\\t)/gm, '').trim().length === 0
      ) {
        return false
      }
    }

    return true
  }

  const containsOnlyEmptyText = (domNode: Element) =>
    !((domNode.children as DOMNode[]) ?? []).some(hasContent)

  // html-react-parser uses null/undefined a a signal to skip replacement behaviour
  const keepOriginalElement = null
  const outputNothing = <></>

  const paragraphWrap = (domNodes: DOMNode[]) => {
    if (domNodes.every((node) => isLineBreak(node as Element))) {
      // exclude paragraphs of just line breaks
      unboundedPhrasingContent = []
      return outputNothing
    }

    const wrapped = <p>{domToReact(domNodes)}</p>
    unboundedPhrasingContent = []
    return wrapped
  }

  /**
   * Called per-node by html-react-parser for the children of non-empty container nodes.
   * A light-weight replace function for HTMLReactParserOptions for handling empty container children within a main container
   * @param domNode - The current node being evaluated
   * @returns The current node (leave it as-is) OR empty element (removes it)
   */
  const replaceEmptyContentWithinContainer = (domNode: DOMNode) => {
    const domElement = domNode as Element

    const thisIsContainer = isContainerElement(domElement)
    const containsEmptyTextOnly = containsOnlyEmptyText(domElement)

    if (thisIsContainer) {
      return containsEmptyTextOnly ? outputNothing : keepOriginalElement
    }

    const isEmptyText = !isLineBreak(domElement) && !hasContent(domNode)
    return isEmptyText ? outputNothing : keepOriginalElement
  }

  /**
   * Called per-node by html-react-parser to evaluate and optionally replace a node.
   * Used here to:
   *  - skip empty nodes or those with no content
   *  - wrap unbound phrasing content in a relevant paragraph container
   * @param {DOMNode} domNode - The current node being evaluated
   * @returns The current node (leave it as-is), domToReact(domNode) (run the same parser process for the node and its children), empty element (removes it)
   */
  const replaceEmptyContainersWrapContent = (domNode: DOMNode) => {
    const domElement = domNode as Element

    const thisIsContainer = isContainerElement(domElement)
    if (thisIsContainer) {
      const emptyTextOnly = containsOnlyEmptyText(domElement)
      return (
        <>
          {unboundedPhrasingContent.length > 0
            ? paragraphWrap(unboundedPhrasingContent)
            : null}
          {!emptyTextOnly
            ? domToReact([domNode], {
                replace: replaceEmptyContentWithinContainer,
              })
            : null}
        </>
      )
    }

    const isContainedEventually = isInsideAContainerEventually(domElement)
    if (isContainedEventually) {
      return keepOriginalElement
    }

    const thisIsUnenclosedText = isUnenclosedText(domNode)
    if (
      thisIsUnenclosedText ||
      isPhrasing(domElement) ||
      isLineBreak(domElement)
    ) {
      const isEmptyText = thisIsUnenclosedText && !hasContent(domNode)
      if (!isEmptyText) {
        unboundedPhrasingContent.push(domElement)
      }

      const isFinalElement = !domNode?.next

      // ensure any unbound content is output if there are no more elements to
      // check; invocations of this for future elements will ensure that
      // unboundedPhrasingContent gets rendered
      return isFinalElement
        ? paragraphWrap(unboundedPhrasingContent)
        : outputNothing
    }

    return keepOriginalElement
  }

  let description = decode(
    descriptionRaw
      .replaceAll('℃', '&deg;C') // Clean up the degree symbol
      .replace(/(\r\n|\n|\r)/gm, '<br>')
      .replace(/\t|\\t/gm, ' ')
  )
  description = linkLocaliser(description, locale)

  const options: HTMLReactParserOptions = {
    replace: replaceEmptyContainersWrapContent,
  }
  let parsed = parse(sanitisedHTML(description), options)
  if ((parsed as JSX.Element[]).length === undefined) {
    return parsed
  }

  parsed = (parsed as unknown as ReactElement[]).map(
    (element: ReactElement, index: number) => {
      return React.cloneElement(element, { key: `${keysPrefix}-${index}` })
    }
  )

  return parsed
}
