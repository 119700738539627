import { EcomOrganisationAddressContact } from '@abcam-web/shared/data-access/ecommerce-schema'
import { ClickId, PageSubtype } from '@abcam-web/shared/data-access/tracking'
import {
  getCountryCodeAndPhoneNumberFromPrimaryPhoneNumber,
  useCheckoutTracking,
} from '@abcam-web/shared/ecommerce/utilities'
import { SimpleTextInput } from '@lego/ui/form-elements'
import { Bin, WarningIcon } from '@lego/ui/icons'
import { Notification } from '@lego/ui/notification'
import classNames from 'classnames'
import { isEmpty, isNil, omitBy, uniqueId } from 'lodash'
import { FC, useEffect, useMemo, useState } from 'react'
import { Collapse } from 'react-collapse'
import { useIntl } from 'react-intl'

import { SearchSelect } from '../search-select'
import { TelephoneInput } from '../telephone-input'
import styles from './contact-info.module.css'

export type Contact = {
  id?: string
  firstname: string
  lastname: string
  orgId?: string
  emails: string[]
  phone: {
    countryCode: string
    telephoneNumber: string
  }
}
interface ContactInfoProps {
  id: string
  title?: string
  description?: string
  loading?: boolean
  disabled?: boolean
  hint?: string
  contacts?: EcomOrganisationAddressContact[]
  emailHint?: string
  telephoneNumberHint?: string
  defaultCountry?: string
  telephoneNumberRequired?: boolean
  additionalEmailHint?: string
  defaultContact?: Contact
  hideAdditionalEmail?: boolean
  onContactSelect: (
    contact: EcomOrganisationAddressContact | undefined,
    isNew: boolean
  ) => void
  pageSubType: PageSubtype
}

type Option = {
  value: string
  label: string
  contact: EcomOrganisationAddressContact | undefined
}

const ContactInfo: FC<ContactInfoProps> = ({
  id,
  title,
  description,
  defaultContact,
  loading = false,
  disabled,
  defaultCountry,
  contacts,
  emailHint = undefined,
  hint,
  telephoneNumberHint = undefined,
  telephoneNumberRequired = true,
  additionalEmailHint = undefined,
  hideAdditionalEmail = false,
  onContactSelect,
  pageSubType,
}) => {
  const [contact, setContact] = useState(defaultContact)
  const [isContactMatching, setIsContactMatching] = useState(false)
  const [emails, setEmails] = useState(
    defaultContact?.emails?.length ? defaultContact?.emails : ['']
  )
  const [inputContact, setInputContact] = useState(false)
  const [formKey, setFormKey] = useState(uniqueId())
  const { trackEngagement } = useCheckoutTracking({ pageSubType })
  const { formatMessage } = useIntl()

  const messages = useMemo(
    () => ({
      searchSelectPlaceholder: formatMessage({
        id: 'contactInfo.searchSelect.placeholder',
      }),
      searchSelectLabel: formatMessage({
        id: 'contactInfo.searchSelect.label',
      }),
      inputFirstNameLabel: formatMessage({
        id: 'contactInfo.input.firstName.label',
      }),
      inputLastNameLabel: formatMessage({
        id: 'contactInfo.input.lastName.label',
      }),
      inputEmailLabel: formatMessage({ id: 'contactInfo.input.email.label' }),
      inputAdditionalEmailLabel: formatMessage({
        id: 'contactInfo.input.additionalEmail.label',
      }),
      addAdditionalAddress: formatMessage({
        id: 'contactInfo.addAdditionalAddress',
      }),
      telephone: formatMessage({ id: 'contactInfo.telephone' }),
      telephoneInputError: formatMessage({
        id: 'contactInfo.telephoneInputError',
      }),
      newContact: formatMessage({ id: 'contactInfo.newContact' }),
    }),
    [formatMessage]
  )

  const generateNewFormKey = () => {
    setFormKey(uniqueId())
  }

  useEffect(() => {
    const contactWithoutNill = omitBy(defaultContact, isNil) as Contact

    if (
      contactWithoutNill &&
      !isEmpty(contactWithoutNill) &&
      contactWithoutNill?.firstname !== '' &&
      contactWithoutNill?.lastname !== ''
    ) {
      setContact(contactWithoutNill)
      const match = !!contacts?.find(
        (item) =>
          item.firstname === contactWithoutNill?.firstname &&
          item.lastname === contactWithoutNill.lastname
      )
      setEmails(
        contactWithoutNill?.emails?.length ? contactWithoutNill?.emails : ['']
      )
      setInputContact(false)
      generateNewFormKey()
      setIsContactMatching(match)
    }
  }, [defaultContact, contacts])

  const { section, infoGroup, fieldsGroup, fieldItem, subTitle, subTitleBody } =
    styles

  const options: Option[] = (contacts || []).map((contact) => ({
    value: `${contact?.firstname} ${contact?.lastname}`,
    label: `${contact?.firstname} ${contact?.lastname}`,
    contact: { ...contact, id: uniqueId() },
  }))

  options?.push({
    value: messages.newContact,
    label: messages.newContact,
    contact: undefined,
  })

  const isNewContact = !isContactMatching && !loading && inputContact

  const valueSelectProp = !isContactMatching &&
    contact?.firstname && { value: options?.slice(-1) }

  const countryCode = contact?.phone?.countryCode || ''

  const optionIndex = options.findIndex((item) => {
    return (
      item?.contact?.firstname === contact?.firstname &&
      item?.contact?.lastname === contact?.lastname
    )
  })

  const selectedOptionIndex =
    optionIndex !== -1 && !inputContact ? optionIndex : options.length - 1

  return (
    <div className={section}>
      <div className={infoGroup}>
        {title && <span className={subTitle}>{title}</span>}
        {description && <div className={subTitleBody}>{description}</div>}
      </div>

      <div className={fieldsGroup}>
        <fieldset
          onChange={(e) => {
            if ((e.target as HTMLInputElement).name !== 'additionalEmail') {
              setInputContact(true)
              setIsContactMatching(false)
              onContactSelect(undefined, true)
            }
          }}
        >
          <div className={fieldItem}>
            <SearchSelect
              instanceId={title}
              label={messages.searchSelectLabel}
              isLoading={loading}
              {...valueSelectProp}
              value={options[selectedOptionIndex]}
              isDisabled={disabled || loading}
              placeholder={messages.searchSelectPlaceholder}
              options={options}
              formatOptionLabel={(data: any) => {
                const { contact } = data as any
                return (
                  <span data-cy="contact-details" className="text-body-small">
                    {contact ? (
                      <>
                        {contact?.firstname} {contact.lastname}
                      </>
                    ) : (
                      <strong>{messages.newContact}</strong>
                    )}
                  </span>
                )
              }}
              onChange={(data: any) => {
                const { contact } = data as {
                  contact: EcomOrganisationAddressContact
                }
                setInputContact(false)
                generateNewFormKey()
                const { countryCode, telephoneNumber } =
                  getCountryCodeAndPhoneNumberFromPrimaryPhoneNumber(
                    contact?.primaryPhoneNumber
                  )
                setContact({
                  firstname: contact?.firstname,
                  lastname: contact?.lastname || '',
                  emails: contact?.emailAddress ? [contact.emailAddress] : [],
                  phone: {
                    countryCode,
                    telephoneNumber,
                  },
                })
                setEmails(contact?.emailAddress ? [contact.emailAddress] : [''])
                const isNew = data.value === messages.newContact
                onContactSelect(contact, isNew)
                setIsContactMatching(true)

                trackEngagement({
                  click_id: ClickId.SELECT_CONTACT,
                  item_cta: contact?.firstname ? 'existing' : 'new',
                })
              }}
            />
          </div>
          <div className={fieldItem}>
            <SimpleTextInput
              labelClassName="text-body-small"
              inputClassName="text-body-small"
              wrapperClassName="mt-4"
              name="firstname"
              label={messages.inputFirstNameLabel}
              id={contact?.firstname}
              key={`firstname-${formKey}`}
              required
              defaultValue={contact?.firstname ?? ''}
              onBlur={(e) =>
                trackEngagement({
                  click_id: ClickId.UPDATE_FIRST_NAME,
                  item_cta: e.target.value,
                })
              }
            />
          </div>
          <div className={fieldItem}>
            <SimpleTextInput
              labelClassName="text-body-small"
              inputClassName="text-body-small"
              defaultValue={contact?.lastname ?? ''}
              required
              wrapperClassName="mt-4"
              name="lastname"
              id={contact?.lastname}
              key={`lastname-${formKey}`}
              label={messages.inputLastNameLabel}
              onBlur={(e) =>
                trackEngagement({
                  click_id: ClickId.UPDATE_LAST_NAME,
                  item_cta: e.target.value,
                })
              }
            />
          </div>
          <div className={fieldItem}>
            {emails?.map((item, index) => (
              <div key={index} className={classNames({ flex: index })}>
                <SimpleTextInput
                  labelClassName="text-body-small"
                  inputClassName="text-body-small"
                  wrapperClassName="mt-4"
                  defaultValue={item ?? ''}
                  required
                  id={contact?.emails?.[index]}
                  onChange={(e) => {
                    const value = (e.target as HTMLTextAreaElement).value
                    setEmails((currentEmails) => {
                      const newEmails = [...currentEmails]
                      newEmails[index] = value
                      return newEmails
                    })
                  }}
                  onBlur={(e) =>
                    trackEngagement({
                      click_id: ClickId.UPDATE_EMAIL,
                      item_cta: (e.target as HTMLInputElement).value,
                    })
                  }
                  key={`email-${index}-${formKey}`}
                  className="mb-0"
                  hintClassName="text-body-small"
                  hint={index === 0 ? emailHint : additionalEmailHint}
                  type="email"
                  name={index === 0 ? 'email' : 'additionalEmail'}
                  placeholder="john@doe.com"
                  label={
                    index === 0
                      ? messages.inputEmailLabel
                      : messages.inputAdditionalEmailLabel
                  }
                />
                {index !== 0 && (
                  <Bin
                    onClick={() => {
                      const newEmails = [...emails]
                      newEmails.splice(index, 1)
                      setEmails(newEmails)
                    }}
                    className="mt-auto mb-3 ml-2 cursor-pointer"
                  />
                )}
              </div>
            ))}
            {emails?.length < 2 && !hideAdditionalEmail && (
              <div
                onClick={() => {
                  const newEmails = [...emails]
                  newEmails.push('')
                  setEmails(newEmails)
                  trackEngagement({
                    click_id: ClickId.ADD_ADDITIONAL_EMAIL,
                    item_cta: '',
                  })
                }}
                className="font-semibold text-right cursor-pointer hover:underline text-body-small text-blue-default"
              >
                {messages.addAdditionalAddress}
              </div>
            )}
          </div>

          <div className={fieldItem}>
            {!loading && (
              <div className="text-body-small">
                <label htmlFor="telephoneInput" className="font-semibold ">
                  {messages.telephone}
                </label>
                {telephoneNumberHint && (
                  <p className="text-grey50 font-body">{telephoneNumberHint}</p>
                )}
              </div>
            )}
            <TelephoneInput
              id={id}
              key={`telephone-input-${formKey}`}
              errorMessage={messages.telephoneInputError}
              defaultCountry={defaultCountry}
              name="telephone"
              countryCodeDefaultValue={countryCode}
              telephoneNumberDefaultValue={contact?.phone?.telephoneNumber}
              required={telephoneNumberRequired}
              onBlur={(e) =>
                trackEngagement({
                  click_id: ClickId.UPDATE_PHONE,
                  item_cta: e.target.value,
                })
              }
            />
            {hint && (
              <label className="font-normal text-ui-medium text-grey50">
                {hint}
              </label>
            )}
          </div>
        </fieldset>
      </div>
    </div>
  )
}

export default ContactInfo
