import { EnvironmentFinder } from '@abcam-web/shared/utilities/environment'
import {
  isFreshchatInitialized,
  initializeFreshchat,
  destroyFreshchat,
  userConsentsToFunctionalCookies,
} from 'libs/assist/pages/contact-us-page/src/lib/component-wrappers/chat-with-us-wrapper/freshchat'
import { FeatureToggleName } from '@feature-toggles/feature-toggle-name.enum'
import { useEffect, useRef } from 'react'
import type { FeatureToggle } from '@abcam-web/shared/data-access/feature-toggle-schema'
import type { Environment } from '@abcam-web/shared/utilities/environment'
import { useIsPunchoutUser } from '@abcam-web/auth-shared/aws-cognito/hooks/useIsPunchoutUser'
import { MarketType } from './common.types'
import { isCountryChinaOrJapan } from '@abcam-web/shared/utilities/localisation'
import { useMarketInfo } from '@abcam-web/shared/ecommerce/utilities'
import { useCountry } from '@abcam-web/shared/utilities/country'

declare global {
  interface Window {
    OnetrustActiveGroups: string
    OneTrust: {
      OnConsentChanged: (callback: () => void) => void
      ToggleInfoDisplay: () => void
    }
    OptanonWrapper: () => void
  }
}

export const useFreshchat = (
  environment: Environment,
  isOneTrustEnabled: boolean,
  featureToggles: FeatureToggle[]
) => {
  const { country: countryCode } = useCountry()
  const marketInfo = useMarketInfo()
  const marketType = marketInfo?.data?.marketType

  const isPunchoutUser = useIsPunchoutUser()

  const assistFeatureToggle = featureToggles.find(
    ({ name }) => name === FeatureToggleName.TempAssistContactUs
  )

  const isAssistEnabled = assistFeatureToggle && assistFeatureToggle.enabled
  const isChinaOrJapan = isCountryChinaOrJapan(countryCode)

  const isFreshChatAvailableInCountry =
    (marketType === MarketType.Direct || marketType === MarketType.Mixed) &&
    !isChinaOrJapan

  const latestIsFreshChatAvailableInCountry = useRef<boolean>()
  latestIsFreshChatAvailableInCountry.current = isFreshChatAvailableInCountry

  const latestIsPunchoutUser = useRef<boolean>()
  latestIsPunchoutUser.current = isPunchoutUser

  // If a user has already consented the following effect starts Freshchat
  useEffect(() => {
    setTimeout(() => {
      if (typeof window !== 'undefined') {
        if (isFreshChatAvailableInCountry && !isPunchoutUser) {
          if (userConsentsToFunctionalCookies()) {
            if (!isFreshchatInitialized()) {
              initializeFreshchat(
                environment.FRESHCHAT_TOKEN,
                environment.FRESHCHAT_HOST,
                environment.FRESHCHAT_UUID
              )
            }
          }
        } else {
          destroyFreshchat()
        }
      }
    }, 3000)
  }, [
    environment.FRESHCHAT_HOST,
    environment.FRESHCHAT_TOKEN,
    environment.FRESHCHAT_UUID,
    isFreshChatAvailableInCountry,
    isPunchoutUser,
  ])

  // This effect sets-up the OneTrust consent handler. It sets up the handler to start
  // FreshChat.
  useEffect(() => {
    if (
      (isOneTrustEnabled || EnvironmentFinder.isProdLike()) &&
      isAssistEnabled &&
      typeof window !== 'undefined' &&
      !window.OptanonWrapper
    ) {
      window.OptanonWrapper = () => {
        window.OneTrust.OnConsentChanged(() => {
          if (
            userConsentsToFunctionalCookies() &&
            // Due to window.OneTrust.OnConsentChanged creating a closure over the callback function
            // use useRef to get the latest IsFreshChatAvailableInCountry/isPunchoutUser values to escape closure hell.
            latestIsFreshChatAvailableInCountry.current &&
            !latestIsPunchoutUser.current
          ) {
            if (!isFreshchatInitialized()) {
              initializeFreshchat(
                environment.FRESHCHAT_TOKEN,
                environment.FRESHCHAT_HOST,
                environment.FRESHCHAT_UUID
              )
            }
          } else {
            destroyFreshchat()
          }
        })
      }
    }
  }, [
    environment.FRESHCHAT_HOST,
    environment.FRESHCHAT_TOKEN,
    environment.FRESHCHAT_UUID,
    isAssistEnabled,
    isOneTrustEnabled,
    isPunchoutUser,
  ])

  return isFreshChatAvailableInCountry
}
