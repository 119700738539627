import { DiscountReason } from '@abcam-web/shared/data-access/ecommerce-schema'

type Country = {
  code: string
  name: string
}

enum CtaText {
  ADD_TO_BASKET = 'Add to basket',
  ADD_TO_INQUIRY_BASKET = 'Add to inquiry basket',
  BUY = 'Buy',
  INQUIRE = 'Inquire',
}

const editPriceReasons = [
  {
    key: 'null',
    displayValue: 'Select a discount reason',
    isPlaceholder: true,
    focr: false,
  },
  {
    key: DiscountReason.ORA_ERROR_CORRECTION,
    displayValue: 'Error Correction',
    focr: false,
  },
  {
    key: DiscountReason.ORA_OTHER,
    displayValue: 'Other',
    focr: false,
  },
  {
    key: DiscountReason.ORA_PRICE_MATCH,
    displayValue: 'Price Match',
    focr: false,
  },
  {
    key: DiscountReason.ORA_SALES_NEGOTIATION,
    displayValue: 'Sales Negotiation',
    focr: false,
  },
  {
    key: DiscountReason.XXAB_ABTRIAL_ABREVIEW,
    displayValue: 'Abtrial and Abreview',
    focr: false,
  },
  {
    key: DiscountReason.XXAB_CAP_PRICE,
    displayValue: 'Cap Price',
    focr: false,
  },
  {
    key: DiscountReason.XXAB_COLLABORATOR,
    displayValue: 'Collaborator',
    focr: false,
  },
  {
    key: DiscountReason.XXAB_CS_TRAINING,
    displayValue: 'CS Training',
    focr: false,
  },
  {
    key: DiscountReason.XXAB_CUSTOMER_REQUEST_PRICE,
    displayValue: 'Customer Request Price',
    focr: false,
  },
  {
    key: DiscountReason.XXAB_DISTRIBUTOR,
    displayValue: 'Distributor',
    focr: false,
  },
  {
    key: DiscountReason.XXAB_FOCR_MISSING_COMPONENT,
    displayValue: 'Entered in Error',
    focr: false,
  },
  {
    key: DiscountReason.XXAB_FOCR_COURIER_ERROR,
    displayValue: 'Courier in Error',
    focr: true,
  },
  {
    key: DiscountReason.XXAB_FOCR_CUSTOMS_ISSUE,
    displayValue: 'Customs Issue',
    focr: true,
  },
  {
    key: DiscountReason.XXAB_FOCR_DATASHEET_ERROR,
    displayValue: 'Datasheet Error',
    focr: true,
  },
  {
    key: DiscountReason.XXAB_FOCR_GOODSOUT_ERROR,
    displayValue: 'Goodsout Error',
    focr: true,
  },
  {
    key: DiscountReason.XXAB_FOCR_GOODWILL,
    displayValue: 'Goodwill',
    focr: true,
  },
  {
    key: DiscountReason.XXAB_FOCR_INCORRECT_CONCEN,
    displayValue: 'Incorrect Concentration',
    focr: true,
  },
  {
    key: DiscountReason.XXAB_FOCR_MISSING_COMPONENT,
    displayValue: 'Missing Component',
    focr: true,
  },
  {
    key: DiscountReason.XXAB_FOCR_ORDER_ERROR_BYCS,
    displayValue: 'Order Error By cs',
    focr: true,
  },
  {
    key: DiscountReason.XXAB_FOCR_PAN,
    displayValue: 'PAN',
    focr: true,
  },
  {
    key: DiscountReason.XXAB_FOCR_PRODUCT_QUALITY,
    displayValue: 'Product Quality',
    focr: true,
  },
  {
    key: DiscountReason.XXAB_FOCR_PRODUCT_QUALITY_GUAR,
    displayValue: 'Product Quality Guarantee',
    focr: true,
  },
  {
    key: DiscountReason.XXAB_FOCR_VOLUME_SHORTAGE,
    displayValue: 'Volume Shortage',
    focr: true,
  },
  {
    key: DiscountReason.XXAB_FOCR_WARM_VIAL,
    displayValue: 'Warm Vial',
    focr: true,
  },
  {
    key: DiscountReason.XXAB_FREE_SAMPLE,
    displayValue: 'Free Sample',
    focr: false,
  },
  {
    key: DiscountReason.XXAB_GOODWILL,
    displayValue: 'Goodwill',
    focr: false,
  },
  {
    key: DiscountReason.XXAB_LVO_DISCOUNT,
    displayValue: 'LVO Discount',
    focr: false,
  },
  {
    key: DiscountReason.XXAB_PROMOTION,
    displayValue: 'Promotion',
    focr: false,
  },
  {
    key: DiscountReason.XXAB_TECHNICAL,
    displayValue: 'Technical',
    focr: false,
  },
]

const countries = [
  { code: 'AL', name: 'Albania', dialingCode: '+355' },
  { code: 'DZ', name: 'Algeria', dialingCode: '+213' },
  { code: 'AG', name: 'Antigua and Barbuda', dialingCode: '+1-268' },
  { code: 'AR', name: 'Argentina', dialingCode: '+54' },
  { code: 'AM', name: 'Armenia', dialingCode: '+374' },
  { code: 'AW', name: 'Aruba', dialingCode: '+297' },
  { code: 'AU', name: 'Australia', dialingCode: '+61' },
  { code: 'AT', name: 'Austria', dialingCode: '+43' },
  { code: 'AZ', name: 'Azerbaijan', dialingCode: '+994' },
  { code: 'BS', name: 'Bahamas', dialingCode: '+1-242' },
  { code: 'BH', name: 'Bahrain', dialingCode: '+973' },
  { code: 'BD', name: 'Bangladesh', dialingCode: '+880' },
  { code: 'BB', name: 'Barbados', dialingCode: '+1-246' },
  { code: 'BY', name: 'Belarus', dialingCode: '+375' },
  { code: 'BE', name: 'Belgium', dialingCode: '+32' },
  { code: 'BZ', name: 'Belize', dialingCode: '+501' },
  { code: 'BJ', name: 'Benin', dialingCode: '+229' },
  { code: 'BM', name: 'Bermuda', dialingCode: '+1-441' },
  { code: 'BO', name: 'Bolivia', dialingCode: '+591' },
  { code: 'BA', name: 'Bosnia and Herzegovina', dialingCode: '+387' },
  { code: 'BW', name: 'Botswana', dialingCode: '+267' },
  { code: 'BR', name: 'Brazil', dialingCode: '+55' },
  { code: 'BN', name: 'Brunei Darussalam', dialingCode: '+673' },
  { code: 'BG', name: 'Bulgaria', dialingCode: '+359' },
  { code: 'KH', name: 'Cambodia', dialingCode: '+855' },
  { code: 'CM', name: 'Cameroon', dialingCode: '+237' },
  { code: 'CA', name: 'Canada', dialingCode: '+1' },
  { code: 'KY', name: 'Cayman Islands', dialingCode: '+1-345' },
  { code: 'CL', name: 'Chile', dialingCode: '+56' },
  { code: 'CN', name: 'China', dialingCode: '+86' },
  { code: 'CO', name: 'Colombia', dialingCode: '+57' },
  { code: 'CR', name: 'Costa Rica', dialingCode: '+506' },
  { code: 'CI', name: 'Cote d’Ivoire', dialingCode: '+225' },
  { code: 'HR', name: 'Croatia', dialingCode: '+385' },
  { code: 'CY', name: 'Cyprus', dialingCode: '+357' },
  { code: 'CZ', name: 'Czech Republic', dialingCode: '+420' },
  { code: 'DK', name: 'Denmark', dialingCode: '+45' },
  { code: 'DM', name: 'Dominica', dialingCode: '+1-767' },
  { code: 'DO', name: 'Dominican Republic', dialingCode: '+1-809' },
  { code: 'EC', name: 'Ecuador', dialingCode: '+593' },
  { code: 'EG', name: 'Egypt', dialingCode: '+20' },
  { code: 'SV', name: 'El Salvador', dialingCode: '+503' },
  { code: 'EE', name: 'Estonia', dialingCode: '+372' },
  { code: 'ET', name: 'Ethiopia', dialingCode: '+251' },
  { code: 'FO', name: 'Faroe Islands', dialingCode: '+298' },
  { code: 'FI', name: 'Finland', dialingCode: '+358' },
  { code: 'FR', name: 'France', dialingCode: '+33' },
  { code: 'GF', name: 'French Guiana', dialingCode: '+594' },
  { code: 'GA', name: 'Gabon', dialingCode: '+241' },
  { code: 'GM', name: 'Gambia', dialingCode: '+220' },
  { code: 'GE', name: 'Georgia', dialingCode: '+995' },
  { code: 'DE', name: 'Germany', dialingCode: '+49' },
  { code: 'GH', name: 'Ghana', dialingCode: '+233' },
  { code: 'GR', name: 'Greece', dialingCode: '+30' },
  { code: 'GD', name: 'Grenada', dialingCode: '+1-473' },
  { code: 'GP', name: 'Guadeloupe', dialingCode: '+590' },
  { code: 'GU', name: 'Guam', dialingCode: '+1-671' },
  { code: 'GT', name: 'Guatemala', dialingCode: '+502' },
  { code: 'GY', name: 'Guyana', dialingCode: '+592' },
  { code: 'HT', name: 'Haiti', dialingCode: '+509' },
  { code: 'HN', name: 'Honduras', dialingCode: '+504' },
  { code: 'HK', name: 'Hong Kong', dialingCode: '+852' },
  { code: 'HU', name: 'Hungary', dialingCode: '+36' },
  { code: 'IS', name: 'Iceland', dialingCode: '+354' },
  { code: 'IN', name: 'India', dialingCode: '+91' },
  { code: 'ID', name: 'Indonesia', dialingCode: '+62' },
  { code: 'IE', name: 'Ireland', dialingCode: '+353' },
  { code: 'IL', name: 'Israel', dialingCode: '+972' },
  { code: 'IT', name: 'Italy', dialingCode: '+39' },
  { code: 'JM', name: 'Jamaica', dialingCode: '+1-876' },
  { code: 'JP', name: 'Japan', dialingCode: '+81' },
  { code: 'JO', name: 'Jordan', dialingCode: '+962' },
  { code: 'KZ', name: 'Kazakhstan', dialingCode: '+7' },
  { code: 'KE', name: 'Kenya', dialingCode: '+254' },
  { code: 'KR', name: 'Korea, Rep of', dialingCode: '+82' },
  { code: 'KW', name: 'Kuwait', dialingCode: '+965' },
  { code: 'KG', name: 'Kyrgyzstan', dialingCode: '+996' },
  { code: 'LV', name: 'Latvia', dialingCode: '+371' },
  { code: 'LB', name: 'Lebanon', dialingCode: '+961' },
  { code: 'LT', name: 'Lithuania', dialingCode: '+370' },
  { code: 'LU', name: 'Luxembourg', dialingCode: '+352' },
  { code: 'MO', name: 'Macau', dialingCode: '+853' },
  { code: 'MK', name: 'Macedonia', dialingCode: '+389' },
  { code: 'MG', name: 'Madagascar', dialingCode: '+261' },
  { code: 'MW', name: 'Malawi', dialingCode: '+265' },
  { code: 'MY', name: 'Malaysia', dialingCode: '+60' },
  { code: 'MT', name: 'Malta', dialingCode: '+356' },
  { code: 'MQ', name: 'Martinique', dialingCode: '+596' },
  { code: 'MX', name: 'Mexico', dialingCode: '+52' },
  { code: 'MC', name: 'Monaco', dialingCode: '+377' },
  { code: 'MA', name: 'Morocco', dialingCode: '+212' },
  { code: 'NP', name: 'Nepal', dialingCode: '+977' },
  { code: 'NL', name: 'Netherlands', dialingCode: '+31' },
  { code: 'AN', name: 'Netherlands Ant', dialingCode: '+599' },
  { code: 'NC', name: 'New Caledonia', dialingCode: '+687' },
  { code: 'NZ', name: 'New Zealand', dialingCode: '+64' },
  { code: 'NI', name: 'Nicaragua', dialingCode: '+505' },
  { code: 'NG', name: 'Nigeria', dialingCode: '+234' },
  { code: 'NO', name: 'Norway', dialingCode: '+47' },
  { code: 'OM', name: 'Oman', dialingCode: '+968' },
  { code: 'PK', name: 'Pakistan', dialingCode: '+92' },
  { code: 'PA', name: 'Panama', dialingCode: '+507' },
  { code: 'PG', name: 'Papua New Guinea', dialingCode: '+675' },
  { code: 'PY', name: 'Paraguay', dialingCode: '+595' },
  { code: 'PE', name: 'Peru', dialingCode: '+51' },
  { code: 'PH', name: 'Philippines', dialingCode: '+63' },
  { code: 'PL', name: 'Poland', dialingCode: '+48' },
  { code: 'PT', name: 'Portugal', dialingCode: '+351' },
  { code: 'PR', name: 'Puerto Rico', dialingCode: '+1-787' },
  { code: 'QA', name: 'Qatar', dialingCode: '+974' },
  { code: 'RE', name: 'Reunion', dialingCode: '+262' },
  { code: 'RO', name: 'Romania', dialingCode: '+40' },
  { code: 'RU', name: 'Russian Fed', dialingCode: '+7' },
  { code: 'KN', name: 'Saint Kitts and Nevis', dialingCode: '+1-869' },
  { code: 'MF', name: 'Saint Martin', dialingCode: '+590' },
  { code: 'PM', name: 'Saint Pierre and Miquelon', dialingCode: '+508' },
  { code: 'SA', name: 'Saudi Arabia', dialingCode: '+966' },
  { code: 'SN', name: 'Senegal', dialingCode: '+221' },
  { code: 'RS', name: 'Serbia', dialingCode: '+381' },
  { code: 'SG', name: 'Singapore', dialingCode: '+65' },
  { code: 'SK', name: 'Slovakia', dialingCode: '+421' },
  { code: 'SI', name: 'Slovenia', dialingCode: '+386' },
  { code: 'ZA', name: 'South Africa', dialingCode: '+27' },
  { code: 'ES', name: 'Spain', dialingCode: '+34' },
  { code: 'LK', name: 'Sri Lanka', dialingCode: '+94' },
  { code: 'SE', name: 'Sweden', dialingCode: '+46' },
  { code: 'CH', name: 'Switzerland', dialingCode: '+41' },
  { code: 'TW', name: 'Taiwan', dialingCode: '+886' },
  { code: 'TZ', name: 'Tanzania, United Republic of', dialingCode: '+255' },
  { code: 'TH', name: 'Thailand', dialingCode: '+66' },
  { code: 'TG', name: 'Togo', dialingCode: '+228' },
  { code: 'TT', name: 'Trinidad & Tobago', dialingCode: '+1-868' },
  { code: 'TN', name: 'Tunisia', dialingCode: '+216' },
  { code: 'TR', name: 'Turkey', dialingCode: '+90' },
  { code: 'AE', name: 'U.A.E.', dialingCode: '+971' },
  { code: 'UG', name: 'Uganda', dialingCode: '+256' },
  { code: 'UA', name: 'Ukraine', dialingCode: '+380' },
  { code: 'GB', name: 'United Kingdom', dialingCode: '+44' },
  { code: 'US', name: 'United States', dialingCode: '+1' },
  { code: 'UY', name: 'Uruguay', dialingCode: '+598' },
  { code: 'VN', name: 'Vietnam', dialingCode: '+84' },
  { code: 'ZM', name: 'Zambia', dialingCode: '+260' },
]

const distributorCountries: string[] = [
  'AR',
  'BD',
  'BY',
  'BZ',
  'BO',
  'BR',
  'CL',
  'CN',
  'CO',
  'CR',
  'HR',
  'CZ',
  'DO',
  'EC',
  'EG',
  'GR',
  'GD',
  'GP',
  'GT',
  'GY',
  'HN',
  'HU',
  'IN',
  'IN',
  'IN',
  'ID',
  'IL',
  'JM',
  'JO',
  'KZ',
  'KR',
  'KW',
  'LV',
  'LB',
  'MY',
  'MY',
  'MX',
  'MA',
  'NI',
  'NG',
  'OM',
  'PK',
  'PA',
  'PY',
  'PE',
  'PH',
  'QA',
  'RO',
  'RU',
  'RU',
  'KN',
  'SA',
  'RS',
  'SI',
  'ZA',
  'ZA',
  'LK',
  'TW',
  'TW',
  'TW',
  'TH',
  'TH',
  'TT',
  'TN',
  'TR',
  'AE',
  'AE',
  'UA',
  'UY',
  'VN',
]

export { countries, CtaText, distributorCountries, editPriceReasons }
export type { Country }
