import {
  aboutTabTitle,
  productTabTitle,
  resourcesTabTitle,
  supportTabTitle,
  productTabSlug,
  resourcesTabSlug,
  supportTabSlug,
  aboutTabSlug,
} from './constants'
import type { HeaderNavigationResponse } from './header-navigation.type'
import type { NavItem } from './mega-nav.types'

export const transformData = (
  res: HeaderNavigationResponse
): NavItem[] | null => {
  const [navigationData] = res?.meganavMainList?.items ?? []

  if (!navigationData) {
    return null
  }

  const {
    productsColumn1,
    productsColumn2,
    productsColumn3,
    productFeaturedColumn,
    aboutUsColumn1,
    aboutUsColumn2,
    aboutUsColumn3,
    aboutUsColumn4,
    aboutUsColumn5,
    resourcesColumn1,
    resourcesColumn2,
    resourcesColumn3,
    resourcesFeaturedColumn,
    supportColumn1,
    supportColumn2,
    supportColumn3,
    supportFeaturedColumn,
    language,
  } = navigationData

  const productNavItem = {
    title: productTabTitle[language as keyof typeof productTabTitle],
    originalTitle: productTabTitle.en,
    slug: productTabSlug,
    language,
    sections: [
      {
        ...(productsColumn1 && {
          blocks: productsColumn1.map(
            ({ title, titleUrl, productCategoryLevel1 }) => ({
              title,
              titleUrl,
              links: productCategoryLevel1.map(({ title, slug }) => ({
                title,
                url: `/products/${slug}`,
              })),
            })
          ),
        }),
      },
      {
        ...(productsColumn2 && {
          blocks: productsColumn2.map(
            ({ title, titleUrl, productCategoryLevel1 }) => ({
              title,
              titleUrl,
              links: productCategoryLevel1.map(({ title, slug }) => ({
                title,
                url: `/products/${slug}`,
              })),
            })
          ),
        }),
      },
      {
        ...(productsColumn3 && {
          blocks: productsColumn3.map(
            ({ title, titleUrl, productCategoryLevel1 }) => ({
              title,
              titleUrl,
              links: productCategoryLevel1.map(({ title, slug }) => ({
                title,
                url: `/products/${slug}`,
              })),
            })
          ),
        }),
      },
      {
        ...(productFeaturedColumn && {
          blocks: productFeaturedColumn.map(
            ({ title, titleUrl, blockCopy, urlLink, image, button }) => ({
              type: 'ProductFeaturedColumn',
              title,
              titleUrl,
              blockCopy: blockCopy?.html || '',
              links: urlLink?.map(({ title, url }) => ({
                title,
                url,
              })),
              image: {
                _path: image?.image?._path ?? '',
                imageCaption: image?.imageCaption?.plaintext ?? '',
              },
              button,
            })
          ),
        }),
      },
    ],
  }

  const aboutNavItem = {
    title: aboutTabTitle[language as keyof typeof aboutTabTitle],
    originalTitle: aboutTabTitle.en,
    slug: aboutTabSlug,
    language,
    sections: [
      {
        ...(aboutUsColumn1 && {
          blocks: aboutUsColumn1.map(
            ({ title, titleUrl, blockCopy, urlLink, image, button }) => ({
              type: 'GenericColumn',
              title,
              titleUrl,
              blockCopy: blockCopy?.html || '',
              links: urlLink?.map(({ title, url }) => ({
                title,
                url,
              })),
              image: {
                _path: image?.image?._path ?? '',
                imageCaption: image?.imageCaption?.plaintext ?? '',
              },
              button,
            })
          ),
        }),
      },
      {
        ...(aboutUsColumn2 && {
          blocks: aboutUsColumn2.map(
            ({ title, titleUrl, blockCopy, urlLink, image, button }) => ({
              type: 'GenericColumn',
              title,
              titleUrl,
              blockCopy: blockCopy?.html || '',
              links: urlLink?.map(({ title, url }) => ({
                title,
                url,
              })),
              image: {
                _path: image?.image?._path ?? '',
                imageCaption: image?.imageCaption?.plaintext ?? '',
              },
              button,
            })
          ),
        }),
      },
      {
        ...(aboutUsColumn3 && {
          blocks: aboutUsColumn3.map(
            ({ title, titleUrl, blockCopy, urlLink, image, button }) => ({
              type: 'GenericColumn',
              title,
              titleUrl,
              blockCopy: blockCopy?.html || '',
              links: urlLink?.map(({ title, url }) => ({
                title,
                url,
              })),
              image: {
                _path: image?.image?._path ?? '',
                imageCaption: image?.imageCaption?.plaintext ?? '',
              },
              button,
            })
          ),
        }),
      },
      {
        ...(aboutUsColumn4 && {
          blocks: aboutUsColumn4.map(
            ({ title, titleUrl, blockCopy, urlLink, image, button }) => ({
              type: 'GenericColumn',
              title,
              titleUrl,
              blockCopy: blockCopy?.html || '',
              links: urlLink?.map(({ title, url }) => ({
                title,
                url,
              })),
              image: {
                _path: image?.image?._path ?? '',
                imageCaption: image?.imageCaption?.plaintext ?? '',
              },
              button,
            })
          ),
        }),
      },
      {
        ...(aboutUsColumn5 && {
          blocks: aboutUsColumn5.map(
            ({ title, titleUrl, blockCopy, urlLink, image, button }) => ({
              type: 'GenericColumn',
              title,
              titleUrl,
              blockCopy: blockCopy?.html || '',
              links: urlLink?.map(({ title, url }) => ({
                title,
                url,
              })),
              image: {
                _path: image?.image?._path ?? '',
                imageCaption: image?.imageCaption?.plaintext ?? '',
              },
              button,
            })
          ),
        }),
      },
    ],
  }

  const resourcesNavItem = {
    title: resourcesTabTitle[language as keyof typeof resourcesTabTitle],
    originalTitle: resourcesTabTitle.en,
    slug: resourcesTabSlug,
    language,
    sections: [
      {
        ...(resourcesColumn1 && {
          blocks: resourcesColumn1.map(({ title, titleUrl, urlLink }) => {
            return {
              type: 'GenericColumn',
              title,
              titleUrl,
              links: urlLink.map(({ title, url }) => ({
                title,
                url,
              })),
            }
          }),
        }),
      },
      {
        ...(resourcesColumn2 && {
          blocks: resourcesColumn2.map(
            ({ title, titleUrl, urlLink, button }) => {
              return {
                type: 'GenericColumn',
                title,
                titleUrl,
                links: urlLink.map(({ title, url }) => ({
                  title,
                  url,
                })),
                button,
              }
            }
          ),
        }),
      },
      {
        ...(resourcesColumn3 && {
          blocks: resourcesColumn3.map(
            ({ title, titleUrl, urlLink, button }) => {
              return {
                type: 'GenericColumn',
                title,
                titleUrl,
                links: urlLink.map(({ title, url }) => ({
                  title,
                  url,
                })),
                button,
              }
            }
          ),
        }),
      },
      {
        ...(resourcesFeaturedColumn && {
          blocks: resourcesFeaturedColumn.map(
            ({ title, titleUrl, urlLink, button, type, image, blockCopy }) => {
              return {
                type: 'FeaturedColumn',
                title,
                titleUrl,
                image: {
                  _path: image?.image?._path ?? '',
                  imageCaption: image?.imageCaption?.html ?? '',
                },
                blockCopy: blockCopy?.html || '',
                links: urlLink.map(({ title, url }) => ({
                  type,
                  title,
                  url,
                })),
                button,
              }
            }
          ),
        }),
      },
    ],
  }

  const supportNavItem = {
    title: supportTabTitle[language as keyof typeof supportTabTitle],
    originalTitle: supportTabTitle.en,
    slug: supportTabSlug,
    language,
    sections: [
      {
        ...(supportColumn1 && {
          blocks: supportColumn1.map(({ title, titleUrl, urlLink }) => {
            return {
              type: 'GenericColumn',
              title,
              titleUrl,
              links: urlLink.map(({ title, url }) => ({
                title,
                url,
              })),
            }
          }),
        }),
      },
      {
        ...(supportColumn2 && {
          blocks: supportColumn2.map(({ title, titleUrl, urlLink, button }) => {
            return {
              type: 'GenericColumn',
              title,
              titleUrl,
              links: urlLink.map(({ title, url }) => ({
                title,
                url,
              })),
              button,
            }
          }),
        }),
      },
      {
        ...(supportColumn3 && {
          blocks: supportColumn3.map(({ title, titleUrl, urlLink, button }) => {
            return {
              type: 'GenericColumn',
              title,
              titleUrl,
              links: urlLink.map(({ title, url }) => ({
                title,
                url,
              })),
              button,
            }
          }),
        }),
      },
      {
        ...(supportFeaturedColumn && {
          blocks: supportFeaturedColumn.map(
            ({ title, titleUrl, urlLink, button, type, image, blockCopy }) => {
              return {
                type: 'FeaturedColumn',
                title,
                titleUrl,
                image: {
                  _path: image?.image?._path ?? '',
                  imageCaption: image?.imageCaption?.html ?? '',
                },
                blockCopy: blockCopy?.html || '',
                links: urlLink.map(({ title, url, linkCopyText }) => ({
                  type,
                  title,
                  url,
                  linkCopyText,
                })),
                button,
              }
            }
          ),
        }),
      },
    ],
  }

  return [productNavItem, resourcesNavItem, supportNavItem, aboutNavItem]
}
