import { usePunchout } from '@abcam-web/auth-shared/aws-cognito/hooks/usePunchout'
import {
  BasketSummaryModel,
  Price,
} from '@abcam-web/shared/data-access/ecommerce-schema'
import { useCallback, useMemo } from 'react'

export interface BasketSummaryPriceProps {
  summary?: BasketSummaryModel
}

export type BasketSummaryPriceType = keyof Pick<
  BasketSummaryModel,
  'subtotal' | 'total'
>
/**
 * This hook is responsible for getting the correct basket summary subtotal and total prices
 * depending on the condition that for punchout users that have tax inclusive prices
 * we need to provide base price values
 */
export function useBasketSummaryPrice({ summary }: BasketSummaryPriceProps) {
  const punchoutSession = usePunchout()

  const isPriceTaxInclusive = useMemo(
    () =>
      !!summary?.taxes?.find(
        (t) => t.type === 'GOODS' && t.includedInPrice === true
      ),
    [summary]
  )

  const doUseBasePrice = useMemo(
    () => punchoutSession.active && isPriceTaxInclusive,
    [punchoutSession, isPriceTaxInclusive]
  )

  const getSummaryTaxValue = useCallback(() => {
    return (summary?.taxes ?? [])
      .filter((tax) => tax.type === 'GOODS')
      .map((tax) => tax?.price?.value ?? 0)
      .reduce((acc, taxValue) => acc + taxValue, 0)
  }, [summary])

  const getPrice = useCallback(
    (priceType: BasketSummaryPriceType): Price | undefined => {
      if (!summary) {
        return
      }
      if (!doUseBasePrice) {
        return summary[priceType] as Price
      }

      const { value, currency } = summary[priceType] as Price
      const newSubtotalValue = value - getSummaryTaxValue()

      return {
        currency,
        value: newSubtotalValue,
      }
    },
    [summary, doUseBasePrice, getSummaryTaxValue]
  )

  return {
    doUseBasePrice,
    getPrice,
  }
}
