import getConfig from 'next/config'
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import { COGNITO_ATTRIBUTES } from '@abcam-web/auth-shared/aws-cognito/util/cognitoAttributes'
import { apolloClient } from '@abcam-web/shared/data-access/apollo'
import type { User } from '@abcam-web/auth-shared/contexts/user'
import {
  PUNCHOUT_DATA_QUERY,
  PUNCHOUT_BASKET_MUTATION,
} from '@abcam-web/auth-shared/gql/punchout-data-gql'
import { showPunchoutError } from '@abcam-web/shared/ui/error-handling'
import { cachedValue } from './cachedValue'
import { Auth } from 'aws-amplify'

export type PunchoutUserData = {
  readonly isPunchoutUser: boolean
  readonly welcomeText: string | null
  readonly logoUrl: string | null
  readonly arePromotionsEnabled: boolean
}

function resolveApolloClient() {
  const { STAGE, PROXY_GATEWAY_URL } = getConfig().publicRuntimeConfig

  if (STAGE === 'local') {
    return new ApolloClient({
      cache: new InMemoryCache(),
      link: new HttpLink({
        uri: 'http://localhost:3333/graphql',
        fetch,
      }),
    })
  }

  return apolloClient(PROXY_GATEWAY_URL, ['punchoutgateway'])
}

export function isPunchoutUser(user: User | null | undefined): user is User {
  return !!user?.[COGNITO_ATTRIBUTES.PUNCHOUT_ID]
}

export function getPunchoutId(user: User | null | undefined) {
  return user?.[COGNITO_ATTRIBUTES.PUNCHOUT_ID]
}

export function getPunchoutSessionId(user: User | null | undefined) {
  return user?.[COGNITO_ATTRIBUTES.PUNCHOUT_SESSION_ID]
}

export async function queryUserData(
  punchoutId: string,
  punchoutSessionId: string
): Promise<PunchoutUserData> {
  console.assert(punchoutId, 'punchout user has no id')
  console.assert(punchoutSessionId, 'punchout user has no session')

  return cachedValue(punchoutSessionId, async () => {
    const punchoutGatewayClient = resolveApolloClient()

    const { data } = await punchoutGatewayClient.query({
      query: PUNCHOUT_DATA_QUERY,
      variables: {
        sessionId: punchoutSessionId,
        punchoutId,
      },
    })

    return {
      isPunchoutUser: true,
      welcomeText: data?.punchoutClient?.welcomeText as string,
      logoUrl: data?.punchoutClient.welcomeIconUrl || null,
      arePromotionsEnabled: data?.punchoutClient.arePromotionsEnabled || false,
    }
  })
}

export type CheckoutBasketOptions = {
  onBeforeRedirect?: (redirectUrl: string) => void
}

export async function checkoutBasket(
  punchoutId: string,
  punchoutSessionId: string,
  basketId: string,
  options?: CheckoutBasketOptions
) {
  const punchoutGatewayClient = resolveApolloClient()

  try {
    const { data } = await punchoutGatewayClient.mutate({
      mutation: PUNCHOUT_BASKET_MUTATION,
      variables: {
        params: {
          punchoutId,
          punchoutSessionId,
          basketId,
        },
      },
    })

    // TODO: provide message to client that she is about to be redirected
    // back to punchout portal

    if (data.punchoutOrder?.redirectUrl) {
      // When we're done with the checkout we remove all auth cookies, if the user
      // comes back from the public website we do not want them to see a punchout session.
      Auth.signOut().finally(() => {
        if (options?.onBeforeRedirect) {
          options.onBeforeRedirect(data.punchoutOrder.redirectUrl)
        }
        window.location.href = data.punchoutOrder.redirectUrl
      })
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(`Cannot complete punchout checkout: ${error?.message}`)
    showPunchoutError('ERR-005')
  }
}
