import countryNames from './countries.json'
const defaultCountry = 'US'
const defaultLocale = 'en-us'
const defaultCountryName = countryNames[defaultCountry].name

const countries: string[] = Object.keys(countryNames)

export { getLocales } from './utils/getLocales'
export { isValidCountry } from './utils/isValidCountry'
export { isValidLocale } from './utils/isValidLocale'
export { defaultCountry, defaultLocale, defaultCountryName, countryNames, countries }

export type CountryNameWithAlternative = {
  [countryCode: string]: {
    name: string
    alternative: string
  }
}
