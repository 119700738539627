import type {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  HTMLProps,
  KeyboardEvent,
  ReactNode,
  SyntheticEvent,
} from 'react'

export enum BUTTON_SIZE {
  xsmall = 'xsmall',
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export enum BUTTON_VARIANT {
  primary = 'primary',
  secondary = 'secondary',
  tertiaryOutline = 'tertiaryOutline',
  tertiaryFilled = 'tertiaryFilled',
  textOnly = 'textOnly',
}

type ButtonProps = {
  alignCenter?: boolean
  as?: 'a' | 'button' | 'div'
  bodyClassName?: string
  children?: ReactNode
  className?: string
  dark?: boolean
  dataCy?: string
  disabled?: boolean
  download?: boolean | string
  form?: string
  fullWidth?: boolean
  href?: string
  iconBurger?: boolean
  iconButton?: boolean
  iconLeft?: ReactNode
  iconRight?: ReactNode
  name?: string
  onBlur?: (evt: SyntheticEvent) => void
  onClick?: (evt: SyntheticEvent) => void
  onFocus?: (event: SyntheticEvent) => void
  onKeyDown?: (evt: KeyboardEvent) => void
  onMouseDown?: (evt: SyntheticEvent) => void
  openInNewWindow?: boolean
  overrideRouter?: boolean
  rel?: string
  /**
   * Makes button sizes adapt to wider screens: size=small will inherit the size=medium styles for 1920px+
   * viewport widths, in order to increase legibility.
   **/
  responsiveSizing?: boolean
  role?: HTMLProps<HTMLElement>['role']
  rounded?: boolean
  size?: BUTTON_SIZE | keyof typeof BUTTON_SIZE
  style?: HTMLProps<HTMLButtonElement>['style']
  tabIndex?: HTMLProps<HTMLElement>['tabIndex']
  title?: string
  type?: DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >['type']
  variant?: BUTTON_VARIANT | keyof typeof BUTTON_VARIANT
}

export type { ButtonProps }
