import type { AxiosDataResponse } from '@abcam-web/browse/aem'
import { AEMClientSideNextApiEndpoint } from '@abcam-web/browse/aem'
import { getOriginUrl } from '@abcam-web/shared/utilities/request'
import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { transformData } from '../mega-nav/header-navigation.transform'
import type { HeaderNavigationResponse } from '../mega-nav/header-navigation.type'
import type { NavItem } from '../mega-nav/mega-nav.types'
import { Header } from './header'
import type { HeaderWrapperProps } from './header.type'

export function HeaderWrapper({
  navigation,
  testMode = false,
  apiEndpointOverride,
}: HeaderWrapperProps) {
  const { locale, formatMessage } = useIntl()

  const navInitialData: NavItem[] = useMemo(() => [
    {
      title: formatMessage({ id: `common.header.products` }),
      originalTitle: 'Products',
      slug: '/products',
      language: locale,
      sections: [],
    },
    {
      title: formatMessage({ id: `common.header.resources` }),
      originalTitle: 'Resources',
      slug: '/technical-resources',
      language: locale,
      sections: [],
    },
    {
      title: formatMessage({ id: `common.header.support` }),
      originalTitle: 'Support',
      slug: '/support',
      language: locale,
      sections: [],
    },
    {
      title: formatMessage({ id: `common.header.aboutUs` }),
      originalTitle: 'About Us',
      slug: '/about',
      language: locale,
      sections: [],
    },
  ], [formatMessage, locale])


  // We need to pass some initial nav data to the header component to avoid a flash of no data for
  // apps that do not use serverside rendering
  const [navData, setNavData] = useState<NavItem[] | null | undefined>(
    navigation || navInitialData
  )

  useEffect(() => {
    if (
      !navigation ||
      navigation.length === 0 ||
      navigation === navInitialData
    ) {
      (async () => {
        const graphqlUrl =
          getOriginUrl(undefined, { overrideOrigin: apiEndpointOverride }) +
          AEMClientSideNextApiEndpoint
        try {
          const fetchResponse = await fetch(graphqlUrl, {
            body: JSON.stringify({
              params: 'GetHeaderNavigation',
            }),
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'POST',
          })

          const fetchJson: AxiosDataResponse<HeaderNavigationResponse> =
            await fetchResponse.json()

          const transformedData = transformData(fetchJson?.data)
          setNavData(transformedData)
        } catch (err) {
          console.error(err)
        }
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigation])

  if (!testMode) {
    return <Header navigation={navigation ?? navData} />
  }

  return null
}
