import { BASE_URL_BY_DOMAIN } from '@abcam-web/auth-shared/constants/base-url'
import { STAGE } from '@abcam-web/auth-shared/constants/stage-type'

const authBase = {
  ...BASE_URL_BY_DOMAIN,
  local: 'http://localhost:3000/',
}

export const isChinaDomain = () => {
  return (
    typeof window !== 'undefined' && window.location.origin.includes('abcam.cn')
  )
}

export const getAuthenticationHost = (stage: STAGE): string => {
  let baseUrl = authBase[stage]
  if (
    typeof window !== 'undefined' &&
    window.location.origin.includes('abcam.cn')
  ) {
    baseUrl = baseUrl.replace('abcam.com', 'abcam.cn')
  }
  return `${baseUrl}auth`
}
