import { defaultLocale, getLocales } from '@abcam-web/shared/config'
import { useCountry } from '@abcam-web/shared/utilities/country'
import { CountryStorage } from '@abcam-web/shared/utilities/storage'
import { usePathname, useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'

export const useSwitchLocale = () => {
  const router = useRouter()
  const { setCountry } = useCountry()
  let pathname = usePathname()

  const searchParams = useSearchParams()
  const switchLocale = (language: string | undefined, country: string) => {
    const host = window.location.host
    const hostname = window.location.hostname
    const hostIsDev = hostname.includes('localhost')
    const hostIsGlobal = hostname.endsWith('.com')
    const hostIsChina = hostname.endsWith('.cn')

    let newHost = ''
    let locale =
      getLocales().find((l) => l.startsWith(`${language}-`)) ?? defaultLocale

    // Set cookies and provider state first
    CountryStorage.setSelectedLocale(locale)
    CountryStorage.setSelectedCountry(country)
    setCountry(country)

    // Global domain for all countries except China with its own domain
    if (!hostIsDev && hostIsGlobal && country === 'CN') {
      newHost = host.replace('.com', '.cn')
    }
    if (!hostIsDev && hostIsChina && country !== 'CN') {
      newHost = host.replace('.cn', '.com')
    }

    // Preserve the 'country' URL query parameter on certain routes or when changing domain
    const nextSearchParams = new URLSearchParams(searchParams.toString())
    if (
      ['/shopping-basket/[shopping-basket]'].includes(router.route) ||
      newHost
    ) {
      nextSearchParams.set('country', country)
    } else {
      nextSearchParams.delete('country')
    }

    // Only change URL if the path, locale or hostname has changed
    if (pathname !== router.asPath || locale !== router.locale || newHost) {
      // If changing domain, locale must be included in the pathname instead
      if (newHost) {
        pathname = `/${locale}${pathname === '/' ? '' : pathname}`
        locale = ''
      }

      router.push(
        {
          pathname,
          host: newHost || host,
          search: nextSearchParams.toString(),
          protocol: document.location.protocol,
        },
        undefined,
        { locale }
      )
    }
  }

  return { switchLocale }
}
