/**
 * When multiple drawers are present on the page - each should have a unique id
 */
export enum DrawerId {
  Reviews,
  Publications,
  CrossSell,
  RecentlyViewed,
  Tags,
  Variations,
  Alternatives,
  Product,
  SearchFilters,
  ReviewsFilters,
}
