import type { Dispatch, SetStateAction } from 'react'
import { useId, useState } from 'react'
import { RadioInput } from '../radioInput/radioInput'
import sharedStyles from '../styles.module.css'

export type RadioOption = {
  key: string | number
  displayValue: string
  disabled?: boolean
}

type Props = {
  label?: string
  hint?: string
  name: string
  options: RadioOption[]
  onItemClick?: Dispatch<SetStateAction<string>>
  required?: boolean
  errorText?: string
  wrapperClassName?: string
  defaultCheckedValue?: string | number
  testTag?: string
  hideRequiredFieldAsterisk?: boolean
}

export const RadioGroup = ({
  label,
  hint,
  name,
  options,
  onItemClick,
  errorText,
  required,
  wrapperClassName = '',
  defaultCheckedValue,
  testTag,
  hideRequiredFieldAsterisk,
}: Props) => {
  const [isValid, setIsValid] = useState(true)
  const id = useId()

  const onInvalid = () => {
    setIsValid(false)
  }

  return (
    <div className={`flex flex-col mb-4 gap-4 ${wrapperClassName}`}>
      {label && (
        <label
          className={`font-semibold ${
            required && !hideRequiredFieldAsterisk
              ? sharedStyles['required']
              : ''
          }`}
          id={id}
        >
          {label}
        </label>
      )}
      {hint && <p className="relative text-grey-20 -top-2">{hint}</p>}
      <div aria-labelledby={id} className="flex gap-8">
        {options.map(({ key, displayValue, disabled }) => (
          <RadioInput
            key={key}
            name={name}
            value={key}
            label={displayValue}
            onClick={() => {
              onItemClick?.(displayValue)
              setIsValid(true)
            }}
            onInvalid={onInvalid}
            required={required}
            defaultChecked={key === defaultCheckedValue}
            data-testid={testTag ? `${testTag}-${displayValue}` : undefined}
            disabled={disabled}
          />
        ))}
      </div>
      {!isValid && (
        <div
          data-testid="simple-text-validation-message"
          className="text-negative text-body-small"
        >
          {errorText}
        </div>
      )}
    </div>
  )
}
