import { useSelector } from '@xstate/react'
import { useContext } from 'react'
import { SearchContext } from './search-provider'

export function useSearch() {
  const value = useContext(SearchContext)
  if (!value) throw 'search context must be provided'

  const { searchService, searchInput, searchCount, incrementSearchCount } =
    value
  const { send } = searchService

  return {
    send,
    searchInput,
    isActive: useSelector(searchService, (state) =>
      state.matches('overlay.active')
    ),
    suggestions: useSelector(
      searchService,
      (state) => state.context.suggestions
    ),
    inputValue: useSelector(searchService, (state) => state.context.inputValue),
    isLoading: useSelector(searchService, (state) => {
      return (
        state.matches('searchQuery.loadingFacets') ||
        state.matches('searchQuery.loadingSearchResults')
      )
    }),
    showSuggestions: useSelector(
      searchService,
      (state) =>
        state.matches('suggestions.found') &&
        state.context.suggestions.length > 0
    ),
    suggestionPreselect: useSelector(
      searchService,
      (state) => state.context.suggestionPreselect
    ),
    isClear: useSelector(
      searchService,
      (state) => state.context.inputValue.length === 0
    ),
    previousSearches: useSelector(
      searchService,
      (state) => state.context.previousSearches
    ),
    selectedFacets: useSelector(
      searchService,
      (state) => state.context.selectedFacets
    ),
    selectedFacetIndex: useSelector(
      searchService,
      (state) => state.context.selectedFacetIndex
    ),
    facetRecommendations: useSelector(
      searchService,
      (state) =>
        state.context.allFacetRecommendations.searchFacetRecommendations
    ),
    contentFacetRecommendations: useSelector(
      searchService,
      (state) =>
        state.context.allFacetRecommendations.contentFacetRecommendations
    ),
    productSorting: useSelector(
      searchService,
      (state) => state.context.productSorting
    ),
    resourceSorting: useSelector(
      searchService,
      (state) => state.context.resourceSorting
    ),
    sorting: useSelector(searchService, (state) => state.context.sorting),
    searchResults: useSelector(
      searchService,
      (state) => state.context.searchResults
    ),
    inputFocused: useSelector(searchService, (state) =>
      state.matches('searchInput.focused')
    ),
    searchCount,
    incrementSearchCount,
  }
}

export type useSearchReturnType = ReturnType<typeof useSearch>
