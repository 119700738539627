import { User, useUser } from '@abcam-web/auth-shared/contexts/user'
import { isPunchoutUser } from './utils/punchout'

// We have multiple ways to obtain user data,
// and in some cases useUser hook gets information too late and we can't track that moment.
// That's why this hook can receive userData from outside and delegate the control to the parent
export function useIsPunchoutUser(userData?: User) {
  const user = useUser()
  return isPunchoutUser(userData || user)
}
