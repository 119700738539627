import type { SeoProps } from './seo-props.type'
import type {
  CategoriesSearchResults,
  FacetInput,
  ProductSearchResult,
  ContentSearchResults,
  ProductsSearchResults,
  ContentFacetRecommendations,
} from '@abcam-web/shared/data-access/search-schema'
import type { HandlingHazard } from '@abcam-web/search/public/utilities/product.type'
export type Sorting = 'relevance' | 'publications' | 'newest'
export type TagCodes = 'RABMAB' | 'RECOMBINANT'
export type SelectedProductViewState = {
  productcode: string
  view: CurrentView
  reviewId?: string
}

type ProductTagType = 'highlights' | 'hazards'

export type SearchPageProps = {
  searchResults: ProductsSearchResults | null
  fallbackSearchResults?: CategoriesSearchResults | null
  productSorting: string
  productSortOrder?: string
  resourceSorting: string
  resourceSortOrder?: string
  contentSearchResults?: ContentSearchResults | null
  fallbackContentSearchResults?: ContentFacetRecommendations | null
  keywords: string
  filters: FacetInput[]
  contentFilters?: FacetInput[]
  selectedProduct: SelectedProductViewState | null
  selectedProductDetails: ProductSearchResult | null
} & SeoProps

export type CategoryPageProps = {
  category: CategoriesSearchResults
  sorting: string
  keywords: string
  filters: FacetInput[]
}

export type FallbackPageProps = CategoryPageProps

export type SearchInput = {
  facets: FacetInput[]
  keywords: string
}

export type CurrentView =
  | 'quickview'
  | 'publications'
  | 'reviews'
  | 'highlights'
  | 'hazards'

export type Operators = 'AND' | 'OR'

export enum DropDownType {
  Single = 'SINGLE',
  Multi = 'MULTI',
}

export type OpenProductTagDrawerArgs = {
  idx: string
  highlightTagCodes: string[]
  trialSizeExclusiveCountries: string[]
  hazardTagCodes: HandlingHazard[]
  tagType: ProductTagType
  productCode: string
  itemClass: string
}
