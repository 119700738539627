export const search = {
  form: {
    container: 'header-search-form',
    label: {
      container: 'header-search-form-label',
      icon: 'header-search-form-label-icon',
    },
    input: 'header-search-form-search-term',
    button: 'header-search-form-submit-button',
    combinationSearch: {
      container: 'header-search-form-combo-search',
      label: 'header-search-form-combo-label',
      terms: 'header-search-form-combo-terms',
      clearSearch: 'header-search-form-combo-clear-search-btn',
      pills: {
        container: 'header-search-form-combo-pills-container',
        plus: 'header-search-form-combo-pills-plus',
        ACCM: {
          label: 'header-search-form-combo-pill-accm-label',
          input: 'header-search-form-combo-pill-accm-number',
        },
        OPP: {
          label: 'header-search-form-combo-pill-opp-label',
          input: 'header-search-form-combo-pill-opp-number',
        },
        Order: {
          label: 'header-search-form-combo-pill-order-label',
          input: 'header-search-form-combo-pill-order-number',
        },
        PO: {
          label: 'header-search-form-combo-pill-po-label',
          input: 'header-search-form-combo-pill-purchase-order',
        },
        Contact: {
          label: 'header-search-form-combo-pill-contact-label',
          input: 'header-search-form-combo-pill-contact',
        },
        'Courier Tracking Number': {
          label: 'header-search-form-combo-pill-courier-tracking-number-label',
          input: 'header-search-form-combo-pill-courier-tracking-number',
        },
        'Incident Number': {
          label: 'header-search-form-combo-pill-incident-number-label',
          input: 'header-search-form-combo-pill-incident-number',
        },
        Organisation: {
          label: 'header-search-form-combo-pill-organisation-label',
          input: 'header-search-form-combo-pill-organisation',
        },
        'Product Code': {
          label: 'header-search-form-combo-pill-product-code-label',
          input: 'header-search-form-combo-pill-product-code',
        },
        WMS: {
          label: 'header-search-form-combo-pill-wms-label',
          input: 'header-search-form-combo-pill-wms',
        },
      },
    },
  },
}
