import { getCookie } from './cookie/getCookie'
import { IncomingMessage } from 'http'
import { defaultLocale, isValidLocale } from '@abcam-web/shared/config'
import { isSupportedCountryCode } from '../countries'

interface ParsedCookies {
  [key: string]: string
}

const parseCookies = (cookieHeader: string | undefined): ParsedCookies => {
  const cookies: ParsedCookies = {}
  cookieHeader?.split(';').forEach((cookie) => {
    const [name, value] = cookie.split('=').map((part) => part.trim())
    if (name && value) {
      cookies[name] = value
    }
  })
  return cookies
}

export const getNextLocaleFromCookie = (req?: IncomingMessage): string => {
  const cookieHeader = req?.headers.cookie
  const serverCookies = cookieHeader ? parseCookies(cookieHeader) : undefined
  const clientLocale = getCookie('NEXT_LOCALE')

  const localeFromCookies = serverCookies
    ? serverCookies['NEXT_LOCALE']
    : clientLocale

  if (localeFromCookies && isValidLocale(localeFromCookies)) {
    return localeFromCookies
  }

  return defaultLocale
}

export const getCountryFromCookie = (
  req?: IncomingMessage
): string | undefined => {
  const cookieHeader = req?.headers.cookie
  const serverCookies = cookieHeader ? parseCookies(cookieHeader) : undefined
  const clientCountry = getCookie('NEXT_COUNTRY')

  const countryFromCookies = (
    serverCookies ? serverCookies['NEXT_COUNTRY'] : clientCountry
  )?.toUpperCase()

  if (countryFromCookies && isSupportedCountryCode(countryFromCookies)) {
    return countryFromCookies
  }

  return undefined
}

export { getCookie }
