import type {
  GTMPageType,
  GtmService,
} from '@abcam-web/shared/data-access/tracking'
// eslint-disable-next-line import/order
import {
  getFooterClickContext,
  LocationID,
} from '@abcam-web/shared/data-access/tracking'

export const gtmTracking = (
  gtm: GtmService,
  pathname: string,
  pageType: GTMPageType | '',
  pageSubType: string,
  language: string,
  elementRef: HTMLAnchorElement | null,
  outbound: 'TRUE' | 'FALSE'
) => {
  const linkUrl = elementRef ? elementRef.getAttribute('href') : null

  gtm.track(
    getFooterClickContext({
      page_path: pathname,
      click_id: 'Footer link',
      page_type: pageType,
      page_subtype: pageSubType,
      item_cta: elementRef?.innerText,
      destination_path: linkUrl || '',
      distributor_name: null,
      drawer_type: null,
      drawer_subtype: null,
      location_id: LocationID.FOOTER,
      language: language,
      outbound: outbound,
      product_type: null,
    })
  )
}
