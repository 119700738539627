import { BASE_URL_BY_DOMAIN } from '@abcam-web/auth-shared/constants/base-url'
import { STAGE } from '@abcam-web/auth-shared/constants/stage-type'
import { getLanguageNameFromCode } from '@abcam-web/shared/utilities/localisation'
import {
  ClickId,
  LocationID,
  getContentCTAParameters,
  getPageSubType,
  getPageType,
  useGtm,
} from '@abcam-web/shared/data-access/tracking'
import { LinkButtonAssetModel } from '@abcam-web/shared/ui/content'
import { Button, ButtonProps } from '@lego/ui/button'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import { useRef, type ReactNode } from 'react'
import { useIntl } from 'react-intl'
import { YETI_DOMAINS } from './constants'

type LinkButtonProps = {
  linkButton: Omit<LinkButtonAssetModel, 'buttonLabel'> & {
    buttonLabel: string | ReactNode
  }
  linkProps?: any
  buttonProps?: Omit<ButtonProps, 'as'>
  onKeyDown?: (event: KeyboardEvent) => void
}

export function LinkButton({
  linkProps,
  buttonProps,
  linkButton,
  onKeyDown,
}: LinkButtonProps): JSX.Element {
  const ref = useRef<HTMLElement | null>(null)
  const stage = getConfig()?.publicRuntimeConfig?.STAGE || 'prod'
  const { locale } = useIntl()
  const baseUrl = `${BASE_URL_BY_DOMAIN[stage as STAGE]}${locale}`
  const href = linkButton.buttonLink.startsWith('http')
    ? linkButton.buttonLink
    : baseUrl + linkButton.buttonLink
  const { pathname } = useRouter()
  const gtm = useGtm()
  const language = getLanguageNameFromCode(locale.slice(0, 2)) ?? null

  return (
    <a
      href={href}
      {...linkProps}
      onClick={() => {
        if (linkProps?.onClick) {
          linkProps.onClick()
        } else {
          gtm.track(
            getContentCTAParameters({
              click_id: ClickId.CONTENT_CTA,
              location_id: LocationID.PAGE,
              page_type: getPageType(pathname),
              page_subtype: getPageSubType(pathname),
              page_path: pathname,
              item_cta: ref.current?.textContent,
              destination_path: href,
              outbound: YETI_DOMAINS.some((domain) => href.includes(domain))
                ? 'FALSE'
                : 'TRUE',
              language: language,
              distributor_name: null,
              drawer_type: null,
              drawer_subtype: null,
              product_type: null,
            })
          )
        }
      }}
      onKeyDown={onKeyDown}
    >
      <Button {...buttonProps} as="div">
        <span ref={ref}>{linkButton.buttonLabel}</span>
        {linkButton.buttonDescription && (
          <span className="sr-only">{linkButton.buttonDescription}</span>
        )}
      </Button>
    </a>
  )
}
