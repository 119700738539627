import { getCurrentUserCS } from '@abcam-web/auth-shared/aws-cognito'
import axios from 'axios'
import { differenceInMinutes } from 'date-fns'
import getConfig from 'next/config'
import {
  getAuthHeaders,
  getTokensFromLocalStorage,
} from '@abcam-web/shared/utilities/auth'
import { STAGE } from '@abcam-web/auth-shared/constants/stage-type'

const PROXY_GATEWAY_URL = getConfig()?.publicRuntimeConfig?.PROXY_GATEWAY_URL
const REST_API_OVERRIDE_URL =
  getConfig()?.publicRuntimeConfig?.REST_API_OVERRIDE_URL
const baseURL =
  REST_API_OVERRIDE_URL || `${PROXY_GATEWAY_URL}/ecommerce/rest/v1`
const isOverridden = Boolean(REST_API_OVERRIDE_URL)

const headers = {
  'x-abcam-app-id': 'b2c-public-website ',
  'Content-Type': 'application/json',
  ...(isOverridden && {
    'x-abcam-identity':
      '%7B%22appId%22%3A%22efb9d0f5-4f6d-4460-9d8f-2f6e913d7e8e%22%2C%22roles%22%3A%5B%22ecommerce.private.apply%22%2C%22ecommerce.private.get%22%2C%22ecommerce.orders.create%22%2C%22ecommerce.orders.list%22%2C%22ecommerce.orders.manage%22%2C%22ecommerce.quotes.create%22%2C%22customer.contact.get%22%5D%2C%22type%22%3A%22internal_user%22%7D',
  }),
}

const sessionStorageRefreshTokenKey = 'refresh_token_timestamp'
export const axiosECOMInstance = axios.create({
  withCredentials: !isOverridden,
  baseURL,
  headers,
})

export const setCognitoECOMInterceptor = () => {
  axiosECOMInstance.interceptors.request.use(async (config) => {
    try {
      const stage = getConfig().publicRuntimeConfig?.STAGE as STAGE
      const dateNow = new Date()
      let shouldRefreshToken

      const tokensInStorage = getTokensFromLocalStorage(stage)
      if (tokensInStorage?.refreshToken && tokensInStorage.expiresAt) {
        // if new token management system is enabled, then use it to check token expiration
        shouldRefreshToken = tokensInStorage.expiresAt < dateNow.getTime()
      } else {
        // last time token has been refreshed
        const refreshTokenTimestamp = window.sessionStorage.getItem(
          sessionStorageRefreshTokenKey
        )
        const timeDiffInMinutes = differenceInMinutes(
          dateNow,
          new Date(Number.parseInt(refreshTokenTimestamp || ''))
        )
        shouldRefreshToken = !refreshTokenTimestamp || timeDiffInMinutes > 3
      }

      if (shouldRefreshToken) {
        // refresh token and set the new timestamp
        await getCurrentUserCS()
        window.sessionStorage.setItem(
          sessionStorageRefreshTokenKey,
          Date.now().toString()
        )
      }
    } catch {
      // no-op
    }
    // Get tokens from local storage
    const headers = getAuthHeaders()
    Object.keys(headers).forEach((key) => (config.headers[key] = headers[key]))

    return config
  })
}
