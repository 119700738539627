import { ProductService } from './product.service'
import type { DmdDomain } from '@abcam-web/shared/data-access/product-schema'

export const getProduct = async (
  abId: string,
  locale: string,
  domain: DmdDomain
) => {
  const productService = new ProductService()

  return await productService.getPDP(abId.toUpperCase(), locale, domain)
}
