import { AEMRenditions } from './aem-image-types'

export const imageResolver = {
  [AEMRenditions.xxxl]: {
    webp: 'webp-lowerq',
    png: 'png-lowerq',
    jpg: 'jpg-lowerq',
  },
  [AEMRenditions.xxl]: {
    webp: 'webp-1920',
    png: 'png-1920',
    jpg: 'jpg-1920',
  },
  [AEMRenditions.xl]: {
    webp: 'webp-1280',
    png: 'png-1280',
    jpg: 'jpg-1280',
  },
  [AEMRenditions.lg]: {
    webp: 'webp-1024',
    png: 'png-1024',
    jpg: 'jpg-1024',
  },
  [AEMRenditions.md]: {
    webp: 'webp-768',
    png: 'png-768',
    jpg: 'jpg-768',
  },
  [AEMRenditions.sm]: [
    {
      webp: 'webp-640',
      png: 'png-640',
      jpg: 'jpg-640',
      ratio: false,
    },
    {
      webp: 'webp-ratio1',
      png: 'png-ratio1',
      jpg: 'jpg-ratio1',
      ratio: true,
    },
  ],
  [AEMRenditions.ty]: [
    {
      webp: 'webp-475',
      png: 'png-475',
      jpg: 'jpg-475',
      ratio: false,
    },
    {
      webp: 'webp-ratio2',
      png: 'png-ratio2',
      jpg: 'jpg-ratio2',
      ratio: true,
    },
  ],
}
