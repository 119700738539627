import { SearchSelect } from '@abcam-web/shared/ecommerce/components'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import {
  components,
  FormatOptionLabelMeta,
  GroupBase,
  InputActionMeta,
  MenuListProps,
  MultiValue,
  SingleValue,
} from 'react-select'
import { SelectComponents } from 'react-select/dist/declarations/src/components'

import { MenuListHeader, menuListOptions } from './components/menu-list'

type Option<OptionValue> = { label: string; value: OptionValue }

type SearchableDropdownState =
  | 'empty'
  | 'invalidValue'
  | 'loading'
  | 'multipleOptions'
  | 'open'

type Messages = 'noOptions' | 'loadingResults'

export type SearchableDropdownStrings = SearchableDropdownState | Messages

export interface Props<OptionValue> {
  resourceStrings: Record<SearchableDropdownStrings, string>
  label?: string
  instanceId: string
  options?: Option<OptionValue>[]
  selectedOption?: Option<OptionValue>
  customComponents?: Partial<
    SelectComponents<
      Option<OptionValue>,
      boolean,
      GroupBase<Option<OptionValue>>
    >
  >
  menuListHeader?: ReactNode
  formatOptionLabelOverride?: (
    option: Option<OptionValue>,
    meta: FormatOptionLabelMeta<Option<OptionValue>>
  ) => ReactNode
  loading?: boolean
  isValid?: boolean
  isSearchable?: boolean
  validationMessage?: string
  onInputChange?: (data: string) => void
  menuScrollToBottomCallback?: () => void
  onChange?: (value: OptionValue) => void
  filterOption?: null
  isPaginated?: boolean
  error?: boolean
}

export const SearchableDropdown = <OptionValue,>({
  resourceStrings,
  label,
  options,
  selectedOption,
  customComponents,
  menuListHeader,
  formatOptionLabelOverride,
  loading,
  isValid,
  isSearchable = true,
  instanceId,
  onChange,
  onInputChange,
  validationMessage,
  menuScrollToBottomCallback,
  filterOption,
  isPaginated,
  error,
  ...rest
}: Props<OptionValue>) => {
  const [placeholderValue, setPlaceholderValue] = useState('')
  const [isSearching, setIsSearching] = useState(false)

  const placeHolderMemo = useMemo(() => {
    if (loading) {
      return resourceStrings.loading
    }

    if (isSearching) {
      return resourceStrings.open
    }

    if (!options?.length) {
      return resourceStrings.empty
    }

    if (isValid) {
      return resourceStrings.invalidValue
    }

    return resourceStrings.multipleOptions
  }, [
    isSearching,
    loading,
    options?.length,
    isValid,
    resourceStrings.multipleOptions,
    resourceStrings.open,
    resourceStrings.loading,
    resourceStrings.empty,
    resourceStrings.invalidValue,
  ])

  useEffect(() => {
    setPlaceholderValue(placeHolderMemo)
  }, [placeHolderMemo])

  const onOptionChangeCallback = useCallback(
    (
      data: MultiValue<Option<OptionValue>> | SingleValue<Option<OptionValue>>
    ) => {
      const { value } = (data || { value: '' }) as Option<OptionValue>

      if (onChange) {
        onChange(value)
      }
    },
    [onChange]
  )

  const onMenuFocusCallback = useCallback(() => {
    setPlaceholderValue(resourceStrings.open)
    setIsSearching(true)
  }, [resourceStrings.open])

  const onMenuCloseCallback = useCallback(() => {
    setPlaceholderValue(placeHolderMemo)
    setIsSearching(false)
  }, [placeHolderMemo])

  const formatOptionLabelCallback = useCallback(
    (
      option: Option<OptionValue>,
      meta: FormatOptionLabelMeta<Option<OptionValue>>
    ) => {
      if (formatOptionLabelOverride) {
        return formatOptionLabelOverride(option, meta)
      }
      const isOptionSelected =
        option.label === selectedOption?.label && meta.context === 'menu'

      const variant = isOptionSelected ? 'selectedOption' : 'default'

      const Option = menuListOptions[variant]

      return <Option content={option.label} />
    },
    [formatOptionLabelOverride, selectedOption?.label]
  )

  const MenuList = useCallback(
    (props: MenuListProps<Option<OptionValue>>) => {
      return (
        <components.MenuList {...props}>
          {menuListHeader && <MenuListHeader content={menuListHeader} />}
          {props.children}
        </components.MenuList>
      )
    },
    [menuListHeader]
  )

  const noOptionsMessage = useCallback(
    ({ inputValue }: { inputValue: string }) => {
      if (inputValue.trim().length === 0) {
        return null
      }
      return resourceStrings.noOptions
    },
    [resourceStrings.noOptions]
  )

  const loadingMessage = useCallback(() => {
    return resourceStrings.loadingResults
  }, [resourceStrings.loadingResults])

  const isDisabled =
    (!isSearchable && loading) || (!isSearchable && !options?.length)

  return (
    <div {...rest}>
      <SearchSelect<Option<OptionValue>>
        styles={{
          control: (base, state) => ({
            ...base,
            height: '48px',
            backgroundColor:
              state.menuIsOpen || error
                ? base.backgroundColor
                : 'rgba(39, 63, 63, 0.05)',
            boxShadow:
              error && !state.menuIsOpen
                ? '0 0 0 3px #d43737'
                : state.menuIsOpen || state.isFocused
                ? '0 0 0 3px #6DB9C1'
                : 'transparent',
            borderColor: 'transparent',
          }),
          placeholder: (baseStyles) => ({
            ...baseStyles,
            color:
              placeholderValue === resourceStrings.multipleOptions
                ? 'inherit'
                : baseStyles.color,
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: state.isSelected
              ? '#EDF6F7'
              : baseStyles.backgroundColor,
            color: 'black',
            '&:first-child': {
              backgroundColor: 'transparent',
            },
          }),
          menuList: (baseStyles) => ({
            ...baseStyles,
            minHeight: '540px',
            position: 'relative',
            paddingTop: '0px',
          }),
          noOptionsMessage: (baseStyles) => ({
            ...baseStyles,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }),
          loadingMessage: (baseStyles) => ({
            ...baseStyles,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }),
        }}
        options={!isPaginated && loading ? undefined : options}
        isLoading={loading}
        isClearable={true}
        components={{ MenuList, ...customComponents }}
        noOptionsMessage={noOptionsMessage}
        loadingMessage={loadingMessage}
        filterOption={filterOption}
        onFocus={onMenuFocusCallback}
        onMenuOpen={onMenuFocusCallback}
        onMenuClose={onMenuCloseCallback}
        formatOptionLabel={formatOptionLabelCallback}
        onInputChange={onInputChange}
        onChange={onOptionChangeCallback}
        value={selectedOption}
        isDisabled={isDisabled}
        instanceId={instanceId}
        onMenuScrollToBottom={menuScrollToBottomCallback}
        key={`${selectedOption?.value}`}
        label={label}
        placeholder={placeholderValue}
        isSearchable={isSearchable}
      />
      {!selectedOption && validationMessage && (
        <p className="mt-1 text-negative text-body-small">
          {validationMessage}
        </p>
      )}
    </div>
  )
}
