import { ReactNode, KeyboardEvent, MutableRefObject } from 'react'

export type TextInputProps = {
  onKeyUp?: (val: { value: string; valid: boolean }) => void
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void
  onClick?: () => void
  isFocused?: boolean
  isSelected?: boolean
  name?: string
  className?: string
  inputClassName?: string
  label?: ReactNode | string
  labelHint?: string
  variant?: keyof typeof TextInputVariant
  placeholder?: string
  errorText?: string
  hint?: string | JSX.Element | ReactNode
  onChange?: (val: { value: string; valid: boolean }) => void
  onFocus?: (val: { value: string; valid: boolean }) => void
  onBlur?: (val: { value: string; valid: boolean }) => void
  inputType?: keyof typeof TextInputType
  validationPattern?: string
  minLength?: number
  maxLength?: number
  inputSize?: number
  fullWidth?: boolean
  required?: boolean
  disabled?: boolean
  hidden?: boolean
  ariaLabel?: string
  value?: string | number
  resetValueTo?: { value: string }
  error?: boolean
  rows?: number
  icon?: ReactNode
  dataCy?: string
  autocomplete?: string
  trackErrors?: boolean
  trackFormID?: string
  trackLabel?: string
  inputRef?: MutableRefObject<HTMLInputElement | HTMLTextAreaElement | null>
  labelClassName?: string
  'data-testid'?: string
}

export enum TextInputVariant {
  dark = 'dark',
  light = 'light',
}
export enum TextInputType {
  email = 'email',
  tel = 'tel',
  text = 'text',
  url = 'url',
  password = 'password',
  area = 'area',
  number = 'number',
}
export type TextInputChangeEvent<T> = {
  value: T
  valid: boolean
}
