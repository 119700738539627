import { MutableRefObject, useCallback, useEffect } from 'react'

const useClickOutside = (
  ref:
    | MutableRefObject<Element | null | undefined>
    | MutableRefObject<Element | null | undefined>[],
  callback: () => void
) => {
  const handleClick = useCallback(
    (e: MouseEvent) => {
      const refs = Array.isArray(ref) ? ref : [ref]

      const clickWithinSomeRef = refs.some(
        (ref) => ref?.current && ref.current.contains(e.target as Node)
      )

      if (!clickWithinSomeRef) callback()
    },
    [ref, callback]
  )

  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [handleClick])
}

export { useClickOutside }
