import { useRouter } from 'next/router'
// eslint-disable-next-line import/order
import { parse, stringify } from 'querystring'
import { useCallback } from 'react'

import type { SelectedProductViewState } from '../search-types'

// A hash namespace must be provided in order for queryparams to work.
// For example, '#foo=1&bar=2' will be rejected by next/router
// but '#namespace?foo=1&bar=2' will be accepted.
//
// `hashNs` is used to identify hash queryparams controlled by useSetProductView.
// There is no equivalent namespace for standard queryparams.
const hashNs = 'reviewsFilters'

export const useSetProductView = () => {
  const router = useRouter()
  return useCallback(
    (paramsType?: string, updatedView?: SelectedProductViewState): void => {
      if (paramsType === 'hash') {
        if (typeof window !== 'undefined') {
          const hash = router.asPath.split('#')[1] || ''
          const query = parse(hash.split('?')[1])
          if (updatedView) {
            const updatedQuery = Object.assign({}, query, updatedView)
            router.push(`#${hashNs}?${stringify(updatedQuery)}`, undefined, {
              shallow: true,
            })
          } else {
            // Only update the hash queryparams if they are controlled by this hook.
            if (hash.indexOf(hashNs) === 0) {
              router.push(`#`, undefined, { shallow: true })
            }
          }
        }
      } else {
        if (updatedView?.view) {
          const {
            productcode: currentProductCode,
            view: _view,
            reviewId: _reviewId,
            ...query
          } = router.query
          const { productcode, view, reviewId } = updatedView

          if (productcode) {
            query.productcode = productcode
          }
          if (view) {
            query.view = view
          }
          if (reviewId) {
            query.reviewId = reviewId
          }

          router.push(
            {
              pathname: `/${router.locale}${router.pathname}`,
              query,
            },
            undefined,
            { shallow: true }
          )
        } else {
          const { productcode, view, reviewId, ...updatedQuery } = router.query

          router.push(
            {
              pathname: `/${router.locale}${router.pathname}`,
              query: updatedQuery,
            },
            undefined,
            { shallow: true }
          )
        }
      }
    },
    [router]
  )
}
