/* eslint-disable @typescript-eslint/no-explicit-any */
import { createLogger, format, transports } from 'winston'

const loggerTransports =
  process.env.NODE_ENV === 'production'
    ? [new transports.Console()]
    : [
        new transports.Console({
          format: format.combine(format.colorize(), format.simple()),
        }),
      ]

const commonOptions = {
  format: format.json(),
  defaultMeta: { service: 'abcam-web' },
  transports: loggerTransports,
}

const logger = createLogger({
  ...commonOptions,
  level: 'info',
})

const debugLogger = createLogger({
  ...commonOptions,
  level: 'debug',
})

const errorLogger = createLogger({
  ...commonOptions,
  level: 'error',
})

export class Logger {
  static debug(message: string, params?: Record<string, any>): void {
    debugLogger.debug(message, params)
  }

  static info(message: string, params?: Record<string, any>): void {
    logger.info(message, params)
  }

  static warn(message: string, params?: any, err?: Error): void {
    logger.warn(message, params, err)
  }

  static error(
    message: string,
    params?: Record<string, any>,
    err?: Error
  ): void {
    errorLogger.error(message, params, err)
  }
}
