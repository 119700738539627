import { useContext } from 'react'
import { ViewportContext } from './viewport.context'

import type { Dimensions } from './viewport.types'

const useViewport = (): Dimensions => {
  const context = useContext(ViewportContext)
  if (context === undefined)
    throw new Error('useViewport must be within ViewportProvider')

  return context
}
export { useViewport }
