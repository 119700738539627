import { POSTCODE_REGEXES } from '../data'
import { BaseAddressSchema } from './base'

export const MYAddressSchema = {
  ...BaseAddressSchema,

  state: {
    type: 'string',
    required: true,
    oneOf: [
      'JOHORE',
      'KEDAH',
      'KELANTAN',
      'MALACCA',
      'NEGERI SEMBILAN',
      'PAHANG',
      'PENANG',
      'PERAK',
      'PERLIS',
      'SABAH',
      'SARAWAK',
      'SELANGOR',
      'TERENGGANU',
    ],
  },

  postcode: {
    type: 'string',
    required: true,
    // combining all regexes in logical OR
    pattern: new RegExp(
      POSTCODE_REGEXES['MY'].map((exp) => `^${exp}$`).join('|'),
      'i'
    ),
  },
} as const

export type MYAddressSchemaType = typeof MYAddressSchema

export default MYAddressSchema
