export const COGNITO_ATTRIBUTES = {
  MARKETING_OPT_IN: 'custom:marketingOptIn',
  GIVEN_NAME: 'given_name',
  FAMILY_NAME: 'family_name',
  IS_RED_LISTED: 'custom:isRedListed',
  CONTACT_REGISTRY_ID: 'custom:contactRegistryId',
  CONSUMER_SEGMENT: 'custom:consumerSegment',
  PRIMARY_ORGANISATION_REGISTRY_ID: 'custom:primaryOrgRegistryId',
  GROUPS: 'cognito:groups',
  PUNCHOUT_ID: 'custom:po2goId',
  PUNCHOUT_SESSION_ID: 'custom:po2goSessionId',
} as const
