import {
  AssetDefinitionModel,
  BasketModel,
  CheckDeliveryDatesRequest,
  CheckDeliveryDatesResponse,
  UpdateDeliveryDatesRequest,
  UpdateDeliveryDatesResponse,
  ContactAddressesModel,
  ContactsResponse,
  Distributor as EcommDistributor,
  DistributorInquiryModel,
  LotSpecificOrder,
  OrderChangeStatusModel,
  OrderRequestModel,
  OrderResponseModel,
  OrderStatus,
  OrganisationType,
  OrganisationsResponse,
  PriceAdjustment,
  PurchaseTypeEnum,
  RestServiceError,
  StocksDetails,
} from '@abcam-web/shared/data-access/ecommerce-schema'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { axiosECOMInstance } from '@abcam-web/shared/data-access/ecommerce-api-hooks'
import { DISTRIBUTOR_API_CLIENT, Distributor } from './distributor-api-client'
import getConfig from 'next/config'
import { useCountry } from '@abcam-web/shared/utilities/country'

const isValidPathParameter = (param: string | number | undefined | null) =>
  param !== '' && param !== undefined && param !== null

const getUrlWithPathAndQueryParameters = ({
  baseUrl,
  queryParameters,
  pathParameters = [],
}: {
  baseUrl: string
  queryParameters?: Record<string, string | number | undefined>
  pathParameters?: (string | number | undefined)[]
}): string => {
  if (!baseUrl || typeof baseUrl !== 'string') {
    throw new Error(
      `base url was missing or of wrong format "baseUrl": ${JSON.stringify(
        baseUrl
      )}`
    )
  }
  let urlWithPathParameters = baseUrl
  const validPathParameters = pathParameters.filter(isValidPathParameter)
  if (validPathParameters.length > 0) {
    urlWithPathParameters += '/' + validPathParameters.join('/')
  }

  if (!queryParameters) {
    return urlWithPathParameters
  }

  return Object.keys(queryParameters).reduce((url, parameterKey) => {
    if (queryParameters[parameterKey]) {
      url += url.includes('?')
        ? `&${parameterKey}=${queryParameters[parameterKey]}`
        : `?${parameterKey}=${queryParameters[parameterKey]}`
    }
    return url
  }, urlWithPathParameters)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface Options<ResponseType = any, RequestType = any, ErrorType = any> {
  onSuccess?: (responseData: ResponseType, requestData: RequestType) => void
  onError?: (error: ErrorType) => void
  disabled?: boolean
}

const useAssetDefinitionQuery = (
  variables: {
    assetDefinitionNumber: string
    country: string
    quantity: number
    organisationRegistryId?: string
  },
  options?: Options
) => {
  const { countryIsLoaded } = useCountry()

  const { assetDefinitionNumber, country, quantity, organisationRegistryId } =
    variables
  const assetDefinitionQuery = useQuery<AssetDefinitionModel, AxiosError>({
    // Enabled only if the country from the cookie has been loaded client-side
    enabled: countryIsLoaded && !!assetDefinitionNumber,
    queryKey: [
      'asset-definition',
      assetDefinitionNumber,
      country,
      quantity,
      organisationRegistryId,
    ],
    onSuccess: (data) => {
      options?.onSuccess &&
        options?.onSuccess(data, {
          assetDefinitionNumber,
          country,
          quantity,
        })
    },
    onError: options?.onError,
    queryFn: () => {
      const url = getUrlWithPathAndQueryParameters({
        baseUrl: 'asset-definition',
        pathParameters: [assetDefinitionNumber],
        queryParameters: { country, quantity, organisationRegistryId },
      })

      return axiosECOMInstance.get(url).then(({ data }) => {
        return data
      })
    },
  })

  return assetDefinitionQuery
}

export type BasketQueryKey = ReturnType<typeof getBasketQueryKey>

function getBasketQueryKey({
  basketId,
  country,
  controlPoint,
  organisationRegistryId,
  billingCountry,
  isLogged = false,
}: {
  basketId?: string
  country?: string
  controlPoint?: string
  organisationRegistryId?: string
  billingCountry?: string
  isLogged?: boolean
}) {
  return [
    'basket',
    basketId,
    controlPoint,
    country,
    organisationRegistryId,
    billingCountry,
    isLogged,
  ]
}

const useBasketQuery = (
  variables: {
    queryKey: BasketQueryKey
    basketId?: string
    country?: string
    controlPoint?: string
    organisationRegistryId?: string
  },
  options?: Options
) => {
  const { queryKey, basketId, country, controlPoint, organisationRegistryId } =
    variables
  const queryClient = useQueryClient()
  const { countryIsLoaded } = useCountry()

  if (!basketId) {
    queryClient.removeQueries({
      queryKey,
    })
  }

  const basketQuery = useQuery<BasketModel, AxiosError>({
    queryKey,
    // Enabled only if country from the cookie has also been loaded client-side
    enabled: !!basketId && !options?.disabled && countryIsLoaded,
    queryFn: async () => {
      const url = getUrlWithPathAndQueryParameters({
        baseUrl: `basket`,
        queryParameters: {
          country,
          controlPoint,
          organisationRegistryId,
        },
        pathParameters: [basketId as string],
      })

      return axiosECOMInstance
        .get(url)
        .then(({ data }) => {
          options?.onSuccess && options?.onSuccess(data, { basketId, country })
          return data
        })
        .catch((error) => {
          options?.onError && options?.onError(error)
          throw error
        })
    },
  })

  return basketQuery
}

const useDistributorQuery = (
  variables: { country: string },
  options?: Options
) => {
  const { country } = variables

  const distributorQuery = useQuery<EcommDistributor[], AxiosError>({
    queryKey: ['distributors', country],
    queryFn: () =>
      axiosECOMInstance
        .get(
          getUrlWithPathAndQueryParameters({
            baseUrl: 'distributors',
            queryParameters: { country },
          })
        )
        .then(({ data }) => {
          options?.onSuccess && options?.onSuccess(data, variables)
          return data
        })
        .catch((error) => {
          options?.onError && options?.onError(error)
          throw error
        }),
  })

  return distributorQuery
}

const useDistributorApiQuery = (
  variables: { country: string; stage?: string },
  options?: Options & { shouldRefetch?: boolean; initialData?: Distributor[] }
) => {
  const { country, stage = getConfig().publicRuntimeConfig?.STAGE } = variables
  const { countryIsLoaded } = useCountry()

  const distributorApiQuery = useQuery<Distributor[], AxiosError>({
    queryKey: ['fetch-distributors', country],
    initialData: options?.initialData,
    // Enabled only if country from the cookie has also been loaded client-side
    enabled: options?.shouldRefetch && countryIsLoaded,
    queryFn: () =>
      DISTRIBUTOR_API_CLIENT(stage)
        .fetchDistributors(country)
        .then((data) => {
          options?.onSuccess && options?.onSuccess(data, variables)
          return data
        })
        .catch((error) => {
          options?.onError && options?.onError(error)
          throw error
        }),
  })

  return distributorApiQuery
}

const useUpdateLineInBasketMutation = (
  queryKey: BasketQueryKey,
  options?: Options
) => {
  const queryClient = useQueryClient()
  const updateLineInBasket = useMutation({
    mutationFn: ({
      basketId,
      country,
      lineNumber,
      publicAssetCode,
      quantity,
      organisationRegistryId,
      priceAdjustment,
      customerLineNumber,
      customerPurchaseOrderNumber,
      lotSpecificOrder,
      controlPoint,
    }: {
      basketId: string
      country?: string
      lineNumber: number
      publicAssetCode: string
      quantity: number
      organisationRegistryId?: string
      priceAdjustment?: PriceAdjustment | null
      customerPurchaseOrderNumber?: string | null
      customerLineNumber?: string | null
      lotSpecificOrder?: LotSpecificOrder | null
      billingCountry?: string
      controlPoint?: string
    }) => {
      const url = getUrlWithPathAndQueryParameters({
        baseUrl: 'basket',
        pathParameters: [basketId, 'line', lineNumber],
        queryParameters: { country, organisationRegistryId, controlPoint },
      })

      return axiosECOMInstance
        .put(url, {
          publicAssetCode,
          quantity,
          priceAdjustment,
          customerPurchaseOrderNumber,
          customerLineNumber,
          lotSpecificOrder,
        })
        .then(({ data }) => {
          queryClient.setQueryData(queryKey, data.basket)
          options?.onSuccess &&
            options?.onSuccess(data, {
              basketId,
              country,
              lineNumber,
              publicAssetCode,
              quantity,
              organisationRegistryId,
              customerPurchaseOrderNumber,
              customerLineNumber,
              lotSpecificOrder,
            })
        })
        .catch(options?.onError)
    },
  })
  return updateLineInBasket
}

const useAddLinesInBasketMutation = (
  queryKey: BasketQueryKey,
  options?: Options
) => {
  const queryClient = useQueryClient()
  const updateLineInBasket = useMutation({
    mutationFn: ({
      basketId,
      country,
      lines,
      organisationRegistryId,
      controlPoint,
    }: {
      lines: { assetId: string; quantity: number }[]
      basketId: string
      country?: string
      organisationRegistryId?: string
      controlPoint?: string
    }) => {
      const url = getUrlWithPathAndQueryParameters({
        baseUrl: 'basket',
        pathParameters: [basketId],
        queryParameters: { country, organisationRegistryId, controlPoint },
      })
      return axiosECOMInstance
        .post(url, lines)
        .then(({ data }) => {
          queryClient.setQueryData(queryKey, data.basket)
          options?.onSuccess &&
            options?.onSuccess(data, {
              basketId,
              country,
              lines,
              organisationRegistryId,
            })
        })
        .catch(options?.onError)
    },
  })
  return updateLineInBasket
}

const useDeleteLineInBasketMutation = (
  queryKey: BasketQueryKey,
  options?: Options
) => {
  const queryClient = useQueryClient()
  const updateLineInBasket = useMutation({
    mutationFn: ({
      basketId,
      lineNumber,
      country,
      organisationRegistryId,
      controlPoint,
    }: {
      basketId: string
      lineNumber: number
      country?: string
      organisationRegistryId?: string
      controlPoint?: string
    }) => {
      const url = getUrlWithPathAndQueryParameters({
        baseUrl: 'basket',
        pathParameters: [basketId, 'line', lineNumber],
        queryParameters: { country, organisationRegistryId, controlPoint },
      })

      return axiosECOMInstance
        .delete(url)
        .then(({ data }) => {
          queryClient.setQueryData(queryKey, data)
          options?.onSuccess &&
            options?.onSuccess(data, {
              basketId,
              lineNumber,
              country,
              organisationRegistryId,
            })
        })
        .catch(options?.onError)
    },
  })
  return updateLineInBasket
}

const useDeleteAllLinesInBasketMutation = (
  queryKey: BasketQueryKey,
  options?: Options
) => {
  const queryClient = useQueryClient()
  const updateLineInBasket = useMutation({
    mutationFn: ({
      basketId,
      country,
      organisationRegistryId,
    }: {
      basketId: string
      country?: string
      organisationRegistryId?: string
    }) => {
      const url = getUrlWithPathAndQueryParameters({
        baseUrl: 'basket',
        pathParameters: [basketId, 'empty'],
        queryParameters: { country, organisationRegistryId },
      })

      return axiosECOMInstance
        .delete(url)
        .then(({ data }) => {
          queryClient.setQueryData(queryKey, data)
          options?.onSuccess &&
            options?.onSuccess(data, {
              basketId,
              country,
              organisationRegistryId,
            })
        })
        .catch(options?.onError)
    },
  })
  return updateLineInBasket
}

const useApplyDiscountCodeToBasketMutation = (options?: Options) => {
  const applyDiscountCodeToBasketMutation = useMutation({
    mutationFn: ({
      basketId,
      promoCode,
      country,
    }: {
      basketId: string
      promoCode: string
      country?: string
    }) => {
      const url = getUrlWithPathAndQueryParameters({
        baseUrl: 'basket',
        pathParameters: [basketId, 'promotion'],
        queryParameters: { country },
      })

      return axiosECOMInstance
        .put(url, { promotionCode: promoCode })
        .then(({ data }) => {
          options?.onSuccess &&
            options?.onSuccess(data, { basketId, promoCode, country })
        })
        .catch(options?.onError)
    },
  })
  return applyDiscountCodeToBasketMutation
}

const useRemoveDiscountCodeFromBasketMutation = (options?: Options) => {
  const removeDiscountCodeFromBasketMutation = useMutation({
    mutationFn: ({
      basketId,
      promoCode,
      country,
    }: {
      basketId: string
      promoCode: string
      country?: string
    }) => {
      const url = getUrlWithPathAndQueryParameters({
        baseUrl: 'basket',
        pathParameters: [basketId, 'promotion', encodeURIComponent(promoCode)],
        queryParameters: { country },
      })

      return axiosECOMInstance
        .delete(url)
        .then(({ data }) => {
          options?.onSuccess &&
            options?.onSuccess(data, { basketId, promoCode, country })
        })
        .catch(options?.onError)
    },
  })

  return removeDiscountCodeFromBasketMutation
}

const useInquiryDistributorsMutation = (
  variables: { basketId: string; country: string },
  options?: Options
) => {
  const { basketId, country } = variables
  const inquiryDistributorsMutation = useMutation({
    mutationKey: ['basket', basketId, country],
    mutationFn: (inquiry: DistributorInquiryModel) =>
      axiosECOMInstance
        .post(
          getUrlWithPathAndQueryParameters({
            baseUrl: 'distributors',
            pathParameters: [basketId],
            queryParameters: { country },
          }),
          inquiry
        )
        .then(({ data }) => {
          options?.onSuccess &&
            options?.onSuccess(data, { basketId, country, inquiry })
        })
        .catch(options?.onError),
  })
  return inquiryDistributorsMutation
}

const useOrderQuery = (variables: { orderId?: string }, options?: Options) => {
  const { orderId } = variables
  const orderQuery = useQuery<OrderResponseModel, AxiosError>({
    enabled: !!orderId && !options?.disabled,
    queryKey: ['order', orderId],
    queryFn: () =>
      axiosECOMInstance
        .get(
          getUrlWithPathAndQueryParameters({
            baseUrl: 'order',
            pathParameters: [orderId as string],
          })
        )
        .then(({ data }) => {
          options?.onSuccess && options?.onSuccess({ ...data }, { orderId })
          return data
        })
        .catch(options?.onError),
  })
  return orderQuery
}

const useOrderMutation = (
  variables: {
    orderId: string
    organisationRegistryId?: string
  },
  options?: Options
) => {
  const queryClient = useQueryClient()
  const { orderId, organisationRegistryId } = variables
  const orderMutation = useMutation({
    mutationFn: async ({
      orderModel,
      checkShippingCountry,
    }: {
      orderModel: OrderRequestModel
      checkShippingCountry?: boolean
    }) => {
      // TODO: why not handle this in the backend by order service
      if (checkShippingCountry) {
        const shippingCountry = orderModel?.addresses?.shipping?.country
        const marketInfoResponse = await axiosECOMInstance.get(
          getUrlWithPathAndQueryParameters({
            baseUrl: 'market-info',
            queryParameters: { country: shippingCountry },
          })
        )
        if (
          marketInfoResponse?.data?.purchaseType ===
          PurchaseTypeEnum.Distributor
        ) {
          return Promise.reject({
            code: 409,
            message: 'Shipping country not allowed',
          })
        }
      }

      const url = getUrlWithPathAndQueryParameters({
        baseUrl: 'order',
        pathParameters: [orderId],
        queryParameters: { organisationRegistryId },
      })

      const orderUpdateResponse = await axiosECOMInstance.put(url, orderModel)
      return orderUpdateResponse
    },
    onSuccess: (response, requestData) => {
      queryClient.setQueryData(['order', orderId], response.data)
      options?.onSuccess && options.onSuccess(response, requestData)
    },
    onError: options?.onError,
  })
  return orderMutation
}

const useChangeOrderStatusMutation = (
  variables: {
    orderId: string
  },
  options?: Options
) => {
  const queryClient = useQueryClient()
  const { orderId } = variables
  const orderStatusMutation = useMutation({
    mutationFn: async ({
      orderchangeStatusModel,
    }: {
      orderchangeStatusModel: OrderChangeStatusModel
    }) => {
      const url = getUrlWithPathAndQueryParameters({
        baseUrl: 'order',
        pathParameters: [orderId],
      })

      return axiosECOMInstance.patch(url, orderchangeStatusModel)
    },
    onSuccess: (response, requestData) => {
      queryClient.setQueryData(['order', orderId], response.data)
      options?.onSuccess && options.onSuccess(response, requestData)
    },
    onError: options?.onError,
  })
  return orderStatusMutation
}

const useOproSubmitOrderMutation = (
  variables: { orderId: string },
  options?: Options
) => {
  const queryClient = useQueryClient()
  const { orderId } = variables
  const submitOproMutation = useMutation({
    mutationFn: () =>
      axiosECOMInstance.patch(
        getUrlWithPathAndQueryParameters({
          baseUrl: 'order',
          pathParameters: [orderId],
        }),
        {
          status: OrderStatus.CONFIRMED,
        }
      ),
    onSuccess: ({ data }, requestData) => {
      queryClient.setQueryData(['order', orderId], data)
      options?.onSuccess && options?.onSuccess(data, requestData)
    },
    onError: options?.onError,
  })
  return submitOproMutation
}

const useSubmitOrderMutation = (
  variables: { orderId: string },
  options?: Options
) => {
  const queryClient = useQueryClient()
  const { orderId } = variables
  const submitOrderMutation = useMutation({
    mutationFn: () =>
      axiosECOMInstance.patch(
        getUrlWithPathAndQueryParameters({
          baseUrl: 'order',
          pathParameters: [orderId],
        }),
        {
          status: OrderStatus.ONLINE_BETA,
        }
      ),
    onSuccess: ({ data }, requestData) => {
      queryClient.setQueryData(['order', orderId], data)
      options?.onSuccess && options?.onSuccess(data, requestData)
    },
    onError: options?.onError,
  })
  return submitOrderMutation
}

const useOrderPaymentProcessingMutation = (
  variables: { orderId: string },
  options?: Options
) => {
  const queryClient = useQueryClient()

  const { orderId } = variables

  const submitOrderPaymentProcessingMutation = useMutation({
    mutationFn: () =>
      axiosECOMInstance.patch(
        getUrlWithPathAndQueryParameters({
          baseUrl: 'order',
          pathParameters: [orderId],
        }),
        {
          status: OrderStatus.PAYMENT_PROCESSING,
        }
      ),
    onSuccess: ({ data }, requestData) => {
      queryClient.setQueryData(['order', orderId], data)
      options?.onSuccess && options?.onSuccess(data, requestData)
    },
    onError: options?.onError,
  })

  return submitOrderPaymentProcessingMutation
}

const useCancelOrderMutation = (
  variables: {
    orderId: string
    cancellationReasonCode?: string
    progressNote?: string
  },
  options?: Options
) => {
  const queryClient = useQueryClient()
  const { orderId, cancellationReasonCode, progressNote } = variables

  const cancelOrderMutation = useMutation({
    mutationFn: () =>
      axiosECOMInstance.patch(
        getUrlWithPathAndQueryParameters({
          baseUrl: 'order',
          pathParameters: [orderId],
        }),
        {
          status: OrderStatus.CANCELLED,
          cancellationReasonCode,
          progressNotes: progressNote,
        }
      ),
    onSuccess: ({ data }, requestData) => {
      queryClient.setQueryData(['order', orderId], data)
      options?.onSuccess && options?.onSuccess(data, requestData)
    },
    onError: options?.onError,
  })

  return cancelOrderMutation
}

const useCancelQuoteMutation = (
  variables: {
    orderId: string
    cancellationReasonCode?: string
    progressNote?: string
  },
  options?: Options
) => {
  const queryClient = useQueryClient()
  const { orderId, cancellationReasonCode, progressNote } = variables

  const cancelQuoteMutation = useMutation({
    mutationFn: () =>
      axiosECOMInstance.patch(
        getUrlWithPathAndQueryParameters({
          baseUrl: 'order',
          pathParameters: [orderId],
        }),
        {
          // In the UI we’re showing this quote action as a cancellation but it’s
          // actually changing to status `EXPIRED` — this is the end-of-life
          // state of an order of type `QUOTE`, if it’s not destined to be
          // upgraded to type `ORDER`.
          status: OrderStatus.EXPIRED,
          cancellationReasonCode,
          progressNotes: progressNote,
        }
      ),
    onSuccess: ({ data }, requestData) => {
      queryClient.setQueryData(['order', orderId], data)
      options?.onSuccess && options?.onSuccess(data, requestData)
    },
    onError: options?.onError,
  })

  return cancelQuoteMutation
}

const useStockDetailsQuery = (
  variables: {
    assetNumber: string
    country: string
    city?: string
    province?: string
  },
  options?: Options
) => {
  const { assetNumber, country, city, province } = variables

  const url = getUrlWithPathAndQueryParameters({
    baseUrl: 'stock-details',
    queryParameters: { assetNumber, country, city, province },
  })
  const stockDetailsQuery = useQuery<StocksDetails, AxiosError>({
    queryKey: ['stock-details', assetNumber, country],
    enabled: !options?.disabled && !!country,
    queryFn: () =>
      axiosECOMInstance
        .get(url)
        .then(({ data }) => {
          options?.onSuccess &&
            options?.onSuccess(data, { assetNumber, country, city, province })
          return data
        })
        .catch(options?.onError),
  })

  return stockDetailsQuery
}

const useContactAddressesQuery = (
  variables: {
    contactRegistryId: string
    limit?: number
    offset?: number
    partySiteNumber?: string
    organisationRegistryId?: string
    addressType?: string
    searchString?: string
  },
  options?: Options
) => {
  const {
    contactRegistryId,
    limit,
    offset,
    partySiteNumber,
    organisationRegistryId,
    addressType,
    searchString,
  } = variables
  const baseUrl = 'customers'

  const contactAddressesQuery = useQuery<ContactAddressesModel, AxiosError>({
    queryKey: [
      baseUrl,
      contactRegistryId,
      limit,
      offset,
      partySiteNumber,
      organisationRegistryId,
      addressType,
      searchString,
    ],
    enabled: Boolean(contactRegistryId),
    queryFn: () => {
      const url = getUrlWithPathAndQueryParameters({
        baseUrl: baseUrl,
        pathParameters: ['contacts', contactRegistryId, 'addresses'],
        queryParameters: {
          limit,
          offset,
          partySiteNumber,
          organisationRegistryId,
          addressType,
          searchString,
        },
      })

      return axiosECOMInstance
        .get(url)
        .then(({ data }) => {
          return data
        })
        .catch(options?.onError)
    },
  })

  return contactAddressesQuery
}
export type OrganisationContactsQueryVariables = {
  organisationRegistryId: string
  organisationType: (typeof OrganisationType)[keyof typeof OrganisationType]
  limit?: number
  offset?: number
  contactRegistryId?: string
}

const useOrganisationContactsQuery = (
  variables: OrganisationContactsQueryVariables,
  options?: Options<
    ContactsResponse,
    OrganisationContactsQueryVariables,
    AxiosError<RestServiceError>
  >
) => {
  const {
    organisationRegistryId,
    organisationType,
    limit,
    offset,
    contactRegistryId,
  } = variables

  const url = getUrlWithPathAndQueryParameters({
    baseUrl: 'customers',
    pathParameters: ['organisations', organisationRegistryId, 'contacts'],
    queryParameters: { limit, offset, contactRegistryId },
  })
  const organisationContactsQuery = useQuery<
    ContactsResponse,
    AxiosError<RestServiceError>
  >({
    enabled: !!organisationRegistryId && !options?.disabled,
    queryKey: [
      organisationType,
      'contacts',
      organisationRegistryId,
      limit,
      offset,
      contactRegistryId,
    ],
    queryFn: () =>
      axiosECOMInstance
        .get<ContactsResponse>(url)
        .then(({ data }) => {
          options?.onSuccess &&
            options?.onSuccess(data, {
              organisationRegistryId,
              organisationType,
            })
          return data
        })
        .catch((error: AxiosError<RestServiceError>) => {
          options?.onError && options.onError(error)
          throw error
        }),
  })

  return organisationContactsQuery
}

export type OrganisationContactsSearchQueryVariables = {
  organisationRegistryId: string
  limit?: number
  offset?: number
  contactIdentifier?: string
  contactType?: 'BILL_TO' | 'SHIP_TO'
}

const useOrganisationContactsSearchQuery = (
  variables: OrganisationContactsSearchQueryVariables,
  options?: Options<
    ContactsResponse,
    OrganisationContactsSearchQueryVariables,
    AxiosError<RestServiceError>
  >
) => {
  const {
    organisationRegistryId,
    contactType,
    limit,
    offset,
    contactIdentifier,
  } = variables

  const url = getUrlWithPathAndQueryParameters({
    baseUrl: 'customers',
    pathParameters: ['organisations', organisationRegistryId, 'contact-search'],
    queryParameters: {
      limit,
      offset,
      contactIdentifier,
      contactType,
    },
  })
  const organisationContactsQuery = useQuery<
    ContactsResponse,
    AxiosError<RestServiceError>
  >({
    enabled: !!organisationRegistryId && !options?.disabled,
    queryKey: [
      contactType,
      'contacts',
      organisationRegistryId,
      limit,
      offset,
      contactIdentifier,
    ],
    queryFn: () =>
      axiosECOMInstance
        .get<ContactsResponse>(url)
        .then(({ data }) => {
          options?.onSuccess &&
            options?.onSuccess(data, {
              organisationRegistryId,
              contactType,
            })
          return data
        })
        .catch((error: AxiosError<RestServiceError>) => {
          options?.onError && options.onError(error)
          throw error
        }),
  })

  return organisationContactsQuery
}

export type ContactOrganisationsQueryVariables = {
  contactRegistryId: string
  organisationType: (typeof OrganisationType)[keyof typeof OrganisationType]
  limit?: number
  offset?: number
  organisationRegistryId?: string
}

const useOrganisationsQuery = (
  variables: ContactOrganisationsQueryVariables,
  options?: Options
) => {
  const {
    organisationType,
    contactRegistryId,
    limit,
    offset,
    organisationRegistryId,
  } = variables

  const url = getUrlWithPathAndQueryParameters({
    baseUrl: 'customers',
    pathParameters: [contactRegistryId, 'organisations'],
    queryParameters: { limit, offset, organisationRegistryId },
  })
  const organisationsQuery = useQuery<
    OrganisationsResponse,
    AxiosError<RestServiceError>
  >({
    enabled: !!contactRegistryId,
    queryKey: [
      'organisations',
      contactRegistryId,
      limit,
      offset,
      organisationRegistryId,
      organisationType,
    ],
    queryFn: () =>
      axiosECOMInstance
        .get<OrganisationsResponse>(url)
        .then(({ data }) => {
          options?.onSuccess && options?.onSuccess(data, variables)
          return data
        })
        .catch((error: AxiosError<RestServiceError>) => {
          options?.onError && options.onError(error)
          throw error
        }),
  })

  return organisationsQuery
}

const useRestoreOrderMutation = (
  variables: { orderId: string },
  options?: Options
) => {
  const { orderId } = variables
  const baseUrl = 'order'
  const restoreOrderMutation = useMutation({
    mutationFn: () => {
      const url = getUrlWithPathAndQueryParameters({
        baseUrl: baseUrl,
        pathParameters: [orderId, 'restore'],
      })

      return axiosECOMInstance
        .post(url)
        .then(({ data }) => {
          options?.onSuccess && options?.onSuccess(data, { orderId })
        })
        .catch(options?.onError)
    },
  })
  return restoreOrderMutation
}

const useCheckDeliveryDatesMutation = (
  variables: { basketId: string },
  options?: Options
) => {
  const { basketId } = variables
  const baseUrl = 'basket'
  const endpoint = 'check-delivery-dates'

  const url = getUrlWithPathAndQueryParameters({
    baseUrl: baseUrl,
    pathParameters: [basketId, endpoint],
  })

  const checkDeliveryDatesMutation = useMutation({
    mutationFn: (requestData: CheckDeliveryDatesRequest) =>
      axiosECOMInstance
        .post<CheckDeliveryDatesResponse>(url, requestData)
        .then(({ data }) => {
          options?.onSuccess && options?.onSuccess(data, requestData)
        })
        .catch((error: AxiosError<RestServiceError>) =>
          options?.onError?.(error)
        ),
  })

  return checkDeliveryDatesMutation
}

const useUpdateDeliveryDatesMutation = (
  variables: { basketId: string; queryKey?: BasketQueryKey },
  options?: Options
) => {
  const queryClient = useQueryClient()

  const { basketId, queryKey } = variables
  const baseUrl = 'basket'
  const endpoint = 'update-delivery-dates'

  const url = getUrlWithPathAndQueryParameters({
    baseUrl: baseUrl,
    pathParameters: [basketId, endpoint],
  })

  const UpdateDeliveryDatesMutation = useMutation({
    mutationFn: (requestData: UpdateDeliveryDatesRequest) =>
      axiosECOMInstance
        .put<UpdateDeliveryDatesResponse>(url, requestData)
        .then(({ data }) => {
          if (queryKey) {
            queryClient.setQueryData(queryKey, data)
          }
          options?.onSuccess && options?.onSuccess(data, requestData)
        })
        .catch((error: AxiosError<RestServiceError>) =>
          options?.onError?.(error)
        ),
  })

  return UpdateDeliveryDatesMutation
}

export const useConvertToOrder = (orderId: string, options?: Options) => {
  const convertToOrder = useMutation({
    mutationFn: () =>
      axiosECOMInstance.post(`quote/${orderId}/convert-to-order`),
    onSuccess: ({ data }, requestData) => {
      options?.onSuccess && options?.onSuccess(data, requestData)
    },
    onError: options?.onError,
  })

  return convertToOrder
}

const useQuotesPdfPreviewMutation = (
  variables: { orderId: string },
  options?: Options
) => {
  const { orderId } = variables
  const baseUrl = 'quote'
  const endpoint = 'preview'

  const url = getUrlWithPathAndQueryParameters({
    baseUrl: baseUrl,
    pathParameters: [orderId, endpoint],
  })

  const pdfPreview = useMutation({
    mutationFn: (requestData: { quoteId: string }) =>
      axiosECOMInstance
        .post<{
          downloadUrl: string
        }>(url)
        .then(({ data }) => {
          options?.onSuccess && options?.onSuccess(data, requestData)
        })
        .catch((error: AxiosError<RestServiceError>) =>
          options?.onError?.(error)
        ),
  })

  return pdfPreview
}

export {
  getBasketQueryKey,
  getUrlWithPathAndQueryParameters,
  isValidPathParameter,
  useAddLinesInBasketMutation,
  useApplyDiscountCodeToBasketMutation,
  useAssetDefinitionQuery,
  useBasketQuery,
  useCancelOrderMutation,
  useCancelQuoteMutation,
  useChangeOrderStatusMutation,
  useContactAddressesQuery,
  useDeleteAllLinesInBasketMutation,
  useDeleteLineInBasketMutation,
  useDistributorQuery,
  useDistributorApiQuery,
  useInquiryDistributorsMutation,
  useOproSubmitOrderMutation,
  useOrderMutation,
  useOrderPaymentProcessingMutation,
  useOrderQuery,
  useOrganisationContactsQuery,
  useOrganisationContactsSearchQuery,
  useOrganisationsQuery,
  useRemoveDiscountCodeFromBasketMutation,
  useRestoreOrderMutation,
  useStockDetailsQuery,
  useSubmitOrderMutation,
  useUpdateLineInBasketMutation,
  useCheckDeliveryDatesMutation,
  useUpdateDeliveryDatesMutation,
  useQuotesPdfPreviewMutation,
}
