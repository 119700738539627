import { amplifySignOut } from '@abcam-web/amplify-secure-js'
import { AccountDropdown } from '@abcam-web/auth-shared/components'
import { User, UserContext } from '@abcam-web/auth-shared/contexts/user'

import { useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { getAuthenticationHost } from '../util/getAuthenticationHost'
import { getCurrentUserCS } from '../util/getCurrentUserCS'
import { STAGE } from '@abcam-web/auth-shared/constants/stage-type'
import { COGNITO_ATTRIBUTES } from '@abcam-web/auth-shared/aws-cognito'
import {
  trackUserSessionLoggedOutEvent,
  trackUserSessionStartedEvent,
  useGtm,
} from '@abcam-web/shared/data-access/tracking'
import { removeAuthTokensFromLocalStorage } from '@abcam-web/shared/utilities/auth'
import { Auth } from 'aws-amplify'
// import { usePunchoutUser } from '../hooks/usePunchoutUser'

interface CognitoAccountDropdownProps {
  stage: STAGE
  onSignOut?: () => void
}

const CognitoAccountDropdown = ({
  stage,
  onSignOut,
}: CognitoAccountDropdownProps) => {
  const { user: userCtx, isProcessedTokenFromUrl } = useContext(UserContext)

  const [user, setUser] = useState<User | null | undefined>(userCtx)
  const [registerUrl, setRegisterUrl] = useState('')
  const [loginUrl, setLoginUrl] = useState('')
  const router = useRouter()
  const gtm = useGtm()

  // TODO: Uncomment when punchout is ready
  // const { isPunchoutUser, logoUrl, welcomeText } = usePunchoutUser(user)

  useEffect(() => {
    setRegisterUrl(
      `${getAuthenticationHost(stage)}/register?redirect=${encodeURIComponent(
        window.location.href
      )}`
    )
    setLoginUrl(
      `${getAuthenticationHost(stage)}/login?redirect=${encodeURIComponent(
        window.location.href
      )}`
    )
  }, [stage, router.pathname, router.query])

  useEffect(() => {
    if (userCtx === undefined && isProcessedTokenFromUrl) {
      getCurrentUserCS()
        .then(setUser)
        .catch(() => setUser(null))
    }
  }, [userCtx, isProcessedTokenFromUrl])

  useEffect(() => {
    if (user && user[COGNITO_ATTRIBUTES.CONTACT_REGISTRY_ID]) {
      trackUserSessionStartedEvent(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        user[COGNITO_ATTRIBUTES.CONTACT_REGISTRY_ID]!,
        gtm.track
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const cognitoSignout = async () => {
    const res = await Auth.signOut()

    removeAuthTokensFromLocalStorage(stage)
    trackUserSessionLoggedOutEvent(gtm.track)

    return res
  }

  const isLowerEnvironment =
    stage === 'local' || stage === 'dev' || stage === 'staging'

  const selectSignOutMethod = isLowerEnvironment
    ? cognitoSignout
    : amplifySignOut

  // TODO: Uncomment when punchout is ready
  // if (isPunchoutUser) {
  //   return (
  //     <span>
  //       {logoUrl && <img src={logoUrl} alt="" />}
  //       {welcomeText && <span>{welcomeText}</span>}
  //     </span>
  //   )
  // }

  return (
    <span data-cy="account-dropdown">
      <AccountDropdown
        loginUrl={loginUrl}
        registerUrl={registerUrl}
        user={user}
        signOut={selectSignOutMethod}
        onSignOut={onSignOut}
      />
    </span>
  )
}

export { CognitoAccountDropdown }
export type { CognitoAccountDropdownProps }
