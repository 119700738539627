import { MultipleOrgDropdownV2 } from '@abcam-web/ecommerce/components'
import {
  AddressDetails,
  AddressesSearchResult,
  OrderAddressModel,
  OrganisationDetails,
} from '@abcam-web/shared/data-access/ecommerce-schema'
import { PageSubtype } from '@abcam-web/shared/data-access/tracking'
import { SearchableDropdown } from '@abcam-web/shared/ecommerce/components'
import { removeWordFromString } from '@abcam-web/shared/ecommerce/utilities'
import { Button } from '@lego/ui/button'
import { SimpleTextInput } from '@lego/ui/form-elements'
import { AlertIcon, CircleArrows, SpinnerOffset } from '@lego/ui/icons'
import { Notification } from '@lego/ui/notification'
import { SearchableDropdownStrings } from 'libs/shared/ecommerce/components/src/lib/searchable-dropdown/searchable-dropdown'
import { FC, FormEvent, useCallback, useMemo } from 'react'

import styles from './address-info.module.css'
import { HistoricalAddressFieldset } from './historical-address-fieldset'

interface Option {
  label: string
  value: AddressesSearchResult['addresses'][number]
}

const isNotNull = <TValue,>(value: TValue | null): value is TValue => {
  return value !== null
}

export const getAddressLabel = (address: AddressDetails) => {
  const placeHolder = 'invalidValue'

  const organisationName = address.organisationName || placeHolder
  const addressLine1 = address.addressLine1 || placeHolder
  const addressLine2 = address.addressLine2 || placeHolder
  const postcode = address.postcode || placeHolder
  const country = address.country || placeHolder
  const addressNumber = address.addressNumber || placeHolder

  const rawLabel = `${organisationName}, ${addressLine1}, ${addressLine2}, ${postcode}, ${country} (${addressNumber})`

  const sanitisedLabelValue = removeWordFromString(rawLabel, placeHolder, {
    removePrecedingChar: true,
    removeTrailingChar: true,
  })

  return sanitisedLabelValue
}

interface AddressInfoOpro {
  title: string
  description: string
  addresses: (AddressDetails | null)[]
  selectedAddress?: AddressDetails | OrderAddressModel
  organisations?: OrganisationDetails[]
  onAddressSelect: (address: AddressDetails) => void
  onAddressChange: (
    address: AddressDetails | OrderAddressModel,
    isHistoricalAddress: boolean
  ) => void
  onAddressInputChange?: (value: string) => void
  onAddressMenuScrollCallback?: () => void
  onOrganisationSelect: (organisation?: OrganisationDetails) => void
  onOrganisationRefresh?: () => void
  onOrganisationMenuScrollCallback?: () => void
  selectedOrganisation?: OrganisationDetails
  addedToOrderOrganistion?: OrganisationDetails
  organisationLoading?: boolean
  organisationRefetching?: boolean
  addressesLoading?: boolean
  isValid?: boolean
  addressDropdownlabel: string
  organisationDropdownlabel: string
  organisationType: 'Ship to' | 'Bill to'
  addressDropdownStrings: Record<SearchableDropdownStrings, string>
  defaultAddress?: AddressDetails | OrderAddressModel
  forAttentionOf?: string
  onForAttentionOfChange?: (event: FormEvent<HTMLInputElement>) => void
  isVerifiedAddress?: boolean
  userInputAddress?: AddressDetails | OrderAddressModel
  isAddressDropdownPaginated?: boolean
}

export const AddressInfoOpro: FC<AddressInfoOpro> = ({
  title,
  description,
  addresses,
  selectedAddress,
  organisations = [],
  onAddressSelect,
  onAddressChange,
  onAddressInputChange,
  onAddressMenuScrollCallback,
  onOrganisationSelect,
  onOrganisationRefresh = () => null,
  onOrganisationMenuScrollCallback = () => null,
  selectedOrganisation,
  addedToOrderOrganistion,
  organisationLoading,
  organisationRefetching,
  addressesLoading,
  isValid = true,
  addressDropdownlabel,
  organisationDropdownlabel,
  organisationType,
  addressDropdownStrings,
  defaultAddress,
  forAttentionOf,
  onForAttentionOfChange,
  userInputAddress,
  isAddressDropdownPaginated,
}) => {
  const onAddressFieldsetChange = useCallback(
    (address: OrderAddressModel, isHistoricalAddress: boolean) => {
      onAddressChange(address, isHistoricalAddress)
    },
    [onAddressChange]
  )

  const notNullableOrgs = useMemo(
    () => organisations?.filter(isNotNull),
    [organisations]
  )

  const notNullableAddresses = useMemo(
    () => addresses?.filter(isNotNull),
    [addresses]
  )

  const mappedAddresses = useMemo(
    () =>
      notNullableAddresses.reduce<Option[]>((acc, cur) => {
        const label = getAddressLabel(cur)

        acc.push({ label, value: cur })

        return acc
      }, []),
    [notNullableAddresses]
  )

  const mappedSelectedAddress = useMemo(() => {
    // when address is not valid do not render its value in the dropdown label
    if (selectedAddress && selectedAddress.addressLine1) {
      const label = getAddressLabel(selectedAddress)

      return { label, value: selectedAddress }
    }

    return undefined
  }, [selectedAddress])

  const multipleOrgsDataTestId = `${organisationType
    .toLocaleLowerCase()
    .replace(' ', '-')}-multiple-organisations`

  const isCurrentAddressFromCurrentOrg =
    selectedAddress?.organisationRegistryId ===
    selectedOrganisation?.organisationRegistryId
  const shouldShowNotification =
    !isCurrentAddressFromCurrentOrg || !selectedAddress?.addressFusionId

  return (
    <article>
      <section className={styles.section}>
        <div className={styles.infoGroup}>
          {title && <h2 className={styles.subTitle}>{title}</h2>}
          {description && <p className={styles.subTitleBody}>{description}</p>}
        </div>
        <div className={styles.fieldsGroup}>
          <SimpleTextInput
            labelClassName="text-body-small"
            inputClassName="text-body-small h-12"
            wrapperClassName="my-4"
            defaultValue={forAttentionOf || ''}
            name="forAttentionOf"
            label="For attention of"
            onChange={onForAttentionOfChange}
          />
          <MultipleOrgDropdownV2
            data-testid={multipleOrgsDataTestId}
            organisationType={organisationType}
            optionClassName="text-ui-medium"
            label={organisationDropdownlabel}
            organisations={notNullableOrgs}
            onChange={onOrganisationSelect}
            selectedOrg={selectedOrganisation}
            menuListHeader={addedToOrderOrganistion}
            loading={organisationLoading}
            onMenueScrollCallback={onOrganisationMenuScrollCallback}
            disableable={false}
            className="mb-4"
          />
          <Button
            className="mb-4 ml-auto"
            iconLeft={
              !organisationLoading ? (
                <CircleArrows />
              ) : (
                <SpinnerOffset className="animate-spin" />
              )
            }
            variant="tertiaryOutline"
            size="small"
            disabled={organisationRefetching}
            onClick={onOrganisationRefresh}
          >
            Refresh organisation list
          </Button>
          <SearchableDropdown<AddressDetails>
            loading={addressesLoading}
            isValid={isValid}
            instanceId="AddressInfoV2"
            resourceStrings={addressDropdownStrings}
            onChange={onAddressSelect}
            options={mappedAddresses}
            selectedOption={mappedSelectedAddress}
            label={addressDropdownlabel}
            onInputChange={onAddressInputChange}
            menuListHeader={
              userInputAddress && getAddressLabel(userInputAddress)
            }
            menuScrollToBottomCallback={onAddressMenuScrollCallback}
            filterOption={null}
            isPaginated={isAddressDropdownPaginated}
          />
          {shouldShowNotification && (
            <Notification
              dataCy="notification-sales-cloud"
              className="mt-5 font-normal"
              variant="negative"
            >
              {`Please save new ${title.toLocaleLowerCase()} addresses in Sales Cloud and refresh address list to use with this order.`}
            </Notification>
          )}
          <HistoricalAddressFieldset
            onAddressChange={onAddressFieldsetChange}
            address={defaultAddress}
            addresses={notNullableAddresses}
            pageSubType={
              organisationType === 'Ship to'
                ? PageSubtype.SHIPPING_DETAILS
                : PageSubtype.BILLING_DETAILS
            }
          />
          {shouldShowNotification && (
            <Notification
              dataCy="notification-sales-cloud"
              className="w-2/3 mt-5 ml-auto mr-0 font-normal"
              variant="negative"
              size="small"
              icon={<AlertIcon />}
            >
              <span className="text-body-x-small">Unverified address</span>
            </Notification>
          )}
        </div>
      </section>
    </article>
  )
}
