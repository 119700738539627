import { createContext } from 'react'
//* This context is used to track the state of the form
//* isDirty: tracks if the form has been changed
//* submissionError: tracks if the form sumbitting Promise failed
//* isSubmissionAttempted: tracks if the form has been submitted

interface FormContextProps {
  isDirty: boolean
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>

  submissionError: Error | null
  setSubmissionError: React.Dispatch<React.SetStateAction<Error | null>>

  isSubmissionAttempted: boolean
  setIsSubmissionAttempted: React.Dispatch<React.SetStateAction<boolean>>

  isSubmitting: boolean
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>
}

const FormContext = createContext<FormContextProps>({
  isDirty: false,
  setIsDirty: () => false,

  submissionError: null,
  setSubmissionError: () => '',

  isSubmissionAttempted: false,
  setIsSubmissionAttempted: () => false,

  isSubmitting: false,
  setIsSubmitting: () => false,
})

export { FormContext }
