import classNames from 'classnames'
import React, { useCallback } from 'react'
import { twMerge } from 'tailwind-merge'
import { forwardRef } from 'react'
import { FormContext } from './formContext'

interface Props extends Omit<React.HTMLProps<HTMLFormElement>, 'onSubmit'> {
  children: React.ReactNode
  className?: string
  onSubmit?: (data: FormData) => Promise<void> | void
}

const Form = forwardRef<HTMLFormElement, Props>(
  (
    { action, children, className, onSubmit, onChange, ...rest }: Props,
    ref
  ) => {
    const [isDirty, setIsDirty] = React.useState(false)
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [isSubmissionAttempted, setIsSubmissionAttempted] =
      React.useState(false)
    const [submissionError, setSubmissionError] = React.useState<Error | null>(
      null
    )

    const cn = classNames(
      twMerge('flex flex-col text-body-xmedium', className),
      {
        formSubmitted: isSubmissionAttempted,
      }
    )

    const handleChange = useCallback(
      (e: React.FormEvent<HTMLFormElement>) => {
        setIsDirty(true)
        setSubmissionError(null)
        onChange?.(e)
      },
      [onChange]
    )

    const handleSubmit = useCallback(
      (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        const formElement = e.target as HTMLFormElement
        setIsSubmissionAttempted(true)
        setIsSubmitting(true)

        const isValid = formElement.checkValidity()

        // moving focus to the first invalid field
        const firstInvalidField = formElement.querySelector(
          ':invalid:not(fieldset)'
        ) as HTMLInputElement

        firstInvalidField?.focus()

        // submit the dataObject if isValid===true
        if (isValid && onSubmit) {
          setSubmissionError(null)
          const dataObject = new FormData(formElement)
          // onSubmit function should return a promise
          const SubmitPromise = Promise.resolve(onSubmit(dataObject))

          SubmitPromise.then(() => {
            setIsDirty(false)
          })
            .catch((error) => {
              setSubmissionError(error)
            })
            .finally(() => {
              setIsSubmitting(false)
            })
        } else {
          setIsSubmitting(false)
        }
      },
      [onSubmit]
    )

    return (
      <FormContext.Provider
        value={{
          isDirty,
          setIsDirty,
          submissionError,
          setSubmissionError,
          isSubmissionAttempted,
          setIsSubmissionAttempted,
          isSubmitting,
          setIsSubmitting,
        }}
      >
        <form
          ref={ref}
          aria-label="form"
          {...rest}
          onSubmit={handleSubmit}
          onChange={handleChange}
          noValidate
          className={cn}
        >
          {children}
        </form>
      </FormContext.Provider>
    )
  }
)

export { Form }
