import { getCurrentUserCS } from '@abcam-web/auth-shared/aws-cognito'
import { UserContext } from '@abcam-web/auth-shared/contexts/user'
import { useEffect, useMemo, useRef, useState } from 'react'
import type { FC, PropsWithChildren } from 'react'
import type { User } from '@abcam-web/auth-shared/contexts/user'
import { restoreTokensFromQueryParams } from '@abcam-web/shared/utilities/auth'
import { useRouter } from 'next/router'
import { useSearchParams } from 'next/navigation'
import getConfig from 'next/config'
import { STAGE } from '@abcam-web/auth-shared/constants/stage-type'

function useIsDisposed() {
  const disposed = useRef(false)

  useEffect(() => {
    return () => {
      disposed.current = true
    }
  }, [])

  return disposed
}

export const UserProvider: FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<User | null | undefined>(undefined)
  const [isProcessedTokenFromUrl, setIsProcessedTokenFromUrl] = useState(false)
  const disposed = useIsDisposed()
  const stage = getConfig().publicRuntimeConfig?.STAGE as STAGE
  const router = useRouter()
  const searchParams = useSearchParams()

  useEffect(() => {
    restoreTokensFromQueryParams(router, searchParams, stage, () => {
      setIsProcessedTokenFromUrl(true)
    })
  }, [router, searchParams, stage])

  useEffect(() => {
    async function fetchUser() {
      try {
        const data = await getCurrentUserCS()
        if (!disposed.current) {
          setUser(data)
        }
      } catch (error) {
        console.error(error)
      }
    }

    if (isProcessedTokenFromUrl) {
      fetchUser()
    }
  }, [isProcessedTokenFromUrl])

  const context = useMemo(
    () => ({ user, setUser, isProcessedTokenFromUrl }),
    [user, isProcessedTokenFromUrl]
  )

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>
}

UserProvider.displayName = 'UserProvider'
