/* eslint-disable jsx-a11y/interactive-supports-focus */
import { ListItem } from './listItem'
import {
  isArrowDownEvent,
  isArrowUpEvent,
  isEnterEvent,
  isEscapeEvent,
  isSpaceEvent,
  isTabEvent,
  keyboardVerticalNavigationHandler,
  isClickEvent,
} from '@lego/utilities'
import { useClickOutside } from '@lego/hooks/click-outside'
import { ChevronDown, ChevronUp } from '@lego/ui/icons'
import classnames from 'classnames'
import { FC, LegacyRef, useCallback, useEffect, useRef, useState } from 'react'
import { CUSTOM_INPUT_ID, DEFAULT_MAX_OPTIONS } from './constants'
import {
  SELECT_JUSTIFY,
  SELECT_SIZE,
  SELECT_VARIANT,
  SelectOption,
  SelectProps,
} from './select.type'

import styles from './select.module.css'

const getPreselectedOrFirstOption = (options: SelectOption[]) => {
  let selectedIndex = 0
  const preselected = options.filter((e, index) => {
    if (e?.isPreselected) {
      selectedIndex = index
      return true
    }
    return false
  })[0]
  return { value: preselected || options[0], index: selectedIndex }
}

const Select: FC<SelectProps> = (props) => {
  const containerRef = useRef<HTMLDivElement>()
  const numberInputRef = useRef<HTMLInputElement>()

  const {
    options = [],
    onChange,
    onInputChange = () => null,
    onNumberInputToggle = () => null,
    maxOptions,
    disabled = false,
    dark = false,
    variant = SELECT_VARIANT.primary,
    disableMaxOption = false,
    fullWidth = false,
    size = SELECT_SIZE.small,
    justifyContent = SELECT_JUSTIFY.spaceBetween,
    inputId,
    testTag,
    className = '',
    isExpanded = false,
    listMaxHeight,
    ulClassName,
    selectedValueContainerClassName = '',
    hasError = false,
    errorText,
    position = 'right',
  } = props

  const preselected = getPreselectedOrFirstOption(options)
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(
    preselected.index
  )

  useEffect(() => {
    setSelectedValue(preselected.value)
  }, [options, preselected.value])

  const [isItemListVisible, setIsItemListVisible] = useState(false)
  const [isNumberInputVisible, setIsNumberInputVisible] = useState(false)
  const [hoverOnSelect, setHoverOnSelect] = useState(false)
  const [selectedValue, setSelectedValue] = useState(preselected.value)

  const maxNumberOfOptions = disableMaxOption
    ? options.length
    : maxOptions || DEFAULT_MAX_OPTIONS
  const isDataSetBiggerThanLimit = options.length > maxNumberOfOptions

  const lastSelectableValue = isDataSetBiggerThanLimit
    ? options[maxNumberOfOptions]?.key
    : options[options.length - 1]?.key

  const defaultInput =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    Number(selectedValue.key!) > maxNumberOfOptions
      ? selectedValue.key
      : maxNumberOfOptions

  // Callback to set the item of the selected index when the value is selected
  // So that the value can be highlighted in the dropdown. If the value is not in the range of options
  // Set it to zero
  useEffect(() => {
    if (
      selectedValue.key &&
      lastSelectableValue &&
      selectedValue.key > lastSelectableValue &&
      isDataSetBiggerThanLimit
    ) {
      return setSelectedItemIndex(0)
    }
    const newSelectedIndex = options.findIndex(
      (val: any) => val?.key === selectedValue?.key
    )
    setSelectedItemIndex(newSelectedIndex)
  }, [isDataSetBiggerThanLimit, lastSelectableValue, options, selectedValue])

  // Common logic when the select is closed, both after selecting a value and hitting escape
  // Close the list
  // Close the input
  // Reset the selected index to the selected value
  // Set the focus
  const closeSelectComponentCallback = useCallback(() => {
    containerRef?.current?.focus()
    isItemListVisible && setIsItemListVisible(false)
    isNumberInputVisible && setIsNumberInputVisible(false)
  }, [isItemListVisible, isNumberInputVisible])

  useEffect(() => {
    if (disabled) {
      isItemListVisible && setIsItemListVisible(false)
      isNumberInputVisible && setIsNumberInputVisible(false)
    } else {
      if (isExpanded) {
        setIsItemListVisible(true)
      }
    }
  }, [disabled, isExpanded, isItemListVisible, isNumberInputVisible])

  // Callback to be triggered when the Select option changes to be input
  useEffect(() => {
    onNumberInputToggle(isNumberInputVisible)
  }, [isNumberInputVisible, onNumberInputToggle])

  const setSelectedValueCallback = useCallback(
    (value: any) => {
      if (value.key !== selectedValue.key) {
        setSelectedValue(value)
        onChange(value)
      }
      closeSelectComponentCallback()
    },
    [selectedValue?.key, closeSelectComponentCallback, onChange]
  )

  const setSelectedValueOnInputChangeCallback = useCallback(
    (value: any) => {
      if (value.key !== selectedValue.key) {
        onInputChange(value)
      }
    },
    [selectedValue?.key, onInputChange]
  )

  const setNumberInputValue = useCallback(() => {
    if (!isNumberInputVisible) {
      return
    }
    const numberInputVal = numberInputRef?.current?.value

    if (numberInputVal === undefined) {
      return
    }

    const targetInputValue = ['', '0'].includes(numberInputVal)
      ? '1'
      : numberInputVal

    const customValue = {
      key: parseInt(targetInputValue, 10),
      displayValue: targetInputValue,
    }
    setSelectedValueCallback(customValue)
  }, [isNumberInputVisible, setSelectedValueCallback])

  const hideOnOutsideClick = useCallback(() => {
    if (disabled || hoverOnSelect) {
      return
    }
    setNumberInputValue()

    if (isItemListVisible) {
      setIsItemListVisible(false)
    }
  }, [disabled, isItemListVisible, hoverOnSelect, setNumberInputValue])

  useClickOutside(containerRef, hideOnOutsideClick)

  const toggleNumberInputDisplay = useCallback(
    (evt: any) => {
      if (isEscapeEvent(evt)) {
        closeSelectComponentCallback()
      }

      const isOpenEvent =
        isEnterEvent(evt) || isClickEvent(evt) || isTabEvent(evt)

      if (isOpenEvent) {
        setIsItemListVisible(false)
        setIsNumberInputVisible(true)
        if (defaultInput) {
          onInputChange({
            key: parseInt(defaultInput.toString(), 10),
            displayValue: defaultInput.toString(),
          })
        }
      }
    },
    [closeSelectComponentCallback, onInputChange, defaultInput]
  )

  /******** Items interaction ********/
  // Determines if the list or the number input should show
  const onContainerInteractionCallback = useCallback(
    (evt: any) => {
      if (disabled) {
        return
      }
      if (isNumberInputVisible) {
        return evt.preventDefault()
      }
      const isOpenEvent =
        isEnterEvent(evt) ||
        isSpaceEvent(evt) ||
        isArrowDownEvent(evt) ||
        isArrowUpEvent(evt) ||
        isClickEvent(evt)

      if (!isOpenEvent) {
        return
      }

      const shouldToggleNumberInput =
        selectedValue.key &&
        lastSelectableValue &&
        Number.isInteger(selectedValue?.key) &&
        Number.isInteger(lastSelectableValue) &&
        selectedValue.key > lastSelectableValue &&
        isDataSetBiggerThanLimit

      if (shouldToggleNumberInput) {
        return setIsNumberInputVisible(true)
      }

      setIsItemListVisible(!isItemListVisible)
    },
    [
      disabled,
      isDataSetBiggerThanLimit,
      isItemListVisible,
      isNumberInputVisible,
      lastSelectableValue,
      selectedValue?.key,
    ]
  )

  // Determines what to do when the user clicks/enters/escapes while a list item is focussed
  const listItemInteractionHandler = useCallback(
    (evt: any, value: any) => {
      if (isEnterEvent(evt) || isClickEvent(evt) || isTabEvent(evt)) {
        return setSelectedValueCallback(value)
      }
      if (isEscapeEvent(evt) && isItemListVisible) {
        return closeSelectComponentCallback()
      }
    },
    [isItemListVisible, closeSelectComponentCallback, setSelectedValueCallback]
  )

  // Determines what to dd when the user enters/escapes while the number input is focussed
  const numberInputKeypressHandler = useCallback(
    (evt: any) => {
      if (isEnterEvent(evt) || isEscapeEvent(evt)) {
        setNumberInputValue()
      }
    },
    [setNumberInputValue]
  )

  // Handles the up/down navigation on the list
  const listKeyboardNavigationHandler = useCallback(
    (evt: any) =>
      keyboardVerticalNavigationHandler(
        evt,
        selectedItemIndex,
        setSelectedItemIndex,
        maxNumberOfOptions - 1
      ),
    [selectedItemIndex, maxNumberOfOptions]
  )
  /***********************************/
  const classes = classnames(
    {
      [styles.select]: true,
      [styles.open]: isItemListVisible,
      [styles.inputOpen]: isNumberInputVisible,
      [styles.disabled]: disabled,
      [styles.fullWidth]: fullWidth,
      [styles.dark]: dark,
    },
    styles[variant],
    styles[size],
    className
  )

  return (
    <div className={classes} data-testid={testTag}>
      <div
        ref={containerRef as LegacyRef<HTMLDivElement>}
        onMouseEnter={() => setHoverOnSelect(true)}
        onMouseLeave={() => setHoverOnSelect(false)}
        tabIndex={0}
        data-testid="selected-value-container"
        className={classnames(
          {
            [styles.selectedValueContainer]: !isNumberInputVisible,
            [styles.customInputContainer]: isNumberInputVisible,
            [styles.center]: justifyContent === SELECT_JUSTIFY.center,
            [styles.evenly]: justifyContent === SELECT_JUSTIFY.evenly,
            [styles.spaceBetween]:
              justifyContent === SELECT_JUSTIFY.spaceBetween,
            [styles.spaceAround]: justifyContent === SELECT_JUSTIFY.spaceAround,
            'shadow-interactiveElementError': hasError,
          },
          selectedValueContainerClassName
        )}
        role="button"
        onClick={onContainerInteractionCallback}
        onKeyUp={onContainerInteractionCallback}
      >
        {isNumberInputVisible && (
          <input
            onBlur={() => {
              setHoverOnSelect(false)
            }}
            onChange={(e) => {
              const targetValue = ['', '0'].includes(e.target.value)
                ? '1'
                : e.target.value
              const customValue = {
                key: parseInt(targetValue, 10),
                displayValue: targetValue,
              }
              setSelectedValueOnInputChangeCallback(customValue)
            }}
            autoFocus // eslint-disable-line jsx-a11y/no-autofocus
            placeholder={`${maxNumberOfOptions} +`}
            defaultValue={defaultInput}
            type="number"
            onKeyUp={numberInputKeypressHandler}
            id={inputId || CUSTOM_INPUT_ID}
            ref={numberInputRef as LegacyRef<HTMLInputElement>}
            disabled={disabled}
          />
        )}
        {!isNumberInputVisible && selectedValue?.displayValue}
        {!isNumberInputVisible && !isItemListVisible && (
          <ChevronDown
            aria-label="Open select"
            className="pointer-events-none"
          />
        )}
        {isItemListVisible && <ChevronUp aria-label="Close select" />}
      </div>
      <div
        className={classnames(styles.content, {
          'right-0': position === 'left',
          'left-0': position === 'right',
        })}
        onMouseEnter={() => setHoverOnSelect(true)}
        onMouseLeave={() => setHoverOnSelect(false)}
        role={'button'}
      >
        {isItemListVisible && (
          <ul
            className={classnames({ [`${ulClassName}`]: !!ulClassName })}
            style={{ maxHeight: listMaxHeight }}
            onKeyUp={listKeyboardNavigationHandler}
            role="presentation"
          >
            {options.map((val, idx) => {
              if (!isDataSetBiggerThanLimit || idx < maxNumberOfOptions - 1) {
                return (
                  <ListItem
                    testTag={`select-list-item-${val?.key}`}
                    tabNavigation={false}
                    autofocus={idx === selectedItemIndex}
                    clickCallback={listItemInteractionHandler}
                    keyupCallback={listItemInteractionHandler}
                    value={val}
                    key={val?.key}
                    isPlaceholder={val?.isPlaceholder}
                  />
                )
              }

              if (isDataSetBiggerThanLimit && idx === maxNumberOfOptions - 1) {
                return (
                  <ListItem
                    testTag={`select-list-item-${val?.key}`}
                    tabNavigation={false}
                    autofocus={idx === selectedItemIndex}
                    clickCallback={toggleNumberInputDisplay}
                    keyupCallback={toggleNumberInputDisplay}
                    value={{
                      key: val?.key,
                      displayValue: `${options[idx].displayValue} +`,
                    }}
                    key={val?.key}
                  />
                )
              } else {
                return null
              }
            })}
          </ul>
        )}
      </div>
      {hasError && errorText && (
        <div className={styles.errorMessage}>
          <p data-cy="error-text">{errorText}</p>
        </div>
      )}
    </div>
  )
}

export { Select }
