import { saveTokensInLocalStorage } from './authLocalStorageUtils'
import type { STAGE } from '@abcam-web/auth-shared/constants/stage-type'
import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'

/**
 * if user is on local or dev and if we have serializedTokens in query params
 * then use the values in serializedTokens as user's auth tokens
 * This is needed for developers who needs to work on different ports and different subdomains
 */
export const restoreTokensFromQueryParams = async (
  router: ReturnType<typeof useRouter>,
  searchParams: ReturnType<typeof useSearchParams>,
  stage: STAGE,
  callback: () => void
) => {
  try {
    if (
      ['local', 'dev'].includes(stage) &&
      searchParams.get('serializedTokens')
    ) {
      const tokens = JSON.parse(
        decodeURIComponent(searchParams.get('serializedTokens') as string)
      )
      console.log('Restoring tokens from query parameters')
      saveTokensInLocalStorage(stage, tokens)

      // remove token from query params
      const { serializedTokens, ...rest } = router.query
      await router.replace(
        { pathname: router.pathname, query: rest },
        undefined,
        {
          shallow: true,
        }
      )
    }
  } catch (err) {
    console.error('Error while restoring tokens from url', err)
  }
  callback()
}
