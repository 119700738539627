import {
  BeckmanCoulterIcon,
  IdbsIcon,
  LeicaIcon,
  MolecularDevicesIcon,
  PhenomenexIcon,
  SciexIcon,
  AldevronIcon,
  IdtIcon,
  FacebookIcon,
  LinkedinIcon,
  XIcon,
  YoutubeIcon,
  BilibiliIcon,
  XiaohongshuIcon,
  GenedatIcon,
} from '@abcam-web/lego/ui/icons'
import type { ReactNode } from 'react'

/* types */
type OpCoLink = {
  url: string
  icon: ReactNode
  name: string
}

type SocialMediaLink = {
  ariaLabel: string
  href: string
  icon: ReactNode
  dataTestId?: string
}

type LegalLink = {
  href: string
  formatMessageId: string
  disableOn?: string[]
}

type NavigationLink = {
  dataTestId: string
  href: string
  formatMessageId: string
  disableOnCN?: boolean
}

type CnLegalInfo = {
  href: string
  formatMessageId: string
  className?: string
  dataTestId?: string
}

/* Default footer */
export const opCos: OpCoLink[] = [
  {
    url: 'https://www.mybeckman.com?utm_source=abcam_website&utm_medium=referral&utm_content=footer',
    icon: <BeckmanCoulterIcon fill="white" />,
    name: 'Beckman Coulter Link',
  },
  {
    url: 'https://www.genedata.com/?utm_source=abcam_website&utm_medium=referral&utm_content=footer',
    icon: <GenedatIcon fill="white" />,
    name: 'Genedata Link',
  },
  {
    url: 'https://www.idbs.com/?utm_source=abcam_website&utm_medium=referral&utm_content=footer',
    icon: <IdbsIcon fill="white" />,
    name: 'IDBS Link',
  },
  {
    url: 'https://www.leica-microsystems.com/?utm_source=abcam_website&utm_medium=referral&utm_content=footer',
    icon: <LeicaIcon fill="white" />,
    name: 'Leica Microsystems Link',
  },
  {
    url: 'https://www.moleculardevices.com/?utm_source=abcam_website&utm_medium=referral&utm_content=footer',
    icon: <MolecularDevicesIcon fill="white" />,
    name: 'Molecular Devices Link',
  },
  {
    url: 'https://www.phenomenex.com/?utm_source=abcam_website&utm_medium=referral&utm_content=footer',
    icon: <PhenomenexIcon fill="white" />,
    name: 'Phenomenex Link',
  },
  {
    url: 'https://sciex.com/?utm_source=abcam_website&utm_medium=referral&utm_content=footer',
    icon: <SciexIcon fill="white" />,
    name: 'Sciex Link',
  },
  {
    url: 'https://www.aldevron.com/?utm_source=abcam_website&utm_medium=referral&utm_content=footer',
    icon: <AldevronIcon fill="white" />,
    name: 'Aldevron Link',
  },
  {
    url: 'https://www.idtdna.com/?utm_source=abcam_website&utm_medium=referral&utm_content=footer',
    icon: <IdtIcon fill="white" />,
    name: 'Integrated DNA Technologies Link',
  },
]

const commonSocialMediaLinks: SocialMediaLink[] = [
  {
    ariaLabel: 'Facebook link',
    dataTestId: 'facebook',
    href: 'https://www.facebook.com/abcam',
    icon: <FacebookIcon fill="white" />,
  },
  {
    ariaLabel: 'LinkedIn link',
    dataTestId: 'linkedin',
    href: 'https://www.linkedin.com/company/abcam',
    icon: <LinkedinIcon fill="white" />,
  },
  {
    ariaLabel: 'Youtube link',
    dataTestId: 'youtube',
    href: 'https://www.youtube.com/channel/UC17cyB7OIeKqTCUAPE8ZhFQ',
    icon: <YoutubeIcon fill="white" />,
  },
]

export const socialMediaLinks: SocialMediaLink[] = [
  ...commonSocialMediaLinks,
  {
    ariaLabel: 'X link',
    dataTestId: 'x',
    href: 'https://x.com/abcam',
    icon: <XIcon fill="white" />,
  },
]

export const jpSocialMediaLinks: SocialMediaLink[] = [
  ...commonSocialMediaLinks,
  {
    ariaLabel: 'X link',
    dataTestId: 'x',
    href: 'https://x.com/abcamjp',
    icon: <XIcon fill="white" />,
  },
]

export const helpSupportLinks: NavigationLink[] = [
  {
    dataTestId: 'contact',
    href: '/contact-us',
    formatMessageId: 'footer.contactUs',
  },
  {
    dataTestId: 'protocols',
    href: '/technical-resources/protocols',
    formatMessageId: 'footer.protocols',
  },
  {
    dataTestId: 'troubleshooting',
    href: '/technical-resources/troubleshooting',
    formatMessageId: 'footer.troubleshooting',
  },
]

export const abcamLinks: NavigationLink[] = [
  {
    dataTestId: 'corporate',
    formatMessageId: 'footer.corporateSite',
    href: 'https://corporate.abcam.com/',
  },
  {
    dataTestId: 'careers',
    formatMessageId: 'footer.careers',
    href: 'https://careers.abcam.com/',
  },
  {
    dataTestId: 'danaher',
    formatMessageId: 'footer.danaher',
    href: 'https://lifesciences.danaher.com/?utm_source=abcam_website&utm_medium=referral&utm_content=footer',
  },
]

export const programLinks: NavigationLink[] = [
  {
    dataTestId: 'promise',
    formatMessageId: 'footer.productPromise',
    href: '/offers-and-initiatives/product-promise',
  },
  {
    dataTestId: 'reviews',
    formatMessageId: 'footer.productReviews',
    href: '/offers-and-initiatives/product-reviews',
  },
  {
    dataTestId: 'trial',
    formatMessageId: 'footer.trialProgram',
    href: '/offers-and-initiatives/trial-program',
    disableOnCN: true,
  },
]

export const legalLinks: LegalLink[] = [
  {
    href: '/legal/privacy-policy',
    formatMessageId: 'footer.privacyPolicy',
  },
  {
    href: '/legal/cookie-policy',
    formatMessageId: 'footer.cookies',
  },
  {
    href: '/legal/website-terms-of-use',
    formatMessageId: 'footer.termsOfUse',
  },
  {
    href: '/legal/terms-and-conditions',
    formatMessageId: 'footer.termsAndConditions',
  },
  {
    href: '/legal/modern-slavery-statement',
    formatMessageId: 'footer.slaveryStatement',
    // Needs to be changed with simpler locales MR
    disableOn: ['zh-cn', 'en-cn', 'en-jp', 'ja-jp'],
  },
  {
    href: '/legal',
    formatMessageId: 'footer.legal',
  },
]

/* CN footer */
export const cnSocialMediaLinks: SocialMediaLink[] = [
  {
    ariaLabel: 'Bilibili link',
    dataTestId: 'bilibili',
    href: 'https://space.bilibili.com/1147341878?spm_id_from=333.337.search-card.all.click',
    icon: <BilibiliIcon fill="white" />,
  },
  {
    ariaLabel: 'Xiaohongshu link',
    dataTestId: 'xiaohongshu',
    href: 'https://www.xiaohongshu.com/user/profile/655c444800000000080002ad',
    icon: <XiaohongshuIcon fill="white" />,
  },
]

export const cnLegalInfo: CnLegalInfo[] = [
  {
    dataTestId: 'security-number',
    href: 'https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502007413',
    formatMessageId: 'footer.cn.securityNumber',
    className: 'xld:min-w-full',
  },
  {
    dataTestId: 'icp-licence',
    href: 'https://beian.miit.gov.cn',
    formatMessageId: 'footer.cn.icpLicence',
  },
]
