import getConfig from 'next/config'
import { FeatureToggleName } from '@feature-toggles/feature-toggle-name.enum'

import type { FeatureToggleNameMappings } from '@feature-toggles/feature-toggle.type'

export const featureToggleMappings: Partial<FeatureToggleNameMappings> = {
  [FeatureToggleName.TempBrowseAbreviews]:
    getConfig()?.publicRuntimeConfig?.TEMP_BROWSE_ABREVIEWS,
  [FeatureToggleName.TempAssistContactUs]:
    getConfig()?.publicRuntimeConfig?.TEMP_ASSIST_CONTACT_US,
  [FeatureToggleName.TempOproChangeDeliveryDateEnabled]:
    getConfig()?.publicRuntimeConfig?.TEMP_OPRO_CHANGE_DELIVERY_DATE_ENABLED,
  [FeatureToggleName.ProxyGatewayEnabled]:
    getConfig()?.publicRuntimeConfig?.FALLBACK_BROWSE_PROXY_GATEWAY_ENABLED,
  [FeatureToggleName.CrossSellPdp]:
    getConfig()?.publicRuntimeConfig?.CROSS_SELL_PDP,
  [FeatureToggleName.MultipleOrganisationsEnabled]:
    getConfig()?.publicRuntimeConfig?.FALLBACK_ECOM_MULTIPLE_ORG,
  [FeatureToggleName.EntityPageContentFetch]:
    getConfig()?.publicRuntimeConfig?.ENTITY_PAGE_CONTENT_FETCH,
  [FeatureToggleName.TempEnablePromotions]:
    getConfig()?.publicRuntimeConfig?.TEMP_ENABLE_PROMOTIONS,
  [FeatureToggleName.ContentSearch]:
    getConfig()?.publicRuntimeConfig?.CONTENT_SEARCH,
  [FeatureToggleName.NewSearchOverlay]:
    getConfig()?.publicRuntimeConfig?.NEW_SEARCH_OVERLAY,
}
