import { BUTTON_SIZE, BUTTON_VARIANT, ButtonProps } from '@lego/ui/button'
import { ReactNode, SyntheticEvent } from 'react'

export enum RESPONSIVE_BUTTON_ICON_POSITION {
  left = 'left',
  right = 'right',
}

export type ResponsiveButtonProps = {
  dark?: ButtonProps['dark']
  size?: BUTTON_SIZE | keyof typeof BUTTON_SIZE
  variant?: BUTTON_VARIANT | keyof typeof BUTTON_VARIANT
  onClick?: (evt: SyntheticEvent) => void
  disableResponsive?: boolean
  className?: string
  disabled?: boolean
  icon?: ReactNode
  iconPosition?:
    | RESPONSIVE_BUTTON_ICON_POSITION
    | keyof typeof RESPONSIVE_BUTTON_ICON_POSITION
  iconLeft?: ReactNode
  iconRight?: ReactNode
}
