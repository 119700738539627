import { Dialog, Transition } from '@headlessui/react'
import noop from 'lodash/noop'
import { PropsWithChildren, Fragment } from 'react'

interface OverlayProps {
  show?: boolean
  onClose?: () => void
}

const Overlay = ({
  show = false,
  onClose,
  children,
}: PropsWithChildren<OverlayProps>) => {
  return (
    <Transition show={show} as={Fragment}>
      <Dialog
        // initialFocus={refPanel}
        as="div"
        className="relative z-imageOverlay"
        onClose={onClose || noop}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-1000"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-400"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black-0 bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 flex items-center justify-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-50 scale-90"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-150"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-80"
          >
            <Dialog.Panel className="flex items-center justify-center w-full h-full transform transition-all">
              {children}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export { Overlay }
export type { OverlayProps }
