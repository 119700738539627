import {
  getCountrySchema,
  Schema,
  SchemaOverride,
} from '@abcam-web/address-validator'
import { PageSubtype } from '@abcam-web/shared/data-access/tracking'
import {
  addressFieldsetCountryOverrides as localisationOverride,
  addressFieldsetMessages as localisedText,
} from '@abcam-web/shared/utilities/i18n'
import {
  COUNTRY_CODES,
  LocalisationProvider,
} from '@abcam-web/shared/utilities/localisation'
import { FC, FormEvent, memo, useCallback } from 'react'

import { fieldConfig } from './data/fieldConfig'
import style from './index.module.css'
import { applySchemaOverride } from './utils/helpers'
import { DefaultValues, SchemaRenderer } from './utils/SchemaRenderer'

interface AddressFieldsetProps {
  defaultValues?: DefaultValues & {
    countryCode?: COUNTRY_CODES
  }
  className?: string
  labelClassName?: string
  inputClassName?: string
  menuClassName?: string
  schemaOverride?: SchemaOverride
  onChange?: (e: FormEvent, selectedSchema?: Schema) => void
  pageSubType: PageSubtype
}

const AddressFieldset: FC<AddressFieldsetProps> = memo(
  function AddressFielsSet({
    defaultValues,
    className,
    labelClassName,
    inputClassName,
    menuClassName,
    schemaOverride,
    onChange,
  }) {
    let defaultCountryCode: COUNTRY_CODES | undefined = undefined
    if (defaultValues?.countryCode || defaultValues?.country) {
      defaultCountryCode = defaultValues.country
        ? (defaultValues.country as COUNTRY_CODES)
        : (defaultValues.countryCode as COUNTRY_CODES)
    }
    const { countryCode: _, ...rest } = defaultValues || {}

    const selectedSchema = getCountrySchema(defaultCountryCode)

    if (schemaOverride) {
      applySchemaOverride(schemaOverride, selectedSchema)
    }

    const onChangeHandler = useCallback(
      (e: FormEvent) => {
        if ((e.target as HTMLInputElement).name === 'country') {
          // pass schema to be used for matching addresses
          const countrySchema = getCountrySchema(
            (e.target as HTMLInputElement).value as COUNTRY_CODES
          )
          if (schemaOverride) {
            applySchemaOverride(schemaOverride, countrySchema)
          }
          onChange && onChange(e, countrySchema)
        } else {
          onChange && onChange(e)
        }
      },
      [onChange, schemaOverride]
    )

    return (
      <LocalisationProvider
        translations={localisedText}
        translationsOverrides={localisationOverride}
        countryCodeOverride={defaultCountryCode}
      >
        <fieldset
          className={`${style.wrapper} ${className}`}
          onChange={onChangeHandler}
        >
          <SchemaRenderer
            key={defaultCountryCode}
            countryCode={defaultCountryCode}
            schema={selectedSchema}
            fieldConfig={fieldConfig}
            labelClassName={labelClassName}
            inputClassName={inputClassName}
            menuClassName={menuClassName}
            defaultValues={{ ...rest, country: defaultCountryCode }}
          />
        </fieldset>
      </LocalisationProvider>
    )
  }
)

export { AddressFieldset }
export type { AddressFieldsetProps }
