import type {
  Product,
  LocalisedName,
} from '@abcam-web/search/public/utilities/product.type'

export const resolveProductName = (product: Product, locale?: string) => {
  const lang = (locale ? locale.split('-')[0] : 'en') as keyof LocalisedName

  return product.localisedName &&
    product.localisedName[lang] &&
    product.localisedName[lang] !== ''
    ? product.localisedName[lang]
    : product.name
}
