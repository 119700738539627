import { useCallback, useEffect, useRef } from 'react'
import { isFinite } from 'lodash'
import { tags } from '@tagging-shared/browse'
import { useSearch } from '@abcam-web/search/utilities'
import { MagnifierIcon, Plus } from '@lego/ui/icons'
import { useResizeObserver } from '@lego/hooks/resize-observer'
import {
  useGtm,
  getSearchOverlayContext,
  LocationID,
  getSearchOverlayPageTypes,
} from '@abcam-web/shared/data-access/tracking'
import { useRouter } from 'next/router'
import { useCountry } from '@abcam-web/shared/utilities/country'
import type { FacetInput } from '@abcam-web/shared/data-access/search-schema'
import type { RefObject } from 'react'

type SearchInputProps = {
  placeHolder?: string
  cyTag?: string
  isCompactMode?: boolean
  className?: string
}

const showMoreLozengeId = 'show-more-lozenge'
const totalElementWidth = (el: Element) => {
  const computedStyle = window.getComputedStyle(el)
  const leftMargin = parseInt(computedStyle.marginLeft, 10)
  const rightMargin = parseInt(computedStyle.marginRight, 10)
  // `offsetWidth` is not a member of SVGElement
  let elementWidth = (el as HTMLElement).offsetWidth || el.clientWidth
  if (!isFinite(elementWidth)) {
    elementWidth = 0
    console.warn('Width could not be determined for element', el)
  }
  return leftMargin + rightMargin + elementWidth
}

const createShowMoreLozenge = () => {
  const showMoreElement = document.createElement('div')
  showMoreElement.className = `
        ${showMoreLozengeId}
        bg-white rounded-full
        px-0 py-0
        w-8 h-8
        font-bold text-xl md:text-3xl text-center text-black-0
        leading-1.15 items-center justify-center inline-flex
      `
  showMoreElement.setAttribute('role', 'listitem')
  showMoreElement.setAttribute('data-cy', showMoreLozengeId)
  showMoreElement.innerHTML = '&middot;&middot;&middot;'
  return showMoreElement
}

const redrawSearchbar = (
  containerRef: RefObject<HTMLButtonElement>,
  selectedFacets: FacetInput[]
) => {
  // XXX This effect manipulates the DOM elements directly.  This should
  // not break the VDOM's mutations as long as the number of elements in the
  // DOM is equal to or greater than the number in the VDOM by the end of
  // this function.
  const lozengesContainer = containerRef.current?.children[1]
  // Satisfy type checker by converting type from HTMLCollection to HTMLElement(?)[]
  const lozenges =
    Array.prototype.slice.call(lozengesContainer?.children, 0) ?? []
  const searchIcon = containerRef.current?.children[0]

  if (!containerRef.current || !searchIcon) {
    return
  }

  if (lozengesContainer && lozenges) {
    let totalVisibleLozenges = 0
    let totalLozengeWidth = 0

    // We need to measure the show-more element's width so we can exclude it from
    // the total available width.  Therefore, add it if it's not present.
    let showMoreElement = lozengesContainer.querySelector(
      `.${showMoreLozengeId}`
    )
    if (!showMoreElement) {
      showMoreElement = createShowMoreLozenge()
      lozengesContainer.appendChild(showMoreElement)
    }

    const availableWidth =
      containerRef.current?.clientWidth -
      totalElementWidth(searchIcon) * 2 -
      totalElementWidth(showMoreElement)

    // Remove show-more lozenge from list to prevent it from intefering with
    // calculating how many lozenges we can show
    showMoreElement.remove()

    for (
      let idx = 0, el = lozenges[idx];
      idx < lozenges.length;
      ++idx, el = lozenges[idx]
    ) {
      el.style.display = ''
      totalLozengeWidth += totalElementWidth(el)
      if (totalLozengeWidth < availableWidth) {
        ++totalVisibleLozenges
      } else {
        break
      }
    }

    if (totalVisibleLozenges < selectedFacets.length) {
      const elementsToHide = Array.prototype.slice.call(
        lozenges,
        totalVisibleLozenges
      )
      elementsToHide.forEach((el) => {
        el.style.display = 'none'
      })
      // Show-more lozenge is required by the UI this time, so put it back.
      const showMoreElement = createShowMoreLozenge()
      lozengesContainer.appendChild(showMoreElement)
    }
  }
}

export const LaunchSearchOverlayButtonWithSearchInfo = ({
  placeHolder = '',
  cyTag = '',
  className = '',
}: SearchInputProps) => {
  const search = useSearch()
  const { asPath } = useRouter()
  const { countryName } = useCountry()
  const gtm = useGtm()
  const { send, inputValue, selectedFacets } = search

  const containerRef = useRef<HTMLButtonElement>(null)

  const hasSelectedFacets = selectedFacets.length > 0
  const showSeparator = hasSelectedFacets

  const redrawSearchbarCallback = useCallback(
    () => redrawSearchbar(containerRef, selectedFacets),
    [containerRef, selectedFacets]
  )

  useResizeObserver(containerRef, redrawSearchbarCallback)
  useEffect(redrawSearchbarCallback)

  return (
    <button
      aria-label="See all or edit search"
      className={`
        appearance-none space-x-2 cursor-pointer text-left
        border border-solid border-white
        focus:border-opacity-100 focus:border-blue-40
        ring-blue-40 focus:ring-2
        outline-none
        rounded-full w-full h-[50px]
        smd:w-[34px] smd:h-[34px]
        flex flex-grow items-center
        overflow-hidden
        ${className}
      `}
      data-cy={tags.homePage.searchWrapper}
      data-testid="launch-search-overlay"
      onClick={(evt) => {
        evt.stopPropagation()
        const { pageType, pageSubType } = getSearchOverlayPageTypes(asPath)
        gtm.track(
          getSearchOverlayContext({
            click_id: 'search overlay',
            location_id: LocationID.PAGE,
            page_type: pageType,
            page_subtype: pageSubType,
            page_path: asPath,
            item_cta: '',
            country: countryName,
          })
        )
        send('ACTIVATE')
      }}
      ref={containerRef}
      type="button"
    >
      <MagnifierIcon
        className={`
          ml-4 mr-1 smd:mx-auto
          w-5 min-w-5 smd:min-w-4 smd:w-4
          fill-white
        `}
        data-testid="search-input-magnifier"
      />

      <ul
        className={`
          ${hasSelectedFacets ? 'flex' : 'hidden'}
          opacity-40
          content-start justify-start items-center
          py-2 space-x-2
          smd:hidden
        `}
      >
        {selectedFacets.map(({ label }, index) => {
          return (
            <li
              className={`
              inline-block whitespace-nowrap
              overflow-ellipsis max-w-360px overflow-hidden
              rounded-32px py-2 px-4
              col-rule-stroke-darkbg-medium
              text-black-0 leading-1.15
              bg-white text-ui-medium
            `}
              data-cy={tags.commonTags.searchLozenge}
              key={`${index} ${label}`}
            >
              {label}
            </li>
          )
        })}
      </ul>

      {showSeparator && (
        <div className="my-3 min-w-5 min-h-5 mdu:my-0 smd:hidden">
          <Plus
            aria-hidden={true}
            className="text-white fill-current max-w-5"
            data-testid="search-input-separator"
          />
        </div>
      )}

      <div className="z-10 flex flex-col self-stretch flex-grow mdu:relative smd:hidden">
        <span
          className="my-auto font-semibold text-heading-xx-small whitespace-nowrap"
          data-cy={cyTag}
        >
          {inputValue || (hasSelectedFacets ? '' : placeHolder)}
        </span>
      </div>
    </button>
  )
}
