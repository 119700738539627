import {
  AssetModel,
  Price,
} from '@abcam-web/shared/data-access/ecommerce-schema'
import { useFormattedPrice } from '@abcam-web/shared/ecommerce/utilities'
import { FC } from 'react'
import { useIntl } from 'react-intl'

export enum DataTestIds {
  ASSET_SINGLE_PRICE = 'asset-single-price',
  BASE_PRICE = 'base-price',
  ADJUSTMENT_PRICE = 'adjustment-price',
  TAX_MESSAGE = 'tax-message',
}
export interface AssetSinglePriceProps {
  quantity: number
  prices?: AssetModel['prices']
}

export interface PriceWithDiscountProps {
  priceWithoutDiscount: string
  priceWithDiscount: string
}

export interface PriceWithoutDiscountProps {
  priceWithoutDiscount: string
}

const PriceWithDiscount: FC<PriceWithDiscountProps> = ({
  priceWithoutDiscount,
  priceWithDiscount,
}) => {
  const { formatMessage } = useIntl()
  return (
    <>
      <div
        data-testid={DataTestIds.BASE_PRICE}
        className="mb-1 text-heading-medium"
      >
        <span className="text-body-xmedium text-grey-20">
          {formatMessage({id: 'asset.price.prefix'})}
        </span>
        <span className="line-through text-grey-20 text-body-large">
          {priceWithoutDiscount}
        </span>
      </div>
      <div data-testid={DataTestIds.ADJUSTMENT_PRICE} className="mb-1">
        <span className="text-body-xmedium">
          {formatMessage({id: 'asset.discount.prefix'})}
        </span>
        <span className="font-semibold text-heading-medium">
          {priceWithDiscount}
        </span>
      </div>
    </>
  )
}

const PriceWithoutDiscount: FC<PriceWithoutDiscountProps> = ({
  priceWithoutDiscount,
}) => (
  <div
    data-testid={DataTestIds.BASE_PRICE}
    className="mb-1 text-heading-medium"
  >
    <span className="font-semibold text-heading-medium">
      {priceWithoutDiscount}
    </span>
  </div>
)

export const AssetSinglePrice: FC<AssetSinglePriceProps> = ({
  quantity,
  prices,
}) => {
  const currency = prices?.unitPriceWithoutDiscount?.currency
  const { formatMessage } = useIntl()
  const priceWithoutDiscount = useFormattedPrice({
    currency,
    value: (prices?.unitPriceWithoutDiscount?.value as number) * quantity,
  } as unknown as Price)

  const priceWithDiscount = useFormattedPrice({
    value: (prices?.unitPriceWithDiscount?.value as number) * quantity,
    currency,
  } as unknown as Price)

  const taxMessage = prices?.tax?.includedInPrice
    ? `(${formatMessage({ id: 'asset.taxMessage.incl' })})`
    : `(${formatMessage({ id: 'asset.taxMessage.excl' })})`

  const hasAdjustmentPrice = priceWithoutDiscount !== priceWithDiscount

  return (
    <div
      data-testid={DataTestIds.ASSET_SINGLE_PRICE}
      className="mt-4 text-heading-medium"
    >
      {hasAdjustmentPrice ? (
        <PriceWithDiscount
          priceWithoutDiscount={priceWithoutDiscount}
          priceWithDiscount={priceWithDiscount}
        />
      ) : (
        <PriceWithoutDiscount priceWithoutDiscount={priceWithoutDiscount} />
      )}
      <div data-testid={DataTestIds.TAX_MESSAGE} className="text-body-xmedium">
        {taxMessage}
      </div>
    </div>
  )
}
