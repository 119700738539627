import { CSSProperties } from 'react'

import { Direction, JustifyContent } from './types'

export const justifyContent: Record<JustifyContent, CSSProperties['flex']> = {
  normal: 'justify-normal',
  start: 'justify-start',
  end: 'justify-end',
  center: 'justify-center',
  between: 'justify-between',
  around: 'justify-around',
  evenly: 'justify-evenly',
  stretch: 'justify-stretch',
}

export const directions: Record<Direction, string> = {
  row: 'flex-row',
  rowReverse: 'flex-row-reverse',
  column: 'flex-col',
  columnReverse: 'flex-col-reverse',
}
