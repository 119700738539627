import { signIn } from './src/login'
import { register } from './src/register'
import { forgotPassword } from './src/forgot-password'
import { oneTimePassword } from './src/one-time-password'
import { thankYou } from './src/thank-you'
import { changePassword } from './src/change-password'
import { passwordValidation } from './src/password-validation'
import { checkList } from './src/check-list'

export const tags = {
  signIn,
  register,
  forgotPassword,
  oneTimePassword,
  thankYou,
  changePassword,
  passwordValidation,
  checkList,
}
