import {
  PurchaseType,
  PurchaseTypeEnum,
} from '@abcam-web/shared/data-access/ecommerce-schema'
import { useShoppingExperience } from '@abcam-web/shared/ecommerce/utilities'
import { Button } from '@lego/ui/button'
import { CheckIcon, CrossIcon } from '@lego/ui/icons'
import { FC } from 'react'
import { useIntl } from 'react-intl'

import styles from './add-product-confirmation.module.css'

export interface AddProductConfirmationProps {
  onClose: () => void
  onContactDistributor: () => void
  onShoppingBasket: () => void
  onGoToCheckoutOrSignIn: () => void
  purchaseType: PurchaseType
  isLogged: boolean
  isPunchout: boolean
}

const AddProductConfirmation: FC<AddProductConfirmationProps> = ({
  onClose,
  onContactDistributor,
  onShoppingBasket,
  onGoToCheckoutOrSignIn,
  purchaseType,
}) => {
  const distributorFlow = purchaseType === PurchaseTypeEnum.Distributor
  const mixedMarket = purchaseType === PurchaseTypeEnum.Mixed
  const { ui } = useShoppingExperience()
  const { formatMessage } = useIntl()
  return (
    <aside className="h-full text-black-0">
      <div className="flex mb-5 text-blue-0 text-body-medium">
        <CheckIcon className={styles.ico} />
        {` ${formatMessage({
          id: distributorFlow
            ? 'buyBox.addConfirmationModal.success.distributor'
            : 'buyBox.addConfirmationModal.success.nonDistributor',
        })}`}
        <CrossIcon onClick={onClose} className="ml-auto cursor-pointer" />
      </div>
      <div className="mb-10 font-normal">
        {formatMessage({ id: 'buyBox.addConfirmationModal.next.message' })}
      </div>
      <div className="flex justify-between my-3 smd:flex-col">
        <div className="my-2 smu:w-1/2 smu:mr-5">
          <Button
            size="medium"
            fullWidth
            variant="tertiaryOutline"
            dataCy="view-basket"
            onClick={onShoppingBasket}
          >
            {formatMessage({
              id: 'buyBox.addConfirmationModal.buttons.toBasket',
            })}
          </Button>
        </div>
        {distributorFlow ? (
          <div className="my-2 smu:w-1/2">
            <Button
              variant="primary"
              fullWidth
              size="medium"
              dataCy="contact-distributor"
              onClick={onContactDistributor}
            >
              {formatMessage({
                id: 'buyBox.addConfirmationModal.buttons.contactDistributor',
              })}
            </Button>
          </div>
        ) : (
          <div className="my-2 smu:w-1/2">
            <Button
              variant="primary"
              fullWidth
              size="medium"
              dataCy="go-to-checkout"
              onClick={onGoToCheckoutOrSignIn}
            >
              {ui.checkoutButtonLabel}
            </Button>
          </div>
        )}
      </div>
      {mixedMarket && (
        <Button
          variant="secondary"
          fullWidth
          size="medium"
          dataCy="contact-distributor"
          onClick={onContactDistributor}
        >
          {formatMessage({
            id: 'buyBox.addConfirmationModal.buttons.contactDistributor',
          })}
        </Button>
      )}
      <Button
        variant="textOnly"
        size="medium"
        fullWidth
        dataCy="continue-browsing"
        onClick={onClose}
      >
        {formatMessage({
          id: 'buyBox.addConfirmationModal.buttons.continueBrowsing',
        })}
      </Button>
    </aside>
  )
}

export { AddProductConfirmation }
