import cn from 'classnames'
import { AEMImage } from '@abcam-web/shared/ui/content'
import { getAEMOrigin } from '@abcam-web/browse/aem'

type ImageProps = {
  isMobile?: boolean
  isFeatureColumn?: boolean
  image: {
    _path: string
    imageCaption?: string
  }
}

export const MegaNavImage = ({
  image,
  isFeatureColumn,
  isMobile,
}: ImageProps) => {
  return (
    <div
      className={cn(
        isFeatureColumn && !isMobile && 'w-1/2 lgd:w-full lgd:mb-4',
        isMobile && isFeatureColumn ? 'order-last w-full' : 'mt-3 mb-8'
      )}
    >
      <AEMImage
        alt={image.imageCaption ?? ''}
        src={getAEMOrigin() + image._path}
        forceVisible={{
          isVisible: true,
          containerWidth: 400, // We are making an assumption here due to limited header area
          // this section can be improved in the future for 4k+ screens
        }}
      />
    </div>
  )
}
