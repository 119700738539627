import { ResourcePageTypes } from './resource-page-types'

export const ResourceCategoriesLabels: Record<
  ResourcePageTypes,
  `common.contentPageType.${string}`
> = {
  [ResourcePageTypes.AboutUs]: 'common.contentPageType.aboutUs',
  [ResourcePageTypes.Careers]: 'common.contentPageType.careers',
  [ResourcePageTypes.ContactUs]: 'common.contentPageType.contactUs',
  [ResourcePageTypes.LearningGuide]: 'common.contentPageType.learningGuide',
  [ResourcePageTypes.Legal]: 'common.contentPageType.legal',
  [ResourcePageTypes.MarkerSelectionGuide]:
    'common.contentPageType.markerSelectionGuides',
  [ResourcePageTypes.OffersInitiatives]:
    'common.contentPageType.offersInitiatives',
  [ResourcePageTypes.Pathways]: 'common.contentPageType.pathways',
  [ResourcePageTypes.ProductOverviews]:
    'common.contentPageType.productOverviews',
  [ResourcePageTypes.Protocols]: 'common.contentPageType.protocols',
  [ResourcePageTypes.Support]: 'common.contentPageType.support',
  [ResourcePageTypes.Troubleshooting]: 'common.contentPageType.troubleshooting',
}
