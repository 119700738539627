import { AlertIcon, Bin } from '@abcam-web/lego/ui/icons'
import { BasketItemModel } from '@abcam-web/shared/data-access/ecommerce-schema'
import { Button } from '@lego/ui/button'
import { FC } from 'react'
import { useIntl } from 'react-intl'

import styles from './restricted-item-list.module.css'

export enum RestrictedItemsVariant {
  online = 'online',
  opro = 'opro',
}

export interface RestrictedItemListProps {
  restrictedItems: BasketItemModel[]
  onDeleteItemsClick: (restrictedItems: BasketItemModel[]) => void
  variant: RestrictedItemsVariant
}

const RestrictedItemList: FC<RestrictedItemListProps> = ({
  restrictedItems,
  onDeleteItemsClick,
  variant,
}) => {
  const isOnline = variant === RestrictedItemsVariant.online

  const { formatMessage } = useIntl()
  const messages = {
    productsCantBeShipped: formatMessage({
      id: 'restrictedItemList.productsCantBeShipped',
    }),
    andTheyWillBeAutoRemoved: formatMessage({
      id: 'restrictedItemList.willBeAutoRemoved',
    }),
    productsNotAvailable: formatMessage({
      id: 'restrictedItemList.productsNotAvailable',
    }),
    removeAll: formatMessage({
      id: 'restrictedItemList.removeAll',
    }),
    quantity: formatMessage({ id: 'restrictedItemList.quantity' }),
  }

  return (
    <div
      id="restricted-container"
      data-testid="restricted-container"
      className={
        isOnline
          ? styles.restrictedContainerOnline
          : styles.restrictedContainerOpro
      }
    >
      <div className="flex text-[#b22626]" data-testid="prompt-message">
        {isOnline && (
          <p className="p-4 text-body-xmedium">
            {messages.productsCantBeShipped} <br />
            {messages.andTheyWillBeAutoRemoved}
          </p>
        )}
        {!isOnline && (
          <div className="flex">
            <div className={styles.restrictionAlertIco}>
              <AlertIcon height={16} width={16} />
            </div>
            <p className="p-3 text-[#b22626] text-body-xmedium">
              {messages.productsNotAvailable}
            </p>
          </div>
        )}
        <Button
          onClick={(): void => {
            onDeleteItemsClick(restrictedItems)
          }}
          iconLeft={isOnline ? <Bin /> : null}
          className={
            isOnline ? styles.removeButtonOnline : styles.removeButtonOpro
          }
          size="small"
          dataCy="basket-restricted-items"
          data-testid="removeButton"
          variant={isOnline ? 'primary' : 'textOnly'}
        >
          {messages.removeAll}
        </Button>
      </div>
      {isOnline &&
        restrictedItems.map((item, index) => (
          <div className={styles.restrictedItemsOnline} key={index}>
            <div className="flex ml-2">
              <div className="mr-2">#{index + 1}</div>
              <span
                className="lowercase"
                data-testid={`restricted-item-assetDefinitionNumber-${
                  index + 1
                }`}
              >
                {item.assetDefinitionNumber}
              </span>
            </div>
            <p className="truncate text-grey-dark">{item.lineDescription}</p>
            <div
              className={`${styles.restrictedItem} ${styles.restrictedItemSeparator}`}
            >
              {index < restrictedItems.length - 1 && (
                <hr className="h-px mt-2 border-0 bg-interactive-black-transparent-active" />
              )}
            </div>
          </div>
        ))}
      {!isOnline && (
        <div className="pb-4">
          {restrictedItems.map((item, index) => (
            <div className={styles.restrictedItemsOpro} key={index}>
              <div className="flex mx-2 text-[#b22626]">
                <div className="font-bold mr-1.5">{index + 1}.</div>
                <span
                  className="font-bold lowercase"
                  data-testid={`restricted-item-assetDefinitionNumber-${
                    index + 1
                  }`}
                >
                  {item.assetDefinitionNumber}
                </span>
                <span>
                  , {item.lineDescription}, {messages.quantity}: {item.quantity}
                </span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export { RestrictedItemList }
