import { usePunchout } from '@abcam-web/auth-shared/aws-cognito/hooks/usePunchout'
import dynamic from 'next/dynamic'
import { useMemo } from 'react'
import { useSessionState } from './use-session-state'
import { useTimeout } from './use-timeout'
import type { FC } from 'react'

const MILLISECONDS_PER_MINUTE = 60_000
const PUNCHOUT_SESSION_MAXIMUM_LENGTH = MILLISECONDS_PER_MINUTE * 60
const PUNCHOUT_SESSION_SAFE_LENGTH =
  PUNCHOUT_SESSION_MAXIMUM_LENGTH - 1 * MILLISECONDS_PER_MINUTE

const handleSessionExpired = () => {
  window.location.href = `${window.location.origin}/auth/punchout/error/err-006`
}

const MonitorBody: FC<{ sessionId: string }> = ({ sessionId }) => {
  const now = Date.now()

  const [sessionStartTime] = useSessionState(
    `punchout-${sessionId}-timestamp`,
    now
  )

  const timeLeft = useMemo(() => {
    const expectedEnd = sessionStartTime + PUNCHOUT_SESSION_SAFE_LENGTH
    return Math.max(0, expectedEnd - now)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStartTime])

  useTimeout(handleSessionExpired, timeLeft)

  // TO DO: should we display a banner to inform the user that the session is about to expire?
  return null
}

const Monitor: FC = () => {
  const { sessionId } = usePunchout()

  if (!sessionId) {
    return null
  }

  return <MonitorBody sessionId={sessionId} />
}

Monitor.displayName = 'PunchoutSessionMonitor'

export const PunchoutSessionMonitor = dynamic(Promise.resolve(Monitor), {
  ssr: false,
})
