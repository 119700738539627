import { FeatureToggleName } from '@feature-toggles/feature-toggle-name.enum'
import { useFeatureToggle } from '@feature-toggles/use-feature-toggle.hook'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'

import { useIsLogged } from './use-is-logged'

export const useInStockMessage = (country: string) => {
  const { isLogged, isLoading } = useIsLogged()
  const { formatMessage } = useIntl()

  const { attributesAsStringArray, enabled: inStockMessageFeatureEnabled } =
    useFeatureToggle(FeatureToggleName.DistributorMarketInStockMessage)
  const inStockMessageAllowedCountries =
    attributesAsStringArray?.find((attribute) => attribute.name === 'countries')
      ?.value || []

  const inStockMessage = formatMessage({ id: 'stockMessage.inStock' })
  const isStockMessageEnabled =
    !isLoading &&
    !isLogged &&
    inStockMessageFeatureEnabled &&
    inStockMessageAllowedCountries.includes(country)

  return useMemo(
    () => ({
      inStockMessage,
      isStockMessageEnabled,
    }),
    [inStockMessage, isStockMessageEnabled]
  )
}
