import { AlertIcon, PencilFilled } from '@abcam-web/lego/ui/icons'
import { Tooltip } from '@lego/ui/tooltip'
import classNames from 'classnames'
import React, { FC, useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'

import styles from './basket-item-list.module.css'

type Variant = 'warning' | 'default'
interface PriceBaseProps {
  originalPrice?: string
  price: string
  disabled?: boolean
  variant?: Variant
}

export const PriceBase: FC<PriceBaseProps> = ({
  originalPrice,
  price,
  disabled = false,
  variant,
}) => {
  const hasAdjustmentPrice = originalPrice !== price

  return (
    <div
      className={classNames({
        [styles.cell]: true,
        'opacity-20': disabled,
      })}
    >
      {originalPrice && hasAdjustmentPrice ? (
        <p
          data-cy="original-price"
          className={classNames(
            variant === 'warning' ? 'text-negative' : 'text-grey-15',
            'line-through text-right mt-1 -mb-1'
          )}
        >
          {originalPrice}
        </p>
      ) : (
        <p className={`${styles.fakeCode} hidden mb-1`}>&nbsp;</p>
      )}
      {
        <p
          data-cy="price"
          className={classNames(' text-right text-ui-medium', {
            'text-negative': variant === 'warning',
          })}
        >
          {price}
        </p>
      }
    </div>
  )
}

const WarningTooltip = ({ text }: { text?: string }) => {
  return text ? (
    <div className={classNames('flex flex-row px-2 text-grey-20', styles.icon)}>
      <AlertIcon className="mr-1 fill-grey-20" height={16} width={16} />
      <span>{text}</span>
    </div>
  ) : null
}

const priceIcon: Record<Variant, FC<React.SVGProps<SVGSVGElement>>> = {
  default: PencilFilled,
  warning: AlertIcon,
}

export const PriceComponent = ({
  originalPrice,
  price,
  disabled = false,
  editPrice,
  toggleEditPrice,
  variant = 'default',
  tooltipText,
  headersClass,
}: PriceBaseProps & {
  toggleEditPrice: () => void
  editPrice?: boolean
  tooltipText?: string
  headersClass?: string
}) => {
  const { formatMessage } = useIntl()
  const messages = useMemo(
    () => ({
      unitPrice: formatMessage({
        id: 'basketItem.unitPrice',
      }),
    }),
    [formatMessage]
  )

  const color = variant === 'warning' ? 'text-negative' : 'text-blue-default'

  const toggleEditPriceCallback = useCallback(() => {
    !disabled && toggleEditPrice()
  }, [disabled, toggleEditPrice])

  const Icon = useMemo(() => priceIcon[variant], [variant])

  return (
    <div data-cy="unit-price" className={`${styles.item} ${styles.unitPrice}`}>
      <p className={classNames(headersClass, 'lgu:hidden')}>
        {messages.unitPrice}
      </p>
      <div className="flex lgu:my-auto mdd:mt-auto">
        <PriceBase
          data-cy="unit-price"
          originalPrice={originalPrice}
          price={price}
          disabled={disabled}
          variant={variant}
        />
        {editPrice && (
          <Tooltip
            text={<WarningTooltip text={tooltipText} />}
            enabled={variant === 'warning'}
          >
            <div className="flex h-full">
              <span
                data-cy="edit-price"
                className={classNames('my-auto ', color, styles.icon)}
                onClick={toggleEditPriceCallback}
              >
                <Icon
                  className={classNames('ml-1 cursor-pointer fill-current', {
                    'opacity-30 cursor-not-allowed': disabled,
                  })}
                  height={16}
                  width={16}
                />
              </span>
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  )
}
