import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
}

export type BooleanAttribute = {
  __typename?: 'BooleanAttribute'
  name: Scalars['String']
  value: Scalars['Boolean']
}

export type FeatureToggle = {
  __typename?: 'FeatureToggle'
  /** Set of attributes with values as boolean */
  attributesAsBoolean?: Maybe<Array<Maybe<BooleanAttribute>>>
  /** Set of attributes with values as number */
  attributesAsNumber?: Maybe<Array<Maybe<NumberAttribute>>>
  /** Set of attributes with values as number array */
  attributesAsNumberArray?: Maybe<Array<Maybe<NumberArrayAttribute>>>
  /** Set of attributes with values as string */
  attributesAsString?: Maybe<Array<Maybe<StringAttribute>>>
  /** Set of attributes with values as string array */
  attributesAsStringArray?: Maybe<Array<StringArrayAttribute>>
  enabled: Scalars['Boolean']
  name: Scalars['String']
}

export type NumberArrayAttribute = {
  __typename?: 'NumberArrayAttribute'
  name: Scalars['String']
  value: Array<Scalars['Float']>
}

export type NumberAttribute = {
  __typename?: 'NumberAttribute'
  name: Scalars['String']
  value: Scalars['Float']
}

export type Query = {
  __typename?: 'Query'
  _service: _Service
  featureToggleList: Array<FeatureToggle>
  featureToggles: Array<FeatureToggle>
}

export type StringArrayAttribute = {
  __typename?: 'StringArrayAttribute'
  name: Scalars['String']
  value: Array<Scalars['String']>
}

export type StringAttribute = {
  __typename?: 'StringAttribute'
  name: Scalars['String']
  value: Scalars['String']
}

export type _Service = {
  __typename?: '_Service'
  /** The sdl representing the federated service capabilities. Includes federation directives, removes federation types, and includes rest of full schema after schema directives have been applied */
  sdl?: Maybe<Scalars['String']>
}

export type FeatureToggleListQueryVariables = Exact<{ [key: string]: never }>

export type FeatureToggleListQuery = {
  __typename?: 'Query'
  featureToggleList: Array<{
    __typename?: 'FeatureToggle'
    name: string
    enabled: boolean
    attributesAsStringArray?: Array<{
      __typename?: 'StringArrayAttribute'
      name: string
      value: Array<string>
    }> | null
    attributesAsString?: Array<{
      __typename?: 'StringAttribute'
      name: string
      value: string
    } | null> | null
    attributesAsBoolean?: Array<{
      __typename?: 'BooleanAttribute'
      name: string
      value: boolean
    } | null> | null
    attributesAsNumber?: Array<{
      __typename?: 'NumberAttribute'
      name: string
      value: number
    } | null> | null
    attributesAsNumberArray?: Array<{
      __typename?: 'NumberArrayAttribute'
      name: string
      value: Array<number>
    } | null> | null
  }>
}

export type FeatureTogglesQueryVariables = Exact<{ [key: string]: never }>

export type FeatureTogglesQuery = {
  __typename?: 'Query'
  featureToggles: Array<{
    __typename?: 'FeatureToggle'
    name: string
    enabled: boolean
    attributesAsStringArray?: Array<{
      __typename?: 'StringArrayAttribute'
      name: string
      value: Array<string>
    }> | null
    attributesAsString?: Array<{
      __typename?: 'StringAttribute'
      name: string
      value: string
    } | null> | null
    attributesAsBoolean?: Array<{
      __typename?: 'BooleanAttribute'
      name: string
      value: boolean
    } | null> | null
    attributesAsNumber?: Array<{
      __typename?: 'NumberAttribute'
      name: string
      value: number
    } | null> | null
    attributesAsNumberArray?: Array<{
      __typename?: 'NumberArrayAttribute'
      name: string
      value: Array<number>
    } | null> | null
  }>
}

export const FeatureToggleListDocument = gql`
  query featureToggleList {
    featureToggleList {
      name
      enabled
      attributesAsStringArray {
        name
        value
      }
      attributesAsString {
        name
        value
      }
      attributesAsBoolean {
        name
        value
      }
      attributesAsNumber {
        name
        value
      }
      attributesAsNumberArray {
        name
        value
      }
    }
  }
`

/**
 * __useFeatureToggleListQuery__
 *
 * To run a query within a React component, call `useFeatureToggleListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureToggleListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureToggleListQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeatureToggleListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FeatureToggleListQuery,
    FeatureToggleListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FeatureToggleListQuery,
    FeatureToggleListQueryVariables
  >(FeatureToggleListDocument, options)
}
export function useFeatureToggleListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FeatureToggleListQuery,
    FeatureToggleListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FeatureToggleListQuery,
    FeatureToggleListQueryVariables
  >(FeatureToggleListDocument, options)
}
export type FeatureToggleListQueryHookResult = ReturnType<
  typeof useFeatureToggleListQuery
>
export type FeatureToggleListLazyQueryHookResult = ReturnType<
  typeof useFeatureToggleListLazyQuery
>
export type FeatureToggleListQueryResult = Apollo.QueryResult<
  FeatureToggleListQuery,
  FeatureToggleListQueryVariables
>
export const FeatureTogglesDocument = gql`
  query featureToggles {
    featureToggles {
      name
      enabled
      attributesAsStringArray {
        name
        value
      }
      attributesAsString {
        name
        value
      }
      attributesAsBoolean {
        name
        value
      }
      attributesAsNumber {
        name
        value
      }
      attributesAsNumberArray {
        name
        value
      }
    }
  }
`

/**
 * __useFeatureTogglesQuery__
 *
 * To run a query within a React component, call `useFeatureTogglesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureTogglesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureTogglesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeatureTogglesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FeatureTogglesQuery,
    FeatureTogglesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FeatureTogglesQuery, FeatureTogglesQueryVariables>(
    FeatureTogglesDocument,
    options
  )
}
export function useFeatureTogglesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FeatureTogglesQuery,
    FeatureTogglesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FeatureTogglesQuery, FeatureTogglesQueryVariables>(
    FeatureTogglesDocument,
    options
  )
}
export type FeatureTogglesQueryHookResult = ReturnType<
  typeof useFeatureTogglesQuery
>
export type FeatureTogglesLazyQueryHookResult = ReturnType<
  typeof useFeatureTogglesLazyQuery
>
export type FeatureTogglesQueryResult = Apollo.QueryResult<
  FeatureTogglesQuery,
  FeatureTogglesQueryVariables
>
