import { setCognitoECOMInterceptor } from '@abcam-web/shared/data-access/ecommerce-api-hooks'
import { FC, PropsWithChildren } from 'react'

import { HistoryProvider } from './history.context'
import { InquiryProvider } from './inquiry.context'
import { OrderProvider } from './order.context'

const combineComponents = (...components: FC[]): FC => {
  return components.reduce(
    (
      AccumulatedComponents: FC<PropsWithChildren>,
      CurrentComponent: FC<PropsWithChildren>
    ) => {
      return ({ children }: PropsWithChildren): JSX.Element => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        )
      }
    },
    ({ children }: any) => children
  )
}

const providers = [InquiryProvider, OrderProvider, HistoryProvider]
const CombinedProviders: FC<PropsWithChildren> = combineComponents(
  ...(providers as FC[])
)

interface Options {
  enableCognitoInterceptor?: boolean
}

export const EcommerceProvider: FC<PropsWithChildren & Options> = ({
  enableCognitoInterceptor = false,
  children,
}) => {
  if (enableCognitoInterceptor) {
    setCognitoECOMInterceptor()
  }
  return <CombinedProviders>{children}</CombinedProviders>
}
