import {
  countryNames as countries,
  CountryNameWithAlternative,
} from '@abcam-web/shared/config'

export const isSupportedCountryCode = (countryCode: string): boolean =>
  Object.prototype.hasOwnProperty.call(countries, countryCode)

export function getCountryNameByCountryCode(countryCode: string): string {
  if (!countryCode || !isSupportedCountryCode(countryCode)) {
    throw new Error(`Cannot get country name by country code ${countryCode}!`)
  }

  return (
    (<CountryNameWithAlternative>countries)[countryCode]?.name ?? countryCode
  )
}

const COUNTRIES_WITH_THE = ['GB', 'US', 'NL']
export function getCountryNameWithTheByCountryCode(
  countryCode: string
): string {
  const name = getCountryNameByCountryCode(countryCode)
  return COUNTRIES_WITH_THE.includes(countryCode) ? `the ${name}` : name
}

export function getCountryNameByGenericCountryCode(
  countryCode: keyof typeof countries
): string {
  if (countryCode && countries[countryCode]) {
    return countries[countryCode]?.name
  }
  return countryCode as string
}

export const getCountryAlternativeNameFromCountryCode = (
  countryCode: string
): string => {
  if (!countryCode || !isSupportedCountryCode(countryCode)) {
    throw new Error(
      `Cannot get country alternative name, invalid countryCode specified: ${countryCode}!`
    )
  }

  return (<CountryNameWithAlternative>countries)[countryCode]?.alternative ?? ''
}

export const getAllCountryCodes = (): string[] => Object.keys(countries)

/*
  Extract geolocation data from akamai x-akamai-edgescape header
*/
export const getGeolocationData = (geolocationString: string) => {
  const output: { [key: string]: number | string } = {}

  geolocationString.split(',').forEach((item) => {
    const [key, value] = item.split('=')
    output[key] = isNaN(Number(value)) ? value : Number(value)
  })

  return output
}

/**
 * Checks if given country is china or japan or user is china or japan domain
 * @param countryCode
 */
export const isCountryChinaOrJapan = (countryCode: string) => {
  let isChinaOrJapanDomain = false
  if (typeof window !== 'undefined') {
    const extension = window.location.origin.split('.').pop() || 'com'
    isChinaOrJapanDomain = ['jp', 'cn'].includes(extension)
  }

  return (
    ['CN', 'JP'].includes(countryCode.toLocaleUpperCase()) ||
    isChinaOrJapanDomain
  )
}
