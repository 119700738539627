import { useUser } from '@abcam-web/auth-shared/contexts/user'
import { getPunchoutId, getPunchoutSessionId } from './utils/punchout'
import { useMemo } from 'react'

export type PunchoutInfo =
  | {
      readonly active: false
      readonly sessionId?: undefined
      readonly punchoutId?: undefined
    }
  | {
      readonly active: true
      readonly sessionId: string
      readonly punchoutId: string
    }

export function usePunchout(): PunchoutInfo {
  const user = useUser()

  const punchoutId = getPunchoutId(user)
  const sessionId = getPunchoutSessionId(user)

  return useMemo(() => {
    if (!!punchoutId && !!sessionId) {
      return { active: true, punchoutId, sessionId }
    }

    return { active: false }
  }, [punchoutId, sessionId])
}
