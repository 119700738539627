import {
  ClickId,
  getBaseWebEngagementProperties,
  GTMPageType,
  LocationID,
  PageSubtype,
  useGtm,
} from '@abcam-web/shared/data-access/tracking'
import {
  useDiscount,
  useMarketInfo,
} from '@abcam-web/shared/ecommerce/utilities'
import { Button } from '@lego/ui/button'
import {
  AlertIcon,
  ChevronDown,
  ChevronRight,
  Cross,
  IconTick,
  SpinnerOffset,
} from '@lego/ui/icons'
import { TextInput } from '@lego/ui/text-input'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { Collapse } from 'react-collapse'
import { useIntl } from 'react-intl'

import styles from './discount.module.css'

function ErrorMessage({ children }: React.PropsWithChildren) {
  return (
    <div
      className="flex items-center w-full mb-2 text-left text-negative text-body-small"
      data-testid="promotion-error-message"
    >
      <AlertIcon className="mr-1 w-[20px] h-[20px] icon-current-color" />
      {children}
    </div>
  )
}

function SuccessMessage({ children }: React.PropsWithChildren) {
  return (
    <div
      className="flex items-center w-full mb-2 text-left text-green text-body-small"
      data-testid="promotion-success-message"
    >
      <IconTick className="mr-1 w-[15px] h-[15px] icon-current-color" />
      {children}
    </div>
  )
}

export type DiscountFlatProps = {
  basketId: string
  headerLabel?: string
  subHeaderLabel?: string
  buttonLabel?: string
  /**
   * Whether the discount section is collapsible, that is can user can expand and collapse the section
   * by clicking on the chevron in the header.
   */
  isCollapsible?: boolean
  /**
   * Whether the discount section is initially expanded or collapsed.
   * Only applicable if `isCollapsible` is true.
   */
  isInitiallyExtended?: boolean

  /**
   * Callback to be executed upon successful application of the discount code.
   * When server side reported success, this callback will be run.
   */
  onSuccessPromoCodeApplication?: () => Promise<unknown>

  /**
   * Callback to be executed upon failed application of the discount code.
   */
  onFailedPromoCodeApplication?: () => Promise<unknown>

  disabled?: boolean

  appliedPromotions?: {
    promotionCode: string
    name?: string
  }[]

  alwaysShow?: boolean

  pageSubtype?: PageSubtype
}

function Discount({
  basketId,
  headerLabel: headerLabelOverride,
  subHeaderLabel: subHeaderLabelOverride,
  buttonLabel: buttonLabelOverride,
  isCollapsible,
  isInitiallyExtended,
  onSuccessPromoCodeApplication,
  onFailedPromoCodeApplication,
  disabled,
  appliedPromotions = [],
  alwaysShow = false,
  pageSubtype,
}: DiscountFlatProps) {
  const { formatMessage } = useIntl()
  const messages = {
    headerLabel: formatMessage({ id: 'discount.headerLabel.default' }),
    subHeaderLabel: formatMessage({ id: 'discount.subHeaderLabel.default' }),
    buttonLabel: formatMessage({ id: 'discount.buttonLabel.default' }),
  }
  const {
    promoCode,
    onPromoCodeUpdate,
    submitPromoCode,
    removePromoCode,
    isLoading,
    isReadyToSubmit,

    showWidget,

    errorMessage,
    successMessage,
  } = useDiscount({
    basketId,
    onSuccessPromoCodeApplication,
    onFailedPromoCodeApplication,
  })
  const gtm = useGtm()
  const marketInfo = useMarketInfo()
  const router = useRouter()
  const [isExpanded, setIsExpanded] = React.useState<boolean>(
    isCollapsible ? !!isInitiallyExtended : true
  )

  const trackDiscountCode = () => {
    //TODO: pass in the discount when it becomes available
    gtm.track({
      ...getBaseWebEngagementProperties(),
      click_id: ClickId.APPLY_DISCOUNT_CODE,
      location_id: LocationID.PAGE,
      page_path: router.asPath,
      page_type: GTMPageType.CHECKOUT,
      page_subtype: pageSubtype,
      item_cta: 'Apply',
      free_text: promoCode,
      ecommerce_items: [
        {
          coupon: promoCode,
          // discount: '#TBD',
          basket_id: basketId,
          market_type: marketInfo?.data?.marketType ?? '',
        },
      ],
    })
  }

  const handleApplyPromoCode = (event: any) => {
    event.stopPropagation()
    trackDiscountCode()
    submitPromoCode(event)
  }

  useEffect(() => {
    const isWidgetDisplayed = showWidget || alwaysShow
    if (appliedPromotions.length && !isWidgetDisplayed && !isLoading) {
      Promise.all(
        appliedPromotions.map((p) => removePromoCode(p.promotionCode))
      )
    }
  }, [appliedPromotions, showWidget, alwaysShow, removePromoCode, isLoading])

  if (!showWidget && !alwaysShow) {
    return null
  }

  return (
    <div className={styles.discountContainer}>
      <div className="flex w-full mb-1 font-semibold text-left text-heading-x-small text-grey-dark">
        {headerLabelOverride || messages.headerLabel}
        {isCollapsible && (
          <span
            data-testid="expand-discount-section"
            className="ml-1 cursor-pointer"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? (
              <ChevronDown aria-label="chevron-down" />
            ) : (
              <ChevronRight aria-label="chevron-right" />
            )}
          </span>
        )}
      </div>

      <Collapse isOpened={isExpanded}>
        <div className="mb-5">
          <div className="w-full text-left text-grey-20 text-body-small whitespace-nowrap">
            {subHeaderLabelOverride || messages.subHeaderLabel}
          </div>
          <div className="flex items-center mt-2 mb-2 space-x-3">
            <div className="flex justify-center w-2/3">
              <TextInput
                ariaLabel="promo code"
                fullWidth
                onChange={onPromoCodeUpdate}
                onBlur={onPromoCodeUpdate}
                value={promoCode}
                disabled={isLoading || disabled}
              />
            </div>
            <div className="flex justify-center w-1/3">
              <Button
                aria-label="apply discount"
                size="medium"
                variant="tertiaryFilled"
                disabled={!isReadyToSubmit || disabled}
                onClick={handleApplyPromoCode}
                iconLeft={
                  isLoading && <SpinnerOffset className="ml-3 animate-spin" />
                }
              >
                {buttonLabelOverride || messages.buttonLabel}
              </Button>
            </div>
          </div>

          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

          {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}

          {appliedPromotions.length > 0 &&
            appliedPromotions.map((promotion) => (
              <div
                className="flex mb-2 items-left"
                key={promotion.promotionCode}
              >
                <Button
                  variant="tertiaryFilled"
                  aria-label="remove discount"
                  size="small"
                  disabled={isLoading || disabled}
                  onClick={(event) => {
                    event.stopPropagation()
                    removePromoCode(promotion.promotionCode)
                  }}
                  iconRight={<Cross />}
                  data-testid={`remove-applied-promotion-${promotion.promotionCode}`}
                >
                  {promotion.promotionCode}
                </Button>
              </div>
            ))}
        </div>
      </Collapse>
    </div>
  )
}

export { Discount }
