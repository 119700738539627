import { InfoIcon } from '@abcam-web/lego/ui/icons'
import { Notification } from '@lego/ui/notification'
import { Tooltip } from '@lego/ui/tooltip'
import { FC, PropsWithChildren } from 'react'
import { useIntl } from 'react-intl'

const ViewStockNoteTrigger = () => {
  const { formatMessage } = useIntl()
  return (
    <span
      data-testid={'view-stock-note-trigger'}
      className="font-semibold underline cursor-pointer text-grey-dark ml-2.5"
    >
      {formatMessage({ id: 'tooltip.text.general' })}
    </span>
  )
}

export type StockNoteToolTipProps = {
  toolTipTitle?: string
  toolTipMessage: string
  showToolTipIcon: boolean
}
export const StockNoteToolTip: FC<PropsWithChildren<StockNoteToolTipProps>> = ({
  toolTipTitle,
  toolTipMessage,
  showToolTipIcon,
}) => {
  return (
    <div className="flex mr-2">
      {toolTipTitle && (
        <span
          data-testid="stock-note-title"
          className="text-body-xmedium shrink-0"
        >
          {toolTipTitle}
        </span>
      )}
      {toolTipMessage && (
        <Tooltip
          data-testid="stock-note-tooltip"
          text={toolTipMessage}
          className="max-w-[300px] opacity-20"
        >
          {showToolTipIcon ? (
            <InfoIcon
              data-testid={'info-icon'}
              className="flex-grow-0 ml-1 fill-blue-0"
              height={16}
              width={16}
            />
          ) : (
            <ViewStockNoteTrigger />
          )}
        </Tooltip>
      )}
    </div>
  )
}

export interface StockNotesComponentProps {
  shouldShowStockNote: boolean
  shouldReplaceGOPMessage: boolean
  isMadeToOrder: boolean
  toolTipMessage: string
  toolTipTitle: string
  deliveryMessage: React.ReactNode
}

export const BasketItemStockNotes: FC<
  PropsWithChildren<StockNotesComponentProps>
> = ({
  shouldShowStockNote,
  shouldReplaceGOPMessage,
  isMadeToOrder,
  toolTipMessage,
  toolTipTitle,
  deliveryMessage,
}) => {
  return (
    <div className="flex">
      {(!shouldShowStockNote || !shouldReplaceGOPMessage) && deliveryMessage}
      {shouldShowStockNote && (
        <Notification variant="cautionary" size="x-small" className="ml-3">
          <StockNoteToolTip
            toolTipTitle={toolTipTitle}
            toolTipMessage={toolTipMessage}
            showToolTipIcon={isMadeToOrder}
          />
        </Notification>
      )}
    </div>
  )
}

export const AssetItemStockNotes: FC<
  PropsWithChildren<StockNotesComponentProps>
> = ({
  shouldShowStockNote,
  isMadeToOrder,
  toolTipTitle,
  toolTipMessage,
  shouldReplaceGOPMessage,
  deliveryMessage,
}) => {
  return (
    <>
      {shouldShowStockNote && isMadeToOrder && (
        <div className="flex justify-center pt-4 font-normal">
          <StockNoteToolTip
            toolTipTitle={toolTipTitle}
            toolTipMessage={toolTipMessage}
            showToolTipIcon={isMadeToOrder}
          />
        </div>
      )}
      {(!shouldShowStockNote || !shouldReplaceGOPMessage) && (
        <div className="mt-4 font-normal">{deliveryMessage}</div>
      )}
      {shouldShowStockNote && !isMadeToOrder && (
        <div data-testid="stock-note-message" className="flex pt-4 font-normal">
          <span className="whitespace-pre-line">{toolTipMessage}</span>
        </div>
      )}
    </>
  )
}

export enum StockNoteVariant {
  basket = 'basket',
  asset = 'asset',
}
export interface StockNoteProps extends StockNotesComponentProps {
  variant: StockNoteVariant
}

export const StockNote: FC<PropsWithChildren<StockNoteProps>> = ({
  variant,
  ...props
}) => {
  if (variant === StockNoteVariant.asset) {
    return <AssetItemStockNotes {...props} />
  }
  if (variant === StockNoteVariant.basket) {
    return <BasketItemStockNotes {...props} />
  }
  return null
}
