/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  NavMenuItem,
  navMenuItems,
} from '@abcam-web/auth-shared/constants/navMenuItems'
import { UserContext } from '@abcam-web/auth-shared/contexts/user'
import { Outbox, Spinner } from '@lego/ui/icons'
import { useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import style from './navMenu.module.css'
import getConfig from 'next/config'
import {
  AUTH_BASE_URL,
  BASE_CHINESE_URL_BY_DOMAIN,
  BASE_URL_BY_DOMAIN,
  CHINESE_LOCALE,
} from '@abcam-web/auth-shared/constants/base-url'
import { STAGE } from '@abcam-web/auth-shared/constants/stage-type'
import {
  getAuthenticationHost,
  isChinaDomain,
} from '@abcam-web/auth-shared/aws-cognito'
import { useGtm } from '@abcam-web/shared/data-access/tracking'
import { useRouter } from 'next/router'

import {
  trackAccountMenuItemClick,
  trackLogoutClick,
} from '@abcam-web/auth-shared/gtm-tracking/track-events'
import { getNextLocaleFromCookie } from '@abcam-web/shared/utilities/localisation'

interface Props {
  className?: string
  onLogOut: () => void
}
const accountNavItems: NavMenuItem[] = [
  ...navMenuItems.filter(({ hiddenOnDropdown }) => !hiddenOnDropdown),
]

export const getAbcamUrl = (locale: string): string => {
  const stage: STAGE = getConfig().publicRuntimeConfig?.STAGE
  const isChina = isChinaDomain()

  if (locale === CHINESE_LOCALE || isChina) {
    return `${BASE_CHINESE_URL_BY_DOMAIN[stage ?? 'prod']}${locale || ''}`
  }

  return `${BASE_URL_BY_DOMAIN[stage ?? 'prod']}${locale || ''}`
}

const buildHref = (item: NavMenuItem, isLoggedIn: boolean) => {
  const locale = getNextLocaleFromCookie()
  const baseUrl = getAbcamUrl(locale)

  const myAccountUrl = baseUrl.replace(`/${locale}`, '/my-account')
  const stage: STAGE = getConfig().publicRuntimeConfig?.STAGE

  if (isLoggedIn) {
    return `${myAccountUrl}/${item.urlPath}`
  } else {
    const authUrl = getAuthenticationHost(stage)
    const url = new URL(`${AUTH_BASE_URL}login`, authUrl)
    url.searchParams.append('redirect', `${myAccountUrl}/${item.urlPath}`)
    return url.href
  }
}

// building the nav menu to show inside the dropdown
const NavMenu = ({ className = '', onLogOut }: Props) => {
  const { user } = useContext(UserContext)
  const router = useRouter()
  const gtm = useGtm()
  const isLoggedIn = !!user
  const stage: STAGE = getConfig().publicRuntimeConfig?.STAGE
  const { formatMessage } = useIntl()
  const [loggingOut, setLoggingOut] = useState(false)
  const [path] = router.asPath.split(/[/?]/).filter(Boolean)

  const onLogOutClick = () => {
    trackLogoutClick({
      gtm,
      pathName: router.pathname,
      destinationPath: router.pathname,
    })
    setLoggingOut(true)
    onLogOut()
  }

  // filter out items that should not be shown on the current stage
  const items = accountNavItems
    .filter((item) => {
      return item.hiddenOnStage?.includes(stage) !== true
    })
    .map((item) => {
      const active = item.href.includes(path)
      return (
        <li
          key={item.labelId}
          className={`group ${active ? style.active : ''}`}
        >
          <a
            href={buildHref(item, isLoggedIn)}
            className="flex items-center px-4 py-3 outline-none hover:bg-interactive-grey-transparent-hover active:bg-interactive-grey-transparent-active hover:no-underline focus-visible:shadow-interactiveElementInset"
            data-cy={`${item.labelId}-navmenu-link`}
            onClick={() =>
              trackAccountMenuItemClick({
                gtm,
                pathName: router.pathname,
                destinationPath: buildHref(item, true),
                isLoggedIn,
                menuItem: item.labelId,
              })
            }
          >
            {item.icon && item.filledIcon && (
              <span className="pr-4 ">
                <item.icon
                  className={`w-6 h-6 [&_path]:fill-black group-hover:hidden ${
                    active ? 'hidden' : ''
                  }`}
                />
                <item.filledIcon
                  className={`hidden w-6 h-6 [&_path]:fill-black group-hover:block ${
                    active ? '!block' : ''
                  }`}
                />
              </span>
            )}
            {formatMessage({ id: `common.routes.${item.labelId}` })}
          </a>
        </li>
      )
    })

  return (
    <ul
      className={`font-semibold ${style.wrapper} ${className}`}
      data-cy="navmenu-links"
    >
      {items}

      {/* adding the logout button */}
      {isLoggedIn && (
        <button
          disabled={loggingOut}
          onClick={onLogOutClick}
          className="block w-full px-4 py-3 font-semibold text-left outline-none group md:py-2 hover:bg-interactive-grey-transparent-hover active:bg-interactive-grey-transparent-active hover:no-underline focus-visible:shadow-interactiveElementInset"
        >
          <div className="flex gap-1">
            <span className="pr-4 ">
              {!loggingOut && (
                <Outbox className="w-5 h-5 -rotate-90 [&_path]:fill-black group-hover:[&_path]:fill-blue-default" />
              )}
              {loggingOut && <Spinner className={style.spinner} />}
            </span>

            {formatMessage({ id: `components.accountDropDown.logout` })}
          </div>
        </button>
      )}
    </ul>
  )
}

export { NavMenu }
