import {
  type LotSpecificOrderTypeForRequest,
  BasketItemLotStrategy,
  EditCustomerLineNumberModal,
  EditLotNumberModal,
  EditPONumberModal,
  EditPrice,
  ItemAction,
  ItemActionsDropdown,
  LotSpecificOrderTypeForStrategy,
  OrderTag,
  OrderTagEnum,
  ValidatePriceEditNotification,
} from '@abcam-web/ecommerce/components'
import { useOrderContext } from '@abcam-web/ecommerce/utilities'
import { useAssetDefinitionQuery } from '@abcam-web/shared/data-access/ecommerce-api-hooks'
import {
  AdjustmentType,
  AssetDefinitionModel,
  BasketItemModel,
  DeliveryMethodType,
  ItemPrices,
  PriceAdjustmentSource,
} from '@abcam-web/shared/data-access/ecommerce-schema'
import { LotSpecificOrderType } from '@abcam-web/shared/data-access/ecommerce-schema'
import {
  DeliveryInfo,
  StockNote,
  StockNoteVariant,
  useStockNotes,
} from '@abcam-web/shared/ecommerce/components'
import {
  getBasketFormattedPrices,
  getDeliveryMessage,
  getEstimatedDeliveryMessage,
  getQuantityMaxOptions,
  getSelectNumberOptions,
  getStockDetails,
  isDiscontinued as isItemDiscontinued,
} from '@abcam-web/shared/ecommerce/utilities'
import {
  editPriceReasons,
  onAssetUpdate,
  updateType,
  useInStockMessage,
} from '@abcam-web/shared/ecommerce/utilities'
import { useCountry } from '@abcam-web/shared/utilities/country'
import {
  getLanguageFromLocale,
  getNextLocaleFromCookie,
} from '@abcam-web/shared/utilities/localisation'
import { useToggle } from '@lego/hooks/toggle'
import { Button } from '@lego/ui/button'
import { AlertIcon, Bin, InfoIcon } from '@lego/ui/icons'
import { Modal } from '@lego/ui/modal'
import { Notification, NOTIFICATION_VARIANT } from '@lego/ui/notification'
import { Select, SelectOption } from '@lego/ui/select'
import { Tooltip } from '@lego/ui/tooltip'
import classNames from 'classnames'
import isEqual from 'lodash/isEqual'
import { useRouter } from 'next/router'
import { FC, ReactNode, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'

import styles from './basket-item-list.module.css'
import { Item } from './item'
import { PriceBase, PriceComponent } from './price'
import { SizeSelector } from './size-selector'

export interface BasketItemProps {
  availabilityComponent?: (assetNumber: string) => ReactNode
  billingCountry?: string
  deleteOnClick?: ({
    itemToDelete,
    price,
  }: {
    itemToDelete: BasketItemModel
    price: string
  }) => void
  disableHyperlink?: boolean
  disableInteractions?: boolean
  distributorFlow: boolean
  editPrice?: boolean
  enableActionDropdown?: boolean
  hideSeparator: boolean
  index: number
  isQuote?: boolean
  item: BasketItemModel
  onAssetUpdate?: (assetUpdates: onAssetUpdate, callback?: () => void) => void
  shippingCountry?: string
  showBasePrice?: boolean
  showLQL?: boolean
  showValidatePriceEditNotification?: boolean
  simplified?: boolean
  priceDiscrepancyError?: boolean
}

const DiscontinuedAlert = ({
  simplified = false,
}: {
  simplified?: boolean
}) => {
  const { formatMessage } = useIntl()

  const header = simplified
    ? formatMessage({
        id: 'basketItem.discontinued.header.simple',
      })
    : formatMessage({
        id: 'basketItem.discontinued.header.standard',
      })

  return (
    <div
      className={classNames(
        'flex items-center justify-center py-1 text-black-0 bg-cautionary',
        {
          'text-body-xmedium': !simplified,

          'text-body-small px-2 rounded': simplified,
        }
      )}
    >
      <AlertIcon className="mr-1" height={17} width={17} />
      <Tooltip
        text={formatMessage({
          id: 'basketItem.discontinued.explainer',
        })}
      >
        {header}
        <span className="ml-2 font-semibold underline cursor-help">
          {formatMessage({
            id: 'basketItem.discontinued.whatIsThis',
          })}
        </span>
      </Tooltip>
    </div>
  )
}

const BasketItem: FC<BasketItemProps> = ({
  availabilityComponent,
  billingCountry,
  deleteOnClick = () => null,
  disableHyperlink = false,
  disableInteractions = false,
  distributorFlow,
  editPrice = false,
  enableActionDropdown = false,
  hideSeparator = false,
  index,
  isQuote = false,
  item,
  onAssetUpdate = () => null,
  shippingCountry,
  showBasePrice = false,
  showLQL = false,
  showValidatePriceEditNotification = false,
  simplified = false,
  priceDiscrepancyError,
}) => {
  const [openEditPrice, toggleEditPrice] = useToggle(false)
  const { cache } = useOrderContext()
  const language = getLanguageFromLocale(
    useRouter().locale || getNextLocaleFromCookie()
  )
  const { country } = useCountry()
  const [purchaseOrderModal, togglePurchaseOrderModal] = useToggle()
  const [customerNumberModal, toggleCustomerNumberModal] = useToggle()
  const [requestLotModal, toggleRequestLotModal] = useToggle()
  const [requestLotModalType, setRequestLotModalType] =
    useState<LotSpecificOrderTypeForRequest>()

  const { data: assetDefinition, isFetched } = useAssetDefinitionQuery({
    country: shippingCountry || (country as string),
    assetDefinitionNumber: item.assetDefinitionNumber,
    quantity: item.quantity,
    organisationRegistryId: cache.organisationRegistryId || undefined,
  })

  const showAvailability = !!availabilityComponent

  const customerPurchaseOrderNumber = item.customerPurchaseOrderNumber
  const customerLineNumber = item.customerLineNumber

  const assetDefinitionWithOneSizeAndLoader = {
    assetDefinitionName: item.lineDescription,
    assetDefinitionNumber: item.assetDefinitionNumber,
    sizes: [{ size: item.size }, { size: { value: 'Loading', unit: '...' } }],
  } as AssetDefinitionModel

  // Minimum 11 so that we display the 10+ and allow the user to type a quantity
  const columnHeadersClass = `${styles.columnheader} ${classNames({
    hidden: index && !simplified,
    'mb-3': !simplified,
    'mb-1': simplified,
  })}`

  const mapSizesToSelectOptions = (
    isFetched
      ? assetDefinition?.sizes
      : assetDefinitionWithOneSizeAndLoader?.sizes
  )?.map((mapItem) => ({
    key: mapItem?.publicAssetCode,
    displayValue: `${mapItem?.size?.value?.toString()} ${mapItem?.size?.unit}`,
    isPreselected: mapItem?.publicAssetCode === item?.publicAssetCode,
  })) as SelectOption[]

  const {
    unitPriceWithDiscountFormatted,
    unitPriceWithoutDiscountFormatted,
    unitBasePriceFormatted,
    itemTotalWithDiscountFormatted,
    itemTotalWithoutDiscountFormatted,
    itemBaseTotalFormatted,
    userInputPriceFormatted,
  } = useMemo(
    () => getBasketFormattedPrices(item, language as string, country),
    [country, item, language]
  )

  const { formatMessage } = useIntl()
  const messages = useMemo(
    () => ({
      unitPriceEditedByAgent: formatMessage({
        id: 'basketItem.unitPriceEditedByAgent',
      }),
      product: formatMessage({
        id: 'basketItem.product',
      }),
      size: formatMessage({
        id: 'basketItem.size',
      }),
      quantity: formatMessage({
        id: 'basketItem.quantity',
      }),
      unitPrice: formatMessage({
        id: 'basketItem.unitPrice',
      }),
      itemTotal: formatMessage({
        id: 'basketItem.itemTotal',
      }),
      availability: formatMessage({
        id: 'basketItem.availability',
      }),
      productDiscontinued: formatMessage({
        id: 'basketItem.productDiscontinued',
      }),
      lineItemPoNumber: formatMessage({
        id: 'basketItem.lineItemPoNumber',
      }),
      lineItemCustomerLineNumber: formatMessage({
        id: 'basketItem.lineItemCustomerLineNumber',
      }),
      customerPoNumberClear: formatMessage({
        id: 'basketItem.customerPoNumberClear',
      }),
      editItemPrice: formatMessage({
        id: 'basketItem.editItemPrice',
      }),
      resetPrice: formatMessage({
        id: 'basketItem.resetPrice',
      }),
      deliveryNotAvailable: formatMessage({
        id: 'basketItem.deliveryMessage.notAvailable',
      }),
      priceDiscrepancyNotification: formatMessage(
        { id: 'basketItem.priceDiscrepancy.notification' },
        { userInputPrice: userInputPriceFormatted || '' }
      ),
      priceDiscrepancyExplainer: formatMessage({
        id: 'basketItem.PriceDiscrepancy.explainer',
      }),
    }),
    [formatMessage, userInputPriceFormatted]
  )

  const deliveryMessage = item?.dates?.scheduledArrivalDate
    ? isQuote
      ? getEstimatedDeliveryMessage(
          new Date(item?.dates?.scheduledArrivalDate.toString())
        )
      : getDeliveryMessage(
          new Date(item?.dates?.scheduledArrivalDate.toString()),
          new Date(item?.dates?.cutoffTimeForOrdering?.toString()),
          `${language}-${country}`,
          formatMessage
        )
    : messages.deliveryNotAvailable
  const stockDetails = getStockDetails(
    item?.availableQuantity as number,
    item?.quantity,
    formatMessage,
    item?.status
  )
  const { inStockMessage, isStockMessageEnabled } = useInStockMessage(
    country || shippingCountry || ''
  )
  const deliveryType = item.carrierDetails?.shipmentType as DeliveryMethodType
  const isDiscontinued = isItemDiscontinued(item.status)
  const {
    shouldShowStockNote,
    shouldReplaceGOPMessage,
    isMadeToOrder,
    toolTipMessage,
    toolTipTitle,
  } = useStockNotes(item.carrierDetails?.stockNotes)

  const options = getSelectNumberOptions(
    item?.quantity,
    getQuantityMaxOptions(item)
  )

  const cmoAdjustment = item?.prices?.adjustments?.find(
    (item) => item?.source === PriceAdjustmentSource.CMO
  )
  const editPriceReason = editPriceReasons.find(
    (reason) => reason.key === cmoAdjustment?.reason.type
  )

  const priceAdjustmentDescription = editPriceReason?.focr
    ? `FOCR (${editPriceReason.displayValue})`
    : `${messages.unitPriceEditedByAgent} (${editPriceReason?.displayValue})`

  return (
    <>
      <div
        className={classNames(
          'gap-y-1 mb-1 lgd:hidden',
          styles.itemGridCommon,
          {
            'p-2': !simplified,
            'py-2': simplified,
            [styles.withAvailability]: showAvailability,
            [styles.itemGridSimplified]: simplified && !distributorFlow,
            [styles.itemGridFull]: !simplified && !distributorFlow,
            [styles.itemGridFullDistributor]: distributorFlow && !simplified,
          }
        )}
      >
        <p className={classNames(columnHeadersClass, 'mb-0')}>
          {messages.product}
        </p>
        <p className={classNames(columnHeadersClass, 'mb-0')}>
          {messages.size}
        </p>
        <p className={classNames(columnHeadersClass, 'mb-0')}>
          {messages.quantity}
        </p>
        {showAvailability && (
          <p className={classNames(columnHeadersClass, 'mb-0 text-center')}>
            {messages.availability}
          </p>
        )}
        <p
          className={classNames(columnHeadersClass, 'mb-0', {
            hidden: distributorFlow || simplified,
          })}
        >
          {messages.unitPrice}
        </p>
        <p
          className={classNames(
            columnHeadersClass,
            { hidden: distributorFlow },
            'mb-0'
          )}
        >
          {messages.itemTotal}
        </p>
      </div>
      <div
        data-cy="basket-item"
        className={classNames({
          'border border-amber-300 shadow-none mb-1':
            isDiscontinued && !simplified,
        })}
      >
        {isDiscontinued && !simplified && <DiscontinuedAlert />}
        <div
          className={classNames(styles.itemGridCommon, {
            'p-2': !simplified,
            'py-2': simplified,
            'gap-y-0': isStockMessageEnabled,
            [styles.withAvailability]: showAvailability,
            [styles.itemGridSimplified]: simplified && !distributorFlow,
            [styles.itemGridFull]: !simplified && !distributorFlow,
            [styles.itemGridFullDistributor]: distributorFlow && !simplified,
          })}
          role="table"
        >
          <div className={`${styles.item} ${styles.code}`}>
            <p
              className={classNames(
                columnHeadersClass,
                { hidden: simplified },
                'lgu:hidden text-left'
              )}
            >
              {messages.product}
            </p>
            {
              <Item
                simplified={simplified || disableHyperlink}
                disabled={!!disableInteractions}
                lineNumber={index + 1}
                definitionNumber={item?.assetDefinitionNumber}
                definitionName={item?.lineDescription}
                tagValue={''}
                promotions={item.promotions}
              />
            }
          </div>
          <div className={`${styles.item} ${styles.sizes}`}>
            <p className={classNames(columnHeadersClass, 'lgu:hidden')}>
              {messages.size}
            </p>
            <SizeSelector
              sizeOnChange={(key: string) => {
                onAssetUpdate({
                  publicAssetCode: key,
                  lineNumber: item?.lineNumber,
                  assetNumber: key as string,
                  quantity: 1,
                  updateType: updateType.SIZE,
                  priceAdjustment: editPrice ? null : undefined,
                })
              }}
              options={mapSizesToSelectOptions}
              disableInteractions={disableInteractions}
            />
          </div>
          <div className={`${styles.item} ${styles.quantity}`}>
            <p className={classNames(columnHeadersClass, 'lgu:hidden')}>
              {messages.quantity}
            </p>
            <div data-cy="quantity-selector" className={styles.cell}>
              <Select
                disabled={disableInteractions}
                options={options}
                onChange={(val: SelectOption) => {
                  if (item?.assetNumber && item?.lineNumber) {
                    onAssetUpdate({
                      assetNumber: item?.assetNumber,
                      lineNumber: item?.lineNumber,
                      publicAssetCode: item?.publicAssetCode,
                      quantity: Number(val.key),
                      updateType: updateType.QUANTITY,
                    })
                  }
                }}
              />
            </div>
          </div>
          {showAvailability && (
            <div className={`${styles.item} ${styles.availability}`}>
              <p
                className={classNames(columnHeadersClass, 'lgu:hidden mx-auto')}
              >
                {messages.availability}
              </p>
              {availabilityComponent(item.assetNumber)}
            </div>
          )}
          {!simplified && !distributorFlow && (
            <PriceComponent
              editPrice={editPrice}
              originalPrice={
                showBasePrice
                  ? unitBasePriceFormatted
                  : unitPriceWithoutDiscountFormatted
              }
              price={
                showBasePrice
                  ? unitBasePriceFormatted
                  : unitPriceWithDiscountFormatted
              }
              disabled={disableInteractions}
              toggleEditPrice={toggleEditPrice}
              headersClass={columnHeadersClass}
              variant={priceDiscrepancyError ? 'warning' : 'default'}
              tooltipText={
                priceDiscrepancyError
                  ? messages.priceDiscrepancyExplainer
                  : undefined
              }
            />
          )}

          {!distributorFlow && (
            <div
              data-cy="item-total"
              className={`${styles.item} ${styles.itemTotal}`}
            >
              <p
                className={classNames(columnHeadersClass, 'lgu:hidden', {
                  hidden: simplified,
                })}
              >
                {messages.itemTotal}
              </p>
              <div className="lgu:my-auto mdd:mt-auto">
                <PriceBase
                  originalPrice={
                    showBasePrice
                      ? itemBaseTotalFormatted
                      : itemTotalWithoutDiscountFormatted
                  }
                  price={
                    showBasePrice
                      ? itemBaseTotalFormatted
                      : itemTotalWithDiscountFormatted
                  }
                  disabled={disableInteractions}
                />
              </div>
            </div>
          )}
          <div className={`${styles.itemActions} flex justify-self-end`}>
            {enableActionDropdown && (
              <div className={styles.cell}>
                <ItemActionsDropdown
                  disabled={disableInteractions}
                  onActionClick={(action) => {
                    if (action === ItemAction.LinePONumber) {
                      togglePurchaseOrderModal()
                    }
                    if (action === ItemAction.CustomerLineNumber) {
                      toggleCustomerNumberModal()
                    }
                    if (action === ItemAction.RequestSpecificLot) {
                      setRequestLotModalType(
                        LotSpecificOrderType.THIS_SPECIFIC_LOT
                      )
                      toggleRequestLotModal()
                    }
                    if (action === ItemAction.ExcludeSpecificLot) {
                      setRequestLotModalType(
                        LotSpecificOrderType.NOT_THIS_SPECIFIC_LOT
                      )
                      toggleRequestLotModal()
                    }
                    if (action === ItemAction.AllWithinSameLot) {
                      onAssetUpdate({
                        assetNumber: item?.assetNumber,
                        lineNumber: item?.lineNumber,
                        quantity: item?.quantity,
                        publicAssetCode: item?.publicAssetCode,
                        updateType: updateType.LOT_REQUEST,
                        lotSpecificOrder: {
                          lotSpecificOrderType:
                            LotSpecificOrderType.ALL_ONE_LOT,
                        },
                      })
                    }
                  }}
                />
              </div>
            )}
            <div className={classNames('my-auto', styles.cell)}>
              <Button
                variant="textOnly"
                size="medium"
                data-cy="delete-item"
                disabled={disableInteractions}
                iconButton={true}
                onClick={() => {
                  if (item) {
                    deleteOnClick({
                      itemToDelete: item,
                      price: unitPriceWithDiscountFormatted,
                    })
                  }
                }}
                data-testid="delete-item"
              >
                <Bin color="#D43737" />
              </Button>
            </div>
          </div>

          {distributorFlow && isStockMessageEnabled && (
            <div
              className="my-auto col-span-full text-green text-body-xmedium"
              data-testid="stock-details"
            >
              {inStockMessage}
            </div>
          )}
          {!distributorFlow && (
            <div className={classNames(styles.itemInfo)}>
              <div className="flex flex-wrap justify-start mb-2 gap-y-1">
                <DeliveryInfo className="my-auto mr-2" type={deliveryType} />
                <div className="flex flex-wrap my-auto mr-2 font-normal text-black-0 text-body-xmedium">
                  {isDiscontinued &&
                    !simplified &&
                    `${messages.productDiscontinued}, `}
                  {!isMadeToOrder && !isQuote && (
                    <div
                      data-testid="stock-details"
                      className={classNames(stockDetails.color, 'mr-1')}
                    >
                      {stockDetails.text}.
                    </div>
                  )}
                  <StockNote
                    variant={StockNoteVariant.basket}
                    shouldShowStockNote={shouldShowStockNote}
                    shouldReplaceGOPMessage={shouldReplaceGOPMessage}
                    isMadeToOrder={isMadeToOrder}
                    toolTipMessage={toolTipMessage}
                    toolTipTitle={toolTipTitle}
                    deliveryMessage={
                      <span
                        data-testid="delivery-message"
                        className="text-green"
                      >
                        {deliveryMessage}
                      </span>
                    }
                  />
                </div>
                {isDiscontinued && simplified && (
                  <DiscontinuedAlert simplified />
                )}
              </div>
              {showLQL && item?.largeQuantityLine && (
                <OrderTag type={OrderTagEnum.LQL} />
              )}
              <div className="flex flex-wrap">
                {enableActionDropdown && customerPurchaseOrderNumber && (
                  <div className="flex" data-testid="line-item-po-info">
                    <InfoIcon
                      className="my-auto fill-blue-default"
                      height={16}
                      width={16}
                    />
                    <span className="my-auto ml-2 font-normal text-body-small">
                      {messages.lineItemPoNumber}
                      {': '}
                      {customerPurchaseOrderNumber}
                    </span>
                    <Button
                      disabled={disableInteractions}
                      onClick={() => {
                        onAssetUpdate({
                          assetNumber: item?.assetNumber,
                          lineNumber: item?.lineNumber,
                          publicAssetCode: item?.publicAssetCode,
                          quantity: item?.quantity,
                          updateType: updateType.CUSTOMER_PO_NUMBER,
                          customerPurchaseOrderNumber: null,
                        })
                      }}
                      className="px-2 my-auto text-negative"
                      size="xsmall"
                      variant="textOnly"
                    >
                      {messages.customerPoNumberClear}
                    </Button>
                  </div>
                )}
                {enableActionDropdown && customerLineNumber && (
                  <div className="flex" data-testid="customer-line-number-info">
                    <InfoIcon
                      className="my-auto fill-blue-default"
                      height={16}
                      width={16}
                    />
                    <span className="my-auto ml-2 font-normal text-body-small">
                      {messages.lineItemCustomerLineNumber}
                      {': '}
                      {customerLineNumber}
                    </span>
                    <Button
                      disabled={disableInteractions}
                      data-testid="clear-customer-line-number-button"
                      onClick={() => {
                        onAssetUpdate({
                          assetNumber: item?.assetNumber,
                          lineNumber: item?.lineNumber,
                          publicAssetCode: item?.publicAssetCode,
                          quantity: item?.quantity,
                          updateType: updateType.CUSTOMER_LINE_NUMBER,
                          customerLineNumber: null,
                        })
                      }}
                      className="px-2 my-auto text-negative"
                      size="xsmall"
                      variant="textOnly"
                    >
                      {messages.customerPoNumberClear}
                    </Button>
                  </div>
                )}

                {editPriceReason && (
                  <div className="flex" data-testid="price-edit-reason">
                    <span className="my-auto text-blue-default">
                      <InfoIcon
                        className="fill-current"
                        width={16}
                        height={16}
                      />
                    </span>
                    <span className="my-auto ml-2 font-normal text-body-small">
                      {priceAdjustmentDescription}
                    </span>
                    <Button
                      disabled={disableInteractions}
                      onClick={() => {
                        onAssetUpdate({
                          assetNumber: item?.assetNumber,
                          lineNumber: item?.lineNumber,
                          publicAssetCode: item?.publicAssetCode,
                          quantity: item?.quantity,
                          updateType: updateType.PRICE_ADJUSTMENT,
                          customerPurchaseOrderNumber:
                            item?.customerPurchaseOrderNumber,
                          priceAdjustment: null,
                        })
                      }}
                      className="px-2 my-auto text-negative"
                      bodyClassName="text-ui-small"
                      size="medium"
                      variant="textOnly"
                    >
                      {messages.resetPrice}
                    </Button>
                  </div>
                )}
                {enableActionDropdown && item.lotSpecificOrder && (
                  <BasketItemLotStrategy
                    onClear={() => {
                      onAssetUpdate({
                        assetNumber: item?.assetNumber,
                        lineNumber: item?.lineNumber,
                        publicAssetCode: item?.publicAssetCode,
                        quantity: item?.quantity,
                        updateType: updateType.LOT_REQUEST,
                        lotSpecificOrder: null,
                      })
                    }}
                    disableInteractions={disableInteractions}
                    lotStrategy={
                      item.lotSpecificOrder
                        ?.lotSpecificOrderType as LotSpecificOrderTypeForStrategy
                    }
                    lotNumber={
                      'lotNumber' in item.lotSpecificOrder
                        ? item?.lotSpecificOrder.lotNumber
                        : undefined
                    }
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {!(isDiscontinued || hideSeparator) && (
        <div className={`${styles.item} ${styles.separator}`}>
          <hr className="h-px border-0 bg-interactive-black-transparent-active" />
        </div>
      )}
      <Modal
        header={messages.editItemPrice}
        onClose={toggleEditPrice}
        show={openEditPrice}
        width="566px"
      >
        {priceDiscrepancyError && (
          <Notification
            dataCy={`basket-validation-notification`}
            className="mx-6 mt-6 font-normal"
            multiline
            variant={NOTIFICATION_VARIANT.negative}
            size="medium"
          >
            <span>{messages.priceDiscrepancyNotification}</span>
          </Notification>
        )}

        {showValidatePriceEditNotification && (
          <ValidatePriceEditNotification className="mx-6 mt-6 mb-2" />
        )}
        {openEditPrice && (
          <EditPrice
            onClose={toggleEditPrice}
            onSave={({ price, reason }) => {
              onAssetUpdate({
                assetNumber: item?.assetNumber,
                lineNumber: item?.lineNumber,
                publicAssetCode: item?.publicAssetCode,
                quantity: item?.quantity,
                updateType: updateType.PRICE_ADJUSTMENT,
                customerPurchaseOrderNumber: item?.customerPurchaseOrderNumber,
                priceAdjustment: {
                  price,
                  reason,
                  type: AdjustmentType.DISCOUNT_AMOUNT,
                },
              })
            }}
            overrideReason={cmoAdjustment?.reason.type}
            prices={item?.prices as ItemPrices}
          />
        )}
      </Modal>

      {enableActionDropdown && (
        <EditPONumberModal
          isOpen={purchaseOrderModal}
          onClose={togglePurchaseOrderModal}
          defaultValue={item.customerPurchaseOrderNumber}
          loading={disableInteractions}
          onSave={(customerPurchaseOrderNumber) => {
            onAssetUpdate(
              {
                assetNumber: item?.assetNumber,
                lineNumber: item?.lineNumber,
                publicAssetCode: item?.publicAssetCode,
                quantity: item?.quantity,
                updateType: updateType.CUSTOMER_PO_NUMBER,
                customerPurchaseOrderNumber,
              },
              togglePurchaseOrderModal
            )
          }}
        />
      )}
      {enableActionDropdown && (
        <EditCustomerLineNumberModal
          isOpen={customerNumberModal}
          onClose={toggleCustomerNumberModal}
          defaultValue={item.customerLineNumber}
          loading={disableInteractions}
          onSave={(customerLineNumber) => {
            onAssetUpdate(
              {
                assetNumber: item?.assetNumber,
                lineNumber: item?.lineNumber,
                publicAssetCode: item?.publicAssetCode,
                quantity: item?.quantity,
                updateType: updateType.CUSTOMER_LINE_NUMBER,
                customerLineNumber,
              },
              toggleCustomerNumberModal
            )
          }}
        />
      )}
      {enableActionDropdown && requestLotModalType && (
        <EditLotNumberModal
          isOpen={requestLotModal}
          onClose={toggleRequestLotModal}
          requestLotModalType={requestLotModalType}
          loading={disableInteractions}
          onSave={(lotNumber) => {
            requestLotModalType &&
              onAssetUpdate(
                {
                  assetNumber: item?.assetNumber,
                  lineNumber: item?.lineNumber,
                  quantity: item?.quantity,
                  publicAssetCode: item?.publicAssetCode,
                  updateType: updateType.LOT_REQUEST,
                  lotSpecificOrder: {
                    lotNumber,
                    lotSpecificOrderType: requestLotModalType,
                  },
                },
                toggleRequestLotModal
              )
          }}
        />
      )}
    </>
  )
}

export { BasketItem }
