import { POSTCODE_REGEXES } from '../data'
import { BaseAddressSchema } from './base'

export const JPAddressSchema = {
  ...BaseAddressSchema,

  postcode: {
    type: 'string',
    required: true,
    // combining all regexes in logical OR
    pattern: new RegExp(
      POSTCODE_REGEXES['JP'].map((exp) => `^${exp}$`).join('|'),
      'i'
    ),
  },

  state: {
    type: 'string',
    required: true,
    oneOf: [
      '山形県',
      '東京都',
      '福岡県',
      '佐賀県',
      '神奈川県',
      '群馬県',
      '兵庫県',
      '静岡県',
      '埼玉県',
      '愛知県',
      '岩手県',
      '大阪府',
      '茨城県',
      '長野県',
      '秋田県',
      '広島県',
      '岡山県',
      '京都府',
      '青森県',
      '熊本県',
      '千葉県',
      '岐阜県',
      '北海道',
      '山梨県',
      '新潟県',
      '宮城県',
      '栃木県',
      '滋賀県',
      '石川県',
      '三重県',
      '愛媛県',
      '富山県',
      '徳島県',
      '山口県',
      '香川県',
      '鹿児島県',
      '福井県',
      '福島県',
      '大分県',
      '鳥取県',
      '宮崎県',
      '高知県',
      '和歌山県',
      '奈良県',
      '沖縄県',
      '島根県',
      '長崎県',
    ],
  },
} as const

export type JPAddressSchemaType = typeof JPAddressSchema

export default JPAddressSchema
