import { STAGE } from './stage-type'

export const CHINESE_LOCALE = 'zh-cn'

export const BASE_URL_BY_DOMAIN: Record<STAGE, string> = {
  local: 'http://localhost:4200/',
  test: 'https://test.abcam.com/',
  dev: 'https://dev.abcam.com/',
  staging: 'https://staging.abcam.com/',
  preprod: 'https://pp.abcam.com/',
  prod: 'https://www.abcam.com/',
  author_prod: 'https://www.abcam.com/',
}

export const BASE_CHINESE_URL_BY_DOMAIN: Record<STAGE, string> = {
  local: 'http://localhost:4200/',
  test: 'https://test.abcam.cn/',
  dev: 'https://dev.abcam.cn/',
  staging: 'https://staging.abcam.cn/',
  preprod: 'https://pp.abcam.cn/',
  prod: 'https://www.abcam.cn/',
  author_prod: 'https://www.abcam.cn/',
}

export const AUTH_BASE_URL = 'auth/'
export const ACCOUNT_BASE_URL = 'my-account/'
