import { addressFields, Schema } from '@abcam-web/address-validator'
import {
  AddressDetails,
  OrderAddressModel,
} from '@abcam-web/shared/data-access/ecommerce-schema'
import { PageSubtype } from '@abcam-web/shared/data-access/tracking'
import { debounce, isNil, omitBy } from 'lodash'
import {
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { AddressFieldset } from '../address-fieldset'

interface ShippingAddressProps {
  address?: AddressDetails | OrderAddressModel

  onAddressChange: (
    address: OrderAddressModel,
    isHistoricalAddress: boolean
  ) => void
  addresses: AddressDetails[]
  pageSubType: PageSubtype
}

export const matchAddress = (
  addressOne?: OrderAddressModel | AddressDetails,
  addressTwo?: OrderAddressModel | AddressDetails
) => {
  const historicalAddressFieldsToCompare = [
    'addressFusionId',
    'organisationRegistryId',
    'organisationName',
  ]
  if (addressOne && addressTwo) {
    const addressOneWithoutNil = omitBy(addressOne, isNil)
    const addressTwoWithoutNil = omitBy(addressTwo, isNil)

    const hasMismatch = [
      ...addressFields,
      ...historicalAddressFieldsToCompare,
    ].some((fieldName) => {
      /**
       * When user modifies historical address, then both `addressFusionId` and `organisationRegistryId` are set to `undefined`
       * if user reverted those changes we want to mark the address as match and pass addressFusionId and organisationRegistryId
       */
      if (historicalAddressFieldsToCompare.includes(fieldName)) {
        if (
          addressOneWithoutNil[fieldName] &&
          addressTwoWithoutNil[fieldName]
        ) {
          return (
            addressOneWithoutNil[fieldName] !== addressTwoWithoutNil[fieldName]
          )
        } else {
          return false
        }
      } else {
        return (
          addressOneWithoutNil[fieldName] !== addressTwoWithoutNil[fieldName]
        )
      }
    })
    return !hasMismatch
  }
  return false
}

function isKey<T extends object>(x: T, k: PropertyKey): k is keyof T {
  return k in x
}

export const HistoricalAddressFieldset: FC<ShippingAddressProps> = ({
  addresses = [],
  address,
  onAddressChange = () => null,
  pageSubType,
}) => {
  // using ref to keep track of current address changes as prop is only updated when address is selected
  const currentAddress = useRef(address)
  const [formKey, setFormKey] = useState(0)

  // updating ref if prop is updated
  useEffect(() => {
    if (!matchAddress(address, currentAddress.current)) {
      currentAddress.current = address
    }
  }, [address])

  const debouncedAddressFieldSetChangeHandler = useMemo(
    () =>
      debounce((e: FormEvent, selectedSchema?: Schema) => {
        let schemaMatchingAddress = address || ({} as any)
        if (selectedSchema && address) {
          //When schema changes we want to drop fields from previous schema that does not matches new schema
          schemaMatchingAddress = {}
          Object.keys(selectedSchema).forEach((key) => {
            if (isKey(address, key)) {
              schemaMatchingAddress[key] = address[key]
            }
          })
        }
        const fieldName = (e.target as HTMLInputElement).name
        const fieldNewValue = (e.target as HTMLInputElement).value
        const defaultFieldValue =
          currentAddress.current?.[fieldName as keyof typeof address]

        if (fieldNewValue !== defaultFieldValue) {
          const newAddress = {
            ...schemaMatchingAddress,
            [fieldName]: fieldNewValue,
          }
          const matchingHistoricalAddress = addresses.find((item) =>
            matchAddress(newAddress, item)
          )
          const modifiedAddress = {
            ...newAddress,
            addressFusionId: undefined,
            organisationRegistryId: undefined,
          }

          const isHistoricalAddress = Boolean(matchingHistoricalAddress)

          const updatedAddress = matchingHistoricalAddress || modifiedAddress

          currentAddress.current = updatedAddress
          onAddressChange(updatedAddress, isHistoricalAddress)
        }
      }, 500),
    [address, addresses, onAddressChange]
  )
  const addressFieldSetChangeHandler = useCallback(
    (e: FormEvent, selectedSchema?: Schema) =>
      debouncedAddressFieldSetChangeHandler(e, selectedSchema),
    [debouncedAddressFieldSetChangeHandler]
  )

  useEffect(() => {
    if (address?.addressFusionId) {
      setFormKey((prev) => prev + 1)
    }
  }, [address?.addressFusionId])

  return (
    <AddressFieldset
      defaultValues={address}
      onChange={addressFieldSetChangeHandler}
      key={`address-${formKey}`}
      className="mt-4 gap-5"
      labelClassName="text-body-small"
      inputClassName="text-body-small h-12"
      menuClassName="!max-h-[300px]"
      schemaOverride={{
        organisationName: undefined,
      }}
      pageSubType={pageSubType}
    />
  )
}
