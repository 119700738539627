import { CloseIcon } from '@lego/ui/icons'

interface Props {
  onClick: () => void
  dataCy?: string
}

const CloseButton = ({ onClick, dataCy }: Props) => {
  return (
    <button
      aria-label="Close"
      onClick={onClick}
      className="absolute p-2 text-lg rounded-full outline-none top-4 right-4 focus-visible:shadow-interactiveElement hover:bg-grey-10"
      data-cy={dataCy}
    >
      <CloseIcon />
    </button>
  )
}

export { CloseButton }
