export const oneTimePassword = {
  otp: {
    component: 'otp_component',
    value: 'otp_value',
    value_hidden: 'otp_value_hidden_input',
  },
  resend: 'button_resendOtp',
  submit: 'button_continue',
  notification: {
    error: 'error_oneTimePassword_notification',
    success: 'success_oneTimePassword_notification',
  },
  email: 'text_email',
}
