import { Button } from '@lego/ui/button'
import { SimpleTextInput } from '@lego/ui/form-elements'
import { SpinnerOffset } from '@lego/ui/icons'
import { Modal } from '@lego/ui/modal'
import { FC, FormEvent, useCallback, useState } from 'react'

interface Props {
  testTagPrefix: string
  headerText: string
  saveText?: string
  cancelText?: string
  subheaderText?: string
  inputLabelText?: string
  inputPlaceholderText?: string
  loading?: boolean
  isOpen: boolean
  onSave: (customerPurchaseOrderNumber: string) => void
  onClose: () => void
  defaultValue?: string
}

const EditLinePropertyInputModal: FC<Props> = ({
  testTagPrefix,
  loading,
  isOpen,
  onSave,
  onClose,
  defaultValue = '',
  headerText,
  saveText = 'Save',
  cancelText = 'Cancel',
  subheaderText,
  inputLabelText,
  inputPlaceholderText,
}) => {
  const [inputValue, setInputValue] = useState(defaultValue)

  const onChangeValueCallback = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement
      setInputValue(target.value)
    },
    []
  )

  const onSaveCallback = useCallback(() => {
    onSave(inputValue)
  }, [inputValue, onSave])

  const onCloseCallback = useCallback(() => {
    setInputValue(defaultValue || '')

    onClose()
  }, [onClose, defaultValue])

  return (
    <Modal
      header={headerText}
      onClose={onCloseCallback}
      show={isOpen}
      width="566px"
    >
      <div className="p-6" data-testid={`${testTagPrefix}-modal`}>
        {subheaderText && (
          <p className="mb-3 text-body-small">{subheaderText}</p>
        )}
        <SimpleTextInput
          className="w-1/2"
          placeholder={inputPlaceholderText}
          inputClassName="text-body-small"
          label={inputLabelText}
          value={inputValue ?? ''}
          onChange={onChangeValueCallback}
          data-testid={`${testTagPrefix}-input`}
        />
        <div className="flex justify-end">
          <Button
            onClick={onCloseCallback}
            variant="textOnly"
            size="small"
            data-testid={`${testTagPrefix}-cancel-button`}
            disabled={loading}
          >
            {cancelText}
          </Button>
          <Button
            iconLeft={
              loading && <SpinnerOffset className="ml-3 animate-spin" />
            }
            size="small"
            data-testid={`${testTagPrefix}-confirm-button`}
            disabled={loading || inputValue === ''}
            onClick={onSaveCallback}
          >
            {saveText}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export { EditLinePropertyInputModal }
