import { useId, useState } from 'react'
import formElementStyles from '../styles.module.css'
import style from './simpleTextArea.module.css'
import type { FormEvent, HTMLProps, ReactNode } from 'react'

interface Props extends HTMLProps<HTMLTextAreaElement> {
  label?: string
  hint?: ReactNode
  testTag?: string
  errorText?: string
  hideRequiredFieldAsterisk?: boolean
}

export const SimpleTextArea = ({
  label,
  hint,
  className = '',
  name,
  required,
  errorText,
  testTag,
  hideRequiredFieldAsterisk,
  ...rest
}: Props) => {
  const [validationMessage, setValidationMessage] = useState<string>()
  const id = useId()

  const onInvalid = (e: FormEvent) => {
    const target = e.target as HTMLTextAreaElement
    setValidationMessage(target.validationMessage)
  }

  const onChange = (e: FormEvent) => {
    if (validationMessage) {
      const target = e.target as HTMLTextAreaElement
      setValidationMessage(target.validationMessage)
    }
  }

  return (
    <div className="flex flex-col w-full gap-1">
      {label && (
        <label
          className={`font-semibold ${
            required && !hideRequiredFieldAsterisk
              ? formElementStyles['required']
              : ''
          }`}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      {hint && <p className="text-grey-20">{hint}</p>}
      <textarea
        className={`w-full text-base appearance-none text-black-0 py-3 pl-5 my-1 align-middle outline-none bg-interactive-black-transparent-hover rounded-xs focus:bg-white focus:shadow-interactiveElement hover:bg-interactiveGreyTransparentActive7 resize-none
        ${className}
        ${style.simpletextarea}`}
        id={id}
        onInvalid={onInvalid}
        onChange={onChange}
        name={name}
        required={required}
        aria-required={required}
        data-testid={testTag}
        {...rest}
      />
      {!!validationMessage && (
        <div
          data-testid="simple-text-validation-message"
          className="text-negative text-body-small"
        >
          {errorText === undefined ? validationMessage : errorText}
        </div>
      )}
    </div>
  )
}
