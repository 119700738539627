import { Amplify } from 'aws-amplify'
import { getAwsAmplifyConfig } from './getAwsAmplifyConfig'
import { initAmplifyLocalStorage } from '@abcam-web/amplify-secure-js'
import { getAuthenticationHost } from './util/getAuthenticationHost'
import { STAGE } from '../constants/stage-type'

export const configureAuth = (stage: STAGE = 'prod'): void => {
  const authHost = getAuthenticationHost(stage)
  Amplify.configure({
    ...getAwsAmplifyConfig(stage),
    storage: initAmplifyLocalStorage(authHost),
  })
}
