import { useIntl } from 'react-intl'

export const SkipToMainContent = () => {
  const { formatMessage } = useIntl()
  return (
    <p className="sr-only">
      <a href="#main-content">
        {formatMessage({ id: 'common.skipToMainContent' })}
      </a>
    </p>
  )
}
