import {
  DiscountReason,
  ItemPrices,
  Price,
  PriceAdjustment,
  PriceAdjustmentReason,
} from '@abcam-web/shared/data-access/ecommerce-schema'
import {
  editPriceReasons,
  useFormattedPrice,
} from '@abcam-web/shared/ecommerce/utilities'
import { Button } from '@lego/ui/button'
import { Checkbox } from '@lego/ui/checkbox'
import { Select } from '@lego/ui/select'
import { TextInput } from '@lego/ui/text-input'
import classNames from 'classnames'
import { FC, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'

export interface EditPriceProps {
  prices: ItemPrices
  overrideReason?: string
  onClose: () => void
  onSave: ({
    price,
    reason,
  }: {
    price: Price
    reason: PriceAdjustmentReason
  }) => void
}

interface FOCR {
  itemNo: number
  orderNumber: string
}

const EditPrice: FC<EditPriceProps> = ({
  prices,
  overrideReason,
  onClose = () => null,
  onSave = () => null,
}) => {
  const { formatMessage } = useIntl()
  const [priceValue, setPriceValue] = useState<string>('')
  const [shownPrice, setShownPrice] = useState<string>()
  const [isFOCR, setIsFOCR] = useState<boolean>(false)
  const [FOCR, setFOCR] = useState<Partial<FOCR>>()
  const [reason, setReason] = useState<DiscountReason>()
  const basePrice = useFormattedPrice(prices?.base)
  const lastAdjustmentsIndex =
    (prices?.adjustments as PriceAdjustment[])?.length - 1
  const specialPrice = useFormattedPrice(
    prices?.adjustments?.[lastAdjustmentsIndex]?.price as Price | undefined
  )

  const currency = prices?.base?.currency
  const inputEntered = isFOCR
    ? FOCR?.itemNo && FOCR?.orderNumber && !!reason
    : !!priceValue && !!reason
  const inputPrice = useFormattedPrice({
    value: parseFloat(priceValue) || 0,
    currency: prices?.base?.currency,
  })

  const currentPriceReason =
    editPriceReasons?.find((item) => item.key === overrideReason)
      ?.displayValue || 'Cap price'

  const reasonLabel = isFOCR ? 'Reason for FOCR' : 'Discount Reason'

  const currentEditPriceReasons = useMemo(
    () => editPriceReasons.filter((item) => item.focr === isFOCR) || [],
    [isFOCR]
  )

  return (
    <div className="px-6 py-4">
      <div className="p-4 font-semibold rounded border-1_2 text-body-medium">
        <div className="flex text-grey-20">
          <span className="w-1/3">
            {formatMessage({ id: 'editPrice.basePrice' })}
          </span>
          <span className="mr-auto font-normal " data-cy="base-price">
            {basePrice}
          </span>
        </div>
        <div className="flex mt-1 text-black-0">
          <span className="w-1/3">
            {formatMessage({ id: 'editPrice.currentPrice' })}
          </span>
          <span className="mr-auto font-normal" data-cy="current-price">
            {specialPrice} ({currentPriceReason})
          </span>
        </div>
      </div>
      <div>
        <div className="my-6 font-normal text-body-small text-grey-20">
          {formatMessage({ id: 'editPrice.allFieldsRequired' })}
        </div>
        <TextInput
          dataCy="new-price-input"
          disabled={isFOCR}
          inputType="text"
          label={formatMessage({ id: 'editPrice.newPriceLabel' })}
          onBlur={() => {
            setShownPrice(inputPrice)
          }}
          onChange={({ value }) => {
            setPriceValue(value)
          }}
          onFocus={() => {
            setShownPrice(undefined)
          }}
          onKeyDown={(event: any) => {
            const isSelectAll =
              event.key === 'a' && (event.ctrlKey || event.metaKey)
            const isDot = event.key === '.'
            const isBackspace = event.key === 'Backspace'
            const thereAreMoreDots =
              event.key === '.' && priceValue.includes('.')
            const isArrow = ['ArrowLeft', 'ArrowRight'].includes(event.key)
            if (
              (!isDot &&
                !isArrow &&
                !isBackspace &&
                !isSelectAll &&
                !parseFloat(event.key) &&
                event.key !== '0') ||
              thereAreMoreDots
            ) {
              event.preventDefault()
            }
          }}
          value={shownPrice || priceValue}
        />
        <Checkbox
          label={formatMessage({ id: 'editPrice.isFocReplacement' })}
          onChange={(value) => {
            setIsFOCR(value)
          }}
        />
        {isFOCR && (
          <>
            <TextInput
              dataCy="input-order-number"
              label={formatMessage({ id: 'editPrice.orderNumber' })}
              onChange={({ value }) => {
                setFOCR((prev) => ({ ...prev, orderNumber: value }))
              }}
            />
            <TextInput
              dataCy="input-item-line-number"
              inputType="number"
              label={formatMessage({ id: 'editPrice.itemLineNumber' })}
              onChange={({ value }) => {
                setFOCR((prev) => ({
                  ...prev,
                  itemNo: parseInt(value),
                }))
              }}
            />
          </>
        )}
        <div className="my-6">
          <label className="text-body-small">{reasonLabel}</label>
          <div className="mb-2" />
          <Select
            disableMaxOption
            listMaxHeight={isFOCR ? '11.50rem' : '16rem'}
            onChange={(val: any) => {
              setReason(val.key as DiscountReason)
            }}
            options={currentEditPriceReasons}
            testTag="discount-reason"
          />
        </div>
        <div className="flex">
          {!!inputEntered && (
            <Button
              className={classNames(
                { 'ml-auto': inputEntered },
                'text-negative'
              )}
              onClick={() => {
                onClose()
              }}
              size="medium"
              variant="textOnly"
            >
              {formatMessage({ id: 'editPrice.cancel' })}
            </Button>
          )}
          <Button
            className={classNames({ 'ml-auto': !inputEntered })}
            dataCy={inputEntered ? 'save-and-close' : 'close'}
            onClick={() => {
              if (inputEntered && reason) {
                if (isFOCR && FOCR?.itemNo && FOCR?.orderNumber) {
                  onSave({
                    price: {
                      value: 0,
                      currency: currency,
                    },
                    reason: {
                      type: reason,
                      replacement: {
                        lineNumber: FOCR.itemNo.toString(),
                        orderNumber: FOCR.orderNumber,
                      },
                    },
                  })
                } else {
                  onSave({
                    price: {
                      value: parseFloat(priceValue),
                      currency: prices.base.currency,
                    },
                    reason: {
                      type: reason,
                    },
                  })
                }
              }
              onClose()
            }}
            size="medium"
          >
            {inputEntered
              ? formatMessage({ id: 'editPrice.saveAndClose' })
              : formatMessage({ id: 'editPrice.close' })}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default EditPrice
