import {
  StockNotes,
  StockNotesCodeValue,
  StockNotesTypeValue,
} from '@abcam-web/shared/data-access/ecommerce-schema'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'

export interface UseStockNotesResult {
  shouldShowStockNote: boolean
  shouldReplaceGOPMessage: boolean
  isMadeToOrder: boolean
  toolTipMessage: string
  toolTipTitle: string
}

const mappedCodeToi18Id = {
  [StockNotesCodeValue.Unknown_Product_Availability]: 'message.101',
  [StockNotesCodeValue.Supplier_Delays_and_Disruptions]: 'message.102',
  [StockNotesCodeValue.Products_with_Incomplete_Data_Setup]: 'message.103',
  [StockNotesCodeValue.Manufacturing_Issues]: 'message.104',
  [StockNotesCodeValue.Conjugates_on_Demand]: 'message.105',
  [StockNotesCodeValue.Quarantine]: 'message.106',
  [StockNotesCodeValue.Made_to_Order_Cell_Lines]: 'message.108',
  [StockNotesCodeValue.Info_on_Moving_from_Non_recombinants_to_Recombinants]:
    'message.109',
}

/**
 * @warning this hook can only be used in components wrapped with `IntlProvider` from `react-intl` as it was implemented with translation scalability in mind.
 * @description Custom hook to process stock notes and determine messages and titles for display based on stock conditions.
 * Uses memoization to optimize performance by recalculating only when necessary.
 * @param {StockNotes[]} stockNotes array of one stock note object if it had more than one object we should pick the first one.
 * @returns {UseStockNotesResult} returns flags used to display stock notes messages and toolTips with texts needed for the title and message
 */
export const useStockNotes = (stockNotes?: StockNotes): UseStockNotesResult => {
  const { formatMessage } = useIntl()
  return useMemo(() => {
    let toolTipMessage = ''
    let toolTipTitle = ''
    let shouldReplaceGOPMessage = false
    let shouldShowStockNote = false
    let isMadeToOrder = false
    /**
     * @AbishekSukumaran
     * Stock notes are entered in Product Hub in Oracle Fusion in a page called Disruption Notes.
     * As per the current design we can only enter one at a time. So multiple stock notes for an item-org combination is not possible.
     * Courier service returns if a valid stock note exists for the item-org that is being queried
     * current criteria defines validity of stock note:
     * 1. Start date should be after the current date
     * 2. End date (Optional) if exists should be beyond the current date.
     * 3. If the "visible only when out of stock" flag is enabled, stock note is to be returned only if the item is out of stock in that warehouse.
     */
    const stockNote = stockNotes?.[0]

    if (stockNote && stockNote.stockNotesCode) {
      toolTipTitle = formatMessage({ id: 'title.general' })
      toolTipMessage = formatMessage({
        id: mappedCodeToi18Id[stockNote.stockNotesCode],
      })
      if (
        stockNote.stockNotesCode ===
        StockNotesCodeValue.Made_to_Order_Cell_Lines
      ) {
        toolTipTitle = formatMessage({
          id: `title.108`,
        })
        isMadeToOrder = true
      }
    }

    if (
      stockNote &&
      stockNote.stockNotesType === StockNotesTypeValue.Override
    ) {
      shouldReplaceGOPMessage = true
    }

    shouldShowStockNote = Boolean(toolTipTitle && toolTipMessage)

    return {
      shouldReplaceGOPMessage,
      shouldShowStockNote,
      isMadeToOrder,
      toolTipMessage,
      toolTipTitle,
    }
  }, [formatMessage, stockNotes])
}
