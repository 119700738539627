import type { STAGE } from '@abcam-web/auth-shared/constants/stage-type'

export const cognitoConfig: Record<STAGE, Record<string, string>> = {
  local: {
    COGNITO_USER_POOL_ID: 'eu-west-1_297RWg8JL',
    COGNITO_USER_POOL_WEB_CLIENT_ID: '3f3hickn2ajqvn4qij25hnfss2',
  },
  test: {
    COGNITO_USER_POOL_ID: 'eu-west-1_297RWg8JL',
    COGNITO_USER_POOL_WEB_CLIENT_ID: '3f3hickn2ajqvn4qij25hnfss2',
  },
  dev: {
    COGNITO_USER_POOL_ID: 'eu-west-1_297RWg8JL',
    COGNITO_USER_POOL_WEB_CLIENT_ID: '3f3hickn2ajqvn4qij25hnfss2',
  },
  staging: {
    COGNITO_USER_POOL_ID: 'eu-west-1_jXdRKBlDY',
    COGNITO_USER_POOL_WEB_CLIENT_ID: '598cp62569eglif0g8c9vsbhv2',
  },
  preprod: {
    COGNITO_USER_POOL_ID: 'eu-west-1_3kQP11LBB',
    COGNITO_USER_POOL_WEB_CLIENT_ID: '6867rs0fc1rqdlaq1kpekimu6l',
  },
  author_prod: {
    COGNITO_USER_POOL_ID: 'eu-west-1_KBGn73YU9',
    COGNITO_USER_POOL_WEB_CLIENT_ID: '1sfrqi9v6h1tc9nfnleli0dmkb',
  },
  prod: {
    COGNITO_USER_POOL_ID: 'eu-west-1_KBGn73YU9',
    COGNITO_USER_POOL_WEB_CLIENT_ID: '1sfrqi9v6h1tc9nfnleli0dmkb',
  },
}
