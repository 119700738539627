import { tags } from '@tagging-shared/browse'
import { useErrorsConfig as defaultUseErrorsConfig } from './useErrorsConfig'
import { Button } from '@lego/ui/button'
import type { ErrorProps } from './error.type'
import { CircleArrows } from '@lego/ui/icons'
import { useIntl } from 'react-intl'

export const ErrorComponent = ({
  errorCode,
  title,
  subTitle,
  description,
  button,
  onClick,
  useErrorsConfig = defaultUseErrorsConfig,
}: ErrorProps) => {
  const errorConfig = useErrorsConfig(errorCode!, onClick)

  const { formatMessage } = useIntl()

  const goBack = () => {
    window.history.back()
  }

  return (
    <div className="w-full m-auto mt-10 mb-16 bg-white text-black-0 max-w-7xl">
      <div
        className="flex flex-col px-12.5% lgd:px-6.25% smd:px-5.25%"
        data-cy="abcam-error"
      >
        <h1 className="font-semibold text-black-0 text-heading-xlarge font-header">
          {title || errorConfig?.title || formatMessage({ id: 'error.oops' })}
        </h1>
        <h2
          className="text-base py-1_5 text-black-0 text-heading-large font-header"
          data-cy={tags.commonTags.errorText}
        >
          {subTitle ||
            errorConfig?.subTitle ||
            formatMessage({ id: 'error.unexpected' })}
        </h2>
        <div className="mb-6 text-body-large">
          {description ||
            errorConfig?.description ||
            formatMessage({ id: 'error.problemReported' })}
        </div>
        <div className="flex gap-6">
          {button || errorConfig?.button || (
            <Button
              iconLeft={<CircleArrows />}
              onClick={onClick || goBack}
              size="medium"
              variant="primary"
            >
              {formatMessage({ id: 'error.tryAgain' })}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
