import React from 'react'

const useToggle = (initialState = false) => {
  const [isToggled, setIsToggled] = React.useState(initialState)

  // toggle function can be called with no arguments to toggle the state
  // or with a boolean to force the state to a specific value
  const toggle = React.useCallback((newState?: boolean) => {
    setIsToggled((state) =>
      newState === undefined || typeof newState !== 'boolean'
        ? !state
        : newState
    )
  }, [])

  // https://stackoverflow.com/questions/61543626/react-typescript-hook-error-this-expression-is-not-callable
  return [isToggled, toggle] as const
}
export { useToggle }
