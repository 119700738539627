import {
  getMessagesWithCountryOverrides,
  i18nErrorHandler,
  mappedTranslations,
  mergeCountryOverrides,
} from './helpers'
import { FC, PropsWithChildren } from 'react'
import { IntlProvider, IntlShape } from 'react-intl'
import type {
  COUNTRY_CODES,
  CountryOverrides,
  LangCode,
  Translations,
} from '../types'
import { getLanguageFromLocale } from '../getLanguageFromLocale'
import { useCountry } from '@abcam-web/shared/utilities/country'
import { defaultLocale } from '@abcam-web/shared/config'

export type LocalisationProviderProps = {
  countryCodeOverride?: COUNTRY_CODES
  /**
   * This is server side locale passed from consumer app
   * language is known on server side from request
   * change in the language triggers server call where locale is updated from request
   * country should not rely on server side locale as country change does not trigger server call
   */
  locale?: string
  langCodeOverride?: LangCode
  translations: Translations | Translations[]
  translationsOverrides?: CountryOverrides | CountryOverrides[]
  onError?: IntlShape['onError']
}

export const LocalisationProvider: FC<
  PropsWithChildren<LocalisationProviderProps>
> = ({
  translations,
  translationsOverrides,
  locale = defaultLocale,
  langCodeOverride,
  countryCodeOverride,
  onError = i18nErrorHandler,
  children,
}) => {
  const { country } = useCountry()
  const langCode: LangCode =
    langCodeOverride ?? (getLanguageFromLocale(locale) as LangCode)
  const countryCode = (
    countryCodeOverride ?? country
  ).toUpperCase() as COUNTRY_CODES

  const translationMessages: Translations = Array.isArray(translations)
    ? mappedTranslations(translations)
    : translations

  const overridesMessages: CountryOverrides | undefined = Array.isArray(
    translationsOverrides
  )
    ? mergeCountryOverrides(translationsOverrides)
    : translationsOverrides

  const messages = getMessagesWithCountryOverrides({
    translations: translationMessages,
    translationsOverrides: overridesMessages,
    langCode,
    countryCode,
  })
  return (
    <IntlProvider
      defaultLocale="en"
      locale={locale}
      messages={messages}
      onError={onError}
    >
      {children}
    </IntlProvider>
  )
}
