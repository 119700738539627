import { useRouter } from 'next/router'
import { useEffect, useMemo } from 'react'
import { getVirtualPageViewParameters } from './gtm'
import { GTMPageType } from './gtm.enums'
import { getPageType } from './page-type-mapping'
import { useGtm } from './use-gtm'

declare global {
  interface Window {
    __STATE__: { env: string }
    OnetrustActiveGroups: string
    OneTrust: {
      OnConsentChanged: (callback: () => void) => void
      ToggleInfoDisplay: () => void
    }
    OptanonWrapper: () => void
  }
}

// On some pages the virtual page view tracking
// is triggered from within the page with additional
// parameters. In these cases we don't want to trigger
// this generic version
const excludedPages = [GTMPageType.PDP]

type PageOverrides = {
  product_type: null | string
}

export const useVirtualPageViewTracking = (pageOverrides?: PageOverrides) => {
  const { asPath, query, basePath } = useRouter()
  const path = basePath ? basePath + asPath : asPath
  const pathWithoutQueryAndHash = useMemo(() => path.split(/[#?]/)[0], [path])
  const gtm = useGtm()

  const product_type = pageOverrides ? pageOverrides.product_type : null

  let item_code: string
  let item_name: string
  if (typeof query.productSlug === 'string') {
    [item_name, item_code] = query.productSlug.split(/-(?=[^-]*$)/)
  } else if (typeof query.seoClassLevelTwoOrProductSlug === 'string') {
    [item_name, item_code] =
      query.seoClassLevelTwoOrProductSlug.split(/-(?=[^-]*$)/)
  }
  const pageType = getPageType(pathWithoutQueryAndHash)

  useEffect(() => {
    const maxRetries = 10
    let retryCount = 0

    const checkOneTrustReady = () => {
      if (excludedPages.includes(pageType as GTMPageType) && !pageOverrides)
        return

      if (window?.OnetrustActiveGroups) {
        gtm.track(
          getVirtualPageViewParameters({
            pageUrl: `${window.location.origin}${path}`,
            pageTitle: document.title,
            pagePath: pathWithoutQueryAndHash,
            pageType,
            item_code,
            item_name,
            product_type,
          })
        )
      } else if (retryCount < maxRetries) {
        retryCount++
        setTimeout(checkOneTrustReady, 100)
      } else {
        console.warn("OnetrustActiveGroups isn't available")
      }
    }

    checkOneTrustReady()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathWithoutQueryAndHash])
}
