import { COGNITO_ATTRIBUTES } from '@abcam-web/auth-shared/aws-cognito'
import { useUser } from '@abcam-web/auth-shared/contexts/user'
import {
  OrganisationsForContactQuery,
  useOrganisationsForContactQuery,
} from '@abcam-web/shared/data-access/ecommerce-schema'
import { FeatureToggleName } from '@feature-toggles/feature-toggle-name.enum'
import { useFeatureToggle } from '@feature-toggles/use-feature-toggle.hook'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'

declare global {
  interface WindowEventMap {
    'session-storage': CustomEvent
  }
}

type SetValue<T> = Dispatch<SetStateAction<T>>

const key = 'selectedOrganisationId'

export type OrganisationResult = {
  currentOrganisationId: string
  setCurrentOrganisationId: SetValue<string>
  // OrganisationsForContactQuery
  organisations:
    | OrganisationsForContactQuery['organisationsForContact']
    | undefined
  loading: boolean
  enabled: boolean
}

export function useOrganisation(initialValue = ''): OrganisationResult {
  const readValue = useCallback((): string => {
    // Prevent build error "window is undefined" but keep keep working
    if (typeof window === 'undefined') {
      return ''
    }

    try {
      const item = window.sessionStorage.getItem(key)
      return item ? (parseJSON(item) as string) : ''
    } catch (error) {
      console.warn(`Error reading sessionStorage key “${key}”:`, error)
      return ''
    }
  }, [])
  const [currentStoredOrganisationId, setStoredOrganisationId] =
    useState<string>(readValue)
  const user = useUser()
  const { enabled: multipleOrganisationsEnabled } = useFeatureToggle(
    FeatureToggleName.MultipleOrganisationsEnabled
  )

  useEffect(() => {
    if (!user) {
      sessionStorage.removeItem(key)
    }
  }, [user])

  const { data: organisations, loading } = useOrganisationsForContactQuery({
    skip: !multipleOrganisationsEnabled || !user,
    onCompleted: (data) => {
      if (!currentStoredOrganisationId || currentStoredOrganisationId === '') {
        const userOrganisationRegistryId =
          initialValue ||
          user?.[COGNITO_ATTRIBUTES.PRIMARY_ORGANISATION_REGISTRY_ID]
        const selectedOrgainsation = (data?.organisationsForContact || []).find(
          (organisation) =>
            organisation?.organisationRegistryId === userOrganisationRegistryId
        )
        setCurrentOrganisationId(
          selectedOrgainsation?.organisationRegistryId || ''
        )
      }
    },
  })

  const enabled =
    multipleOrganisationsEnabled &&
    !loading &&
    !!organisations?.organisationsForContact?.length &&
    organisations?.organisationsForContact?.length > 1

  const setCurrentOrganisationId: SetValue<string> = useCallback(
    (value) => {
      if (typeof window == 'undefined') {
        console.warn(
          `Tried setting sessionStorage key “${key}” even though environment is not a client`
        )
      }

      try {
        const newValue =
          value instanceof Function ? value(currentStoredOrganisationId) : value
        window.sessionStorage.setItem(key, JSON.stringify(newValue))
        setStoredOrganisationId(newValue)
        window.dispatchEvent(new Event('session-storage'))
      } catch (error) {
        console.warn(`Error setting sessionStorage key “${key}”:`, error)
      }
    },
    [currentStoredOrganisationId]
  )

  useEffect(() => {
    setStoredOrganisationId(readValue())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleStorageChange = useCallback(
    (event: StorageEvent | CustomEvent) => {
      if ((event as StorageEvent)?.key && (event as StorageEvent).key !== key) {
        return
      }
      setStoredOrganisationId(readValue())
    },
    [readValue]
  )

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange)

    window.addEventListener('session-storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
      window.removeEventListener('session-storage', handleStorageChange)
    }
  }, [readValue, handleStorageChange])

  return {
    currentOrganisationId: currentStoredOrganisationId,
    setCurrentOrganisationId,
    organisations: organisations?.organisationsForContact,
    loading,
    enabled,
  }
}

function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '')
  } catch {
    console.log('parsing error on', { value })
    return undefined
  }
}
