import { createContext } from 'react'
import type { ReactElement } from 'react'
import type { ProductContextProps } from '@abcam-web/search/public/utilities/product.type'

export const ProductContext = createContext<ProductContextProps | undefined>(
  undefined
)

export const ProductProvider = ({
  product,
  children,
}: ProductContextProps): ReactElement => (
  <ProductContext.Provider
    value={{
      product,
    }}
  >
    {children}
  </ProductContext.Provider>
)
