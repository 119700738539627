import { ExternalFeatureToggle } from '@feature-toggles/external-feature-toggles/external-feature-toggle'

import { apolloClient } from '@feature-toggles/apollo-client'
import type { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import type { Bootstrapable } from '@feature-toggles/interfaces/bootstrapable'
import type {
  FeatureToggleService,
  FeatureToggleService as FeatureToggleServiceInterface,
} from '@feature-toggles/interfaces/feature-toggle.interface'
import { getFeatureTogglesServiceUrl } from '@feature-toggles/feature-toggle-service-url'

export class ExternalFeatureToggleBootstrapable
  implements Bootstrapable<FeatureToggleServiceInterface>
{
  private client!: ApolloClient<NormalizedCacheObject>
  private url = getFeatureTogglesServiceUrl()

  private async init() {
    if (!this.url) {
      throw new Error('Missing PROXY_GATEWAY_URL environment variable')
    }

    this.client = apolloClient(this.url)
  }

  public bootstrap(): FeatureToggleService {
    this.init()

    return new ExternalFeatureToggle(this.client)
  }

  public teardown(): void {
    // empty
  }
}
