import { TextInput } from '@lego/ui/text-input'
import classNames from 'classnames'
import { FC, useEffect, useState } from 'react'

interface EORIProps {
  value?: string
  onChange: (value: string) => void
  className?: string
  label?: string
  subLabel?: string
  leftLabel?: boolean
}

const EORINumber: FC<EORIProps> = ({
  value = '',
  onChange = () => null,
  leftLabel = false,
  className,
  label = 'Customs ID',
  subLabel = 'EORI number. Helps speed up the custom process',
}) => {
  const [EORINumber, setEORINumber] = useState('')

  useEffect(() => {
    if (value) {
      setEORINumber(value)
    }
  }, [value])

  useEffect(() => {
    onChange(EORINumber)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [EORINumber])

  return (
    <div
      className={classNames('pr-layout-2', className, {
        'grid grid-cols-3': leftLabel,
      })}
    >
      <div
        className={classNames({
          'col-span-1 mr-10': leftLabel,
        })}
      >
        <div className="font-semibold text-heading-x-small">{label}</div>
        <div className="mt-1 text-grey-20 text-body-small fon">{subLabel}</div>
      </div>
      <div
        className={classNames({
          'col-span-2': leftLabel,
        })}
      >
        <TextInput
          value={EORINumber}
          dataCy="customs-id-input"
          onChange={(value) => setEORINumber(value.value)}
          fullWidth
        />
      </div>
    </div>
  )
}

export { EORINumber }
