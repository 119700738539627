export const homePage = {
  searchWrapper: 'search-wrapper',
  welcomeTitle: 'welcome-abcam-title',
  welcomeDescription: 'welcome-description',
  feedbackButton: 'feedback-button',
  closeToggleButton: 'close-toggle-button',
  previousSearch: 'previous-searches',
  overlaySearchInput: 'overlay-search-input',
  launchSearchButton: 'launch-search-button',
  headerSearchInput: 'header-search-input',
  previousSearchIcon: 'previous-search-icon',
  searchClearButton: 'search-clear-button',
  betaToggleButton: 'toggle-component',
  searchResultsCategoryHeader: 'search-results-heading',
}
