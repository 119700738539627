import {
  PunchoutInfo,
  usePunchout,
} from '@abcam-web/auth-shared/aws-cognito/hooks/usePunchout'
import { trackEvents } from './gtm'
import { EventType } from './gtm.types'
import { useCallback, useMemo } from 'react'

function enrichEvent<T>(parameters: T, punchout: PunchoutInfo): T {
  if (punchout.active) {
    return {
      ...parameters,
      integration_type: 'punchout',
      integration_id: punchout.punchoutId,
      integration_session_id: punchout.sessionId,
    }
  }
  return parameters
}

/** Represents the GTM service used to track user events. */
export type GtmService = {
  /**
   * Publishes the specified event.
   *
   * Note: events published through this hook are enriched with additional information
   * (such as which integration - if any - is active).
   * @param parameters - Parameters of the event to publish.
   */
  track<T extends EventType | EventType[]>(parameters: T): void
}

/**
 * Obtains an instance of the `GtmService` service, used to publish
 * events to Google Tags Manager for analytic purposes.
 */
export function useGtm(): GtmService {
  const punchout = usePunchout()

  const track = useCallback(
    <T extends EventType | EventType[]>(parameters: T) => {
      trackEvents<T>(enrichEvent<T>(parameters, punchout))
    },
    [punchout]
  )

  return useMemo(() => ({ track }), [track])
}
