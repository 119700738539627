import { FC } from 'react'
import { useIntl } from 'react-intl'

import { EditLinePropertyInputModal } from '../edit-line-property-input-modal/edit-line-property-input-modal'

interface Props {
  cancelText?: string
  subheaderText?: string
  inputLabelText?: string
  inputPlaceholderText?: string
  loading?: boolean
  isOpen: boolean
  onSave: (customerPurchaseOrderNumber: string) => void
  onClose: () => void
  defaultValue?: string
}

const EditPONumberModal: FC<Props> = ({
  loading,
  isOpen,
  onSave,
  onClose,
  defaultValue = '',
}) => {
  const { formatMessage } = useIntl()

  return (
    <EditLinePropertyInputModal
      testTagPrefix="line-po-number"
      headerText={formatMessage({ id: 'editPoNumberModal.header' })}
      inputPlaceholderText="i.e. “12345-1”"
      inputLabelText={formatMessage({ id: 'editPoNumberModal.label' })}
      subheaderText={formatMessage({ id: 'editPoNumberModal.explainer' })}
      cancelText={formatMessage({ id: 'editPoNumberModal.cancel' })}
      saveText={formatMessage({ id: 'editPoNumberModal.save' })}
      isOpen={isOpen}
      key={`line-po-number-${defaultValue}`}
      onClose={onClose}
      defaultValue={defaultValue}
      loading={loading}
      onSave={onSave}
    />
  )
}

export { EditPONumberModal }
