import type {
  UserRefreshTokens,
  UserTokens,
  UserTokensStorageData,
} from './types/tokens'
import { cognitoConfig } from './cognitoConfig'
import type { STAGE } from '@abcam-web/auth-shared/constants/stage-type'

const LAST_AUTH_USER_SUFFIX = 'LastAuthUser'

const getUserPoolId = (stage: STAGE): string | undefined => {
  return cognitoConfig[stage]?.COGNITO_USER_POOL_WEB_CLIENT_ID
}

export const getStoragePrefix = (stage: STAGE) => {
  return `CognitoIdentityServiceProvider.${getUserPoolId(stage)}.`
}

export const getStoragePrefixWithUsername = (
  stage: STAGE,
  username: string
) => {
  return `CognitoIdentityServiceProvider.${getUserPoolId(stage)}.${username}.`
}

// calculate when token  will expire and subtract 3 minutes to stay in the safe side
export const getExpiresAt = (expiresIn: number): number => {
  return Date.now() + 1000 * expiresIn - 1000 * 60 * 3
}

export const saveTokensInLocalStorage = (
  stage: STAGE,
  tokenData: UserTokens
) => {
  if (!tokenData.accessToken) {
    return
  }

  const expiresAt = tokenData.expiresAt || getExpiresAt(tokenData.expiresIn)

  const prefix = getStoragePrefix(stage)
  const prefixWithUser = getStoragePrefixWithUsername(
    stage,
    tokenData.lastAuthUser
  )

  localStorage.setItem(
    `${prefix}${LAST_AUTH_USER_SUFFIX}`,
    tokenData.lastAuthUser
  )
  localStorage.setItem(`${prefixWithUser}accessToken`, tokenData.accessToken)
  localStorage.setItem(`${prefixWithUser}idToken`, tokenData.idToken)
  localStorage.setItem(`${prefixWithUser}refreshToken`, tokenData.refreshToken)
  localStorage.setItem(`${prefixWithUser}expiresAt`, expiresAt.toString())
}

export const saveExpiryInLocalStorage = ({
  stage,
  expiry,
  email,
}: {
  stage: STAGE
  expiry: number
  email: string
}) => {
  const expiresAt = getExpiresAt(expiry)
  const prefixWithUser = getStoragePrefixWithUsername(stage, email)
  localStorage.setItem(`${prefixWithUser}expiresAt`, expiresAt.toString())
}

export const setExpiryCookie = (stage: STAGE, userName: string) => {
  const expiresAt = getExpiresAt(3600)
  const prefixWithUser = getStoragePrefixWithUsername(stage, userName)
  localStorage.setItem(`${prefixWithUser}expiresAt`, expiresAt.toString())
}

export const getTokensFromLocalStorage = (
  stage: STAGE
): Omit<UserTokensStorageData, 'expiresIn'> | null => {
  if (typeof window === 'undefined') {
    return null
  }

  const prefix = getStoragePrefix(stage)
  const lastAuthUser = localStorage.getItem(`${prefix}${LAST_AUTH_USER_SUFFIX}`)
  if (!lastAuthUser) {
    return null
  }

  const prefixWithUser = getStoragePrefixWithUsername(stage, lastAuthUser)

  const accessToken = localStorage.getItem(`${prefixWithUser}accessToken`)
  const idToken = localStorage.getItem(`${prefixWithUser}idToken`)
  const refreshToken = localStorage.getItem(`${prefixWithUser}refreshToken`)
  const expiresAt = localStorage.getItem(`${prefixWithUser}expiresAt`)
  if (!accessToken || !idToken || !refreshToken || !expiresAt) {
    return null
  }

  return {
    idToken,
    accessToken,
    refreshToken,
    lastAuthUser,
    expiresAt: parseInt(expiresAt),
  }
}

export const refreshTokensInLocalStorage = (
  stage: STAGE,
  { accessToken, idToken, expiresIn, refreshToken }: UserRefreshTokens,
  email: string
) => {
  if (!accessToken) {
    return
  }

  const prefix = getStoragePrefix(stage)

  const prefixWithUser = getStoragePrefixWithUsername(stage, email)

  localStorage.setItem(`${prefix}${LAST_AUTH_USER_SUFFIX}`, email)
  localStorage.setItem(`${prefixWithUser}accessToken`, accessToken)
  localStorage.setItem(`${prefixWithUser}idToken`, idToken)
  if (refreshToken) {
    localStorage.setItem(`${prefixWithUser}refreshToken`, refreshToken)
  }

  if (expiresIn) {
    const expiresAt = getExpiresAt(expiresIn)
    localStorage.setItem(`${prefixWithUser}expiresAt`, expiresAt.toString())
  }
}

export const removeAuthTokensFromLocalStorage = (stage: STAGE): void => {
  if (typeof window === 'undefined') {
    return
  }

  const items = { ...localStorage }
  const prefix = getStoragePrefix(stage)
  Object.keys(items)
    .filter((key) => key.startsWith(prefix))
    .forEach((key) => {
      localStorage?.removeItem(key)
    })
}

const PREFIX = '@abcam-amplify:'
/**
 * Saves amplify tokens to local storage.
 * We are using the same format in rest of the system, just storing them should be enough.
 */
export function saveAmplifyTokensToLocalStorage(
  tokens: Record<string, string>
) {
  Object.keys(tokens).forEach((key) => {
    // amplify doesn't store with prefix, so we are removing them
    const storageKey = key.replace(PREFIX, '')
    localStorage.setItem(storageKey, tokens[key])
    if (storageKey.includes('accessToken')) {
      const expiresAtKey = storageKey.replace('accessToken', 'expiresAt')
      // amplify doesn't give expires in, but we need this data. It's the same for all environments, so it's safe to use 3600 as expiresIn.
      localStorage.setItem(expiresAtKey, getExpiresAt(3600).toString())
    }
  })
}
