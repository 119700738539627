import { getCurrentUserCS } from '@abcam-web/auth-shared/aws-cognito'
import { User } from '@abcam-web/auth-shared/contexts/user'
import { useCallback, useEffect, useMemo, useState } from 'react'

const useIsLogged = () => {
  const [isLogged, setIsLogged] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [user, setUser] = useState<User>()
  const [isUserFetched, setIsUserFetched] = useState<boolean>(false);

  const fetchAuthUser = useCallback(() => {
    setIsLoading(true)
    getCurrentUserCS()
      .then((userData) => {
        if (userData) {
          setIsLogged(true)
          setUser(userData)
        } else {
          setIsLogged(false)
          setUser(undefined)
        }
      })
      .catch(() => {
        setIsLogged(false)
        setUser(undefined)
      })
      .finally(() => {
        setIsLoading(false)
        setIsUserFetched(true)
      })
  }, [setIsLogged, setUser])

  useEffect(() => {
    fetchAuthUser()
  }, [fetchAuthUser])

  return useMemo(
    () => ({ isLogged, user, isLoading, isUserFetched }),
    [isLogged, user, isLoading, isUserFetched]
  )
}

export { useIsLogged }
