import { theme } from '@tailwind-preset-theme'

type Theme = typeof theme

const screenKeys: (keyof Theme['extend']['screens'])[] = Object.keys(
  theme.extend.screens
) as (keyof Theme['extend']['screens'])[]

export const breakpointsAsNumbers: { [key: string]: number } = {}

screenKeys.forEach((k) => {
  const current = theme.extend.screens[k]

  if ((current as { raw: string }).raw) {
    return
  }

  breakpointsAsNumbers[k] = parseInt(
    typeof current === 'string' ? current : (current as { max: string }).max,
    10
  )
})
