export const sourceAndLinksModal = {
  container: 'modal-source-and-links',
  sourceTitle: 'modal-source-and-links-source-title',
  source: 'modal-source-and-links-source',
  linksTitle: 'modal-source-and-links-links-title',
  linksInfo: 'modal-source-and-links-links-information',
  links: {
    container: 'modal-source-and-links-table',
    heading: {
      count: 'modal-source-and-links-heading-count',
      type: 'modal-source-and-links-heading-type',
      number: 'modal-source-and-links-heading-number',
      reason: 'modal-source-and-links-heading-reason',
    },
  },
}
