import { InfoIcon } from '@abcam-web/lego/ui/icons'
import { LotSpecificOrderType } from '@abcam-web/shared/data-access/ecommerce-schema'
import { Button } from '@lego/ui/button'
import { FC } from 'react'
import { useIntl } from 'react-intl'

export type LotSpecificOrderTypeForStrategy = Extract<
  LotSpecificOrderType,
  | LotSpecificOrderType.THIS_SPECIFIC_LOT
  | LotSpecificOrderType.NOT_THIS_SPECIFIC_LOT
  | LotSpecificOrderType.ALL_ONE_LOT
>

interface BasketItemLotStrategyProps {
  lotStrategy: LotSpecificOrderTypeForStrategy
  lotNumber?: string
  disableInteractions?: boolean
  onClear: () => void
}

const BasketItemLotStrategy: FC<BasketItemLotStrategyProps> = ({
  lotStrategy,
  lotNumber,
  disableInteractions,
  onClear,
}) => {
  const { formatMessage } = useIntl()

  const message =
    lotStrategy === LotSpecificOrderType.THIS_SPECIFIC_LOT
      ? formatMessage({ id: 'basketItemLotStrategy.sendOnly' })
      : lotStrategy === LotSpecificOrderType.NOT_THIS_SPECIFIC_LOT
      ? formatMessage({ id: 'basketItemLotStrategy.exclude' })
      : lotStrategy === LotSpecificOrderType.ALL_ONE_LOT
      ? formatMessage({ id: 'basketItemLotStrategy.sendFromSingle' })
      : ''

  return (
    <div className="flex" data-testid="basket-item-lot-strategy">
      <span className="my-auto text-blue-default">
        <InfoIcon className="fill-current" width={16} height={16} />
      </span>
      <span className="my-auto ml-2 font-normal text-body-small">
        {message}
        {lotNumber}
      </span>
      <Button
        disabled={disableInteractions}
        onClick={onClear}
        className="px-2 my-auto text-negative"
        bodyClassName="text-ui-small"
        size="xsmall"
        variant="textOnly"
      >
        {formatMessage({ id: 'basketItemLotStrategy.clear' })}
      </Button>
    </div>
  )
}

export { BasketItemLotStrategy }
