import { Button } from '@lego/ui/button'
import { ContentLoader } from '@lego/ui/content-loader'
import cn from 'classnames'
import React, { FC, useState } from 'react'

import styles from './ship-to.module.css'

const user = {
  name: 'John',
  surname: 'Doe',
  address: {
    street: 'Northwood Street',
    number: '16',
    companyName: 'Labtown',
    city: 'Cambridge',
    country: 'UK',
  },
  email: 'deliver@doe.com',
  telephone: '07777142576',
}

interface ShipToProps {
  loading: boolean
  smallLabel?: boolean
}

const ShipTo: FC<ShipToProps> = ({ loading, smallLabel = false }) => {
  const [userInfo] = useState(user)

  const {
    name,
    surname,
    address: { number, street, companyName, city, country },
    telephone,
    email,
  } = userInfo

  const fullName = `${name} ${surname}`
  const addressStreet = `${number} ${street}, ${companyName},`
  const addressCityCountry = `${city}, ${country}`

  const addressSmu = `${number}, ${street}, ${companyName}. ${addressCityCountry}`

  const content = (
    <>
      <div className={styles.lgd}>
        <p>{fullName}</p>
        <p>{addressStreet}</p>
        <p>{addressCityCountry}</p>
        <div className="mt-3" />
        <p>Tel: {telephone}</p>
        <p>Email: {email}</p>
      </div>
      <div className={styles.smu}>
        <p>
          <strong>{fullName}</strong>
        </p>
        <p>{addressSmu}</p>
      </div>
      <Button
        size="small"
        variant="tertiaryOutline"
        className={styles.button}
        bodyClassName="text-blue-default"
      >
        Change <span className={styles.smuHidden}>shipping address</span>
      </Button>
    </>
  )

  const skeleton = (
    <>
      <ContentLoader
        className="w-2/5 mt-2"
        height="16px"
        isLoading={loading}
        uniqueKey="first-line"
      />
      <ContentLoader
        className="w-4/5 mt-2"
        height="16px"
        isLoading={loading}
        uniqueKey="second-line"
      />
      <ContentLoader
        className="w-4/5 mt-2"
        height="16px"
        isLoading={loading}
        uniqueKey="third-line"
      />
      <ContentLoader
        className="w-3/5 mt-5"
        innerClassName="rounded"
        height="36px"
        isLoading={loading}
        uniqueKey="button"
      />
    </>
  )

  return (
    <div className={styles.shipToWrapper}>
      <div
        className={cn({
          [styles.title]: !smallLabel,
          'text-heading-small': smallLabel,
        })}
      >
        Ship to
      </div>
      <div className={styles.content}>{loading ? skeleton : content}</div>
    </div>
  )
}

export default ShipTo
