/* eslint-disable no-case-declarations */
import {
  FieldNames,
  FieldNumberSchema,
  FieldSchema,
  FieldStringSchema,
} from '@abcam-web/address-validator'
import {
  Checkbox,
  SimpleSelect,
  SimpleTextInput,
} from '@lego/ui/form-elements'
import { validateField } from 'libs/address-validator/src/validator/validate-field'
import { ReactNode } from 'react'
import { useIntl } from 'react-intl'

import {
  ErrorMessages,
  Label,
} from '../data/fieldConfig'

type TypeRendererProps = {
  key: FieldNames
  fieldSchema: FieldSchema
  fieldConfig?: {
    labels?: (Label & { label: string })[]
    errorMessages?: ErrorMessages
  }
  uid?: string
  requiredPlaceholder?: string
  defaultValue?: string | number | null
  labelClassName?: string
  inputClassName?: string
  menuClassName?: string
}

const boolean: ({ key, fieldConfig }: TypeRendererProps) => JSX.Element = ({
  key,
  fieldConfig,
  defaultValue,
}) => {
  const label = fieldConfig?.labels?.find((label) => label.key === key)

  return (
    <Checkbox
      name={key}
      label={label?.label}
      placeholder={label?.placeholder}
      errorText={fieldConfig?.errorMessages?.['error-required']}
      defaultChecked={!!defaultValue}
    />
  )
}

function generateMask(regexStr: string) {
  return regexStr
    .replace(/\[0-9\]/g, '#')
    .replace(/\[a-z\]/g, 'L')
    .replace(/\^/g, '')
    .replace(/\$/g, '')
}

function getLabelHint(schema: FieldStringSchema, key: string) {
  if (schema.pattern) {
    const mask = schema.pattern.source
      .split('|')
      .map((exp) => generateMask(exp))

    // this needs to be localized
    return (
      <ul className="font-mono text-grey-15" key={`${key}_tooltip`}>
        <li>e.g.</li>
        {mask.map((m, i) => (
          <li className="font-bold text-green-800" key={`${key}_${i}`}>
            {m}
          </li>
        ))}

        <li className="mt-4">
          <span>
            <strong>#</strong> = digit
          </span>
        </li>
        <li>
          <span>
            <strong>L</strong> = letter
          </span>
        </li>
      </ul>
    )
  }

  return undefined
}

const StringOrNumber: (props: TypeRendererProps) => JSX.Element = ({
  key,
  fieldSchema,
  fieldConfig,
  uid,
  requiredPlaceholder,
  defaultValue,
  labelClassName,
  inputClassName,
  menuClassName,
}) => {
  const stringOrNumberSchema = fieldSchema as
    | FieldStringSchema
    | FieldNumberSchema
  const label = fieldConfig?.labels?.find((label) => label.key === key)
  const { formatMessage } = useIntl()

  if (stringOrNumberSchema.oneOf) {
    const options = stringOrNumberSchema.oneOf.map((item, index) => {
      if (stringOrNumberSchema.formatValue) {
        if (item) {
          const countryName = formatMessage({ id: `country.${item}` })
          return {
            key: item,
            displayValue: countryName,
            checked: item === defaultValue,
          }
        }
      }
      return {
        key: item,
        displayValue: item ? (item as string) : (requiredPlaceholder as string),
        checked: defaultValue ? defaultValue === item : index === 0,
      }
    })
    if (stringOrNumberSchema.sortValue) {
      options.sort((a, b) => a.displayValue.localeCompare(b.displayValue))
    }
    return (
      <SimpleSelect
        key={uid}
        testTag={key}
        labelClassName={labelClassName}
        inputClassName={inputClassName}
        name={key}
        inputWrapperClassName={label?.size === 'narrow' ? 'max-w-[215px]' : ''}
        label={label?.label}
        errorText={fieldConfig?.errorMessages?.['error-required']}
        required={stringOrNumberSchema.required}
        options={options}
        menuClassName={menuClassName}
      />
    )
  }

  const conditionalProps =
    stringOrNumberSchema.type === 'number'
      ? {
          min: stringOrNumberSchema.minValue,
          max: stringOrNumberSchema.maxValue,
        }
      : {
          minLength: stringOrNumberSchema.minLength,
          maxLength: stringOrNumberSchema.maxLength,
          type: stringOrNumberSchema.format,
        }
  let hint: string | ReactNode = label?.hint || null

  if (stringOrNumberSchema.type === 'string' && !hint && label?.hintId) {
    const mask = (stringOrNumberSchema.pattern?.source || '')
      .split('|')
      .map((exp) => generateMask(exp))

    if (mask[0] !== '') {
      const hintLabel = formatMessage(
        { id: label.hintId },
        { patterns: mask.join(', ') }
      )
      const footnote = formatMessage(
        {
          id: label.hintFootnoteId,
          defaultMessage: '',
        },
        { bold: (chunks: ReactNode) => <strong>{chunks}</strong> }
      )
      hint = (
        <span
          className={`${labelClassName} font-mono text-grey-15`}
          data-testid={`${key}-hint-text`}
          key={`label${key}`}
        >
          {`${hintLabel}${footnote ? ' ' : ''}`}
          {footnote}
        </span>
      )
    }
  }
  return (
    stringOrNumberSchema && (
      <SimpleTextInput
        key={uid}
        name={key}
        labelClassName={labelClassName}
        inputClassName={inputClassName}
        inputWrapperClassName={label?.size === 'narrow' ? 'max-w-[215px]' : ''}
        placeholder={label?.placeholder}
        label={label?.label}
        defaultValue={defaultValue ?? ''}
        labelHint={
          stringOrNumberSchema.type === 'string' && label?.labelHint
            ? getLabelHint(stringOrNumberSchema, key)
            : undefined
        }
        hint={hint}
        validationFunction={(value: string) => {
          const errorCodes = validateField(stringOrNumberSchema, value)
          const errorMessage =
            fieldConfig?.errorMessages?.[errorCodes[0]] ||
            '❌ ERROR MESSAGE NOT FOUND!'

          return errorCodes.length ? errorMessage : ''
        }}
        {...conditionalProps}
      />
    )
  )
}

export const typeRenderer: Record<
  FieldSchema['type'],
  (props: TypeRendererProps) => JSX.Element
> = {
  boolean,
  string: StringOrNumber,
  number: StringOrNumber,
}

export type { ErrorMessages, Label, TypeRendererProps }
