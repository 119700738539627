import { EnvironmentFinder } from '@abcam-web/shared/utilities/environment'
import { isAxiosError } from 'axios'

import { pageActionName } from './constants'
import {
  AlertAttributes,
  AxiosErrorAttributes,
  GlobalNewrelic,
  LogAlertsEventParams,
  NewRelicAttributes,
  PageActionName,
} from './types'
import { deepValueSearch } from './utils'

export const addNewRelicPageAction = (
  name: PageActionName,
  attributes?: NewRelicAttributes
) => {
  const { newrelic } = (window || {}) as GlobalNewrelic
  if (!newrelic) {
    return
  }

  newrelic.addPageAction(name, attributes)
}

export const logAlertsEvent = ({
  alertType,
  attributes,
  alert,
}: LogAlertsEventParams) => {
  const env = EnvironmentFinder.currentEnv() || null
  const alertAttributes: Partial<AlertAttributes & AxiosErrorAttributes> = {}

  if (isAxiosError(alert)) {
    const statusCode = alert.response?.data.code ?? alert.status
    const { error: errorResponse, operation } =
      alert.response?.data || ({} as any)

    alertAttributes.message = `${statusCode} - ${
      errorResponse || alert.code
    } - ${operation}`
    alertAttributes.endpoint = alert.request?.responseURL || ''
    alertAttributes.statusCode = statusCode || ''
    alertAttributes.correlationId =
      alert.response?.headers['x-correlation-id'] || ''
  } else {
    alertAttributes.message = alert?.message || ''
    alertAttributes.originalError = JSON.stringify(alert)
    alertAttributes.correlationId = deepValueSearch('x-correlation-id', alert)
  }

  addNewRelicPageAction(pageActionName[alertType], {
    ...attributes,
    ...alertAttributes,
    env,
    isAuthenticated: attributes.isAuthenticated,
  })
}
