import { useContext, useEffect, useMemo, useState } from 'react'
import { FeatureTogglesContext } from './feature-toggles.context'
import { buildToggle } from './factories/feature-toggle-factory'

import type { FeatureToggle } from '@abcam-web/shared/data-access/feature-toggle-schema'
import type { FeatureToggleName } from './feature-toggle-name.enum'
import { FeatureToggleServer } from './feature-toggle-server'

export function useFeatureToggle(
  featureToggleName: FeatureToggleName
): FeatureToggle {
  const value = useContext(FeatureTogglesContext)
  if (value === undefined) {
    throw new Error(
      'Cannot find any feature toggle, are you calling useFeatureToggle() outside FeatureTogglesContext?'
    )
  }

  return useMemo(
    () =>
      value.find(({ name }) => name === featureToggleName) ??
      buildToggle(featureToggleName, false),
    [value, featureToggleName]
  )
}

export function useAllFeatureToggles(){

  const [featureToggles, setFeatureToggles] = useState<FeatureToggle[]>([])

  useEffect(() => {
    const featureToggleServer = new FeatureToggleServer(false).bootstrap()
    featureToggleServer.getAllToggles().then((toggles) => {
      setFeatureToggles(toggles)
    })
  }, [])

  return featureToggles
}
