// Copyright 2022, Nitric Technologies Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
import { Auth } from 'aws-amplify'
import { AmplifyStorage } from './storage'
import {
  removeAuthTokensFromLocalStorage,
  saveAmplifyTokensToLocalStorage,
} from '@abcam-web/shared/utilities/auth'
import getConfig from 'next/config'

let amplifyLocalStorage: AmplifyStorage

export interface StorageReturn {
  success: boolean
}

export const initAmplifyLocalStorage = (authURL: string) => {
  amplifyLocalStorage = new AmplifyStorage()

  return amplifyLocalStorage
}

/**
 * Saves punchout amplify tokens to local storage.
 */
export function storePunchoutTokens() {
  saveAmplifyTokensToLocalStorage(amplifyLocalStorage.store)
}

export const amplifySignOut: typeof Auth.signOut = async (opts) => {
  const stage = getConfig().publicRuntimeConfig?.STAGE
  removeAuthTokensFromLocalStorage(stage)
  const res = await Auth.signOut(opts)

  return res
}
