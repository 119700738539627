export const secondaries = {
  secondariesDescription: 'secondaries-description',
  secondariesHostSpecies: 'secondaries-host-species',
  secondariesTargetSpecies: 'secondaries-target-species',
  secondariesTargetIsotype: 'secondaries-target-isotype',
  secondariesTargetSpecificity: 'secondaries-target-specificity',
  secondariesConjugation: 'secondaries-conjugation',
  secondariesEmission: 'secondaries-emission',
  secondariesApplications: 'secondaries-applications',
  secondariesClonality: 'secondaries-clonality',
  secondariesFormat: 'secondaries-format',
  econdariesPreAbsorbed: 'econdaries-preAbsorbed',
  secondariesForm: 'secondaries-form',
  secondariesStorageInstructions: 'secondaries-storage-instructions',
  secondariesStorageBuffer: 'secondaries-storage-buffer',
  secondariesConcentration: 'secondaries-concentration',
  secondariesPurity: 'secondaries-purity',
  secondariesPurificationNotes: 'secondaries-purification-notes',
  secondariesImmunogen: 'secondaries-immunogen',
}
