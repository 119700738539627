import { getAuthenticationHost } from '@abcam-web/auth-shared/aws-cognito'
import {
  PurchaseType,
  PurchaseTypeEnum,
} from '@abcam-web/shared/data-access/ecommerce-schema'
import {
  useIsLogged,
  useMarketInfo,
} from '@abcam-web/shared/ecommerce/utilities'
import { useEnvironment } from '@abcam-web/shared/utilities/environment'
import { FC } from 'react'

import { SignInBox } from '../sign-in-box'

interface SignInControllerProps {
  classNames?: string
  linkTextOverrideId?: string
  fullTextLink?: boolean
}

const SignInController: FC<SignInControllerProps> = ({
  classNames,
  linkTextOverrideId,
  fullTextLink = false,
}) => {
  const { isLoading, isLogged } = useIsLogged()
  const { STAGE } = useEnvironment()
  const marketInfo = useMarketInfo()
  const purchaseType = marketInfo?.data?.purchaseType as PurchaseType
  const distributorFlow = purchaseType === PurchaseTypeEnum.Distributor

  if (isLogged || isLoading) {
    return null
  }

  const signInUrl = `${getAuthenticationHost(STAGE)}/login?redirect=${
    window.location.href
  }`

  return (
    <SignInBox
      classNames={classNames}
      distributorFlow={distributorFlow}
      signInUrl={signInUrl}
      linkTextOverrideId={linkTextOverrideId}
      fullTextLink={fullTextLink}
    />
  )
}

export { SignInController }
