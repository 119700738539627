import { RadioInput } from '@lego/ui/radio-input'
import { FC, useState } from 'react'

import styles from './payment-radio-input.module.css'
import {
  PaymentRadioInputOption,
  PaymentRadioInputProps,
} from './payment-radio-input.types'

export const PaymentRadioInput: FC<PaymentRadioInputProps> = (props) => {
  const { options, name, selectionOnChange } = {
    ...props,
  }

  const getDefaultValue = (options: PaymentRadioInputOption[]): string => {
    const defaultSelection = options.filter((o) => o.isDefault)[0]
    return defaultSelection && defaultSelection.value
  }

  const [value, setValue] = useState<string | undefined>(
    getDefaultValue(options)
  )

  const isShowButtonAlways = (option: PaymentRadioInputOption) => {
    return option.selectButton && option.selectButtonAlwaysShow
  }

  const isShowButtonOnSelection = (option: PaymentRadioInputOption) => {
    return (
      option.selectButton &&
      !option.selectButtonAlwaysShow &&
      option.value === value
    )
  }

  const isShowImageAlways = (option: PaymentRadioInputOption) => {
    return option.selectImage && option.selectImageAlwaysShow
  }

  const isShowImageOnSelection = (option: PaymentRadioInputOption) => {
    return (
      option.selectImage &&
      !option.selectImageAlwaysShow &&
      option.value === value
    )
  }

  return (
    <>
      {options.map((option: PaymentRadioInputOption, index: number) => (
        <div key={index}>
          <div className={styles.selectOption}>
            <RadioInput
              id={`PaymentInput ${index.toString()}`}
              value={option.value}
              displayValue={option.name}
              onChecked={({ value }) => {
                setValue(value as string)
                selectionOnChange && selectionOnChange(value as string)
              }}
              checked={option.value === value}
            />
            {isShowImageOnSelection(option) ||
              (isShowImageAlways(option) && (
                <div className={styles.selectImage}>{option.selectImage}</div>
              ))}
          </div>
          <div>
            {option.value === value && option.description && (
              <>
                {option.description.map((line, index) => (
                  <div key={index} className={styles.optionTerms}>
                    {line}
                  </div>
                ))}
              </>
            )}
          </div>
          {isShowButtonAlways(option) ||
            (isShowButtonOnSelection(option) && (
              <div className={styles.selectButton}>{option.selectButton}</div>
            ))}
          <div>
            {options.length - 1 !== index && (
              <hr className={styles.optionsLine} />
            )}
          </div>
        </div>
      ))}
    </>
  )
}
