import type { IncomingMessage } from 'http';

type GetOriginURLOptions = {
  overrideOrigin?: string;
};

/**
 * Get origin from HTTP request or window
 */
export function getOriginUrl(req: IncomingMessage | undefined, options?: GetOriginURLOptions): string {
  const overrideOrigin = options?.overrideOrigin;

  if(overrideOrigin) {
    return overrideOrigin;
  }

  if (req && req.headers.host) {
    // Typically happening on server side
    const publicEnvsRegex = new RegExp(/(pp|www)\.abcam\.com/, 'i');
    if (publicEnvsRegex.test(req.headers.host)) {
      return 'https://' + req.headers.host;
    }
    return 'http://' + req.headers.host;
  }

  if (typeof window === 'undefined') {
    return '';
  }

  const { origin, host } = window.location;
  return origin || 'http://' + host;
}
