import { Button } from '@lego/ui/button'
import { Dialog } from '@headlessui/react'

import { Card, CloseButton } from '../common'
import { ElementType } from 'react'

interface AlertProps {
  title?: React.ReactNode
  description?: React.ReactNode
  descriptionAs?: ElementType
  descriptionClassName?: string
  onOk: () => void
  okText: React.ReactNode
  className?: string
}

// This is the modal content
const AlertContent = ({
  title,
  description,
  descriptionAs = 'p',
  descriptionClassName,
  onOk,
  okText,
  className,
}: AlertProps) => {
  return (
    <Card className={className}>
      {title && (
        <Dialog.Title as={descriptionAs}>
          <span className="block mr-8 text-2xl">{title}</span>
        </Dialog.Title>
      )}

      {description && (
        <Dialog.Description as={descriptionAs} className={descriptionClassName}>
          {description}
        </Dialog.Description>
      )}
      <div className="flex justify-end gap-4">
        <Button size="small" onClick={onOk}>
          {okText}
        </Button>
      </div>
      <CloseButton onClick={onOk} />
    </Card>
  )
}

export { AlertContent }
export type { AlertProps }
