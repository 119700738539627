import React, {
  FC,
  LegacyRef,
  useMemo,
  useRef,
  ReactElement,
  useEffect,
} from 'react'
import { filterArrayByText } from './filterArrayByText'
import { FilterableListOption } from './filterableList.type'
import { FILTER_MAX_RESULTS } from './constants'
import { useComboBoxState, ComboBoxState } from '@react-stately/combobox'
import { useComboBox } from '@react-aria/combobox'
import { useListBox, useOption } from '@react-aria/listbox'
import { Item } from '@react-stately/collections'
import { useIntl } from 'react-intl'
import type { Key } from '@react-types/shared'

type AriaListBoxOptions = ReturnType<typeof useComboBox>['listBoxProps']
type CollectionItem = NonNullable<
  ReturnType<ComboBoxState<object>['collection']['at']>
>

export const FilterableListItem = Item

export type FilterableListProps = {
  title?: string
  searchPlaceholder?: string
  options?: FilterableListOption[]
  onValueSelect?: (key: Key | null) => void
  filterKey?: string
  cy?: string
  children: (item: FilterableListOption) => JSX.Element
  standalone?: boolean
  className?: string
  labelClassname?: string
  inputClassName?: string
  listClassName?: string
  InputIcon?: ReactElement | undefined
  inputPropsOverrides?: { [key: string]: any }
}

export const FilterableList: FC<FilterableListProps> = React.memo((props) => {
  const {
    options = [],
    title,
    searchPlaceholder,
    onValueSelect = () => null,
    filterKey = 'value',
    standalone = false,
    className = '',
    labelClassname = '',
    inputClassName = '',
    listClassName = '',
    InputIcon,
    inputPropsOverrides,
    cy,
  } = props

  const listBoxRef = React.useRef(null)
  const popoverRef = React.useRef(null)
  const inputFieldRef = useRef<HTMLInputElement | null>(null)
  const [keywordFilter, setKeywordFilter] = React.useState('')
  const { formatMessage } = useIntl()

  const filteredOptions = useMemo(() => {
    return filterArrayByText(options, keywordFilter, filterKey)?.slice(
      0,
      FILTER_MAX_RESULTS
    )
  }, [options, keywordFilter, filterKey])

  const state = useComboBoxState({
    ...props,
    children: props.children,
    items: filteredOptions,
    inputValue: keywordFilter,
    onInputChange: setKeywordFilter,
    onSelectionChange: onValueSelect,
  })

  const { inputProps, listBoxProps, labelProps } = useComboBox(
    {
      label: title,
      inputRef: inputFieldRef,
      listBoxRef,
      popoverRef,
    },
    state
  )

  const shouldShowOptions = keywordFilter && filteredOptions
  const shouldShowNoResult = keywordFilter && filteredOptions.length === 0

  return (
    <div
      className={`
      text-body-x-small tyd:pb-1
      font-semibold
      ${standalone ? '' : 'bg-white pb-4 rounded-sm shadow-elevation-5'}
      ${className}
    `}
      data-cy={cy}
    >
      {title && (
        <label
          {...labelProps}
          className={`text-black-0 p-4 pb-2 tracking-normal text-heading-x-small block ${labelClassname}`}
        >
          {title}
        </label>
      )}
      <div className="relative">
        {InputIcon}
        <input
          {...inputProps}
          className={`
            ${inputClassName}
            text-black-0 bg-interactive-black-transparent-hover mx-4 h-12 w-[20.9375rem]
            tyd:w-64 placeholder-text-grey-15 placeholder-text-body-small
            ${
              standalone
                ? 'rounded-xs mb-0.5 text-base pl-5 focus:bg-white focus:shadow-interactiveElement hover:bg-interactive-black-transparent-active outline-none bg-interactive-black-transparent-hover'
                : 'border border-solid border-grey-15 mb-3 pl-2.5 active:border active:border-solid active:border-blue-40 focus-visible:outline-none focus-visible:shadow-interactiveElement-small'
            }
          `}
          ref={inputFieldRef as LegacyRef<HTMLInputElement>}
          type={'text'}
          placeholder={searchPlaceholder}
          {...{
            onFocus: (e) => {
              if (inputProps?.onFocus) {
                inputProps?.onFocus(e)
              }
              inputPropsOverrides?.onFocus(e)
            },
            onBlur: (e) => {
              if (inputProps?.onBlur) {
                inputProps?.onBlur(e)
              }
              inputPropsOverrides?.onBlur(e)
            },
          }}
        />
      </div>
      <div ref={popoverRef} className={listClassName}>
        {state.isOpen && shouldShowOptions ? (
          <ListRenderer
            {...listBoxProps}
            listBoxRef={listBoxRef}
            state={state}
            standalone={standalone}
          />
        ) : null}
        {shouldShowNoResult && (
          <span className={'block mx-4 py-3 text-black-0'}>
            {formatMessage({
              id: 'common.noResults',
              defaultMessage: 'No results',
            })}
          </span>
        )}
      </div>
    </div>
  )
})

const ListRenderer = React.memo(
  (
    props: {
      state: ComboBoxState<object>
      listBoxRef: React.RefObject<HTMLUListElement>
      standalone: boolean
    } & AriaListBoxOptions
  ) => {
    const ref = useRef<HTMLUListElement | null>(null)
    const { listBoxRef = ref, state } = props
    const { listBoxProps } = useListBox(props, state, listBoxRef)

    return (
      <ul
        data-cy="filterable-list-items"
        className={`
          flex flex-col
          ${
            props.standalone
              ? 'absolute w-full bg-white z-10 ml-4 w-[20.9375rem] shadow-elevation-1'
              : ''
          }
        `}
        {...listBoxProps}
        ref={listBoxRef}
      >
        {[...state.collection].map((option) => {
          return (
            <ListItemRenderer key={option.key} option={option} state={state} />
          )
        })}
      </ul>
    )
  }
)

const ListItemRenderer = React.memo(
  (props: { option: CollectionItem; state: ComboBoxState<object> }) => {
    const ref = React.useRef<HTMLLIElement | null>(null)
    const { optionProps, isFocused } = useOption(
      { key: props.option.key },
      props.state,
      ref
    )
    const { option } = props

    return (
      <li {...optionProps} ref={ref} className={`text-black-0 cursor-pointer`}>
        <span
          className={`py-3 px-4 focus-visible-outline-none focus-visible-shadow-interactiveElement-small hover-bg-grey-5  block ${
            isFocused ? 'bg-grey-5' : ''
          }`}
        >
          {option.rendered}
        </span>
      </li>
    )
  }
)
