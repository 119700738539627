import { useMemo } from 'react'
import { NewRelic } from '@abcam-web/shared/ui/new-relic'

const getCommonHeadElements = (
  newRelicAppID: number,
  newRelicKey: string,
  newRelicAgentID: string
) => {
  const licenseKey = newRelicKey || ''
  const isNewRelicConfigPresent =
    newRelicAppID > 0 && licenseKey && newRelicAgentID

  const elements = [
    <link key="favicon" href="/favicon.ico" rel="icon" sizes="any" />,
    <link
      key="favicon-svg"
      href="/favicon.svg"
      rel="icon"
      type="image/svg+xml"
    />,
    <link
      key="apple-icon"
      href="/apple-touch-icon.png"
      rel="apple-touch-icon"
    />,
    <link key="manifest" href="/site.webmanifest" rel="manifest" />,
  ]
  if (isNewRelicConfigPresent) {
    elements.push(
      <NewRelic
        key="new-relic"
        applicationID={newRelicAppID}
        licenseKey={licenseKey}
        agentID={newRelicAgentID}
      />
    )
  } else {
    elements.push(
      <script
        key="new-relic-error"
        dangerouslySetInnerHTML={{
          __html: `console.error("NewRelic couldn't be requested, please review the applicationID and licenseKey provided.")`,
        }}
      />
    )
  }

  return elements
}

export const useCommonHeadElements = (
  newRelicAppID: number,
  newRelicKey: string,
  newRelicAgentID: string
) => {
  return useMemo(
    () => getCommonHeadElements(newRelicAppID, newRelicKey, newRelicAgentID),
    [newRelicAppID, newRelicKey, newRelicAgentID]
  )
}
