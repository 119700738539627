const isoLanguages: Record<string, string> = {
  aa: 'Afar',
  ab: 'Abkhaz',
  ae: 'Avestan',
  af: 'Afrikaans',
  ak: 'Akan',
  am: 'Amharic',
  an: 'Aragonese',
  ar: 'Arabic',
  as: 'Assamese',
  av: 'Avaric',
  ay: 'Aymara',
  az: 'Azerbaijani',
  ba: 'Bashkir',
  be: 'Belarusian',
  bg: 'Bulgarian',
  bh: 'Bihari',
  bi: 'Bislama',
  bm: 'Bambara',
  bn: 'Bengali',
  bo: 'Tibetan Standard, Tibetan, Central',
  br: 'Breton',
  bs: 'Bosnian',
  ca: 'Catalan; Valencian',
  ce: 'Chechen',
  ch: 'Chamorro',
  co: 'Corsican',
  cr: 'Cree',
  cs: 'Czech',
  cu: 'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
  cv: 'Chuvash',
  cy: 'Welsh',
  da: 'Danish',
  de: 'German',
  dv: 'Divehi; Dhivehi; Maldivian;',
  ee: 'Ewe',
  el: 'Greek, Modern',
  en: 'English',
  eo: 'Esperanto',
  es: 'Spanish; Castilian',
  et: 'Estonian',
  eu: 'Basque',
  fa: 'Persian',
  ff: 'Fula; Fulah; Pulaar; Pular',
  fi: 'Finnish',
  fj: 'Fijian',
  fo: 'Faroese',
  fr: 'French',
  fy: 'Western Frisian',
  ga: 'Irish',
  gd: 'Scottish Gaelic; Gaelic',
  gl: 'Galician',
  gn: 'Guaraní',
  gu: 'Gujarati',
  gv: 'Manx',
  ha: 'Hausa',
  he: 'Hebrew (modern)',
  hi: 'Hindi',
  ho: 'Hiri Motu',
  hr: 'Croatian',
  ht: 'Haitian; Haitian Creole',
  hu: 'Hungarian',
  hy: 'Armenian',
  hz: 'Herero',
  ia: 'Interlingua',
  id: 'Indonesian',
  ie: 'Interlingue',
  ig: 'Igbo',
  ii: 'Nuosu',
  ik: 'Inupiaq',
  io: 'Ido',
  is: 'Icelandic',
  it: 'Italian',
  iu: 'Inuktitut',
  ja: 'Japanese',
  jv: 'Javanese',
  ka: 'Georgian',
  kg: 'Kongo',
  ki: 'Kikuyu, Gikuyu',
  kj: 'Kwanyama, Kuanyama',
  kk: 'Kazakh',
  kl: 'Kalaallisut, Greenlandic',
  km: 'Khmer',
  kn: 'Kannada',
  ko: 'Korean',
  kr: 'Kanuri',
  ks: 'Kashmiri',
  ku: 'Kurdish',
  kv: 'Komi',
  kw: 'Cornish',
  ky: 'Kirghiz, Kyrgyz',
  la: 'Latin',
  lb: 'Luxembourgish, Letzeburgesch',
  lg: 'Luganda',
  li: 'Limburgish, Limburgan, Limburger',
  ln: 'Lingala',
  lo: 'Lao',
  lt: 'Lithuanian',
  lu: 'Luba-Katanga',
  lv: 'Latvian',
  mg: 'Malagasy',
  mh: 'Marshallese',
  mi: 'Māori',
  mk: 'Macedonian',
  ml: 'Malayalam',
  mn: 'Mongolian',
  mr: 'Marathi (Marāṭhī)',
  ms: 'Malay',
  mt: 'Maltese',
  my: 'Burmese',
  na: 'Nauru',
  nb: 'Norwegian Bokmål',
  nd: 'North Ndebele',
  ne: 'Nepali',
  ng: 'Ndonga',
  nl: 'Dutch',
  nn: 'Norwegian Nynorsk',
  no: 'Norwegian',
  nr: 'South Ndebele',
  nv: 'Navajo, Navaho',
  ny: 'Chichewa; Chewa; Nyanja',
  oc: 'Occitan',
  oj: 'Ojibwe, Ojibwa',
  om: 'Oromo',
  or: 'Oriya',
  os: 'Ossetian, Ossetic',
  pa: 'Panjabi, Punjabi',
  pi: 'Pāli',
  pl: 'Polish',
  ps: 'Pashto, Pushto',
  pt: 'Portuguese',
  qu: 'Quechua',
  rm: 'Romansh',
  rn: 'Kirundi',
  ro: 'Romanian, Moldavian, Moldovan',
  ru: 'Russian',
  rw: 'Kinyarwanda',
  sa: 'Sanskrit (Saṁskṛta)',
  sc: 'Sardinian',
  sd: 'Sindhi',
  se: 'Northern Sami',
  sg: 'Sango',
  si: 'Sinhala, Sinhalese',
  sk: 'Slovak',
  sl: 'Slovene',
  sm: 'Samoan',
  sn: 'Shona',
  so: 'Somali',
  sq: 'Albanian',
  sr: 'Serbian',
  ss: 'Swati',
  st: 'Southern Sotho',
  su: 'Sundanese',
  sv: 'Swedish',
  sw: 'Swahili',
  ta: 'Tamil',
  te: 'Telugu',
  tg: 'Tajik',
  th: 'Thai',
  ti: 'Tigrinya',
  tk: 'Turkmen',
  tl: 'Tagalog',
  tn: 'Tswana',
  to: 'Tonga (Tonga Islands)',
  tr: 'Turkish',
  ts: 'Tsonga',
  tt: 'Tatar',
  tw: 'Twi',
  ty: 'Tahitian',
  ug: 'Uighur, Uyghur',
  uk: 'Ukrainian',
  ur: 'Urdu',
  uz: 'Uzbek',
  ve: 'Venda',
  vi: 'Vietnamese',
  vo: 'Volapük',
  wa: 'Walloon',
  wo: 'Wolof',
  xh: 'Xhosa',
  yi: 'Yiddish',
  yo: 'Yoruba',
  za: 'Zhuang, Chuang',
  zh: 'Chinese',
}

export const getLanguageNameFromCode = (
  languageCode: string
): string | undefined => isoLanguages[languageCode]
