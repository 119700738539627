import { useResizeObserver } from '@lego/hooks/resize-observer'
import debounce from 'lodash/debounce'
import { useState } from 'react'
import { useIsomorphicLayoutEffect } from './use-isomorphic-layout'
import type { MutableRefObject } from 'react'

/**
 * Returns the index of the a child element of a given ref
 * that is the first one to be wrapped.
 * Returns `-1` if no wrapping element found
 */
export const useFindWrappingElementIndex = (
  container: MutableRefObject<HTMLElement | null>,
  leadingDebounce = false
) => {
  const [firstWrappedElementIndex, setFirstWrappedElementIndex] = useState(() =>
    container.current ? findWrappingElementIndex(container.current) : -1
  )

  useIsomorphicLayoutEffect(() => {
    setFirstWrappedElementIndex(
      container.current ? findWrappingElementIndex(container.current) : -1
    )
  }, [container])

  const debouncedResizeHandler = debounce(
    ([entry]: ResizeObserverEntry[]) => {
      if (entry?.target) {
        setFirstWrappedElementIndex(
          findWrappingElementIndex(entry.target as HTMLElement)
        )
      }
    },
    250,
    { leading: leadingDebounce }
  )

  useResizeObserver(container, debouncedResizeHandler)

  return firstWrappedElementIndex
}

const findWrappingElementIndex = ({ children }: HTMLElement) => {
  return Array.from({ length: children.length })
    .map((_, index) => children.item(index))
    .map((child) => {
      const rect = child!.getBoundingClientRect()
      return {
        top: rect.top,
      }
    })
    .reduce((indexOfDifferentTop, { top }, index, all) => {
      // check if already found an index
      if (indexOfDifferentTop !== -1) return indexOfDifferentTop

      const [{ top: firstElementTop }] = all
      if (top > firstElementTop) return index

      return indexOfDifferentTop
    }, -1)
}
