import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
}

export type AddDmdOverrideInput = {
  assetDefinitionNumber: Scalars['String']
  batchName?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  domain: DmdDomain
  locales?: InputMaybe<Array<Scalars['String']>>
  modifiedBy?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
}

export type AminoAcidSequence = {
  __typename?: 'AminoAcidSequence'
  accessionNumber?: Maybe<Scalars['String']>
  actualMolecularWeight?: Maybe<Scalars['String']>
  aminoAcidEnd: Scalars['Int']
  aminoAcidStart: Scalars['Int']
  expressionSystem?: Maybe<Scalars['String']>
  nature: Scalars['String']
  predictedMolecularWeight?: Maybe<Scalars['String']>
  proteinLength?: Maybe<Scalars['String']>
  sequence?: Maybe<Scalars['String']>
  tags: Array<TagDetail>
}

export type AntibodyAttributes = {
  __typename?: 'AntibodyAttributes'
  dissociationConstant?: Maybe<DissociationConstant>
  epitope?: Maybe<Scalars['String']>
  epitopeDomainSpecificity?: Maybe<Scalars['String']>
  isotypeControlFlag: Scalars['Boolean']
  lightChainType?: Maybe<Scalars['String']>
  productCode: Scalars['String']
  specificity?: Maybe<Scalars['String']>
}

export type ApplicationIndependentReactivity = {
  __typename?: 'ApplicationIndependentReactivity'
  reactivityType: ReactivityType
  similarity: Scalars['String']
  species: Scalars['String']
  speciesDetail: Species
}

export type ApplicationReactivity = {
  __typename?: 'ApplicationReactivity'
  fullName: Scalars['String']
  name: Scalars['String']
  species: Array<SpeciesReactivity>
  validation?: Maybe<Scalars['String']>
  validationTested: Scalars['Boolean']
}

export type ApplicationSpeciesReactivityItem = {
  __typename?: 'ApplicationSpeciesReactivityItem'
  applicationName: Scalars['String']
  reactivity: GroupedSpecies
}

export type AssayPrecision = {
  __typename?: 'AssayPrecision'
  coefficientOfVariability?: Maybe<Scalars['String']>
  mean?: Maybe<Scalars['String']>
  replicates?: Maybe<Scalars['Int']>
  reproducibilityType?: Maybe<Scalars['String']>
  sample?: Maybe<Scalars['String']>
  standardDeviation?: Maybe<Scalars['String']>
}

export type AssayTarget = {
  __typename?: 'AssayTarget'
  interWellCv?: Maybe<Scalars['String']>
  proteinStandard: Scalars['String']
  range?: Maybe<Scalars['String']>
  sensitivity: Scalars['String']
  target: SimpleTarget
}

export type AssetComponentDetail = {
  __typename?: 'AssetComponentDetail'
  component: Component
  size?: Maybe<Scalars['String']>
}

export type AssetComponents = {
  __typename?: 'AssetComponents'
  assetComponentDetails: Array<AssetComponentDetail>
  publicAssetCode: Scalars['String']
  sellingSize: Scalars['String']
}

export type BaseProduct = {
  __typename?: 'BaseProduct'
  categoryType?: Maybe<Scalars['String']>
  conjugation?: Maybe<Conjugation>
  name: Scalars['String']
  productCode: Scalars['String']
  productTags: Array<ProductTag>
}

export type BiochemicalAttributes = {
  __typename?: 'BiochemicalAttributes'
  biochemicalName?: Maybe<Scalars['String']>
  biologicalAction: Array<Scalars['String']>
  biologicalDescription?: Maybe<Scalars['String']>
  canonicalSmiles: Scalars['String']
  casNumber: Scalars['String']
  cellPermeable: Scalars['Boolean']
  chemicalStructureImage: Scalars['String']
  chemicalType: Array<Scalars['String']>
  emission?: Maybe<Scalars['String']>
  excitation?: Maybe<Scalars['String']>
  expectedPurity: Scalars['String']
  inchi: Inchi
  isomericSmiles: Scalars['String']
  iupacName: Scalars['String']
  molecularFormula: Scalars['String']
  molecularWeight?: Maybe<Scalars['String']>
  nature: Scalars['String']
  pubChemId: Scalars['String']
  solubility: Array<Scalars['String']>
  source: Scalars['String']
}

export type CellLineAttributes = {
  __typename?: 'CellLineAttributes'
  antibioticResistance?: Maybe<Scalars['String']>
  cryopreservationMedium?: Maybe<Scalars['String']>
  cultureMedium?: Maybe<Scalars['String']>
  initialHandlingGuidelines?: Maybe<Scalars['String']>
  subcultureGuidelines: Array<Scalars['String']>
}

export type CellLineTargetAttributes = {
  __typename?: 'CellLineTargetAttributes'
  biosafetyLevelEurope: Scalars['String']
  biosafetyLevelUnitedStates: Scalars['String']
  cellType?: Maybe<Scalars['String']>
  chromosome?: Maybe<Scalars['String']>
  cosmicId?: Maybe<Scalars['String']>
  cultureProperties: Array<Scalars['String']>
  disease?: Maybe<Scalars['String']>
  gene?: Maybe<Scalars['String']>
  geneEditedCellLineKnockoutValidations: Array<Scalars['String']>
  geneEditingMethod?: Maybe<Scalars['String']>
  geneEditingType?: Maybe<Scalars['String']>
  geneLocation?: Maybe<Scalars['String']>
  morphology?: Maybe<Scalars['String']>
  mutationDescription?: Maybe<Scalars['String']>
  sex?: Maybe<Scalars['String']>
  species: Scalars['String']
  speciesDetail: Species
  strLocus: Array<Maybe<Scalars['String']>>
  tissueType?: Maybe<Scalars['String']>
  viability?: Maybe<Scalars['String']>
  wildTypeExpressionProfile?: Maybe<Scalars['String']>
  zygosity?: Maybe<Scalars['String']>
}

export type Certificate = {
  __typename?: 'Certificate'
  name: Scalars['String']
  type: Scalars['String']
  url: Scalars['String']
}

export type Component = {
  __typename?: 'Component'
  name: Scalars['String']
  number: Scalars['String']
  product?: Maybe<Product>
}

export type ComponentDetails = {
  __typename?: 'ComponentDetails'
  all: Array<Component>
  byAsset: Array<AssetComponents>
}

export type Concentration = {
  __typename?: 'Concentration'
  lowerRange?: Maybe<Scalars['Int']>
  uoM?: Maybe<Scalars['String']>
  upperRange?: Maybe<Scalars['Int']>
  value?: Maybe<Scalars['Int']>
}

export type Conjugation = {
  __typename?: 'Conjugation'
  abbreviation: Scalars['String']
  emission?: Maybe<Scalars['String']>
  excitation?: Maybe<Scalars['String']>
  label: Scalars['String']
}

export type CrossSellRecommendation = {
  __typename?: 'CrossSellRecommendation'
  product: Product
  rank: Scalars['Int']
}

export type DatabaseLink = {
  __typename?: 'DatabaseLink'
  label?: Maybe<Scalars['String']>
  type: Scalars['String']
  value?: Maybe<Scalars['String']>
}

export type DatasheetDocument = {
  __typename?: 'DatasheetDocument'
  name: Scalars['String']
  url: Scalars['String']
}

export type DissociationConstant = {
  __typename?: 'DissociationConstant'
  exponent: Scalars['String']
  value: Scalars['String']
}

export enum DmdDomain {
  CN = 'CN',
  COM = 'COM',
}

export type DmdOverride = {
  __typename?: 'DmdOverride'
  assetDefinitionNumber: Scalars['String']
  batchName?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  domain?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  lastModified?: Maybe<Scalars['String']>
  locales?: Maybe<Array<Maybe<Scalars['String']>>>
  modifiedBy?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type DmdOverrideInput = {
  assetDefinitionNumber: Scalars['String']
  batchName?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  domain?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['String']>
  lastModified?: InputMaybe<Scalars['String']>
  locales?: InputMaybe<Array<Scalars['String']>>
  modifiedBy?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
}

export type Document = {
  __typename?: 'Document'
  certificates?: Maybe<Array<Certificate>>
  countries?: Maybe<Array<Scalars['String']>>
  countryCode?: Maybe<Scalars['String']>
  datasheet?: Maybe<DatasheetDocument>
  languageCode?: Maybe<Scalars['String']>
  lotNumber?: Maybe<Scalars['String']>
  productCode: Scalars['String']
  protocols?: Maybe<Array<Protocol>>
  sds?: Maybe<Array<SdsDocument>>
}

export type DocumentsFilter = {
  countryCode?: InputMaybe<Scalars['String']>
  languageCode?: InputMaybe<Scalars['String']>
  lotNumber?: InputMaybe<Scalars['String']>
  productCode: Scalars['String']
}

export type EditDmdOverrideInput = {
  assetDefinitionNumber: Scalars['String']
  batchName?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  domain: DmdDomain
  id: Scalars['String']
  locales?: InputMaybe<Array<Scalars['String']>>
  modifiedBy?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
}

export type GroupedSpecies = {
  __typename?: 'GroupedSpecies'
  guaranteed: Array<Scalars['String']>
  notRecommended: Array<Scalars['String']>
  predicted: Array<Scalars['String']>
  testedAndGuaranteed: Array<Scalars['String']>
}

export type HandlingHazard = {
  __typename?: 'HandlingHazard'
  label: Scalars['String']
  type: Scalars['String']
  value: Scalars['String']
}

export type Image = {
  __typename?: 'Image'
  application: Scalars['String']
  condition?: Maybe<Scalars['String']>
  copyright?: Maybe<LegalCopyright>
  ecl?: Maybe<Scalars['Boolean']>
  exposureTime?: Maybe<Scalars['String']>
  imageUsage?: Maybe<ImageUsage>
  legend?: Maybe<Scalars['String']>
  seoUrl: Scalars['String']
  source?: Maybe<ImageSource>
  species: Array<Scalars['String']>
  title?: Maybe<Scalars['String']>
  url: Scalars['String']
}

export type ImageBand = {
  __typename?: 'ImageBand'
  observed: Array<Scalars['String']>
  predicted: Array<Scalars['String']>
}

export type ImageBlock = {
  __typename?: 'ImageBlock'
  lanes: Array<ImageLane>
  type: Scalars['String']
}

export type ImageLane = {
  __typename?: 'ImageLane'
  bucketName: Scalars['String']
  description: Scalars['String']
}

export type ImageSource = {
  __typename?: 'ImageSource'
  externalId?: Maybe<Scalars['String']>
  name: Scalars['String']
}

export type ImageUsage = {
  __typename?: 'ImageUsage'
  bands?: Maybe<ImageBand>
  imageBlocks: Array<ImageBlock>
  legend?: Maybe<Scalars['String']>
}

export type Immunogen = {
  __typename?: 'Immunogen'
  databaseLinks: Array<DatabaseLink>
  description: Scalars['String']
  sensitivity: Scalars['String']
  sequence?: Maybe<Scalars['String']>
}

export type Inchi = {
  __typename?: 'Inchi'
  key: Scalars['String']
  value: Scalars['String']
}

export type Isoform = {
  __typename?: 'Isoform'
  isoformAccessionNumber: Scalars['String']
  sequenceLength: Scalars['Int']
  subcellularLocalisations: Array<SubcellularLocalisation>
}

export type KitAttributes = {
  __typename?: 'KitAttributes'
  assayPlatform?: Maybe<Scalars['String']>
  assayPrecision: Array<AssayPrecision>
  assayTargets: Array<AssayTarget>
  assayTime?: Maybe<Scalars['String']>
  assayType?: Maybe<Scalars['String']>
  componentDetails: ComponentDetails
  detectionMethod?: Maybe<Scalars['String']>
  generalRecovery?: Maybe<Scalars['String']>
  measurementMethod?: Maybe<Scalars['String']>
  miRnaTargets: Array<MiRnaTarget>
  productCode: Scalars['String']
  range?: Maybe<Scalars['String']>
  recovery: Array<Recovery>
  sampleTypes: Array<SampleType>
  sensitivity?: Maybe<Scalars['String']>
}

export type LegalCopyright = {
  __typename?: 'LegalCopyright'
  credit?: Maybe<Scalars['String']>
}

export type LocalisedField = {
  __typename?: 'LocalisedField'
  en: Scalars['String']
  ja: Scalars['String']
  zh: Scalars['String']
}

export type Marker = {
  __typename?: 'Marker'
  name?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
}

export type MiRnaTarget = {
  __typename?: 'MiRnaTarget'
  homology: Array<Scalars['String']>
  miRnaSequence: Scalars['String']
  mimatId: Scalars['String']
  rowOrderNumber: Scalars['String']
  target: SimpleTarget
}

export type Modification = {
  __typename?: 'Modification'
  type: ModificationType
  value: Scalars['String']
}

export enum ModificationType {
  ONE_LETTER = 'ONE_LETTER',
  THREE_LETTER = 'THREE_LETTER',
}

export type Mutation = {
  __typename?: 'Mutation'
  addDmdOverride?: Maybe<Scalars['Boolean']>
  addDmdOverrides?: Maybe<Scalars['Boolean']>
  deleteDmdOverride?: Maybe<Scalars['Boolean']>
  deleteDmdOverrides?: Maybe<Scalars['Boolean']>
  editDmdOverride?: Maybe<Scalars['Boolean']>
}

export type MutationAddDmdOverrideArgs = {
  input: AddDmdOverrideInput
}

export type MutationAddDmdOverridesArgs = {
  input: Array<InputMaybe<AddDmdOverrideInput>>
}

export type MutationDeleteDmdOverrideArgs = {
  id: Scalars['String']
}

export type MutationDeleteDmdOverridesArgs = {
  ids: Array<InputMaybe<Scalars['String']>>
}

export type MutationEditDmdOverrideArgs = {
  input: EditDmdOverrideInput
}

export type Note = {
  __typename?: 'Note'
  noteType?: Maybe<Scalars['String']>
  statement: Scalars['String']
}

export type PaginatedProducts = {
  __typename?: 'PaginatedProducts'
  count: Scalars['Int']
  products: Array<Product>
}

export type PaginatedPublicationResult = {
  __typename?: 'PaginatedPublicationResult'
  count: Scalars['Int']
  items: Array<Publication>
}

export type PaginationFilter = {
  limit: Scalars['Int']
  offset: Scalars['Int']
}

export type PredictedReactivity = {
  __typename?: 'PredictedReactivity'
  expectedToReact: Scalars['Boolean']
  similarity: Scalars['String']
  species: Scalars['String']
  speciesDetail: Species
}

export type Product = {
  __typename?: 'Product'
  allowTargetTierTwoOverride: Scalars['Boolean']
  alternativeNames: Array<Scalars['String']>
  animalFree?: Maybe<Scalars['Boolean']>
  antibodyAttributes?: Maybe<AntibodyAttributes>
  associatedProducts: Array<ProductAssociation>
  biochemicalAttributes?: Maybe<BiochemicalAttributes>
  carrierFree?: Maybe<Scalars['Boolean']>
  categoryType: Scalars['String']
  cellLineAttributes?: Maybe<CellLineAttributes>
  cellLineTargetAttributes?: Maybe<CellLineTargetAttributes>
  clonality?: Maybe<Scalars['String']>
  cloneNumber?: Maybe<Scalars['String']>
  conjugation?: Maybe<Conjugation>
  conjugations: Array<Conjugation>
  crossSellRecommendations: Array<CrossSellRecommendation>
  directReplacementProduct?: Maybe<Product>
  firstActivationDate?: Maybe<Scalars['String']>
  form?: Maybe<Scalars['String']>
  format?: Maybe<Scalars['String']>
  formulation?: Maybe<Scalars['String']>
  formulationConstituents?: Maybe<Scalars['String']>
  handlingHazards: Array<HandlingHazard>
  hostSpecies?: Maybe<Scalars['String']>
  hostSpeciesDetail?: Maybe<Species>
  images: Array<Image>
  immunogen?: Maybe<Immunogen>
  isoType?: Maybe<Scalars['String']>
  itemClass: Scalars['String']
  kitAttributes?: Maybe<KitAttributes>
  localisedName: LocalisedField
  marker?: Maybe<Marker>
  name: Scalars['String']
  notes: Array<Note>
  productCode: Scalars['String']
  productSlug: Scalars['String']
  productSummary?: Maybe<Scalars['String']>
  productTags: Array<ProductTag>
  proteinAttributes?: Maybe<ProteinAttributes>
  publicationOptionCombinations: Array<PublicationCombination>
  publications: PaginatedPublicationResult
  purificationNotes: Array<Note>
  purity: Array<ProteinPurity>
  qualityScore?: Maybe<Scalars['Float']>
  reactivity: Reactivity
  recombinant?: Maybe<Scalars['String']>
  reconstitution?: Maybe<Scalars['String']>
  relatedImmunogens: Array<Immunogen>
  relatedTargets: Array<Target>
  reviewsSummary: ReviewsSummary
  secondaryAntibodyAttributes?: Maybe<SecondaryAntibodyAttributes>
  seoClass: SeoClass
  standardProtein?: Maybe<StandardProtein>
  status: ProductStatus
  storage?: Maybe<Storage>
  target?: Maybe<Target>
  targetSummaryNotes: Array<Note>
  topRecommendedProducts: Array<Product>
  trialSizeExclusiveCountries: Array<Scalars['String']>
  unpublishedAttributes?: Maybe<UnpublishedAttributes>
  variations: Array<Variation>
}

export type ProductPublicationsArgs = {
  filter?: InputMaybe<PublicationsFilter>
}

export type ProductUnpublishedAttributesArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ProductAssociation = {
  __typename?: 'ProductAssociation'
  associatedProduct: Product
  relationship: Scalars['String']
}

export type ProductBatch = {
  __typename?: 'ProductBatch'
  batchConcentration?: Maybe<Scalars['String']>
  concentrationRange?: Maybe<Scalars['String']>
  lotNumber?: Maybe<Scalars['String']>
  productCode: Scalars['String']
}

export enum ProductStatus {
  ACTIVE = 'ACTIVE',
  AUTHORISED = 'AUTHORISED',
  DISCONTINUED = 'DISCONTINUED',
  INACTIVE = 'INACTIVE',
  QUARANTINED = 'QUARANTINED',
}

export type ProductTag = {
  __typename?: 'ProductTag'
  tagCode: Scalars['String']
}

export type ProductsFilter = {
  casNumber?: InputMaybe<Scalars['String']>
  imageNumber?: InputMaybe<Scalars['String']>
  productCodes?: InputMaybe<Array<Scalars['String']>>
  targetNumber?: InputMaybe<Scalars['String']>
}

export type ProteinAttributes = {
  __typename?: 'ProteinAttributes'
  active: Scalars['Boolean']
  /** @deprecated This field is deprecated. Use 'aminoAcidSequences' instead. */
  aminoAcidSequence?: Maybe<AminoAcidSequence>
  aminoAcidSequences: Array<AminoAcidSequence>
  biologicalActivity?: Maybe<Scalars['String']>
  endotoxinLevel?: Maybe<Scalars['String']>
  expressionSystem?: Maybe<Scalars['String']>
  massSpectrometry?: Maybe<Scalars['String']>
}

export type ProteinPurity = {
  __typename?: 'ProteinPurity'
  purificationFraction?: Maybe<Scalars['String']>
  purificationTechnique?: Maybe<Scalars['String']>
  purificationTechniqueReagent?: Maybe<Scalars['String']>
  purity?: Maybe<Scalars['String']>
  purityAssessment?: Maybe<Scalars['String']>
}

export type Protocol = {
  __typename?: 'Protocol'
  documentType: Scalars['String']
  language?: Maybe<Scalars['String']>
  name: Scalars['String']
  url: Scalars['String']
}

export type Publication = {
  __typename?: 'Publication'
  authors: Array<Scalars['String']>
  journal: Scalars['String']
  name: Scalars['String']
  pages: Scalars['String']
  productCode: Scalars['String']
  publicationDate: Scalars['String']
  pubmedId: Scalars['String']
  references: Array<ReferenceUsage>
  volume: Scalars['String']
}

export type PublicationCombination = {
  __typename?: 'PublicationCombination'
  application: Scalars['String']
  species: Scalars['String']
}

export type PublicationsFilter = {
  application?: InputMaybe<Scalars['String']>
  keywords?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  species?: InputMaybe<Scalars['String']>
}

export type Query = {
  __typename?: 'Query'
  _service: _Service
  dmdOverride?: Maybe<DmdOverride>
  dmdOverrideHistory?: Maybe<Array<Maybe<DmdOverride>>>
  dmdOverrides: Array<DmdOverride>
  document?: Maybe<Document>
  /**
   * Return type: array of comma separated adId,domain, comma separated locales
   * e.g. ['AB13245,com,"en-gb,en-us"', 'AB9867,jp,null']
   */
  overlappingDmdOverrides: Array<Maybe<Scalars['String']>>
  /**
   * Return type: array of comma separated adId,domain, comma separated locales
   * e.g. ['AB13245,com,"en-gb,en-us"', 'AB9867,jp,null']
   */
  overridesWithDuplicateTitleDescription: Array<Maybe<Scalars['String']>>
  paginatedProducts: PaginatedProducts
  product: Product
  productBatch: ProductBatch
  products: Array<Product>
  publication?: Maybe<Publication>
  target: Target
}

export type QueryDmdOverrideArgs = {
  abId: Scalars['String']
  domain: DmdDomain
  locale: Scalars['String']
}

export type QueryDmdOverrideHistoryArgs = {
  abId?: InputMaybe<Scalars['String']>
  deleted?: InputMaybe<Scalars['Boolean']>
  dmdOverrideId?: InputMaybe<Scalars['String']>
  searchTerm?: InputMaybe<Scalars['String']>
}

export type QueryDmdOverridesArgs = {
  abId?: InputMaybe<Scalars['String']>
  searchTerm?: InputMaybe<Scalars['String']>
}

export type QueryDocumentArgs = {
  filter: DocumentsFilter
}

export type QueryOverlappingDmdOverridesArgs = {
  input: Array<InputMaybe<DmdOverrideInput>>
}

export type QueryOverridesWithDuplicateTitleDescriptionArgs = {
  input: Array<InputMaybe<DmdOverrideInput>>
}

export type QueryPaginatedProductsArgs = {
  page: PaginationFilter
}

export type QueryProductArgs = {
  filter?: InputMaybe<PublicationsFilter>
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
}

export type QueryProductBatchArgs = {
  lotNumber?: InputMaybe<Scalars['String']>
  productCode: Scalars['String']
}

export type QueryProductsArgs = {
  filter: ProductsFilter
}

export type QueryPublicationArgs = {
  referenceNumber: Scalars['String']
}

export type QueryTargetArgs = {
  id?: InputMaybe<Scalars['String']>
  slug?: InputMaybe<Scalars['String']>
}

export type Reactivity = {
  __typename?: 'Reactivity'
  appGrid?: Maybe<Array<ApplicationSpeciesReactivityItem>>
  applications: Array<ApplicationReactivity>
  speciesReactivity: Array<ApplicationIndependentReactivity>
}

export enum ReactivityOption {
  GUARANTEED_AND_PREDICTED = 'GUARANTEED_AND_PREDICTED',
  GUARANTEED_AND_TESTED = 'GUARANTEED_AND_TESTED',
  NOT_RECOMMENDED = 'NOT_RECOMMENDED',
  NOT_SUITABLE = 'NOT_SUITABLE',
  NO_INFORMATION = 'NO_INFORMATION',
}

export enum ReactivityType {
  DOES_NOT_REACT = 'DOES_NOT_REACT',
  EXPECTED_TO_REACT = 'EXPECTED_TO_REACT',
  NOT_EXPECTED_TO_REACT = 'NOT_EXPECTED_TO_REACT',
  REACTS = 'REACTS',
}

export type Recovery = {
  __typename?: 'Recovery'
  average?: Maybe<Scalars['String']>
  range?: Maybe<Scalars['String']>
  sample?: Maybe<Scalars['String']>
}

export type ReferenceUsage = {
  __typename?: 'ReferenceUsage'
  application: Scalars['String']
  species: Scalars['String']
}

export type Relevance = {
  __typename?: 'Relevance'
  cellularLocalization: Array<Scalars['String']>
  developmentalStage: Array<Scalars['String']>
  domain: Array<Scalars['String']>
  function: Array<Scalars['String']>
  involvementInDisease: Array<Scalars['String']>
  pathway: Array<Scalars['String']>
  postTranslationalModifications: Array<Scalars['String']>
  sequenceSimilarities: Array<Scalars['String']>
  tissueSpecificity: Array<Scalars['String']>
}

export type ReviewsSummary = {
  __typename?: 'ReviewsSummary'
  aggregatedRating: Scalars['Float']
  numberOfReviews: Scalars['Int']
}

export type SampleType = {
  __typename?: 'SampleType'
  name: Scalars['String']
  simplifiedName: Scalars['String']
  slug: Scalars['String']
}

export type SdsDocument = {
  __typename?: 'SdsDocument'
  countryCode?: Maybe<Scalars['String']>
  displayName: Scalars['String']
  languageCode?: Maybe<Scalars['String']>
  name: Scalars['String']
  url: Scalars['String']
}

export type SecondaryAntibodyAttributes = {
  __typename?: 'SecondaryAntibodyAttributes'
  preAdsorbed: Scalars['Boolean']
  specificity?: Maybe<Scalars['String']>
  targetIsotypes: Array<Scalars['String']>
  targetSpecies: Scalars['String']
  targetSpeciesDetails: Species
  targetSpecificity: Scalars['String']
}

export type SeoClass = {
  __typename?: 'SeoClass'
  levelOne: Scalars['String']
  levelTwo?: Maybe<Scalars['String']>
}

export type SimpleTarget = {
  __typename?: 'SimpleTarget'
  name: Scalars['String']
  number: Scalars['String']
}

export type Species = {
  __typename?: 'Species'
  name: Scalars['String']
  number: Scalars['String']
  scientificName?: Maybe<Scalars['String']>
  slug: Scalars['String']
}

export type SpeciesReactivity = {
  __typename?: 'SpeciesReactivity'
  name: Scalars['String']
  notes?: Maybe<Scalars['String']>
  recommendedDilution: Scalars['String']
  speciesDetail?: Maybe<Species>
  speciesIndependent: Scalars['Boolean']
  suitability: Suitability
}

export type StandardProtein = {
  __typename?: 'StandardProtein'
  accession: Scalars['String']
  function?: Maybe<Scalars['String']>
  isoforms: Array<Isoform>
  postTranslationalModification?: Maybe<Scalars['String']>
  sequenceSimilarities?: Maybe<Scalars['String']>
  species: Scalars['String']
  speciesDetail: Species
}

export type Statement = {
  __typename?: 'Statement'
  statementDescription?: Maybe<Scalars['String']>
  statementName: Scalars['String']
  statementNumber: Scalars['String']
  statementOrder: Scalars['Int']
  statementType: Scalars['String']
}

export type Storage = {
  __typename?: 'Storage'
  aliquotingInformation: Array<Scalars['String']>
  appropriateLongTermStorageConditions: Array<Scalars['String']>
  appropriateShortTermStorageConditions: Array<Scalars['String']>
  appropriateShortTermStorageDuration: Array<Scalars['String']>
  shippedAtConditions: Array<Scalars['String']>
  storageInformation: Array<Scalars['String']>
}

export type SubcellularLocalisation = {
  __typename?: 'SubcellularLocalisation'
  localisation: Scalars['String']
  type: Scalars['String']
}

export enum Suitability {
  NOT_SUITABLE = 'NOT_SUITABLE',
  NO_EXPERIMENTAL_DATA_EXPECTED_TO_REACT = 'NO_EXPERIMENTAL_DATA_EXPECTED_TO_REACT',
  NO_EXPERIMENTAL_DATA_NOT_EXPECTED_TO_REACT = 'NO_EXPERIMENTAL_DATA_NOT_EXPECTED_TO_REACT',
  TESTED_AND_DOES_NOT_REACT = 'TESTED_AND_DOES_NOT_REACT',
  TESTED_AND_REACTS = 'TESTED_AND_REACTS',
}

export type TagDetail = {
  __typename?: 'TagDetail'
  tag: Scalars['String']
  terminus: Scalars['String']
}

export type Target = {
  __typename?: 'Target'
  alternativeNames: Array<Scalars['String']>
  databaseLinks: Array<Maybe<DatabaseLink>>
  isoformAccession?: Maybe<DatabaseLink>
  modificationSummary?: Maybe<Scalars['String']>
  modifications: Array<Modification>
  molecularWeight?: Maybe<Scalars['String']>
  mutationSummary?: Maybe<Scalars['String']>
  name: Scalars['String']
  number: Scalars['String']
  relatedTargets: Array<Target>
  relevance?: Maybe<Relevance>
  researchAreas: Array<Scalars['String']>
  shortModificationSummary?: Maybe<Scalars['String']>
  slug: Scalars['String']
  targetGrouping?: Maybe<TargetGrouping>
  type?: Maybe<Scalars['String']>
}

export type TargetGrouping = {
  __typename?: 'TargetGrouping'
  alternativeNames: Array<Scalars['String']>
  alternativeNamesTierTwo: Array<Scalars['String']>
  name: Scalars['String']
  nameTierTwo?: Maybe<Scalars['String']>
  slug: Scalars['String']
  slugTierTwo?: Maybe<Scalars['String']>
}

export type UnpublishedAttributes = {
  __typename?: 'UnpublishedAttributes'
  alternativeProducts: Array<Product>
  datasheet?: Maybe<DatasheetDocument>
  directReplacementProduct?: Maybe<Product>
  lastInactivationDate?: Maybe<Scalars['String']>
  productCode: Scalars['String']
  protocols: Array<Protocol>
  unpublishedReason?: Maybe<Scalars['String']>
}

export type Variation = {
  __typename?: 'Variation'
  product: Product
  relationship: Scalars['String']
}

export type _Service = {
  __typename?: '_Service'
  /** The sdl representing the federated service capabilities. Includes federation directives, removes federation types, and includes rest of full schema after schema directives have been applied */
  sdl?: Maybe<Scalars['String']>
}

export type AminoAcidSequenceFragment = {
  __typename?: 'AminoAcidSequence'
  accessionNumber?: string | null
  actualMolecularWeight?: string | null
  aminoAcidEnd: number
  aminoAcidStart: number
  expressionSystem?: string | null
  nature: string
  predictedMolecularWeight?: string | null
  proteinLength?: string | null
  sequence?: string | null
  tags: Array<{ __typename?: 'TagDetail'; tag: string; terminus: string }>
}

export type AntibodyAttributesFragment = {
  __typename?: 'Product'
  antibodyAttributes?: {
    __typename?: 'AntibodyAttributes'
    productCode: string
    specificity?: string | null
    epitope?: string | null
    epitopeDomainSpecificity?: string | null
    lightChainType?: string | null
    isotypeControlFlag: boolean
    dissociationConstant?: {
      __typename?: 'DissociationConstant'
      value: string
      exponent: string
    } | null
  } | null
}

export type AssociatedProductsFragment = {
  __typename?: 'Product'
  associatedProducts: Array<{
    __typename?: 'ProductAssociation'
    relationship: string
    associatedProduct: {
      __typename?: 'Product'
      itemClass: string
      productCode: string
      name: string
      categoryType: string
      productSlug: string
      firstActivationDate?: string | null
      seoClass: { __typename?: 'SeoClass'; levelOne: string }
      reviewsSummary: {
        __typename?: 'ReviewsSummary'
        aggregatedRating: number
        numberOfReviews: number
      }
    }
  }>
}

export type BiochemicalFragment = {
  __typename?: 'BiochemicalAttributes'
  biochemicalName?: string | null
  biologicalDescription?: string | null
  canonicalSmiles: string
  isomericSmiles: string
  iupacName: string
  casNumber: string
  cellPermeable: boolean
  chemicalType: Array<string>
  emission?: string | null
  excitation?: string | null
  expectedPurity: string
  molecularFormula: string
  molecularWeight?: string | null
  nature: string
  pubChemId: string
  solubility: Array<string>
  source: string
  inchi: { __typename?: 'Inchi'; value: string; key: string }
}

export type CellLineFragment = {
  __typename?: 'Product'
  cellLineAttributes?: {
    __typename?: 'CellLineAttributes'
    cultureMedium?: string | null
    cryopreservationMedium?: string | null
    initialHandlingGuidelines?: string | null
    subcultureGuidelines: Array<string>
    antibioticResistance?: string | null
  } | null
}

export type CellLineTargetFragment = {
  __typename?: 'Product'
  cellLineTargetAttributes?: {
    __typename?: 'CellLineTargetAttributes'
    chromosome?: string | null
    cosmicId?: string | null
    disease?: string | null
    gene?: string | null
    geneEditedCellLineKnockoutValidations: Array<string>
    geneEditingMethod?: string | null
    geneEditingType?: string | null
    geneLocation?: string | null
    strLocus: Array<string | null>
    cultureProperties: Array<string>
    cellType?: string | null
    species: string
    tissueType?: string | null
    wildTypeExpressionProfile?: string | null
    mutationDescription?: string | null
    biosafetyLevelEurope: string
    biosafetyLevelUnitedStates: string
    sex?: string | null
    viability?: string | null
    zygosity?: string | null
    speciesDetail: {
      __typename?: 'Species'
      name: string
      number: string
      slug: string
    }
  } | null
}

export type ConjugationFragment = {
  __typename?: 'Product'
  conjugation?: {
    __typename?: 'Conjugation'
    label: string
    emission?: string | null
    excitation?: string | null
  } | null
}

export type Seo_DmdOverrideQueryVariables = Exact<{
  abId?: InputMaybe<Scalars['String']>
  searchTerm?: InputMaybe<Scalars['String']>
}>

export type Seo_DmdOverrideQuery = {
  __typename?: 'Query'
  dmdOverrides: Array<{
    __typename?: 'DmdOverride'
    id?: string | null
    assetDefinitionNumber: string
    domain?: string | null
    locales?: Array<string | null> | null
    batchName?: string | null
    title?: string | null
    description?: string | null
    modifiedBy?: string | null
    lastModified?: string | null
  }>
}

export type Seo_AddDmdOverrideMutationVariables = Exact<{
  input: AddDmdOverrideInput
}>

export type Seo_AddDmdOverrideMutation = {
  __typename?: 'Mutation'
  addDmdOverride?: boolean | null
}

export type Seo_AddDmdOverridesMutationVariables = Exact<{
  input:
    | Array<InputMaybe<AddDmdOverrideInput>>
    | InputMaybe<AddDmdOverrideInput>
}>

export type Seo_AddDmdOverridesMutation = {
  __typename?: 'Mutation'
  addDmdOverrides?: boolean | null
}

export type Seo_EditDmdOverrideMutationVariables = Exact<{
  input: EditDmdOverrideInput
}>

export type Seo_EditDmdOverrideMutation = {
  __typename?: 'Mutation'
  editDmdOverride?: boolean | null
}

export type Seo_DeleteDmdOverrideMutationVariables = Exact<{
  id: Scalars['String']
}>

export type Seo_DeleteDmdOverrideMutation = {
  __typename?: 'Mutation'
  deleteDmdOverride?: boolean | null
}

export type Seo_DeleteDmdOverridesMutationVariables = Exact<{
  ids: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
}>

export type Seo_DeleteDmdOverridesMutation = {
  __typename?: 'Mutation'
  deleteDmdOverrides?: boolean | null
}

export type Seo_DmdOverrideHistoryQueryVariables = Exact<{
  dmdOverrideId?: InputMaybe<Scalars['String']>
  deleted?: InputMaybe<Scalars['Boolean']>
  abId?: InputMaybe<Scalars['String']>
  searchTerm?: InputMaybe<Scalars['String']>
}>

export type Seo_DmdOverrideHistoryQuery = {
  __typename?: 'Query'
  dmdOverrideHistory?: Array<{
    __typename?: 'DmdOverride'
    id?: string | null
    assetDefinitionNumber: string
    domain?: string | null
    locales?: Array<string | null> | null
    batchName?: string | null
    title?: string | null
    description?: string | null
    modifiedBy?: string | null
    lastModified?: string | null
  } | null> | null
}

export type Seo_DmdValidationQueryVariables = Exact<{
  input: Array<InputMaybe<DmdOverrideInput>> | InputMaybe<DmdOverrideInput>
}>

export type Seo_DmdValidationQuery = {
  __typename?: 'Query'
  overlappingDmdOverrides: Array<string | null>
  overridesWithDuplicateTitleDescription: Array<string | null>
}

export type HandlingHazardFragment = {
  __typename?: 'HandlingHazard'
  label: string
  value: string
  type: string
}

export type ImageFragment = {
  __typename?: 'Image'
  application: string
  legend?: string | null
  species: Array<string>
  title?: string | null
  url: string
  seoUrl: string
  ecl?: boolean | null
  condition?: string | null
  exposureTime?: string | null
  imageUsage?: {
    __typename?: 'ImageUsage'
    legend?: string | null
    imageBlocks: Array<{
      __typename?: 'ImageBlock'
      type: string
      lanes: Array<{
        __typename?: 'ImageLane'
        bucketName: string
        description: string
      }>
    }>
    bands?: {
      __typename?: 'ImageBand'
      predicted: Array<string>
      observed: Array<string>
    } | null
  } | null
  copyright?: { __typename?: 'LegalCopyright'; credit?: string | null } | null
  source?: {
    __typename?: 'ImageSource'
    externalId?: string | null
    name: string
  } | null
}

export type ImageBlockFragment = {
  __typename?: 'ImageBlock'
  type: string
  lanes: Array<{
    __typename?: 'ImageLane'
    bucketName: string
    description: string
  }>
}

export type ImageUsageFragment = {
  __typename?: 'ImageUsage'
  legend?: string | null
  imageBlocks: Array<{
    __typename?: 'ImageBlock'
    type: string
    lanes: Array<{
      __typename?: 'ImageLane'
      bucketName: string
      description: string
    }>
  }>
  bands?: {
    __typename?: 'ImageBand'
    predicted: Array<string>
    observed: Array<string>
  } | null
}

export type ImmunogenFragment = {
  __typename?: 'Immunogen'
  description: string
  sequence?: string | null
  sensitivity: string
  databaseLinks: Array<{
    __typename?: 'DatabaseLink'
    label?: string | null
    value?: string | null
    type: string
  }>
}

export type ComponentAttributesFragment = {
  __typename?: 'Component'
  number: string
  name: string
  product?: {
    __typename?: 'Product'
    productCode: string
    productSlug: string
    seoClass: {
      __typename?: 'SeoClass'
      levelOne: string
      levelTwo?: string | null
    }
  } | null
}

export type KitAttributesFragment = {
  __typename?: 'KitAttributes'
  assayPlatform?: string | null
  assayTime?: string | null
  assayType?: string | null
  detectionMethod?: string | null
  range?: string | null
  generalRecovery?: string | null
  sensitivity?: string | null
  assayPrecision: Array<{
    __typename?: 'AssayPrecision'
    mean?: string | null
    replicates?: number | null
    reproducibilityType?: string | null
    sample?: string | null
    standardDeviation?: string | null
    coefficientOfVariability?: string | null
  }>
  recovery: Array<{
    __typename?: 'Recovery'
    average?: string | null
    range?: string | null
    sample?: string | null
  }>
  sampleTypes: Array<{
    __typename?: 'SampleType'
    simplifiedName: string
    name: string
    slug: string
  }>
  componentDetails: {
    __typename?: 'ComponentDetails'
    all: Array<{
      __typename?: 'Component'
      number: string
      name: string
      product?: {
        __typename?: 'Product'
        productCode: string
        productSlug: string
        seoClass: {
          __typename?: 'SeoClass'
          levelOne: string
          levelTwo?: string | null
        }
      } | null
    }>
    byAsset: Array<{
      __typename?: 'AssetComponents'
      publicAssetCode: string
      sellingSize: string
      assetComponentDetails: Array<{
        __typename?: 'AssetComponentDetail'
        size?: string | null
        component: {
          __typename?: 'Component'
          number: string
          name: string
          product?: {
            __typename?: 'Product'
            productCode: string
            productSlug: string
            seoClass: {
              __typename?: 'SeoClass'
              levelOne: string
              levelTwo?: string | null
            }
          } | null
        }
      }>
    }>
  }
  assayTargets: Array<{
    __typename?: 'AssayTarget'
    sensitivity: string
    range?: string | null
    proteinStandard: string
    interWellCv?: string | null
    target: { __typename?: 'SimpleTarget'; name: string; number: string }
  }>
  miRnaTargets: Array<{
    __typename?: 'MiRnaTarget'
    mimatId: string
    miRnaSequence: string
    rowOrderNumber: string
    homology: Array<string>
    target: { __typename?: 'SimpleTarget'; number: string; name: string }
  }>
}

export type Browse_ProductBatch_BatchConcentrationsQueryVariables = Exact<{
  productCode: Scalars['String']
  lotNumber: Scalars['String']
}>

export type Browse_ProductBatch_BatchConcentrationsQuery = {
  __typename?: 'Query'
  productBatch: {
    __typename?: 'ProductBatch'
    batchConcentration?: string | null
    lotNumber?: string | null
  }
}

export type Browse_Document_CertificatePdfQueryVariables = Exact<{
  query: DocumentsFilter
}>

export type Browse_Document_CertificatePdfQuery = {
  __typename?: 'Query'
  document?: {
    __typename?: 'Document'
    lotNumber?: string | null
    productCode: string
    certificates?: Array<{
      __typename?: 'Certificate'
      type: string
      url: string
      name: string
    }> | null
  } | null
}

export type Browse_Document_ProtocolPdfQueryVariables = Exact<{
  query: DocumentsFilter
}>

export type Browse_Document_ProtocolPdfQuery = {
  __typename?: 'Query'
  document?: {
    __typename?: 'Document'
    protocols?: Array<{
      __typename?: 'Protocol'
      documentType: string
      language?: string | null
      url: string
    }> | null
  } | null
}

export type Browse_Document_DatasheetPdfQueryVariables = Exact<{
  query: DocumentsFilter
}>

export type Browse_Document_DatasheetPdfQuery = {
  __typename?: 'Query'
  document?: {
    __typename?: 'Document'
    datasheet?: {
      __typename?: 'DatasheetDocument'
      name: string
      url: string
    } | null
  } | null
}

export type Browse_Product_PublicationsQueryVariables = Exact<{
  productCode: Scalars['String']
  filter: PublicationsFilter
}>

export type Browse_Product_PublicationsQuery = {
  __typename?: 'Query'
  product: {
    __typename?: 'Product'
    publications: {
      __typename?: 'PaginatedPublicationResult'
      count: number
      items: Array<{
        __typename?: 'Publication'
        authors: Array<string>
        journal: string
        name: string
        pages: string
        productCode: string
        publicationDate: string
        pubmedId: string
        volume: string
        references: Array<{
          __typename?: 'ReferenceUsage'
          application: string
          species: string
        }>
      }>
    }
  }
}

export type Browse_ProductBatch_ProductConcentrationsQueryVariables = Exact<{
  id: Scalars['String']
}>

export type Browse_ProductBatch_ProductConcentrationsQuery = {
  __typename?: 'Query'
  productBatch: {
    __typename?: 'ProductBatch'
    concentrationRange?: string | null
  }
}

export type Browse_Product_ProductDetailsQueryVariables = Exact<{
  abId: Scalars['String']
  domain: DmdDomain
  locale: Scalars['String']
}>

export type Browse_Product_ProductDetailsQuery = {
  __typename?: 'Query'
  product: {
    __typename?: 'Product'
    allowTargetTierTwoOverride: boolean
    animalFree?: boolean | null
    carrierFree?: boolean | null
    itemClass: string
    categoryType: string
    clonality?: string | null
    cloneNumber?: string | null
    form?: string | null
    format?: string | null
    formulation?: string | null
    reconstitution?: string | null
    formulationConstituents?: string | null
    hostSpecies?: string | null
    isoType?: string | null
    name: string
    productSummary?: string | null
    productCode: string
    productSlug: string
    recombinant?: string | null
    trialSizeExclusiveCountries: Array<string>
    status: ProductStatus
    conjugations: Array<{ __typename?: 'Conjugation'; label: string }>
    standardProtein?: {
      __typename?: 'StandardProtein'
      accession: string
      function?: string | null
      postTranslationalModification?: string | null
      sequenceSimilarities?: string | null
      species: string
      isoforms: Array<{
        __typename?: 'Isoform'
        isoformAccessionNumber: string
        sequenceLength: number
        subcellularLocalisations: Array<{
          __typename?: 'SubcellularLocalisation'
          localisation: string
          type: string
        }>
      }>
    } | null
    biochemicalAttributes?: {
      __typename?: 'BiochemicalAttributes'
      biochemicalName?: string | null
      biologicalDescription?: string | null
      canonicalSmiles: string
      isomericSmiles: string
      iupacName: string
      casNumber: string
      cellPermeable: boolean
      chemicalType: Array<string>
      emission?: string | null
      excitation?: string | null
      expectedPurity: string
      molecularFormula: string
      molecularWeight?: string | null
      nature: string
      pubChemId: string
      solubility: Array<string>
      source: string
      inchi: { __typename?: 'Inchi'; value: string; key: string }
    } | null
    directReplacementProduct?: {
      __typename?: 'Product'
      status: ProductStatus
      productCode: string
      name: string
      categoryType: string
      productSlug: string
      itemClass: string
      firstActivationDate?: string | null
      images: Array<{ __typename?: 'Image'; url: string }>
      publications: { __typename?: 'PaginatedPublicationResult'; count: number }
      seoClass: { __typename?: 'SeoClass'; levelOne: string }
      reviewsSummary: {
        __typename?: 'ReviewsSummary'
        aggregatedRating: number
        numberOfReviews: number
      }
    } | null
    topRecommendedProducts: Array<{
      __typename?: 'Product'
      status: ProductStatus
      productCode: string
      name: string
      categoryType: string
      productSlug: string
      itemClass: string
      firstActivationDate?: string | null
      images: Array<{ __typename?: 'Image'; url: string }>
      publications: { __typename?: 'PaginatedPublicationResult'; count: number }
      seoClass: { __typename?: 'SeoClass'; levelOne: string }
      reviewsSummary: {
        __typename?: 'ReviewsSummary'
        aggregatedRating: number
        numberOfReviews: number
      }
    }>
    publicationOptionCombinations: Array<{
      __typename?: 'PublicationCombination'
      application: string
      species: string
    }>
    immunogen?: {
      __typename?: 'Immunogen'
      description: string
      sequence?: string | null
      sensitivity: string
      databaseLinks: Array<{
        __typename?: 'DatabaseLink'
        label?: string | null
        value?: string | null
        type: string
      }>
    } | null
    relatedImmunogens: Array<{
      __typename?: 'Immunogen'
      description: string
      sequence?: string | null
      sensitivity: string
      databaseLinks: Array<{
        __typename?: 'DatabaseLink'
        label?: string | null
        value?: string | null
        type: string
      }>
    }>
    images: Array<{
      __typename?: 'Image'
      application: string
      legend?: string | null
      species: Array<string>
      title?: string | null
      url: string
      seoUrl: string
      ecl?: boolean | null
      condition?: string | null
      exposureTime?: string | null
      imageUsage?: {
        __typename?: 'ImageUsage'
        legend?: string | null
        imageBlocks: Array<{
          __typename?: 'ImageBlock'
          type: string
          lanes: Array<{
            __typename?: 'ImageLane'
            bucketName: string
            description: string
          }>
        }>
        bands?: {
          __typename?: 'ImageBand'
          predicted: Array<string>
          observed: Array<string>
        } | null
      } | null
      copyright?: {
        __typename?: 'LegalCopyright'
        credit?: string | null
      } | null
      source?: {
        __typename?: 'ImageSource'
        externalId?: string | null
        name: string
      } | null
    }>
    kitAttributes?: {
      __typename?: 'KitAttributes'
      assayPlatform?: string | null
      assayTime?: string | null
      assayType?: string | null
      detectionMethod?: string | null
      range?: string | null
      generalRecovery?: string | null
      sensitivity?: string | null
      assayPrecision: Array<{
        __typename?: 'AssayPrecision'
        mean?: string | null
        replicates?: number | null
        reproducibilityType?: string | null
        sample?: string | null
        standardDeviation?: string | null
        coefficientOfVariability?: string | null
      }>
      recovery: Array<{
        __typename?: 'Recovery'
        average?: string | null
        range?: string | null
        sample?: string | null
      }>
      sampleTypes: Array<{
        __typename?: 'SampleType'
        simplifiedName: string
        name: string
        slug: string
      }>
      componentDetails: {
        __typename?: 'ComponentDetails'
        all: Array<{
          __typename?: 'Component'
          number: string
          name: string
          product?: {
            __typename?: 'Product'
            productCode: string
            productSlug: string
            seoClass: {
              __typename?: 'SeoClass'
              levelOne: string
              levelTwo?: string | null
            }
          } | null
        }>
        byAsset: Array<{
          __typename?: 'AssetComponents'
          publicAssetCode: string
          sellingSize: string
          assetComponentDetails: Array<{
            __typename?: 'AssetComponentDetail'
            size?: string | null
            component: {
              __typename?: 'Component'
              number: string
              name: string
              product?: {
                __typename?: 'Product'
                productCode: string
                productSlug: string
                seoClass: {
                  __typename?: 'SeoClass'
                  levelOne: string
                  levelTwo?: string | null
                }
              } | null
            }
          }>
        }>
      }
      assayTargets: Array<{
        __typename?: 'AssayTarget'
        sensitivity: string
        range?: string | null
        proteinStandard: string
        interWellCv?: string | null
        target: { __typename?: 'SimpleTarget'; name: string; number: string }
      }>
      miRnaTargets: Array<{
        __typename?: 'MiRnaTarget'
        mimatId: string
        miRnaSequence: string
        rowOrderNumber: string
        homology: Array<string>
        target: { __typename?: 'SimpleTarget'; number: string; name: string }
      }>
    } | null
    marker?: { __typename?: 'Marker'; name?: string | null } | null
    localisedName: {
      __typename?: 'LocalisedField'
      en: string
      ja: string
      zh: string
    }
    notes: Array<{
      __typename?: 'Note'
      statement: string
      noteType?: string | null
    }>
    purificationNotes: Array<{ __typename?: 'Note'; statement: string }>
    productTags: Array<{ __typename?: 'ProductTag'; tagCode: string }>
    targetSummaryNotes: Array<{
      __typename?: 'Note'
      statement: string
      noteType?: string | null
    }>
    proteinAttributes?: {
      __typename?: 'ProteinAttributes'
      active: boolean
      biologicalActivity?: string | null
      endotoxinLevel?: string | null
      expressionSystem?: string | null
      massSpectrometry?: string | null
      aminoAcidSequences: Array<{
        __typename?: 'AminoAcidSequence'
        accessionNumber?: string | null
        actualMolecularWeight?: string | null
        aminoAcidEnd: number
        aminoAcidStart: number
        expressionSystem?: string | null
        nature: string
        predictedMolecularWeight?: string | null
        proteinLength?: string | null
        sequence?: string | null
        tags: Array<{ __typename?: 'TagDetail'; tag: string; terminus: string }>
      }>
    } | null
    publications: {
      __typename?: 'PaginatedPublicationResult'
      count: number
      items: Array<{
        __typename?: 'Publication'
        authors: Array<string>
        journal: string
        name: string
        pages: string
        productCode: string
        publicationDate: string
        pubmedId: string
        volume: string
        references: Array<{
          __typename?: 'ReferenceUsage'
          application: string
          species: string
        }>
      }>
    }
    seoClass: {
      __typename?: 'SeoClass'
      levelOne: string
      levelTwo?: string | null
    }
    target?: {
      __typename?: 'Target'
      alternativeNames: Array<string>
      modificationSummary?: string | null
      mutationSummary?: string | null
      number: string
      name: string
      slug: string
      modifications: Array<{
        __typename?: 'Modification'
        type: ModificationType
        value: string
      }>
      relevance?: { __typename?: 'Relevance'; function: Array<string> } | null
      targetGrouping?: {
        __typename?: 'TargetGrouping'
        name: string
        nameTierTwo?: string | null
      } | null
      isoformAccession?: {
        __typename?: 'DatabaseLink'
        label?: string | null
        value?: string | null
        type: string
      } | null
    } | null
    relatedTargets: Array<{ __typename?: 'Target'; name: string }>
    unpublishedAttributes?: {
      __typename?: 'UnpublishedAttributes'
      lastInactivationDate?: string | null
      productCode: string
      protocols: Array<{
        __typename?: 'Protocol'
        documentType: string
        language?: string | null
        url: string
      }>
      alternativeProducts: Array<{
        __typename?: 'Product'
        status: ProductStatus
        productCode: string
        name: string
        categoryType: string
        productSlug: string
        itemClass: string
        firstActivationDate?: string | null
        images: Array<{ __typename?: 'Image'; url: string }>
        publications: {
          __typename?: 'PaginatedPublicationResult'
          count: number
        }
        seoClass: { __typename?: 'SeoClass'; levelOne: string }
        reviewsSummary: {
          __typename?: 'ReviewsSummary'
          aggregatedRating: number
          numberOfReviews: number
        }
      }>
      directReplacementProduct?: {
        __typename?: 'Product'
        status: ProductStatus
        productCode: string
        name: string
        categoryType: string
        productSlug: string
        itemClass: string
        firstActivationDate?: string | null
        images: Array<{ __typename?: 'Image'; url: string }>
        publications: {
          __typename?: 'PaginatedPublicationResult'
          count: number
        }
        seoClass: { __typename?: 'SeoClass'; levelOne: string }
        reviewsSummary: {
          __typename?: 'ReviewsSummary'
          aggregatedRating: number
          numberOfReviews: number
        }
      } | null
    } | null
    variations: Array<{
      __typename?: 'Variation'
      relationship: string
      product: {
        __typename?: 'Product'
        name: string
        productCode: string
        productSlug: string
        seoClass: { __typename?: 'SeoClass'; levelOne: string }
        conjugation?: {
          __typename?: 'Conjugation'
          emission?: string | null
          excitation?: string | null
          label: string
        } | null
      }
    }>
    handlingHazards: Array<{
      __typename?: 'HandlingHazard'
      label: string
      value: string
      type: string
    }>
    associatedProducts: Array<{
      __typename?: 'ProductAssociation'
      relationship: string
      associatedProduct: {
        __typename?: 'Product'
        itemClass: string
        productCode: string
        name: string
        categoryType: string
        productSlug: string
        firstActivationDate?: string | null
        seoClass: { __typename?: 'SeoClass'; levelOne: string }
        reviewsSummary: {
          __typename?: 'ReviewsSummary'
          aggregatedRating: number
          numberOfReviews: number
        }
      }
    }>
    cellLineAttributes?: {
      __typename?: 'CellLineAttributes'
      cultureMedium?: string | null
      cryopreservationMedium?: string | null
      initialHandlingGuidelines?: string | null
      subcultureGuidelines: Array<string>
      antibioticResistance?: string | null
    } | null
    cellLineTargetAttributes?: {
      __typename?: 'CellLineTargetAttributes'
      chromosome?: string | null
      cosmicId?: string | null
      disease?: string | null
      gene?: string | null
      geneEditedCellLineKnockoutValidations: Array<string>
      geneEditingMethod?: string | null
      geneEditingType?: string | null
      geneLocation?: string | null
      strLocus: Array<string | null>
      cultureProperties: Array<string>
      cellType?: string | null
      species: string
      tissueType?: string | null
      wildTypeExpressionProfile?: string | null
      mutationDescription?: string | null
      biosafetyLevelEurope: string
      biosafetyLevelUnitedStates: string
      sex?: string | null
      viability?: string | null
      zygosity?: string | null
      speciesDetail: {
        __typename?: 'Species'
        name: string
        number: string
        slug: string
      }
    } | null
    conjugation?: {
      __typename?: 'Conjugation'
      label: string
      emission?: string | null
      excitation?: string | null
    } | null
    antibodyAttributes?: {
      __typename?: 'AntibodyAttributes'
      productCode: string
      specificity?: string | null
      epitope?: string | null
      epitopeDomainSpecificity?: string | null
      lightChainType?: string | null
      isotypeControlFlag: boolean
      dissociationConstant?: {
        __typename?: 'DissociationConstant'
        value: string
        exponent: string
      } | null
    } | null
    purity: Array<{
      __typename?: 'ProteinPurity'
      purificationTechniqueReagent?: string | null
      purificationTechnique?: string | null
      purificationFraction?: string | null
      purity?: string | null
      purityAssessment?: string | null
    }>
    reactivity: {
      __typename?: 'Reactivity'
      appGrid?: Array<{
        __typename?: 'ApplicationSpeciesReactivityItem'
        applicationName: string
        reactivity: {
          __typename?: 'GroupedSpecies'
          guaranteed: Array<string>
          notRecommended: Array<string>
          predicted: Array<string>
          testedAndGuaranteed: Array<string>
        }
      }> | null
      applications: Array<{
        __typename?: 'ApplicationReactivity'
        name: string
        fullName: string
        validation?: string | null
        validationTested: boolean
        species: Array<{
          __typename?: 'SpeciesReactivity'
          name: string
          speciesIndependent: boolean
          suitability: Suitability
          recommendedDilution: string
          notes?: string | null
        }>
      }>
      speciesReactivity: Array<{
        __typename?: 'ApplicationIndependentReactivity'
        species: string
        reactivityType: ReactivityType
        similarity: string
      }>
    }
    reviewsSummary: {
      __typename?: 'ReviewsSummary'
      aggregatedRating: number
      numberOfReviews: number
    }
    secondaryAntibodyAttributes?: {
      __typename?: 'SecondaryAntibodyAttributes'
      specificity?: string | null
      targetSpecies: string
      targetIsotypes: Array<string>
      targetSpecificity: string
      preAdsorbed: boolean
      targetSpeciesDetails: {
        __typename?: 'Species'
        slug: string
        scientificName?: string | null
        name: string
        number: string
      }
    } | null
    storage?: {
      __typename?: 'Storage'
      shippedAtConditions: Array<string>
      appropriateShortTermStorageDuration: Array<string>
      appropriateShortTermStorageConditions: Array<string>
      appropriateLongTermStorageConditions: Array<string>
      aliquotingInformation: Array<string>
      storageInformation: Array<string>
    } | null
  }
  dmdOverride?: {
    __typename?: 'DmdOverride'
    assetDefinitionNumber: string
    title?: string | null
    description?: string | null
  } | null
}

export type Browse_Product_ProductOverviewQueryVariables = Exact<{
  id: Scalars['String']
}>

export type Browse_Product_ProductOverviewQuery = {
  __typename?: 'Query'
  product: {
    __typename?: 'Product'
    allowTargetTierTwoOverride: boolean
    categoryType: string
    clonality?: string | null
    cloneNumber?: string | null
    carrierFree?: boolean | null
    form?: string | null
    format?: string | null
    formulation?: string | null
    formulationConstituents?: string | null
    hostSpecies?: string | null
    isoType?: string | null
    name: string
    reconstitution?: string | null
    productCode: string
    productSlug: string
    trialSizeExclusiveCountries: Array<string>
    status: ProductStatus
    biochemicalAttributes?: {
      __typename?: 'BiochemicalAttributes'
      biochemicalName?: string | null
      biologicalDescription?: string | null
      canonicalSmiles: string
      isomericSmiles: string
      iupacName: string
      casNumber: string
      cellPermeable: boolean
      chemicalType: Array<string>
      emission?: string | null
      excitation?: string | null
      expectedPurity: string
      molecularFormula: string
      molecularWeight?: string | null
      nature: string
      pubChemId: string
      solubility: Array<string>
      source: string
      inchi: { __typename?: 'Inchi'; value: string; key: string }
    } | null
    publicationOptionCombinations: Array<{
      __typename?: 'PublicationCombination'
      application: string
      species: string
    }>
    immunogen?: {
      __typename?: 'Immunogen'
      description: string
      sequence?: string | null
      sensitivity: string
      databaseLinks: Array<{
        __typename?: 'DatabaseLink'
        label?: string | null
        value?: string | null
        type: string
      }>
    } | null
    relatedImmunogens: Array<{
      __typename?: 'Immunogen'
      description: string
      sequence?: string | null
      sensitivity: string
      databaseLinks: Array<{
        __typename?: 'DatabaseLink'
        label?: string | null
        value?: string | null
        type: string
      }>
    }>
    images: Array<{
      __typename?: 'Image'
      application: string
      legend?: string | null
      species: Array<string>
      title?: string | null
      url: string
      seoUrl: string
      ecl?: boolean | null
      condition?: string | null
      exposureTime?: string | null
      imageUsage?: {
        __typename?: 'ImageUsage'
        legend?: string | null
        imageBlocks: Array<{
          __typename?: 'ImageBlock'
          type: string
          lanes: Array<{
            __typename?: 'ImageLane'
            bucketName: string
            description: string
          }>
        }>
        bands?: {
          __typename?: 'ImageBand'
          predicted: Array<string>
          observed: Array<string>
        } | null
      } | null
      copyright?: {
        __typename?: 'LegalCopyright'
        credit?: string | null
      } | null
      source?: {
        __typename?: 'ImageSource'
        externalId?: string | null
        name: string
      } | null
    }>
    kitAttributes?: {
      __typename?: 'KitAttributes'
      assayPlatform?: string | null
      assayTime?: string | null
      assayType?: string | null
      detectionMethod?: string | null
      range?: string | null
      generalRecovery?: string | null
      sensitivity?: string | null
      assayPrecision: Array<{
        __typename?: 'AssayPrecision'
        mean?: string | null
        replicates?: number | null
        reproducibilityType?: string | null
        sample?: string | null
        standardDeviation?: string | null
        coefficientOfVariability?: string | null
      }>
      recovery: Array<{
        __typename?: 'Recovery'
        average?: string | null
        range?: string | null
        sample?: string | null
      }>
      sampleTypes: Array<{
        __typename?: 'SampleType'
        simplifiedName: string
        name: string
        slug: string
      }>
      componentDetails: {
        __typename?: 'ComponentDetails'
        all: Array<{
          __typename?: 'Component'
          number: string
          name: string
          product?: {
            __typename?: 'Product'
            productCode: string
            productSlug: string
            seoClass: {
              __typename?: 'SeoClass'
              levelOne: string
              levelTwo?: string | null
            }
          } | null
        }>
        byAsset: Array<{
          __typename?: 'AssetComponents'
          publicAssetCode: string
          sellingSize: string
          assetComponentDetails: Array<{
            __typename?: 'AssetComponentDetail'
            size?: string | null
            component: {
              __typename?: 'Component'
              number: string
              name: string
              product?: {
                __typename?: 'Product'
                productCode: string
                productSlug: string
                seoClass: {
                  __typename?: 'SeoClass'
                  levelOne: string
                  levelTwo?: string | null
                }
              } | null
            }
          }>
        }>
      }
      assayTargets: Array<{
        __typename?: 'AssayTarget'
        sensitivity: string
        range?: string | null
        proteinStandard: string
        interWellCv?: string | null
        target: { __typename?: 'SimpleTarget'; name: string; number: string }
      }>
      miRnaTargets: Array<{
        __typename?: 'MiRnaTarget'
        mimatId: string
        miRnaSequence: string
        rowOrderNumber: string
        homology: Array<string>
        target: { __typename?: 'SimpleTarget'; number: string; name: string }
      }>
    } | null
    notes: Array<{
      __typename?: 'Note'
      statement: string
      noteType?: string | null
    }>
    productTags: Array<{ __typename?: 'ProductTag'; tagCode: string }>
    proteinAttributes?: {
      __typename?: 'ProteinAttributes'
      active: boolean
      biologicalActivity?: string | null
      endotoxinLevel?: string | null
      expressionSystem?: string | null
      massSpectrometry?: string | null
      aminoAcidSequences: Array<{
        __typename?: 'AminoAcidSequence'
        accessionNumber?: string | null
        actualMolecularWeight?: string | null
        aminoAcidEnd: number
        aminoAcidStart: number
        expressionSystem?: string | null
        nature: string
        predictedMolecularWeight?: string | null
        proteinLength?: string | null
        sequence?: string | null
        tags: Array<{ __typename?: 'TagDetail'; tag: string; terminus: string }>
      }>
    } | null
    seoClass: {
      __typename?: 'SeoClass'
      levelOne: string
      levelTwo?: string | null
    }
    target?: {
      __typename?: 'Target'
      alternativeNames: Array<string>
      modificationSummary?: string | null
      mutationSummary?: string | null
      number: string
      name: string
      slug: string
      modifications: Array<{
        __typename?: 'Modification'
        type: ModificationType
        value: string
      }>
      relevance?: { __typename?: 'Relevance'; function: Array<string> } | null
      targetGrouping?: {
        __typename?: 'TargetGrouping'
        name: string
        nameTierTwo?: string | null
      } | null
      isoformAccession?: {
        __typename?: 'DatabaseLink'
        label?: string | null
        value?: string | null
        type: string
      } | null
    } | null
    handlingHazards: Array<{
      __typename?: 'HandlingHazard'
      label: string
      value: string
      type: string
    }>
    cellLineAttributes?: {
      __typename?: 'CellLineAttributes'
      cultureMedium?: string | null
      cryopreservationMedium?: string | null
      initialHandlingGuidelines?: string | null
      subcultureGuidelines: Array<string>
      antibioticResistance?: string | null
    } | null
    cellLineTargetAttributes?: {
      __typename?: 'CellLineTargetAttributes'
      chromosome?: string | null
      cosmicId?: string | null
      disease?: string | null
      gene?: string | null
      geneEditedCellLineKnockoutValidations: Array<string>
      geneEditingMethod?: string | null
      geneEditingType?: string | null
      geneLocation?: string | null
      strLocus: Array<string | null>
      cultureProperties: Array<string>
      cellType?: string | null
      species: string
      tissueType?: string | null
      wildTypeExpressionProfile?: string | null
      mutationDescription?: string | null
      biosafetyLevelEurope: string
      biosafetyLevelUnitedStates: string
      sex?: string | null
      viability?: string | null
      zygosity?: string | null
      speciesDetail: {
        __typename?: 'Species'
        name: string
        number: string
        slug: string
      }
    } | null
    conjugation?: {
      __typename?: 'Conjugation'
      label: string
      emission?: string | null
      excitation?: string | null
    } | null
    purity: Array<{
      __typename?: 'ProteinPurity'
      purificationTechniqueReagent?: string | null
      purificationTechnique?: string | null
      purificationFraction?: string | null
      purity?: string | null
      purityAssessment?: string | null
    }>
    reactivity: {
      __typename?: 'Reactivity'
      appGrid?: Array<{
        __typename?: 'ApplicationSpeciesReactivityItem'
        applicationName: string
        reactivity: {
          __typename?: 'GroupedSpecies'
          guaranteed: Array<string>
          notRecommended: Array<string>
          predicted: Array<string>
          testedAndGuaranteed: Array<string>
        }
      }> | null
      applications: Array<{
        __typename?: 'ApplicationReactivity'
        name: string
        fullName: string
        validation?: string | null
        validationTested: boolean
        species: Array<{
          __typename?: 'SpeciesReactivity'
          name: string
          speciesIndependent: boolean
          suitability: Suitability
          recommendedDilution: string
          notes?: string | null
        }>
      }>
      speciesReactivity: Array<{
        __typename?: 'ApplicationIndependentReactivity'
        species: string
        reactivityType: ReactivityType
        similarity: string
      }>
    }
    reviewsSummary: {
      __typename?: 'ReviewsSummary'
      aggregatedRating: number
      numberOfReviews: number
    }
    secondaryAntibodyAttributes?: {
      __typename?: 'SecondaryAntibodyAttributes'
      specificity?: string | null
      targetSpecies: string
      targetIsotypes: Array<string>
      targetSpecificity: string
      preAdsorbed: boolean
      targetSpeciesDetails: {
        __typename?: 'Species'
        slug: string
        scientificName?: string | null
        name: string
        number: string
      }
    } | null
    storage?: {
      __typename?: 'Storage'
      shippedAtConditions: Array<string>
      appropriateShortTermStorageDuration: Array<string>
      appropriateShortTermStorageConditions: Array<string>
      appropriateLongTermStorageConditions: Array<string>
      aliquotingInformation: Array<string>
      storageInformation: Array<string>
    } | null
  }
}

export type Browse_Products_ProductIdentifiersQueryVariables = Exact<{
  filter: ProductsFilter
}>

export type Browse_Products_ProductIdentifiersQuery = {
  __typename?: 'Query'
  products: Array<{
    __typename?: 'Product'
    name: string
    productSlug: string
    productCode: string
    seoClass: { __typename?: 'SeoClass'; levelOne: string }
  }>
}

export type Browse_Document_SdsPdfQueryVariables = Exact<{
  query: DocumentsFilter
}>

export type Browse_Document_SdsPdfQuery = {
  __typename?: 'Query'
  document?: {
    __typename?: 'Document'
    sds?: Array<{
      __typename?: 'SdsDocument'
      countryCode?: string | null
      languageCode?: string | null
      name: string
      displayName: string
      url: string
    }> | null
  } | null
}

export type Browse_Document_SdsCountriesQueryVariables = Exact<{
  query: DocumentsFilter
}>

export type Browse_Document_SdsCountriesQuery = {
  __typename?: 'Query'
  document?: {
    __typename?: 'Document'
    countries?: Array<string> | null
  } | null
}

export type Browse_Product_CrossSellQueryVariables = Exact<{
  productCode: Scalars['String']
}>

export type Browse_Product_CrossSellQuery = {
  __typename?: 'Query'
  product: {
    __typename?: 'Product'
    crossSellRecommendations: Array<{
      __typename?: 'CrossSellRecommendation'
      rank: number
      product: {
        __typename?: 'Product'
        itemClass: string
        productCode: string
        name: string
        categoryType: string
        productSlug: string
        firstActivationDate?: string | null
        seoClass: { __typename?: 'SeoClass'; levelOne: string }
        reviewsSummary: {
          __typename?: 'ReviewsSummary'
          aggregatedRating: number
          numberOfReviews: number
        }
      }
    }>
  }
}

export type Browse_Target_EntityDetailsQueryVariables = Exact<{
  slug: Scalars['String']
  id?: InputMaybe<Scalars['String']>
}>

export type Browse_Target_EntityDetailsQuery = {
  __typename?: 'Query'
  target: {
    __typename?: 'Target'
    name: string
    number: string
    slug: string
    type?: string | null
    researchAreas: Array<string>
    molecularWeight?: string | null
    alternativeNames: Array<string>
    modificationSummary?: string | null
    relevance?: {
      __typename?: 'Relevance'
      domain: Array<string>
      developmentalStage: Array<string>
      cellularLocalization: Array<string>
      function: Array<string>
      involvementInDisease: Array<string>
      pathway: Array<string>
      postTranslationalModifications: Array<string>
      sequenceSimilarities: Array<string>
      tissueSpecificity: Array<string>
    } | null
    databaseLinks: Array<{
      __typename?: 'DatabaseLink'
      label?: string | null
      value?: string | null
      type: string
    } | null>
    relatedTargets: Array<{
      __typename?: 'Target'
      name: string
      number: string
      slug: string
      relevance?: { __typename?: 'Relevance'; domain: Array<string> } | null
    }>
  }
}

export type Browse_Products_RecentlyViewedQueryVariables = Exact<{
  filter: ProductsFilter
}>

export type Browse_Products_RecentlyViewedQuery = {
  __typename?: 'Query'
  products: Array<{
    __typename?: 'Product'
    productCode: string
    name: string
    categoryType: string
    firstActivationDate?: string | null
    productSlug: string
    status: ProductStatus
    seoClass: { __typename?: 'SeoClass'; levelOne: string }
    reviewsSummary: {
      __typename?: 'ReviewsSummary'
      aggregatedRating: number
      numberOfReviews: number
    }
    publications: { __typename?: 'PaginatedPublicationResult'; count: number }
  }>
}

export type ProteinAttributesFragment = {
  __typename?: 'ProteinAttributes'
  active: boolean
  biologicalActivity?: string | null
  endotoxinLevel?: string | null
  expressionSystem?: string | null
  massSpectrometry?: string | null
  aminoAcidSequences: Array<{
    __typename?: 'AminoAcidSequence'
    accessionNumber?: string | null
    actualMolecularWeight?: string | null
    aminoAcidEnd: number
    aminoAcidStart: number
    expressionSystem?: string | null
    nature: string
    predictedMolecularWeight?: string | null
    proteinLength?: string | null
    sequence?: string | null
    tags: Array<{ __typename?: 'TagDetail'; tag: string; terminus: string }>
  }>
}

export type ProtocolFragment = {
  __typename?: 'Protocol'
  documentType: string
  language?: string | null
  url: string
}

export type PurityFragment = {
  __typename?: 'Product'
  purity: Array<{
    __typename?: 'ProteinPurity'
    purificationTechniqueReagent?: string | null
    purificationTechnique?: string | null
    purificationFraction?: string | null
    purity?: string | null
    purityAssessment?: string | null
  }>
}

export type ReactivityFragment = {
  __typename?: 'Product'
  reactivity: {
    __typename?: 'Reactivity'
    appGrid?: Array<{
      __typename?: 'ApplicationSpeciesReactivityItem'
      applicationName: string
      reactivity: {
        __typename?: 'GroupedSpecies'
        guaranteed: Array<string>
        notRecommended: Array<string>
        predicted: Array<string>
        testedAndGuaranteed: Array<string>
      }
    }> | null
    applications: Array<{
      __typename?: 'ApplicationReactivity'
      name: string
      fullName: string
      validation?: string | null
      validationTested: boolean
      species: Array<{
        __typename?: 'SpeciesReactivity'
        name: string
        speciesIndependent: boolean
        suitability: Suitability
        recommendedDilution: string
        notes?: string | null
      }>
    }>
    speciesReactivity: Array<{
      __typename?: 'ApplicationIndependentReactivity'
      species: string
      reactivityType: ReactivityType
      similarity: string
    }>
  }
}

export type RecommendedProductFragment = {
  __typename?: 'Product'
  status: ProductStatus
  productCode: string
  name: string
  categoryType: string
  productSlug: string
  itemClass: string
  firstActivationDate?: string | null
  images: Array<{ __typename?: 'Image'; url: string }>
  publications: { __typename?: 'PaginatedPublicationResult'; count: number }
  seoClass: { __typename?: 'SeoClass'; levelOne: string }
  reviewsSummary: {
    __typename?: 'ReviewsSummary'
    aggregatedRating: number
    numberOfReviews: number
  }
}

export type ReviewsSummaryFragment = {
  __typename?: 'Product'
  reviewsSummary: {
    __typename?: 'ReviewsSummary'
    aggregatedRating: number
    numberOfReviews: number
  }
}

export type SecondaryAntibodyFragment = {
  __typename?: 'Product'
  secondaryAntibodyAttributes?: {
    __typename?: 'SecondaryAntibodyAttributes'
    specificity?: string | null
    targetSpecies: string
    targetIsotypes: Array<string>
    targetSpecificity: string
    preAdsorbed: boolean
    targetSpeciesDetails: {
      __typename?: 'Species'
      slug: string
      scientificName?: string | null
      name: string
      number: string
    }
  } | null
}

export type SeoClassFragment = {
  __typename?: 'SeoClass'
  levelOne: string
  levelTwo?: string | null
}

export type StorageFragment = {
  __typename?: 'Product'
  storage?: {
    __typename?: 'Storage'
    shippedAtConditions: Array<string>
    appropriateShortTermStorageDuration: Array<string>
    appropriateShortTermStorageConditions: Array<string>
    appropriateLongTermStorageConditions: Array<string>
    aliquotingInformation: Array<string>
    storageInformation: Array<string>
  } | null
}

export type TargetFragment = {
  __typename?: 'Target'
  alternativeNames: Array<string>
  modificationSummary?: string | null
  mutationSummary?: string | null
  number: string
  name: string
  slug: string
  modifications: Array<{
    __typename?: 'Modification'
    type: ModificationType
    value: string
  }>
  relevance?: { __typename?: 'Relevance'; function: Array<string> } | null
  targetGrouping?: {
    __typename?: 'TargetGrouping'
    name: string
    nameTierTwo?: string | null
  } | null
  isoformAccession?: {
    __typename?: 'DatabaseLink'
    label?: string | null
    value?: string | null
    type: string
  } | null
}

export type UnpublishedAttributesFragment = {
  __typename?: 'UnpublishedAttributes'
  lastInactivationDate?: string | null
  productCode: string
  protocols: Array<{
    __typename?: 'Protocol'
    documentType: string
    language?: string | null
    url: string
  }>
  alternativeProducts: Array<{
    __typename?: 'Product'
    status: ProductStatus
    productCode: string
    name: string
    categoryType: string
    productSlug: string
    itemClass: string
    firstActivationDate?: string | null
    images: Array<{ __typename?: 'Image'; url: string }>
    publications: { __typename?: 'PaginatedPublicationResult'; count: number }
    seoClass: { __typename?: 'SeoClass'; levelOne: string }
    reviewsSummary: {
      __typename?: 'ReviewsSummary'
      aggregatedRating: number
      numberOfReviews: number
    }
  }>
  directReplacementProduct?: {
    __typename?: 'Product'
    status: ProductStatus
    productCode: string
    name: string
    categoryType: string
    productSlug: string
    itemClass: string
    firstActivationDate?: string | null
    images: Array<{ __typename?: 'Image'; url: string }>
    publications: { __typename?: 'PaginatedPublicationResult'; count: number }
    seoClass: { __typename?: 'SeoClass'; levelOne: string }
    reviewsSummary: {
      __typename?: 'ReviewsSummary'
      aggregatedRating: number
      numberOfReviews: number
    }
  } | null
}

export const AntibodyAttributesFragmentDoc = gql`
  fragment AntibodyAttributes on Product {
    antibodyAttributes {
      productCode
      specificity
      epitope
      epitopeDomainSpecificity
      dissociationConstant {
        value
        exponent
      }
      lightChainType
      isotypeControlFlag
    }
  }
`
export const ReviewsSummaryFragmentDoc = gql`
  fragment ReviewsSummary on Product {
    reviewsSummary {
      aggregatedRating
      numberOfReviews
    }
  }
`
export const AssociatedProductsFragmentDoc = gql`
  fragment AssociatedProducts on Product {
    associatedProducts {
      relationship
      associatedProduct {
        itemClass
        productCode
        name
        categoryType
        seoClass {
          levelOne
        }
        productSlug
        firstActivationDate
        ...ReviewsSummary
      }
    }
  }
  ${ReviewsSummaryFragmentDoc}
`
export const BiochemicalFragmentDoc = gql`
  fragment Biochemical on BiochemicalAttributes {
    biochemicalName
    biologicalDescription
    canonicalSmiles
    isomericSmiles
    inchi {
      value
      key
    }
    iupacName
    casNumber
    cellPermeable
    chemicalType
    emission
    excitation
    expectedPurity
    molecularFormula
    molecularWeight
    nature
    pubChemId
    solubility
    source
  }
`
export const CellLineFragmentDoc = gql`
  fragment CellLine on Product {
    cellLineAttributes {
      cultureMedium
      cryopreservationMedium
      initialHandlingGuidelines
      subcultureGuidelines
      antibioticResistance
    }
  }
`
export const CellLineTargetFragmentDoc = gql`
  fragment CellLineTarget on Product {
    cellLineTargetAttributes {
      chromosome
      cosmicId
      disease
      gene
      geneEditedCellLineKnockoutValidations
      geneEditingMethod
      geneEditingType
      geneLocation
      strLocus
      cultureProperties
      cellType
      species
      tissueType
      wildTypeExpressionProfile
      mutationDescription
      biosafetyLevelEurope
      biosafetyLevelUnitedStates
      sex
      speciesDetail {
        name
        number
        slug
      }
      viability
      zygosity
    }
  }
`
export const ConjugationFragmentDoc = gql`
  fragment Conjugation on Product {
    conjugation {
      label
      emission
      excitation
    }
  }
`
export const HandlingHazardFragmentDoc = gql`
  fragment HandlingHazard on HandlingHazard {
    label
    value
    type
  }
`
export const ImageBlockFragmentDoc = gql`
  fragment ImageBlock on ImageBlock {
    type
    lanes {
      bucketName
      description
    }
  }
`
export const ImageUsageFragmentDoc = gql`
  fragment ImageUsage on ImageUsage {
    imageBlocks {
      ...ImageBlock
    }
    legend
    bands {
      predicted
      observed
    }
  }
  ${ImageBlockFragmentDoc}
`
export const ImageFragmentDoc = gql`
  fragment Image on Image {
    application
    legend
    species
    title
    url
    seoUrl
    imageUsage {
      ...ImageUsage
    }
    ecl
    condition
    exposureTime
    copyright {
      credit
    }
    source {
      externalId
      name
    }
  }
  ${ImageUsageFragmentDoc}
`
export const ImmunogenFragmentDoc = gql`
  fragment Immunogen on Immunogen {
    databaseLinks {
      label
      value
      type
    }
    description
    sequence
    sensitivity
  }
`
export const ComponentAttributesFragmentDoc = gql`
  fragment ComponentAttributes on Component {
    number
    name
    product {
      productCode
      productSlug
      seoClass {
        levelOne
        levelTwo
      }
    }
  }
`
export const KitAttributesFragmentDoc = gql`
  fragment KitAttributes on KitAttributes {
    assayPlatform
    assayPrecision {
      mean
      replicates
      reproducibilityType
      sample
      standardDeviation
      coefficientOfVariability
    }
    assayTime
    assayType
    detectionMethod
    range
    recovery {
      average
      range
      sample
    }
    generalRecovery
    sampleTypes {
      simplifiedName
      name
      slug
    }
    sensitivity
    componentDetails {
      all {
        ...ComponentAttributes
      }
      byAsset {
        publicAssetCode
        sellingSize
        assetComponentDetails {
          component {
            ...ComponentAttributes
          }
          size
        }
      }
    }
    assayTargets {
      target {
        name
        number
      }
      sensitivity
      range
      proteinStandard
      interWellCv
    }
    miRnaTargets {
      target {
        number
        name
      }
      mimatId
      miRnaSequence
      rowOrderNumber
      homology
    }
  }
  ${ComponentAttributesFragmentDoc}
`
export const AminoAcidSequenceFragmentDoc = gql`
  fragment AminoAcidSequence on AminoAcidSequence {
    accessionNumber
    actualMolecularWeight
    aminoAcidEnd
    aminoAcidStart
    expressionSystem
    nature
    predictedMolecularWeight
    proteinLength
    sequence
    tags {
      tag
      terminus
    }
  }
`
export const ProteinAttributesFragmentDoc = gql`
  fragment ProteinAttributes on ProteinAttributes {
    active
    aminoAcidSequences {
      ...AminoAcidSequence
    }
    biologicalActivity
    endotoxinLevel
    expressionSystem
    massSpectrometry
  }
  ${AminoAcidSequenceFragmentDoc}
`
export const ProtocolFragmentDoc = gql`
  fragment Protocol on Protocol {
    documentType
    language
    url
  }
`
export const PurityFragmentDoc = gql`
  fragment Purity on Product {
    purity {
      purificationTechniqueReagent
      purificationTechnique
      purificationFraction
      purity
      purityAssessment
    }
  }
`
export const ReactivityFragmentDoc = gql`
  fragment Reactivity on Product {
    reactivity {
      appGrid {
        applicationName
        reactivity {
          guaranteed
          notRecommended
          predicted
          testedAndGuaranteed
        }
      }
      applications {
        name
        fullName
        species {
          name
          speciesIndependent
          suitability
          recommendedDilution
          notes
        }
        validation
        validationTested
      }
      speciesReactivity {
        species
        reactivityType
        similarity
      }
    }
  }
`
export const SecondaryAntibodyFragmentDoc = gql`
  fragment SecondaryAntibody on Product {
    secondaryAntibodyAttributes {
      specificity
      targetSpecies
      targetSpeciesDetails {
        slug
        scientificName
        name
        number
      }
      targetIsotypes
      targetSpecificity
      preAdsorbed
    }
  }
`
export const SeoClassFragmentDoc = gql`
  fragment SeoClass on SeoClass {
    levelOne
    levelTwo
  }
`
export const StorageFragmentDoc = gql`
  fragment Storage on Product {
    storage {
      shippedAtConditions
      appropriateShortTermStorageDuration
      appropriateShortTermStorageConditions
      appropriateLongTermStorageConditions
      aliquotingInformation
      storageInformation
    }
  }
`
export const TargetFragmentDoc = gql`
  fragment Target on Target {
    alternativeNames
    modifications {
      type
      value
    }
    modificationSummary
    mutationSummary
    number
    name
    relevance {
      function
    }
    slug
    targetGrouping {
      name
      nameTierTwo
    }
    isoformAccession {
      label
      value
      type
    }
  }
`
export const RecommendedProductFragmentDoc = gql`
  fragment RecommendedProduct on Product {
    status
    productCode
    name
    categoryType
    productSlug
    images {
      url
    }
    publications(filter: { limit: 1 }) {
      count
    }
    seoClass {
      levelOne
    }
    itemClass
    firstActivationDate
    ...ReviewsSummary
  }
  ${ReviewsSummaryFragmentDoc}
`
export const UnpublishedAttributesFragmentDoc = gql`
  fragment UnpublishedAttributes on UnpublishedAttributes {
    lastInactivationDate
    productCode
    protocols {
      documentType
      language
      url
    }
    alternativeProducts {
      ...RecommendedProduct
    }
    directReplacementProduct {
      ...RecommendedProduct
    }
  }
  ${RecommendedProductFragmentDoc}
`
export const Seo_DmdOverrideDocument = gql`
  query SEO_DmdOverride($abId: String, $searchTerm: String)
  @endpoint(name: "product") {
    dmdOverrides(abId: $abId, searchTerm: $searchTerm) {
      id
      assetDefinitionNumber
      domain
      locales
      batchName
      title
      description
      modifiedBy
      lastModified
    }
  }
`

/**
 * __useSeo_DmdOverrideQuery__
 *
 * To run a query within a React component, call `useSeo_DmdOverrideQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeo_DmdOverrideQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeo_DmdOverrideQuery({
 *   variables: {
 *      abId: // value for 'abId'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useSeo_DmdOverrideQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Seo_DmdOverrideQuery,
    Seo_DmdOverrideQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Seo_DmdOverrideQuery, Seo_DmdOverrideQueryVariables>(
    Seo_DmdOverrideDocument,
    options
  )
}
export function useSeo_DmdOverrideLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Seo_DmdOverrideQuery,
    Seo_DmdOverrideQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Seo_DmdOverrideQuery,
    Seo_DmdOverrideQueryVariables
  >(Seo_DmdOverrideDocument, options)
}
export type Seo_DmdOverrideQueryHookResult = ReturnType<
  typeof useSeo_DmdOverrideQuery
>
export type Seo_DmdOverrideLazyQueryHookResult = ReturnType<
  typeof useSeo_DmdOverrideLazyQuery
>
export type Seo_DmdOverrideQueryResult = Apollo.QueryResult<
  Seo_DmdOverrideQuery,
  Seo_DmdOverrideQueryVariables
>
export const Seo_AddDmdOverrideDocument = gql`
  mutation SEO_AddDmdOverride($input: AddDmdOverrideInput!)
  @endpoint(name: "product") {
    addDmdOverride(input: $input)
  }
`
export type Seo_AddDmdOverrideMutationFn = Apollo.MutationFunction<
  Seo_AddDmdOverrideMutation,
  Seo_AddDmdOverrideMutationVariables
>

/**
 * __useSeo_AddDmdOverrideMutation__
 *
 * To run a mutation, you first call `useSeo_AddDmdOverrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSeo_AddDmdOverrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [seoAddDmdOverrideMutation, { data, loading, error }] = useSeo_AddDmdOverrideMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSeo_AddDmdOverrideMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Seo_AddDmdOverrideMutation,
    Seo_AddDmdOverrideMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Seo_AddDmdOverrideMutation,
    Seo_AddDmdOverrideMutationVariables
  >(Seo_AddDmdOverrideDocument, options)
}
export type Seo_AddDmdOverrideMutationHookResult = ReturnType<
  typeof useSeo_AddDmdOverrideMutation
>
export type Seo_AddDmdOverrideMutationResult =
  Apollo.MutationResult<Seo_AddDmdOverrideMutation>
export type Seo_AddDmdOverrideMutationOptions = Apollo.BaseMutationOptions<
  Seo_AddDmdOverrideMutation,
  Seo_AddDmdOverrideMutationVariables
>
export const Seo_AddDmdOverridesDocument = gql`
  mutation SEO_AddDmdOverrides($input: [AddDmdOverrideInput]!)
  @endpoint(name: "product") {
    addDmdOverrides(input: $input)
  }
`
export type Seo_AddDmdOverridesMutationFn = Apollo.MutationFunction<
  Seo_AddDmdOverridesMutation,
  Seo_AddDmdOverridesMutationVariables
>

/**
 * __useSeo_AddDmdOverridesMutation__
 *
 * To run a mutation, you first call `useSeo_AddDmdOverridesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSeo_AddDmdOverridesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [seoAddDmdOverridesMutation, { data, loading, error }] = useSeo_AddDmdOverridesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSeo_AddDmdOverridesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Seo_AddDmdOverridesMutation,
    Seo_AddDmdOverridesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Seo_AddDmdOverridesMutation,
    Seo_AddDmdOverridesMutationVariables
  >(Seo_AddDmdOverridesDocument, options)
}
export type Seo_AddDmdOverridesMutationHookResult = ReturnType<
  typeof useSeo_AddDmdOverridesMutation
>
export type Seo_AddDmdOverridesMutationResult =
  Apollo.MutationResult<Seo_AddDmdOverridesMutation>
export type Seo_AddDmdOverridesMutationOptions = Apollo.BaseMutationOptions<
  Seo_AddDmdOverridesMutation,
  Seo_AddDmdOverridesMutationVariables
>
export const Seo_EditDmdOverrideDocument = gql`
  mutation SEO_EditDmdOverride($input: EditDmdOverrideInput!)
  @endpoint(name: "product") {
    editDmdOverride(input: $input)
  }
`
export type Seo_EditDmdOverrideMutationFn = Apollo.MutationFunction<
  Seo_EditDmdOverrideMutation,
  Seo_EditDmdOverrideMutationVariables
>

/**
 * __useSeo_EditDmdOverrideMutation__
 *
 * To run a mutation, you first call `useSeo_EditDmdOverrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSeo_EditDmdOverrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [seoEditDmdOverrideMutation, { data, loading, error }] = useSeo_EditDmdOverrideMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSeo_EditDmdOverrideMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Seo_EditDmdOverrideMutation,
    Seo_EditDmdOverrideMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Seo_EditDmdOverrideMutation,
    Seo_EditDmdOverrideMutationVariables
  >(Seo_EditDmdOverrideDocument, options)
}
export type Seo_EditDmdOverrideMutationHookResult = ReturnType<
  typeof useSeo_EditDmdOverrideMutation
>
export type Seo_EditDmdOverrideMutationResult =
  Apollo.MutationResult<Seo_EditDmdOverrideMutation>
export type Seo_EditDmdOverrideMutationOptions = Apollo.BaseMutationOptions<
  Seo_EditDmdOverrideMutation,
  Seo_EditDmdOverrideMutationVariables
>
export const Seo_DeleteDmdOverrideDocument = gql`
  mutation SEO_DeleteDmdOverride($id: String!) @endpoint(name: "product") {
    deleteDmdOverride(id: $id)
  }
`
export type Seo_DeleteDmdOverrideMutationFn = Apollo.MutationFunction<
  Seo_DeleteDmdOverrideMutation,
  Seo_DeleteDmdOverrideMutationVariables
>

/**
 * __useSeo_DeleteDmdOverrideMutation__
 *
 * To run a mutation, you first call `useSeo_DeleteDmdOverrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSeo_DeleteDmdOverrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [seoDeleteDmdOverrideMutation, { data, loading, error }] = useSeo_DeleteDmdOverrideMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSeo_DeleteDmdOverrideMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Seo_DeleteDmdOverrideMutation,
    Seo_DeleteDmdOverrideMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Seo_DeleteDmdOverrideMutation,
    Seo_DeleteDmdOverrideMutationVariables
  >(Seo_DeleteDmdOverrideDocument, options)
}
export type Seo_DeleteDmdOverrideMutationHookResult = ReturnType<
  typeof useSeo_DeleteDmdOverrideMutation
>
export type Seo_DeleteDmdOverrideMutationResult =
  Apollo.MutationResult<Seo_DeleteDmdOverrideMutation>
export type Seo_DeleteDmdOverrideMutationOptions = Apollo.BaseMutationOptions<
  Seo_DeleteDmdOverrideMutation,
  Seo_DeleteDmdOverrideMutationVariables
>
export const Seo_DeleteDmdOverridesDocument = gql`
  mutation SEO_DeleteDmdOverrides($ids: [String]!) @endpoint(name: "product") {
    deleteDmdOverrides(ids: $ids)
  }
`
export type Seo_DeleteDmdOverridesMutationFn = Apollo.MutationFunction<
  Seo_DeleteDmdOverridesMutation,
  Seo_DeleteDmdOverridesMutationVariables
>

/**
 * __useSeo_DeleteDmdOverridesMutation__
 *
 * To run a mutation, you first call `useSeo_DeleteDmdOverridesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSeo_DeleteDmdOverridesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [seoDeleteDmdOverridesMutation, { data, loading, error }] = useSeo_DeleteDmdOverridesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useSeo_DeleteDmdOverridesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Seo_DeleteDmdOverridesMutation,
    Seo_DeleteDmdOverridesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Seo_DeleteDmdOverridesMutation,
    Seo_DeleteDmdOverridesMutationVariables
  >(Seo_DeleteDmdOverridesDocument, options)
}
export type Seo_DeleteDmdOverridesMutationHookResult = ReturnType<
  typeof useSeo_DeleteDmdOverridesMutation
>
export type Seo_DeleteDmdOverridesMutationResult =
  Apollo.MutationResult<Seo_DeleteDmdOverridesMutation>
export type Seo_DeleteDmdOverridesMutationOptions = Apollo.BaseMutationOptions<
  Seo_DeleteDmdOverridesMutation,
  Seo_DeleteDmdOverridesMutationVariables
>
export const Seo_DmdOverrideHistoryDocument = gql`
  query SEO_DmdOverrideHistory(
    $dmdOverrideId: String
    $deleted: Boolean
    $abId: String
    $searchTerm: String
  ) @endpoint(name: "product") {
    dmdOverrideHistory(
      dmdOverrideId: $dmdOverrideId
      deleted: $deleted
      abId: $abId
      searchTerm: $searchTerm
    ) {
      id
      assetDefinitionNumber
      domain
      locales
      batchName
      title
      description
      modifiedBy
      lastModified
    }
  }
`

/**
 * __useSeo_DmdOverrideHistoryQuery__
 *
 * To run a query within a React component, call `useSeo_DmdOverrideHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeo_DmdOverrideHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeo_DmdOverrideHistoryQuery({
 *   variables: {
 *      dmdOverrideId: // value for 'dmdOverrideId'
 *      deleted: // value for 'deleted'
 *      abId: // value for 'abId'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useSeo_DmdOverrideHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Seo_DmdOverrideHistoryQuery,
    Seo_DmdOverrideHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Seo_DmdOverrideHistoryQuery,
    Seo_DmdOverrideHistoryQueryVariables
  >(Seo_DmdOverrideHistoryDocument, options)
}
export function useSeo_DmdOverrideHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Seo_DmdOverrideHistoryQuery,
    Seo_DmdOverrideHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Seo_DmdOverrideHistoryQuery,
    Seo_DmdOverrideHistoryQueryVariables
  >(Seo_DmdOverrideHistoryDocument, options)
}
export type Seo_DmdOverrideHistoryQueryHookResult = ReturnType<
  typeof useSeo_DmdOverrideHistoryQuery
>
export type Seo_DmdOverrideHistoryLazyQueryHookResult = ReturnType<
  typeof useSeo_DmdOverrideHistoryLazyQuery
>
export type Seo_DmdOverrideHistoryQueryResult = Apollo.QueryResult<
  Seo_DmdOverrideHistoryQuery,
  Seo_DmdOverrideHistoryQueryVariables
>
export const Seo_DmdValidationDocument = gql`
  query SEO_DmdValidation($input: [DmdOverrideInput]!)
  @endpoint(name: "product") {
    overlappingDmdOverrides(input: $input)
    overridesWithDuplicateTitleDescription(input: $input)
  }
`

/**
 * __useSeo_DmdValidationQuery__
 *
 * To run a query within a React component, call `useSeo_DmdValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeo_DmdValidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeo_DmdValidationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSeo_DmdValidationQuery(
  baseOptions: Apollo.QueryHookOptions<
    Seo_DmdValidationQuery,
    Seo_DmdValidationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Seo_DmdValidationQuery,
    Seo_DmdValidationQueryVariables
  >(Seo_DmdValidationDocument, options)
}
export function useSeo_DmdValidationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Seo_DmdValidationQuery,
    Seo_DmdValidationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Seo_DmdValidationQuery,
    Seo_DmdValidationQueryVariables
  >(Seo_DmdValidationDocument, options)
}
export type Seo_DmdValidationQueryHookResult = ReturnType<
  typeof useSeo_DmdValidationQuery
>
export type Seo_DmdValidationLazyQueryHookResult = ReturnType<
  typeof useSeo_DmdValidationLazyQuery
>
export type Seo_DmdValidationQueryResult = Apollo.QueryResult<
  Seo_DmdValidationQuery,
  Seo_DmdValidationQueryVariables
>
export const Browse_ProductBatch_BatchConcentrationsDocument = gql`
  query BROWSE_ProductBatch_batchConcentrations(
    $productCode: String!
    $lotNumber: String!
  ) {
    productBatch(productCode: $productCode, lotNumber: $lotNumber)
      @endpoint(name: "product") {
      batchConcentration
      lotNumber
    }
  }
`

/**
 * __useBrowse_ProductBatch_BatchConcentrationsQuery__
 *
 * To run a query within a React component, call `useBrowse_ProductBatch_BatchConcentrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrowse_ProductBatch_BatchConcentrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrowse_ProductBatch_BatchConcentrationsQuery({
 *   variables: {
 *      productCode: // value for 'productCode'
 *      lotNumber: // value for 'lotNumber'
 *   },
 * });
 */
export function useBrowse_ProductBatch_BatchConcentrationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Browse_ProductBatch_BatchConcentrationsQuery,
    Browse_ProductBatch_BatchConcentrationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Browse_ProductBatch_BatchConcentrationsQuery,
    Browse_ProductBatch_BatchConcentrationsQueryVariables
  >(Browse_ProductBatch_BatchConcentrationsDocument, options)
}
export function useBrowse_ProductBatch_BatchConcentrationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Browse_ProductBatch_BatchConcentrationsQuery,
    Browse_ProductBatch_BatchConcentrationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Browse_ProductBatch_BatchConcentrationsQuery,
    Browse_ProductBatch_BatchConcentrationsQueryVariables
  >(Browse_ProductBatch_BatchConcentrationsDocument, options)
}
export type Browse_ProductBatch_BatchConcentrationsQueryHookResult = ReturnType<
  typeof useBrowse_ProductBatch_BatchConcentrationsQuery
>
export type Browse_ProductBatch_BatchConcentrationsLazyQueryHookResult =
  ReturnType<typeof useBrowse_ProductBatch_BatchConcentrationsLazyQuery>
export type Browse_ProductBatch_BatchConcentrationsQueryResult =
  Apollo.QueryResult<
    Browse_ProductBatch_BatchConcentrationsQuery,
    Browse_ProductBatch_BatchConcentrationsQueryVariables
  >
export const Browse_Document_CertificatePdfDocument = gql`
  query BROWSE_Document_certificatePdf($query: DocumentsFilter!) {
    document(filter: $query) @endpoint(name: "product") {
      lotNumber
      productCode
      certificates {
        type
        url
        name
      }
    }
  }
`

/**
 * __useBrowse_Document_CertificatePdfQuery__
 *
 * To run a query within a React component, call `useBrowse_Document_CertificatePdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrowse_Document_CertificatePdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrowse_Document_CertificatePdfQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useBrowse_Document_CertificatePdfQuery(
  baseOptions: Apollo.QueryHookOptions<
    Browse_Document_CertificatePdfQuery,
    Browse_Document_CertificatePdfQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Browse_Document_CertificatePdfQuery,
    Browse_Document_CertificatePdfQueryVariables
  >(Browse_Document_CertificatePdfDocument, options)
}
export function useBrowse_Document_CertificatePdfLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Browse_Document_CertificatePdfQuery,
    Browse_Document_CertificatePdfQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Browse_Document_CertificatePdfQuery,
    Browse_Document_CertificatePdfQueryVariables
  >(Browse_Document_CertificatePdfDocument, options)
}
export type Browse_Document_CertificatePdfQueryHookResult = ReturnType<
  typeof useBrowse_Document_CertificatePdfQuery
>
export type Browse_Document_CertificatePdfLazyQueryHookResult = ReturnType<
  typeof useBrowse_Document_CertificatePdfLazyQuery
>
export type Browse_Document_CertificatePdfQueryResult = Apollo.QueryResult<
  Browse_Document_CertificatePdfQuery,
  Browse_Document_CertificatePdfQueryVariables
>
export const Browse_Document_ProtocolPdfDocument = gql`
  query BROWSE_Document_protocolPdf($query: DocumentsFilter!) {
    document(filter: $query) @endpoint(name: "product") {
      protocols {
        ...Protocol
      }
    }
  }
  ${ProtocolFragmentDoc}
`

/**
 * __useBrowse_Document_ProtocolPdfQuery__
 *
 * To run a query within a React component, call `useBrowse_Document_ProtocolPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrowse_Document_ProtocolPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrowse_Document_ProtocolPdfQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useBrowse_Document_ProtocolPdfQuery(
  baseOptions: Apollo.QueryHookOptions<
    Browse_Document_ProtocolPdfQuery,
    Browse_Document_ProtocolPdfQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Browse_Document_ProtocolPdfQuery,
    Browse_Document_ProtocolPdfQueryVariables
  >(Browse_Document_ProtocolPdfDocument, options)
}
export function useBrowse_Document_ProtocolPdfLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Browse_Document_ProtocolPdfQuery,
    Browse_Document_ProtocolPdfQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Browse_Document_ProtocolPdfQuery,
    Browse_Document_ProtocolPdfQueryVariables
  >(Browse_Document_ProtocolPdfDocument, options)
}
export type Browse_Document_ProtocolPdfQueryHookResult = ReturnType<
  typeof useBrowse_Document_ProtocolPdfQuery
>
export type Browse_Document_ProtocolPdfLazyQueryHookResult = ReturnType<
  typeof useBrowse_Document_ProtocolPdfLazyQuery
>
export type Browse_Document_ProtocolPdfQueryResult = Apollo.QueryResult<
  Browse_Document_ProtocolPdfQuery,
  Browse_Document_ProtocolPdfQueryVariables
>
export const Browse_Document_DatasheetPdfDocument = gql`
  query BROWSE_Document_datasheetPdf($query: DocumentsFilter!) {
    document(filter: $query) @endpoint(name: "product") {
      datasheet {
        name
        url
      }
    }
  }
`

/**
 * __useBrowse_Document_DatasheetPdfQuery__
 *
 * To run a query within a React component, call `useBrowse_Document_DatasheetPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrowse_Document_DatasheetPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrowse_Document_DatasheetPdfQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useBrowse_Document_DatasheetPdfQuery(
  baseOptions: Apollo.QueryHookOptions<
    Browse_Document_DatasheetPdfQuery,
    Browse_Document_DatasheetPdfQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Browse_Document_DatasheetPdfQuery,
    Browse_Document_DatasheetPdfQueryVariables
  >(Browse_Document_DatasheetPdfDocument, options)
}
export function useBrowse_Document_DatasheetPdfLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Browse_Document_DatasheetPdfQuery,
    Browse_Document_DatasheetPdfQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Browse_Document_DatasheetPdfQuery,
    Browse_Document_DatasheetPdfQueryVariables
  >(Browse_Document_DatasheetPdfDocument, options)
}
export type Browse_Document_DatasheetPdfQueryHookResult = ReturnType<
  typeof useBrowse_Document_DatasheetPdfQuery
>
export type Browse_Document_DatasheetPdfLazyQueryHookResult = ReturnType<
  typeof useBrowse_Document_DatasheetPdfLazyQuery
>
export type Browse_Document_DatasheetPdfQueryResult = Apollo.QueryResult<
  Browse_Document_DatasheetPdfQuery,
  Browse_Document_DatasheetPdfQueryVariables
>
export const Browse_Product_PublicationsDocument = gql`
  query BROWSE_Product_publications(
    $productCode: String!
    $filter: PublicationsFilter!
  ) @endpoint(name: "product") {
    product(id: $productCode) {
      publications(filter: $filter) {
        items {
          authors
          journal
          name
          pages
          productCode
          publicationDate
          pubmedId
          references {
            application
            species
          }
          volume
        }
        count
      }
    }
  }
`

/**
 * __useBrowse_Product_PublicationsQuery__
 *
 * To run a query within a React component, call `useBrowse_Product_PublicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrowse_Product_PublicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrowse_Product_PublicationsQuery({
 *   variables: {
 *      productCode: // value for 'productCode'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useBrowse_Product_PublicationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Browse_Product_PublicationsQuery,
    Browse_Product_PublicationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Browse_Product_PublicationsQuery,
    Browse_Product_PublicationsQueryVariables
  >(Browse_Product_PublicationsDocument, options)
}
export function useBrowse_Product_PublicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Browse_Product_PublicationsQuery,
    Browse_Product_PublicationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Browse_Product_PublicationsQuery,
    Browse_Product_PublicationsQueryVariables
  >(Browse_Product_PublicationsDocument, options)
}
export type Browse_Product_PublicationsQueryHookResult = ReturnType<
  typeof useBrowse_Product_PublicationsQuery
>
export type Browse_Product_PublicationsLazyQueryHookResult = ReturnType<
  typeof useBrowse_Product_PublicationsLazyQuery
>
export type Browse_Product_PublicationsQueryResult = Apollo.QueryResult<
  Browse_Product_PublicationsQuery,
  Browse_Product_PublicationsQueryVariables
>
export const Browse_ProductBatch_ProductConcentrationsDocument = gql`
  query BROWSE_ProductBatch_productConcentrations($id: String!) {
    productBatch(productCode: $id) @endpoint(name: "product") {
      concentrationRange
    }
  }
`

/**
 * __useBrowse_ProductBatch_ProductConcentrationsQuery__
 *
 * To run a query within a React component, call `useBrowse_ProductBatch_ProductConcentrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrowse_ProductBatch_ProductConcentrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrowse_ProductBatch_ProductConcentrationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBrowse_ProductBatch_ProductConcentrationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Browse_ProductBatch_ProductConcentrationsQuery,
    Browse_ProductBatch_ProductConcentrationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Browse_ProductBatch_ProductConcentrationsQuery,
    Browse_ProductBatch_ProductConcentrationsQueryVariables
  >(Browse_ProductBatch_ProductConcentrationsDocument, options)
}
export function useBrowse_ProductBatch_ProductConcentrationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Browse_ProductBatch_ProductConcentrationsQuery,
    Browse_ProductBatch_ProductConcentrationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Browse_ProductBatch_ProductConcentrationsQuery,
    Browse_ProductBatch_ProductConcentrationsQueryVariables
  >(Browse_ProductBatch_ProductConcentrationsDocument, options)
}
export type Browse_ProductBatch_ProductConcentrationsQueryHookResult =
  ReturnType<typeof useBrowse_ProductBatch_ProductConcentrationsQuery>
export type Browse_ProductBatch_ProductConcentrationsLazyQueryHookResult =
  ReturnType<typeof useBrowse_ProductBatch_ProductConcentrationsLazyQuery>
export type Browse_ProductBatch_ProductConcentrationsQueryResult =
  Apollo.QueryResult<
    Browse_ProductBatch_ProductConcentrationsQuery,
    Browse_ProductBatch_ProductConcentrationsQueryVariables
  >
export const Browse_Product_ProductDetailsDocument = gql`
  query BROWSE_Product_productDetails(
    $abId: String!
    $domain: DmdDomain!
    $locale: String!
  ) {
    product(id: $abId) @endpoint(name: "product") {
      allowTargetTierTwoOverride
      animalFree
      ...AssociatedProducts
      carrierFree
      itemClass
      conjugations {
        label
      }
      standardProtein {
        accession
        function
        postTranslationalModification
        sequenceSimilarities
        species
        isoforms {
          isoformAccessionNumber
          sequenceLength
          subcellularLocalisations {
            localisation
            type
          }
        }
      }
      biochemicalAttributes {
        ...Biochemical
      }
      categoryType
      ...CellLine
      ...CellLineTarget
      clonality
      cloneNumber
      directReplacementProduct {
        ...RecommendedProduct
      }
      topRecommendedProducts {
        ...RecommendedProduct
      }
      publicationOptionCombinations {
        application
        species
      }
      ...Conjugation
      form
      format
      formulation
      reconstitution
      formulationConstituents
      hostSpecies
      immunogen {
        ...Immunogen
      }
      relatedImmunogens {
        ...Immunogen
      }
      isoType
      images {
        ...Image
      }
      kitAttributes {
        ...KitAttributes
      }
      ...AntibodyAttributes
      marker {
        name
      }
      name
      localisedName {
        en
        ja
        zh
      }
      productSummary
      notes {
        statement
        noteType
      }
      purificationNotes {
        statement
      }
      productCode
      productSlug
      productTags {
        tagCode
      }
      recombinant
      trialSizeExclusiveCountries
      targetSummaryNotes {
        statement
        noteType
      }
      proteinAttributes {
        ...ProteinAttributes
      }
      publications(filter: { limit: 10 }) {
        count
        items {
          authors
          journal
          name
          pages
          productCode
          publicationDate
          pubmedId
          volume
          references {
            application
            species
          }
        }
      }
      ...Purity
      ...Reactivity
      ...ReviewsSummary
      ...SecondaryAntibody
      seoClass {
        ...SeoClass
      }
      status
      ...Storage
      target {
        ...Target
      }
      relatedTargets {
        name
      }
      unpublishedAttributes {
        ...UnpublishedAttributes
      }
      variations {
        relationship
        product {
          name
          productCode
          productSlug
          seoClass {
            levelOne
          }
          conjugation {
            emission
            excitation
            label
          }
        }
      }
      handlingHazards {
        ...HandlingHazard
      }
    }
    dmdOverride(abId: $abId, domain: $domain, locale: $locale) {
      assetDefinitionNumber
      title
      description
    }
  }
  ${AssociatedProductsFragmentDoc}
  ${BiochemicalFragmentDoc}
  ${CellLineFragmentDoc}
  ${CellLineTargetFragmentDoc}
  ${RecommendedProductFragmentDoc}
  ${ConjugationFragmentDoc}
  ${ImmunogenFragmentDoc}
  ${ImageFragmentDoc}
  ${KitAttributesFragmentDoc}
  ${AntibodyAttributesFragmentDoc}
  ${ProteinAttributesFragmentDoc}
  ${PurityFragmentDoc}
  ${ReactivityFragmentDoc}
  ${ReviewsSummaryFragmentDoc}
  ${SecondaryAntibodyFragmentDoc}
  ${SeoClassFragmentDoc}
  ${StorageFragmentDoc}
  ${TargetFragmentDoc}
  ${UnpublishedAttributesFragmentDoc}
  ${HandlingHazardFragmentDoc}
`

/**
 * __useBrowse_Product_ProductDetailsQuery__
 *
 * To run a query within a React component, call `useBrowse_Product_ProductDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrowse_Product_ProductDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrowse_Product_ProductDetailsQuery({
 *   variables: {
 *      abId: // value for 'abId'
 *      domain: // value for 'domain'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useBrowse_Product_ProductDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Browse_Product_ProductDetailsQuery,
    Browse_Product_ProductDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Browse_Product_ProductDetailsQuery,
    Browse_Product_ProductDetailsQueryVariables
  >(Browse_Product_ProductDetailsDocument, options)
}
export function useBrowse_Product_ProductDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Browse_Product_ProductDetailsQuery,
    Browse_Product_ProductDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Browse_Product_ProductDetailsQuery,
    Browse_Product_ProductDetailsQueryVariables
  >(Browse_Product_ProductDetailsDocument, options)
}
export type Browse_Product_ProductDetailsQueryHookResult = ReturnType<
  typeof useBrowse_Product_ProductDetailsQuery
>
export type Browse_Product_ProductDetailsLazyQueryHookResult = ReturnType<
  typeof useBrowse_Product_ProductDetailsLazyQuery
>
export type Browse_Product_ProductDetailsQueryResult = Apollo.QueryResult<
  Browse_Product_ProductDetailsQuery,
  Browse_Product_ProductDetailsQueryVariables
>
export const Browse_Product_ProductOverviewDocument = gql`
  query BROWSE_Product_productOverview($id: String!) {
    product(id: $id) @endpoint(name: "product") {
      allowTargetTierTwoOverride
      biochemicalAttributes {
        ...Biochemical
      }
      categoryType
      ...CellLine
      ...CellLineTarget
      clonality
      cloneNumber
      carrierFree
      publicationOptionCombinations {
        application
        species
      }
      ...Conjugation
      form
      format
      formulation
      formulationConstituents
      hostSpecies
      immunogen {
        ...Immunogen
      }
      relatedImmunogens {
        ...Immunogen
      }
      isoType
      images {
        ...Image
      }
      kitAttributes {
        ...KitAttributes
      }
      name
      notes {
        statement
        noteType
      }
      reconstitution
      productCode
      productSlug
      productTags {
        tagCode
      }
      trialSizeExclusiveCountries
      proteinAttributes {
        ...ProteinAttributes
      }
      ...Purity
      ...Reactivity
      ...ReviewsSummary
      ...SecondaryAntibody
      seoClass {
        ...SeoClass
      }
      status
      ...Storage
      target {
        ...Target
      }
      handlingHazards {
        ...HandlingHazard
      }
    }
  }
  ${BiochemicalFragmentDoc}
  ${CellLineFragmentDoc}
  ${CellLineTargetFragmentDoc}
  ${ConjugationFragmentDoc}
  ${ImmunogenFragmentDoc}
  ${ImageFragmentDoc}
  ${KitAttributesFragmentDoc}
  ${ProteinAttributesFragmentDoc}
  ${PurityFragmentDoc}
  ${ReactivityFragmentDoc}
  ${ReviewsSummaryFragmentDoc}
  ${SecondaryAntibodyFragmentDoc}
  ${SeoClassFragmentDoc}
  ${StorageFragmentDoc}
  ${TargetFragmentDoc}
  ${HandlingHazardFragmentDoc}
`

/**
 * __useBrowse_Product_ProductOverviewQuery__
 *
 * To run a query within a React component, call `useBrowse_Product_ProductOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrowse_Product_ProductOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrowse_Product_ProductOverviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBrowse_Product_ProductOverviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    Browse_Product_ProductOverviewQuery,
    Browse_Product_ProductOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Browse_Product_ProductOverviewQuery,
    Browse_Product_ProductOverviewQueryVariables
  >(Browse_Product_ProductOverviewDocument, options)
}
export function useBrowse_Product_ProductOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Browse_Product_ProductOverviewQuery,
    Browse_Product_ProductOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Browse_Product_ProductOverviewQuery,
    Browse_Product_ProductOverviewQueryVariables
  >(Browse_Product_ProductOverviewDocument, options)
}
export type Browse_Product_ProductOverviewQueryHookResult = ReturnType<
  typeof useBrowse_Product_ProductOverviewQuery
>
export type Browse_Product_ProductOverviewLazyQueryHookResult = ReturnType<
  typeof useBrowse_Product_ProductOverviewLazyQuery
>
export type Browse_Product_ProductOverviewQueryResult = Apollo.QueryResult<
  Browse_Product_ProductOverviewQuery,
  Browse_Product_ProductOverviewQueryVariables
>
export const Browse_Products_ProductIdentifiersDocument = gql`
  query BROWSE_Products_productIdentifiers($filter: ProductsFilter!) {
    products(filter: $filter) @endpoint(name: "product") {
      name
      productSlug
      productCode
      seoClass {
        levelOne
      }
    }
  }
`

/**
 * __useBrowse_Products_ProductIdentifiersQuery__
 *
 * To run a query within a React component, call `useBrowse_Products_ProductIdentifiersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrowse_Products_ProductIdentifiersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrowse_Products_ProductIdentifiersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useBrowse_Products_ProductIdentifiersQuery(
  baseOptions: Apollo.QueryHookOptions<
    Browse_Products_ProductIdentifiersQuery,
    Browse_Products_ProductIdentifiersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Browse_Products_ProductIdentifiersQuery,
    Browse_Products_ProductIdentifiersQueryVariables
  >(Browse_Products_ProductIdentifiersDocument, options)
}
export function useBrowse_Products_ProductIdentifiersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Browse_Products_ProductIdentifiersQuery,
    Browse_Products_ProductIdentifiersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Browse_Products_ProductIdentifiersQuery,
    Browse_Products_ProductIdentifiersQueryVariables
  >(Browse_Products_ProductIdentifiersDocument, options)
}
export type Browse_Products_ProductIdentifiersQueryHookResult = ReturnType<
  typeof useBrowse_Products_ProductIdentifiersQuery
>
export type Browse_Products_ProductIdentifiersLazyQueryHookResult = ReturnType<
  typeof useBrowse_Products_ProductIdentifiersLazyQuery
>
export type Browse_Products_ProductIdentifiersQueryResult = Apollo.QueryResult<
  Browse_Products_ProductIdentifiersQuery,
  Browse_Products_ProductIdentifiersQueryVariables
>
export const Browse_Document_SdsPdfDocument = gql`
  query BROWSE_Document_sdsPdf($query: DocumentsFilter!) {
    document(filter: $query) @endpoint(name: "product") {
      sds {
        countryCode
        languageCode
        name
        displayName
        url
      }
    }
  }
`

/**
 * __useBrowse_Document_SdsPdfQuery__
 *
 * To run a query within a React component, call `useBrowse_Document_SdsPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrowse_Document_SdsPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrowse_Document_SdsPdfQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useBrowse_Document_SdsPdfQuery(
  baseOptions: Apollo.QueryHookOptions<
    Browse_Document_SdsPdfQuery,
    Browse_Document_SdsPdfQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Browse_Document_SdsPdfQuery,
    Browse_Document_SdsPdfQueryVariables
  >(Browse_Document_SdsPdfDocument, options)
}
export function useBrowse_Document_SdsPdfLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Browse_Document_SdsPdfQuery,
    Browse_Document_SdsPdfQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Browse_Document_SdsPdfQuery,
    Browse_Document_SdsPdfQueryVariables
  >(Browse_Document_SdsPdfDocument, options)
}
export type Browse_Document_SdsPdfQueryHookResult = ReturnType<
  typeof useBrowse_Document_SdsPdfQuery
>
export type Browse_Document_SdsPdfLazyQueryHookResult = ReturnType<
  typeof useBrowse_Document_SdsPdfLazyQuery
>
export type Browse_Document_SdsPdfQueryResult = Apollo.QueryResult<
  Browse_Document_SdsPdfQuery,
  Browse_Document_SdsPdfQueryVariables
>
export const Browse_Document_SdsCountriesDocument = gql`
  query BROWSE_Document_sdsCountries($query: DocumentsFilter!) {
    document(filter: $query) @endpoint(name: "product") {
      countries
    }
  }
`

/**
 * __useBrowse_Document_SdsCountriesQuery__
 *
 * To run a query within a React component, call `useBrowse_Document_SdsCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrowse_Document_SdsCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrowse_Document_SdsCountriesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useBrowse_Document_SdsCountriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    Browse_Document_SdsCountriesQuery,
    Browse_Document_SdsCountriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Browse_Document_SdsCountriesQuery,
    Browse_Document_SdsCountriesQueryVariables
  >(Browse_Document_SdsCountriesDocument, options)
}
export function useBrowse_Document_SdsCountriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Browse_Document_SdsCountriesQuery,
    Browse_Document_SdsCountriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Browse_Document_SdsCountriesQuery,
    Browse_Document_SdsCountriesQueryVariables
  >(Browse_Document_SdsCountriesDocument, options)
}
export type Browse_Document_SdsCountriesQueryHookResult = ReturnType<
  typeof useBrowse_Document_SdsCountriesQuery
>
export type Browse_Document_SdsCountriesLazyQueryHookResult = ReturnType<
  typeof useBrowse_Document_SdsCountriesLazyQuery
>
export type Browse_Document_SdsCountriesQueryResult = Apollo.QueryResult<
  Browse_Document_SdsCountriesQuery,
  Browse_Document_SdsCountriesQueryVariables
>
export const Browse_Product_CrossSellDocument = gql`
  query BROWSE_Product_crossSell($productCode: String!) {
    product(id: $productCode) @endpoint(name: "product") {
      crossSellRecommendations {
        product {
          itemClass
          productCode
          name
          categoryType
          seoClass {
            levelOne
          }
          productSlug
          firstActivationDate
          ...ReviewsSummary
        }
        rank
      }
    }
  }
  ${ReviewsSummaryFragmentDoc}
`

/**
 * __useBrowse_Product_CrossSellQuery__
 *
 * To run a query within a React component, call `useBrowse_Product_CrossSellQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrowse_Product_CrossSellQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrowse_Product_CrossSellQuery({
 *   variables: {
 *      productCode: // value for 'productCode'
 *   },
 * });
 */
export function useBrowse_Product_CrossSellQuery(
  baseOptions: Apollo.QueryHookOptions<
    Browse_Product_CrossSellQuery,
    Browse_Product_CrossSellQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Browse_Product_CrossSellQuery,
    Browse_Product_CrossSellQueryVariables
  >(Browse_Product_CrossSellDocument, options)
}
export function useBrowse_Product_CrossSellLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Browse_Product_CrossSellQuery,
    Browse_Product_CrossSellQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Browse_Product_CrossSellQuery,
    Browse_Product_CrossSellQueryVariables
  >(Browse_Product_CrossSellDocument, options)
}
export type Browse_Product_CrossSellQueryHookResult = ReturnType<
  typeof useBrowse_Product_CrossSellQuery
>
export type Browse_Product_CrossSellLazyQueryHookResult = ReturnType<
  typeof useBrowse_Product_CrossSellLazyQuery
>
export type Browse_Product_CrossSellQueryResult = Apollo.QueryResult<
  Browse_Product_CrossSellQuery,
  Browse_Product_CrossSellQueryVariables
>
export const Browse_Target_EntityDetailsDocument = gql`
  query BROWSE_Target_entityDetails($slug: String!, $id: String) {
    target(slug: $slug, id: $id) @endpoint(name: "product") {
      name
      number
      slug
      relevance {
        domain
        developmentalStage
        cellularLocalization
        function
        involvementInDisease
        pathway
        postTranslationalModifications
        sequenceSimilarities
        tissueSpecificity
      }
      type
      researchAreas
      molecularWeight
      alternativeNames
      modificationSummary
      databaseLinks {
        label
        value
        type
      }
      relatedTargets {
        name
        number
        slug
        relevance {
          domain
        }
      }
    }
  }
`

/**
 * __useBrowse_Target_EntityDetailsQuery__
 *
 * To run a query within a React component, call `useBrowse_Target_EntityDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrowse_Target_EntityDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrowse_Target_EntityDetailsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBrowse_Target_EntityDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Browse_Target_EntityDetailsQuery,
    Browse_Target_EntityDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Browse_Target_EntityDetailsQuery,
    Browse_Target_EntityDetailsQueryVariables
  >(Browse_Target_EntityDetailsDocument, options)
}
export function useBrowse_Target_EntityDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Browse_Target_EntityDetailsQuery,
    Browse_Target_EntityDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Browse_Target_EntityDetailsQuery,
    Browse_Target_EntityDetailsQueryVariables
  >(Browse_Target_EntityDetailsDocument, options)
}
export type Browse_Target_EntityDetailsQueryHookResult = ReturnType<
  typeof useBrowse_Target_EntityDetailsQuery
>
export type Browse_Target_EntityDetailsLazyQueryHookResult = ReturnType<
  typeof useBrowse_Target_EntityDetailsLazyQuery
>
export type Browse_Target_EntityDetailsQueryResult = Apollo.QueryResult<
  Browse_Target_EntityDetailsQuery,
  Browse_Target_EntityDetailsQueryVariables
>
export const Browse_Products_RecentlyViewedDocument = gql`
  query BROWSE_Products_recentlyViewed($filter: ProductsFilter!) {
    products(filter: $filter) @endpoint(name: "product") {
      productCode
      name
      categoryType
      seoClass {
        levelOne
      }
      reviewsSummary {
        aggregatedRating
        numberOfReviews
      }
      firstActivationDate
      productSlug
      status
      publications(filter: { limit: 1 }) {
        count
      }
    }
  }
`

/**
 * __useBrowse_Products_RecentlyViewedQuery__
 *
 * To run a query within a React component, call `useBrowse_Products_RecentlyViewedQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrowse_Products_RecentlyViewedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrowse_Products_RecentlyViewedQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useBrowse_Products_RecentlyViewedQuery(
  baseOptions: Apollo.QueryHookOptions<
    Browse_Products_RecentlyViewedQuery,
    Browse_Products_RecentlyViewedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Browse_Products_RecentlyViewedQuery,
    Browse_Products_RecentlyViewedQueryVariables
  >(Browse_Products_RecentlyViewedDocument, options)
}
export function useBrowse_Products_RecentlyViewedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Browse_Products_RecentlyViewedQuery,
    Browse_Products_RecentlyViewedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Browse_Products_RecentlyViewedQuery,
    Browse_Products_RecentlyViewedQueryVariables
  >(Browse_Products_RecentlyViewedDocument, options)
}
export type Browse_Products_RecentlyViewedQueryHookResult = ReturnType<
  typeof useBrowse_Products_RecentlyViewedQuery
>
export type Browse_Products_RecentlyViewedLazyQueryHookResult = ReturnType<
  typeof useBrowse_Products_RecentlyViewedLazyQuery
>
export type Browse_Products_RecentlyViewedQueryResult = Apollo.QueryResult<
  Browse_Products_RecentlyViewedQuery,
  Browse_Products_RecentlyViewedQueryVariables
>
