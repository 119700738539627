import styles from './listItem.module.css'
import { isKeydownEvent, isKeyupEvent, isTabEvent } from '@lego/utilities'
import cn from 'classnames'
import React, { FC, LegacyRef, useCallback, useEffect, useRef } from 'react'
import type { SelectOption } from './select.type'

type ListItemProps = {
  autofocus?: boolean
  clickCallback: (evt: MouseEvent, val: SelectOption) => void
  keyupCallback?: (evt: KeyboardEvent, val: SelectOption) => void
  keydownCallback?: (evt: KeyboardEvent, val: SelectOption) => void
  value: SelectOption
  tabNavigation?: boolean
  isPlaceholder?: boolean
  testTag?: string
}

const ListItem: FC<ListItemProps> = React.memo((props) => {
  const {
    clickCallback,
    keyupCallback,
    keydownCallback,
    value,
    autofocus,
    tabNavigation = true,
    isPlaceholder,
    testTag,
  } = props
  const itemRef = useRef<HTMLDivElement>()

  const onClick = useCallback(
    (evt: any) => {
      if (isPlaceholder) {
        evt.preventDefault()
        evt.stopPropagation()
        return
      }
      clickCallback(evt, value)
    },
    [clickCallback, isPlaceholder, value]
  )

  const onKeyPressCallback = useCallback(
    (evt: any) => {
      if (!tabNavigation && isTabEvent(evt)) {
        evt.preventDefault()
      }
      if (!autofocus || isPlaceholder) {
        return
      }
      if (isKeyupEvent(evt) && keyupCallback) {
        return keyupCallback(evt, value)
      }
      if (isKeydownEvent(evt) && keydownCallback) {
        return keydownCallback(evt, value)
      }
    },
    [
      autofocus,
      isPlaceholder,
      keydownCallback,
      keyupCallback,
      tabNavigation,
      value,
    ]
  )

  useEffect(() => {
    if (!autofocus) {
      return
    }
    itemRef?.current?.focus()
  }, [autofocus, isPlaceholder, value])

  const classes = cn(styles.listItem, {
    [styles.placeholder]: isPlaceholder,
  })

  return (
    <li
      data-testid={testTag}
      aria-label={value?.displayValue}
      className={classes}
    >
      <div
        ref={itemRef as LegacyRef<HTMLDivElement>}
        tabIndex={0}
        onClick={onClick}
        onKeyDown={onKeyPressCallback}
        onKeyUp={onKeyPressCallback}
        role={'button'}
      >
        <span className="truncate p-0.5"> {value?.displayValue}</span>
      </div>
    </li>
  )
})

export { ListItem }
