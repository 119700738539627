import Head from 'next/head'
import Script from 'next/script'
import { useEffect } from 'react'
import { ApolloProvider } from '@apollo/client'
import { useRouter } from 'next/router'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Toast } from '@abcam-web/shared/ecommerce/components'
import { SearchProvider } from '@abcam-web/search/utilities'
import { Search as SearchOverlay } from '@abcam-web/search/search-overlay'
import { FeatureToggleName } from '@feature-toggles/feature-toggle-name.enum'
import { FeatureTogglesProvider } from '@feature-toggles/feature-toggles.context'
import {
  useVirtualPageViewTracking,
  useScrollDepthTracking,
} from '@abcam-web/shared/data-access/tracking'
import { Freshchat } from '@abcam-web/assist/components'
import { EnvironmentProvider } from '@abcam-web/shared/utilities/environment'
import { CountryProvider } from '@abcam-web/shared/utilities/country'
import {
  // getCountryFromCookie,
  LocalisationProvider,
} from '@abcam-web/shared/utilities/localisation'
import { getProxyClient } from '@abcam-web/shared/data-access/apollo'
import { EcommerceProvider } from '@abcam-web/ecommerce/utilities'
import { ViewportProvider } from '@lego/hooks/viewport'
import { useCommonHeadElements } from '@abcam-web/shared/ui/common-head'
import { HeaderWrapper } from '@abcam-web/browse/header'
import { ShoppingExperience } from '@abcam-web/shared/ui/shopping-experience-provider'
import { UserProvider } from '@abcam-web/shared/ui/user-provider'
import { ErrorBoundary } from '@abcam-web/browse/error-ui'
import { configureAuth } from '@abcam-web/auth-shared/aws-cognito'
import { StaleCardPaymentMonitor } from '@abcam-web/payments/ui/stale-card-payment-monitor'
import { translations as searchTranslations } from '@abcam-web/search/public/utilities'
import { translations as assistTranslations } from '@abcam-web/assist/utilities'
import { translations as countryDropdownMessages } from '@lego/ui/country-selector-dropdown'
import accountsDropDownTranslations from '@abcam-web/auth-shared/components/accountDropdown/i18n'
// import { COUNTRY_SELECTOR_PERSIST_KEY } from '@lego/ui/country-selector-dropdown'
// import { useIsPunchoutUser } from '@abcam-web/auth-shared/aws-cognito/hooks/useIsPunchoutUser'
import { countries, defaultCountry } from '@abcam-web/shared/config'
import { Footer } from '@abcam-web/search/public/ui'
import {
  ecommerceTranslations,
  sharedComponentTranslationsOverrides,
} from '@abcam-web/shared/utilities/i18n'
// import { useIsLogged } from '@abcam-web/shared/ecommerce/utilities'
import { WelcomeBanner } from './welcome-banner'
import { SkipToMainContent } from './skip-to-main-content'
// import GeolocationModal from './geolocation-modal/geolocation-modal'
import type { MyAppType } from './types/_app.type'
// import { isbot } from 'isbot'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
})

declare global {
  interface Window {
    __STATE__: { env: string }
    OnetrustActiveGroups: string
    OneTrust: {
      OnConsentChanged: (callback: () => void) => void
      ToggleInfoDisplay: () => void
    }
    OptanonWrapper: () => void
  }
}

const GtmSetup = ({ containerId }: { containerId: string }) => (
  <Script
    dangerouslySetInnerHTML={{
      __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${containerId}');`,
    }}
    id="browse-gtm"
  ></Script>
)

export const MyApp: MyAppType = ({
  Component,
  environment,
  featureToggles,
  pageProps,
  isOneTrustEnabled,
  navigation,
  locale,
}) => {
  const { pathname } = useRouter()
  // const { isUserFetched, user } = useIsLogged()
  // const isPunchout = useIsPunchoutUser(user)

  const commonHead = useCommonHeadElements(
    Number(environment?.NEW_RELIC_APPLICATION_ID),
    environment?.NEW_RELIC_LICENSE_KEY,
    environment?.NEW_RELIC_AGENT_ID
  )
  // const [
  //   showGeolocationModal,
  //   // setShowGeolocationModal
  // ] = useState<boolean>(false)

  const translations = [
    searchTranslations,
    assistTranslations,
    accountsDropDownTranslations,
    countryDropdownMessages,
    ecommerceTranslations,
  ]
  const translationsOverrides = [sharedComponentTranslationsOverrides]

  configureAuth(environment.STAGE)

  const hideFooter = [
    '/inquiry/[inquiry]',
    '/checkout/[checkout]',
    '/shipping-details',
    '/shipping-details/[shipping-details]',
    '/billing-details',
    '/billing-details/[billing-details]',
  ].includes(pathname)

  // Allows to disable Header and Footer on ecommerce pages

  useEffect(() => {
    // Required for GTM
    window.__STATE__ = { env: environment.STATUS_ENVIRONMENTNAME }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useVirtualPageViewTracking()
  useScrollDepthTracking()

  useEffect(() => {
    // specifying that the UI has hydrated and is now stable.
    // can be used in tests
    setTimeout(() => {
      const head = document.getElementsByTagName('head')[0]
      head?.setAttribute('data-hydrated', 'true')
    }, 0)
  }, [])

  // TODO: Enable/remove once we determine whether to keep the geolocation modal.
  // (Also "yarn add isbot" would be needed below).
  /*
  const compareGeolocation = async (countryCode: string) => {
    const data = await fetch('/api/detected-country', { cache: 'no-store' })
    try {
      const response = await data.json()
      if (response.country && response.country !== countryCode) {
        setShowGeolocationModal(true)
      }
    } catch (err) {
      console.info('Could not detect country via API endpoint', err)
    }
  }
  useEffect(() => {
    if (!isUserFetched) {
      return
    }
    // Determine whether geolocation modal should render
    const countryCode = getCountryFromCookie()
    if (
      pathname ==
        '/products/[seoClassLevelOne]/[seoClassLevelTwoOrProductSlug]' &&
      countryCode &&
      !isbot(navigator.userAgent) &&
      !isPunchout &&
      localStorage.getItem(COUNTRY_SELECTOR_PERSIST_KEY) !== 'true'
    ) {
      compareGeolocation(countryCode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, isPunchout, isUserFetched])
  */

  const gtmSetupInHead = featureToggles.find(
    (featureToggle) => featureToggle?.name === FeatureToggleName.GtmSetupInHead
  )?.enabled

  const isHomePage = pathname === '/'

  return (
    <>
      <Head>
        <link href="https://js-agent.newrelic.com" rel="dns-prefetch" />
        {commonHead}
        {/* Site ownership verification tags for all the search engines
        Do not remove these as it will break data in the respective dashboards for the SEO team */}
        {isHomePage && (
          <>
            <meta
              content="qfzMjU56cpvgZKS6PhdMbD90wkFBG60r3-LK2NAndO0"
              name="google-site-verification"
            />
            <meta
              content="49B628F6CA2CC299EC928FC9A6FEE21A"
              name="msvalidate.01"
            />
            <meta
              content="93adf8561645ab041eb7e65aa9beaf25663e1fd7"
              name="naver-site-verification"
            />
            <meta
              content="e3w5qUIsjsCw10irieMT3m34oruLD2U4"
              name="seznam-wmt"
            />
            <meta content="0b6bee8765016db2" name="yandex-verification" />
          </>
        )}
        <title>
          Antibodies, Proteins, Kits and Reagents for Life Science | Abcam
        </title>
        <meta
          content="Abcam, the leading supplier of protein research tools to life scientists. Abcam offers high-quality biological reagents and tools including antibodies, proteins, assays, cell lines and lysates."
          key="meta description"
          name="description"
        />
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/lubalingraphstd-book.woff2"
          rel="preload"
          type="font/woff2"
        />
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/lubalingraphstd-demi.woff2"
          rel="preload"
          type="font/woff2"
        />
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/noto-sans-latin-regular.woff2"
          rel="preload"
          type="font/woff2"
        />
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/noto-sans-latin-italic.woff2"
          rel="preload"
          type="font/woff2"
        />
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/noto-sans-latin-semibold.woff2"
          rel="preload"
          type="font/woff2"
        />
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/noto-sans-latin-bold.woff2"
          rel="preload"
          type="font/woff2"
        />
        <meta content="initial-scale=1.0, width=device-width" name="viewport" />
        {gtmSetupInHead ? (
          <GtmSetup containerId={environment.GTM_CONTAINER_ID} />
        ) : null}
      </Head>

      {!gtmSetupInHead ? (
        <GtmSetup containerId={environment.GTM_CONTAINER_ID} />
      ) : null}
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${environment.GTM_CONTAINER_ID}"
              height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        }}
      ></noscript>

      <CountryProvider countries={countries} initialCountry={defaultCountry}>
        <LocalisationProvider
          locale={locale}
          translations={translations}
          translationsOverrides={translationsOverrides}
        >
          <EnvironmentProvider environment={environment}>
            <FeatureTogglesProvider featureToggles={featureToggles}>
              <QueryClientProvider client={queryClient}>
                <UserProvider>
                  <ViewportProvider>
                    <ApolloProvider client={getProxyClient()}>
                      <ShoppingExperience />
                      <EcommerceProvider enableCognitoInterceptor>
                        <StaleCardPaymentMonitor>
                          <SearchProvider>
                            <div className="flex flex-col">
                              <SkipToMainContent />
                              <div
                                className="relative z-feedbackPanel"
                                id="header-container"
                              >
                                <Freshchat
                                  environment={environment}
                                  featureToggles={featureToggles}
                                  isOneTrustEnabled={!!isOneTrustEnabled}
                                />
                                <WelcomeBanner hidden={!isHomePage} />
                              </div>
                              <HeaderWrapper navigation={navigation} />
                              <SearchOverlay />
                              <ErrorBoundary>
                                <div id="main-content"></div>
                                <Component {...pageProps} />
                                <Toast
                                  closeOnClick={false}
                                  position="top-center"
                                />
                              </ErrorBoundary>
                              {!hideFooter && <Footer />}
                              {/* {showGeolocationModal && <GeolocationModal />} */}
                            </div>
                          </SearchProvider>
                        </StaleCardPaymentMonitor>
                      </EcommerceProvider>
                    </ApolloProvider>
                  </ViewportProvider>
                </UserProvider>
              </QueryClientProvider>
            </FeatureTogglesProvider>
          </EnvironmentProvider>
        </LocalisationProvider>
      </CountryProvider>
    </>
  )
}
