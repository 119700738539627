import cn from 'classnames'
import { useRouter } from 'next/router'
import type { LegacyRef, Ref, SyntheticEvent } from 'react'
import { forwardRef } from 'react'
import type { ButtonProps } from './button.type'

import styles from './button.module.css'
import { BUTTON_SIZE, BUTTON_VARIANT } from './button.type'

const Button = forwardRef(
  (
    {
      alignCenter = true,
      as = 'button',
      bodyClassName,
      children,
      className,
      dark = false,
      dataCy,
      disabled,
      form,
      fullWidth = false,
      href = '',
      iconBurger = false,
      iconButton = false,
      iconLeft,
      iconRight,
      onBlur,
      onClick,
      onKeyDown,
      onMouseDown,
      openInNewWindow = false,
      overrideRouter = false,
      responsiveSizing = false,
      rounded = true,
      size = BUTTON_SIZE.large,
      variant = BUTTON_VARIANT.primary,
      ...otherProps
    }: ButtonProps,
    ref?: Ref<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    const router = useRouter()

    const htmlClassName = cn(
      styles.container,
      styles[variant],
      styles[size],
      bodyClassName,
      className,
      { [styles.disabled]: disabled },
      { [styles.dark]: dark },
      { [styles.iconButton]: iconButton },
      { [styles.iconBurger]: iconBurger },
      { [styles.fullWidth]: fullWidth },
      { [styles.alignCenter]: alignCenter },
      { [styles.rounded]: rounded },
      { [styles.responsiveSizing]: responsiveSizing }
    )

    const handleAnchorClick = (e: SyntheticEvent) => {
      if (onClick) {
        onClick(e)
        return
      }
      if (!overrideRouter) {
        e.preventDefault()
        router.push(href)
      }
    }

    const contentClassName = cn(styles.content, bodyClassName)
    if (as === 'button' || as === 'div') {
      const content = (
        <>
          <span className={styles.iconLeft}>{iconLeft && iconLeft}</span>
          <span className={contentClassName}>{children}</span>
          <span className={styles.iconRight}>{iconRight && iconRight}</span>
        </>
      )

      if (as === 'div') {
        return (
          <div className={htmlClassName} data-cy={dataCy}>
            {content}
          </div>
        )
      }

      if (as === 'button') {
        return (
          <button
            className={htmlClassName}
            data-cy={dataCy}
            disabled={disabled}
            form={form}
            onBlur={onBlur}
            onClick={onClick}
            onKeyDown={onKeyDown}
            onMouseDown={onMouseDown}
            ref={ref ? (ref as LegacyRef<HTMLButtonElement>) : undefined}
            type="button"
            {...otherProps}
          >
            {content}
          </button>
        )
      }
    }

    return (
      <a
        className={htmlClassName}
        href={href}
        {...(!openInNewWindow && { onClick: handleAnchorClick })}
        {...(openInNewWindow && {
          rel: 'noreferrer',
          target: '_blank',
          onClick,
        })}
        {...otherProps}
        ref={ref ? (ref as LegacyRef<HTMLAnchorElement>) : undefined}
        data-cy={dataCy}
      >
        {iconLeft && iconLeft}
        <span className={contentClassName}>{children}</span>
        {iconRight && iconRight}
      </a>
    )
  }
)

export { Button }
