import {
  COUNTRY_CODES,
  FieldNames,
  FieldSchema,
  Schema,
} from '@abcam-web/address-validator'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'

import { FieldConfigProps } from '../data/fieldConfig'
import { typeRenderer } from './typeRenderer'

type DefaultValues = Partial<Record<keyof Schema, string | null>>

type RenderSchemaProps = {
  schema?: Schema
  countryCode?: COUNTRY_CODES
  fieldConfig: FieldConfigProps
  defaultValues?: DefaultValues
  labelClassName?: string
  inputClassName?: string
  menuClassName?: string
}

const SchemaRenderer = ({
  schema,
  defaultValues,
  fieldConfig = {
    labels: [],
    errorMessages: {},
  },
  countryCode,
  labelClassName,
  inputClassName,
  menuClassName,
}: RenderSchemaProps) => {
  const { formatMessage } = useIntl()
  const { errorMessages, labels } = fieldConfig

  // translating the labels
  const localisedLabels = useMemo(() => {
    return labels?.map((field) => {
      return {
        ...field,
        label: formatMessage({ id: `address.${field.key}` }),
      }
    })
  }, [labels, formatMessage])

  // translating error messages
  const localisedErrorMessages = useMemo(() => {
    return Object.entries(errorMessages || {}).reduce(
      (acc, [key]) => ({
        ...acc,
        [key]: formatMessage({ id: `validation.${key}` }),
      }),
      {}
    )
  }, [errorMessages, formatMessage])

  const keys = fieldConfig?.labels?.map((field) => field.key) as FieldNames[]

  if (!schema) {
    return null
  }

  //adding an empty entry to the oneOf type field to create a placholder item for the select
  const schemaWithPlaceholders = Object.keys(schema).reduce((acc, key) => {
    const fieldSchema: FieldSchema | undefined = schema[key as FieldNames]
    if (
      fieldSchema &&
      (fieldSchema.type === 'string' || fieldSchema.type === 'number') &&
      fieldSchema.oneOf
    ) {
      const value = fieldSchema.type === 'string' ? '' : 0
      return {
        ...acc,
        [key]: {
          ...fieldSchema,
          oneOf: [value].concat(fieldSchema.oneOf),
        },
      }
    }
    return {
      ...acc,
      [key]: fieldSchema,
    }
  }, {} as Schema)

  return (
    <>
      {keys.map((key) => {
        const fieldSchema = schemaWithPlaceholders[key]
        return (
          fieldSchema &&
          typeRenderer[fieldSchema.type]({
            uid: key + countryCode,
            key,
            fieldSchema,
            requiredPlaceholder: formatMessage({
              id: `address.requiredPlaceholder`,
            }),
            fieldConfig: {
              errorMessages: localisedErrorMessages,
              labels: localisedLabels,
            },
            defaultValue: defaultValues?.[key],
            labelClassName,
            inputClassName,
            menuClassName,
          })
        )
      })}
    </>
  )
}

export { SchemaRenderer }
export type { DefaultValues,FieldConfigProps }
