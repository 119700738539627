import {
  EventName,
  LocationID,
  ToggleAction,
} from '@abcam-web/shared/data-access/tracking'
import {
  getContactUsContext,
  trackEvents,
} from 'libs/shared/data-access/tracking/src/lib/gtm'
import { Freshchat } from './freshchat.types'
import {
  getCountryFromCookie,
  getCountryNameByCountryCode,
} from '@abcam-web/shared/utilities/localisation'
import { defaultCountry } from '@abcam-web/shared/config'
declare global {
  interface Window {
    fcWidget: Freshchat
    OnetrustActiveGroups: string
  }
}

export const OneTrustFunctionalCookiesConsented = 'C0003'

export const toggleFreshchat = () => {
  if (typeof window !== 'undefined') {
    window.fcWidget?.isOpen()
      ? window.fcWidget?.close()
      : window.fcWidget?.open()
  }
}

export const trackEvent = (click_id: string, toggleOpen?: boolean) => {
  if (typeof window !== 'undefined') {
    const page_path = window.location.pathname

    const countryCode = getCountryFromCookie() ?? defaultCountry
    const country = getCountryNameByCountryCode(countryCode)

    const context = getContactUsContext({
      event: EventName.WEB_ENGAGEMENT,
      click_id,
      country,
      page_path,
      widget: 'FreshChat',
      location_id: LocationID.WIDGET,
      toggle: toggleOpen
        ? toggleOpen
          ? ToggleAction.OPEN
          : ToggleAction.CLOSE
        : undefined,
      page_type: '',
    })

    trackEvents(context)
  }
}

const initializeEventTracking = () => {
  if (typeof window !== 'undefined' && typeof window.fcWidget !== 'undefined') {
    window.fcWidget.on('widget:opened', () => {
      trackEvent('Toggle Widget', true)
    })

    window.fcWidget.on('widget:closed', () => {
      trackEvent('Toggle Widget', false)
    })

    window.fcWidget.on('message:sent', () => {
      trackEvent('Message Sent')
    })

    window.fcWidget.on('message:received', () => {
      trackEvent('Message Received')
    })

    window.fcWidget.on('widget:loaded', () => {
      trackEvent('Widget Loaded')
    })

    window.fcWidget.on('widget:destroyed', () => {
      trackEvent('Widget Unloaded')
    })
  }
}

export const initializeFreshchat = (
  token: string,
  host: string,
  widgetUuid: string
) => {
  if (typeof window !== 'undefined' && typeof window.fcWidget !== 'undefined') {
    window.fcWidget?.init({
      token,
      host,
      widgetUuid,
    })

    initializeEventTracking()
  }
}

export const isFreshchatInitialized = () => {
  if (typeof window !== 'undefined' && typeof window.fcWidget !== 'undefined') {
    const isInitialized = window.fcWidget?.isInitialized()
    return isInitialized
  }

  return false
}

export const destroyFreshchat = () => {
  if (typeof window !== 'undefined' && typeof window.fcWidget !== 'undefined') {
    window.fcWidget?.destroy()
  }
}

export const userConsentsToFunctionalCookies = () => {
  if (
    typeof window !== 'undefined' &&
    typeof window.OnetrustActiveGroups !== 'undefined'
  ) {
    const userConsentsToFunctionalCookies =
      window.OnetrustActiveGroups?.includes(OneTrustFunctionalCookiesConsented)

    return userConsentsToFunctionalCookies
  }

  return false
}
