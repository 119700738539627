import { useState, useCallback, useMemo, useEffect } from 'react'
import type { Dispatch, SetStateAction } from 'react'

function isCallback<T>(value: SetStateAction<T>): value is (prevValue: T) => T {
  return typeof value === 'function'
}

/**
 * Returns a stateful value (persisted across refreshes within the same session)
 * and a function to update it.
 * @param id - ID (unique for this session) used to store the persisted value
 * in the session storage. If this value changes then the current value is overwritten
 * with what found on the session storage (or `initialValue` if it's empty).
 * @param initialValue - Initial value, it's ignored if a value with the
 * specified `id` already exists in the session storage. Note that this value
 * (and the value provided to the update function) must be able to be serialised
 * in JSON. It means that functions are not supported.
 * @returns An array containing the current value (first element) and a function
 * to update it (second value).
 */
export function useSessionState<T>(
  id: string,
  initialValue: T
): [T, Dispatch<SetStateAction<T>>] {
  if (window === undefined) {
    throw new Error(
      'useSessionState can be used only inside components rendered client-side'
    )
  }

  const [value, setValue] = useState<T>(initialValue)

  useEffect(() => {
    const persistedValue = window.sessionStorage.getItem(id)
    if (persistedValue !== null) {
      try {
        setValue(JSON.parse(persistedValue))
        return
      } catch {
        // JSON from session storage is malformed, just ignore it
      }
    }

    window.sessionStorage.setItem(id, JSON.stringify(value))
    // We do not want a dependency from the current value of 'value'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const setter: typeof setValue = useCallback(
    (newValue: Parameters<typeof setValue>[0]) => {
      setValue((prevValue) => {
        const valueToStore = isCallback(newValue)
          ? newValue(prevValue)
          : newValue
        window.sessionStorage.setItem(id, JSON.stringify(valueToStore))
        return valueToStore
      })
    },
    [id]
  )

  return useMemo(() => [value, setter], [value, setter])
}
