import { createContext } from 'react'
import type { ReactElement } from 'react'
import type { ProductOverviewContextProps } from '@abcam-web/search/public/utilities/product.type'

export const ProductOverviewContext =
  createContext<ProductOverviewContextProps>({ productOverview: undefined })

export const ProductOverviewProvider = ({
  productOverview,
  children,
}: ProductOverviewContextProps): ReactElement => (
  <ProductOverviewContext.Provider
    value={{
      productOverview,
    }}
  >
    {children}
  </ProductOverviewContext.Provider>
)
