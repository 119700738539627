import debounce from 'lodash/debounce'
import { createContext, useEffect, useState } from 'react'

import { breakpoints } from './breakpoints'

import type { PropsWithChildren } from 'react'
import type { Breakpoint, Dimensions, TWBreakpoint } from './viewport.types'

const toNumber = (value: string | undefined): number | undefined => {
  return value ? parseInt(value.replace('px', ''), 10) : undefined
}

const translateToBreakpoint = (twBreakpoint: TWBreakpoint): Breakpoint => {
  let min = 0
  let max = Number.MAX_SAFE_INTEGER
  if (typeof twBreakpoint === 'string') min = toNumber(twBreakpoint) || min
  if (typeof twBreakpoint === 'number') min = twBreakpoint
  if (typeof twBreakpoint === 'object') {
    min = toNumber(twBreakpoint['min']) || min
    max = toNumber(twBreakpoint['max']) || max
  }
  return { min, max }
}

const matchQuery = (width: number) => (screen: string) => {
  let foundMatch = false
  for (const [key, value] of Object.entries(breakpoints)) {
    if (key === screen) {
      const breakpoint = translateToBreakpoint(value)
      if (breakpoint.min <= width && width <= breakpoint.max) foundMatch = true
    }
  }

  return foundMatch
}

const defaultDimensions: Dimensions = {
  width: 1300,
  height: 1000,
  isSmall: false,
  isMedium: false,
  isLarge: true,
  matchQuery: matchQuery(1300),
}

const ViewportContext = createContext<Dimensions>(defaultDimensions)

const ViewportProvider = ({ children }: PropsWithChildren) => {
  const [dimensions, setDimensions] = useState<Dimensions>(defaultDimensions)

  function handleResize() {
    const windowWidth = window.innerWidth
    setDimensions({
      width: windowWidth,
      height: window.innerHeight,
      isSmall: windowWidth <= Number(breakpoints.small),
      isMedium:
        windowWidth > Number(breakpoints.small) && windowWidth <= Number(breakpoints.medium),
      isLarge: windowWidth > Number(breakpoints.medium),
      matchQuery: matchQuery(windowWidth),
    })
  }

  useEffect(() => {
    handleResize()
    const handleDebounced = debounce(handleResize, 250)
    window.addEventListener('resize', handleDebounced)
    return () => window.removeEventListener('resize', handleDebounced)
  }, [])

  return (
    <ViewportContext.Provider value={dimensions}>
      {children}
    </ViewportContext.Provider>
  )
}

export { ViewportContext, ViewportProvider }
