import { BaseSwitchProps } from '../_base_switch/base_switch.type'
import { BaseSwitch } from '../_base_switch/base_switch'

import style from './switch.module.css'

type Props = Omit<BaseSwitchProps, 'styles' | 'SwitchUi'>
const SwitchComponent = () => <div className={style.slider} />

const Switch = (props: Props) => {
  return <BaseSwitch styles={style} SwitchUi={<SwitchComponent />} {...props} />
}

export { Switch }
