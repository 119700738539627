import type { FacetInput } from '@abcam-web/shared/data-access/search-schema'
import type { ParsedUrlQuery } from 'querystring'

const getFiltersFromQueryParams = (queryParams: ParsedUrlQuery) =>
  Object.keys(queryParams)
    .filter((key: string) => key.indexOf('facets') !== -1)
    .reduce((filters: FacetInput[], key) => {
      const currentQueryParams = (queryParams[key] || '').toString()
      const values = currentQueryParams.split('|')
      const type = key.split('.')[1]
      const filtersByKey = values.map(
        (value: string): FacetInput => ({ label: value, type })
      )
      filters.push(...filtersByKey)
      return filters
    }, [])

export { getFiltersFromQueryParams }
