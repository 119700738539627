import { routes } from '@abcam-web/search/utilities'
import { logger } from '@abcam-web/logger'
import { searchService } from '@abcam-web/search/data-access/search.service'

export const getPDPRouteFromProductCode = async (
  productCode: string,
  locale: string
): Promise<string | undefined> => {
  try {
    const product = await searchService.product(productCode, locale || '')
    return routes.productDetailPage({
      seoClassLevelOne: product.seoClass.levelOne,
      productSlug: product.productSlug,
    }).href
  } catch (error) {
    logger.error(`Error getting PDP route from product code: ${productCode}`, {
      error: JSON.stringify(error),
    })
    return
  }
}
