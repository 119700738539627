import type { Currency } from './types/currencies'
import type { LocalePrimitive } from './types/locale'
import { formatNumberIntl } from './helpers/formatNumberIntl'
import { normalizeLocale } from './helpers/normalizeLocale'
import { CURRENCY_CONFIG, currencyRegex } from './constants'

export const formatCurrency = ({
  locale,
  currency,
  value,
}: {
  locale: LocalePrimitive
  currency?: string
  value?: number
}): string | undefined => {
  const normalizedLocale = normalizeLocale(locale)

  const isValidInput =
    typeof value === 'number' &&
    typeof currency === 'string' &&
    currencyRegex.test(currency)

  if (!isValidInput || !normalizedLocale) {
    return
  }

  const { symbol, intl } =
    CURRENCY_CONFIG[currency.toUpperCase() as Currency] ?? {}

  const intlOptions: Intl.NumberFormatOptions = {
    style: 'currency',
    currency,
    currencyDisplay:
      intl?.getCurrencyDisplay(normalizedLocale) ?? 'narrowSymbol',
  }

  const formatNumberForLocale = formatNumberIntl(normalizedLocale)

  if (symbol && symbol.showVariant(normalizedLocale)) {
    return formatNumberForLocale({ ...intlOptions, currencyDisplay: 'code' })
      .format(value)
      .replace(currency.toUpperCase(), symbol.variant)
  }

  return formatNumberForLocale(intlOptions).format(value)
}
