export const toSentences = (parts: string[]): string => {
  const partsWithoutFullStop = parts.map((part) => {
    const trimmed = part.trim()
    return trimmed.endsWith('.')
      ? trimmed.substring(0, trimmed.length - 1)
      : trimmed
  })

  const sentence = partsWithoutFullStop.filter(Boolean).join('. ')

  return sentence.length ? `${sentence}.` : ''
}
