import type { MegaNavActiveStates } from '../mega-nav.types'

export function calculateUnderlineLocation(
  pathname: string
): MegaNavActiveStates {
  if (pathname.includes('/technical-resources')) {
    if (pathname.includes('/troubleshooting')) {
      return 'support'
    }

    return 'resources'
  }

  if (
    pathname.includes('/product-overviews') ||
    pathname.includes('/products')
  ) {
    return 'products'
  }

  if (
    pathname.includes('/offers-and-initiatives') ||
    pathname.includes('/support')
  ) {
    return 'support'
  }

  if (pathname.includes('/about')) {
    return 'about-us'
  }

  return ''
}
