import { useEffect } from 'react'

/**
 * Invokes a callback after a specified amount of time.
 * @param callback - The callback to invoke after `ms` milliseconds elapsed.
 * @param ms - The amount of time (in milliseconds) to wait before invoking the specified
 * callback. If the component is unmounted before this amount of time then the callback is
 * never invoked. Changing this value (or any other parameter) before the time elapsed will
 * cause the timer to restart without invoking the callback.
 * @param enabled - If set to `false` then the timer does not start counting.
 * Default is `true`.
 */
export function useTimeout(callback: () => void, ms: number, enabled = true) {
  useEffect(() => {
    if (!enabled) {
      return
    }

    const timerId = setTimeout(callback, ms)

    return () => clearTimeout(timerId)
  }, [enabled, ms, callback])
}
