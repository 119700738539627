type SelectOption = {
  key?: string | number
  displayValue: string
  isPreselected?: boolean
  isCustom?: boolean
  isPlaceholder?: boolean
}

type SelectProps = {
  options?: SelectOption[]
  onChange: (val: SelectOption) => void
  onInputChange?: (val: SelectOption) => void
  onNumberInputToggle?: (val: boolean) => void
  maxOptions?: number
  disabled?: boolean
  dark?: boolean
  variant?: SELECT_VARIANT
  position?: 'left' | 'right'
  disableMaxOption?: boolean
  fullWidth?: boolean
  size?: SELECT_SIZE
  inputId?: string
  justifyContent?: SELECT_JUSTIFY | keyof typeof SELECT_JUSTIFY
  testTag?: string
  className?: string
  isExpanded?: boolean
  listMaxHeight?: string
  ulClassName?: string
  hasError?: boolean
  errorText?: string
  selectedValueContainerClassName?: string
  label?: string
  name?: string
}

export enum SELECT_VARIANT {
  primary = 'primary',
  tertiary = 'tertiary',
  secondary = 'secondary',
}

export enum SELECT_SIZE {
  xsmall = 'xsmall',
  small = 'small',
  medium = 'medium',
}

export enum SELECT_JUSTIFY {
  center = 'center',
  evenly = 'evenly',
  spaceBetween = 'space-between',
  spaceAround = 'space-around',
}

export type { SelectProps, SelectOption }
