import React from 'react'
import { ErrorComponent } from './error'
import { logger } from '@abcam-web/logger'
import type { PropsWithChildren } from 'react'

class ErrorBoundary extends React.Component<
  PropsWithChildren,
  { hasError: boolean }
> {
  constructor(props: PropsWithChildren) {
    super(props)

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI

    return { hasError: true }
  }

  public override componentDidCatch(error: any, errorInfo: any) {
    // You can use your own error logging service here
    logger.error('Error boundary triggered', error)
    logger.info('Error boundary triggered', errorInfo)
  }
  public override render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorComponent
          errorCode={500}
          onClick={() => this.setState({ hasError: false })}
        />
      )
    }

    // Return children components in case of no error

    return this.props.children
  }
}

export { ErrorBoundary }
