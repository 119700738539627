import { NavMenu } from './navMenu'
import { toast } from 'react-toastify'
import { UserContext } from '@abcam-web/auth-shared/contexts/user'
import { useRouter } from 'next/router'
import { AuthenticationDropdown } from './authentication-dropdown'
import { User } from '@abcam-web/auth-shared/contexts/user'
import { DropDownContent, DropDownFooter } from './dropdownContent'
import {
  useBreakpointListener,
  breakpointsAsNumbers,
} from '@lego/hooks/breakpoint-listener'
import {
  trackUserSessionLoggedOutEvent,
  useGtm,
} from '@abcam-web/shared/data-access/tracking'
import getConfig from 'next/config'
import { removeAuthTokensFromLocalStorage } from '@abcam-web/shared/utilities/auth'

interface Props {
  signOut: () => Promise<void>
  loginUrl: string
  registerUrl: string
  onSignOut?: () => void
  user?: User | null
}
const AccountDropdown = ({
  signOut,
  loginUrl,
  registerUrl,
  onSignOut,
  user,
}: Props) => {
  const router = useRouter()
  const gtm = useGtm()

  const handleLogOut = () => {
    signOut()
      .then(() => {
        const stage = getConfig().publicRuntimeConfig?.STAGE
        removeAuthTokensFromLocalStorage(stage)

        onSignOut && onSignOut()

        trackUserSessionLoggedOutEvent(gtm.track)
        // reloads the page
        router.reload()
      })
      .catch((error) => {
        toast.error(error)
      })
  }

  const matchesMduQuery = useBreakpointListener(breakpointsAsNumbers.md, '<')
  const displayPosition = 'right'

  return (
    <UserContext.Provider value={{ user }}>
      <AuthenticationDropdown
        className="text-body-xmedium"
        displayPosition={displayPosition}
      >
        <DropDownContent
          loginUrl={loginUrl}
          registerUrl={registerUrl}
          isMobile={matchesMduQuery}
        />
        <div className="px-3">
          <div className="w-full h-px bg-gray-300" />
        </div>
        <NavMenu className="pb-4" onLogOut={handleLogOut} />
        <DropDownFooter />
      </AuthenticationDropdown>
    </UserContext.Provider>
  )
}

export { AccountDropdown }
