import styles from './countrySelectorDropdown.module.css'
import {
  CountrySelectorSize,
  CountrySelectorVariant,
} from './countrySelectorDropdown.type'
import type { CountrySelectorDropdownProps } from './countrySelectorDropdown.type'
import { ChevronDown } from '@lego/ui/icons'
import * as Popover from '@radix-ui/react-popover'
import classnames from 'classnames'
import { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { CountryLanguageSelector } from './countryLanguageSelector'
import { CountryDisplay, CountryShortDisplay } from './utils'
import { useCountry } from '@abcam-web/shared/utilities/country'
import { useRouter } from 'next/router'

const CountrySelectorDropdown = ({
  defaultSelectedCountry,
  shouldDisplayCountryName = false,
  variant = CountrySelectorVariant.primary,
  size = CountrySelectorSize.small,
  displayPosition,
  className = '',
  persistSelection = false,
  countries = undefined,
  excludedCountries = undefined,
  onChange = undefined,
  isMainSiteSwitcher = false,
  withLanguageSelector = false,
}: CountrySelectorDropdownProps) => {
  const { query } = useRouter()
  const { country } = useCountry()
  const { formatMessage } = useIntl()
  const [isOpen, setIsOpen] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState<string | undefined>()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setSelectedCountry(defaultSelectedCountry)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSelectedCountry, query, country])

  const onVisibilityChange = useCallback((visibility: boolean) => {
    setIsOpen(visibility)
  }, [])

  const getParentElementVariantStyle = useCallback(
    (variant: CountrySelectorVariant) => {
      switch (variant) {
        // CountrySelectorVariant.primary intentionally missing
        case CountrySelectorVariant.secondary:
          return 'bg-interactive-black-transparent-hover border-interactive-black-transparent-hover text-black-0 hover:shadow-interactiveElement hover:bg-interactive-black-transparent-hover'
        case CountrySelectorVariant.tertiary:
          return '!border-black-0 justify-between text-black-0 hover:shadow-interactiveElement hover:bg-interactive-black-transparent-hover'
        default:
          return ''
      }
    },
    []
  )

  const onChangeIsOpenCallback = useCallback(
    (val: boolean) => {
      setIsOpen(val)
    },
    [setIsOpen]
  )

  const onChangeSelectedCallback = useCallback(
    (val: string | undefined) => {
      if (val) {
        setSelectedCountry(val)
      }
    },
    [setSelectedCountry]
  )

  return (
    <Popover.Root open={isOpen} onOpenChange={onVisibilityChange}>
      <Popover.Trigger asChild>
        <button
          data-cy="countryselector-dropdown"
          data-testid="countryselector-dropdown"
          className={`
              flex flex-row items-center gap-2
              ${
                variant === CountrySelectorVariant.tertiary
                  ? ''
                  : 'w-fit-content'
              }
              py-2 px-4
              rounded-sm
              border border-solid border-white
              hover:bg-stroke-darkbg-medium
              text-body-x-small text-white
              ${size === CountrySelectorSize.medium ? 'gap-3 py-2.5 px-6' : ''}
              ${getParentElementVariantStyle(variant)}
              ${classnames(styles.parentComponent)}
              ${className}
            `}
        >
          {!shouldDisplayCountryName ? (
            <>
              <span className={'sr-only'}>
                {formatMessage({ id: 'countrySelector.countryRegion' })}{' '}
              </span>
              <CountryShortDisplay countryCode={selectedCountry} />
            </>
          ) : (
            <CountryDisplay countryCode={selectedCountry} variant={variant} />
          )}
          <ChevronDown
            className={`
                  w-4 h-4
                  border-stroke-darkbg-medium
                  ${isOpen ? 'transform rotate-180' : ''}
                  ${styles.openIcon}
                  ${
                    variant === CountrySelectorVariant.tertiary
                      ? 'text-black-0'
                      : ''
                  }
                `}
            aria-label="Open dropdown"
          />
        </button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          align={displayPosition === 'right' ? 'start' : 'end'}
          className={`relative ${
            isMainSiteSwitcher
              ? 'bg-white pb-4 rounded-sm shadow-elevation-5'
              : ''
          }`}
          sideOffset={6}
        >
          <CountryLanguageSelector
            confirmOnSelect
            shouldDisplayCountryName={shouldDisplayCountryName}
            variant={variant}
            persistSelection={persistSelection}
            countries={countries}
            excludedCountries={excludedCountries}
            isMainSiteSwitcher={isMainSiteSwitcher}
            withLanguageSelector={withLanguageSelector}
            onChange={onChange}
            onChangeIsOpenCallback={onChangeIsOpenCallback}
            selectedCountry={selectedCountry}
            onChangeSelectedCountryCallback={onChangeSelectedCallback}
            countrySelectorOverrides={{
              title: formatMessage({ id: 'countrySelector.dropdownTitle' }),
              searchPlaceholder: formatMessage({
                id: 'countrySelector.searchPlaceholder',
              }),
            }}
            languageSelectorOverrides={{
              label: 'Language selector',
              labelClassName:
                'text-lg text-black-0 pl-4 tracking-normal text-heading-x-small block',
              inputWrapperClassName: 'mx-4 font-semibold outline-blue-40',
              menuClassName: 'ml-4 min-w-[20.9375rem] text-black-0',
            }}
          />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}

export { CountrySelectorDropdown }
