import React, { ReactNode } from 'react'

export enum SWITCH_SIZES {
  small = 'small',
  regular = 'regular',
}

interface BaseSwitchProps
  extends Omit<React.HTMLProps<HTMLInputElement>, 'size' | 'type' | 'label'> {
  size?: keyof typeof SWITCH_SIZES
  errorText?: string
  label?: ReactNode
  labelOnTheLeft?: boolean
  SwitchUi: ReactNode
  styles?: {
    readonly [key: string]: string
  }
  type?: 'checkbox' | 'radio'
  dataCy?: string
  controlled?: boolean // if true, `checked` parameter will be controlled by React state
}

export type { BaseSwitchProps }
