import type { ProductIdentifiersRecord } from '../product-identifiers/get-product-identifiers.type'
import type { SeoProps } from '../seo-props.type'
import type {
  CrossSellRecommendations,
  DmdOverride,
  Product,
} from '@abcam-web/search/public/utilities/product.type'

export enum ProductTabOptions {
  OVERVIEW = 'overview',
  DATASHEET = 'datasheet',
  IMAGES = 'images',
  SUPPORT = 'support',
}

export const TAB_INDEX_MAP: Record<number, ProductTabOptions> = {
  0: ProductTabOptions.OVERVIEW,
  1: ProductTabOptions.DATASHEET,
  2: ProductTabOptions.IMAGES,
  3: ProductTabOptions.SUPPORT,
}

export type ProductTab = `${ProductTabOptions}`

export type ProductDetailPageProps = SeoProps & {
  referer: string
  product: Product
  dmdOverride?: DmdOverride
  isChina?: boolean
  productIdentifiers?: ProductIdentifiersRecord | null
  crossSell: CrossSellRecommendations | null
}
