import { POSTCODE_REGEXES } from '../data'
import { BaseAddressSchema } from './base'

export const AUAddressSchema = {
  ...BaseAddressSchema,

  state: {
    type: 'string',
    required: true,
    oneOf: ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'],
  },
  postcode: {
    type: 'string',
    required: true,
    // combining all regexes in logical OR
    pattern: new RegExp(
      POSTCODE_REGEXES['AU'].map((exp) => `^${exp}$`).join('|'),
      'i'
    ),
  },
} as const

export type AUAddressSchemaType = typeof AUAddressSchema

export default AUAddressSchema
