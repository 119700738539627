export enum GTMPageType {
  HOMEPAGE = 'Homepage',
  REVIEWS = 'Reviews',
  ENTITY = 'Entity',
  PDP = 'PDP',
  SEARCH = 'Search',
  CATEGORY_RESULTS_PAGE = 'CRP', // Deprecated: this is now a PageSubtype
  SEARCH_RESULTS_PAGE = 'SRP', // Deprecated: this is now a PageSubtype
  FULL_DATASHEET = 'Datasheet',
  QUICK_VIEW = 'Quick view',
  NOT_A_PAGE = 'Not a page',
  CONTACT_US = 'Contact us',
  CONTENT = 'Content',
  AUTHENTICATION = 'Authentication',
  ACCOUNT = 'Account',
  CHECKOUT = 'Checkout',
  ABOUT = 'About',
  LEGAL = 'Legal',
  OFFERS_AND_INITIATIVES = 'Offers and initiatives',
  PRODUCT_CATEGORY_LP = 'Product Category landing page',
  TECHNICAL_RESOURCES = 'Technical resources',
  CUSTOMER_ACCOUNT = 'customer account',
}

export enum CtaText {
  ADD_TO_BASKET = 'Add to basket',
  ADD_TO_INQUIRY_BASKET = 'Add to inquiry basket',
  BUY = 'Buy',
  INQUIRE = 'Inquire',
}

export enum EventName {
  VIRTUAL_SCROLL_DEPTH = 'virtual_scroll_depth',
  COPY_TITLE_CLICK = 'pdp_copy_title_click',
  SELECT_ITEM = 'select_item',
  VIEW_ITEM_LIST = 'view_item_list',
  VIEW_SEARCH_RESULTS = 'view_search_results',
  BETA_BANNER_LINK_CLICK = 'beta_banner_link_click',
  ABREVIEWS_REVIEWS_CLICK = 'abreviews_reviews_click',
  WEB_ENGAGEMENT = 'web_engagement',
  LINK_CLICK = 'link_click',
  OPEN_DRAWER = 'Open Drawer',
  SEARCH_EXECUTED = 'Search Executed',

  VIRTUAL_PAGEVIEW = 'Virtual Page View',
  /**
   * Indicates that a Punchout session has been started immediately after successful authentication.
   */
  PUNCHOUT_SESSION_STARTED = 'punchout_session_started',
  /**
   * Indicates that a Punchout session has been started in Browse web app.
   */
  PUNCHOUT_SESSION_STARTED_IN_BROWSE = 'punchout_session_started_in_browse',
  PLACE_ORDER = 'place_order',
  CARD_PAYMENT_OUTCOME = 'card_payment_outcome',
  MODAL = 'modal',
  USER_SESSION_STARTED = 'user_session_started',
  USER_SESSION_LOGGED_OUT = 'user_session_logged_out',
  TOGGLE_SWITCH = 'toggle_switch',
  ERROR = 'error',
}

export enum GTMListType {
  ONSITE_SEARCH = 'Onsite search',
}

export enum ContentType {
  PRODUCT_CODE = 'product code',
  PRODUCT_NAME = 'product name',
}

export enum LinkClasses {
  TOGGLE = 'Toggle',
  LEARN_MORE = 'Learn more',
}

export enum ClickId {
  ADD_TO_BASKET = 'Add to basket',
  ITEM_SIZE = 'item size',
  QUANTITY = 'quantity',
  SELECT_PURCHASING_ORGANISATION = 'select purchasing organization',
  BACK_TO_BASKET = 'back to shopping basket',
  BACK_TO_SHIPPING = 'back to shipping details',
  BACK_TO_BILLING = 'back to billing details',
  CANCEL_AND_GO_TO_BASKET = 'cancel and go to basket',
  CHANGE_PURCHASING_ORGANISATION = 'change purchasing organisation',
  EDIT_BILLING_DETAILS = 'edit billing details',
  EDIT_SHIPPING_DETAILS = ' edit shipping details',
  PROCEED_TO_BILLING = 'proceed to billing details',
  PROCEED_TO_REVIEW_PAY = 'proceed to review & place order',
  SELECT_ADDRESS = 'Select address',
  SELECT_CONTACT = 'Select contact',
  SELECT_DELIVERY_OPTION = 'Select delivery option',
  UPDATE_FAO = 'update FAO',
  UPDATE_FIRST_NAME = 'update first name',
  UPDATE_LAST_NAME = 'update last name',
  UPDATE_EMAIL = 'update email',
  UPDATE_COUNTRY_CODE = 'update country code number',
  UPDATE_PHONE = 'update telephone number',
  UPDATE_VAT = 'Update VAT',
  CANCEL_CHECKOUT = 'cancel checkout',
  ADD_ADDITIONAL_EMAIL = 'add additional email',
  UPDATE_DELIVERY_NOTES = 'update delivery notes/instructions',
  BILLING_NOTES_INSTRUCTIONS = 'billing notes/instructions',
  ANCHOR_LINKS = 'Anchor links',
  CONTENT_CTA = 'Content CTA',
  OTP = 'OTP authentication success',
  MY_ACCOUNT = 'My account',
  ADDRESS_BOOK = 'Address book',
  ORDERS = 'Orders',
  PROFILE = 'Profile',
  REVIEWS = 'Reviews',
  REWARD_POINTS = 'Reward points',
  INQUIRIES = 'Inquiries',
  LOGOUT = 'Logout',
  SIGNIN = 'Signin',
  CREATE_ACCOUNT = 'Create an account',
  GEOLOCATION = 'Geolocation modal',
  PROCEED_TO_CHECKOUT = 'proceed to checkout',
  SIGN_IN_TO_CHECKOUT = 'sign in to checkout',
  VIEW_BASKET = 'view basket',
  QUICK_ADD = 'quick add',
  REMOVE_FROM_CART = 'remove from cart',
  CLOSE_QUICK_ADD = 'close quick add',
  APPLY_DISCOUNT_CODE = 'apply discount code',
  CHECKOUT = 'checkout',
  LEARN_HOW_TO_EARN_POINTS = 'learn how to earn points',
  REDEEM_REWARD_POINTS = 'redeem reward points',
  COPY_DISCOUNT_CODE = 'copy discount code',
  WRITE_A_REVIEW = 'write a review',
  ABREVIEWS = 'abreviews',
  RATING = 'rating',
  READ_FULL_REVIEW = 'read full review',
  WRITE_REVIEW_NEXT = 'write a review next',
  SUBMIT_REVIEW = 'submit review',
  SELECT_LOT_NUMBER = 'select lot number',
  SELECT_ORDER_NUMBER = 'select order number',
  SELECT_RATING = 'select rating',
  SELECT_INCONCLUSIVE = 'select inconclusive',
  SELECT_AGREE_TO_TERMS_AND_SERVICE = 'agree to terms and service',
  SELECT_SHOW_MY_NAME = 'show my name',
  CONSIDER_THIS_ALTERNATIVE = 'Consider this alternative',
}

export enum LocationID {
  PAGE = 'page',
  POPUP = 'popup',
  DRAWER = 'drawer',
  WIDGET = 'widget',
  OVERLAY = 'overlay',
  FOOTER = 'footer',
  PDP = 'PDP',
  PDP_DRAWER = 'PDP Drawer',
  SRP_DRAWER = 'SRP Drawer',
  MAIN_NAV = 'main navigation',
}

export enum ToggleAction {
  OPEN = 'open',
  CLOSE = 'close',
}

export enum PageSubtype {
  DATA_SHEET = 'Datasheet',
  FLOATER = 'Floating basket',
  CATEGORY_RESULTS_PAGE = 'CRP',
  SEARCH_RESULTS_PAGE = 'SRP',
  ZERO_SEARCH = 'Zero Search Results',
  OVERVIEW = 'Overview',
  HEADER = 'Header',
  UNPUBLISHED = 'Unpublished',
  QUICK_VIEW = 'Quick view',
  SUPPORT = 'Support',
  OVERLAY = 'Overlay',
  REWARD_POINTS = 'Reward points',
  BASKET = 'Basket',
  PRIVATE_POLICY = 'Privacy policy',
  COOKIE_POLICY = 'Cookie policy',
  TERMS_AND_CONDITIONS = 'Terms and conditions',
  MODERN_SLAVERY_STATEMENT = 'modern slavery statement',
  PRODUCT_PROMISE = 'Product promise',
  PRODUCT_REVIEWS = 'Product reviews',
  TRIAL_PROGRAM = 'Trial program',
  APPLICATIONS = 'Applications',
  GUIDES = 'Guides',
  PATHWAYS = 'Pathways',
  PROTOCOLS = 'Protocols',
  TROUBLESHOOTING = 'Troubleshooting',
  PRODUCT_OVERVIEW = 'Product overview',
  REVIEW_AND_PAY = 'Review and pay',
  PAYMENT_PROCESSING = 'Payment processing',
  LOGIN = 'Login',
  REGISTER = 'Register',
  MY_ORDERS = 'My Orders',
  MY_ACCOUNT = 'my account',
  SHIPPING_DETAILS = 'shipping details',
  BILLING_DETAILS = 'billing details',
  MY_REWARDS = 'my rewards points',
  MY_REVIEWS = 'my reviews',
  SUBMIT_REVIEW = 'Submit Review',
  ADDRESS_BOOK = 'Address book',
  IMAGES = 'Images',
}

export enum DrawerType {
  QUICK_VIEW = 'Quickview',
  PDP = 'PDP',
  MERCHANDISING = 'Merchandising',
  REVIEWS = 'Reviews',
  PRODUCT_FEATURES = 'Product features',
  PRODUCT_TAGS = 'Product tags',
  EMAIL_US = 'Email us',
  CALL_US = 'Call us',
  FIND_DISTRIBUTORS = 'Find distributors',
  CHAT_WITH_US = 'Chat with us',
  CUSTOMER_ACCOUNTS = 'customer accounts',
  FLOATING_BASKET = 'floating basket',
}

export enum DrawerSubtype {
  PRODUCT = 'Product',
  PUBLICATIONS = 'Publications',
  REVIEWS_OVERVIEW = 'Reviews overview',
  REVIEW_DETAILS = 'Review details',
  FORMULATIONS_CONJUGATIONS = 'Formulations & conjugations',
  RECOMMENDED_PRODUCTS = 'Recommended products',
  RECENTLY_VIEWED = 'Recently viewed',
  PRODUCT_HIGHLIGHTS = 'Product highlights',
  UNPUBLISHED_PRODUCT_REPLACEMENT = 'Unpublished product replacement',
  HIGHLIGHTS = 'Highlights',
  HAZARDS = 'Hazards',
  MY_ACCOUNT = 'my account',
  SIGN_IN = 'sign in',
  DIRECT_REPLACEMENT = 'Direct replacement',
  ABCAM_RECOMMENDS = 'Abcam recommends',
}
