/* eslint-disable @next/next/no-img-element */

import { useState } from 'react'
import { useWelcomeBanner } from './use-welcome-banner'
import styles from './welcome-banner.module.css'

export const WelcomeBanner = ({ hidden }: { hidden: boolean }) => {
  const { visible, text, logoUrl } = useWelcomeBanner()
  const [logoCannotBeLoaded, setLogoCannotBeLoaded] = useState(false)

  if (!visible || hidden) {
    return null
  }

  const showLogo = !!logoUrl && !logoCannotBeLoaded

  return (
    <header
      className={`${styles.header} h-[80px] flex justify-between items-center pl-16 pr-8`}
      role="banner"
    >
      <p
        className="font-semibold font-body"
        dangerouslySetInnerHTML={{ __html: text ?? '' }}
      />
      {showLogo && (
        <div className="relative flex justify-center py-2 text-black bg-white rounded-lg min-w-[220px] h-[54px]">
          {/*
             Use vanilla `img` tag rather than Next's `Image`.  As Next needs either `width` and `height` or `fill` to be set,
             but we do not know it in advance. The images are coming from customers' configuration and is not part of static assets for Browse web app.
          */}
          <img
            alt="Customer's logo"
            aria-hidden
            loading="lazy"
            onError={() => setLogoCannotBeLoaded(true)}
            role="img"
            src={logoUrl}
          />
        </div>
      )}
    </header>
  )
}

WelcomeBanner.displayName = 'WelcomeBanner'
