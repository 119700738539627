/* eslint-disable @typescript-eslint/no-non-null-assertion */
export type EntityType = keyof typeof entityTypeMap
export type EntityName = (typeof entityTypeMap)[EntityType]

/**
 * mapping of API entity types to entity names used on the UI
 */
const entityTypeMap = {
  application: 'applications',
  reactiveSpecies: 'reactive-species',
  target: 'targets',
  speciesOfOrigin: 'species-of-origin',
  hostSpecies: 'host-species',
  targetSpecies: 'target-species',
} as const

export const mapTypeToEntityName = (type: EntityType): EntityName =>
  entityTypeMap[type]

export const mapEntityNameToType = (entityName: EntityName) =>
  Object.entries(entityTypeMap)
    // refine the type, since `Object.entries` doesn't recognize keys of
    // a readonly object as a string literal union
    .map(
      ([type, name]) =>
        [type, name] as [keyof typeof entityTypeMap, typeof name]
    )
    .find(([_, name]) => name === entityName)![0]

/**
 * Type guard to check if a given value is of the supported entity types
 */
export const isEntityType = (
  maybeEntityType: unknown
): maybeEntityType is EntityType =>
  !!entityTypeMap[maybeEntityType as EntityType]

/**
 * Type guard to check if a given value is of the recognized entity names
 */
export const isEntityName = (
  maybeEntityName: unknown
): maybeEntityName is EntityName => {
  return (
    Object.values(entityTypeMap).findIndex(
      (name) => name === maybeEntityName
    ) >= 0
  )
}
