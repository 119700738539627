import { useEffect } from 'react'

export const useDisableBodyScroll = (open: boolean) => {
  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'unset'
    document.body.style.touchAction = open ? 'none' : 'unset'
    const root = document.getElementById('__next')
    if (root) {
      root.style.overflow = open ? 'hidden' : 'unset'
    }

    return () => {
      document.body.style.overflow = 'unset'
      document.body.style.touchAction = 'unset'
      if (root) {
        root.style.overflow = 'unset'
      }
    }
  }, [open])
}
