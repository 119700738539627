import type {
  FacetInput,
  ProductsSearchResults,
} from '@abcam-web/shared/data-access/search-schema'
import { EventName, GTMPageType, PageSubtype } from './gtm.enums'
import type { SearchExecutedEvent } from './gtm.types'

const getSrpAvailableFilters = (searchResults: ProductsSearchResults) => {
  return searchResults.facets
    .map((filter) => {
      const values = filter.values.map((v) => `"${v.label}"`).join(',')
      const res = `${filter.type}: ${values}`
      return res
    })
    .join(' | ')
}

const getSearchResultParams = (
  searchResults?: ProductsSearchResults | number
) => {
  if (!searchResults || typeof searchResults === 'number')
    return {
      srp_available_filters: null,
      srp_available_filters_total: null,
      search_number_of_results:
        typeof searchResults === 'number' ? searchResults : null,
      visible_products: null,
    }

  const searchResultsParams = {
    srp_available_filters: getSrpAvailableFilters(searchResults),
    srp_available_filters_total: searchResults.facets.reduce(
      (acc, cv) => acc + cv.values.length,
      0
    ),
    search_number_of_results: searchResults.header.count,
    visible_products: searchResults.items
      .map(({ productCode }) => productCode)
      .join(','),
  }
  return searchResultsParams
}

const getSearchQueryParams = (searchQuery: SearchQuery) => {
  const facetByType = (type: string) => {
    return searchQuery.facets
      .filter((f) => f.type === type)
      .map((f) => f.label)
      .join(',')
  }
  const facetsAsString = searchQuery.facets
    .map((facet) => facet.label)
    .join('|')
  const searchQueryParams = {
    search_number_of_entities: searchQuery.facets.length,
    search_free_text: searchQuery.keywords,
    search_category: facetByType('categoryType'),
    search_target_name: facetByType('target'),
    search_application: facetByType('application'),
    search_reactive_species: facetByType('reactiveSpecies'),
    search_host_species: facetByType('hostSpecies'),
    srp_sorting_used: searchQuery.sort || null,
    search_input_order: `${facetsAsString}+${searchQuery.keywords}`,
  }
  return searchQueryParams
}

type SearchQuery = {
  facets: FacetInput[]
  sort?: string
  sortOrder?: string
  keywords: string
}

export const getSearchExecutedParameters = (
  pagePath: string,
  pageSubType: PageSubtype,
  searchQuery: SearchQuery,
  searchResults?: ProductsSearchResults | number,
  searchCount: number | null = null
): SearchExecutedEvent => {
  const searchResultsParams = getSearchResultParams(searchResults)
  const searchQueryParams = getSearchQueryParams(searchQuery)
  return {
    event: EventName.SEARCH_EXECUTED,
    page_path: pagePath,
    page_type: GTMPageType.SEARCH,
    page_subtype: pageSubType,
    search_count: searchCount,
    ...searchResultsParams,
    ...searchQueryParams,
  }
}
