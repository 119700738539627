import { POSTCODE_REGEXES } from '../data'
import { BaseAddressSchema } from './base'

export const KRAddressSchema = {
  ...BaseAddressSchema,

  province: {
    type: 'string',
    required: true,
    oneOf: [
      /*
      Because in historical addresses we have a mix of English and Korean language (~90% being English),
      we need to make sure the old addresses are supported, and also to be future proof, should someone put the KR address in SalesCloud.
      Note though, that because we want to be English-first, we put English version of the province earlier in the list,
      in such way encouraging customers to naturally select English variant.
      */
      'GYEONGGI-DO',
      'GANGWON-DO',
      'CHUNGCHEONGBUK-DO',
      'CHUNGCHEONGNAM-DO',
      'JEOLLABUK-DO',
      'JEOLLANAM-DO',
      'GYEONGSANGBUK-DO',
      'GYEONGSANGNAM-DO',
      'JEJU-DO',
      '강원도',
      '경기도',
      '제주특별자치도',
      '충청북도',
      '경상북도',
      '전라북도',
      '충청남도',
      '경상남도',
      '전라남도',
    ],
  },

  postcode: {
    type: 'string',
    required: true,
    // combining all regexes in logical OR
    pattern: new RegExp(
      POSTCODE_REGEXES['KR'].map((exp) => `^${exp}$`).join('|'),
      'i'
    ),
  },
} as const

export type KRAddressSchemaType = typeof KRAddressSchema

export default KRAddressSchema
