import { STAGE } from '@abcam-web/auth-shared/constants/stage-type'
import axios from 'axios'
import { getProxyGatewayHost } from '@abcam-web/auth-shared/aws-cognito/util/get-proxy-gateway-host'
import {
  getTokensFromLocalStorage,
  refreshTokensInLocalStorage,
  removeAuthTokensFromLocalStorage,
} from '@abcam-web/shared/utilities/auth'

export const getCurrentAuthenticatedUser = async (stage: STAGE) => {
  const proxyGatewayHost = getProxyGatewayHost[stage]
  const currentTokens = getTokensFromLocalStorage(stage)
  const getAuthUserUrl = `${proxyGatewayHost}/authentication/current-authenticated-user`

  const response = await axios.post(
    getAuthUserUrl,
    {
      ...(currentTokens?.refreshToken && {
        refreshToken: currentTokens.refreshToken,
        accessToken: currentTokens.accessToken,
        idToken: currentTokens.idToken,
      }),
    },
    {
      headers: {
        'x-abcam-app-id': 'b2c-public-website',
      },
    }
  )
  const { tokens, ...user } = response.data
  if (!tokens?.accessToken) {
    // if tokens object is empty, then new token management is not enabled, so we should remove tokens from storage if they exist
    removeAuthTokensFromLocalStorage(stage)
  }
  if (
    tokens?.accessToken &&
    tokens?.accessToken !== currentTokens?.accessToken
  ) {
    // if token changed, then update local storage
    refreshTokensInLocalStorage(stage, tokens, user.email)
  }

  return user
}
