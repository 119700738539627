import { ErrorCodes, FieldSchema, FieldType } from '../types/schema'

export type ValidateField = (
  schema: FieldSchema,
  input?: FieldType
) => ErrorCodes[]

export const validateField: ValidateField = (schema, input) => {
  const errors: ErrorCodes[] = []
  const isMissing = input === undefined || input === null || input === ''

  if (schema.required && isMissing) {
    return ['error-required']
  }

  // if it's not required, it's not an error if it's missing
  if (isMissing) {
    return []
  }

  if (schema.type === 'string' && typeof input !== 'string') {
    errors.push('error-type')
  }

  if (schema.type === 'number' && typeof input !== 'number') {
    errors.push('error-type')
  }

  if (schema.type === 'boolean' && typeof input !== 'boolean') {
    errors.push('error-type')
  }

  if (schema.type === 'number') {
    if (schema.minValue && (input as number) < schema.minValue) {
      errors.push('error-min-length')
    }

    if (schema.maxValue && (input as number) > schema.maxValue) {
      errors.push('error-max-length')
    }
  }

  if (schema.type === 'string' && typeof input === 'string') {
    if (schema.oneOf && !schema.oneOf.includes(input)) {
      errors.push('error-one-of')
    }
    if (schema.minLength && input.length < schema.minLength) {
      errors.push('error-min-length')
    }

    if (schema.maxLength && input.length > schema.maxLength) {
      errors.push('error-max-length')
    }

    if (schema.format && schema.format === 'email') {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input)) {
        errors.push('error-format-email')
      }
    }

    if (schema.format && schema.format === 'phone') {
      if (!/^\d{3}-\d{3}-\d{4}$/.test(input)) {
        errors.push('error-format-phone')
      }
    }

    if (schema.format && schema.format === 'date') {
      if (!/^\d{4}-\d{2}-\d{2}$/.test(input)) {
        errors.push('error-format-date')
      }
    }

    if (schema.pattern && !schema.pattern.test(input)) {
      errors.push('error-pattern')
    }
  }

  return errors
}
