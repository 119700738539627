import style from './style.module.css'

type CustomValidationMessageProps = {
  htmlElement: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  errorText?: string
  errorObject?: Record<string, string>
}

const CustomValidationMessage = ({
  htmlElement,
  errorText,
  errorObject,
}: CustomValidationMessageProps) => {
  const defaultMessage = htmlElement.validationMessage

  if (!defaultMessage) {
    return null
  }
  let message = errorText === undefined ? defaultMessage : errorText

  if (errorObject) {
    const errorKeys = Object.keys(errorObject)
    const errorItem = errorKeys.find(
      (key) => htmlElement.validity[key as keyof ValidityState]
    )
    message = errorItem ? errorObject[errorItem] : defaultMessage
  }

  return (
    <div data-testid="simple-text-validation-message" className={style.wrapper}>
      {message}
    </div>
  )
}

export { CustomValidationMessage }
