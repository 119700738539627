import { FocusEvent, FocusEventHandler, useCallback, useRef } from 'react'

// PLEASE NOTE:
// because of this long standing Safari bug, focus events are not triggered on buttons
// https://itnext.io/fixing-focus-for-safari-b5916fef1064??

// the mouseup event listener below is used to account for this bug

interface FocusBoundaryProps {
  children: React.ReactNode
  onFocusIn?: (event: FocusEvent<HTMLDivElement, Element>) => void
  onFocusOut?: (event: FocusEvent<HTMLDivElement, Element> | MouseEvent) => void
  className?: string
}

if (typeof window !== 'undefined') {
  window.addEventListener(
    'mouseup',
    (event) => {
      const target = event.target as HTMLElement
      target.closest('button')?.focus()
    },
    { capture: true }
  )
}

const FocusBoundary = ({
  children,
  onFocusIn,
  onFocusOut,
  className,
}: FocusBoundaryProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const isInside = useRef(false)

  const setIsInside = useCallback((state: boolean) => {
    isInside.current = state
  }, [])

  const handleBlur: FocusEventHandler<HTMLDivElement> = useCallback(
    (event: FocusEvent<HTMLDivElement, Element>) => {
      if (!wrapperRef.current) return

      // If focus is still in the component, do nothing
      if (wrapperRef.current.contains(event.relatedTarget)) return

      //otherwise
      if (!isInside.current) {
        // the focus is outside the component: trigger the callback
        onFocusOut?.(event)
      } else {
        // the focus is still inside the component, but on an element that can't receive focus so we need
        // to move the focus to the wrapper element
        wrapperRef.current.focus()
      }

      // resetting the state
      setIsInside(false)
    },
    [onFocusOut, setIsInside]
  )
  const handleFocus: FocusEventHandler<HTMLDivElement> = useCallback(
    (event: FocusEvent<HTMLDivElement, Element>) => {
      if (!wrapperRef.current) return
      if (
        !event.relatedTarget ||
        wrapperRef.current.contains(event.relatedTarget) === false
      ) {
        onFocusIn?.(event)
      }
    },
    [onFocusIn]
  )

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      ref={wrapperRef}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onMouseDown={() => setIsInside(true)}
      onMouseUp={() => setIsInside(false)}
      className={className}
      tabIndex={-1}
    >
      {children}
    </div>
  )
}

export { FocusBoundary }
export type { FocusBoundaryProps }
