import { Dropdown } from '@lego/ui/dropdown'
import cn from 'classnames'
import { PropsWithChildren } from 'react'

import { DropdownButton } from './buttons/buttons'
import { DatePickerCalendar } from './date-picker-base'
import { Footer } from './footer/footer-variants'
import { ErrorLabel, InfoLabel } from './labels'
import { DatePickerProps } from './types'

export const DatePicker = ({
  disabled,
  dropdownOpen,
  toggleDropdown,
  dropdownButtonLabel,
  children,
  testTagPrefix,
}: PropsWithChildren<DatePickerProps>) => {
  return (
    <Dropdown
      displayPosition="right"
      isOpen={dropdownOpen}
      onVisibilityChange={toggleDropdown}
      parentElement={
        <DropdownButton isOpen={dropdownOpen} label={dropdownButtonLabel} />
      }
      cy={`${testTagPrefix}-date-picker`}
      dropdownContentClassName="z-search"
    >
      <div className="inline-block p-6 bg-white rounded w-96 shadow-elevation-5">
        <div
          className={cn('inline-block w-full', {
            'pointer-events-none': disabled,
            'opacity-30': disabled,
          })}
        >
          {children}
        </div>
      </div>
    </Dropdown>
  )
}

DatePicker.Calendar = DatePickerCalendar
DatePicker.InfoLabel = InfoLabel
DatePicker.ErrorLabel = ErrorLabel
DatePicker.Footer = Footer
