import { FC, PropsWithChildren } from 'react'

import {
  CancelButton,
  CloseButton,
  ResetButton,
  SaveButton,
} from '../buttons/buttons'
import { ButtonsCallbacks, ButtonsLabels, DatePickerStatus } from '../types'

const FooterWrapper: FC<PropsWithChildren> = ({ children }) => (
  <div className="flex justify-end mt-6 gap-3">{children}</div>
)

const InitialFooter: FC<ButtonsCallbacks & ButtonsLabels> = ({
  onClose,
  closeLabel,
}) => (
  <FooterWrapper>
    <CloseButton label={closeLabel} onClick={onClose} />
  </FooterWrapper>
)

const PendingFooter: FC<ButtonsCallbacks & ButtonsLabels> = ({
  onCancel,
  onSave,
  cancelLabel,
  saveLabel,
}) => (
  <FooterWrapper>
    <CancelButton label={cancelLabel} onClick={onCancel} />
    <SaveButton label={saveLabel} onClick={onSave} />
  </FooterWrapper>
)

const ErrorFooter: FC<ButtonsCallbacks & ButtonsLabels> = ({
  onClose,
  closeLabel,
}) => (
  <FooterWrapper>
    <CloseButton label={closeLabel} onClick={onClose} />
  </FooterWrapper>
)

const DoneFooter: FC<ButtonsCallbacks & ButtonsLabels> = ({
  onReset,
  onClose,
  resetLabel,
  closeLabel,
}) => (
  <FooterWrapper>
    <ResetButton label={resetLabel} onClick={onReset} />
    <CloseButton label={closeLabel} onClick={onClose} />
  </FooterWrapper>
)

export const footerVariants: Record<
  DatePickerStatus,
  FC<ButtonsCallbacks & ButtonsLabels>
> = {
  initial: InitialFooter,
  pending: PendingFooter,
  error: ErrorFooter,
  done: DoneFooter,
}

export const Footer: FC<{
  status: DatePickerStatus
  buttonsCallbacks: ButtonsCallbacks
  buttonsLabels: ButtonsLabels
}> = ({ status, buttonsCallbacks, buttonsLabels }) => {
  const FooterVariant = footerVariants[status]
  return <FooterVariant {...buttonsCallbacks} {...buttonsLabels} />
}
