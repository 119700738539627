import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { CheckboxCheckedIcon, CheckIcon } from '@lego/ui/icons'
import styles from './checkList.module.css'
import { localisedValidationRule } from '@abcam-web/shared/utilities/password-validation'
import { Notification, NOTIFICATION_VARIANT } from '@lego/ui/notification'
import { tags } from '@tagging-shared/authentication'

interface ChecklistProps {
  rules: localisedValidationRule[]
  password?: string
  dataCy?: string
  validationSuccessMessage?: string
}

const CheckList = React.memo(
  ({ password, rules, dataCy, validationSuccessMessage }: ChecklistProps) => {
    const [passwordCriteriaMet, setPasswordCriteriaMet] =
      useState<boolean>(false)

    useEffect(() => {
      const allPasswordCriteriaMet = rules.every((rule) => {
        return !!password && rule.pattern.test(password)
      })

      if (allPasswordCriteriaMet) {
        setPasswordCriteriaMet(true)
      } else {
        setPasswordCriteriaMet(false)
      }
    }, [rules, password])

    return (
      <>
        {passwordCriteriaMet && validationSuccessMessage && (
          <Notification
            variant={NOTIFICATION_VARIANT.positive}
            dataCy={tags.checkList.notification}
            centered
            icon={<CheckIcon />}
          >
            {validationSuccessMessage}
          </Notification>
        )}
        {!passwordCriteriaMet && (
          <div
            className={styles.checklist}
            data-testid="checklist"
            data-cy="checklist-ruleset"
          >
            {rules.map((rule, index) => {
              const isValid = !!password && rule.pattern.test(password)
              const classes = cn(styles.checklistItem, {
                [styles.checklistItemChecked]: isValid,
                checked: isValid,
              })

              return (
                <div
                  key={`rule_${index}`}
                  className={classes}
                  data-testid="checklist-rule"
                  data-cy={`checklist-rule-${index}`}
                >
                  <p>
                    {isValid && <CheckboxCheckedIcon />}
                    {rule.label}
                  </p>
                </div>
              )
            })}
          </div>
        )}
      </>
    )
  }
)

CheckList.displayName = 'CheckList'

export { CheckList }
