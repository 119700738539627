import { useShoppingExperience } from '@abcam-web/shared/ecommerce/utilities'
import cn from 'classnames'
import { useRouter } from 'next/router'
import { AppHeader } from '../app-header/app-header'
import { LaunchSearchOverlayButtonWithSearchInfo } from '@abcam-web/search/search-overlay'
import { useEnvironment } from '@abcam-web/shared/utilities/environment'
import { tags } from '@tagging-shared/browse'
import { useIntl } from 'react-intl'
import { MegaNavDesktop } from '../mega-nav/desktop/mega-nav-desktop'
import type { HeaderProps } from './header.type'

const Header = ({ navigation }: HeaderProps) => {
  const router = useRouter()
  const { STAGE } = useEnvironment()
  const { ui } = useShoppingExperience()
  const { formatMessage } = useIntl()
  const accountsRootHref = '/my-account'
  const reviewsRootHref = '/reviews'
  const isAccountsPage = router.basePath.includes(accountsRootHref)
  const isReviewsAppPage = router.basePath.includes(reviewsRootHref)
  const isTrackOrderPage = router.pathname === '/track-order'
  const isRootPage = router.pathname === '/'
  const allowedPage =
    isRootPage || isAccountsPage || isTrackOrderPage || isReviewsAppPage

  const hideCountrySelector = () => {
    if (isAccountsPage || isTrackOrderPage) {
      return true
    }
    // previous search home logic
    else if (isRootPage) {
      return !ui.showCountrySelector
    } else {
      // previous search header logic
      return (
        !ui.showCountrySelector ||
        ['/inquiry/[inquiry]'].includes(router.pathname)
      )
    }
  }

  const accountDropdown =
    // previous search home logic
    (allowedPage && ui.showAccountDropdown) ||
    // previous search header logic
    (!allowedPage && ui.showAccountDropdown)

  const hideSearchBar =
    [
      '/',
      '/inquiry/[inquiry]',
      'checkout',
      '/checkout/[checkout]',
      '/shipping-details',
      '/shipping-details/[shipping-details]',
      '/billing-details',
      '/billing-details/[billing-details]',
      '/review-and-pay',
      '/review-and-pay/[review-and-pay]',
    ].includes(router.pathname) ||
    isAccountsPage ||
    isTrackOrderPage ||
    isReviewsAppPage

  const cardPaymentPages = [
    '/payment-form',
    '/payment-processing',
    '/payment-failed',
  ]
  const shoppingBasketDropdown =
    ![
      '/shopping-basket',
      '/shopping-basket/[shopping-basket]',
      '/inquiry/[inquiry]',
      '/checkout/[checkout]',
      '/confirmation',
      '/shipping-details',
      '/shipping-details/[shipping-details]',
      '/billing-details',
      '/billing-details/[billing-details]',
      '/review-and-pay',
      '/review-and-pay/[review-and-pay]',
      ...cardPaymentPages,
    ].includes(router.pathname) && !isReviewsAppPage

  const hideNavigation = [
    '/checkout',
    '/checkout/[checkout]',
    '/shipping-details',
    '/shipping-details/[shipping-details]',
    '/billing-details',
    '/billing-details/[billing-details]',
    '/review-and-pay',
    '/review-and-pay/[review-and-pay]',
  ].includes(router.pathname)

  const appHeaderWrapperClassnames = cn({
    'gradient-border-b after:mb-0 after:h-0.5 py-4': allowedPage,
    'print:hidden': !allowedPage,
  })

  const appHeaderHeaderContentClassnames =
    'px-[30px] xlu:px-[80px] max-w-[2464px]'
  const searchBarPlaceHolder = formatMessage({
    id: 'searchOverlay.placeholder',
  })

  const isSticky = !!router.pathname.match(/(products.*productslug|\/search)/i)

  const SearchBar = () => {
    return (
      <LaunchSearchOverlayButtonWithSearchInfo
        placeHolder={searchBarPlaceHolder}
        className={`
          smd:hidden
          z-searchMiddle
          mx-4 w-full relative
          text-ui-large mdd:text-ui-small
        `}
        cyTag={tags.homePage.headerSearchInput}
        isCompactMode
      />
    )
  }

  const navigationAlignmentClassNames =
    'hidden mn_lg:flex xl:px-[30px] xlu:px-[80px] xl:justify-between xl:flex xl:flex-row xl:gap-6 xl:max-w-[2464px] xxxlu:mx-auto'

  return (
    <>
      <AppHeader
        accountDropdownStage={accountDropdown ? STAGE : undefined}
        hideCountrySelector={hideCountrySelector()}
        isSticky={isSticky}
        shoppingBasketDropdown={shoppingBasketDropdown}
        navigation={!hideNavigation ? navigation : null}
        classOverrides={{
          wrapper: appHeaderWrapperClassnames,
          headerContent: appHeaderHeaderContentClassnames,
        }}
        showSeoHeading={allowedPage}
        hideLogoMobile={!allowedPage}
        hideSearchBar={hideSearchBar}
      >
        {allowedPage && navigation ? (
          <MegaNavDesktop
            items={navigation}
            classOverrides={{ wrapper: 'w-full hidden mn_lg:flex' }}
            isOnHomepage={allowedPage}
          />
        ) : (
          !hideSearchBar && <SearchBar />
        )}
      </AppHeader>

      <div className="mdu:hidden gradient-border-b after:mb-0 after:h-0.5" />

      {!allowedPage && navigation && !hideNavigation && (
        <div className="bg-black-0">
          {/* Needed to align navigation to search bar */}
          <div
            className={cn(
              navigationAlignmentClassNames,
              'py-3 mdd:hidden gradient-border-b after:mb-0 after:h-0.5'
            )}
          >
            <div className="justify-start mn_lg:flex mn_lg:basis-1/4" />
            {/* Needed to align navigation to search bar */}
            <div className="w-full xl:px-2">
              {/* Needed to align navigation to search bar */}
              <MegaNavDesktop items={navigation} isOnHomepage={allowedPage} />
            </div>
            <div className="justify-end xl:flex xl:basis-1/4" />
            {/* Needed to align navigation to search bar */}
          </div>
        </div>
      )}
    </>
  )
}

export { Header }
