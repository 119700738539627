import Link from 'next/link'
import { FC } from 'react'

import styles from './product-restriction.module.css'

export interface ProductRestrictionProps {
  header: string
  message: string
  description: string
  recoveryLink?: string
  recoveryHint?: string
}

const ProductRestriction: FC<ProductRestrictionProps> = ({
  header,
  message,
  description,
  recoveryLink,
  recoveryHint,
}) => {
  return (
    <>
      <div className={styles.restrictionMessageContainer}>{header}</div>
      <div className={styles.productRestrictionContainer}>
        <div>
          <div data-cy="unavailable-in-region" className={styles.title}>
            {message}
          </div>
          <div className={styles.message}>{description}</div>
        </div>
        {recoveryLink && recoveryHint && (
          <div>
            <Link
              locale={false}
              href={recoveryLink}
              className="mt-5 text-blue-default"
            >
              {recoveryHint}
            </Link>
          </div>
        )}
        {!recoveryLink && recoveryHint && (
          <div className="mt-5 text-blue-default">{recoveryHint}</div>
        )}
      </div>
    </>
  )
}

export { ProductRestriction }
