import type { FC } from 'react'

type CountryFlagProps = {
  countryCode: string
  countryName?: string
  className?: string
}

const CountryFlag: FC<CountryFlagProps> = ({
  countryCode,
  countryName,
  className,
}) => {
  return (
    <img
      src={`/flags/${countryCode.toLowerCase()}.svg`}
      className={className}
      alt={countryName || countryCode}
      title={countryName || countryCode}
      // Disable OneTrust autoblock
      data-ot-ignore=""
    />
  )
}

export { CountryFlag }
