import { usePunchoutUser } from '@abcam-web/auth-shared/aws-cognito/hooks/usePunchoutUser'
import { User, useUser } from '@abcam-web/auth-shared/contexts/user'
import {
  breakpointsAsNumbers,
  useBreakpointListener,
} from '@abcam-web/lego/hooks/breakpoint-listener'
import { FeatureToggleName } from '@feature-toggles/feature-toggle-name.enum'
import { useFeatureToggle } from '@feature-toggles/use-feature-toggle.hook'
import { useCallback, useMemo } from 'react'
import { IntlShape, useIntl } from 'react-intl'

import { deleteBasketId, readBasketId } from '../use-basket-id'
import { NavigatorGoToOptions, useNavigator } from './use-navigator'

/**
 * Represents a service that determines the overall shopping experience and exposes
 * high-level methods to perform shopping actions.
 */
export type ShoppingService = {
  /**
   * Contains flags indicating whether specific UI features/elements are visible.
   */
  readonly ui: {
    /** Determines whether the account dropdown element is visible. */
    readonly showAccountDropdown: boolean
    /** Determines whether the country selector element is visible. */
    readonly showCountrySelector: boolean
    /** Determines whether the punchout specific Checkout button is visible. */
    readonly showPunchoutCheckoutButton: boolean
    /** Indicates whether tax calculations are visible in the basket. */
    readonly showTaxCalculations: boolean
    /** Indicates whether shipping information is visible in the basket. */
    readonly showShippingInfomation: boolean
    /** The label to use for the checkout button on floating basket and pdp product add confirmation modal.*/
    readonly checkoutButtonLabel: string
    /** The label to use for the checkout button on basket summary.*/
    readonly basketSummaryCheckoutButtonLabel: string
    /** Determines whether organisations fetching is done or not */
    readonly isFetchingOrganisations: boolean
    /** Determines whether showing the total amount in the summary*/
    readonly showTotal: boolean
  }

  /** Gets the client-side user data. */
  readonly user: User | undefined

  /**
   * Navigates to the specified page.
   * @param pathname - The path of the locale page you want to navigate to.
   * @param options - Optional options, set `protected` to `true` if the page can be accessed
   * only by authenticated users.
   */
  goTo(pathname: string, options?: NavigatorGoToOptions): void

  /** Performs a checkout. */
  checkout(options?: NavigatorGoToOptions): Promise<void>

  /** indicates if Punchout checkout is in progress */
  loading?: boolean
}

/** Represents a _scenario_ for `calculateVisibleFeatures()`. */
type ShoppingExperienceScenario = {
  /** Indicates whether the authentication is currently enabled. Default (if omitted) is `true`. */
  readonly authenticationIsEnabled?: boolean
  /** Indicates whether there is a currently logged in user. */
  readonly authenticated: boolean
  /**
   * Indicates whether the currently logged in user inside a Punchout session. This value cannot be
   * `true` if `authenticated` is `false`. Default value (if omitted) is `false`.
   */
  readonly isPunchoutUser?: boolean
  /**
   * Indicates whether organisationsForContact query is done or not
   * `true` if data is fetched and ready, and `false` if fetching is still in progress. Default value (if omitted) is `false`.
   */
  readonly isOrganisationsLoading?: boolean
}

/**
 * **Infrastructure**: do NOT use in your code. This function is intended to be used internally
 * by `useShoppingExperience()` hook and it's exposed only to simplify testing. If you're not writing
 * a test then you most definitely should use `useShoppingExperience()` instead.
 *
 * Calculates which features are visible/active for the user in the specified scenario.
 * @param scenario - The scenario for which you need to calculate the visibility of some UI features.
 * @returns The UI settings for the specified scenario.
 */
export function calculateVisibleFeatures(
  scenario: ShoppingExperienceScenario,
  formatMessage: IntlShape['formatMessage'],
  screenSizeXLD: boolean,
  screenSizeMDD: boolean
): ShoppingService['ui'] {
  const {
    authenticationIsEnabled = true,
    authenticated,
    isPunchoutUser = false,
    isOrganisationsLoading,
  } = scenario

  if (isPunchoutUser && !authenticated) {
    throw new Error('There cannot be an anonymous Punchout user')
  }

  return {
    showAccountDropdown: authenticationIsEnabled && !isPunchoutUser,
    showCountrySelector: !isPunchoutUser,
    showPunchoutCheckoutButton: isPunchoutUser,
    showTaxCalculations: !isPunchoutUser,
    showShippingInfomation: !isPunchoutUser,
    showTotal: !isPunchoutUser,
    checkoutButtonLabel: isPunchoutUser
      ? formatMessage({ id: 'floatingBasket.checkoutButtonLabel.punchout' })
      : authenticated
      ? formatMessage({
          id: 'floatingBasket.checkoutButtonLabel.authenticated',
        })
      : formatMessage({
          id: 'floatingBasket.checkoutButtonLabel.nonAuthenticated',
        }),
    basketSummaryCheckoutButtonLabel: isPunchoutUser
      ? formatMessage({ id: 'basketSummary.checkoutButton.punchout' })
      : authenticated && screenSizeMDD
      ? formatMessage({
          id: 'basketSummary.checkoutButton.authenticated.smallScreen',
        })
      : authenticated && screenSizeXLD
      ? formatMessage({
          id: 'basketSummary.checkoutButton.authenticated.mediumScreen',
        })
      : authenticated
      ? formatMessage({
          id: 'basketSummary.checkoutButton.authenticated.largeScreen',
        })
      : screenSizeMDD
      ? formatMessage({
          id: 'basketSummary.checkoutButton.nonAuthenticated.smallScreen',
        })
      : screenSizeXLD
      ? formatMessage({
          id: 'basketSummary.checkoutButton.nonAuthenticated.mediumScreen',
        })
      : formatMessage({
          id: 'basketSummary.checkoutButton.nonAuthenticated.largeScreen',
        }),
    isFetchingOrganisations: !!isOrganisationsLoading,
  }
}

/**
 * Returns an instance of the `ShoppingService`.
 *
 * This hook is intended to be used when rendering **client-side**.
 *
 * This service is used to determine which UI elements
 * are visible, which features are enabled, and to perform high-level actions (such as checkout)
 * without knowing the specific details (for example if an user is using Punchout or not).
 *
 * _Please use this whenever possible: having a single service we can easily mock (instead of multiple hooks)
 * should make writing unit tests easier (also setting up predefined "scenarios")._
 */
export function useShoppingExperience(): ShoppingService {
  const user = useUser()
  const navigator = useNavigator(user)
  const { isPunchoutUser, checkoutPunchoutBasket, loading } = usePunchoutUser(user)
  const { formatMessage } = useIntl()
  const isScreenSizeXLD = useBreakpointListener(breakpointsAsNumbers.xld, '<')
  const isScreenSizeMDD = useBreakpointListener(breakpointsAsNumbers.mdd, '<')
  const { enabled: authenticationIsEnabled } = useFeatureToggle(
    FeatureToggleName.EnableAuth
  )

  const ui = useMemo(
    () =>
      calculateVisibleFeatures(
        {
          authenticationIsEnabled,
          authenticated: !!user,
          isPunchoutUser,
        },
        formatMessage,
        isScreenSizeXLD,
        isScreenSizeMDD
      ),
    [
      authenticationIsEnabled,
      user,
      isPunchoutUser,
      formatMessage,
      isScreenSizeXLD,
      isScreenSizeMDD,
    ]
  )

  const goTo = useCallback(
    (pathname: string, options?: NavigatorGoToOptions) => {
      navigator.goTo(pathname, options)
    },
    [navigator]
  )

  const checkout = useCallback(
    async (options?: NavigatorGoToOptions) => {
      const id = readBasketId()
      if (isPunchoutUser) {
        if (!id) {
          throw new Error(
            'Checkout cannot be performed because the basket cannot be found'
          )
        }
        await checkoutPunchoutBasket(id, {
          onBeforeRedirect: () => deleteBasketId(),
        })
      } else {
        navigator.goTo(`/shipping-details/${id}`, {
          protected: true,
          ...options,
        })
      }
    },
    [isPunchoutUser, navigator, checkoutPunchoutBasket]
  )

  return useMemo(
    () => ({
      ui,
      user,
      goTo,
      checkout,
      loading,
    }),
    [ui, user, goTo, checkout, loading]
  )
}
