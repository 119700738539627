import { AlertContent, AlertProps } from './content/alertContent'
import { Overlay, OverlayProps } from './overlay'

type Props = OverlayProps & AlertProps

const Alert = (props: Props) => {
  const { show, ...rest } = props

  // passing onClose property makes this a non-modal overlay,
  // i.e. it can be closed by clicking outside of it (or using the escape key)
  return (
    <Overlay show={show} onClose={props.onOk}>
      <AlertContent {...rest} />
    </Overlay>
  )
}

export { Alert }
