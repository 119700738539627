export const editOrder = {
  payment: {
    prepayment: {
      container: 'edit-order-payment-prepayment',
      explaination: 'edit-order-payment-prepayment-explaination',
      terms: 'edit-order-payment-prepayment-terms',
    },
    container: 'edit-order-payment',
    terms: 'edit-order-payment-terms',
  },
  basket: {
    deliveryGroup: {
      information: 'edit-order-basket-delivery-group-information',
      estimatedDispatchDate:
        'edit-order-basket-delivery-group-estimated-dispatch-date',
    },
    item: {
      lineNumber: 'edit-order-basket-item-line-number',
      definitionNumber: 'edit-order-basket-item-definition-number',
      tagValue: 'edit-order-basket-item-tag-value',
      promotionCode: 'edit-order-basket-item-promotion-code',
      definitionName: 'edit-order-basket-item-definition-name',
    },
  },
  notifications: {
    priceAdjustmentApprovalNotification:
      'price-adjustment-approval-notification',
  },
}
