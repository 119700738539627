import type { PurityFragment } from '@abcam-web/shared/data-access/product-schema'

export type DescriptionListItemPropTypes = {
  className?: string
  definition?: React.ReactNode
  testTag?: string
  title: string
  hideSubtitle?: boolean
}

export const getPurityLabel = (
  purificationTechnique: string | null | undefined,
  purificationTechniqueReagent: string | null | undefined
) => {
  if (purificationTechnique || purificationTechniqueReagent) {
    return 'datasheet.purificationTechnique'
  }
  return 'common.purity'
}

export const renderPurity = (
  purity: PurityFragment['purity'] = []
): DescriptionListItemPropTypes => {
  const {
    purificationTechnique,
    purificationTechniqueReagent,
    purificationFraction,
  } = purity[0] || {}

  const purityLabel = getPurityLabel(
    purificationTechnique,
    purificationTechniqueReagent
  )

  const definition = getPurityDefinition(
    purificationFraction,
    purificationTechnique,
    purificationTechniqueReagent
  )

  return {
    definition,
    testTag: 'purity',
    title: purityLabel,
  }
}

export const getPurityDefinition = (
  purificationFraction?: string | null,
  purificationTechnique?: string | null,
  purificationTechniqueReagent?: string | null
): string | undefined => {
  const technique = purificationTechnique || ''
  const reagent = purificationTechniqueReagent || ''

  if (technique && reagent) {
    return `${technique} ${reagent}`
  } else if (technique || reagent) {
    return technique || reagent
  }

  return purificationFraction ? purificationFraction : undefined
}

export const getProteinPurity = (purity: PurityFragment['purity']) => {
  const purityValue = purity?.[0]?.purity || ''
  const purification = purity?.[0]?.purityAssessment || ''

  return [purityValue, purification].filter(Boolean).join(' ')
}
