import { ReactElement } from 'react'

export type DropdownProps = {
  parentElement: ReactElement
  childElement?: ReactElement
  onChange?: (val: unknown) => void
  className?: string
  displayPosition?: DropdownPosition | keyof typeof DropdownPosition
  displayAboveParent?: boolean
  onVisibilityChange: (visibilityState: boolean, isParent?: boolean) => void
  isOpen: boolean
  cy?: string
  parentElementClassNames?: string
  dropdownContentClassName?: string
  portal?: boolean
  disabled?: boolean
  id?: string
}

export enum DropdownPosition {
  left = 'left',
  right = 'right',
}
