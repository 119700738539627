import { Checkbox } from '@lego/ui/checkbox'
import classNames from 'classnames'
import { FC, useEffect, useState } from 'react'

interface HardCopyProps {
  label?: string
  subLabel?: string
  leftLabel?: boolean
  className?: string
  onChange?: (value: boolean) => void
  value: boolean
}

const HardCopy: FC<HardCopyProps> = ({
  label = 'Printed documents',
  subLabel = 'Select if hard copy documents are needed.',
  leftLabel = false,
  onChange = (value: boolean) => null,
  value,
  className,
}) => {
  return (
    <div
      className={classNames('pr-layout-2', className, {
        'grid grid-cols-3': leftLabel,
      })}
    >
      <div
        className={classNames({
          'col-span-1 mr-10': leftLabel,
        })}
      >
        <div className="font-semibold text-heading-x-small">{label}</div>
        <div className="mt-1 text-grey-20 text-body-small fon">{subLabel}</div>
      </div>
      <div
        className={classNames({
          'col-span-2': leftLabel,
        })}
      >
        <Checkbox
          checked={value}
          onChange={(value) => {
            onChange(value)
          }}
          className="my-auto font-semibold text-body-small"
          label="CoA. SDS & Datasheet"
        />
      </div>
    </div>
  )
}

export { HardCopy }
