import { BASE_URL_BY_DOMAIN } from '@abcam-web/auth-shared/constants/base-url'
import { STAGE } from '@abcam-web/auth-shared/constants/stage-type'
import {
  AssetModel,
  BasketChangeResultModel,
  BasketItemModel,
  BasketModel,
  BasketSummaryModel,
  DeliveryMethodType,
  EcomOrganisationAddressContact,
  OrderAddress,
  PriceModel,
} from '@abcam-web/shared/data-access/ecommerce-schema'
import {
  OrderRequestModel,
  OrderResponseModel,
} from '@abcam-web/shared/data-access/ecommerce-schema'
import { GroupedPromotions } from '@abcam-web/shared/orderprocessing/order/summary/summary-breakdown.types'
import { formatCurrency } from '@currency-formatter'
import { dateFormatOptions } from '@lego/utilities'
import { UseMutationResult } from '@tanstack/react-query'
import {
  addDays,
  differenceInBusinessDays,
  format,
  isToday,
  isTomorrow,
  Locale,
} from 'date-fns'
import enUS from 'date-fns/locale/en-US'
import ja from 'date-fns/locale/ja'
import zhCN from 'date-fns/locale/zh-CN'
import { cloneDeep,isEmpty, isNil, set  } from 'lodash'
import getConfig from 'next/config'
import { resolveHref } from 'next/dist/client/resolve-href'
import { NextRouter, Url } from 'next/dist/shared/lib/router/router'
import { ReactNode } from 'react'
import { IntlShape } from 'react-intl'

import { AssetStatusCode } from './types'

const baseUrl: string =
  BASE_URL_BY_DOMAIN[
    (getConfig()?.publicRuntimeConfig?.STAGE as STAGE) || 'prod'
  ]

const EXCLUDED_FIELDS = ['addressFusionId', 'organisationRegistryId']

const productLink = (assetDefinitionNumber: string): string =>
  `/products/all/${assetDefinitionNumber}`

const getFullAddress = (orderAddress: OrderAddress): string => {
  if (typeof orderAddress === 'object') {
    return Object.entries(orderAddress)
      .filter(
        ([key, value]) =>
          !EXCLUDED_FIELDS.includes(key) && value !== '' && value !== undefined
      )
      .map(([_, value]) => value)
      .join(', ')
  } else {
    return orderAddress
  }
}

const getQuickAddResultMessages = (
  basketChangeResult: BasketChangeResultModel,
  inputLines: any[],
  formatMessage: IntlShape['formatMessage'],
  countryName?: string
) => {
  const failuresCount = basketChangeResult?.failures?.failuresCount || 0
  const itemAdded = inputLines.length - failuresCount

  const successMessage =
    itemAdded > 0
      ? formatMessage(
          {
            id:
              itemAdded > 1
                ? 'quickAdd.success.multipleItems'
                : 'quickAdd.success.singleItem',
          },
          {
            itemsNumber: itemAdded,
          }
        )
      : undefined
  const failedToAddByReason =
    basketChangeResult?.failures?.failedProductsByReason || {}
  const errorMessage = failuresCount ? (
    <>
      {formatMessage(
        {
          id:
            failuresCount > 1
              ? 'quickAdd.error.multipleItems'
              : 'quickAdd.error.singleItem',
        },
        { itemsNumber: failuresCount }
      )}
      {Object.keys(failedToAddByReason).map((key) => (
        <li key={key}>
          {formatMessage(
            { id: `quickAdd.error.${key}` },
            {
              bold: (chunks: ReactNode) => <strong>{chunks}</strong>,
              region: countryName,
              products: failedToAddByReason[key].split(':')[0].split('(s) ')[1],
            }
          )}
        </li>
      ))}
    </>
  ) : undefined
  return { successMessage, errorMessage }
}

const getDeliveryMessage = (
  estimatedDate: Date,
  cutOffDate: Date | undefined,
  locale = '',
  formatMessage: IntlShape['formatMessage']
): string => {
  const isEstimatedTomorrow = isTomorrow(estimatedDate)

  const cutOffOutput = cutOffDate ? format(cutOffDate, 'HH:mm') : null
  let cutOffDateOutput = cutOffDate
    ? formatMessage(
        { id: 'deliveryMessage.dateFormat' },
        {
          dayOfWeek: format(cutOffDate, 'iiii'),
          dayOfMonth: format(cutOffDate, 'do'),
          fullMonth: format(cutOffDate, 'MMMM'),
        }
      )
    : null

  if (cutOffDate && isToday(cutOffDate)) {
    cutOffDateOutput = formatMessage({ id: 'deliveryMessage.today' })
  }
  if (cutOffDate && isTomorrow(cutOffDate)) {
    cutOffDateOutput = formatMessage({ id: 'deliveryMessage.tomorrow' })
  }

  const estimatedDateFormatted = locale
    ? estimatedDate.toLocaleDateString(locale, dateFormatOptions)
    : estimatedDate.toLocaleDateString(undefined, dateFormatOptions)

  const deliveryOutput = isEstimatedTomorrow
    ? formatMessage({ id: 'deliveryMessage.tomorrow' })
    : `${estimatedDateFormatted}`

  const isMoreThan90Days =
    estimatedDate?.getTime() > addDays(new Date(), 90).getTime()

  if (isMoreThan90Days) {
    return formatMessage({ id: 'deliveryMessage.moreThan90Days' })
  }
  if (cutOffOutput) {
    const deliveryDateMessage = isEstimatedTomorrow
      ? formatMessage({
          id: 'deliveryMessage.tomorrow',
        })
      : formatMessage(
          { id: 'deliveryMessage.cutOffDate.deliveryDateMessage' },
          { estimatedDate: estimatedDateFormatted }
        )
    return formatMessage(
      { id: 'deliveryMessage.cutOffDate' },
      { cutOffOutput, cutOffDateOutput, deliveryDateMessage }
    )
  }

  return formatMessage(
    { id: 'deliveryMessage.noCutOffDate' },
    { deliveryOutput }
  )
}

const isDiscontinued = (status: BasketItemModel['status']): boolean => {
  if (status && status.length) {
    return status?.some(
      (status) => status.code === AssetStatusCode.DISCONTINUED
    )
  } else {
    return false
  }
}

const getRestrictedStatus = (status: BasketItemModel['status']) => {
  if (status && status.length) {
    return (
      status?.find(
        (status) => status.code === AssetStatusCode.NOT_AVAILABLE_IN_COUNTRY
      ) || null
    )
  }
  return null
}

const getBasketFormattedPrices = (
  item: BasketItemModel,
  language: string,
  country: string
): {
  unitPriceWithDiscountFormatted: string
  unitPriceWithoutDiscountFormatted: string
  unitBasePriceFormatted: string
  itemTotalWithDiscountFormatted: string
  itemTotalWithoutDiscountFormatted: string
  itemBaseTotalFormatted: string
  userInputPriceFormatted?: string
} => {
  const priceErrorString = 'N/A'

  return {
    unitPriceWithoutDiscountFormatted:
      formatCurrency({
        locale: { language, country },
        value: item?.prices?.unitPriceWithoutDiscount?.value,
        currency: item?.prices?.unitPriceWithoutDiscount?.currency,
      }) || priceErrorString,

    unitPriceWithDiscountFormatted:
      formatCurrency({
        locale: { language, country },
        value: item?.prices?.unitPriceWithDiscount?.value,
        currency: item?.prices?.unitPriceWithDiscount?.currency,
      }) || priceErrorString,

    unitBasePriceFormatted:
      formatCurrency({
        locale: { language, country },
        value: item?.prices?.base?.value,
        currency: item?.prices?.base?.currency,
      }) || priceErrorString,

    itemTotalWithoutDiscountFormatted:
      formatCurrency({
        locale: { language, country },
        value:
          item?.prices?.lineTotalWithoutDiscount?.value ||
          (item?.prices?.unitPriceWithoutDiscount?.value as number) *
            (item?.quantity as number),
        currency: item?.prices?.unitPriceWithoutDiscount?.currency,
      }) || priceErrorString,

    itemTotalWithDiscountFormatted:
      formatCurrency({
        locale: { language, country },
        value:
          (item?.prices?.lineTotalWithDiscount?.value ||
            (item?.prices?.unitPriceWithDiscount?.value as number) *
              (item?.quantity as number)) -
          (item.promotions?.reduce(
            (acc, promotion) => +(promotion.discount.value || 0) + acc,
            0
          ) || 0),
        currency: item?.prices?.unitPriceWithDiscount?.currency,
      }) || priceErrorString,

    itemBaseTotalFormatted:
      formatCurrency({
        locale: { language, country },
        value: (item?.prices?.base?.value || 0) * (item?.quantity || 0),
        currency: item?.prices?.base?.currency,
      }) || priceErrorString,
    userInputPriceFormatted: formatCurrency({
      locale: { language, country },
      value: item?.userInputPrice?.value,
      currency: item?.userInputPrice?.currency,
    }),
  }
}

const getSelectNumberOptions = (preselected?: number, maxOptions = 11) => {
  const selectOptionsDefault = []
  for (let i = 1; i <= maxOptions; i++) {
    selectOptionsDefault.push({
      key: i,
      displayValue: `${i}`,
      isPreselected: preselected === i,
    })
  }
  if (preselected && preselected > maxOptions) {
    selectOptionsDefault.push({
      key: preselected,
      displayValue: `${preselected}`,
      isPreselected: true,
    })
  }
  return selectOptionsDefault
}

const getStockDetails = (
  stock: number | undefined | null,
  quantity: number,
  formatMessage: IntlShape['formatMessage'],
  status?: BasketItemModel['status']
) => {
  if (stock === undefined || stock === null) {
    return {
      text: formatMessage({ id: 'stockDetails.notAvailable' }),
      color: 'text-black-0',
    }
  }

  if (status && isDiscontinued(status)) {
    return {
      color: stock > 10 ? 'text-green' : 'text-negative',
      text: formatMessage(
        { id: 'stockDetails.inStock.discontinued' },
        { stock }
      ),
    }
  }

  if (quantity > 1) {
    return quantity > stock
      ? {
          color: 'text-black-0',
          text: formatMessage({ id: 'stockDetails.availableToOrder' }),
        }
      : {
          color: 'text-green',
          text: formatMessage({ id: 'stockDetails.inStock.available' }),
        }
  }

  if (stock > 10) {
    return {
      color: 'text-green',
      text: formatMessage({ id: 'stockDetails.inStock.tenPlus' }),
    }
  }

  if (stock <= 10 && stock >= 1) {
    return {
      color: 'text-green',
      text: formatMessage({ id: 'stockDetails.inStock.ten' }),
    }
  }
  return {
    color: 'text-black-0',
    text: formatMessage({ id: 'stockDetails.availableToOrder' }),
  }
}

const getCountryCodeAndPhoneNumberFromPrimaryPhoneNumber = (
  primaryPhoneNumber:
    | EcomOrganisationAddressContact['primaryPhoneNumber']
    | undefined
) => {
  return {
    countryCode: primaryPhoneNumber?.split(' ')[0] || '',
    telephoneNumber: primaryPhoneNumber?.split(' ')?.slice(1)?.join(' ') || '',
  }
}

const mapDeliveryTypeToString: { [key in DeliveryMethodType]: string } = {
  [DeliveryMethodType.STANDARD]: 'Standard',
  [DeliveryMethodType.DRYICE]: 'Dry ice',
  [DeliveryMethodType.EQ]: 'Dangerous goods',
  [DeliveryMethodType.LQ]: 'Dangerous goods',
  [DeliveryMethodType.FULLDG]: 'Dangerous',
  [DeliveryMethodType.LN2]: 'Liquid Nitrogen',
  [DeliveryMethodType.SERA]: 'Regulated Product',
  [DeliveryMethodType.TOXIN]: 'Regulated Product',
  [DeliveryMethodType.CELLLINE]: 'Dry ice',
  [DeliveryMethodType.SIX_DOT_ONE]: 'Dangerous Goods',
  [DeliveryMethodType.BIOCHEMICAL]: 'N/A',
  [DeliveryMethodType.FD2]: 'N/A',
  [DeliveryMethodType.USDA]: 'N/A',
}

function getTotalPriceValue(
  showItemsBasePriceOnly: boolean,
  summary: BasketSummaryModel,
  selectedShippingCharges?: PriceModel
): PriceModel | undefined {
  if (showItemsBasePriceOnly && summary.subtotal) {
    return summary.subtotal
  }
  if (selectedShippingCharges?.value) {
    return {
      value:
        (summary?.total?.value || 0) -
        (summary?.shippingAndHandling?.value || 0) +
        selectedShippingCharges?.value,
      currency: summary.total?.currency,
    } as PriceModel
  }
  return summary.total
}

function getQuantityMaxOptions(item?: AssetModel): number | undefined {
  if (
    isDiscontinued(item?.status) &&
    typeof item?.availableQuantity === 'number' &&
    item.availableQuantity <= 10
  ) {
    return item.availableQuantity
  }
  return undefined
}

function filterBasketItemsByRestrictedStatus(items: BasketItemModel[]): {
  restrictedItemsList: BasketItemModel[]
  unrestrictedItemsList: BasketItemModel[]
} {
  const itemsFilteredByRestrictionStatus: {
    restrictedItemsList: BasketItemModel[]
    unrestrictedItemsList: BasketItemModel[]
  } = {
    restrictedItemsList: [],
    unrestrictedItemsList: [],
  }
  items?.forEach((item) => {
    if (
      item?.status?.find(
        (statusItem) => statusItem?.code === 'NOT_AVAILABLE_IN_COUNTRY'
      )
    ) {
      itemsFilteredByRestrictionStatus.restrictedItemsList.push(item)
    } else {
      itemsFilteredByRestrictionStatus.unrestrictedItemsList.push(item)
    }
  })
  return itemsFilteredByRestrictionStatus
}

function getItemsFromValidationErrors({
  items,
  validationErrors,
  reason,
}: {
  items: BasketItemModel[]
  validationErrors: BasketModel['validationErrors']
  reason?: 'linkedCells' | 'priceDiscrepancy'
}): BasketItemModel[] | undefined {
  if (!validationErrors?.length || !items?.length) {
    return
  }

  const errors = validationErrors.reduce<string[]>((acc, cur) => {
    if (!reason) {
      return [...acc, ...cur.errors]
    }

    const validationError =
      cur.reason === reason ? [...acc, ...cur.errors] : acc
    return validationError
  }, [])

  const errorAssetsNumbers = errors.reduce<string[]>((acc, cur) => {
    const match = cur.match(/assetNumber:\s*(\S+)/i)
    if (match) {
      const assetNumber = match[1]
      return [...acc, assetNumber]
    }
    return acc
  }, [])

  if (!errorAssetsNumbers.length) {
    return
  }

  return items.filter((item) => errorAssetsNumbers.includes(item.assetNumber))
}

function deleteMultipleItems(
  basketId: string,
  country: string | undefined,
  basketItems: BasketItemModel[],
  deleteLineInBasketResult: UseMutationResult<
    void,
    unknown,
    {
      basketId: string
      lineNumber: number
      country?: string
      organisationRegistryId?: string
    },
    unknown
  >
): Promise<void> {
  // once we have bulk delete we can optimise here
  return basketItems
    .reverse()
    .reduce((promise: any, basketItem: BasketItemModel) => {
      return promise.then(() => {
        return deleteLineInBasketResult.mutateAsync({
          basketId: basketId,
          lineNumber: basketItem.lineNumber,
          country,
        })
      })
    }, Promise.resolve())
}

/**
 * Type guard to check if a value is defined
 */
export function isDefined<T>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null
}

/**
 *  Filters out duplicates from an array of objects by a given attribute
 */
export function getUniqueByAttribute<
  T extends Record<K, any>,
  K extends keyof T
>(array: T[], attributeName: K): T[] {
  const unique = array.reduce<{ map: Record<any, boolean>; result: T[] }>(
    (acc, current) => {
      const attrValue = current[attributeName]
      if (!acc.map[attrValue]) {
        acc.map[attrValue] = true
        acc.result.push(current)
      }
      return acc
    },
    { map: {}, result: [] }
  )

  return unique.result
}

export function getGroupedPromotions(basket?: BasketModel): GroupedPromotions {
  const itemLevel = (basket?.items || [])
    .map((item) => item.promotions)
    .flat()
    .filter(isDefined)

  const basketLevel = (basket?.promotions?.basketLevel || []).filter(isDefined)

  const shipping = (basket?.promotions?.shippingCharge || []).filter(isDefined)

  return {
    itemLevel,
    basketLevel,
    shipping,
  }
}

export function unGroupPromotions(
  groupedPromotions: ReturnType<typeof getGroupedPromotions>
) {
  if (!groupedPromotions) {
    return []
  }

  return getUniqueByAttribute(
    [
      ...groupedPromotions.itemLevel,
      ...groupedPromotions.basketLevel,
      ...groupedPromotions.shipping,
    ].filter(isDefined),
    'promotionCode'
  )
}

function sanitiseUrl(url: string): string {
  const trailingDoubleSlash = /\/\/$/
  // we don't want to remove the // that follows the protocol (https://)
  // but we do want to handle // between the baseUrl and other components in the path
  const nonFirstDoubleSlash = /(?<=(.*\/\/.*))\/{2,}/g

  return url
    .replace(trailingDoubleSlash, '')
    .replaceAll(nonFirstDoubleSlash, '/')
}

type AsAbsoluteUrlProps = {
  url: Url
  router: NextRouter
  locale?: string
}

export function asAbsoluteUrl({
  url,
  router,
  locale,
}: AsAbsoluteUrlProps): string {
  const [, resolvedAs] = resolveHref(router, url, true)
  return sanitiseUrl(`${baseUrl}${locale ? `/${locale}` : ''}/${resolvedAs}`)
}

const removeFalsyValuesFromObjectOrArray = (
  input: Array<object> | object
): Array<object> | object => {
  if (Array.isArray(input)) {
    return input
      .map((v) =>
        v && typeof v === 'object' ? removeFalsyValuesFromObjectOrArray(v) : v
      )
      .filter((v) => !(v == null))
  } else {
    return Object.fromEntries(
      Object.entries(input)
        .filter(
          ([_, v]) =>
            !isNil(v) && v !== '' && !(typeof v === 'object' && isEmpty(v))
        )
        .map(([k, v]) => [
          k,
          v === Object(v) ? removeFalsyValuesFromObjectOrArray(v) : v,
        ])
    )
  }
}

const getOnlineOrderRequestFromOrderModel = (
  orderResponseModel: OrderResponseModel
): OrderRequestModel => {
  const orderRequestModel = cloneDeep(orderResponseModel)

  set(orderRequestModel, 'notes.settings', undefined)
  set(orderRequestModel, 'creationDate', undefined)
  set(orderRequestModel, 'lastModificationDate', undefined)
  set(orderRequestModel, 'status', undefined)
  set(orderRequestModel, 'orderNumber', undefined)
  set(orderRequestModel, 'id', undefined)
  set(orderRequestModel, 'source', undefined)
  set(orderRequestModel, 'meta', undefined)
  set(orderRequestModel, 'shipping.freeShipping', undefined)
  set(orderRequestModel, 'customers.soldTo', undefined)
  set(orderRequestModel, 'billing.creditCardPaymentAuthorised', undefined)
  orderRequestModel.customers?.billTo?.organisationName &&
    set(orderRequestModel, 'customers.billTo.organisationName', undefined)
  orderResponseModel.customers?.shipTo?.organisationName &&
    set(orderRequestModel, 'customers.shipTo.organisationName', undefined)
  orderResponseModel.customers?.soldTo?.accountManager &&
    set(orderRequestModel, 'customers.soldTo.accountManager', undefined)
  orderResponseModel.customers?.soldTo?.accountTier &&
    set(orderRequestModel, 'customers.soldTo.accountTier', undefined)

  const output = removeFalsyValuesFromObjectOrArray(orderRequestModel)
  return output as OrderRequestModel
}

const isEnvironmentClient = () => typeof window !== 'undefined'

const isNotNull = <TValue,>(value: TValue | null): value is TValue => {
  return value !== null
}

const removeWordFromString = (
  inputString: string,
  wordToRemove: string,
  options = {
    removePrecedingChar: false,
    removeTrailingChar: false,
  }
) => {
  const precedingChar = options.removePrecedingChar ? '.?' : ''
  const trailingChar = options.removeTrailingChar ? '.?' : ''

  const pattern = new RegExp(
    `${precedingChar}${wordToRemove}${trailingChar}`,
    'g'
  )

  const resultString = inputString.replace(pattern, '')

  return resultString
}

// Used for quotes, instead of getDeliveryMessage for orders
const getEstimatedDeliveryMessage = (
  scheduledArrivalDate: Date | undefined
) => {
  if (!scheduledArrivalDate) {
    return null
  }

  const businessDays = differenceInBusinessDays(
    scheduledArrivalDate,
    new Date()
  )

  return `Estimated delivery: ${businessDays} business ${
    businessDays === 1 ? 'day' : 'days'
  } (provisional)`
}

const shortenWebsite = (website: string): string => {
  const _website = website.replace('https://', '')

  // If there exists more forward slashes from this point, the first is at the end of the TLD
  // so we can get a shortened version like so
  if (!_website.includes('/')) return website
  else return website.slice(0, _website.indexOf('/') + 'https://'.length)
}

/**
 * Gets the date-fns locale object based on the provided locale string.
 *
 * @param {string} [locale] - The locale string (e.g., 'en-us', 'ja-jp', 'zh-cn'). Defaults to 'en-us' if not provided or if the locale is not found.
 * @returns {Locale} The corresponding date-fns locale object.
 */
const getDateFnsLocale = (locale?: string): Locale => {
  const localeMap: Record<string, Locale> = {
    'en-us': enUS,
    'ja-jp': ja,
    'zh-cn': zhCN,
  }
  const defaultLocale = enUS
  const normalisedLocale = (locale ?? '').toLowerCase()
  return localeMap[normalisedLocale] ?? defaultLocale
}

const getValidationErrors = (
  validationErrors: { errors?: string[]; message?: string }[] | undefined | null
) => {
  if (!validationErrors) {
    return []
  }

  let errors: string[] = []

  validationErrors.forEach((validationError) => {
    if (validationError.errors && validationError.errors.length) {
      errors = [...errors, ...validationError.errors]
    } else if (validationError.message) {
      errors = [...errors, validationError.message]
    }
  })

  return errors
}

export {
  deleteMultipleItems,
  filterBasketItemsByRestrictedStatus,
  getBasketFormattedPrices,
  getCountryCodeAndPhoneNumberFromPrimaryPhoneNumber,
  getDateFnsLocale,
  getDeliveryMessage,
  getEstimatedDeliveryMessage,
  getFullAddress,
  getItemsFromValidationErrors,
  getOnlineOrderRequestFromOrderModel,
  getQuantityMaxOptions,
  getQuickAddResultMessages,
  getRestrictedStatus,
  getSelectNumberOptions,
  getStockDetails,
  getTotalPriceValue,
  getValidationErrors,
  isDiscontinued,
  isEnvironmentClient,
  isNotNull,
  mapDeliveryTypeToString,
  productLink,
  removeFalsyValuesFromObjectOrArray,
  removeWordFromString,
  shortenWebsite,
}
