import { POSTCODE_REGEXES } from '../data'
import { BaseAddressSchema } from './base'

export const CNAddressSchema = {
  ...BaseAddressSchema,
  province: {
    type: 'string',
    required: true,
    oneOf: [
      '云南',
      '吉林',
      '四川',
      '安徽',
      '山东',
      '山西',
      '广东',
      '江苏',
      '江西',
      '河北',
      '河南',
      '浙江',
      '海南',
      '湖北',
      '湖南',
      '甘肃',
      '福建',
      '贵州',
      '辽宁',
      '陕西',
      '黑龙江',
      '青海',
      '内蒙古',
      '广西',
      '西藏',
      '宁夏',
      '新疆',
      '上海',
      '北京',
      '天津',
      '重庆',
      'SHANGHAI',
    ],
  },
  postcode: {
    type: 'string',
    required: true,
    // combining all regexes in logical OR
    pattern: new RegExp(
      POSTCODE_REGEXES['CN'].map((exp) => `^${exp}$`).join('|'),
      'i'
    ),
  },
} as const

export type CNAddressSchemaType = typeof CNAddressSchema

export default CNAddressSchema
