import type {
  GtmService} from '@abcam-web/shared/data-access/tracking';
import {
  LocationID,
  getMegaNavClickContext,
} from '@abcam-web/shared/data-access/tracking'
import {
  getPageType,
  getPageSubType,
} from '@abcam-web/shared/data-access/tracking'

export const gtmTracking = (
  gtm: GtmService,
  pathname: string,
  language: string,
  linkLabel: string,
  elementRef: HTMLAnchorElement | null
) => {
  const externalUrlPattern = new RegExp(/^(https?:\/\/)/)

  const linkUrl = elementRef ? elementRef.getAttribute('href') : null

  gtm.track(
    getMegaNavClickContext({
      page_path: pathname,
      click_id: linkLabel,
      page_type: getPageType(pathname),
      page_subtype: getPageSubType(pathname),
      item_cta: elementRef?.innerText,
      destination_path: linkUrl || '',
      distributor_name: null,
      drawer_type: null,
      drawer_subtype: null,
      location_id: LocationID.MAIN_NAV,
      language: language,
      outbound: linkUrl && String(externalUrlPattern.test(linkUrl)),
    })
  )
}
