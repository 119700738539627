import { TextInput } from '@lego/ui/text-input'
import { FC } from 'react'

import styles from './purchase-order.module.css'

interface PurchaseOrderProps {
  isOnline?: boolean
}

const PurchaseOrder: FC<PurchaseOrderProps> = ({ isOnline = true }) => {
  return (
    <>
      {isOnline && (
        <div className="flex flex-col justify-end py-3">
          <label className="">PO number or your reference</label>
          <TextInput fullWidth />
        </div>
      )}
      {!isOnline && (
        <div className="flex flex-col">
          <label className="text-body-medium">PO Number</label>
          <div className="w-2/4">
            <TextInput fullWidth />
          </div>
        </div>
      )}
    </>
  )
}

export { PurchaseOrder }
