/*
Only types exported from this file should be used to represent objects returned by graphql search queries
*/

import {
  ContentSearchQuery,
  Browse_SearchQuery,
  Browse_RecommendedFacetsQuery,
  Browse_SuggestionsQuery,
  ContentFacetRecommendationResults,
} from './schema'

export type ProductSearchResults = ProductsSearchResults['items']

export type ProductSearchResult = ProductSearchResults[number]

export type ProductTagSearchResults = ProductSearchResult['productTags'][number]

export type SearchResults = Browse_SearchQuery['search']

export type ContentSearchResults = ContentSearchQuery['contentSearch']

export type ContentSearchResultItems = ContentSearchResults['items']

export type ContentSearchResult = ContentSearchResultItems[number]

export type ContentFacetRecommendations =
  ContentFacetRecommendationResults['facets']

export type ProductsSearchResults = Extract<
  SearchResults,
  { __typename: 'ProductsSearchResults' }
>

export type EntitiesSearchResults = Extract<
  SearchResults,
  { __typename: 'EntitiesSearchResults' }
>

export type CategoriesSearchResults = Extract<
  SearchResults,
  { __typename: 'CategoriesSearchResults' }
>

export type FacetSearchResults = ProductsSearchResults['facets'][number]

export type FacetContentSearchResults = ContentSearchResults['facets'][number]

export type ValueSearchResults = FacetSearchResults['values'][number]

export type FacetRecommendationResults =
  Browse_RecommendedFacetsQuery['facetRecommendations']

export type SuggestionSearchResult = Browse_SuggestionsQuery['suggestions']

export type {
  FacetInput,
  Browse_SearchQueryVariables as SearchQueryVariables,
  Browse_SearchQuery as SearchQuery,
  ContentSearchQuery,
  Browse_RecommendedFacetsQueryVariables as RecommendedFacetsQueryVariables,
  Browse_RecommendedFacetsQuery as RecommendedFacetsQuery,
  Browse_SuggestionsQueryVariables as SuggestionsQueryVariables,
  Browse_SuggestionsQuery as SuggestionsQuery,
  Browse_SearchBySlugQueryVariables as SearchBySlugQueryVariables,
  Browse_SearchBySlugQuery as SearchBySlugQuery,
  Browse_SearchQueryHookResult as SearchQueryHookResult,
  Browse_SearchLazyQueryHookResult as SearchLazyQueryHookResult,
  Browse_SearchQueryResult as SearchQueryResult,
  Browse_RecommendedFacetsQueryHookResult as RecommendedFacetsQueryHookResult,
  Browse_RecommendedFacetsLazyQueryHookResult as RecommendedFacetsLazyQueryHookResult,
  Browse_RecommendedFacetsQueryResult as RecommendedFacetsQueryResult,
  Browse_SuggestionsQueryHookResult as SuggestionsQueryHookResult,
  Browse_SuggestionsLazyQueryHookResult as SuggestionsLazyQueryHookResult,
  Browse_SuggestionsQueryResult as SuggestionsQueryResult,
  Browse_SearchBySlugQueryHookResult as SearchBySlugQueryHookResult,
  Browse_SearchBySlugLazyQueryHookResult as SearchBySlugLazyQueryHookResult,
  Browse_SearchBySlugQueryResult as SearchBySlugQueryResult,
  SearchRequestInput,
  SimpleSearchRequestInput,
  HandlingHazard,
  ContentFacetRecommendationResults,
  ContentFacetRecommendationsQuery,
} from './schema';


export {
  Browse_SearchBySlugDocument as SearchBySlugDocument,
  Browse_SearchDocument as SearchDocument,
  ContentSearchDocument,
  Browse_SuggestionsDocument as SuggestionsDocument,
  Browse_RecommendedFacetsDocument as RecommendedFacetsDocument,
  ContentFacetRecommendationsDocument,
  useBrowse_SearchQuery as useSearchQuery,
  useBrowse_SearchLazyQuery as useSearchLazyQuery,
  useBrowse_SearchBySlugQuery as useSearchBySlugQuery,
  useBrowse_SearchBySlugLazyQuery as useSearchBySlugLazyQuery,
  useBrowse_SuggestionsQuery as useSuggestionsQuery,
  useBrowse_SuggestionsLazyQuery as useSuggestionsLazyQuery,
  useBrowse_RecommendedFacetsQuery as useRecommendedFacetsQuery,
  useBrowse_RecommendedFacetsLazyQuery as useRecommendedFacetsLazyQuery,
} from './schema';
