import { forwardRef, useCallback } from 'react'
import styles from './radio-input.module.css'
import { isEnterEvent, isSpaceEvent } from '@lego/utilities'
import { InteractiveElement } from '@lego/ui/interactive-element'
import type { RadioInputProps } from './radio-input.type'
import classNames from 'classnames'

const RadioInput = forwardRef<HTMLDivElement, RadioInputProps>((props, ref) => {
  const {
    checked = false,
    value,
    displayValue,
    onChecked = () => null,
    id,
    fullWidth = false,
    dataCy,
    disabled = false,
    role = 'radio',
    radioWrapperClassNameOverride,
    radioLabelClassNameOverride,
    radioIconClassName,
    ...rest
  } = props
  const onClickCallback = useCallback(() => {
    if (!disabled) {
      onChecked({ value })
    }
  }, [onChecked, value, disabled])

  const onKeyboardInteractionCallback = useCallback(
    (evt: any) => {
      if (!disabled && (isEnterEvent(evt) || isSpaceEvent(evt))) {
        onChecked({ value })
      }
    },
    [onChecked, value, disabled]
  )

  const dataTestId = value.toString().split(' ').join('-').toLowerCase()

  return (
    <InteractiveElement
      ref={ref}
      onClick={onClickCallback}
      onKeyPress={onKeyboardInteractionCallback}
      role={role}
      disabled={disabled}
      aria-checked={checked}
      aria-disabled={disabled}
      tabIndex={0}
      fullWidth={fullWidth}
      className={classNames(
        'rounded focus-visible:shadow-interactiveElement focus-visible:outline-none',
        { 'cursor-pointer': !disabled },
        { 'cursor-not-allowed text-grey-0 pointer-events-none': disabled }
      )}
      {...rest}
    >
      <div
        className={radioWrapperClassNameOverride || styles.radioInput}
        id={id}
        aria-disabled={disabled}
        data-testid={dataTestId}
        data-cy={dataCy}
      >
        <div
          className={`flex shrink-0 w-6 h-6 border border-solid rounded-full ${
            checked ? 'border-blue-active' : 'border-black-0'
          }`}
        >
          {checked && (
            <div className="border-2 border-white border-solid rounded-full bg-blue-active grow" />
          )}
        </div>
        <span className={radioLabelClassNameOverride || styles.description}>
          <span>
            <div>{displayValue || value}</div>
          </span>
        </span>
      </div>
    </InteractiveElement>
  )
})

export { RadioInput }
