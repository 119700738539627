export const datasheet = {
  immunogenDescription: 'immunogen-description',
  conjugation: 'conjugation',
  antibodyColumn: 'antibody-column',
  storageColumn: 'storage-column',
  specificationColumn: 'specification-column',
  generalInfoColumn: 'general-info-column',
  keyFactsColumn: 'key-facts-column',
  propertiesColumn: 'properties-column',
  detectionMethod: 'detection-method',
  sampleTypes: 'sample-types',
  assayType: 'assay-type',
  speciesReactivity: 'species-reactivity',
  handlingProcedures: 'handling-procedures',
  supplementaryNotesSection: 'supplementary-notes',
}
