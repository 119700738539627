import { useContext } from 'react'
import { FormContext } from '../../form/formContext'
import { Notification, NOTIFICATION_VARIANT } from '@lego/ui/notification'
import { AlertIcon } from '@lego/ui/icons'

interface SubmissionErrorProps {
  render?: (error: Error | null) => React.ReactNode
  dataCy?: string
  title?: string
  showIcon?: boolean
  customMessage?: React.ReactNode
}

const SubmissionError = ({
  render,
  dataCy,
  title,
  showIcon,
  customMessage,
}: SubmissionErrorProps) => {
  const { submissionError } = useContext(FormContext)

  if (render) {
    return <>{render(submissionError)}</>
  }

  if (!submissionError) return null

  return (
    <Notification
      variant={NOTIFICATION_VARIANT.negative}
      rounded
      className="my-4"
      dataCy={dataCy}
    >
      <div className="flex items-center">
        {showIcon && (
          <div className="mr-3">
            <AlertIcon />
          </div>
        )}

        <div className="flex flex-col">
          {title && <span className="text-sm font-semibold">{title}</span>}
          {customMessage || submissionError.message}
        </div>
      </div>
    </Notification>
  )
}

export { SubmissionError }
