import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { getProduct } from '@abcam-web/search/data-access'
import { useShoppingExperience } from '@abcam-web/shared/ecommerce/utilities'
import { toast } from '@abcam-web/shared/ecommerce/components'
import { DmdDomain } from '@abcam-web/shared/data-access/product-schema'
import { PunchoutSessionMonitor } from './punchout-session-monitor'
import { useTrackPunchoutSessionStart } from './useTrackPunchoutSessionStart'

const useSearchProductAsQueryParam = () => {
  const { goTo } = useShoppingExperience()
  const router = useRouter()

  useEffect(() => {
    const { publicAssetCode } = router.query
    if (!publicAssetCode) {
      return
    }

    // We're expecting only ONE parameter, if we get more then let's redirect
    // to homepage and ignore the error without bothering the customer.
    if (Array.isArray(publicAssetCode)) {
      goTo('/')
      return
    }

    // The public asset code we obtain could be something like "ab123-100ug" but
    // we're not interested in the size when viewing the product page so let's make
    // it a product code instead.
    const productCode = publicAssetCode.split('-')[0].toUpperCase()
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const locale = router.locale ?? router.defaultLocale!

    toast.info(`Searching for product '${productCode}'...`, {
      closeOnClick: false,
      closeButton: false,
      autoClose: 5000,
    })

    const domain = DmdDomain.COM // no domain-specific information needed. Default to COM

    getProduct(productCode, locale, domain).then((response) => {
      if (response.error) {
        goTo('/auth/punchout/error/err-008.1')
        return
      }

      if (!response.data.product) {
        goTo('/auth/punchout/error/err-008.2')
        return
      }

      goTo(
        `/products/${response.data.product.seoClass.levelOne}/${response.data.product.productSlug}`
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

const ClientSideShoppingExperienceBody: React.FC = () => {
  useSearchProductAsQueryParam()
  useTrackPunchoutSessionStart()

  if (typeof window === 'undefined') {
    return null
  }

  return <PunchoutSessionMonitor />
}

ClientSideShoppingExperienceBody.displayName =
  'ClientSideShoppingExperienceBody'

export const ClientSideShoppingExperience = dynamic(
  Promise.resolve(ClientSideShoppingExperienceBody),
  {
    ssr: false,
  }
)
