import { ContentLoader } from '@lego/ui/content-loader'
import { FC } from 'react'

interface Props {
  rows?: number
  height?: string
  rowClassName?: string
  containerClassName?: string
  uniqueKey: string
}

const RowsLoader: FC<Props> = ({
  rows = 3,
  height = '30',
  rowClassName = 'mt-3',
  containerClassName,
  uniqueKey,
}) => (
  <div className={containerClassName} id={uniqueKey}>
    {Array(rows)
      .fill('')
      .map((_item, index) => (
        <span
          data-testid={`${uniqueKey}-loader-${index}`}
          key={`loader-key-${index}`}
        >
          <ContentLoader
            height={height}
            className={rowClassName}
            uniqueKey={`${uniqueKey}-loader-${index}`}
            isLoading
          />
        </span>
      ))}
  </div>
)

export { RowsLoader }
