import type { STAGE } from '@abcam-web/auth-shared/constants/stage-type'
import getConfig from 'next/config'
import { getTokensFromLocalStorage } from './authLocalStorageUtils'

/**
 * Returns authentication headers
 * @param providedStage
 */
export function getAuthHeaders(providedStage?: STAGE): Record<string, string> {
  if (typeof window === 'undefined') {
    return {}
  }

  const stage =
    providedStage || (getConfig().publicRuntimeConfig?.STAGE as STAGE)
  const currentTokens = getTokensFromLocalStorage(stage)
  if (currentTokens?.accessToken && currentTokens?.idToken) {
    return {
      Authorization: `Bearer ${currentTokens.accessToken}`,
      'x-identity': `${currentTokens.idToken}`,
    }
  }
  return {}
}
