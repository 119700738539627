export const showPunchoutError = (errorCode: string) => {
  // TODO: narrow down errorCode type
  if (typeof window !== 'undefined') {
    const hostname = window.location.origin

    // When there is a way to relay error logs down to server side
    // consider using React Router to redirect to error page when on Auth webapp
    // so that customer does not experience a page refresh

    window.location.href = `${hostname}/auth/punchout/error/${errorCode}`
  }
}
