import { SnackbarCloseReason } from '@material-ui/core'
import { ReactNode, SyntheticEvent } from 'react'

export enum NOTIFICATION_VARIANT {
  positive = 'positive',
  informative = 'informative',
  negative = 'negative',
  cautionary = 'cautionary',
  tip = 'tip',
}

export enum NOTIFICATION_SIZE {
  medium = 'medium',
  small = 'small',
  'x-small' = 'x-small',
}

interface Action {
  text: string
  onAction: () => void
}

type NotificationProps = {
  variant: NOTIFICATION_VARIANT | keyof typeof NOTIFICATION_VARIANT
  size?: NOTIFICATION_SIZE | keyof typeof NOTIFICATION_SIZE
  onClose?: (e?: SyntheticEvent, reason?: SnackbarCloseReason) => void
  action?: Action
  autoHideDuration?: number
  className?: string
  highContrast?: boolean
  icon?: ReactNode
  centered?: boolean
  rounded?: boolean
  multiline?: boolean
  dataCy?: string
}

export type { NotificationProps }
