import { POSTCODE_REGEXES } from '../data'
import { BaseAddressSchema } from './base'

export const MXAddressSchema = {
  ...BaseAddressSchema,

  state: {
    type: 'string',
    required: true,
    oneOf: [
      'AGUASCALIENTES',
      'BAJA CALIFORNIA',
      'BAJA CALIFORNIA SUR',
      'CAMPECHE',
      'COAHUILA',
      'CHIAPAS',
      'CHIHUAHUA',
      'CIUDAD DE MEXICO',
      'COLIMA',
      'DURANGO',
      'GUANAJUATO',
      'GUERRERO',
      'HIDALGO',
      'JALISCO',
      'MEXICO',
      'MICHOACAN',
      'MORELOS',
      'NAYARIT',
      'NUEVO LEON',
      'OAXACA',
      'PUEBLA',
      'QUERETARO',
      'QUINTANA ROO',
      'SAN LUIS POTOSI',
      'SINALOA',
      'SONORA',
      'TABASCO',
      'TAMAULIPAS',
      'TLAXCALA',
      'VERACRUZ',
      'YUCATÁN',
      'ZACATECAS',
    ],
  },

  postcode: {
    type: 'string',
    required: true,
    // combining all regexes in logical OR
    pattern: new RegExp(
      POSTCODE_REGEXES['MX'].map((exp) => `^${exp}$`).join('|'),
      'i'
    ),
  },
} as const

export type MXAddressSchemaType = typeof MXAddressSchema

export default MXAddressSchema
