import { UserContext } from '@abcam-web/auth-shared/contexts/user'
import { useMerchantQuery } from '@abcam-web/payments/data-access/api-hooks'
import {
  useChangeOrderStatusMutation,
  useOrderQuery,
} from '@abcam-web/shared/data-access/ecommerce-api-hooks'
import { OrderStatus } from '@abcam-web/shared/data-access/ecommerce-schema'
import { useBasketId } from '@abcam-web/shared/ecommerce/utilities'
import { MODAL_OUTLET_ID } from '@lego/ui/modal'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { FC, PropsWithChildren, useContext, useEffect, useState } from 'react'
import {
  trackCancelClicked,
  trackReviewClicked,
  trackShowModal,
} from './stale-card-payment-monitor.tracking'
import { useCountry } from '@abcam-web/shared/utilities/country'

const DynamicStaleCardPaymentModal = dynamic(() =>
  import('@abcam-web/payments/ui/stale-card-payment-modal').then(
    (module) => module.StaleCardPaymentModal
  )
)

export const StaleCardPaymentMonitor: FC<PropsWithChildren> = ({
  children,
}) => {
  const router = useRouter()
  const { countryName } = useCountry()

  const [showModal, setShowModal] = useState(false)

  const [basketId] = useBasketId()

  const { user } = useContext(UserContext)

  const isInPaymentFormPage = router.pathname.includes('payment-form')
  const isInPaymentProcessingPage =
    router.pathname.includes('payment-processing')

  const { data: orderData, isLoading: isOrderDataLoading } = useOrderQuery(
    {
      orderId: basketId,
    },
    {
      disabled:
        !basketId || !user || isInPaymentFormPage || isInPaymentProcessingPage,
    }
  )

  const billToCountry = orderData?.addresses?.billing?.country ?? ''

  const isOrderInPaymentProcessingStatus =
    orderData?.status === OrderStatus.PAYMENT_PROCESSING

  const { data: merchantData, isLoading: isMerchantDataLoading } =
    useMerchantQuery(
      {
        billToCountry,
      },
      {
        // Only enable useMerchantQuery if an order exists and it has a billToCountry and it is in PAYMENT_PROCESSING state
        enabled: !!billToCountry && isOrderInPaymentProcessingStatus,
      }
    )

  const { mutate: mutateOrderStatus } = useChangeOrderStatusMutation({
    orderId: basketId,
  })

  useEffect(() => {
    if (
      orderData &&
      isOrderInPaymentProcessingStatus &&
      sessionStorage.getItem('placeOrderInitiated') !== basketId &&
      !isInPaymentFormPage &&
      !isInPaymentProcessingPage &&
      !isOrderDataLoading &&
      !isMerchantDataLoading
    ) {
      trackShowModal(merchantData, countryName, orderData, basketId)

      setShowModal(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    basketId,
    isInPaymentFormPage,
    isInPaymentProcessingPage,
    isMerchantDataLoading,
    isOrderDataLoading,
    isOrderInPaymentProcessingStatus,
    merchantData,
    orderData,
  ])

  return (
    <>
      <div>{children}</div>
      {showModal && (
        <DynamicStaleCardPaymentModal
          onCancelClick={() => {
            mutateOrderStatus({
              orderchangeStatusModel: {
                status: OrderStatus.DRAFT,
              },
            })

            trackCancelClicked(merchantData, countryName, orderData, basketId)

            setShowModal(false)
            router.push(`/shopping-basket/${basketId}`)
          }}
          onConfirmClick={() => {
            mutateOrderStatus({
              orderchangeStatusModel: {
                status: OrderStatus.DRAFT,
              },
            })

            trackReviewClicked(merchantData, countryName, orderData, basketId)

            setShowModal(false)
            router.push(`/checkout/${basketId}`)
          }}
          show={showModal}
        />
      )}
      <div id={MODAL_OUTLET_ID} />
    </>
  )
}