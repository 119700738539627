import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  HTMLProps,
} from 'react'
import cn from 'classnames'
import type { CheckboxProps } from './checkbox.type'

import { IconChecked } from '@lego/ui/icons'
import { InteractiveElement } from '@lego/ui/interactive-element'
import { isEnterEvent, isSpaceEvent } from '@lego/utilities'

const Checkbox = forwardRef<HTMLDivElement, CheckboxProps>((props, ref) => {
  const {
    label,
    error,
    errorText,
    value,
    id,
    fullWidth = false,
    checked,
    disabled = false,
    onChange = () => null,
    className,
    uncheckedBorderClassName,
    checkboxWrapperClassNameOverride,
    ariaLabel,
    checkboxLabelClassNameOverride,
    checkmarkClassNameOverride,
    role = 'checkbox',
    labelTextClassName = '',
    checkboxBackgroundClassNameOverride,
    onClick,
    onKeyPress,
    defaultChecked, // starting value for uncontrolled component
    ...rest
  } = props

  const isControlledComponent = typeof checked === 'boolean'
  const [isChecked, setIsChecked] = useState<boolean>(
    Boolean(checked ?? defaultChecked)
  )

  useEffect(() => {
    if (isControlledComponent) {
      setIsChecked(checked)
    }
  }, [checked])

  const checkboxWrapperClassName = cn(
    'px-4 py-3 block rounded-8px border-3',
    'border-transparent bg-transparent'
  )

  const checkmarkClassName = cn(
    checkmarkClassNameOverride ||
      'absolute top-0 left-0 h-5 w-5 border rounded-[2px]',
    isChecked
      ? `border-blue-active ${
          checkboxBackgroundClassNameOverride ?? 'bg-blue-active'
        }`
      : uncheckedBorderClassName
      ? uncheckedBorderClassName
      : 'border-stroke-opaque'
  )

  const iconClassName = cn(
    'absolute transform top-2/4 translate-x-[2px] -translate-y-1/2',
    isChecked ? 'block' : 'hidden'
  )

  const handleMouseInteraction: HTMLProps<HTMLDivElement>['onClick'] = (
    evt
  ) => {
    onClick?.(evt)

    if (!disabled) {
      onChange(!isChecked)
      evt.preventDefault()
      if (!isControlledComponent) {
        setIsChecked(!isChecked)
      }
    }
  }

  const handleKeyboardInteraction = (
    evt: React.KeyboardEvent<HTMLDivElement>
  ) => {
    onKeyPress?.(evt)

    if (!disabled) {
      if (!isSpaceEvent(evt) && !isEnterEvent(evt)) {
        return
      }
      evt.preventDefault()
      if (!isControlledComponent) {
        setIsChecked(!isChecked)
      }
      onChange(!isChecked)
    }
  }

  const noopCallback = useCallback(() => null, [])
  const checkboxClassName = cn('sr-only')

  return (
    <>
      <InteractiveElement
        className={cn(
          { 'cursor-not-allowed': disabled },
          'focus-visible:shadow-interactiveElement focus-visible:outline-none rounded',
          className
        )}
        fullWidth={fullWidth}
        error={error}
        aria-checked={isChecked}
        role={role}
        ref={ref}
        {...rest}
        onClick={handleMouseInteraction}
        onKeyPress={handleKeyboardInteraction}
      >
        <div
          className={
            checkboxWrapperClassNameOverride ?? checkboxWrapperClassName
          }
        >
          <label
            className={
              checkboxLabelClassNameOverride ??
              cn(
                'relative block h-full text-ui-small cursor-pointer select-none pl-8',
                { 'cursor-not-allowed': disabled }
              )
            }
            htmlFor={id}
          >
            {labelTextClassName ? (
              <p className={labelTextClassName}>{label}</p>
            ) : (
              label
            )}
            <input
              aria-label={ariaLabel}
              // wrapped in an interactive element
              tabIndex={-1}
              className={checkboxClassName}
              type="checkbox"
              disabled={disabled}
              id={id}
              aria-checked={isChecked}
              data-cy={isChecked ? 'checkbox-checked' : 'checkbox-not-checked'}
              checked={isChecked}
              value={value}
              onChange={noopCallback}
            />
            <span data-cy="icon-checked" className={checkmarkClassName}>
              <IconChecked className={iconClassName} />
            </span>
          </label>
        </div>
      </InteractiveElement>
      {error && (
        <span className="block mt-1 text-sm text-negative font-body">
          {errorText}
        </span>
      )}
    </>
  )
})

export { Checkbox }
