import { BASE_URL_BY_DOMAIN } from "@abcam-web/auth-shared/constants/base-url";
import { STAGE } from "@abcam-web/auth-shared/constants/stage-type";
import { resolveHref } from 'next/dist/client/resolve-href';
import { NextRouter, Url } from "next/dist/shared/lib/router/router";
import getConfig from "next/config";

const baseUrl: string = BASE_URL_BY_DOMAIN[getConfig()?.publicRuntimeConfig?.STAGE as STAGE || 'prod']

export const hasBasePath = (router: NextRouter): boolean => (
    !!router.basePath
)

function sanitiseUrl(url: string): string {
    const trailingDoubleSlash = /\/\/$/;
    // we don't want to remove the // that follows the protocol (https://)
    // but we do want to handle // between the baseUrl and other components in the path
    const nonFirstDoubleSlash = /(?<=(.*\/\/.*))\/{2,}/g;
  
    return url
      .replace(trailingDoubleSlash, '')
      .replaceAll(nonFirstDoubleSlash, '/')
  }
  
  type AsAbsoluteUrlProps = {
    url: Url
    router: NextRouter
    locale?: string
  }
  
  export function asAbsoluteUrl({
    url,
    router,
    locale,
  }: AsAbsoluteUrlProps): string {
    const [, resolvedAs] = resolveHref(router, url, true)
    return sanitiseUrl(`${baseUrl}${locale ? `/${locale}` : ''}/${resolvedAs}`)
  }
  