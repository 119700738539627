import { Redirect } from 'next'

import { getAuthenticationHost } from './getAuthenticationHost'
import { STAGE } from '@abcam-web/auth-shared/constants/stage-type'
import getConfig from 'next/config'

type GetAnonymousUserRedirect = (returnTo?: string) => { redirect: Redirect }

const getAnonymousUserRedirect: GetAnonymousUserRedirect = (returnTo) => {
  const stage = getConfig().publicRuntimeConfig?.STAGE as STAGE
  const loginHost = getAuthenticationHost(stage)

  return {
    redirect: {
      permanent: false,
      destination: returnTo
        ? `${loginHost}/login?redirect=${encodeURIComponent(returnTo)}`
        : `${loginHost}/login`,
    },
  }
}

export { getAnonymousUserRedirect }
export type { GetAnonymousUserRedirect }
