import { useAddLinesInBasketMutation } from '@abcam-web/shared/data-access/ecommerce-api-hooks'
import { BasketChangeResultModel } from '@abcam-web/shared/data-access/ecommerce-schema'
import {
  getQuickAddResultMessages,
  useBasketId,
} from '@abcam-web/shared/ecommerce/utilities'
import { Button } from '@lego/ui/button'
import { AlertIcon, CheckIcon, Spinner } from '@lego/ui/icons'
import { Notification } from '@lego/ui/notification'
import { TextInput } from '@lego/ui/text-input'
import cn from 'classnames'
import parse from 'html-react-parser'
import { useRouter } from 'next/router'
import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useIntl } from 'react-intl'

import { LocalisedMessage } from '../toast'

const regExp = new RegExp(/(\w+-\w+)|(\w+)/, 'g')

export interface QuickAddToBasketProps {
  addButtonClickCB?: (
    assetNumbers: string[],
    assetDefinitions: string[],
    freeText: string
  ) => void
  onClose: () => void
  onSuccess?: (
    data: BasketChangeResultModel,
    options: { basketId: string }
  ) => void
  onError?: () => void
  queryKey: (string | undefined | boolean)[]
  queryVariables: {
    basketId: string
    country?: string
    organisationRegistryId?: string
    controlPoint?: string
  }
  countryName?: string
  addLinesLoadingChangeCB?: (isLoading: boolean) => void
  shouldRedirect?: boolean
}

const QuickAddToBasket: FC<QuickAddToBasketProps> = ({
  onClose,
  addButtonClickCB = () => null,
  onSuccess,
  onError,
  countryName,
  queryKey,
  queryVariables,
  addLinesLoadingChangeCB,
  shouldRedirect = false,
}) => {
  const [value, setValue] = useState('')
  const [valid, setValid] = useState(true)
  const [responseNotification, setResponseNotification] = useState<{
    success?: string
    error?: ReactElement
  }>({
    success: undefined,
    error: undefined,
  })

  const router = useRouter()
  const [, , deleteBasketId] = useBasketId()

  const { mutate: addLinesInBasketMutation, isLoading } =
    useAddLinesInBasketMutation(queryKey, {
      onSuccess: (data: BasketChangeResultModel, { lines, basketId }) => {
        const { errorMessage, successMessage } = getQuickAddResultMessages(
          data,
          lines,
          formatMessage,
          countryName
        )

        setResponseNotification({
          error: errorMessage,
          success: successMessage,
        })
        if (!errorMessage) {
          setValue('')
        }
        onSuccess?.(data, { basketId })
      },
      onError: (err) => {
        if (err?.response?.status === 423) {
          deleteBasketId()
          setResponseNotification({
            error: <LocalisedMessage contentId="quickAdd.error.locked" />,
            success: undefined,
          })

          if (shouldRedirect) {
            router.push('/shopping-basket')
          }
        } else {
          onError && onError()
        }
      },
    })

  const { formatMessage } = useIntl()

  useEffect(() => {
    addLinesLoadingChangeCB?.(isLoading)
  }, [isLoading, addLinesLoadingChangeCB])

  const onSubmitHandler = () => {
    const matches = value.matchAll(regExp)
    const assets = []
    const assetNumbers = []

    for (const match of matches) {
      match[2] && assets.push(match[2])
      match[1] && assetNumbers.push(match[1])
    }

    !assets.length && !assetNumbers.length && setValid(false)

    const lines = [...assetNumbers, ...assets].map((item) => ({
      assetId: item,
      quantity: 1,
    }))

    addLinesInBasketMutation({ lines, ...queryVariables })

    addButtonClickCB(assetNumbers, assets, value)
  }

  const onCloseHandler = () => {
    setResponseNotification({ error: undefined, success: undefined })
    onClose()
  }

  const onChangeHandler = ({ value }: { value: string }) => {
    setValue(value)
    setValid(true)
  }

  const onCloseSuccessNotificationCallback = useCallback(() => {
    setResponseNotification((state) => ({
      ...state,
      success: undefined,
    }))
  }, [])

  const onCloseErrorNotificationCallback = useCallback(() => {
    setResponseNotification((state) => ({ ...state, error: undefined }))
  }, [])

  const hintMessage = formatMessage({ id: 'quickAdd.hint' })
  const parsedHint = parse(hintMessage) as React.ReactNode

  return (
    <div className="relative z-10 mt-4 mb-6">
      <div
        className={cn('absolute w-full h-full bg-white opacity-50', {
          hidden: !isLoading,
        })}
      ></div>
      <TextInput
        dataCy="quick-add-text-input"
        label={formatMessage({ id: 'quickAdd.label' })}
        hint={parsedHint}
        placeholder={formatMessage({ id: 'quickAdd.placeHolder' })}
        onChange={onChangeHandler}
        inputType="area"
        fullWidth={true}
        value={value}
        error={!valid}
        disabled={isLoading}
      />
      {Boolean(responseNotification.success) && (
        <Notification
          icon={<CheckIcon />}
          variant="positive"
          onClose={onCloseSuccessNotificationCallback}
          className="mt-2"
          dataCy="success-message"
        >
          {responseNotification.success}
        </Notification>
      )}
      {Boolean(responseNotification.error) && (
        <Notification
          icon={<AlertIcon className="self-start" />}
          variant="negative"
          onClose={onCloseErrorNotificationCallback}
          className="mt-2"
          dataCy="error-message"
        >
          {responseNotification.error}
        </Notification>
      )}
      <div className="flex justify-end mt-6 text-right">
        {!isLoading && (
          <Button
            dataCy="close-button"
            size="medium"
            className="mr-2"
            variant="textOnly"
            onClick={onCloseHandler}
          >
            {formatMessage({ id: 'quickAdd.closeButton' })}
          </Button>
        )}
        <Button
          dataCy="add-products-button"
          data-testid="add-products-button"
          size="medium"
          onClick={onSubmitHandler}
          disabled={!value}
          iconLeft={isLoading ? <Spinner className="animate-spin" /> : null}
        >
          {isLoading
            ? formatMessage({ id: 'quickAdd.submit.isLoading' })
            : formatMessage({ id: 'quickAdd.submit.isNotLoading' })}
        </Button>
      </div>
    </div>
  )
}

export { QuickAddToBasket }
