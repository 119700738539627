import { Button } from '@lego/ui/button'
import { CircleArrows } from '@lego/ui/icons'
import { FC } from 'react'

import styles from './error-handler.module.css'

export interface Props {
  title: string
  description: string
  buttonLabel: string
  onReload?: () => void
}

const ErrorHandler: FC<Props> = ({
  title,
  description,
  buttonLabel,
  onReload,
}) => {
  return (
    <div className={styles.errorHandlerContainer}>
      <div data-cy="error-message" className={styles.title}>
        {title}
      </div>
      <div className={styles.message}>{description}</div>
      <Button
        dataCy="error-button"
        bodyClassName={styles.buttonMessage}
        className={styles.reloadButton}
        onClick={onReload}
        variant="primary"
        size="medium"
        iconLeft={<CircleArrows />}
      >
        {buttonLabel}
      </Button>
    </div>
  )
}

export { ErrorHandler }
