import { InfoIcon } from '@lego/ui/icons'
import { Tooltip } from '@lego/ui/tooltip'
import cx from 'classnames'
import { FC } from 'react'
import { useIntl } from 'react-intl'

export enum OrderTagEnum {
  LVO = 'LVO',
  LQO = 'LQO',
  LQL = 'LQL',
}

interface TagProps {
  type: OrderTagEnum
  className?: string
}

const OrderTag: FC<TagProps> = ({ type, className }) => {
  const { formatMessage } = useIntl()

  return (
    <Tooltip
      text={
        <span
          className="flex font-semibold text-body-small text-grey-20"
          data-testid="tag-tooltip"
        >
          <InfoIcon className="mr-1 fill-grey-20" height={16} width={16} />
          {type === OrderTagEnum.LVO &&
            formatMessage({ id: 'orderTag.largeValueOrder' })}
          {type === OrderTagEnum.LQO &&
            formatMessage({ id: 'orderTag.largeQuantityOrder' })}
          {type === OrderTagEnum.LQL &&
            formatMessage({ id: 'orderTag.largeQuantityLine' })}
        </span>
      }
    >
      <div
        data-testid="order-tag"
        style={{ borderRadius: '3px' }}
        className={cx(
          'flex p-1 mt-2 cursor-pointer font-semibold w-fit h-fit text-body-small text-grey-40',
          {
            'bg-grey-10':
              type === OrderTagEnum.LQL || type === OrderTagEnum.LQO,
            'bg-cautionary': type === OrderTagEnum.LVO,
          },
          className
        )}
      >
        {type}
      </div>
    </Tooltip>
  )
}

export { OrderTag }
