import { Popover } from '@lego/ui/popover'

import {
  AuthenticationButton,
  AuthenticationButtonProps,
} from '../authentication-button'
import { useEffect, useRef, useState } from 'react'
import {
  breakpointsAsNumbers,
  useBreakpointListener,
} from '@abcam-web/lego/hooks/breakpoint-listener'

interface AuthenticationBDropdownProps extends AuthenticationButtonProps {
  displayPosition?: 'left' | 'right' | 'mobile'
  children?: React.ReactNode
}

const AuthenticationDropdown = ({
  children,
  className,
  displayPosition = 'right',
  ...popoverProps
}: AuthenticationBDropdownProps) => {
  const [panelPosition, setPanelPosition] = useState<number>(0)
  const [windowWidth, setWindowWidth] = useState<number>(0)
  const popoverButtonRef = useRef<HTMLButtonElement>(null)
  const matchesMduQuery = useBreakpointListener(breakpointsAsNumbers.md, '<')

  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth)
  }

  // Updates menu position on window resize
  useEffect(() => {
    setTimeout(() => {
      updateWindowWidth()
    }, 1000)

    window.addEventListener('resize', updateWindowWidth)

    return () => {
      window.removeEventListener('resize', updateWindowWidth)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (popoverButtonRef?.current && matchesMduQuery) {
      const location = popoverButtonRef?.current?.getBoundingClientRect()
      const rightPosition = windowWidth - location.right
      setPanelPosition(rightPosition)
    } else {
      setPanelPosition(0)
    }
  }, [matchesMduQuery, windowWidth])

  return (
    <Popover
      className={className}
      panelStyle={{ right: `-${panelPosition}px` }}
      cta={<AuthenticationButton {...popoverProps} />}
      displayPosition={displayPosition}
      popoverButtonRef={popoverButtonRef}
      onPopoverButtonClick={updateWindowWidth}
    >
      {children && (
        <div className="w-screen overflow-hidden bg-white md:h-full text-[black] md:w-full shadow-elevation-3 md:rounded-8px">
          {children}
        </div>
      )}
    </Popover>
  )
}

export { AuthenticationDropdown }
