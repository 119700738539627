import { POSTCODE_REGEXES } from './postcode-regexes'
import { countryCodes } from './countries'
const addressFields = [
  'name',
  'organisationName',
  'addressLine1',
  'addressLine2',
  'city',
  'country',
  'county',
  'district',
  'postcode',
  'state',
  'province',
] as const

export { POSTCODE_REGEXES, countryCodes, addressFields }
export type COUNTRY_CODES = keyof typeof POSTCODE_REGEXES
