export const newOrder = {
  searchInput: 'modal-content-new-order-search-input',
  searchButton: 'modal-content-new-order-search-button',
  cancelButton: 'modal-content-new-order-cancel-button',
  createOrderButton: 'modal-content-new-order-create-order-button',
  createQuoteButton: 'modal-content-new-order-create-quote-button',
  heading: 'modal-content-new-order-heading',
  searchInputInfo: 'modal-content-new-order-search-input-info',
  loadingSpinner: 'modal-content-new-order-loading-spinner',
  contactSearchResults: {
    noResultsFound:
      'modal-content-new-order-contact-search-results-no-results-found',
    searchRefinementInfo:
      'modal-content-new-order-contact-search-results-refinement-info',
    resultCountInfo:
      'modal-content-new-order-contact-search-results-result-count-info',
    row: {
      button: 'modal-content-new-order-contact-search-results-row-button',
      container: 'modal-content-new-order-contact-search-results-row',
      fullName: 'modal-content-new-order-contact-search-results-row-full-name',
      contactRegistryId:
        'modal-content-new-order-contact-search-results-row-contact-registry-id',
      emailAddress:
        'modal-content-new-order-contact-search-results-row-email-address',
    },
  },
  selectedContact: {
    container: 'modal-content-new-order-selected-contact',
    changeContactButton:
      'modal-content-new-order-selected-contact-change-contact-button',
  },
}
