import { ReactivityType } from '@abcam-web/shared/data-access/product-schema'
import type { ReactivityFragment } from '@abcam-web/shared/data-access/product-schema'

export const getMinimalCrossReactivity = (
  speciesReactivity: ReactivityFragment['reactivity']['speciesReactivity']
) =>
  speciesReactivity
    ?.filter(
      (reactivity) =>
        reactivity.reactivityType === ReactivityType.DOES_NOT_REACT
    )
    .map((reactivity) => reactivity.species)
    .join(', ')
