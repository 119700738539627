import { singletonHook } from 'react-singleton-hook'
import { validate as uuidValidate } from 'uuid'

import {
  createLocalStorageHook,
  deleteStorageValue,
  readStorageValue,
} from './use-local-storage'

const BASKET_ID_KEY = 'shoppingBasketId'

const useBasketIdHook = createLocalStorageHook(BASKET_ID_KEY, uuidValidate)

const useSingletonBasketId = singletonHook(['', () => '', () => null], () =>
  useBasketIdHook('')
)

/**
 * Deletes the basket ID from the local storage.
 *
 * If you're interested in re-rendering when the basket has been deleted, or if you also need the basket ID,
 * then you should be probably using `useBasketId()` instead. Use this function when you need to delete the
 * basket but you're not interested in its value (possibly in conjuction with `readBasketId()` if you need to
 * know its value only when you use it without re-rendering when it changes).
 *
 * Note that this function does NOT delete the basket itself (server-side), it simply makes the client to
 * forget the basket currently in use.
 *
 * This function can be used only **client-side** (it throws an error if called in SSR).
 * @returns `true` if the basket ID has been deleted (or if no basket was known), `false` in case of errors.
 */
const deleteBasketId = () => deleteStorageValue(BASKET_ID_KEY)

/**
 * Reads the ID of the current basket.
 *
 * Note that this function reads the _current_ value stored in the local storage: in most cases
 * you want to use `useBasketId()` to actively monitor for changes, use this when you're interested
 * in the value only when you need to use it and you do not need to update anything if it changes.
 *
 * This function can be used only **client-side**.
 * @returns The ID of the current basket, read from the local storage, or `null` if there
 * isn't any basket or if the stored value is emoty. If the value is not a valid UUID then
 * this throws an error.
 */
const readBasketId = () => readStorageValue(BASKET_ID_KEY, uuidValidate)

export {
  deleteBasketId,
  readBasketId,
  useSingletonBasketId as useBasketId,
  useBasketIdHook as useNonSingletonBasketId,
}
