import { useCountry } from '@abcam-web/shared/utilities/country'
import { getLanguageFromLocale, getNextLocaleFromCookie } from '@abcam-web/shared/utilities/localisation'
import { formatCurrency } from '@currency-formatter'
import { useRouter } from 'next/router'

type Input = { value?: number; currency: string }

const useFormattedPrice = (input: Input | undefined): string => {
  const { country } = useCountry()
  const locale = useRouter().locale || getNextLocaleFromCookie() || ''
  const language = getLanguageFromLocale(locale) || locale

  return (
    formatCurrency({
      locale: { language, country },
      value: input?.value,
      currency: input?.currency,
    }) || 'N/A'
  )
}

export { useFormattedPrice }
