export const pdp = {
  stickyHeader: 'sticky-header',
  summaryName: 'pdp-summary-name',
  contactDistributorButton: 'contact-distributor-button',
  makeInquiryModal: 'make-inquiry-modal',
  overviewTab: 'overview-tab',
  kitComponentsPanel: 'kits-components-panel',
  supportTab: 'support-tab',
  imagesTab: 'images-tab',
  kitsPdpAttributes: 'kits-pdp-attributes',
  filterRow: 'filter-row',
  noFilters: 'no-filters',
  fullProductButton: 'full-product-button',
  productDescription: 'product-description',
  imagesViewAll: 'images-view-all',
  imageOverlay: 'image-overlay',
  backToSearchButton: 'back-to-search',
  backToSearchButtonText: 'Back to search',
  readMoreTrigger: 'read-trigger',
  productDetails: 'product-details',
  variationsLength: 'variations-length',
  conjugateListItem: 'conjugate-list-item',
  dataSelected: 'data-selected',
  associatedProductsCard: 'associated-products-card',
  associatedProductsHeading: 'associated-products-heading',
  associatedProductsTab: 'associated-products-tab',
  crossSellCard: 'pdp-card',
  directReplacementCard: 'direct-replacement-card',
  directReplacementCardButton: 'direct-replacement-card-button',
  productPromiseModalTermsAndConditions:
    'product-promise-modal-terms-conditions',
  crossSellHeading: 'cross-sell-heading',
  appGrid: 'app-grid',
  modalTitle: 'modal-title',
  downloadProtocolButton: 'download-protocol-button',
  productPromiseLearnMore: 'product-promise-learn-more',
  SupportAndDownloads: {
    downloadFluorochromeChartButton: 'download-fluorochrome-chart-button',
    downloadCocInput: 'download-coc-input',
    downloadProtocolButton: 'download-protocol-button',
    downloadSequencingBookletButton: {
      cutTagSequencingBooklet: 'download-cut-tag-seq-booklet',
      chicCutRunSequencingBooklet: 'download-chic-cut-run-seq-booklet',
      chipSequencingBooklet: 'download-chip-seq-booklet',
    },
    openSafetyDatasheetButton: 'open-safety-datasheet-button',
    safetyDatasheetModal: 'safety-datasheet-modal',
    safetyDatasheetSelectCountryTitle: 'safetyDatasheetSelectCountryTitle',
    safetyDatasheetModalTitle: 'modal-title',
    safetyDatasheetLanguageDropDownButton: 'selected-value-container',
    downloadCocButton: 'download-coc',
    downloadCocModal: 'download-coc-modal',
    submitCocModalButton: 'loading-spinner',
    cocSubmitButton: 'submit-coc-modal',
    downloadCocItem: 'download-coc-coc-item',
    downloadCocPdfButton: 'open-pdf-button',
    concentrationFinderInput: 'conentration-finder-input',
    concentrationFinderSubmit: 'conentration-finder-submit',
    concentrationFinderText: 'concentration-finder-text',
  },
  publications: {
    shortDescription: 'short-description',
    publicationsViewAll: 'publications-view-all',
    imagesCount: 'images-count',
    publicationsCount: 'publications-count',
    dropDownAllPublications: 'publications-dropdown-all-publications',
    publicationsDropDownAnyReactiveSpecies:
      'publications-dropdown-any-reactive-species',
    publicationCard: 'publication-card',
    publicationCardHeader: 'publication-card-header',
    publicationTab: 'publications',
    sliderImageContainer: 'slider-image-container',
    searchInput: 'publications-search-input',
    sliderImageLeftArrow: 'slider-image-left-arrow',
    sliderImageRightArrow: 'slider-image-right-arrow',
  },
  reviews: {
    reviewsButton: 'star-ratings-view-ratings-btn',
    totalStars: 'star-ratings-read-only-value',
    starRating: 'star-rating',
    numberofReviews: 'star-ratings-read-only-number-of-reviews',
    reviewssDropDownAnyApplications: 'reviews-application-filter-btn',
    reviewsTitle: 'review-title',
    reviewsTab: 'reviews',
    reviewCheckbox: 'checkbox-5',
    reviewlabel: 'star-ratings-variant-label',
  },
  datasheet: {
    notes: 'datasheet-notes-section',
    batchConcentrationFinderButton: 'batch-concentration-finder-button',
  },
  images: {
    productImagesCount: 'product-images-count',
    imageTitle: 'image-title',
  },
  applicationHeading: 'applications-heading',
  reactivityApplications: {
    tab: 'reactivity-applications-tab',
    wrapper: 'reactivity-applications-wrapper',
  },
  targetData: 'target-data',
}
