import { orderLog } from './modal/order-log'
import { cancelOrder } from './modal/cancel-order'
import { changeOrderStatus } from './modal/change-order-status'
import { issueReviewAndPayEmail } from './modal/issue-review-and-pay-email'
import { newOrder } from './modal/new-order'
import { validationErrors } from './modal/validation-errors'
import { selectOrderSource } from './modal/select-order-source'
import { sourceAndLinksModal } from './modal/source-and-links'
import { convertOrder } from './modal/convert-order'

export const modal = {
  cancelOrder,
  changeOrderStatus,
  convertOrder,
  issueReviewAndPayEmail,
  orderLog,
  newOrder,
  validationErrors,
  selectOrderSource,
  sourceAndLinksModal,
}
