import {
  COUNTRY_SELECTOR_FILTER_KEY,
  COUNTRY_SELECTOR_PERSIST_KEY,
} from './constants'
import { CountrySelectorVariant } from './countrySelectorDropdown.type'
import type {
  CountryLanguageSelectorProps,
  CountrySelectorOption,
} from './countrySelectorDropdown.type'
import {
  getDefaultLanguage,
  getCookie,
} from '@abcam-web/shared/utilities/localisation'
import { FilterableList, FilterableListItem } from '@lego/ui/filterable-list'
import { useRouter } from 'next/router'
import { useCallback, useMemo, useState } from 'react'
import type { FormEvent } from 'react'
import { SimpleSelect } from '@lego/ui/form-elements'
import type { Option } from '@lego/ui/form-elements'
import { Button } from '@lego/ui/button'
import { getCountrySelectorOption, getLanguagesGroupedByCountry } from './utils'
import type { Key } from '@react-types/shared'
import { useCountry } from '@abcam-web/shared/utilities/country'
import { getLocales } from '@abcam-web/shared/config'
import { EnvironmentFinder } from '@abcam-web/shared/utilities/environment'

export const CountryLanguageSelector = ({
  shouldDisplayCountryName = false,
  variant = CountrySelectorVariant.primary,
  persistSelection = false,
  countries = undefined,
  excludedCountries = undefined,
  isMainSiteSwitcher = false,
  withLanguageSelector = false,
  countrySelectorOverrides = undefined,
  languageSelectorOverrides = undefined,
  onChange = undefined,
  onChangeIsOpenCallback = undefined,
  selectedCountry,
  onChangeSelectedCountryCallback,
  confirmOnSelect = false,
  ApplyLanguageButton,
}: CountryLanguageSelectorProps) => {
  const [languageOptions, setLanguageOptions] = useState<Option[]>([])
  const [showLanguageSelector, setShowLanguageSelector] = useState(false)
  const [chosenLanguage, setChosenLanguage] = useState('')

  const router = useRouter()
  const { countries: fallbackCountries } = useCountry()
  const filteredCountries = (countries ?? fallbackCountries).filter(
    (code) => !excludedCountries?.includes(code)
  )

  const countryOptions = useMemo(
    () =>
      filteredCountries.map((countryCode: string) =>
        getCountrySelectorOption({
          countryCode,
          variant,
          shouldDisplayCountryName,
        })
      ),
    [countries, filteredCountries, shouldDisplayCountryName, variant]
  )

  const onChangeCountryCallback = useCallback(
    async (key: Key | null) => {
      const val = countryOptions.find(
        (option: CountrySelectorOption) => option.key === key
      )
      if (!val) return

      if (withLanguageSelector) {
        onChangeSelectedCountryCallback(val.key)
        const languagesGroupedByCountry = getLanguagesGroupedByCountry(
          getLocales()
        )
        const selectedCountryCode = val.key.toLowerCase()

        if (selectedCountryCode in languagesGroupedByCountry) {
          const languages = languagesGroupedByCountry[selectedCountryCode]

          // For China and Japan, we need to redirect to their respective PWS sites
          // If they are not a distributor
          if (!getCookie('DISTRIBUTOR')) {
            if (EnvironmentFinder.isProd()) {
              if (selectedCountryCode === 'cn') {
                window.location.href = 'https://www.abcam.cn'
                return
              }

              if (selectedCountryCode === 'jp') {
                window.location.href = 'https://www.abcam.co.jp'
                return
              }
            }
            if (EnvironmentFinder.isPreProd()) {
              if (selectedCountryCode === 'cn') {
                window.location.href = 'https://pp.abcam.cn'
                return
              }

              if (selectedCountryCode === 'jp') {
                window.location.href = 'https://pp.abcam.co.jp'
                return
              }
            }
          }

          if (languages.length === 1) {
            setShowLanguageSelector(false)

            if (confirmOnSelect) {
              onChangeIsOpenCallback?.(false)
              onChange?.({ country: val.key, language: languages[0] })
              if (persistSelection) {
                localStorage.setItem(COUNTRY_SELECTOR_PERSIST_KEY, 'true')
              }
            }
          } else {
            const languageOptions = languages.map((language) => {
              const displayValue = String(
                new Intl.DisplayNames([language], { type: 'language' }).of(
                  language
                )
              )
              if (language === getDefaultLanguage(val.key)) {
                return { key: language, displayValue, checked: true }
              }
              return { key: language, displayValue }
            })
            setLanguageOptions(languageOptions)
            setChosenLanguage(getDefaultLanguage(val.key))
            setShowLanguageSelector(true)
          }
        } else {
          onChangeIsOpenCallback?.(false)
          onChange?.({ country: val.key, language: undefined })
        }
      } else {
        onChangeSelectedCountryCallback(val.key)
        onChangeIsOpenCallback?.(false)
        onChange?.({ country: val.key, language: undefined })

        if (persistSelection) {
          localStorage.setItem(COUNTRY_SELECTOR_PERSIST_KEY, 'true')
        }
      }
    },
    [
      router,
      countries,
      countryOptions,
      onChange,
      withLanguageSelector,
      persistSelection,
      onChangeIsOpenCallback,
      confirmOnSelect,
      onChangeSelectedCountryCallback,
    ]
  )

  const onChangeLanguageCallback = useCallback(
    (event: FormEvent<HTMLSelectElement>) => {
      if (event.currentTarget && event.currentTarget.value) {
        const language = String(event.currentTarget.value)
        setChosenLanguage(language)
      }
    },
    []
  )

  const onApplyButtonClick = useCallback(async () => {
    onChangeIsOpenCallback?.(false)
    if (selectedCountry) {
      onChange?.({
        country: selectedCountry,
        language: chosenLanguage,
      })
    }
    if (persistSelection) {
      localStorage.setItem(COUNTRY_SELECTOR_PERSIST_KEY, 'true')
    }
  }, [
    chosenLanguage,
    onChange,
    selectedCountry,
    onChangeIsOpenCallback,
    persistSelection,
  ])

  return (
    <>
      <FilterableList
        cy="countryselector"
        filterKey={COUNTRY_SELECTOR_FILTER_KEY}
        onValueSelect={onChangeCountryCallback}
        options={countryOptions}
        standalone={isMainSiteSwitcher}
        {...countrySelectorOverrides}
      >
        {(item) => {
          return (
            <FilterableListItem key={item.key} textValue={item.value}>
              {item.displayValue}
            </FilterableListItem>
          )
        }}
      </FilterableList>

      {showLanguageSelector && (
        <>
          <SimpleSelect
            autoFocus={true}
            options={languageOptions}
            onChange={onChangeLanguageCallback}
            {...languageSelectorOverrides}
          />
          {!ApplyLanguageButton && (
            <Button
              as="button"
              onClick={onApplyButtonClick}
              size="medium"
              variant="primary"
              className="my-3 ml-4"
            >
              Apply
            </Button>
          )}
        </>
      )}

      {ApplyLanguageButton && (
        <ApplyLanguageButton onClickHandler={onApplyButtonClick} />
      )}
    </>
  )
}
