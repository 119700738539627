import Cookies from 'universal-cookie'
import type { CookieSetOptions } from 'universal-cookie'

export class KeyValueStorage {
  static HOSTNAME =
    typeof window !== 'undefined' ? window.location.hostname : ''

  static GET_COOKIES_PARAMS = (hostname?: string) => ({
    path: '/',
    domain: hostname || KeyValueStorage.getHostName(KeyValueStorage.HOSTNAME),
  })

  private static validateStorageKeyValue(key: unknown, value: unknown) {
    if (typeof key !== 'string') {
      throw Error(
        `The storage key must be a string. You passed ${key}, which is of type ${typeof key}`
      )
    }

    if (typeof value !== 'string') {
      throw Error(
        `The storage value must be a string. You passed ${value}, which is of type ${typeof value}`
      )
    }
  }

  static setValue(
    storageKey: string,
    value: string,
    options: CookieSetOptions = {},
    hostname?: string
  ) {
    this.validateStorageKeyValue(storageKey, value)

    if (
      KeyValueStorage.HOSTNAME === 'localhost' ||
      hostname?.indexOf('localhost') !== -1
    ) {
      return new Cookies().set(storageKey, value, { path: '/', ...options })
    }
    return new Cookies().set(storageKey, value, {
      ...options,
      ...KeyValueStorage.GET_COOKIES_PARAMS(hostname),
    })
  }

  static getValue(storageKey: string) {
    return new Cookies().get(storageKey)
  }

  static getAllValues() {
    return new Cookies().getAll()
  }

  static deleteValue(valueKey: string) {
    return new Cookies().remove(valueKey)
  }

  static getHostName(hostname: string): string {
    const strippedHostname = hostname.replace(/www./, '')
    return `.${strippedHostname}`
  }
}
