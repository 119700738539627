import { SdsService } from '@abcam-web/search/data-access'
import { useGetSdsCountriesQuery } from '@abcam-web/shared/data-access/product-schema'
import { useEffect, useState } from 'react'
import type { IntlShape } from 'react-intl'
import type { SelectOption } from '@lego/ui/select'
import type {
  SafetyDatasheet,
  SafetyDatasheetHook,
  SafetyDatasheetHookStatus,
} from '../use-safety-datasheet.type'

function updateSelection(
  selection: SafetyDatasheetHook['selection'] = {},
  productCode: string,
  update: {
    languages?: SelectOption[]
    country?: string
    language?: string
    datasheets?: SafetyDatasheet[]
  },
  intl: IntlShape
) {
  const updatedSelection = Object.assign({}, selection)
  updatedSelection[productCode] = Object.assign(
    selection[productCode] || {
      languages: [
        {
          key: 0,
          displayValue: intl.formatMessage({ id: 'safetyDatasheet.language' }),
        },
      ],
    },
    update
  )
  return updatedSelection
}

const getDefaultLanguagesList = (intl: IntlShape): SelectOption[] => {
  return [
    {
      key: 0,
      displayValue: intl
        ? intl.formatMessage({ id: 'safetyDatasheet.language' })
        : 'Language',
    },
  ]
}

export const useSafetyDatasheet = (
  productCode: string,
  intl: IntlShape
): SafetyDatasheetHook => {
  const [selection, setSelection] = useState(
    updateSelection({}, productCode, {}, intl)
  )
  const [status, setStatus] = useState<SafetyDatasheetHookStatus>('initial')

  const countriesQuery = useGetSdsCountriesQuery({
    variables: {
      query: {
        productCode,
      },
    },
  })

  if (!selection[productCode]) {
    setSelection(
      updateSelection(
        selection,
        productCode,
        {
          languages: getDefaultLanguagesList(intl).slice(),
        },
        intl
      )
    )
  }

  const sdsService = new SdsService()
  const setCountry = (country?: string) => {
    setSelection(
      updateSelection(
        selection,
        productCode,
        {
          country: country,
        },
        intl
      )
    )
  }

  const setLanguage = (language?: string) => {
    setSelection(
      updateSelection(selection, productCode, { language: language }, intl)
    )
  }

  const setDatasheets = (datasheets: SafetyDatasheet[]) => {
    setSelection(
      updateSelection(selection, productCode, { datasheets: datasheets }, intl)
    )
  }

  const countryFromCode = (productCode?: string) =>
    selection[productCode || '']?.country
  const language = (productCode?: string) =>
    selection[productCode || '']?.language

  const country = countryFromCode(productCode)

  useEffect(() => {
    async function fetchLanguages(countryInternal: string) {
      setStatus('loading')
      const languageResults = await sdsService.getLanguages(
        productCode,
        countryInternal
      )

      const newSelectOptions =
        languageResults.length > 0
          ? languageResults.map((selectOption, index) => ({
              ...selectOption,
              isPreselected: index === 0,
            }))
          : getDefaultLanguagesList(intl).slice()

      setSelection(
        updateSelection(
          selection,
          productCode,
          { languages: newSelectOptions },
          intl
        )
      )

      if (languageResults.length > 0) {
        setLanguage(languageResults[0]?.key?.toString() || '')
        setStatus('results')
      } else {
        setStatus('no_results')
      }
    }

    if (country) {
      fetchLanguages(country || '')
    }

    return () => {
      setStatus('initial')
      updateSelection({}, productCode, {}, intl)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, productCode])

  useEffect(() => {
    async function fetchSds(languageInternal: string) {
      setStatus('loading')
      const results = await sdsService.getSds(
        productCode,
        country || '',
        languageInternal
      )

      setDatasheets(results)
      setStatus(results.length ? 'results' : 'no_results')
    }

    if (language(productCode)) {
      fetchSds(language(productCode) || '')
    }

    return () => {
      setStatus('initial')
      updateSelection({}, productCode, {}, intl)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language(productCode), productCode])

  useEffect(() => {
    setSelection(updateSelection({}, productCode, {}, intl))
    setStatus('initial')
  }, [intl, productCode])

  return {
    setCountry,
    setLanguage,
    status,
    countryFromCode,
    selection,
    countriesQuery,
  }
}
