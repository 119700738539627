import {
  Contact,
  OrderResponseBillToContact,
  OrderResponseShipToContact,
} from '@abcam-web/shared/data-access/ecommerce-schema'
import { SearchableDropdown } from '@abcam-web/shared/ecommerce/components'
import { SimpleTextInput } from '@lego/ui/form-elements'
import { AlertIcon, Bin } from '@lego/ui/icons'
import { Notification } from '@lego/ui/notification'
import classNames from 'classnames'
import { SearchableDropdownStrings } from 'libs/shared/ecommerce/components/src/lib/searchable-dropdown/searchable-dropdown'
import { isEmpty, isNil, omitBy, uniqueId } from 'lodash'
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import {
  components,
  FormatOptionLabelMeta,
  SingleValueProps,
} from 'react-select'

import { menuListOptions } from '../menu-list'
import { TelephoneInput } from '../telephone-input'
import styles from './contact-info-v2.module.css'

type OrderContact = OrderResponseShipToContact | OrderResponseBillToContact

type ResourceStrings = {
  title?: string
  description?: string
  contactHint?: string
  emailHint?: string
  additionalEmailHint?: string
  telephoneNumberHint?: string
  validationNotFound?: string
  validationOrgDoesNotMatch?: string
}

type OrderRelatedData = {
  additionalEmails: string[]
  addedToOrderContact?: OrderResponseShipToContact | OrderResponseBillToContact
  forAttentionOf?: string
}

export interface ContactInfoV2Props {
  id: string
  dropdownId: string
  loading?: boolean
  isDifferentAddressOrg?: boolean
  disableable?: boolean
  contacts?: Contact[]
  telephoneNumberRequired?: boolean
  contactFieldValues?: OrderContact
  selectedContact?: OrderContact
  defaultCountry?: string
  hideAdditionalEmail?: boolean
  onDropdownInputChange?: (data: string) => void
  isContactsDropdownPaginated?: boolean
  dropdownResourceStrings: Record<SearchableDropdownStrings, string>
  onContactSelect: ({
    contact,
    contactNotes,
  }: {
    contact: OrderResponseShipToContact | OrderResponseBillToContact | undefined
    contactNotes?: Contact['notes']
  }) => void
  onContactsMenuScrollCallback?: () => void
  onClickReset?: () => void
  onAdditionalEmailDelete?: (newEmails: string[]) => void
  resourceStrings?: ResourceStrings
  orderRelatedData?: OrderRelatedData
}

const getAddedToOrderOption = (
  option?: OrderResponseShipToContact | OrderResponseBillToContact
): ReactNode => {
  const {
    firstName = 'First name Missing',
    lastName = 'Last Name Missing',
    contactRegistryId = 'ID Missing',
    emails = ['Email Missing'],
    phone = {
      countryCode: 'Country Code Missing',
      telephoneNumber: 'Phone Number Missing',
    },
  } = option || {}

  return (
    <div className="text-body-small">
      <p>{firstName + ' ' + lastName + ' (' + contactRegistryId + ')'}</p>
      <div>
        <p className="text-grey-20">{emails[0]}</p>
        <p className="text-grey-20">
          {phone.countryCode + ' ' + phone.telephoneNumber}
        </p>
        <p className="mt-3 text-grey-20">
          <b>{'Added to order'}</b>
        </p>
      </div>
    </div>
  )
}

const OptionListItem: FC<{ option?: Contact | OrderContact }> = ({
  option,
}) => {
  if (!option) {
    return null
  }

  const {
    firstName = 'First name Missing',
    lastName = 'Last Name Missing',
    contactRegistryId = 'ID Missing',
    phone,
  } = option || {}
  let email
  if ('emails' in option && option.emails?.[0]) {
    email = option.emails[0]
  }

  if ('email' in option && option.email) {
    email = option.email
  }

  return (
    <div>
      <p>{firstName + ' ' + lastName}</p>
      {email && <p className="text-grey-20">{email}</p>}
      {phone && (
        <p className="text-grey-20">
          {phone?.countryCode + ' ' + phone?.telephoneNumber}
        </p>
      )}
      <p className="text-grey-20">{`(${contactRegistryId})`}</p>
    </div>
  )
}

const getOptionLabel = (contact?: {
  firstName?: string
  lastName?: string
  contactRegistryId?: string
}): string => {
  const {
    firstName = 'First name Missing',
    lastName = 'Last name Missing',
    contactRegistryId = 'ID Missing',
  } = contact || {}

  return `${firstName} ${lastName} (${contactRegistryId})`
}

type Option = {
  label: string
  value: Contact | OrderContact | undefined
}

const { section, infoGroup, fieldsGroup, fieldItem, subTitle, subTitleBody } =
  styles

const CustomSingleValue = (props: SingleValueProps<Option>) => (
  <components.SingleValue className="text-body-xmedium" {...props}>
    {props.data?.label}
  </components.SingleValue>
)

const ContactInfoV2: FC<ContactInfoV2Props> = ({
  id,
  dropdownId,
  selectedContact,
  contacts,
  contactFieldValues,
  loading = false,
  isDifferentAddressOrg,
  disableable,
  telephoneNumberRequired = true,
  defaultCountry,
  hideAdditionalEmail = false,
  onContactSelect,
  onDropdownInputChange,
  onContactsMenuScrollCallback,
  onClickReset,
  onAdditionalEmailDelete,
  resourceStrings = {},
  orderRelatedData = { additionalEmails: [], forAttentionOf: '' },
  isContactsDropdownPaginated,
  dropdownResourceStrings,
  ...rest
}) => {
  const [emails, setEmails] = useState(contactFieldValues?.emails || [])
  const [formKey, setFormKey] = useState(uniqueId())

  const {
    title,
    description,
    contactHint,
    emailHint,
    additionalEmailHint,
    telephoneNumberHint,
  } = resourceStrings

  const generateNewFormKey = () => {
    setFormKey(uniqueId())
  }

  useEffect(() => {
    const contactWithoutNill = omitBy(contactFieldValues, isNil) as
      | OrderResponseShipToContact
      | OrderResponseBillToContact

    if (
      contactWithoutNill &&
      !isEmpty(contactWithoutNill) &&
      contactWithoutNill?.firstName !== '' &&
      contactWithoutNill?.lastName !== ''
    ) {
      setEmails(contactWithoutNill?.emails)
      generateNewFormKey()
    }
  }, [contactFieldValues, contacts])

  const menuListHeader = useMemo(
    () => getAddedToOrderOption(orderRelatedData.addedToOrderContact),
    [orderRelatedData.addedToOrderContact]
  )

  const options: Option[] = useMemo(
    () =>
      (contacts || []).map((contact) => ({
        label: getOptionLabel(contact),
        value: { ...contact, id: uniqueId() },
      })),
    [contacts]
  )

  const mappedSelectedOption = useMemo(() => {
    const { contactRegistryId, organisationRegistryId } = selectedContact || {}

    if (selectedContact && contactRegistryId && organisationRegistryId) {
      return {
        label: getOptionLabel(selectedContact),
        value: { ...selectedContact, id: uniqueId() },
      }
    }

    return undefined
  }, [selectedContact])

  const isValidQueriedContacts = contacts && contacts.length > 0

  const formatOptionLabelCallback = useCallback(
    (option: Option, meta: FormatOptionLabelMeta<Option>) => {
      const { label, value } = option

      const isResetOption = Boolean(!option?.value?.contactRegistryId)

      if (isResetOption) {
        const Option = menuListOptions.optionReset
        return (
          <Option onClick={onClickReset}>
            {dropdownResourceStrings.invalidValue}
          </Option>
        )
      }

      const isSelected = mappedSelectedOption?.label === label
      const isOptionSelected = isSelected && meta.context === 'menu'

      const variant = isOptionSelected ? 'selectedOption' : 'default'

      const Option = menuListOptions[variant]

      return (
        <Option contentClassName={'text-body-small'}>
          <OptionListItem option={value} />
        </Option>
      )
    },
    [
      dropdownResourceStrings.invalidValue,
      mappedSelectedOption?.label,
      onClickReset,
    ]
  )

  const warningMessage = useMemo(() => {
    const { contactRegistryId, organisationRegistryId } = selectedContact || {}

    if (loading) {
      return ''
    }

    if (!contactRegistryId && !organisationRegistryId) {
      return resourceStrings.validationNotFound
    }

    if (isDifferentAddressOrg) {
      return resourceStrings.validationOrgDoesNotMatch
    }

    return ''
  }, [
    selectedContact,
    loading,
    isDifferentAddressOrg,
    resourceStrings.validationNotFound,
    resourceStrings.validationOrgDoesNotMatch,
  ])

  const userError = Boolean(warningMessage) && isValidQueriedContacts

  const onContactDropdownSelect = useCallback(
    (data: any) => {
      generateNewFormKey()

      if (!data) {
        setEmails([])
        onContactSelect({ contact: undefined })

        return
      }

      const emails = [data?.email || '', ...orderRelatedData.additionalEmails]
      setEmails(emails)

      onContactSelect({
        contact: {
          contactRegistryId: data.contactRegistryId,
          organisationRegistryId: data.organisationRegistryId,
          forAttentionOf: orderRelatedData.forAttentionOf,
          emails,
          phone: {
            countryCode: data.phone?.countryCode,
            telephoneNumber: data.phone?.telephoneNumber || '',
          },
          firstName: data.firstName,
          lastName: data.lastName,
        },
        contactNotes: data?.notes,
      })
    },
    [
      onContactSelect,
      orderRelatedData.additionalEmails,
      orderRelatedData.forAttentionOf,
    ]
  )

  return (
    <div className={section}>
      <div className={infoGroup}>
        {title && <span className={subTitle}>{title}</span>}
        {description && <div className={subTitleBody}>{description}</div>}
      </div>

      <div className={fieldsGroup}>
        <fieldset>
          <div className={fieldItem}>
            <SearchableDropdown<Option['value']>
              loading={loading}
              instanceId={dropdownId}
              resourceStrings={dropdownResourceStrings}
              options={options}
              selectedOption={mappedSelectedOption}
              label="Full name"
              onChange={onContactDropdownSelect}
              onInputChange={onDropdownInputChange}
              menuListHeader={menuListHeader}
              menuScrollToBottomCallback={onContactsMenuScrollCallback}
              filterOption={null}
              isPaginated={isContactsDropdownPaginated}
              customComponents={{ SingleValue: CustomSingleValue }}
              formatOptionLabelOverride={formatOptionLabelCallback}
              error={userError}
            />
            {Boolean(warningMessage) && (
              <Notification
                dataCy="notification-sales-cloud"
                className="mt-5 font-normal"
                variant="negative"
              >
                {warningMessage}
              </Notification>
            )}
          </div>
          <div className={fieldItem}>
            <SimpleTextInput
              labelClassName="text-body-small"
              inputClassName="text-body-small"
              wrapperClassName="mt-4"
              name="firstName"
              label="First name"
              id={contactFieldValues?.firstName}
              key={`firstName-${formKey}`}
              required
              defaultValue={contactFieldValues?.firstName ?? ''}
            />
          </div>
          <div className={fieldItem}>
            <SimpleTextInput
              labelClassName="text-body-small"
              inputClassName="text-body-small"
              defaultValue={contactFieldValues?.lastName ?? ''}
              required
              wrapperClassName="mt-4"
              name="lastName"
              id={contactFieldValues?.lastName}
              key={`lastName-${formKey}`}
              label="Last name"
            />
          </div>
          <div className={fieldItem}>
            {emails?.map((item, index) => (
              <div key={index} className={classNames({ flex: index })}>
                <SimpleTextInput
                  labelClassName="text-body-small"
                  inputClassName="text-body-small"
                  wrapperClassName="mt-4"
                  defaultValue={item ?? ''}
                  required
                  id={emails[0]}
                  onChange={(e) => {
                    const value = (e.target as HTMLTextAreaElement).value
                    setEmails((currentEmails) => {
                      const newEmails = [...currentEmails]
                      newEmails[index] = value
                      return newEmails
                    })
                  }}
                  key={`email-${index}-${formKey}`}
                  className="mb-0"
                  hintClassName="text-body-small mt-1.5 text-grey-20"
                  hint={index === 0 ? emailHint : additionalEmailHint}
                  type="email"
                  name={index === 0 ? 'email' : 'additionalEmail'}
                  placeholder="john@doe.com"
                  label={
                    index === 0
                      ? 'Email address'
                      : 'Additional email address (optional)'
                  }
                />
                {index !== 0 && (
                  <Bin
                    onClick={() => {
                      const newEmailsList = [...emails]
                      newEmailsList.splice(index, 1)
                      setEmails(newEmailsList)
                      onAdditionalEmailDelete?.(newEmailsList)
                    }}
                    className="mt-auto mb-3 ml-2 cursor-pointer"
                  />
                )}
              </div>
            ))}
            {emails?.length < 2 && !hideAdditionalEmail && (
              <div
                onClick={() => {
                  const newEmails = [...emails]
                  newEmails.push('')
                  setEmails(newEmails)
                }}
                className="font-semibold text-right cursor-pointer hover:underline text-body-small text-blue-default"
              >
                Add additional email address
              </div>
            )}
          </div>

          <div className={fieldItem}>
            {!loading && (
              <div className="text-body-small">
                <label htmlFor="telephoneInput" className="font-semibold ">
                  Phone number
                </label>
                {telephoneNumberHint && (
                  <p className="mt-1.5 text-grey-20 font-body">
                    {telephoneNumberHint}
                  </p>
                )}
              </div>
            )}
            <TelephoneInput
              key={`telephone-input-${formKey}`}
              defaultCountry={defaultCountry}
              id={id}
              name="telephone"
              errorMessage="Telephone number not valid"
              countryCodeDefaultValue={contactFieldValues?.phone?.countryCode}
              telephoneNumberDefaultValue={
                contactFieldValues?.phone?.telephoneNumber
              }
              required={telephoneNumberRequired}
            />
            {contactHint && (
              <label className="font-normal text-ui-medium text-grey50">
                {contactHint}
              </label>
            )}
          </div>
        </fieldset>
        {warningMessage && (
          <Notification
            dataCy="notification-sales-cloud"
            className="mt-5 ml-auto mr-0 font-normal w-fit-content"
            variant="negative"
            size="small"
            icon={<AlertIcon />}
            centered
          >
            <span className="text-body-small">Unknown contact</span>
          </Notification>
        )}
      </div>
    </div>
  )
}

export default ContactInfoV2
