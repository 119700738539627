export const register = {
  firstName: 'input_firstName',
  lastName: 'input_lastName',
  email: 'input_email',
  password: 'input_password',
  submit: 'button_register',
  marketingOptIn: 'checkbox_marketingOptIn',
  processDataOptIn: 'checkbox_processDataOptIn',
  offShoreDataOptIn: 'checkbox_offShoreDataOptIn',
  privacyOptIn: 'checkbox_privacyOptIn',
}
