import { forwardRef } from 'react'
import { InteractiveElementProps } from './interactive-element.type'
import styles from './interactive-element.module.css'
import cn from 'classnames'

const InteractiveElement = forwardRef<HTMLDivElement, InteractiveElementProps>(
  (props, ref) => {
    const { children, fullWidth = false, error = false, ...otherProps } = props

    const classes = cn(styles.interactiveElement, {
      [styles.fullWidth]: fullWidth,
      [styles.error]: error,
    })
    return (
      <div
        tabIndex={0}
        role={'button'}
        className={classes}
        ref={ref}
        {...otherProps}
      >
        {children}
      </div>
    )
  }
)

export { InteractiveElement }
