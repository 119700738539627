import {
  BasketModel,
  Cache,
  OrderRequestModel,
  OrderResponseModel,
  OrderStatus,
  OrderType,
  SalesChannel,
} from '@abcam-web/shared/data-access/ecommerce-schema'
import { toast } from '@abcam-web/shared/ecommerce/components'
import { getValidationErrors } from '@abcam-web/shared/ecommerce/utilities'
import { OrderValidationState } from '@abcam-web/shared/orderprocessing/order/order-validation-modal'
import { SelectOption } from '@lego/ui/select'
import { DeliveryType } from 'libs/shared/ecommerce/components/src/lib/delivery-options/delivery-options'
import { cloneDeep, isEmpty, isString, set } from 'lodash'

export const resourceStrings = {
  'header.button.submit': 'Submit',
  'header.button.resubmit': 'Submit again',
}

export function getSubmitButtonLabel(orderStatus: string) {
  return orderStatus !== OrderStatus.SUBMITTED
    ? resourceStrings['header.button.submit']
    : resourceStrings['header.button.resubmit']
}

export function getShippingTaxes(
  taxes?: BasketModel['summary']['taxes']
): NonNullable<BasketModel['summary']['taxes']>[number] | null {
  const shippingTaxes = taxes?.find((tax) => tax.type === 'SERVICE')
  if (shippingTaxes) {
    return shippingTaxes
  }
  return null
}

const getCustomerRequestModelFromOrderResponse = (
  customers?: OrderResponseModel['customers']
): OrderRequestModel['customers'] => {
  const customersRequestModel: OrderRequestModel['customers'] = {}
  if (customers) {
    const { shipTo, billTo, soldTo } = customers

    customersRequestModel.soldTo = {
      contactRegistryId: soldTo?.contactRegistryId,
      organisationRegistryId: soldTo?.organisationRegistryId,
    }
    if (shipTo) {
      const { organisationName: _, ...allowedShipToFields } = shipTo
      if (!isEmpty(allowedShipToFields)) {
        customersRequestModel.shipTo = allowedShipToFields
      }
    }

    if (billTo) {
      const { organisationName: _, ...allowedBillToFields } = billTo
      if (!isEmpty(allowedBillToFields)) {
        customersRequestModel.billTo = allowedBillToFields
      }
    }
    return customersRequestModel
  } else {
    return undefined
  }
}

const getOrderRequestModelFromOrder = (
  order: OrderResponseModel,
  cache?: Cache
): OrderRequestModel => {
  const orderRequestModel = cloneDeep(order)
  if (!orderRequestModel?.notes.settings?.ignoreCustomerNote) {
    set(orderRequestModel, 'notes.settings', undefined)
  }
  set(orderRequestModel, 'status', undefined)
  set(orderRequestModel, 'meta', {
    incidentNumbersWithReasons: order?.meta?.incidentNumbersWithReasons,
    links: order?.meta?.links,
    holds: order?.meta?.holds,
  })
  set(
    orderRequestModel,
    'customers',
    getCustomerRequestModelFromOrderResponse(order.customers)
  )

  if (
    (cache?.shipping?.contactGoodsOutNote ||
      cache?.shipping?.orgGoodsOutNote) &&
    typeof order.notes.goodsOut !== 'string'
  ) {
    set<OrderRequestModel>(
      orderRequestModel,
      'notes.goodsOut',
      cache.shipping?.contactGoodsOutNote ||
        cache.shipping?.orgGoodsOutNote ||
        ''
    )
  }

  set(orderRequestModel, 'orderNumber', undefined)
  set(orderRequestModel, 'id', undefined)
  set(orderRequestModel, 'creationDate', undefined)
  set(orderRequestModel, 'lastModificationDate', undefined)
  set(orderRequestModel, 'notes.orderStatusProgress', undefined)

  set(orderRequestModel, 'addresses.userInputBilling', undefined)
  set(orderRequestModel, 'addresses.userInputShipping', undefined)

  set(orderRequestModel, 'billing.paymentTerms', undefined)
  set(orderRequestModel, 'billing.creditCardPaymentAuthorised', undefined)
  set(orderRequestModel, 'billing.paymentMethod', order?.billing?.paymentMethod)

  set(orderRequestModel, 'validationErrors', undefined)
  return orderRequestModel
}

const updateSummaryFieldsForOrder = (
  order: OrderResponseModel,
  orderUpdates: Partial<OrderResponseModel>
): OrderRequestModel => {
  const orderModel = getOrderRequestModelFromOrder(order as OrderResponseModel)
  if (orderUpdates.shipping) {
    set(orderModel, 'shipping', {
      ...orderModel.shipping,
      shippingCosts: orderUpdates?.shipping?.shippingCosts,
      freeShipping: !!orderUpdates.shipping?.freeShipping,
      courierAccountNumber: orderUpdates.shipping?.courierAccountNumber,
      deliveryType: orderUpdates.shipping?.deliveryType,
    })
  }
  if (orderUpdates.billing) {
    set(orderModel, 'billing', {
      ...orderModel?.billing,
      taxExempt: !!orderUpdates.billing?.taxExempt,
    })
  }
  return orderModel
}

const areFormsValid = (document: Document): boolean => {
  const shippingAddressForm = document?.getElementById(
    'shipping-address-form'
  ) as HTMLFormElement
  const billingAddressForm = document?.getElementById(
    'billing-address-form'
  ) as HTMLFormElement
  const shippingContactForm = document?.getElementById(
    'shipping-contact-form'
  ) as HTMLFormElement
  const billingContactForm = document?.getElementById(
    'billing-contact-form'
  ) as HTMLFormElement
  const soldToForm = document?.getElementById('sold-to-form') as HTMLFormElement
  const shippingOptionsForm = document?.getElementById(
    'shipping-options-form'
  ) as HTMLFormElement

  shippingAddressForm.requestSubmit()
  billingAddressForm.requestSubmit()
  shippingContactForm.requestSubmit()
  billingContactForm.requestSubmit()
  soldToForm.requestSubmit()
  shippingOptionsForm.requestSubmit()

  const allFormAreValid =
    shippingAddressForm.checkValidity() &&
    billingAddressForm.checkValidity() &&
    shippingContactForm.checkValidity() &&
    billingContactForm.checkValidity() &&
    soldToForm.checkValidity() &&
    shippingOptionsForm.checkValidity()

  return allFormAreValid
}

const orderSubmitSuccessToast = () =>
  toast.success('Order has been successfully submitted', { autoClose: 3000 })
const orderSubmitErrorToast = (errorInfo?: Error) =>
  toast.error({
    content: 'Something went wrong while submitting the order',
    errorInfo,
  })
const orderUpdateSuccessToast = () =>
  toast.success('Order has been successfully edited', { autoClose: 3000 })
const orderUpdateErrorToast = (errorInfo?: Error) =>
  toast.orderGenericError(errorInfo)
const basketUpdateSuccessToast = () =>
  toast.success('Basket has been successfully updated', {
    autoClose: 3000,
  })
const basketUpdateErrorToast = (errorInfo?: Error) =>
  toast.error({
    content: 'Something went wrong while updating the basket',
    errorInfo,
  })

const SOURCES = [
  SalesChannel.ONLINE,
  SalesChannel.FAX,
  SalesChannel.EDI,
  SalesChannel.COLLABORATOR,
  SalesChannel.POST,
]

const geOtherSourceOptions = (source: SalesChannel | undefined) => {
  const sourceOptions = [
    {
      displayValue: 'Select a source',
      key: 'undefined',
      isPlaceholder: true,
      isPreselected: source === undefined,
    },
    {
      displayValue: 'FAX',
      key: SalesChannel.FAX,
      isPreselected: source === SalesChannel.FAX,
    },
    {
      displayValue: 'Collaborator',
      key: SalesChannel.COLLABORATOR,
      isPreselected: source === SalesChannel.COLLABORATOR,
    },
    {
      displayValue: 'Post',
      key: SalesChannel.POST,
      isPreselected: source === SalesChannel.POST,
    },
  ]

  if (
    source &&
    [SalesChannel.EDI, SalesChannel.ONLINE].includes(source as SalesChannel)
  ) {
    const displayValue =
      source === SalesChannel.ONLINE ? 'Online' : SalesChannel.EDI
    sourceOptions.push({
      displayValue,
      key: source,
      isPreselected: true,
    })
  }
  return sourceOptions
}

const getOrderTypeLabel = (orderType: OrderType) => {
  if (orderType === OrderType.QUOTE) {
    return 'Quote'
  }
  return 'Order'
}

const getChangeOrderStatusOptions = (
  currentorderStatus: OrderStatus,
  selectedOrderStatus: OrderStatus,
  isQuote: boolean
): SelectOption[] => {
  const options = isQuote
    ? [
        {
          displayValue:
            currentorderStatus === OrderStatus.AWAITING_APPROVAL
              ? 'Awaiting Approval (current status)'
              : 'Awaiting Approval',
          key: OrderStatus.AWAITING_APPROVAL,
          isPreselected:
            !!selectedOrderStatus &&
            selectedOrderStatus === OrderStatus.AWAITING_APPROVAL,
        },
        {
          displayValue:
            currentorderStatus === OrderStatus.IN_PROGRESS
              ? 'In Progress (current status)'
              : 'In Progress',
          key: OrderStatus.IN_PROGRESS,
          isPreselected:
            !!selectedOrderStatus &&
            selectedOrderStatus === OrderStatus.IN_PROGRESS,
        },
      ]
    : [
        {
          displayValue:
            currentorderStatus === OrderStatus.CMO_INTERVENTION
              ? 'CMO Intervention (current status)'
              : 'CMO Intervention',
          key: OrderStatus.CMO_INTERVENTION,
          isPreselected:
            !!selectedOrderStatus &&
            selectedOrderStatus === OrderStatus.CMO_INTERVENTION,
        },
        {
          displayValue:
            currentorderStatus === OrderStatus.CUSTOMER_ACTION
              ? 'Customer Action (current status)'
              : 'Customer Action',
          key: OrderStatus.CUSTOMER_ACTION,
          isPreselected:
            !!selectedOrderStatus &&
            selectedOrderStatus === OrderStatus.CUSTOMER_ACTION,
        },
        {
          displayValue:
            currentorderStatus === OrderStatus.ABCAM_INTERVENTION
              ? 'Abcam Intervention (current status)'
              : 'Abcam Intervention',
          key: OrderStatus.ABCAM_INTERVENTION,
          isPreselected:
            !!selectedOrderStatus &&
            selectedOrderStatus === OrderStatus.ABCAM_INTERVENTION,
        },
      ]

  return options.sort((option) => (option.isPreselected ? -1 : 1))
}

const getReasonOptions = (reason?: string) => [
  {
    displayValue: 'Select a reason',
    key: 'undefined',
    isPlaceholder: true,
  },
  {
    displayValue: 'ETA update',
    key: 'eta_update',
    isPreselected: reason === 'eta_update',
  },
  {
    displayValue: 'Placing order',
    key: 'placing_order',
    isPreselected: reason === 'placing_order',
  },
  {
    displayValue: 'Courier tracking',
    key: 'courier_tracking',
    isPreselected: reason === 'courier_tracking',
  },
  {
    displayValue: 'Complaint',
    key: 'complaint',
    isPreselected: reason === 'complaint',
  },
  {
    displayValue: 'Pay by credit card',
    key: 'pay_by_credit_card',
    isPreselected: reason === 'pay_by_credit_card',
  },
  {
    displayValue: 'Invoicing',
    key: 'invoicing',
    isPreselected: reason === 'invoicing',
  },
  {
    displayValue: 'Bespoke order query',
    key: 'bespoke_order_query',
    isPreselected: reason === 'bespoke_order_query',
  },
  {
    displayValue: 'Pre-payment received',
    key: 'pre_payment_received',
    isPreselected: reason === 'pre_payment_received',
  },
]

const OTHER_SOURCE = 'OTHER_SOURCE'

const radioOptions = [
  {
    value: SalesChannel.EMAIL,
    displayValue: 'Email (e)',
  },
  {
    value: SalesChannel.TELEPHONE,
    displayValue: 'Phone (p)',
  },
  {
    value: SalesChannel.PO2GO,
    displayValue: 'PO2GO (g)',
  },
  { value: OTHER_SOURCE, displayValue: 'Other' },
]

const INCIDENT_PATTERN = '^[0-9]{6}-[0-9]{6}$'

type Incident = { number: string; reason?: string }

const validateIncident = (incident: Incident) => {
  return new RegExp(INCIDENT_PATTERN).test(incident?.number)
}

const validateIncidents = (incidents: Incident[]) => {
  const isDefaultIncidents =
    incidents.length === 1 && incidents[0].number === ''
  if (isDefaultIncidents) {
    return true
  }
  const hasInvalidIncident = incidents.some(
    (incident) => !validateIncident(incident)
  )
  return !hasInvalidIncident
}

enum MenuActions {
  'open-source-incident-modal' = 'open-source-incident-modal',
  'change-order-status-modal' = 'change-order-status-modal',
  'cancel-order-modal' = 'cancel-order-modal',
  'issue-review-and-pay-email-modal' = 'issue-review-and-pay-email-modal',
  'open-resend-by-email-modal' = 'open-resend-by-email-modal',
}

const getDefaultDeliveryOption = (
  shippingDetails?: OrderResponseModel['shipping'],
  standardChargeValue?: number,
  handlingOnlyChargeValue?: number
): DeliveryType | null => {
  if (!shippingDetails) {
    return DeliveryType.STANDARD
  }

  if (shippingDetails.deliveryType) {
    return shippingDetails.deliveryType as DeliveryType
  }

  // This is still needed to handle old orders that don't have deliveryType
  if (shippingDetails?.shippingCosts === standardChargeValue) {
    return DeliveryType.STANDARD
  }
  if (shippingDetails?.shippingCosts === handlingOnlyChargeValue) {
    if (!isString(shippingDetails?.courierAccountNumber)) {
      return DeliveryType.PICKUP
    } else {
      return DeliveryType.OWN_COURIER
    }
  }

  return DeliveryType.STANDARD
}

function getNoteLimitFromMaxLimit({
  maxCharacterLimit,
  limitSharingNotes,
  optionalNoteIndex,
  includeOptionalNote,
  notesSeparatorLength,
}: {
  maxCharacterLimit: number
  limitSharingNotes: string[]
  optionalNoteIndex: number
  includeOptionalNote: boolean
  notesSeparatorLength: number
}) {
  const sharingNotesCount =
    limitSharingNotes.filter(Boolean).length - (includeOptionalNote ? 0 : 1)
  const maxLimit = maxCharacterLimit - notesSeparatorLength * sharingNotesCount
  const sharedNotesLength = limitSharingNotes.join('').length
  const optionalNoteLength = includeOptionalNote
    ? 0
    : limitSharingNotes[optionalNoteIndex]?.length || 0
  if (maxLimit <= 0 || maxLimit < sharedNotesLength) {
    return 0
  }
  return maxLimit - (sharedNotesLength - optionalNoteLength)
}

const hasValidationErrors = (
  validationErrors: { errors?: string[]; message?: string }[] | undefined | null
) => {
  return getValidationErrors(validationErrors).length > 0
}

const getValidationState = (
  errors: { errors?: string[]; message?: string }[] | undefined | null
): OrderValidationState => {
  return hasValidationErrors(errors) ? 'alreadyInvalidated' : 'success'
}

export const statusMapper = {
  [OrderStatus.ABCAM_INTERVENTION]: null,
  [OrderStatus.ACCEPTED_UPDATED]: 'Accepted Updated',
  [OrderStatus.APPROVED]: 'Approved',
  [OrderStatus.AWAITING_APPROVAL]: 'Awaiting Approval',
  [OrderStatus.CANCELLED]: 'Cancelled',
  [OrderStatus.CMO_INTERVENTION]: null,
  [OrderStatus.CONFIRMED]: 'Confirmed',
  [OrderStatus.CUSTOMER_ACTION]: null,
  [OrderStatus.DRAFT]: 'Draft',
  [OrderStatus.DUPLICATE]: 'Duplicate',
  [OrderStatus.EXPIRED]: 'Expired',
  [OrderStatus.IN_PROGRESS]: 'In Progress',
  [OrderStatus.ONLINE_BETA]: 'Online Beta',
  [OrderStatus.PAYMENT_PENDING]: 'Payment Pending',
  [OrderStatus.PAYMENT_PROCESSING]: 'Payment Processing',
  [OrderStatus.READY]: 'Ready',
  [OrderStatus.SUBMITTED]: 'Submitted',
  [OrderStatus.VALIDATED]: 'Validated',
  [OrderStatus.WITH_CUSTOMER]: 'With Customer',
}

export {
  areFormsValid,
  basketUpdateErrorToast,
  basketUpdateSuccessToast,
  geOtherSourceOptions,
  getChangeOrderStatusOptions,
  getDefaultDeliveryOption,
  getNoteLimitFromMaxLimit,
  getOrderRequestModelFromOrder,
  getOrderTypeLabel,
  getReasonOptions,
  getValidationErrors,
  getValidationState,
  hasValidationErrors,
  INCIDENT_PATTERN,
  MenuActions,
  orderSubmitErrorToast,
  orderSubmitSuccessToast,
  orderUpdateErrorToast,
  orderUpdateSuccessToast,
  OTHER_SOURCE,
  radioOptions,
  SOURCES,
  updateSummaryFieldsForOrder,
  validateIncident,
  validateIncidents,
}

export type { Incident }
