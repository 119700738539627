/* eslint-disable jsx-a11y/anchor-is-valid */
import { UserContext } from '@abcam-web/auth-shared/contexts/user'
import { Button } from '@lego/ui/button'
import Link from 'next/link'
import style from './dropdownContent.module.css'

import { useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { getDisplayName } from './dropdownContent.helpers'
import {
  trackAccountMenuClick,
  trackCreateAccountClick,
  trackSignInClick,
} from '@abcam-web/auth-shared/gtm-tracking/track-events'
import { useRouter } from 'next/router'
import { useGtm } from '@abcam-web/shared/data-access/tracking'
import { STAGE } from '@abcam-web/auth-shared/constants/stage-type'
import getConfig from 'next/config'
import { getMyAccountUrlForStage } from '@abcam-web/auth-shared/util/account-by-stage'
import { getNextLocaleFromCookie } from '@abcam-web/shared/utilities/localisation'
import { tags } from '@tagging-shared/authentication'

interface Props {
  loginUrl: string
  registerUrl: string
  isMobile?: boolean
}

const DropDownContent = ({ loginUrl, registerUrl, isMobile }: Props) => {
  const { user } = useContext(UserContext)
  const { formatMessage } = useIntl()
  const locale = getNextLocaleFromCookie()
  const router = useRouter()
  const gtm = useGtm()
  const stage: STAGE = getConfig().publicRuntimeConfig?.STAGE

  const contactUsUrl = useMemo(
    () =>
      typeof window === 'undefined'
        ? '/'
        : `${window.location.origin}/${locale}/contact-us`,
    [locale]
  )

  const onClickMyAccount = () => {
    const myAccountUrl = getMyAccountUrlForStage(stage ?? 'prod')
    trackAccountMenuClick({
      gtm,
      pathName: router.pathname,
      destinationPath: myAccountUrl,
    })

    window.location.href = `${window.location.origin}/my-account`
  }

  const onClickSignIn = () => {
    trackSignInClick({
      gtm,
      pathName: router.pathname,
      destinationPath: loginUrl,
    })
  }

  const onClickCreateAccount = () => {
    trackCreateAccountClick({
      gtm,
      pathName: router.pathname,
      destinationPath: registerUrl,
    })
  }

  return (
    <div className="flex flex-col w-full p-4 gap-3 min-w-[240px]">
      <div className="mb-3 md:mb-0">
        {user ? (
          <div className="flex items-center justify-between gap-3">
            <button className={style.userProfile} onClick={onClickMyAccount}>
              <div className="max-w-none md:max-w-[120px]">
                <p
                  className="text-xs font-semibold text-left truncate"
                  title={getDisplayName(user)}
                >
                  {getDisplayName(user)}
                </p>
                <p
                  className="pt-2 font-semibold text-left text-gray-400 truncate text-[10px]"
                  title={user.email}
                >
                  {user.email}
                </p>
              </div>
            </button>

            <Button
              as="a"
              overrideRouter
              href={contactUsUrl}
              className=""
              size="small"
              variant="primary"
            >
              {formatMessage({ id: 'components.accountDropDown.contactUs' })}
            </Button>
          </div>
        ) : (
          <>
            <Button
              as="a"
              overrideRouter
              href={loginUrl}
              className="w-full mb-4"
              size="small"
              variant="primary"
              data-cy={tags.signIn.accountDropDownSignInButton}
              onClick={() => onClickSignIn()}
            >
              {formatMessage({ id: 'components.accountDropDown.signin' })}
            </Button>
            <div className="flex items-center justify-between gap-4">
              <div className="text-body-small">
                <span>
                  {formatMessage({
                    id: 'components.accountDropDown.newToAbcam',
                  })}
                </span>
                <Link
                  locale={false}
                  href={registerUrl}
                  className="pl-1 text-blue-default"
                  onClick={() => onClickCreateAccount()}
                >
                  {formatMessage({
                    id: 'components.accountDropDown.createAccount',
                  })}
                </Link>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

const DropDownFooter = () => {
  const { user } = useContext(UserContext)
  const { formatMessage } = useIntl()
  const locale = getNextLocaleFromCookie()

  const contactUsUrl = useMemo(
    () =>
      typeof window === 'undefined'
        ? '/'
        : `${window.location.origin}/${locale}/contact-us`,
    [locale]
  )

  if (user) {
    return null
  }
  return (
    <div className="px-4">
      <Button
        as="a"
        overrideRouter
        href={contactUsUrl}
        className="w-full mb-4"
        size="small"
        variant="tertiaryOutline"
      >
        {formatMessage({ id: 'components.accountDropDown.contactUs' })}
      </Button>
    </div>
  )
}

export { DropDownContent, DropDownFooter }
