import { useCountry } from '@abcam-web/shared/utilities/country'
import {
  getLanguageNameFromCode,
  getNextLocaleFromCookie,
} from '@abcam-web/shared/utilities/localisation'
import {
  getPageSubType,
  getPageType,
  useGtm,
  type GTMPageType,
} from '@abcam-web/shared/data-access/tracking'
import { LogoAbcamBottom, LogoDanaherGreyscale } from '@lego/ui/icons'
import { tags } from '@tagging-shared/browse'
import Link from 'next/link'
import { useRouter, type NextRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { asAbsoluteUrl, hasBasePath } from '@abcam-web/shared/utilities/next-js'
import Image from 'next/image'
import { defaultCountry } from '@abcam-web/shared/config'
import clsx from 'clsx'
import { Accordion } from '../accordion/index'
import { gtmTracking } from './utils/gtmTracking'
import OpcosBanner from './opcos-banner'
import {
  abcamLinks,
  cnLegalInfo,
  cnSocialMediaLinks,
  socialMediaLinks,
  helpSupportLinks,
  legalLinks,
  programLinks,
  jpSocialMediaLinks,
} from './constants'
import styles from './footer.module.css'
import type { ReactNode } from 'react'

type LinkProps = {
  locale: string
  view: 'mobile' | 'desktop'
  pageData: {
    pathname: string
    language: string
    pageType: '' | GTMPageType
    pageSubType: string
  }
  isCN?: boolean
}

// Custom hook to use absolute path if app has basePath - required for correct path in checkout
const useAbsoluteUrl = () => {
  const router: NextRouter = useRouter()
  const getUrl = (relativeUrl: string, locale: string) =>
    hasBasePath(router)
      ? asAbsoluteUrl({ url: relativeUrl, router, locale })
      : relativeUrl
  return { getUrl }
}

const getLanguageFromLocale = (locale: string): string =>
  getLanguageNameFromCode(locale.slice(0, 2)) || ''

const LinksList = ({ children }: { children: ReactNode }) => (
  <ul className="mt-4 text-white text-body-medium font-body">{children}</ul>
)

const Li = ({ children }: { children: ReactNode }) => (
  <li className="mt-2 text-white opacity-80">{children}</li>
)

const HelpSupportLinks = ({ locale, view, pageData }: LinkProps) => {
  const { formatMessage } = useIntl()
  const gtm = useGtm()
  const linkRefs = useRef<Array<HTMLAnchorElement | null>>([])
  const { getUrl } = useAbsoluteUrl()
  return (
    <LinksList>
      {helpSupportLinks.map(({ dataTestId, formatMessageId, href }, index) => (
        <Li key={formatMessageId}>
          <Link
            data-testid={`${view}-${dataTestId}`}
            href={getUrl(href, locale)}
            onClick={() => {
              gtmTracking(
                gtm,
                pageData.pathname,
                pageData.pageType,
                pageData.pageSubType,
                pageData.language,
                linkRefs.current[index],
                'FALSE'
              )
            }}
            ref={(ref) => (linkRefs.current[index] = ref)}
          >
            {formatMessage({ id: formatMessageId })}
          </Link>
        </Li>
      ))}
    </LinksList>
  )
}

const AbcamLinks = ({ view, pageData }: LinkProps) => {
  const { formatMessage } = useIntl()
  const gtm = useGtm()
  const linkRefs = useRef<Array<HTMLAnchorElement | null>>([])

  return (
    <LinksList>
      {abcamLinks.map(({ dataTestId, formatMessageId, href }, index) => (
        <Li key={formatMessageId}>
          <a
            data-testid={`${view}-${dataTestId}`}
            href={href}
            onClick={() => {
              gtmTracking(
                gtm,
                pageData.pathname,
                pageData.pageType,
                pageData.pageSubType,
                pageData.language,
                linkRefs.current[index],
                'TRUE'
              )
            }}
            ref={(ref) => (linkRefs.current[index] = ref)}
          >
            {formatMessage({ id: formatMessageId })}
          </a>
        </Li>
      ))}
    </LinksList>
  )
}

const ProgramLinks = ({ locale, view, pageData, isCN }: LinkProps) => {
  const { formatMessage } = useIntl()
  const gtm = useGtm()
  const linkRefs = useRef<Array<HTMLAnchorElement | null>>([])
  const { getUrl } = useAbsoluteUrl()

  return (
    <LinksList>
      {programLinks.map(
        ({ dataTestId, formatMessageId, href, disableOnCN }, index) => {
          if (disableOnCN && isCN) {
            return
          }

          return (
            <Li key={formatMessageId}>
              <Link
                data-testid={`${view}-${dataTestId}`}
                href={getUrl(href, locale)}
                onClick={() => {
                  gtmTracking(
                    gtm,
                    pageData.pathname,
                    pageData.pageType,
                    pageData.pageSubType,
                    pageData.language,
                    linkRefs.current[index],
                    'FALSE'
                  )
                }}
                ref={(ref) => (linkRefs.current[index] = ref)}
              >
                {formatMessage({ id: formatMessageId })}
              </Link>
            </Li>
          )
        }
      )}
    </LinksList>
  )
}

const Links = ({ locale, view, pageData, isCN }: LinkProps) => {
  const { formatMessage } = useIntl()

  return [
    {
      title: formatMessage({ id: 'footer.helpSupport' }),
      body: (
        <HelpSupportLinks locale={locale} pageData={pageData} view={view} />
      ),
    },
    {
      title: formatMessage({ id: 'footer.aboutUs' }),
      body: <AbcamLinks locale={locale} pageData={pageData} view={view} />,
    },
    {
      title: formatMessage({ id: 'footer.ourPrograms' }),
      body: (
        <ProgramLinks
          isCN={isCN}
          locale={locale}
          pageData={pageData}
          view={view}
        />
      ),
    },
  ]
}

export const Footer = () => {
  const { formatMessage } = useIntl()
  const year = new Date().getFullYear()
  const { pathname } = useRouter()
  const locale = useRouter().locale || getNextLocaleFromCookie()
  const { country } = useCountry()
  const gtm = useGtm()
  const linkRefs = useRef<Array<HTMLAnchorElement | null>>([])

  // Note: page starts with the default country (US) to match the server-side and avoid a hydration error
  // The useEffect below will instantiate it with the correct country on the client-side
  const [clientCountry, setClientCountry] = useState<string>(defaultCountry)
  const [isCN, setIsCN] = useState<boolean>(false)

  useEffect(() => {
    if (country) {
      setClientCountry(country)
      setIsCN(country === 'CN')
    }
  }, [country])

  const pageData = {
    pathname: pathname,
    language: getLanguageFromLocale(locale),
    pageType: getPageType(pathname),
    pageSubType: getPageSubType(pathname) as string,
  }
  const { getUrl } = useAbsoluteUrl()

  function getSocialLinks() {
    switch (clientCountry.toLocaleLowerCase()) {
      case 'cn':
        return cnSocialMediaLinks
      case 'jp':
        return jpSocialMediaLinks
      default:
        return socialMediaLinks
    }
  }

  return (
    <>
      <footer
        className={clsx(
          'pt-8 pb-6 mt-auto text-white bg-black-0 print:hidden',
          country.toLocaleLowerCase() === 'jp' ||
            country.toLocaleLowerCase() === 'cn'
            ? styles[`footerVisibility-${clientCountry.toLocaleLowerCase()}`]
            : styles.footerVisibility
        )}
        data-cy={tags.commonTags.footWrapper}
      >
        <div className="flex flex-col mx-auto px-[30px] sm:px-[61px] xlu:px-0 xlu:max-w-[1120px]">
          <div className="flex flex-wrap items-end justify-between gap-y-4">
            <Link aria-label="Homepage link" href={getUrl('/', locale)}>
              <LogoAbcamBottom />
            </Link>
            <div
              className="flex items-center gap-x-3 mdu:gap-x-6 [&>a]:p-0"
              data-cy={tags.commonTags.footSocial}
            >
              {getSocialLinks().map(
                ({ ariaLabel, href, icon, dataTestId }, index) => (
                  <a
                    aria-label={ariaLabel}
                    className="pl-3"
                    data-testid={dataTestId}
                    href={href}
                    key={ariaLabel}
                    onClick={() => {
                      gtmTracking(
                        gtm,
                        pageData.pathname,
                        pageData.pageType,
                        pageData.pageSubType,
                        pageData.language,
                        linkRefs.current[index],
                        'TRUE'
                      )
                    }}
                    ref={(ref) => (linkRefs.current[index] = ref)}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {icon}
                  </a>
                )
              )}
            </div>
          </div>

          <div className="my-8 border-t border-white opacity-50 lgu:my-7"></div>

          <div className="flex flex-row justify-between lgd:flex-col">
            {/* Accordion is displayed on mobile & hidden on desktop */}
            <Accordion
              chevronColour="white"
              classOverrides={{
                wrapper: 'mdu:hidden text-white',
                itemHeader:
                  'flex justify-between items-center cursor-pointer font-bold',
              }}
              items={Links({ view: 'mobile', locale, pageData, isCN })}
            />
            {/* desktopView is displayed on desktop & hidden on mobile */}
            <div className="flex-row hidden m-0 mdu:flex mdu:text-body-large">
              {Links({ view: 'desktop', locale, pageData, isCN }).map(
                (link, i) => (
                  <div className="pl-20 first:pl-0 gap-y-4" key={i}>
                    <span className="font-bold">{link.title}</span>
                    {link.body}
                  </div>
                )
              )}
            </div>

            {isCN && (
              <>
                <div className="mb-6 border-t border-white opacity-50 mt-7 mdd:mt-0 lgu:hidden"></div>

                <div className="flex flex-col items-end h-fit gap-y-2 w-fit lgd:w-full mdd:mb-6">
                  <Image
                    alt="Wechat QR code"
                    data-testid="wechat-qr-code"
                    height="107"
                    src="/wechat-qr-code.png"
                    width="107"
                  />
                  <p className="text-link-small text-[#ffffffcc] text-end">
                    {formatMessage({ id: 'footer.cn.scanWechat' })}
                  </p>
                </div>
              </>
            )}
          </div>

          <div className="mb-6 border-t border-white opacity-50 mdu:my-6"></div>

          <div className="flex justify-between text-link-small">
            <div className="shrink-0 h-[84px] w-[72px]">
              <LogoDanaherGreyscale aria-label="Danaher Logo" />
            </div>
            <div className="flex flex-col items-end gap-y-4">
              <div className="flex flex-wrap justify-end text-base font-light space-x-4 opacity-80 [&>a]:text-end">
                {legalLinks.map(
                  ({ href, formatMessageId, disableOn }, index) => {
                    if (disableOn?.includes(locale)) {
                      return
                    }

                    return (
                      <Link
                        href={getUrl(href, locale)}
                        key={formatMessageId}
                        onClick={() => {
                          gtmTracking(
                            gtm,
                            pageData.pathname,
                            pageData.pageType,
                            pageData.pageSubType,
                            pageData.language,
                            linkRefs.current[index + 4],
                            'FALSE'
                          )
                        }}
                        ref={(ref) => (linkRefs.current[index + 4] = ref)}
                      >
                        {formatMessage({ id: formatMessageId })}
                      </Link>
                    )
                  }
                )}
              </div>
              <div className="flex flex-wrap justify-end font-light opacity-80 gap-x-4 [&>*]:text-end smd:flex-col">
                {isCN &&
                  cnLegalInfo.map(
                    ({ href, formatMessageId, className = '', dataTestId }) => (
                      <a
                        className={`${className}`}
                        data-testid={dataTestId}
                        href={href}
                        key={formatMessageId}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {formatMessage({ id: formatMessageId })}
                      </a>
                    )
                  )}
                <p>{formatMessage({ id: 'footer.copyright' }, { year })}</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <OpcosBanner />
    </>
  )
}
