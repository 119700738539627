import { useCallback, useEffect, useMemo, useState } from 'react'
import type { User } from '@abcam-web/auth-shared/contexts/user'
import {
  CheckoutBasketOptions,
  PunchoutUserData,
  checkoutBasket,
  getPunchoutId,
  getPunchoutSessionId,
  isPunchoutUser,
  queryUserData,
} from './utils/punchout'

/**
 * For instance of Client Side User determine if user is a "punchout" user,
 * and if so, return punchout data from server side API
 */
export function usePunchoutUser(
  /**
   * Instance of Client Side User
   */
  user: User | null | undefined
) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [punchoutUserData, setPunchoutUserData] = useState<PunchoutUserData>({
    isPunchoutUser: isPunchoutUser(user),
    welcomeText: null,
    logoUrl: null,
    arePromotionsEnabled: false,
  })

  const punchoutId = getPunchoutId(user)
  const punchoutSessionId = getPunchoutSessionId(user)

  useEffect(() => {
    if (!isPunchoutUser(user) || !punchoutId || !punchoutSessionId) {
      return
    }

    setLoading(true)
    setError(null)

    queryUserData(punchoutId, punchoutSessionId)
      .then((data) => {
        if (data) {
          setPunchoutUserData(data)
        }
      })
      .catch((error) => {
        console.error(
          'Failed to fetch Punchout session data from server',
          error
        )

        setError(error)
      })
      .finally(() => {
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [punchoutId, punchoutSessionId])

  const checkoutPunchoutBasket = useCallback(
    async (basketId: string, options?: CheckoutBasketOptions) => {
      if (!punchoutId || !punchoutSessionId) {
        throw new Error(
          'Cannot perform a punchout checkout outside a punchout session.'
        )
      }

      if (loading) {
        throw new Error('The basket checkout is already in progress...')
      }

      try {
        setLoading(true)
        await checkoutBasket(punchoutId, punchoutSessionId, basketId, options)
      } finally {
        setLoading(false)
      }
    },
    [punchoutId, punchoutSessionId, loading]
  )

  return useMemo(
    () => ({
      loading,
      error,
      punchoutId,
      punchoutSessionId,
      ...punchoutUserData,
      checkoutPunchoutBasket,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, error, punchoutUserData, checkoutPunchoutBasket]
  )
}
