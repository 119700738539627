import { ErrorCodes, FieldNames } from '@abcam-web/address-validator'

type Label = {
  key: FieldNames
  hint?: string
  placeholder?: string
  size?: 'narrow' | 'wide'
  hintId?: string
  hintFootnoteId?: string
  labelHint?: boolean
}
type ErrorMessages = Partial<Record<ErrorCodes, string>>

type FieldConfigProps = {
  labels: Label[]
  errorMessages: ErrorMessages
}

const fieldConfig: FieldConfigProps = {
  // the order of the fields in the form
  labels: [
    {
      key: 'name',
    },
    {
      key: 'organisationName',
    },
    {
      key: 'addressLine1',
    },
    {
      key: 'addressLine2',
    },
    {
      key: 'city',
    },
    {
      key: 'country',
    },
    {
      key: 'county',
    },
    {
      key: 'district',
    },
    {
      key: 'state',
    },
    {
      key: 'province',
    },
    {
      key: 'postcode',
      size: 'narrow',
      hintId: 'address.hint.label',
      hintFootnoteId: 'postcode.hint.footnote',
    },
  ],

  errorMessages: {
    'error-format-date': undefined,
    'error-required': undefined,
    'error-min-length': undefined,
    'error-max-length': undefined,
    'error-pattern': undefined,
    'error-max-value': undefined,
    'error-min-value': undefined,
    'error-one-of': undefined,
    'error-type': undefined,
  },
}
export { fieldConfig }

export type { ErrorMessages, FieldConfigProps,Label }
