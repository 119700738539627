import { CategoryType } from '../product.enum'

export enum CaseType {
  SentenceCase = 'sentenceCase',
  TitleCase = 'titleCase',
}

export const getCategoryKey = (caseType: CaseType, categoryType: string) => {
  const categoryIndex = Object.values(CategoryType).indexOf(
    categoryType as CategoryType
  )
  const categoryKey = Object.keys(CategoryType)[categoryIndex]

  return categoryKey === undefined
    ? categoryType
    : `common.category.${caseType}.${categoryKey}`
}
