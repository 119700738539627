import type { FacetInput } from '@abcam-web/shared/data-access/search-schema'
import type { Sorting } from './search-types'

export type QueryStringParams = {
  facets: FacetInput[]
  keywords: string
  productSorting?: Sorting
  productSortOrder?: string
  resourceSorting?: Sorting
  resourceSortOrder?: string
  tab?: string
  contentType?: string
  productPage?: number
  resourcePage?: number
}

export const getQueryString = ({
  facets,
  keywords,
  productSorting,
  productSortOrder,
  resourceSorting,
  resourceSortOrder,
  tab,
  contentType,
  productPage,
  resourcePage,
}: QueryStringParams) => {
  const groupedfacets = facets.reduce<Record<string, string[]>>(
    (groups, item) => {
      const { label, type } = item
      if (!groups[type]) {
        groups[type] = [label]
      } else {
        groups[type] = [...groups[type], label]
      }

      return groups
    },
    {}
  )

  const parameters = {
    productSorting,
    resourceSorting,
    ...(keywords && { keywords }),
    ...(productPage && { productPage }),
    ...(resourcePage && { productPage }),
  }

  const filters = (
    Object.keys(groupedfacets) as (keyof typeof groupedfacets)[]
  ).map(
    (key) => `facets.${key}=${encodeURIComponent(groupedfacets[key].join('|'))}`
  )

  const otherParams = (
    Object.keys(parameters) as (keyof typeof parameters)[]
  ).map((key) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return `${key}=${encodeURIComponent(parameters[key]!)}`
  })

  if (contentType) {
    otherParams.push(`&contentType=${contentType}`)
  }
  if (productSortOrder) {
    otherParams.push(`productSortOrder=${productSortOrder}`)
  }
  if (resourceSortOrder) {
    otherParams.push(`resourceSortOrder=${resourceSortOrder}`)
  }
  if (tab) {
    otherParams.push(`tab=${tab}`)
  }

  return [...filters, ...otherParams].join('&')
}
