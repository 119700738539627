import { useEffect } from 'react'
import {
  enableBodyScroll,
  disableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'
import type { RefObject } from 'react'

export const useScrollTrap = (
  isActive: boolean,
  ref: RefObject<HTMLElement>
) => {
  useEffect(() => {
    if (!ref.current) {
      return
    }

    if (isActive) {
      disableBodyScroll(ref.current, {
        allowTouchMove: (el: HTMLElement | Element | null) => {
          while (el && el !== document.body) {
            if (el.getAttribute('body-scroll-lock-ignore') !== null) {
              return true
            }
            el = el.parentElement
          }
          return false
        },
      })
    } else {
      enableBodyScroll(ref.current)
    }
    return () => {
      clearAllBodyScrollLocks()
    }
  }, [isActive, ref])
}
