import cn from 'classnames'
import { NotificationProps } from './notification.type'
import { NOTIFICATION_SIZE } from './notification.type'
import { Cross } from '@lego/ui/icons'
import { Button, BUTTON_SIZE, BUTTON_VARIANT } from '@lego/ui/button'
import type { PropsWithChildren } from 'react'

import styles from './notification.module.css'

const defaultProps = {
  size: NOTIFICATION_SIZE.medium,
  rounded: true,
}

export const Notification = (props: PropsWithChildren<NotificationProps>) => {
  const {
    variant,
    size,
    children,
    onClose,
    action,
    icon,
    className,
    highContrast,
    rounded,
    centered,
    multiline,
    dataCy,
  } = {
    ...defaultProps,
    ...props,
  }

  return (
    <div
      role="alert"
      className={cn(
        'flex',
        multiline ? 'items-start' : 'items-center',
        centered ? 'justify-center' : 'justify-between',
        rounded && 'rounded-8px',
        className,
        styles.notification,
        highContrast && styles.highContrast,
        styles[variant],
        styles[size]
      )}
      data-cy={dataCy}
      data-testid={dataCy}
    >
      {icon && multiline ? <div className="mr-2 mt-0.5">{icon}</div> : icon}
      <div className={cn(!centered && 'flex-grow', action && 'mr-2')}>
        {children}
      </div>
      {action && (
        <Button
          bodyClassName={cn('underline')}
          variant={BUTTON_VARIANT.textOnly}
          size={BUTTON_SIZE.small}
          onClick={action.onAction}
          alignCenter={!multiline}
          className={cn(
            !!onClose && '-mt-3 transform translate-x-3 translate-y-1.5'
          )} // push to compensate button size classes
        >
          {action.text}
        </Button>
      )}
      {!!onClose && (
        <Button
          variant={BUTTON_VARIANT.textOnly}
          size={BUTTON_SIZE.small}
          iconButton={true}
          onClick={onClose}
          alignCenter={!multiline}
          className="-mt-3 transform translate-x-3 translate-y-1.5" // push to compensate button size classes
        >
          <Cross />
        </Button>
      )}
    </div>
  )
}
