import { IntlShape } from 'react-intl'
import {
  Translations,
  LangCode,
  CountryOverrides,
  COUNTRY_CODES,
} from '../types'
import { getLanguageFromLocale } from '../getLanguageFromLocale'
import { defaultLocale } from '@abcam-web/shared/config'

export const mappedTranslations = (
  translations: Translations[]
): Translations =>
  translations.reduce((mergedTranslations, translation) => {
    for (const langCode in translation) {
      if (langCode in mergedTranslations) {
        mergedTranslations[langCode as LangCode] = {
          ...mergedTranslations[langCode as LangCode],
          ...translation[langCode as LangCode],
        }
      } else {
        mergedTranslations[langCode as LangCode] =
          translation[langCode as LangCode]
      }
    }
    return mergedTranslations
  }, {} as Translations)

export const mergeCountryOverrides = (
  overrides: CountryOverrides[]
): CountryOverrides =>
  overrides.reduce((mergedOverrides, override) => {
    for (const langCode in override) {
      if (langCode in mergedOverrides) {
        for (const countryCode in override[langCode as LangCode]) {
          if (countryCode in mergedOverrides[langCode as LangCode]) {
            mergedOverrides[langCode as LangCode][
              countryCode as COUNTRY_CODES
            ] = {
              ...mergedOverrides[langCode as LangCode][
                countryCode as COUNTRY_CODES
              ],
              ...override[langCode as LangCode][countryCode as COUNTRY_CODES],
            }
          } else {
            mergedOverrides[langCode as LangCode][
              countryCode as COUNTRY_CODES
            ] = override[langCode as LangCode][countryCode as COUNTRY_CODES]
          }
        }
      } else {
        mergedOverrides[langCode as LangCode] = override[langCode as LangCode]
      }
    }
    return mergedOverrides
  }, {} as CountryOverrides)

export const getCountryOverrides = (
  localisationOverride: CountryOverrides | undefined,
  langCode: LangCode,
  countryCode: COUNTRY_CODES
): Record<string, string> => {
  const countryOverrides = localisationOverride
    ? localisationOverride[langCode]?.[countryCode]
    : undefined

  if (!countryOverrides) {
    return {}
  }

  return Object.fromEntries(
    Object.entries(countryOverrides).filter(
      ([, value]) => value !== undefined
    ) as [string, string][]
  )
}

export type GetMessagesWithCountryOverridesArgs = {
  translations: Translations
  translationsOverrides?: CountryOverrides
  langCode: LangCode
  countryCode: COUNTRY_CODES
}

/**
 * FormatJS does not expose locale when an error is thrown
 * However it is returned as part of error message
 * This function extracts locale value from error message to report it correctly in the error handler
 * @param message value from FormatJS captured error (error.message)
 * @returns locale example 'zh-cn'
 */
export const extractLocaleFromFormatJSErrorMessage = (
  message: string
): string | null => {
  const regex = /for locale "([^"]+)"/
  const match = message.match(regex)
  return match ? match[1] : null
}

export const i18nErrorHandler = (
  formatJSError: Parameters<IntlShape['onError']>[number]
) => {
  const error: typeof formatJSError & { locale?: string } = formatJSError
  error.locale =
    extractLocaleFromFormatJSErrorMessage(formatJSError.message) ?? undefined
  const message =
    error.code === 'MISSING_TRANSLATION'
      ? `Missing message: "${error?.descriptor?.id}" for locale "${error.locale}", using id as fallback.`
      : formatJSError.message

  console.error(message)
}

export const getMessagesWithCountryOverrides = ({
  translations,
  translationsOverrides,
  langCode,
  countryCode,
}: GetMessagesWithCountryOverridesArgs): Translations[LangCode] => {
  const defaultLang = getLanguageFromLocale(defaultLocale) as LangCode
  const countryOverrides = getCountryOverrides(
    translationsOverrides,
    langCode,
    countryCode
  )

  const localisedTextWithCountryOverrides: Record<string, string> = {
    ...(translations[defaultLang] ?? {}),
    ...(translations[langCode] ?? {}),
    ...(countryOverrides ?? {}),
  }
  return localisedTextWithCountryOverrides
}
