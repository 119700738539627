/* eslint-disable no-restricted-globals */
import 'react-phone-input-2/lib/style.css'

import { FormContext } from '@lego/ui/form-elements'
import { isValidPhoneNumber } from 'libphonenumber-js'
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useIntl } from 'react-intl'
import PhoneInput, { CountryData } from 'react-phone-input-2'

import styles from './telephone-input.module.css'

export const triggerNativeEventFor = (
  element: HTMLInputElement | null,
  value: string
) => {
  const nativeInputValueSetter = Object?.getOwnPropertyDescriptor(
    window?.HTMLInputElement?.prototype,
    'value'
  )?.set
  nativeInputValueSetter?.call(element, value)
  const event = new Event('input', { bubbles: true })
  element?.dispatchEvent(event)
}

interface Props {
  id: string
  countryCodeDefaultValue?: string
  telephoneNumberDefaultValue?: string
  required?: boolean
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  errorMessage?: string
  defaultCountry?: string
  name?: string
}

const getDefaultPhoneNumber = (
  countryCode?: string,
  telephoneNumber?: string
) => (countryCode && telephoneNumber ? `${countryCode}${telephoneNumber}` : '')

const getDefaultCountry = (
  countryDefault?: string,
  countryCode?: string,
  telephoneNumber?: string
) =>
  countryCode && telephoneNumber
    ? undefined
    : countryDefault?.toLocaleLowerCase()

const TelephoneInput: FC<Props> = ({
  id,
  countryCodeDefaultValue,
  telephoneNumberDefaultValue,
  required,
  errorMessage,
  defaultCountry,
  name,
}) => {
  const { formatMessage } = useIntl()
  const { isSubmissionAttempted } = useContext(FormContext)

  const [value, setValue] = useState<string>(
    getDefaultPhoneNumber(countryCodeDefaultValue, telephoneNumberDefaultValue)
  )
  const [error, setError] = useState<string | undefined>()

  // if there's not a defaultNumber (countryCodeDefaultValue+telephoneNumberDefaultValue) we select a defaultCountry
  const [defaultCountryComputed, setDefaultCountry] = useState(
    getDefaultCountry(
      defaultCountry,
      countryCodeDefaultValue,
      telephoneNumberDefaultValue
    )
  )

  useEffect(() => {
    if (value === '') {
      setDefaultCountry(
        getDefaultCountry(
          defaultCountry,
          countryCodeDefaultValue,
          telephoneNumberDefaultValue
        )
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCountry])

  const inputRef = useRef<HTMLInputElement>(null)

  const isValidInput = useCallback(
    (newValue: string, country: CountryData): boolean => {
      const isOnlyCountryCode = newValue === country?.countryCode
      const newValueComputed = newValue.includes('+')
        ? newValue
        : `+${newValue}`

      let isValid = true
      let customValidity = ''
      let error

      if (newValue !== '' && !isOnlyCountryCode) {
        const isValidTelephone = isValidPhoneNumber(newValueComputed)
        error = isValidTelephone
          ? undefined
          : errorMessage ||
            formatMessage({
              id: 'telephoneInput.error.phoneNumberInvalid',
            })

        customValidity = isValidTelephone ? '' : ' '
        isValid = isSubmissionAttempted ? isValidTelephone : true
      } else {
        customValidity = required ? ' ' : ''
        isValid = isSubmissionAttempted ? !required : true
        error = required
          ? formatMessage({
              id: 'telephoneInput.error.phoneNumberRequired',
            })
          : undefined
      }

      if (inputRef.current) {
        const target = inputRef.current
        target.setCustomValidity(customValidity)
      }

      setError(error)
      return isValid
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [required, errorMessage, isSubmissionAttempted]
  )

  return (
    <div className={styles.telephoneInput}>
      <PhoneInput
        country={defaultCountryComputed}
        placeholder={formatMessage({
          id: 'telephoneInput.placeholder',
        })}
        searchPlaceholder={formatMessage({
          id: 'telephoneInput.searchPlaceholder',
        })}
        isValid={(value, country: any) => {
          const output = isValidInput(value, country)
          return output
        }}
        containerClass={styles.container}
        searchClass={styles.searchClass}
        buttonClass={styles.button}
        inputClass={styles.input}
        enableSearch
        value={value}
        inputProps={{
          required,
          ref: inputRef,
          name,
          id: `telephone-input-${id}`,
        }}
        onChange={(newValue, _countryData, e) => {
          setValue(newValue)
          // on country change triggers on click event, we need to dispatch onChange in order to get data from the form
          if (e.type === 'click') {
            triggerNativeEventFor(inputRef.current, newValue)
          }
        }}
      />
      {error && isSubmissionAttempted && (
        <div className="block mt-1 text-negative text-body-small">{error}</div>
      )}
    </div>
  )
}

export { TelephoneInput }
