import type { FeatureToggle } from '@abcam-web/shared/data-access/feature-toggle-schema'
import type { FeatureToggleName } from '@feature-toggles/feature-toggle-name.enum'

export const buildToggle = (
  featureToggleName: FeatureToggleName | string,
  enabled: boolean
): FeatureToggle => {
  return {
    name: featureToggleName,
    enabled,
  }
}
