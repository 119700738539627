import US from './us'
import CN from './cn'
import IN from './in'
import AU from './au'
import MY from './my'
import MX from './mx'
import BR from './br'
import JP from './jp'
import CA from './ca'
import KR from './kr'
import TH from './th'

import { BaseAddressSchema } from './base'
import { Schema } from '../types/schema'
import { COUNTRY_CODES, POSTCODE_REGEXES } from '../data'
import OptionalPostcode from './optional-postcode'

const COUNTRY_SCHEMAS = {
  US,
  CN,
  IN,
  AU,
  MY,
  MX,
  BR,
  JP,
  CA,
  KR,
  TH,
  IE: OptionalPostcode,
  AE: OptionalPostcode,
  BW: OptionalPostcode,
  CI: OptionalPostcode,
  CM: OptionalPostcode,
  GA: OptionalPostcode,
  GD: OptionalPostcode,
  GM: OptionalPostcode,
  GY: OptionalPostcode,
  HK: OptionalPostcode,
  QA: OptionalPostcode,
  TT: OptionalPostcode,
  UG: OptionalPostcode,
  ZM: OptionalPostcode,
  AG: OptionalPostcode,
  AW: OptionalPostcode,
  BS: OptionalPostcode,
  BZ: OptionalPostcode,
  BJ: OptionalPostcode,
  BO: OptionalPostcode,
  KY: OptionalPostcode,
  DM: OptionalPostcode,
  GH: OptionalPostcode,
  JM: OptionalPostcode,
  MO: OptionalPostcode,
  MW: OptionalPostcode,
  AN: OptionalPostcode,
  KN: OptionalPostcode,
  TZ: OptionalPostcode,
  TG: OptionalPostcode,
} as Partial<Record<COUNTRY_CODES, Schema>>

const getCountrySchema = (
  countryCode: COUNTRY_CODES | undefined = 'GB'
): Schema | undefined => {
  // If the country code is not in the list of supported countries, return undefined
  if (!POSTCODE_REGEXES[countryCode]) {
    return undefined
  }

  return (
    COUNTRY_SCHEMAS[countryCode] || {
      ...BaseAddressSchema,
      postcode: {
        type: 'string',
        required: true,
        // combining all regexes in logical OR
        pattern: new RegExp(
          POSTCODE_REGEXES[countryCode].map((exp) => `^${exp}$`).join('|'),
          'i'
        ),
      },
    }
  )
}

export { getCountrySchema, COUNTRY_SCHEMAS }
