import { addressFields, Schema } from '@abcam-web/address-validator'
import {
  EcomOrganisationAddress,
  OrderAddressModel,
} from '@abcam-web/shared/data-access/ecommerce-schema'
import { ClickId, PageSubtype } from '@abcam-web/shared/data-access/tracking'
import { useCheckoutTracking } from '@abcam-web/shared/ecommerce/utilities'
import { useCountry } from '@abcam-web/shared/utilities/country'
import { Button } from '@lego/ui/button'
import { SimpleTextInput } from '@lego/ui/form-elements'
import {
  CircleArrows,
  Radio,
  RadioChecked,
  SpinnerOffset,
  WarningIcon,
} from '@lego/ui/icons'
import { Notification } from '@lego/ui/notification'
import cx from 'classnames'
import { isNil, omitBy } from 'lodash'
import {
  FC,
  FormEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Collapse } from 'react-collapse'
import { useIntl } from 'react-intl'

import { AddressFieldset } from '../address-fieldset'
import { DefaultValues } from '../address-fieldset/utils'
import { SearchSelect } from '../search-select'
import styles from './address-info.module.css'

interface Option {
  value: string
  address: Partial<EcomOrganisationAddress> | undefined
}

interface AdressInfoProps {
  title: string
  defaultAddress?: OrderAddressModel
  forAttentionOf?: string
  description: string
  disabled?: boolean
  sameAs?: {
    text: string
    address: OrderAddressModel | undefined | null
  }
  onAddressSelect: (
    address: Partial<EcomOrganisationAddress | OrderAddressModel> | undefined,
    isHistoricalAddress: boolean
  ) => void
  loading?: boolean
  addresses: (EcomOrganisationAddress | null)[]
  formOpen?: boolean
  onRefresh?: () => void
  showRefreshMessage?: boolean
  verificationMessage?: string | ReactNode
  pageSubType: PageSubtype
  dataTestIdSuffix: string
}

const VerificationNotification = ({
  className,
  message,
  dataTestId,
}: {
  className?: string
  message: string | ReactNode
  dataTestId?: string
}) => (
  <Notification
    className={cx('p-2 font-normal', {
      [`${className}`]: !!className,
    })}
    variant="cautionary"
  >
    <div className="flex justify-center mx-3 gap-3" data-testid={dataTestId}>
      <WarningIcon className="mt-1 mr-2" />
      <p>{message}</p>
    </div>
  </Notification>
)

export const formatAddress = (
  address: Partial<EcomOrganisationAddress> | null
) => {
  const {
    organisationName,
    addressLine1,
    addressLine2,
    city,
    postcode,
    country,
  } = address || {}

  const addressFields: EcomOrganisationAddress[keyof EcomOrganisationAddress][] =
    [organisationName, addressLine1, addressLine2, city, postcode, country]

  return addressFields.filter((field) => !!field).join(', ')
}

export const getFormattedHistoricalAddress = ({
  itemAddress,
}: {
  itemAddress: Partial<EcomOrganisationAddress>
}) => {
  const formatedAddress = formatAddress(itemAddress)

  return (
    <span data-cy="address-details" className="text-body-small">
      {formatedAddress}
    </span>
  )
}

export const matchAddress = (
  addressOne: Partial<EcomOrganisationAddress | OrderAddressModel> | undefined,
  addressTwo: Partial<EcomOrganisationAddress | OrderAddressModel> | undefined
) => {
  const historicalAddressFieldsToCompare = [
    'addressFusionId',
    'organisationRegistryId',
    'organisationName',
  ]
  if (addressOne && addressTwo) {
    const addressOneWithoutNil = omitBy(addressOne, isNil)
    const addressTwoWithoutNil = omitBy(addressTwo, isNil)

    const hasMismatch = [
      ...addressFields,
      ...historicalAddressFieldsToCompare,
    ].some((fieldName) => {
      /**
       * When user modifies historical address, then both `addressFusionId` and `organisationRegistryId` are set to `undefined`
       * if user reverted those changes we want to mark the address as match and pass addressFusionId and organisationRegistryId
       */
      if (
        fieldName === 'addressFusionId' ||
        fieldName === 'organisationRegistryId'
      ) {
        if (
          addressOneWithoutNil[fieldName] &&
          addressTwoWithoutNil[fieldName]
        ) {
          return (
            addressOneWithoutNil[fieldName] !== addressTwoWithoutNil[fieldName]
          )
        } else {
          return false
        }
      } else {
        return (
          addressOneWithoutNil[fieldName] !== addressTwoWithoutNil[fieldName]
        )
      }
    })
    return !hasMismatch
  }
  return false
}
/**
 * WARNING!!
 * AddressInfo should be a controlled component
 * as parent component need to be aware of address state
 * we should not keep address updates in local state
 * using uncontrolled input components will not help achieve that
 * as Address info state should be in sync with UI
 */
const AddressInfo: FC<AdressInfoProps> = ({
  title,
  description,
  sameAs = undefined,
  disabled = false,
  addresses = [],
  forAttentionOf = undefined,
  onRefresh = () => null,
  loading = false,
  defaultAddress,
  formOpen = false,
  verificationMessage = '',
  showRefreshMessage = false,
  onAddressSelect = () => null,
  pageSubType,
  dataTestIdSuffix = '',
}) => {
  const { country } = useCountry()
  const [isSameAddress, setIsSameAddress] = useState(false)
  const [addressEnabled, setAddressEnabled] = useState(formOpen)
  const [isDirty, setIsDirty] = useState(false)
  const [placeholderValue, setPlaceholderValue] = useState('')
  const isSelectDisabledRef = useRef(true)
  const fallBackAddress = useMemo(() => ({ country }), [country])
  const [formKey, setFormKey] = useState(0)
  const { trackEngagement } = useCheckoutTracking({ pageSubType })
  const { formatMessage } = useIntl()

  const { section, infoGroup, fieldsGroup, subTitle, subTitleBody } = styles

  const mappedOptions: Option[] = useMemo(
    () =>
      addresses.map((address) => ({
        value: formatAddress(address),
        address: address as EcomOrganisationAddress,
      })),
    [addresses]
  )

  const options: Option[] = useMemo(
    () => [
      ...mappedOptions,
      {
        value: formatMessage({ id: 'addressInfo.newAddress' }),
        address: { country },
      },
    ],
    [country, formatMessage, mappedOptions]
  )

  useEffect(() => {
    const shouldDisplayOptions = Boolean(mappedOptions.length)
    isSelectDisabledRef.current = disabled || loading || !shouldDisplayOptions

    if (isDirty) {
      setPlaceholderValue(formatMessage({ id: 'addressInfo.addressAdded' }))
      return
    }

    if (loading) {
      setPlaceholderValue(formatMessage({ id: 'addressInfo.loading' }))
      return
    }

    if (!shouldDisplayOptions) {
      setPlaceholderValue(formatMessage({ id: 'addressInfo.noOption' }))
      return
    }

    setPlaceholderValue(formatMessage({ id: 'addressInfo.multipleOption' }))
  }, [disabled, formatMessage, isDirty, loading, mappedOptions.length])

  const selectedOption = options.find((item) =>
    matchAddress(item.address, defaultAddress)
  )

  const isCurrentAddressFromHistoricalData = Boolean(selectedOption)

  const isNewAddress = !loading && !isCurrentAddressFromHistoricalData

  const showAddress = addressEnabled || defaultAddress

  const addressFieldSetChangeHandler = useCallback(
    (e: FormEvent, selectedSchema?: Schema) => {
      let schemaMatchingAddress =
        // we cast type as any as we use Object.keys which has loose typing key is of type string which mismatch with type fieldNames
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        defaultAddress || (fallBackAddress as any)
      if (selectedSchema && defaultAddress) {
        //When schema changes we want to drop fields from previous schema that does not matches new schema
        schemaMatchingAddress = {} as OrderAddressModel
        Object.keys(selectedSchema).forEach((key) => {
          if (defaultAddress[key as keyof OrderAddressModel]) {
            schemaMatchingAddress[key] =
              defaultAddress[key as keyof OrderAddressModel]
          }
        })
      }
      const fieldName = (e.target as HTMLInputElement).name
      const fieldNewValue = (e.target as HTMLInputElement).value
      const defaultFieldValue =
        defaultAddress?.[fieldName as keyof typeof defaultAddress]

      if (fieldNewValue !== defaultFieldValue) {
        const matchingHistoricalAddress = addresses.find((item) =>
          matchAddress(
            {
              ...schemaMatchingAddress,
              [fieldName]: fieldNewValue,
            },
            item as EcomOrganisationAddress
          )
        )

        const newAddress = {
          ...schemaMatchingAddress,
          [fieldName]: fieldNewValue,
          addressFusionId: undefined,
          organisationRegistryId: undefined,
        }

        const isHistoricalAddress = Boolean(matchingHistoricalAddress)

        if (!isHistoricalAddress) {
          setIsDirty(true)
        }

        const updatedAddress = matchingHistoricalAddress || newAddress

        onAddressSelect(updatedAddress, isHistoricalAddress)
      }
    },
    [addresses, defaultAddress, fallBackAddress, onAddressSelect]
  )

  const formatOptionLabelCallback = useCallback(
    (data: Option) => {
      const { value, address: itemAddress } = data

      if (
        itemAddress?.addressLine1 &&
        itemAddress?.city &&
        itemAddress?.postcode &&
        itemAddress?.country
      ) {
        return getFormattedHistoricalAddress({ itemAddress })
      }
      return (
        <span data-cy="address-details" className="text-body-small">
          {value === formatMessage({ id: 'addressInfo.newAddress' }) ? (
            <strong className="text-blue-60">{value}</strong>
          ) : (
            value
          )}
        </span>
      )
    },
    [formatMessage]
  )

  const onMenuFocusCallback = useCallback(() => {
    setPlaceholderValue(formatMessage({ id: 'addressInfo.search' }))
  }, [formatMessage])

  const onMenuCloseCallback = useCallback(() => {
    const placeholder = isDirty
      ? formatMessage({ id: 'addressInfo.addressAdded' })
      : formatMessage({ id: 'addressInfo.multipleOption' })

    setPlaceholderValue(placeholder)
  }, [formatMessage, isDirty])

  useEffect(() => {
    const lastOption = options[options.length - 1]

    if (selectedOption && selectedOption.value !== lastOption.value) {
      setFormKey((prev) => prev + 1)
    }
  }, [options, selectedOption])

  return (
    <div
      className={cx({ 'opacity-50 cursor-not-allowed': disabled })}
      data-testid={`address-info-${dataTestIdSuffix}`}
    >
      <div className={section}>
        <div className={infoGroup}>
          {title && <span className={subTitle}>{title}</span>}
          {description && <div className={subTitleBody}>{description}</div>}
        </div>
        <div className={fieldsGroup}>
          {verificationMessage && isNewAddress && (
            <Collapse isOpened={addressEnabled}>
              <VerificationNotification
                message={verificationMessage}
                className="mb-5"
                dataTestId={`notification-new-address-top-${dataTestIdSuffix}`}
              />
            </Collapse>
          )}
          {/**
           * we probably will be removing this logic we need to conform from UX as this is a legacy that won't ever get used
           */}
          {showRefreshMessage && (
            <Collapse isOpened={isNewAddress}>
              <div className="flex flex-col">
                <Notification
                  dataCy="notification-sales-cloud"
                  className="mt-5 font-normal"
                  variant="negative"
                >
                  {`Please save new ${title.toLocaleLowerCase()} addresses in Sales Cloud and refresh address list to use with this order.`}
                </Notification>
                <Button
                  className="my-3 ml-auto"
                  iconLeft={
                    !loading ? (
                      <CircleArrows />
                    ) : (
                      <SpinnerOffset className="animate-spin" />
                    )
                  }
                  variant="tertiaryOutline"
                  size="small"
                  onClick={onRefresh}
                >
                  Refresh address list
                </Button>
              </div>
            </Collapse>
          )}
          {showAddress && !disabled && (
            <>
              <SimpleTextInput
                labelClassName="text-body-small"
                inputClassName="text-body-small"
                wrapperClassName="my-4"
                defaultValue={forAttentionOf || ''}
                name="forAttentionOf"
                label={formatMessage({ id: 'addressInfo.forAttentionOf' })}
                onBlur={(e) =>
                  trackEngagement({
                    click_id: ClickId.UPDATE_FAO,
                    item_cta: e.target.value,
                  })
                }
              />
              <SearchSelect<Option>
                styles={{
                  placeholder: (baseStyles, state) => ({
                    ...baseStyles,
                    color:
                      placeholderValue ===
                      formatMessage({ id: 'addressInfo.multipleOption' })
                        ? 'inherit'
                        : baseStyles.color,
                  }),
                  option: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: state.isSelected
                      ? '#EDF6F7'
                      : baseStyles.backgroundColor,
                    color: 'black',
                    '&:last-child': {
                      backgroundColor: state.isSelected
                        ? 'transparent'
                        : baseStyles.backgroundColor,
                    },
                  }),
                }}
                onFocus={onMenuFocusCallback}
                onMenuOpen={onMenuFocusCallback}
                onMenuClose={onMenuCloseCallback}
                key={`${selectedOption?.value}`}
                instanceId={title}
                value={selectedOption}
                label={formatMessage({ id: 'addressInfo.select' })}
                isLoading={loading}
                isDisabled={isSelectDisabledRef.current}
                formatOptionLabel={formatOptionLabelCallback}
                onChange={(data) => {
                  const { value: dataValue, address } = data as Option
                  const addressWithoutNill = omitBy(address, isNil)

                  const isHistoricalAddress = options.some(
                    ({ value }) => value === dataValue
                  )

                  onAddressSelect(addressWithoutNill, isHistoricalAddress)
                  trackEngagement({
                    click_id: ClickId.SELECT_ADDRESS,
                    item_cta: addressWithoutNill?.addressLine1
                      ? 'existing'
                      : 'new',
                  })
                }}
                placeholder={placeholderValue}
                options={options}
              />
              {/**
               * we probably will be removing this logic we need to conform from UX as this is a legacy that won't ever get used
               */}
              {!showAddress && !disabled && !formOpen && (
                <span className="float-right font-semibold text-body-small">
                  or{' '}
                  <span
                    onClick={() => {
                      setAddressEnabled(true)
                    }}
                    className="cursor-pointer hover:underline text-blue-default"
                  >
                    enter a new one
                  </span>
                </span>
              )}
              {sameAs && (
                <div className="mt-5">
                  <label
                    style={{ marginLeft: '17px' }}
                    className={cx(
                      'flex mb-3 ml-4 text-sm text-body-small cursor-pointer font-semibold'
                    )}
                    onClick={() => {
                      if (!isSameAddress) {
                        setIsSameAddress(true)
                      } else {
                        setIsSameAddress(false)
                      }
                    }}
                  >
                    {isSameAddress ? (
                      <RadioChecked
                        height="24px"
                        width="24px"
                        className="mr-3"
                      />
                    ) : (
                      <Radio className="mr-3" />
                    )}
                    <span className="my-auto">{sameAs.text}</span>
                  </label>
                </div>
              )}
              <AddressFieldset
                defaultValues={
                  (defaultAddress || fallBackAddress) as DefaultValues
                }
                onChange={addressFieldSetChangeHandler}
                key={`address-${title}-${formKey}`}
                className="mt-4 gap-5"
                labelClassName="text-body-small"
                inputClassName="text-body-small"
                menuClassName="!max-h-[300px]"
                pageSubType={pageSubType}
              />
            </>
          )}
          {verificationMessage && isNewAddress && (
            <Collapse isOpened={addressEnabled}>
              <VerificationNotification
                message={verificationMessage}
                className="mt-5"
                dataTestId={`notification-new-address-bottom-${dataTestIdSuffix}`}
              />
            </Collapse>
          )}
        </div>
      </div>
    </div>
  )
}

export default AddressInfo
