import Select, { Props } from 'react-select'

import styles from './search-select.module.css'

interface SearchSelectProps<Option> extends Props<Option> { label?: string }

const SearchSelect = <Option,>(props: SearchSelectProps<Option>) => {
  const { label = undefined, instanceId = '' } = props
  return (
    <>
      {label && (
        <div className="pb-2 font-semibold text-black-0 text-body-small">
          <label htmlFor={instanceId.toString()}>{label}</label>
        </div>
      )}
      <Select
        inputId={instanceId.toString()}
        className={styles.searchSelect}
        {...props}
      />
    </>
  )
}

export { SearchSelect }
