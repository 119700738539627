import { getProxyClient } from '@abcam-web/shared/data-access/apollo'
import {
  GetProductDocument,
  GetSdsDocument,
  GetProductProtocolsDocument,
  GetProductConcentrationsDocument,
  GetEntityDocument,
  GetRecentlyViewedDocument,
  GetProductCrossSellDocument,
} from '@abcam-web/shared/data-access/product-schema'

import type {
  DocumentsFilter,
  GetProductQuery,
  GetProductQueryVariables,
  GetSdsQuery,
  GetSdsQueryVariables,
  GetProductProtocolsQuery,
  GetProductProtocolsQueryVariables,
  GetProductConcentrationsQuery,
  GetProductConcentrationsQueryVariables,
  GetEntityQuery,
  GetEntityQueryVariables,
  GetRecentlyViewedQuery,
  GetRecentlyViewedQueryVariables,
  DmdDomain,
  GetProductCrossSellQueryVariables,
  GetProductCrossSellQuery,
} from '@abcam-web/shared/data-access/product-schema'
import type { Client } from '@abcam-web/shared/data-access/apollo'

class ProductService {
  private client: Client
  constructor() {
    this.client = getProxyClient()
  }

  public async getPDP(productCode: string, locale: string, domain: DmdDomain) {
    return await this.client.query<GetProductQuery, GetProductQueryVariables>({
      query: GetProductDocument,
      variables: { abId: productCode, domain, locale },
    })
  }

  public async getCrossSell(productCode: string) {
    return await this.client.query<
      GetProductCrossSellQuery,
      GetProductCrossSellQueryVariables
    >({
      query: GetProductCrossSellDocument,
      variables: { productCode },
    })
  }

  public async getSDS(payload: DocumentsFilter) {
    return await this.client.query<GetSdsQuery, GetSdsQueryVariables>({
      query: GetSdsDocument,
      variables: { query: payload },
      errorPolicy: 'all',
    })
  }

  public async getProtocols(payload: DocumentsFilter) {
    return await this.client.query<
      GetProductProtocolsQuery,
      GetProductProtocolsQueryVariables
    >({
      query: GetProductProtocolsDocument,
      variables: { query: { ...payload } },
      errorPolicy: 'all',
    })
  }

  public async getConcentrations(
    payload: GetProductConcentrationsQueryVariables
  ) {
    return await this.client.query<
      GetProductConcentrationsQuery,
      GetProductConcentrationsQueryVariables
    >({
      query: GetProductConcentrationsDocument,
      variables: { ...payload },
      errorPolicy: 'all',
    })
  }

  public async getEntity(variables: GetEntityQueryVariables) {
    return this.client.query<GetEntityQuery, GetEntityQueryVariables>({
      query: GetEntityDocument,
      variables,
    })
  }

  public async getRecentlyViewed(productCodes: string[]) {
    return this.client.query<
      GetRecentlyViewedQuery,
      GetRecentlyViewedQueryVariables
    >({
      query: GetRecentlyViewedDocument,
      variables: {
        filter: {
          productCodes,
        },
      },
    })
  }
}

type ProductEntity = {
  getEntityBySlug: GetEntityQuery
}

export type { ProductEntity }
export { ProductService }
