import { MerchantModel } from '@abcam-web/payments/data-access/api-hooks'
import { OrderResponseModel } from '@abcam-web/shared/data-access/ecommerce-schema'
import {
  CancelledPaymentModalParameters,
  getCancelledPaymentModalCancelClickedParameters,
  getCancelledPaymentModalReviewClickedParameters,
  getShowCancelledPaymentModalParameters,
  trackEvents,
} from '@abcam-web/shared/data-access/tracking'

const getTrackingParameters = (
  merchantData: MerchantModel | undefined,
  countryName: string,
  orderData: OrderResponseModel | undefined,
  basketId: string
) => ({
  merchant: merchantData?.merchantCode ?? '',
  countryName,
  billToCountry: orderData?.addresses?.billing?.country ?? '',
  shipToCountry: orderData?.addresses?.shipping?.country ?? '',
  basketId,
})

export const trackShowModal = (
  merchantData: MerchantModel | undefined,
  countryName: string,
  orderData: OrderResponseModel,
  basketId: string
) => {
  const showCancelledPaymentModalParameters =
    getShowCancelledPaymentModalParameters(
      getTrackingParameters(merchantData, countryName, orderData, basketId)
    )

  trackEvents<CancelledPaymentModalParameters>(
    showCancelledPaymentModalParameters
  )
}

export const trackReviewClicked = (
  merchantData: MerchantModel | undefined,
  countryName: string,
  orderData: OrderResponseModel | undefined,
  basketId: string
) => {
  const cancelledPaymentModalReviewClickedParameters =
    getCancelledPaymentModalReviewClickedParameters(
      getTrackingParameters(merchantData, countryName, orderData, basketId)
    )

  trackEvents<CancelledPaymentModalParameters>(
    cancelledPaymentModalReviewClickedParameters
  )
}

export const trackCancelClicked = (
  merchantData: MerchantModel | undefined,
  countryName: string,
  orderData: OrderResponseModel | undefined,
  basketId: string
) => {
  const cancelledPaymentModalCancelClickedParameters =
    getCancelledPaymentModalCancelClickedParameters(
      getTrackingParameters(merchantData, countryName, orderData, basketId)
    )

  trackEvents<CancelledPaymentModalParameters>(
    cancelledPaymentModalCancelClickedParameters
  )
}
