import { FC } from 'react'
import { useIntl } from 'react-intl'

import { EditLinePropertyInputModal } from '../edit-line-property-input-modal/edit-line-property-input-modal'

interface Props {
  loading?: boolean
  isOpen: boolean
  onSave: (customerLineNumber: string) => void
  onClose: () => void
  defaultValue?: string
}

const EditCustomerLineNumberModal: FC<Props> = ({
  loading,
  isOpen,
  onSave,
  onClose,
  defaultValue = '',
}) => {
  const { formatMessage } = useIntl()

  return (
    <EditLinePropertyInputModal
      testTagPrefix="customer-line-number"
      headerText={formatMessage({ id: 'editCustomerLineNumber.header' })}
      cancelText={formatMessage({ id: 'editCustomerLineNumber.cancel' })}
      saveText={formatMessage({ id: 'editCustomerLineNumber.save' })}
      isOpen={isOpen}
      key={`customer-line-number-${defaultValue}`}
      onClose={onClose}
      defaultValue={defaultValue}
      loading={loading}
      onSave={onSave}
    />
  )
}

export { EditCustomerLineNumberModal }
