import { useState, useEffect } from 'react'
import { theme } from '@tailwind-preset-theme'

/**
 * @param breakpoint - window.innerWidth px
 * @param comparisonType - '<' ('less than, i.e. max-width') | '>' ('greater than, i.e. min-width')
 * @returns boolean
 */
export const useBreakpointListener = (
  breakpoint: number,
  comparisonType: '<' | '>'
): boolean => {
  // default to mobile for SSR
  const tinyScreenBreakpoint = parseInt(
    theme.extend.screens.tyu,
    10
  )

  const [isMatching, setIsMatching] = useState(
    comparisonType === '<'
      ? tinyScreenBreakpoint < breakpoint - 1
      : tinyScreenBreakpoint > breakpoint
  )

  useEffect(() => {
    const mediaQuery =
      comparisonType === '<'
        ? `(max-width: ${breakpoint - 1}px)`
        : `(min-width: ${breakpoint}px)`

    setIsMatching(
      comparisonType === '<'
        ? window.innerWidth < breakpoint - 1
        : window.innerWidth > breakpoint
    )

    const mediaQueryList: MediaQueryList = window.matchMedia(mediaQuery)
    const handler = (event: MediaQueryListEvent) => {
      setIsMatching(event.matches)
    }

    // Older Webkit browsers don't support 'addEventListener'
    if (mediaQueryList.addEventListener) {
      mediaQueryList.addEventListener('change', handler)
    } else {
      mediaQueryList.addListener(handler)
    }
    return () => {
      if (mediaQueryList.removeEventListener) {
        mediaQueryList.removeEventListener('change', handler)
      } else {
        mediaQueryList.removeListener(handler)
      }
    }
  }, [breakpoint, comparisonType])

  return isMatching
}
