export const deepValueSearch = (
  searchString: string,
  obj?: Record<string, any>
) => {
  let result = ''

  if (!obj) {
    return result
  }

  function search(obj?: Record<string, any>) {
    for (const key in obj) {
      if (result) return

      if (key === searchString) {
        result = obj[key]
        return
      }

      if (typeof obj[key] === 'object' && obj[key] !== null) {
        search(obj[key])
      }
    }
  }

  search(obj)
  return result
}
