import { INPUT_ARIA_LABEL } from './constants'
import styles from './textInput.module.css'
import {
  TextInputProps,
  TextInputType,
  TextInputVariant,
} from './textInput.type'
import { InfoIcon } from '@lego/ui/icons'
import { Tooltip } from '@lego/ui/tooltip'
import cn from 'classnames'
import ConditionalWrap from 'conditional-wrap'
import { useCallback, useEffect, useRef, useState } from 'react'
import type { PropsWithChildren, LegacyRef } from 'react'

const errorTextTestAttributeValue = 'error-text'

const TextInput = (props: PropsWithChildren<TextInputProps>) => {
  const {
    label,
    labelHint,
    hint,
    placeholder,
    errorText,
    onChange = () => null,
    onBlur = () => null,
    onFocus = () => null,
    onKeyUp = () => null,
    onKeyDown = () => null,
    onClick = () => null,
    inputType = TextInputType.text,
    validationPattern,
    minLength,
    maxLength,
    inputSize,
    fullWidth = false,
    disabled = false,
    hidden = false,
    required = false,
    isFocused = false,
    isSelected = false,
    className,
    variant = TextInputVariant.light,
    ariaLabel = INPUT_ARIA_LABEL,
    inputClassName = undefined,
    value,
    resetValueTo,
    rows = 5,
    error = false,
    name = 'value',
    icon,
    autocomplete,
    dataCy,
    trackFormID,
    trackLabel,
    trackErrors,
    inputRef: inputRefProp,
    labelClassName,
  } = props

  const [isError, setIsError] = useState<boolean>(error)
  const [inputKey, setInputKey] = useState<string | undefined>()
  const _inputRef = useRef<HTMLInputElement>()
  const textAreaRef = useRef<HTMLTextAreaElement>()

  const inputRef = inputRefProp ?? _inputRef

  const classnames = cn(
    styles.textInput,
    {
      [styles.error]: isError || error,
      [styles.fullWidth]: fullWidth,
      [styles.flexible]: inputSize,
      [styles.disabled]: disabled,
      [styles.hide]: hidden,
    },
    styles[variant],
    className
  )

  const onFocusOutCallback = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (evt: any) => {
      const validity =
        inputRef?.current?.validity || textAreaRef?.current?.validity
      if (validity) {
        const { valid } = validity
        setIsError(!valid)
        onBlur({ value: evt.target.value, valid })
      }
    },
    [inputRef, onBlur]
  )

  const onFocusCallback = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (evt: any) => {
      const validity =
        inputRef?.current?.validity || textAreaRef?.current?.validity
      if (validity) {
        const { valid } = validity
        setIsError(!valid)
        onFocus({ value: evt.target.value, valid })
      }
    },
    [inputRef, onFocus]
  )

  const checkValidity = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (evt: any) => {
      const validity =
        inputRef?.current?.validity || textAreaRef?.current?.validity
      if (validity && isError && errorText) {
        const { valid, tooShort } = validity
        const isError = !valid && !tooShort
        setIsError(isError)
      }
      if (validity) {
        const { valid } = validity
        onChange({ value: evt.target.value, valid })
      }
    },
    [inputRef, isError, errorText, onChange]
  )

  const onKeyUpHandler = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (evt: any) => {
      if (!onKeyUp) {
        return
      }
      const validity =
        inputRef?.current?.validity || textAreaRef?.current?.validity
      if (validity && isError && errorText) {
        const { valid, tooShort } = validity
        const isError = !valid && !tooShort
        setIsError(isError)
      }
      if (validity) {
        const { valid } = validity
        onKeyUp({ value: evt.target.value, valid })
      }
    },
    [onKeyUp, inputRef, isError, errorText]
  )

  const validateInput = useCallback(() => {
    const validity =
      inputRef?.current?.validity || textAreaRef?.current?.validity
    if (validity) {
      const { valid, tooShort } = validity
      const isError = !valid && !tooShort
      setIsError(isError)
    }
  }, [inputRef])

  useEffect(() => {
    setIsError(error)
  }, [error])

  useEffect(() => {
    if (isFocused) {
      inputRef?.current?.focus()
    }
    if (isSelected) {
      inputRef?.current?.select()
    }
  }, [inputRef, isFocused, isSelected])

  useEffect(() => {
    const newKey =
      resetValueTo !== undefined ? new Date().getTime().toString() : undefined
    setInputKey(newKey)
  }, [resetValueTo])

  useEffect(() => {
    validateInput()
  }, [validateInput, validationPattern])

  return (
    <div className={classnames}>
      <div className={styles.topText}>
        {label && (
          <div className="inline-flex items-center pb-1">
            <label
              className={cn(styles.label, {
                [`${labelClassName}`]: !!labelClassName,
              })}
              htmlFor={name}
            >
              {label}
            </label>
            {labelHint && (
              <Tooltip text={[labelHint]}>
                <InfoIcon className={styles.infoIcon} />
              </Tooltip>
            )}
          </div>
        )}
        {hint && <p className={styles.hint}>{hint}</p>}
      </div>
      <div className={styles.inputContainer}>
        {inputType !== TextInputType.area ? (
          <input
            onClick={onClick}
            data-testid={props['data-testid'] ?? dataCy}
            className={cn({ [`${inputClassName}`]: !!inputClassName })}
            ref={inputRef as LegacyRef<HTMLInputElement>}
            type={inputType}
            pattern={validationPattern}
            name={name}
            id={name}
            aria-label={ariaLabel}
            placeholder={placeholder}
            onLoad={checkValidity}
            onBlur={onFocusOutCallback}
            onFocus={onFocusCallback}
            onKeyUp={onKeyUpHandler}
            onChange={checkValidity}
            onKeyDown={onKeyDown}
            minLength={minLength}
            maxLength={maxLength}
            disabled={disabled}
            required={required}
            value={value}
            key={inputKey}
            defaultValue={resetValueTo?.value}
            size={inputSize}
            autoComplete={autocomplete}
            data-cy={dataCy}
          />
        ) : (
          <textarea
            data-cy={dataCy}
            onClick={onClick}
            data-testid={props['data-testid'] ?? dataCy}
            className={cn({ [`${inputClassName}`]: !!inputClassName })}
            ref={textAreaRef as LegacyRef<HTMLTextAreaElement>}
            name={name}
            id={name}
            aria-label={ariaLabel}
            placeholder={placeholder}
            onBlur={onFocusOutCallback}
            onChange={checkValidity}
            minLength={minLength}
            maxLength={maxLength}
            disabled={disabled}
            required={required}
            value={value}
            key={inputKey}
            defaultValue={resetValueTo?.value}
            rows={rows}
            autoComplete={autocomplete}
          />
        )}
        {icon && (
          <ConditionalWrap
            condition={!!onClick}
            wrap={(children) => (
              <div
                tabIndex={0}
                role="button"
                onClick={onClick}
                onKeyDown={onClick}
              >
                {children}
              </div>
            )}
          >
            {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
            <>{icon}</>
          </ConditionalWrap>
        )}
      </div>
      {(isError || error) && errorText && (
        <div className={styles.errorMessage}>
          <p
            data-testid={errorTextTestAttributeValue}
            data-cy={errorTextTestAttributeValue}
            data-track={trackErrors ? 'input-error' : null}
            data-track-form-id={trackErrors ? trackFormID : null}
            data-track-label={
              trackErrors
                ? trackLabel
                  ? trackLabel
                  : typeof label === 'string'
                  ? label
                  : ariaLabel
                : null
            }
          >
            {errorText}
          </p>
        </div>
      )}
    </div>
  )
}

export { TextInput }
