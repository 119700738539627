import { Region } from './regionSelectorDropdown'

export const COUNTRY_SELECTOR_FILTER_KEY = 'search'
export const COUNTRY_SELECTOR_PERSIST_KEY = 'COUNTRY_PERSIST'
export const JAPAN_PREFECTURES: Region[] = [
  { name: 'Aichi', alternative: '愛知県', countryCode: 'JP' },
  { name: 'Akita', alternative: '秋田県', countryCode: 'JP' },
  { name: 'Aomori', alternative: '青森県', countryCode: 'JP' },
  { name: 'Chiba', alternative: '千葉県', countryCode: 'JP' },
  { name: 'Ehime', alternative: '愛媛県', countryCode: 'JP' },
  { name: 'Fukui', alternative: '福井県', countryCode: 'JP' },
  { name: 'Fukuoka', alternative: '福岡県', countryCode: 'JP' },
  { name: 'Fukushima', alternative: '福島県', countryCode: 'JP' },
  { name: 'Gifu', alternative: '岐阜県', countryCode: 'JP' },
  { name: 'Gunma', alternative: '群馬県', countryCode: 'JP' },
  { name: 'Hiroshima', alternative: '広島県', countryCode: 'JP' },
  { name: 'Hokkaido', alternative: '北海道', countryCode: 'JP' },
  { name: 'Hyogo', alternative: '兵庫県', countryCode: 'JP' },
  { name: 'Ibaraki', alternative: '茨城県', countryCode: 'JP' },
  { name: 'Ishikawa', alternative: '石川県', countryCode: 'JP' },
  { name: 'Iwate', alternative: '岩手県', countryCode: 'JP' },
  { name: 'Kagawa', alternative: '香川県', countryCode: 'JP' },
  { name: 'Kagoshima', alternative: '鹿児島県', countryCode: 'JP' },
  { name: 'Kanagawa', alternative: '神奈川県', countryCode: 'JP' },
  { name: 'Kochi', alternative: '高知県', countryCode: 'JP' },
  { name: 'Kumamoto', alternative: '熊本県', countryCode: 'JP' },
  { name: 'Kyoto', alternative: '京都府', countryCode: 'JP' },
  { name: 'Mie', alternative: '三重県', countryCode: 'JP' },
  { name: 'Miyagi', alternative: '宮城県', countryCode: 'JP' },
  { name: 'Miyazaki', alternative: '宮崎県', countryCode: 'JP' },
  { name: 'Nagano', alternative: '長野県', countryCode: 'JP' },
  { name: 'Nagasaki', alternative: '長崎県', countryCode: 'JP' },
  { name: 'Nara', alternative: '奈良県', countryCode: 'JP' },
  { name: 'Niigata', alternative: '新潟県', countryCode: 'JP' },
  { name: 'Oita', alternative: '大分県', countryCode: 'JP' },
  { name: 'Okayama', alternative: '岡山県', countryCode: 'JP' },
  { name: 'Okinawa', alternative: '沖縄県', countryCode: 'JP' },
  { name: 'Osaka', alternative: '大阪府', countryCode: 'JP' },
  { name: 'Saga', alternative: '佐賀県', countryCode: 'JP' },
  { name: 'Saitama', alternative: '埼玉県', countryCode: 'JP' },
  { name: 'Shiga', alternative: '滋賀県', countryCode: 'JP' },
  { name: 'Shimane', alternative: '島根県', countryCode: 'JP' },
  { name: 'Shizuoka', alternative: '静岡県', countryCode: 'JP' },
  { name: 'Tochigi', alternative: '栃木県', countryCode: 'JP' },
  { name: 'Tokushima', alternative: '徳島県', countryCode: 'JP' },
  { name: 'Tokyo', alternative: '東京都', countryCode: 'JP' },
  { name: 'Tottori', alternative: '鳥取県', countryCode: 'JP' },
  { name: 'Toyama', alternative: '富山県', countryCode: 'JP' },
  { name: 'Wakayama', alternative: '和歌山県', countryCode: 'JP' },
  { name: 'Yamagata', alternative: '山形県', countryCode: 'JP' },
  { name: 'Yamaguchi', alternative: '山口県', countryCode: 'JP' },
  { name: 'Yamanashi', alternative: '山梨県', countryCode: 'JP' },
]

export const CHINA_PROVINCES: Region[] = [
  { name: 'Anhui', alternative: '安徽', countryCode: 'CN' },
  { name: 'Beijing', alternative: '北京', countryCode: 'CN' },
  { name: 'Chongqing', alternative: '重庆', countryCode: 'CN' },
  { name: 'Fujian', alternative: '福建', countryCode: 'CN' },
  { name: 'Gansu', alternative: '甘肃', countryCode: 'CN' },
  { name: 'Guangdong', alternative: '广东', countryCode: 'CN' },
  { name: 'Guangxi', alternative: '广西', countryCode: 'CN' },
  { name: 'Guizhou', alternative: '贵州', countryCode: 'CN' },
  { name: 'Hebei', alternative: '河北', countryCode: 'CN' },
  { name: 'Heilongjiang', alternative: '黑龙江', countryCode: 'CN' },
  { name: 'Henan', alternative: '河南', countryCode: 'CN' },
  { name: 'Hubei', alternative: '河北', countryCode: 'CN' },
  { name: 'Hunan', alternative: '湖南', countryCode: 'CN' },
  { name: 'Jiangsu', alternative: '江苏', countryCode: 'CN' },
  { name: 'Jiangxi', alternative: '江西', countryCode: 'CN' },
  { name: 'Jilin', alternative: '吉林', countryCode: 'CN' },
  { name: 'Ningxia', alternative: '宁夏', countryCode: 'CN' },
  { name: 'Shaanxi', alternative: '陕西', countryCode: 'CN' },
  { name: 'Shanxi', alternative: '山西', countryCode: 'CN' },
  { name: 'Shanghai', alternative: '上海', countryCode: 'CN' },
  { name: 'Sichuan', alternative: '四川', countryCode: 'CN' },
  { name: 'Tianjin', alternative: '天津', countryCode: 'CN' },
  { name: 'Xinjiang', alternative: '新疆', countryCode: 'CN' },
  { name: 'Yunnan', alternative: '云南', countryCode: 'CN' },
  { name: 'Zhejiang', alternative: '浙江', countryCode: 'CN' },
]

export const regions = {
  cn: CHINA_PROVINCES,
  jp: JAPAN_PREFECTURES,
}

export type RegionalCountry = keyof typeof regions
