import {
  LotSpecificOrder,
  PriceAdjustmentInput,
} from '@abcam-web/shared/data-access/ecommerce-schema'

enum updateType {
  SIZE = 'size',
  QUANTITY = 'quantity',
  PRICE_ADJUSTMENT = 'price_adjustment',
  CUSTOMER_PO_NUMBER = 'customer_po_number',
  LOT_REQUEST = 'lot_request',
  CUSTOMER_LINE_NUMBER = 'customer_line_number',
}

type onAssetUpdate = {
  lineNumber: number
  quantity: number
  publicAssetCode: string
  assetNumber: string
  updateType: updateType
  priceAdjustment?: PriceAdjustmentInput | null
  customerPurchaseOrderNumber?: string | null
  customerLineNumber?: string | null
  lotSpecificOrder?: LotSpecificOrder | null
}

export type { onAssetUpdate }
export { updateType }
