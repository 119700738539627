import { twMerge } from 'tailwind-merge'
import type { PropsWithChildren } from 'react'

const Card = ({
  children,
  className,
  dataCy,
}: PropsWithChildren<{ className?: string; dataCy?: string }>) => {
  const cn = twMerge(
    `flex flex-col gap-6 justify-between bg-white inset-2 xs:max-w-sm xs:h-auto xs:mx-auto p-6 shadow-elevation-5 rounded xs:relative absolute`,
    className
  )
  return (
    <section className={cn} data-cy={dataCy}>
      {children}
    </section>
  )
}

export { Card }
