import { BaseSwitch } from '../_base_switch/base_switch'
import { BaseSwitchProps } from '../_base_switch/base_switch.type'
import styles from './radioInput.module.css'

type Props = Omit<BaseSwitchProps, 'styles' | 'SwitchUi'>

const SwitchComponent = () => (
  <div
    className={`border-2 border-white rounded-[100%] bg-blue-40 ${styles.radioCheck}`}
  />
)

const RadioInput = ({ size = 'small', ...props }: Props) => {
  return (
    <BaseSwitch
      styles={styles}
      size={size}
      SwitchUi={<SwitchComponent />}
      type="radio"
      {...props}
    />
  )
}

export { RadioInput }
