import { useHistory } from '@abcam-web/shared/ecommerce/utilities'
import { createContext, ReactNode, useContext } from 'react'

type State = { history: string[] }

const historyInitialState: State = {
  history: [],
}

const HistoryContext = createContext(historyInitialState)
HistoryContext.displayName = 'HistoryContext'

const useHistoryContext = () => useContext(HistoryContext)

const HistoryProvider = ({
  children,
}: {
  children: ReactNode[] | ReactNode
}) => {
  const { history } = useHistory()
  const value = {
    history,
  }

  return (
    <HistoryContext.Provider value={value}>{children}</HistoryContext.Provider>
  )
}

export { HistoryContext, HistoryProvider, useHistoryContext }
