export const orderLog = {
  table: {
    container: 'modal-content-order-log-table',
    heading: {
      type: 'modal-content-order-log-table-heading-type',
      createdAt: 'modal-content-order-log-table-heading-createdAt',
      description: 'modal-content-order-log-table-heading-description',
      status: 'modal-content-order-log-table-heading-status',
      user: 'modal-content-order-log-table-heading-user',
      actions: 'modal-content-order-log-table-heading-actions',
    },
    row: {
      container: 'model-content-order-log-table-row-container',
      columns: {
        type: 'modal-content-order-log-table-row-column-type',
        createdAt: 'modal-content-order-log-table-row-column-createdAt',
        description: 'modal-content-order-log-table-row-column-description',
        status: 'modal-content-order-log-table-row-column-status',
        user: 'modal-content-order-log-table-row-column-user',
        actions: {
          container: 'modal-content-order-log-table-row-column-actions',
          viewOrderSnapshot:
            'modal-content-order-log-table-row-column-actions-view-order-snapshot',
          compareOrderSnapshot: {
            container: 'modal-content-order-log-table-row-column-actions-compare-order-snapshot',
            checkbox: 'modal-content-order-log-table-row-column-compare-order-snapshot-checkbox'
          }
        },
      },
    },
  },
  footer: {
    container: 'modal-footer',
    closeButton: 'modal-footer-close-button',
    compareButton: 'modal-footer-compare-button',
  },
}
