export const abReviews = {
  imageOverlay: {
    panel: 'review-image-overlay',
    closeButton: 'review-image-overlay-close-button',
    title: 'review-image-overlay-review-title',
    image: 'review-image-overlay-image',
  },
  reviews: {
    submitAReviewButton: 'submit-a-review-btn',
    starRatings: {
      value: 'star-ratings-value',
      viewRatingsButton: 'star-ratings-view-ratings-btn',
    },
    starRating: 'star-rating',
    starIcons: {
      filled: 'star-filled',
      semiFilled: 'star-semi-filled',
      unfilled: 'star-unfilled',
    },
    drawer: {
      starRatingsReadOnly: {
        value: 'star-ratings-read-only-value',
        numberOfReviews: 'star-ratings-read-only-number-of-reviews',
        writeAReviewButton: 'write-a-review-btn',
      },
      ratingBreakdown: {
        panel: 'ratings-breakdown',
        entry: 'ratings-breakdown-entry',
        title: 'ratings-breakdown-title',
        number: 'ratingNumber',
        progress: 'linearProgress',
        numberOfReviews: 'numberOfReviews',
        checkbox: 'icon-checked',
        inconclusive: `inconclusive`,
      },
      actions: {
        filters: {
          application: {
            open: 'open-application-filter-dropdown',
            close: 'close-application-filter-dropdown',
            button: 'reviews-application-filter-btn',
            selectedCount: 'reviews-application-selected-filters-count',
            options: '[data-optionsgroup="application"]'
          },
          species: {
            open: 'open-species-filter-dropdown',
            close: 'close-species-filter-dropdown',
            button: 'reviews-species-filter-btn',
            selectedCount: 'reviews-species-selected-filters-count',
            options: '[data-optionsgroup="species"]'
          },
        },
        sortBy: {
          panel: 'sort',
          button: 'selected-value-container',
          itemsContainer: 'select_content',
          oldest: 'select-list-item-oldest'
        },
        pagination: {
          page2TestId: 'page 2'
        },
        dropdown: {
          panel: 'reviews-sort_dropdownContent',
          filterItems: 'dropdown-select-item',
          filterItem: {
            checkboxIcon: 'icon-checked',
          },
          sortItems: 'select-list-item',
          actions: {
            panel: 'reviews-sort_buttonContainer',
            clear: 'clear-btn',
            apply: 'apply-btn'
          },
        },
      },
      review: {
        fullProductInfoTest: 'Full product info',
        readFullReviewText: 'Read full review',
        readFullReview: 'review-link',
        reviewSectionTestId: 'review-section',
        common: {
          date: 'review-date',
          image: 'review-image',
          starRatingsValue: 'star-rating',
          starRatingsLabel: 'star-ratings-variant-label',
          title: 'review-title',
          application: 'review-section-application-title',
          applicationValue: 'review-section-application-value',
          description: 'review-section',
        },
        primaries: {
          loadingAmount: 'review-section-loadingAmount-title',
          loadingAmountValue: 'review-section-loadingAmount-value',
          sampleType: 'review-section-sample-title',
          sampleTypeValue: 'review-section-sample-value',
        },
        others: {
          easeOfUse: 'ease-of-use',
          easeOfUseValue: 'ease-of-use-label',
        },
      },
      fullReview: {
        back: 'content-back-button',
        contentAuthor: 'content-author',
        common: {
          date: 'content-date',
          image: 'content-image',
          starRatingsValue: 'star-rating',
          starRatingsLabel: 'star-ratings-variant-label',
          title: 'content-title',
          application: 'content-application-title',
          applicationValue: 'content-application-value',
          description: 'content-description',
        },
        primaries: {
          sample: 'content-sample-title',
          sampleValue: 'content-sample-value',
          loadingAmount: 'content-loadingAmount-title',
          loadingAmountValue: 'content-loadingAmount-value',
          specification: 'content-specification-title',
          specificationValue: 'content-specification-value',
          detectionMethod: 'content-detectionMethod-title',
          detectionMethodValue: 'content-detectionMethod-value',
          concentration: 'content-concentration-title',
          concentrationValue: 'content-concentration-value',
          incubationTime: 'content-incubationTime-title',
          incubationTimeValue: 'content-incubationTime-value',
          additionalData: 'content-section-additionalData-title',
          additionalNotes: 'content-additionalNotes-title',
          additionalNotesValue: 'content-additionalNotes-value',
          secondaryAntibody: 'content-section-secondaryAntibody-title',
        },
        others: {
          easeOfUse: 'ease-of-use',
          easeOfUseValue: 'ease-of-use-label',
        },
      },
    },
    legalText: 'legal-text',
  },
}
