import type { FacetInput } from '@abcam-web/shared/data-access/search-schema'

type SearchInput = {
  facets: FacetInput[]
  keywords: string
}

export const PREVIOUS_SEARCHES_STORAGE_KEY = 'previousSearches'

export const getPreviousSearches = () => {
  return (
    (typeof window !== 'undefined' &&
      JSON.parse(
        localStorage.getItem(PREVIOUS_SEARCHES_STORAGE_KEY) ?? '[]'
      )) ||
    []
  )
}

export const storePreviousSearch = (q: SearchInput) => {
  const previousSearches = getPreviousSearches()
  if (searchAlreadyExists(q, previousSearches)) return
  localStorage.setItem(
    PREVIOUS_SEARCHES_STORAGE_KEY,
    JSON.stringify([q, ...previousSearches].slice(0, 6))
  )
}

export const searchAlreadyExists = (
  search: SearchInput,
  previousSearches: SearchInput[]
): boolean =>
  previousSearches.some((ps) => {
    const keyWordsMatch = ps.keywords === search.keywords
    const equalLengthFacets = ps.facets.length === search.facets.length
    const allLabelsMatch = search.facets.every((facet) => {
      return ps.facets.map(({ label }) => label).includes(facet.label)
    })
    return keyWordsMatch && equalLengthFacets && allLabelsMatch
  })
