import { SignInController } from '@abcam-web/ecommerce/components'
import type { AssetModel } from '@abcam-web/shared/data-access/ecommerce-schema'
import {
  StockNote,
  StockNoteVariant,
  useStockNotes,
} from '@abcam-web/shared/ecommerce/components'
import {
  getDeliveryMessage,
  getQuantityMaxOptions,
  getRestrictedStatus,
  getSelectNumberOptions,
  getStockDetails,
  isDiscontinued as isAssetDiscontinued,
  useInStockMessage,
} from '@abcam-web/shared/ecommerce/utilities'
import { useCountry } from '@abcam-web/shared/utilities/country'
import {
  getCountryNameByCountryCode,
  getLanguageFromLocale,
} from '@abcam-web/shared/utilities/localisation'
import { Button } from '@lego/ui/button'
import { ContentLoader } from '@lego/ui/content-loader'
import { InfoIcon, SpinnerOffset } from '@lego/ui/icons'
import { Select, SelectOption } from '@lego/ui/select'
import { Tooltip } from '@lego/ui/tooltip'
import cx from 'classnames'
import { useRouter } from 'next/router'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'

import { SizesBox } from '../sizes-box/sizes-box'
import styles from './asset-info.module.css'
import { AssetPrice } from './asset-price'

export interface AssetInfoProps {
  quantity: number
  assets?: AssetModel[]
  onQuantityChange: (quantity: number) => void
  onInputChange?: (value: number) => void
  onInputTypeChange?: (value: string) => void
  onSizeChange: (asset: AssetModel) => void
  onAddToBasket: () => void
  onNavigateToTechSupport: () => void
  loadingAsset?: boolean
  loadingButton?: boolean
  checkoutLink?: string
  basketLink?: string
  country: string
  distributorFlow?: boolean
  selectedAsset?: AssetModel
}

const AssetInfo: FC<AssetInfoProps> = ({
  assets,
  quantity,
  onAddToBasket,
  onNavigateToTechSupport,
  onQuantityChange,
  onInputChange,
  onInputTypeChange,
  onSizeChange,
  country,
  distributorFlow,
  loadingAsset = false,
  loadingButton = false,
  selectedAsset,
}) => {
  const countryName = getCountryNameByCountryCode(country)
  const [showBuyProductModal, setBuyProductModal] = useState<boolean>(false)
  const stockNotes = selectedAsset?.carrierDetails?.stockNotes
  const language = getLanguageFromLocale(useRouter().locale)

  const { country: countryCode } = useCountry()

  const { formatMessage } = useIntl()
  const {
    shouldShowStockNote,
    shouldReplaceGOPMessage,
    isMadeToOrder,
    toolTipMessage,
    toolTipTitle,
  } = useStockNotes(stockNotes)

  const isDiscontinued = useMemo(
    () => isAssetDiscontinued(selectedAsset?.status),
    [selectedAsset?.status]
  )
  const options = useMemo(
    () =>
      getSelectNumberOptions(quantity, getQuantityMaxOptions(selectedAsset)),
    [quantity, selectedAsset]
  )

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 800) setBuyProductModal(false)
    }
    window.addEventListener('resize', handleResize)
    return function cleanupListener() {
      window.removeEventListener('resize', handleResize)
    }
  })

  const selectQuantityCallback = useCallback(
    (selected: any) => {
      const newQuantity = selected.key > 0 ? selected.key : 1
      onQuantityChange(newQuantity)
    },
    [onQuantityChange]
  )

  const onInputChangeCallback = useCallback(
    (selected: SelectOption) => {
      if (onInputChange) {
        const key = selected?.key
        const isKeyValid = typeof key === 'number' && key > 0
        const newQuantity = isKeyValid ? key : 1

        onInputChange(newQuantity)
      }
    },
    [onInputChange]
  )

  const onSelectTypeChangeCallback = useCallback(
    (value: boolean) => {
      const type = value ? 'TextField' : 'Select'
      onInputTypeChange?.(type)
    },
    [onInputTypeChange]
  )

  const stockDetails = getStockDetails(
    selectedAsset?.availableQuantity || 0,
    quantity,
    formatMessage,
    selectedAsset?.status
  )

  const { inStockMessage, isStockMessageEnabled } = useInStockMessage(country)
  const deliveryMessage = selectedAsset?.dates?.scheduledArrivalDate
    ? getDeliveryMessage(
        new Date(selectedAsset.dates.scheduledArrivalDate),
        selectedAsset?.dates && selectedAsset.dates.cutoffTimeForOrdering
          ? new Date(selectedAsset.dates.cutoffTimeForOrdering)
          : undefined,
        `${language}-${countryCode}`,
        formatMessage
      )
    : formatMessage({ id: 'assetInfo.deliveryMessage.notAvailable' })

  // restricted asset message is not clear from BE we need to understand how to localise it.
  const restrictedStatus = getRestrictedStatus(selectedAsset?.status)

  const shouldButtonBeDisabled =
    loadingButton ||
    Boolean(restrictedStatus) ||
    Boolean(
      selectedAsset && selectedAsset.availableQuantity === 0 && isDiscontinued
    )

  return (
    <div
      className={cx({
        [styles.buyProductContainer]: true,
        [styles.modal]: showBuyProductModal,
      })}
    >
      <div className="size_box">
        <ContentLoader
          isLoading={loadingAsset && !assets}
          height="30"
          width="150"
          className="flex items-center justify-center mt-3"
          innerClassName="rounded-lg"
          uniqueKey="sizes-box-loader"
        >
          {assets && (
            <SizesBox
              selectedAsset={selectedAsset}
              assets={assets}
              onChange={onSizeChange}
            />
          )}
        </ContentLoader>
        {!distributorFlow && (
          <AssetPrice
            isLoading={loadingAsset}
            quantity={quantity}
            prices={selectedAsset?.prices}
            country={country}
          />
        )}

        {!distributorFlow && (
          <ContentLoader
            isLoading={loadingAsset && !assets}
            height="30"
            width="150"
            className="flex items-center justify-center mt-3"
            innerClassName="rounded-lg"
            uniqueKey="sign-in-loader"
          >
            <SignInController
              classNames="my-3 !text-body-medium"
              linkTextOverrideId="assetInfo.signIn.override"
              fullTextLink
            />
          </ContentLoader>
        )}

        {!restrictedStatus && (
          <div className={styles.quantityContainer} data-testid="asset-quantity">
            <ContentLoader
              isLoading={loadingAsset}
              height="30"
              width="150"
              className="flex items-center justify-center mt-3"
              innerClassName="rounded-lg"
              uniqueKey="stock-loader2"
            >
              <div>{formatMessage({ id: 'assetInfo.quantity' })}</div>
              <div className="quantity">
                <Select
                  options={options}
                  onChange={selectQuantityCallback}
                  onInputChange={onInputChangeCallback}
                  onNumberInputToggle={onSelectTypeChangeCallback}
                />
              </div>
            </ContentLoader>
            <ContentLoader
              isLoading={loadingAsset}
              height="30"
              width="150"
              className="flex items-center justify-center mt-3"
              innerClassName="rounded-lg"
              uniqueKey="stock-loader"
            >
              {distributorFlow && isStockMessageEnabled && (
                <div
                  data-testid="stock-details"
                  className="pb-5 mt-3 font-normal whitespace-pre-line"
                >
                  {inStockMessage}
                </div>
              )}
              {!distributorFlow && !isMadeToOrder && (
                <div
                  data-testid="stock-details"
                  className="pb-5 mt-3 font-normal whitespace-pre-line"
                >
                  {isDiscontinued && (
                    <div className="flex justify-center">
                      {formatMessage({ id: 'assetInfo.discontinued.message' })}
                      <Tooltip
                        text={formatMessage({
                          id: 'assetInfo.discontinued.toolTip',
                        })}
                      >
                        <InfoIcon
                          height={16}
                          width={16}
                          className="my-auto ml-1 fill-current cursor-help text-green"
                        />
                      </Tooltip>
                    </div>
                  )}
                  <span className={cx(stockDetails.color)}>
                    {stockDetails.text}
                  </span>
                </div>
              )}
            </ContentLoader>
            <ContentLoader
              isLoading={loadingAsset}
              height="30"
              width="250"
              className="flex items-center justify-center mt-10"
              innerClassName="rounded-lg"
              uniqueKey="delivery-loader"
            >
              {!distributorFlow && (
                <StockNote
                  variant={StockNoteVariant.asset}
                  shouldShowStockNote={shouldShowStockNote}
                  shouldReplaceGOPMessage={shouldReplaceGOPMessage}
                  toolTipTitle={toolTipTitle}
                  toolTipMessage={toolTipMessage}
                  isMadeToOrder={isMadeToOrder}
                  deliveryMessage={
                    <div
                      data-testid="estimated-delivery-message"
                      data-cy="estimated-delivery-message"
                      className="whitespace-pre-line"
                    >
                      {deliveryMessage}
                    </div>
                  }
                />
              )}
            </ContentLoader>
          </div>
        )}
        {restrictedStatus && (
          <>
            <div
              data-cy="unavailable-in-region"
              className="mt-5 text-heading-x-small text-negative"
            >
              {formatMessage({ id: 'assetInfo.restricted.message.header' })}
            </div>
            <div className="my-8 text-body-medium text-black-0">
              {formatMessage({ id: 'assetInfo.restricted.message.body' })}{' '}
              {restrictedStatus.message}
            </div>
          </>
        )}
        <ContentLoader
          isLoading={loadingAsset && !assets}
          height="30"
          width="150"
          className="flex items-center justify-center mt-3"
          innerClassName="rounded-lg"
          uniqueKey="add-to-basket-loader"
        >
          <Button
            bodyClassName={styles.addButtonMessage}
            fullWidth
            disabled={shouldButtonBeDisabled}
            onClick={onAddToBasket}
            variant="primary"
            dataCy="add-to-basket"
            data-testid="add-to-basket"
            size="medium"
          >
            <div className="flex">
              {distributorFlow
                ? formatMessage({ id: 'assetInfo.addToBasket.distributor' })
                : formatMessage({ id: 'assetInfo.addToBasket.nonDistributor' })}
              {loadingButton && <SpinnerOffset className="ml-3 animate-spin" />}
            </div>
          </Button>
        </ContentLoader>
      </div>
      <div
        onClick={onNavigateToTechSupport}
        className="mt-5 font-normal underline cursor-pointer text-link-small text-blue-default"
      >
        {formatMessage({ id: 'assetInfo.technicalSupport' })}
      </div>

      <ContentLoader
        isLoading={loadingAsset && !assets}
        height="30"
        width="150"
        className="flex items-center justify-center mt-3"
        innerClassName="rounded-lg"
        uniqueKey="shipping-country-loader"
      >
        {!distributorFlow && (
          <div className={styles.shippingContainer}>
            <div className={styles.message}>
              {formatMessage({ id: 'assetInfo.shipping.message' })}
            </div>
            <div
              data-cy="shipping-country"
              data-testid="shipping-country"
              className={styles.country}
            >
              {countryName}
            </div>
          </div>
        )}
      </ContentLoader>
    </div>
  )
}

export { AssetInfo }
