import { CurrencyConfig } from './types/CurrencyFormat'
import { Currency } from './types/currencies'

export const currencyRegex = /^[A-Z]{3}$/

export const CURRENCY_CONFIG: Partial<Record<Currency, CurrencyConfig>> = {
  JPY: {
    intl: {
      getCurrencyDisplay: ({ language }) => (language === 'ja' ? 'name' : null),
    },
  },
  AUD: {
    symbol: {
      showVariant: () => true,
      variant: 'A$',
    },
  },
  CAD: {
    symbol: {
      showVariant: () => true,
      variant: 'CA$',
    },
  },
  HKD: {
    symbol: {
      showVariant: () => true,
      variant: 'HK$',
    },
  },
  NZD: {
    symbol: {
      showVariant: () => true,
      variant: 'NZ$',
    },
  },
  SGD: {
    symbol: {
      showVariant: () => true,
      variant: 'S$',
    },
  },
  USD: {
    symbol: {
      showVariant: () => true,
      variant: 'US$',
    },
  },
}
