import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import styles from './dropdown.module.css'
import classnames from 'classnames'
import { useResizeObserver } from '@lego/hooks/resize-observer'
import { isArrowDownEvent, isEnterEvent, isSpaceEvent } from '@lego/utilities'
import { DropdownPosition, DropdownProps } from './dropdown.type'
import { FocusBoundary } from '@lego/ui/focus-boundary'
import { Portal } from '@material-ui/core'
import debounce from 'lodash/debounce'
import type { PropsWithChildren } from 'react'

const useIsomorphicLayoutEffect =
  typeof document === 'undefined' ? useEffect : useLayoutEffect

const Dropdown = (props: PropsWithChildren<DropdownProps>) => {
  const triggerRef = useRef<HTMLDivElement | null>(null)
  const childContentRef = useRef<HTMLDivElement>(null)
  const [portalStyles, setPortalStyles] = useState<{
    top: number
    left: number
  }>({
    left: 0,
    top: 0,
  })

  const {
    parentElement,
    childElement,
    children,
    className = '',
    displayPosition = DropdownPosition.right,
    isOpen,
    onVisibilityChange,
    cy,
    parentElementClassNames,
    portal = false,
    displayAboveParent = false,
    disabled = false,
    id,
    dropdownContentClassName = '',
    ...otherProps
  } = props

  const updatePortalPosition = useCallback(() => {
    if (!triggerRef.current || !isOpen || !portal) return
    const rect = triggerRef.current.getBoundingClientRect()

    const top = (rect?.top ?? 0) + window.scrollY + (rect?.height ?? 0)
    const left = rect.left ?? 0
    setPortalStyles({
      top,
      left,
    })
  }, [triggerRef, isOpen, portal])

  useResizeObserver(
    triggerRef,
    debounce(updatePortalPosition, 500, {
      leading: true,
    }),
    {
      enabled: portal,
    }
  )

  const toggleIsVisible = useCallback(() => {
    onVisibilityChange(!isOpen, true)
  }, [isOpen, onVisibilityChange])

  const hideOnOutsideEvent = useCallback(
    (event: any) => {
      if (
        event.relatedTarget &&
        childContentRef.current?.contains(event.relatedTarget)
      )
        return

      if (isOpen) {
        onVisibilityChange(false, false)
      }
    },
    [isOpen, onVisibilityChange]
  )

  const classes = classnames(
    styles.dropdown,
    'relative',
    'inline-flex',
    'flex-col',
    {
      [styles.open]: isOpen,
      [className]: !!className,
    }
  )

  const dropdownContentClasses =
    displayPosition === 'left' ? 'right-0' : 'left-0'

  const listKeyUpHandler = useCallback(
    (evt: any) => {
      if (isEnterEvent(evt) || isSpaceEvent(evt) || isArrowDownEvent(evt)) {
        toggleIsVisible()
      }
    },
    [toggleIsVisible]
  )

  useIsomorphicLayoutEffect(() => {
    updatePortalPosition()
  }, [updatePortalPosition])

  const childContent = (
    <div
      className={classnames(
        'absolute top-full z-dropdown',
        styles.dropdownContent,
        dropdownContentClasses,
        { [`${dropdownContentClassName}`]: !!dropdownContentClassName }
      )}
      ref={childContentRef}
      style={
        portal
          ? {
              left: `${portalStyles.left}px`,
              top: `${portalStyles.top}px`,
            }
          : displayAboveParent
          ? {
              top: `-${
                (triggerRef.current?.getBoundingClientRect()?.height || 0) + 35
              }px`,
            }
          : {}
      }
    >
      {childElement || children}
    </div>
  )

  return (
    <FocusBoundary className={classes} onFocusOut={hideOnOutsideEvent}>
      <div
        data-cy={cy}
        data-testid={cy}
        tabIndex={disabled ? -1 : 0}
        className={classnames(
          styles.selectedValueContainer,
          parentElementClassNames,
          'focus-visible:outline-none focus-visible:shadow-interactiveElement'
        )}
        role="button"
        onClick={() => {
          toggleIsVisible()
        }}
        onKeyUp={listKeyUpHandler}
        ref={triggerRef}
        id={id}
        {...otherProps}
      >
        {parentElement}
      </div>
      {isOpen && !portal && childContent}
      {isOpen && portal && <Portal>{childContent}</Portal>}
    </FocusBoundary>
  )
}

export { Dropdown }
