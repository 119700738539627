import { createContext, useContext } from 'react'
import { COGNITO_ATTRIBUTES } from '../aws-cognito/util/cognitoAttributes'

type User = {
  username: string
  email: string
  email_verified: boolean
  avatarUrl?: string
  [COGNITO_ATTRIBUTES.MARKETING_OPT_IN]: boolean
  [COGNITO_ATTRIBUTES.GIVEN_NAME]: string
  [COGNITO_ATTRIBUTES.FAMILY_NAME]: string
  [COGNITO_ATTRIBUTES.IS_RED_LISTED]?: boolean
  [COGNITO_ATTRIBUTES.CONTACT_REGISTRY_ID]?: string
  [COGNITO_ATTRIBUTES.CONSUMER_SEGMENT]?: string
  [COGNITO_ATTRIBUTES.PRIMARY_ORGANISATION_REGISTRY_ID]?: string
  [COGNITO_ATTRIBUTES.GROUPS]?: string[]
  [COGNITO_ATTRIBUTES.PUNCHOUT_ID]?: string
  [COGNITO_ATTRIBUTES.PUNCHOUT_SESSION_ID]?: string
}

interface iUserContext {
  user: User | null | undefined
  isProcessedTokenFromUrl?: boolean
  setUser?: (user: User | null) => void
}

const UserContext = createContext<iUserContext>({
  user: undefined,
  isProcessedTokenFromUrl: false,
  setUser: () => {
    throw new Error('You cannot call useUser() outside of UserContext.')
  },
})

const useUser = () => {
  const { user } = useContext(UserContext)

  // Most consumers expects `User | undefined`, no `null` and we do not
  // want to add casts (or chnge the type) everywhere
  return user ?? undefined
}

export { UserContext, useUser }
export type { User }
