import {
  AssetModel,
  Price,
} from '@abcam-web/shared/data-access/ecommerce-schema'
import { useFormattedPrice } from '@abcam-web/shared/ecommerce/utilities'
import { FC } from 'react'
import { useIntl } from 'react-intl'

export enum DataTestIds {
  ASSET_MULTIPLE_PRICES = 'asset-multiple-prices',
  PRICE_WITHOUT_TAX = 'price-without-tax',
  BASE_PRICE = 'base-price',
  ADJUSTMENT_PRICE = 'adjustment-price',
  TAX_MESSAGE = 'tax-message',
  BASE_PRICE_WITH_TAX = 'base-price-with-tax',
  ADJUSTMENT_PRICE_WITH_TAX = 'adjustment-price-with-tax',
}

export interface AssetMultiplePricesProps {
  quantity: number
  prices?: AssetModel['prices']
}

export interface PriceWithDiscountProps {
  basePrice: string
  adjustmentPriceWithoutTax: string
  basePriceWithTax: string
  adjustmentPriceWithTax: string
}

export interface PriceWithoutDiscountProps {
  basePrice: string
  basePriceWithTax: string
}

const PriceWithDiscount: FC<PriceWithDiscountProps> = ({
  basePrice,
  adjustmentPriceWithoutTax,
  basePriceWithTax,
  adjustmentPriceWithTax,
}) => {
  const { formatMessage } = useIntl()
  const taxMessage = formatMessage({ id: 'asset.taxMessage.incl' })
  return (
    <div data-testid={DataTestIds.ASSET_MULTIPLE_PRICES} className="mt-4">
      <div
        data-testid={DataTestIds.PRICE_WITHOUT_TAX}
        className="mb-1 text-heading-medium"
      >
        <span
          data-testid={DataTestIds.BASE_PRICE}
          className="line-through text-grey-20"
        >
          {basePrice}
        </span>
        <span
          data-testid={DataTestIds.ADJUSTMENT_PRICE}
          className="ml-1 font-semibold"
        >
          {adjustmentPriceWithoutTax}
        </span>
      </div>
      <div data-testid={DataTestIds.TAX_MESSAGE} className="text-body-medium">
        {'('}
        <span
          data-testid={DataTestIds.BASE_PRICE_WITH_TAX}
          className="mr-1 line-through text-grey-20"
        >
          {basePriceWithTax}
        </span>
        <span
          data-testid={DataTestIds.ADJUSTMENT_PRICE_WITH_TAX}
          className="mr-1"
        >
          {adjustmentPriceWithTax}
        </span>
        {taxMessage}
        {')'}
      </div>
    </div>
  )
}

const PriceWithoutDiscount: FC<PriceWithoutDiscountProps> = ({
  basePrice,
  basePriceWithTax,
}) => {
  const { formatMessage } = useIntl()
  const taxMessage = formatMessage({ id: 'asset.taxMessage.incl' })
  return (
    <div data-testid={DataTestIds.ASSET_MULTIPLE_PRICES} className="mt-4">
      <div
        data-testid={DataTestIds.PRICE_WITHOUT_TAX}
        className="mb-1 font-semibold text-heading-medium"
      >
        {basePrice}
      </div>
      <div data-testid={DataTestIds.TAX_MESSAGE} className="text-body-medium">
        {'('}
        <span data-testid={DataTestIds.BASE_PRICE_WITH_TAX} className="mr-1">
          {basePriceWithTax}
        </span>
        {taxMessage}
        {')'}
      </div>
    </div>
  )
}

export const AssetMultiplePrices: FC<AssetMultiplePricesProps> = ({
  quantity,
  prices,
}) => {
  const currency = prices?.base?.currency

  const basePrice = useFormattedPrice({
    currency,
    value: (prices?.base?.value as number) * quantity,
  } as unknown as Price)

  const adjustmentPriceWithoutTax = useFormattedPrice({
    value:
      (prices?.unitPriceWithDiscountWithoutTax?.value as number) * quantity,
    currency,
  } as unknown as Price)

  const basePriceWithTax = useFormattedPrice({
    currency,
    value: (prices?.basePriceWithTax?.value as number) * quantity,
  } as unknown as Price)

  const adjustmentPriceWithTax = useFormattedPrice({
    value: (prices?.unitPriceWithDiscountAndTax?.value as number) * quantity,
    currency,
  } as unknown as Price)

  const hasAdjustmentPrice = basePrice !== adjustmentPriceWithoutTax
  return hasAdjustmentPrice ? (
    <PriceWithDiscount
      basePrice={basePrice}
      adjustmentPriceWithoutTax={adjustmentPriceWithoutTax}
      basePriceWithTax={basePriceWithTax}
      adjustmentPriceWithTax={adjustmentPriceWithTax}
    />
  ) : (
    <PriceWithoutDiscount
      basePrice={basePrice}
      basePriceWithTax={basePriceWithTax}
    />
  )
}
