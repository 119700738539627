import getConfig from 'next/config'

const graphQLPathname =
  getConfig().serverRuntimeConfig.AEM_GRAPHQL_PATHNAME ||
  '/content/graphql/global/endpoint.json'

const persistentQueryPathname =
  getConfig().serverRuntimeConfig.AEM_PERSISTENT_GRAPHQL_PATHNAME ||
  '/graphql/execute.json/global'

export const AEMFullEndpoint =
  getConfig().publicRuntimeConfig.AEM_URL + graphQLPathname

export const AEMPersistentQueryEndpoint: string =
  getConfig().publicRuntimeConfig.AEM_URL + persistentQueryPathname

export const AEMClientSideNextApiEndpoint = '/api/content'
