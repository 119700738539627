import { usePunchout } from '@abcam-web/auth-shared/aws-cognito/hooks/usePunchout'
import { usePunchoutUser } from '@abcam-web/auth-shared/aws-cognito/hooks/usePunchoutUser'
import { useUser } from '@abcam-web/auth-shared/contexts/user'
import {
  useApplyDiscountCodeToBasketMutation,
  useRemoveDiscountCodeFromBasketMutation,
} from '@abcam-web/shared/data-access/ecommerce-api-hooks'
import { useCountry } from '@abcam-web/shared/utilities/country'
import { FeatureToggleName } from '@feature-toggles/feature-toggle-name.enum'
import { useFeatureToggle } from '@feature-toggles/use-feature-toggle.hook'
import { useEffect, useState } from 'react'
import { IntlShape, useIntl } from 'react-intl'

import { useMarketInfo } from './use-market-info'

const TIMEOUT_FOR_ERROR_MESSAGE = 30_000
const TIMEOUT_FOR_SUCCESS_MESSAGE = 30_000

/**
 * helper to parse the string param coming from a feature toggle
 * @param type type of the flow we check
 * @param param string in the format "direct-[YES|NO] or "distributor-[YES|NO]"
 * @returns boolean
 */
const isDirectOrDistributor = (
  type: 'direct' | 'distributor',
  param?: string
): boolean => {
  // if the param is not defined in the feature toggle we show the widget
  if (!param) {
    return true
  }

  const [paramType, paramValue] = param.toLowerCase().split('-')

  // we only hide the widget if it's explicitly disabled for the country
  if (paramType === type && paramValue === 'no') {
    return false
  }

  return true
}

/**
 *  Hook to check if the widget should be shown.
 */
export function useShowWidget({ country }: { country?: string }) {
  const { active: isPunchoutSession } = usePunchout()
  const { data: marketInfo } = useMarketInfo()
  const user = useUser()
  const { arePromotionsEnabled } = usePunchoutUser(user)
  const isDistributor = marketInfo?.isDistributor

  const { attributesAsStringArray: featureFlagAttributes } = useFeatureToggle(
    FeatureToggleName.TempEnablePromotions
  )

  if (isPunchoutSession && arePromotionsEnabled) {
    return true
  }

  if (isPunchoutSession || !country) {
    return false
  }

  const listOfCountriesWithPromotions = (
    (featureFlagAttributes || []).find(
      (attribute) => attribute.name === 'enabledCountries'
    )?.value || []
  ).map((param) => {
    const [countryCode = '', directStr, distributorStr] = param.split(':')
    return {
      country: countryCode,
      direct: isDirectOrDistributor('direct', directStr),
      distributor: isDirectOrDistributor('distributor', distributorStr),
    }
  })

  const currentCountryIsInListOfCountriesWithPromotions = country
    ? listOfCountriesWithPromotions.find(
        (i) => i.country.toLowerCase() === country.toLowerCase()
      )
    : undefined

  if (isDistributor) {
    return !!currentCountryIsInListOfCountriesWithPromotions?.distributor
  } else {
    return !!currentCountryIsInListOfCountriesWithPromotions?.direct
  }
}

type UseDiscountProps = {
  basketId: string
  /**
   *  Callback function to be called when the promo code is successfully applied to the basket.
   *  We got confirmation from the API that the promo code is applied to the basket.
   */
  onSuccessPromoCodeApplication?: () => Promise<unknown>

  /**
   * Callback function to execute when failed to apply promo code to the basket.
   */
  onFailedPromoCodeApplication?: () => Promise<unknown>
}

/**
 * Hook to handle the discount code.
 * @param onSuccessPromoCodeApplication Callback function to be called when the promo code is successfully applied to the basket.
 * @param onFailedPromoCodeApplication Callback function to execute when failed to apply promo code to the basket.
 *
 * @returns isLoading - Flag to indicate if the API is loading.
 * @returns isReadyToSubmit - Flag to indicate if the promo code is ready to be submitted.
 * @returns promoCode - The promo code.
 * @returns onPromoCodeUpdate - Callback function to update the promo code (onChange handler).
 * @returns submitPromoCode - Callback function to submit the promo code (onClick handler).
 * @returns errorMessage - Error message. It will be reset after 30 seconds.
 * @returns successMessage - Success message. It will be reset after 30 seconds.
 *
 * ## DEPENDENCIES
 *
 * - `QueryClient` context.
 * - CountryProvider context. It uses `useCountry` hook to get the locale.
 */
export function useDiscount({
  basketId,
  onSuccessPromoCodeApplication,
  onFailedPromoCodeApplication,
}: UseDiscountProps) {
  const { formatMessage } = useIntl()
  const [promoCode, setPromotionCode] = useState<string>('')
  const [isCallbackLoading, setIsCallbackLoading] = useState<boolean>(false)
  const { country } = useCountry()

  const [errorMessage, setErrorMessage] = useState<string>('')
  const [successMessage, setSuccessMessage] = useState<string>('')

  // Reset the error message after 30 seconds
  useEffect(() => {
    if (!errorMessage) {
      return
    }

    const timer = setTimeout(() => {
      setErrorMessage('')
    }, TIMEOUT_FOR_ERROR_MESSAGE)

    return () => clearTimeout(timer)
  }, [errorMessage])

  // Reset the success message after 30 seconds
  useEffect(() => {
    if (!successMessage) {
      return
    }

    const timer = setTimeout(() => {
      setSuccessMessage('')
    }, TIMEOUT_FOR_SUCCESS_MESSAGE)

    return () => clearTimeout(timer)
  }, [successMessage])

  const applyPromoCodeToBasket = useApplyDiscountCodeToBasketMutation({
    onSuccess: async (data) => {
      if (onSuccessPromoCodeApplication) {
        setIsCallbackLoading(true)
        await onSuccessPromoCodeApplication()
        setIsCallbackLoading(false)
      }

      const errors = extractErrorsFromPromotionCodeApplicationResponse(
        data,
        formatMessage
      )

      if (errors.length) {
        setErrorMessage(errors.join('. '))

        return
      }

      setSuccessMessage(
        formatMessage({ id: defaultSuccessfullyAppliedPromotionCodeMessageId })
      )

      // Clean up the promo code
      setPromotionCode('')
    },

    onError: async (error) => {
      if (onFailedPromoCodeApplication) {
        setIsCallbackLoading(true)
        await onFailedPromoCodeApplication()
        setIsCallbackLoading(false)
      } else {
        setErrorMessage(formatMessage({ id: 'discount.error.applyPromotion' }))
      }
    },
  })

  const removePromoCodeFromBasket = useRemoveDiscountCodeFromBasketMutation({
    onSuccess: async (data) => {
      if (onSuccessPromoCodeApplication) {
        setIsCallbackLoading(true)
        await onSuccessPromoCodeApplication()
        setIsCallbackLoading(false)
      }

      if (!data.ok) {
        setErrorMessage(
          formatMessage({ id: 'discount.error.removePromotion.dataIssue' })
        )

        return
      }

      setSuccessMessage(
        formatMessage({ id: 'discount.success.removePromotion' })
      )
    },

    onError: () => {
      setErrorMessage(
        formatMessage({ id: 'discount.error.removePromotion.generic' })
      )
    },
  })

  const onPromoCodeUpdate = ({ value }: { value: string }) => {
    setPromotionCode(value.trim())
  }

  const submitPromoCode = (event: React.SyntheticEvent<EventTarget>) => {
    event.stopPropagation()

    setErrorMessage('')
    setSuccessMessage('')

    applyPromoCodeToBasket.mutate({
      basketId,
      promoCode,
      country,
    })
  }

  const removePromoCode = (promoCode: string) => {
    setErrorMessage('')
    setSuccessMessage('')

    removePromoCodeFromBasket.mutate({
      basketId,
      country,
      promoCode,
    })
  }

  const isLoading =
    applyPromoCodeToBasket.isLoading ||
    removePromoCodeFromBasket.isLoading ||
    isCallbackLoading
  const isReadyToSubmit = !!promoCode && !isLoading

  const showWidget = useShowWidget({ country })

  return {
    isLoading,
    isReadyToSubmit,
    promoCode,
    onPromoCodeUpdate,
    submitPromoCode,
    removePromoCode,

    showWidget,

    errorMessage,
    successMessage,
  }
}

const defaultSuccessfullyAppliedPromotionCodeMessageId =
  'discount.success.applyPromotion.default'

const errorCodeToErrorMessageIdMap: Record<string, string> = {
  CampaignLimitReached: 'discount.error.applyPromotion.campaignLimitReached',
  CouponExpired: 'discount.error.applyPromotion.couponExpired',
  CouponLimitReached: 'discount.error.applyPromotion.couponLimitReached',
  CouponNotFound: 'discount.error.applyPromotion.couponNotFound',
  CouponPartOfNotRunningCampaign:
    'discount.error.applyPromotion.couponPartOfNotRunningCampaign',
  CouponPartOfNotTriggeredCampaign:
    'discount.error.applyPromotion.couponPartOfNotTriggeredCampaign',
  CouponRecipientDoesNotMatch:
    'discount.error.applyPromotion.couponRecipientDoesNotMatch',
  CouponRejectedByCondition:
    'discount.error.applyPromotion.couponRejectedByCondition',
  CouponStartDateInFuture:
    'discount.error.applyPromotion.couponStartDateInFuture',
  EffectCouldNotBeApplied:
    'discount.error.applyPromotion.effectCouldNotBeApplied',
  ProfileLimitReached: 'discount.error.applyPromotion.profileLimitReached',
  ProfileRequired: 'discount.error.applyPromotion.profileRequired',
  UnexpectedError: 'discount.error.applyPromotion.unExpected',
}

/**
 * Extract customer friendly error messages from the add promotion code response.
 *
 * Response could have two types of `errorCode`:
 *
 *  - Custom errors, marked with `CUSTOM-ERROR:` prefix (e.g. `CUSTOM-ERROR:Some error message`). The error message is the part after the `:` character. And it is rendered as is.
 *
 * - Static errors, which are mapped to predefined customer friendly messages.
 *
 */
export function extractErrorsFromPromotionCodeApplicationResponse(
  response: {
    promotions: {
      errorCode?: string
    }[]
  },
  formatMessage: IntlShape['formatMessage']
) {
  if (!response.promotions) {
    return [formatMessage({ id: errorCodeToErrorMessageIdMap.UnexpectedError })]
  }

  return response.promotions
    .filter(({ errorCode }) => Boolean(errorCode))
    .map(({ errorCode }) => {
      if (errorCode?.startsWith('CUSTOM-ERROR:')) {
        // what would the error be? we need to translate it
        return errorCode.split(':')[1]
      }
      if (errorCode && errorCodeToErrorMessageIdMap[errorCode]) {
        return formatMessage({ id: errorCodeToErrorMessageIdMap[errorCode] })
      }
      return formatMessage({ id: errorCodeToErrorMessageIdMap.UnexpectedError })
    })
}
