import { FC, useContext } from 'react'
import { PersonIcon, PersonFilledIcon, ChevronDown } from '@lego/ui/icons'
import {
  ResponsiveButton,
  RESPONSIVE_BUTTON_ICON_POSITION,
} from '@lego/ui/responsive-button'
import { BUTTON_SIZE, BUTTON_VARIANT } from '@lego/ui/button'
import { AuthenticationButtonProps } from './authentication-button.type'
import { UserContext } from '@abcam-web/auth-shared/contexts/user'
import { Spinner } from '@lego/ui/icons'
import { useIntl } from 'react-intl'
import { getDisplayName, getUserIcon } from './authentication-button.helpers'

export const Icon = {
  loading: <Spinner className={'animate-spin'} />,
  loggedOut: <PersonIcon />,
  loggedIn: <PersonFilledIcon />,
}

const AuthenticationButton: FC<AuthenticationButtonProps> = (props) => {
  const { user } = useContext(UserContext)
  const { className } = props
  const { formatMessage } = useIntl()

  return (
    <ResponsiveButton
      dark={true}
      className={className}
      size={BUTTON_SIZE.small}
      variant={BUTTON_VARIANT.tertiaryOutline}
      icon={getUserIcon(user)}
      iconPosition={RESPONSIVE_BUTTON_ICON_POSITION.left}
      iconRight={<ChevronDown />}
    >
      {getDisplayName(user, formatMessage)}
    </ResponsiveButton>
  )
}

export { AuthenticationButton }
