import { useState, useEffect, useCallback, useMemo } from 'react'
import { useRouter } from 'next/router'
import debounce from 'lodash/debounce'
import { getScrollDepthParameters } from './gtm'
import { useGtm } from './use-gtm'

const calculateScrollParams = () => {
  const scrollPosition = window.scrollY + window.innerHeight
  const totalHeight = document.documentElement.scrollHeight
  const scrollPercentage = (scrollPosition / totalHeight) * 100

  return {
    scrollPercentage,
    totalHeight,
    scrollPosition,
  }
}

const allThresholds = [25, 50, 75, 90]

export const useScrollDepthTracking = () => {
  const gtm = useGtm()
  const { asPath, basePath } = useRouter()
  const path = basePath ? basePath + asPath : asPath
  const pathWithoutQueryAndHash = useMemo(() => path.split(/[#?]/)[0], [path])
  const [thresholds, setThresholds] = useState(allThresholds)

  useEffect(() => {
    setThresholds(allThresholds)
  }, [pathWithoutQueryAndHash])

  const trackScrollDepth = useCallback(() => {
    const { scrollPercentage, totalHeight, scrollPosition } =
      calculateScrollParams()
    const reachedThresholds = thresholds.filter((th) => th <= scrollPercentage)

    if (reachedThresholds.length) {
      gtm.track(
        getScrollDepthParameters({
          scrollThreshold: reachedThresholds[reachedThresholds.length - 1],
          pagePath: asPath,
          totalHeight,
          scrollPosition,
        })
      )
      setThresholds(thresholds.filter((th) => th > scrollPercentage))
    }
  }, [thresholds, asPath, gtm])

  useEffect(() => {
    const debouncedTrackScrollDepth = debounce(trackScrollDepth, 100)
    window.addEventListener('scroll', debouncedTrackScrollDepth)
    return () => window.removeEventListener('scroll', debouncedTrackScrollDepth)
  }, [trackScrollDepth])
}
