import { User } from '@abcam-web/auth-shared/contexts/user'
import { useEnvironment } from '@abcam-web/shared/utilities/environment'
import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { currentlyInMyAccount, makeAbsolute, resolveLoginUrl, resolvePath } from './utils'

/** Contains the options for `NavigatorService.goTo()`. */
export type NavigatorGoToOptions = {
  /**
   * Indicates that the page we're navigating to is _protected_ and it requires the user to be authenticated.
   * Setting this option to `true` will cause a redirect to the sign in page (see also `NavigatorService.login()`)
   * if the user isn't authenticated (and they'll land to the required page if login is successful).
   *
   * _This attribute is used only for compatibility with older pages, if you need to use it when navigating to
   *  new pages then you should consider to check if the user is authenticated inside the (new) page itself._
   */
  readonly protected?: boolean

  /**
   * Indicates whether the page we're navgating to needs to be loaded using browser's navigation features
   * (as opposed as Next's router). Set it to `true` to always use browser's navigation,
   * `undefined` (default value) or `false` to automatically determine what to do.
   *
   * _This attribute is used only for compatibility with older code, if you need to use it when writing new code
   * then something else should be fixed, possibly the logic used in `resolvePath()`._
   */
  readonly useBrowser?: boolean
}

/** Represents an high-level navigation service. */
export type NavigatorService = {
  /** Navigates to the login page, optionally specifying a `redirecTo` URL to return after the user successfully signed in. */
  login(redirectTo?: string): void
  /**
   * Generic navigation functions to navigate to the specified URL.
   * It uses the appropriate method (Next router or browser's navigation) according to the URL.
   */
  goTo(url: string, options?: NavigatorGoToOptions): void
}

/**
 * Returns an instance of the `NavigationService`.
 *
 * This hook is intended to be used when rendering **client-side**.
 *
 * This service is intended to be used instead of `useRouter()`: it abstracts away
 * boilerplate code and implementation details and uses the appropriate _method_ to
 * navigate between different pages/apps.
 * @param user - The user currently logged in, it's used to determine if they
 * can access a protected page.
 * @returns A service which implements `NavigatorService`.
 */
export function useNavigator(user: User | null | undefined): NavigatorService {
  const router = useRouter()
  const { locale } = useIntl()
  const { STAGE } = useEnvironment()

  const login = useCallback(
    (redirectTo?: string) => {
      window.location.href = resolveLoginUrl(STAGE, redirectTo)
    },
    [STAGE, router]
  )

  const goTo = useCallback(
    (url: string, options?: NavigatorGoToOptions) => {
      const isLoggedIn = !!user
      const target = resolvePath(url)

      if (
        target.type === 'local' &&
        options?.useBrowser === true &&
        isLoggedIn
      ) {
        // https://jira.abcam.com/browse/CUSTACC-990
        // use window.location.href instead of router.push so that basePath is not included in url if set
        window.location.href = `/${locale}${url}`
        return
      }

      // Absolute URLs cannot be restricted, it's up to them to enforce authentication
      if (target.type === 'remote' || options?.useBrowser === true) {
        window.location.href = currentlyInMyAccount() ? `/${locale}${url}` : url
        return
      }

      if (options?.protected && !isLoggedIn) {
        login(makeAbsolute(`/${locale}${url}`))
      } else {
        router.push(url, url, { locale })
      }
    },
    [user, login, router]
  )

  return useMemo(() => ({ login, goTo }), [login, goTo])
}
