/* eslint-disable react/jsx-no-useless-fragment */
import ReactContentLoader from 'react-content-loader'
import { ContentLoaderProps } from './contentLoader.type'
import { CONTENT_LOADER_DEFAULTS } from './constants'
import type { PropsWithChildren } from 'react'

export const ContentLoader = ({
  uniqueKey,
  component,
  speed = CONTENT_LOADER_DEFAULTS.speed,
  width = CONTENT_LOADER_DEFAULTS.width,
  height = CONTENT_LOADER_DEFAULTS.height,
  className,
  innerClassName,
  style,
  rx = CONTENT_LOADER_DEFAULTS.rx,
  ry = CONTENT_LOADER_DEFAULTS.ry,
  backgroundColor = CONTENT_LOADER_DEFAULTS.backgroundColor,
  foregroundColor = CONTENT_LOADER_DEFAULTS.foregroundColor,
  isLoading,
  children,
}: PropsWithChildren<ContentLoaderProps>) => {
  const htmlStyle = { width, height, ...style }
  return isLoading ? (
    <div className={className} data-testid={'content-loading'}>
      <ReactContentLoader
        uniqueKey={uniqueKey}
        speed={speed}
        className={innerClassName}
        style={htmlStyle}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
      >
        <rect rx={rx} ry={ry} width={width} height={height} />
      </ReactContentLoader>
    </div>
  ) : (
    <>{component || children}</>
  )
}
