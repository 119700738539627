import clsx from 'clsx'
import { opCos } from './constants'
import style from './opcos.module.css'

const OpcosBanner = () => {
  return (
    <div
      className={clsx(
        'flex justify-center bg-[#333] sm:px-[61px] xlu:px-0 print:hidden mt-[-1px]',
        style.opcosVisibility
      )}
    >
      <div className="flex flex-wrap justify-center w-full">
        {opCos.map(({ url, name, icon }, index) => (
          <a
            aria-label={name}
            className="flex items-center justify-center p-4 h-[156px] [&>svg]:w-[96px] xld:w-1/5 smd:w-1/3 tyd:w-1/2 hover:bg-black-0"
            href={url}
            key={index}
            rel="noreferrer"
            target="_blank"
          >
            {icon}
          </a>
        ))}
      </div>
    </div>
  )
}

export default OpcosBanner
