import { usePunchoutUser } from '@abcam-web/auth-shared/aws-cognito/hooks/usePunchoutUser'
import { useUser } from '@abcam-web/auth-shared/contexts/user'
import { useMemo } from 'react'

export function useWelcomeBanner() {
  const user = useUser()
  const { loading, error, isPunchoutUser, welcomeText, logoUrl } =
    usePunchoutUser(user)

  return useMemo(
    () => ({
      visible: !!user && !error && !loading && isPunchoutUser,
      text: welcomeText,
      logoUrl,
    }),
    [user, loading, error, isPunchoutUser, welcomeText, logoUrl]
  )
}
