import { header } from './header'
import { listOrders } from './orders/list-orders'
import { errorContent } from './order/error-content'
import { viewOrder } from './order/view-order'
import { modal } from './order/modal'
import { snapshot } from './order/snapshot'
import { newQuote } from './quote/new-quote'
import { components } from './components'
import { editOrder } from './order/edit-order'

export const tags = {
  header,
  listOrders,
  errorContent,
  viewOrder,
  modal,
  snapshot,
  components,
  newQuote,
  editOrder,
}
