import { startCase, upperFirst } from 'lodash'
import type { IntlShape } from 'react-intl'

const displayNamesList = (formatMessage: IntlShape['formatMessage']) => {
  const displayNames: Record<string, string> = {
    reactiveSpecies: formatMessage({ id: 'common.reactiveSpecies' }),
    hostSpecies: formatMessage({ id: 'common.hostSpecies' }),
    speciesOfOrigin: formatMessage({ id: 'common.speciesOfOrigin' }),
    speciesOrOrganism: formatMessage({ id: 'common.speciesOrOrganism' }),
    targetSpecies: formatMessage({ id: 'common.targetSpecies' }),
    detectionMethod: formatMessage({ id: 'common.detectionMethod' }),
    measurementMethod: formatMessage({ id: 'searchResults.measurementMethod' }),
    biologicalAction: formatMessage({ id: 'common.biologicalAction' }),
    assayType: formatMessage({ id: 'common.assayType' }),
    chemicalType: formatMessage({ id: 'common.chemicalType' }),
    targetIsotype: formatMessage({ id: 'common.targetIsotype' }),
    assayPlatform: formatMessage({ id: 'common.assayPlatform' }),
    target: formatMessage({ id: 'common.target' }),
    cloneNumber: formatMessage({ id: 'common.cloneNumber' }),
    application: formatMessage({ id: 'common.application' }),
    cellType: formatMessage({ id: 'common.cellType' }),
    conjugation: formatMessage({ id: 'common.conjugation' }),
    nature: formatMessage({ id: 'common.nature' }),
  }
  return displayNames
}

export const displayNameMapper = (
  key: string,
  formatMessage: IntlShape['formatMessage']
) => {
  const formattedDisplayNames: Record<string, string> = displayNamesList(
    formatMessage
  ) as unknown as Record<string, string>
  return formattedDisplayNames[key] ?? upperFirst(startCase(key).toLowerCase())
}
