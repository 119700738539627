export enum CategoryType {
  Primaries = 'Primary Antibodies',
  Secondaries = 'Secondary Antibodies',
  AssayKits = 'Assay Kits', // new name for Antibody Based Kits
  SamplePrepAndDetectionKits = 'Sample Prep & Detection Kits', // new name for Cellular Assay Kits
  ProteinsAndPeptides = 'Proteins & Peptides',
  Biochemicals = 'Biochemicals',
  MultiplexKits = 'Multiplex Kits',
  CellLinesAndLysates = 'Cell Lines & Lysates',
  ReagentsControlsAccessories = 'Reagents, Controls & Accessories',
  Mixed = 'Mixed',
}

export enum Entity {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  CategoryType = 'categoryType',
  Target = 'target',
  Application = 'application',
  ReactiveSpecies = 'reactiveSpecies',
  Modification = 'modification',
  TargetSpecies = 'targetSpecies',
  TargetIsotype = 'targetIsotype',
  Conjugation = 'conjugation',
  SampleType = 'sampleType',
  Marker = 'marker',
  AssayPlatform = 'assayPlatform',
  SpeciesOfOrigin = 'speciesOfOrigin',
  Nature = 'nature',
  BiologicalAction = 'biologicalAction',
  AssayType = 'assayType',
  SpeciesOrOrganism = 'speciesOrOrganism',
  CellLine = 'cellLine',
  ProductSubtype = 'productSubtype',
}

enum NonEntityFacetType {
  Clonality = 'clonality',
  HostSpecies = 'hostSpecies',
  DetectionMethod = 'detectionMethod',
  MeasurementMethod = 'measurementMethod',
  Activity = 'activity',
  ChemicalType = 'chemicalType',
  AssayTime = 'assayTime',
  GeneralRange = 'generalRange',
  Specificity = 'specificity',
  PreAdsorbed = 'preAbsorbed',
  CrossAbsorbed = 'crossAbsorbed',
  Quantity = 'quantity',
  Analyte = 'analyte',
  ExpressionSystem = 'expressionSystem',
  BiologicalActive = 'biologicalActive',
  Tag = 'tag',
  Solubility = 'solubility',
  Permeability = 'permeability',
  FormOrState = 'formOrState',
  Knockout = 'knockout',
  ExtractType = 'extractType',
  CellType = 'cellType',
}

export const FacetType = {
  ...NonEntityFacetType,
  ...Entity,
}

export type FacetType = NonEntityFacetType | Entity
