import { defaultLocale } from '@abcam-web/shared/config'
import { CountryStorage } from '@abcam-web/shared/utilities/storage'
import { isEntityType, mapTypeToEntityName } from '../entity/entity-type'
import { getQueryString } from '../get-query-string'
import { getPDPHash } from '../pdp/pdp-ui-state'
import { getContentPagesUrls } from './helpers/get-content-pages-urls'
import type { Target } from '@abcam-web/search/public/utilities/product.type'
import type { QueryStringParams } from '../get-query-string'
import type { PdpUIState } from '../pdp/pdp-ui-state'

const getLocale = () => CountryStorage.getSelectedLocale() || defaultLocale
const notFoundRoute = '/404'

type HomepageArgs = { locale?: string }

const homePage = (args?: HomepageArgs) => {
  const href =
    typeof args?.locale === 'string'
      ? args?.locale === ''
        ? '/'
        : `/${args.locale}`
      : `/${getLocale()}`
  return {
    href,
    as: href,
  }
}

const notFound = () => notFoundRoute

const productDetailPage = ({
  seoClassLevelOne,
  productSlug,
  uiState,
}: {
  seoClassLevelOne?: string
  productSlug: string
  uiState?: Partial<PdpUIState>
}) => {
  const href = `/products/${seoClassLevelOne}/${productSlug}${getPDPHash(
    uiState ?? {}
  )}`
  return { href, as: href }
}

const shortFormPdp = ({ abId, locale }: { abId: string; locale?: string }) => {
  const href = `/${locale || getLocale()}/products/all/${abId}`
  return { href, as: href }
}

const entitiesPage = ({ type, slug }: { type: string; slug: string }) => {
  const href = isEntityType(type)
    ? `/${mapTypeToEntityName(type)}/${slug}`
    : notFoundRoute
  return { href, as: href }
}

// the non digit part of the number might contain
// proprietary information and must not be part
// of the url.
const entitiesDetailPage = ({ slug, number }: Target) => {
  const href = `/targets/${slug}/${number.match(/\d*$/)?.[0]}`
  return { href, as: href }
}

const categoriesPage = (props: QueryStringParams) => {
  const href = `/category?${getQueryString(props)}`
  return { href, as: href }
}

const fallbackSearchPage = (props: QueryStringParams) => {
  const href = `/search/page?${getQueryString({
    productSorting: 'relevance',
    resourceSorting: 'relevance',
    ...props,
  })}`
  return { href, as: href }
}

const resultsPage = (props: QueryStringParams) => {
  const href = `/search?${getQueryString({
    productSorting: 'relevance',
    resourceSorting: 'relevance',
    ...props,
  })}`
  return { href, as: href }
}

const contentPage = ({
  contentType,
  slug,
}: {
  contentType: string
  slug: string
}) => {
  const href = getContentPagesUrls(contentType, slug)
  return { href, as: href }
}

export const routes = {
  contentPage,
  resultsPage,
  fallbackSearchPage,
  categoriesPage,
  entitiesPage,
  entitiesDetailPage,
  shortFormPdp,
  productDetailPage,
  homePage,
  notFound,
}
