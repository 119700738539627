import { Notification, NOTIFICATION_VARIANT } from '@lego/ui/notification'
import { tags } from '@tagging-shared/orderprocessing'
import { FC } from 'react'

const ValidatePriceEditNotification: FC<{ className: string }> = ({
  className,
}) => (
  <Notification
    variant={NOTIFICATION_VARIANT.cautionary}
    rounded
    className={className}
    dataCy={tags.editOrder.notifications.priceAdjustmentApprovalNotification}
  >
    <div className="text-left text-body-xmedium text-grey-dark">
      Please make sure you added the link to related incident with correct
      reason to submit the order.
    </div>
  </Notification>
)

export default ValidatePriceEditNotification
