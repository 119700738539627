import { useEffect, useState } from 'react'
import Snackbar, { SnackbarCloseReason } from '@material-ui/core/Snackbar'
import Fade from '@material-ui/core/Fade'
import type { ToastNotificationProps } from './toastNotification.type'
import { Notification } from '../notification'
import { NOTIFICATION_SIZE } from '../notification.type'
import type { PropsWithChildren, SyntheticEvent } from 'react'

const defaultProps = {
  size: NOTIFICATION_SIZE.medium,
  offset: 115, // Current header, ideally we measure the height needed and pass it
  autoHideDuration: 5000,
  dismissDelay: 1000,
  transitionComponent: Fade,
}

export const ToastNotification = (
  props: PropsWithChildren<ToastNotificationProps>
) => {
  const {
    isOpen,
    children,
    onClose,
    offset,
    autoHideDuration,
    className,
    classNameContent,
    dismissDelay,
    transitionComponent,
    transitionProps,
  } = {
    ...defaultProps,
    ...props,
  }

  const [toastJustOpened, setToastJustOpened] = useState(false)

  useEffect(() => {
    if (!toastJustOpened) return

    const timer = setTimeout(() => {
      setToastJustOpened(false)
    }, dismissDelay)
    return () => clearTimeout(timer)
  }, [dismissDelay, toastJustOpened])

  const _onClose = (e?: SyntheticEvent, reason?: SnackbarCloseReason) => {
    const isClickAway = reason === 'clickaway'
    if (isClickAway && toastJustOpened) return

    onClose && onClose(e, reason)
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={isOpen}
      TransitionComponent={transitionComponent}
      TransitionProps={{
        ...transitionProps,
        onEnter: () => {
          setToastJustOpened(true)
        },
      }}
      autoHideDuration={onClose ? autoHideDuration : undefined}
      onClose={_onClose}
      className={className}
      style={{ top: `${offset}px` }}
    >
      <div
        className={`rounded-8px shadow-elevation-5`}
        data-cy="toast_notification"
      >
        {' '}
        {/* Need to wrap it in a div for the fade transition to work */}
        <Notification
          {...{
            ...props,
            className: classNameContent,
            highContrast: true,
            size: NOTIFICATION_SIZE.small,
          }}
        >
          {children}
        </Notification>
      </div>
    </Snackbar>
  )
}
