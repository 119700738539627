const validAbIdRegex = /(?:\b|_)(AB[0-9]{1,10})(?:\b|_)/gi

export const generateImageTitle = (title?: string | null) =>
  title
    ?.split(validAbIdRegex)
    .map((part) => {
      if (part && validAbIdRegex.test(part)) {
        return part.toLowerCase()
      }
      return part
    })
    .join('')
