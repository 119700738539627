import { DeliveryMethodType } from '@abcam-web/shared/data-access/ecommerce-schema'
import { FastDelivery } from '@lego/ui/icons'
import cx from 'classnames'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'

import styles from './delivery-info.module.css'

interface DeliveryInfoProps {
  type: DeliveryMethodType
  className?: string
}

const DeliveryInfo: FC<DeliveryInfoProps> = ({
  type,
  className = undefined,
}) => {
  const { formatMessage } = useIntl()
  return (
    <div
      style={{ borderRadius: '3px' }}
      className={cx('flex w-fit h-fit ', {
        'bg-interactive-blue-transparent-hover text-blue-default':
          type !== DeliveryMethodType.STANDARD,
        'bg-grey-5 text-black-0': type === DeliveryMethodType.STANDARD,
        [`${className}`]: !!className,
      })}
    >
      <FastDelivery className={cx(styles.deliveryIcon)} />{' '}
      <span
        className={cx(
          'inline-block my-auto whitespace-nowrap mr-2 text-ui-small'
        )}
      >
        {formatMessage({
          id: `deliveryOptions.type.${type}`,
          defaultMessage: 'N/A',
        })}
      </span>
    </div>
  )
}

export default DeliveryInfo
