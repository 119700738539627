import { FC } from 'react'

import { BaseLabel, ErrorLabelProps } from '../types'

export const InfoLabel: FC<BaseLabel> = ({ content }) =>
  content ? <p className="my-4 text-body-xmedium">{content}</p> : null

export const ErrorLabel: FC<ErrorLabelProps> = ({ content }) =>
  content ? (
    <>
      {content.map((error) => (
        <p className="text-body-small text-negative" key={error}>
          {error}
        </p>
      ))}
    </>
  ) : null
