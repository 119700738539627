import { AEMHTMLContent } from '@abcam-web/shared/ui/content'
import cn from 'classnames'
import debounce from 'lodash/debounce'
import { useState, useRef, useEffect } from 'react'
import { featureColumnType, productTabTitle } from '../constants'
import { useIsVisible } from '@lego/hooks/is-visible'
import { MegaNavButton } from '../mega-nav-button'
import { MegaNavImage } from '../mega-nav-image'
import { MegaNavLinks } from '../mega-nav-links'
import type {
  Block,
  MegaNavActiveStates,
  MegaNavProps,
} from '../mega-nav.types'
import { calculateUnderlineLocation } from '../utils/calculate-underline'
import { useRouter } from 'next/router'
import Link from 'next/link'
import ConditionalWrap from 'conditional-wrap'

const renderBlocks = (
  blocks: Block[],
  setActiveSubNav: (subNav: string) => void,
  setForceHideUnderline: (boolValue: boolean) => void,
  navLanguage: string
) => {
  return (
    <div>
      {blocks &&
        blocks.map(
          ({ type, title, titleUrl, links, button, image, blockCopy }, i) => {
            const isFeaturedColumn = type === featureColumnType.en
            return (
              <div key={title + i} className="flex flex-col mb-10 last:mb-0">
                <ConditionalWrap
                  condition={isFeaturedColumn}
                  wrap={(children) => (
                    <div className="flex items-start lgd:flex-col gap-4">
                      {children}
                    </div>
                  )}
                >
                  <>
                    <div
                      className={cn(
                        isFeaturedColumn && image?._path && 'w-1/2 lgd:w-full'
                      )}
                    >
                      {titleUrl ? (
                        <Link
                          className="mb-2 font-bold text-heading-x-small"
                          href={titleUrl}
                        >
                          {title}
                        </Link>
                      ) : (
                        <h3 className="mb-2 font-bold text-heading-x-small">
                          {title}
                        </h3>
                      )}
                      {isFeaturedColumn && blockCopy && (
                        <AEMHTMLContent render={blockCopy} />
                      )}
                    </div>
                    {image?._path && (
                      <MegaNavImage
                        image={image}
                        isFeatureColumn={isFeaturedColumn}
                      />
                    )}
                  </>
                </ConditionalWrap>
                {blockCopy && <AEMHTMLContent render={blockCopy} />}
                {links && (
                  <ul>
                    <MegaNavLinks
                      links={links}
                      onClick={(arg) => {
                        setForceHideUnderline(true)
                        setActiveSubNav(arg)
                      }}
                      language={navLanguage}
                      isFeatureColumn={isFeaturedColumn}
                    />
                  </ul>
                )}
                {button && (
                  <MegaNavButton
                    {...button}
                    onClick={(arg) => {
                      setForceHideUnderline(true)
                      setActiveSubNav(arg)
                    }}
                    language={navLanguage}
                  />
                )}
              </div>
            )
          }
        )}
    </div>
  )
}

export const MegaNavDesktop = ({
  items,
  classOverrides,
  isOnHomepage,
}: MegaNavProps) => {
  const megaNavRef = useRef<HTMLDivElement>(null)
  const isVisible = useIsVisible(megaNavRef)
  const { pathname, locale } = useRouter()
  const originalUnderlineLocation = calculateUnderlineLocation(pathname)

  const [activeSubNav, setActiveSubNav] = useState<MegaNavActiveStates>(
    originalUnderlineLocation
  )

  useEffect(() => {
    if (isVisible) {
      return
    }
    setActiveSubNav('')
  }, [isVisible])

  const [lineLeft, setLineLeft] = useState(0)
  const [lineWidth, setLineWidth] = useState(0)
  const [lineOpacity, setLineOpacity] = useState(0)
  const [insideMegaNav, setInsideMegaNav] = useState(false)
  const [forceHideUnderline, setForceHideUnderline] = useState(false)

  let debouncedActivateMegaNav: ReturnType<typeof debounce>

  useEffect(() => {
    originalPositionLine()
  }, [pathname])

  useEffect(() => {
    if (activeSubNav === '' && !forceHideUnderline) {
      originalPositionLine()
    }
  }, [activeSubNav, forceHideUnderline])

  const originalPositionLine = () => {
    const topMeganavNode = megaNavRef.current

    if (topMeganavNode !== null && originalUnderlineLocation) {
      const originalMenuItem = topMeganavNode.querySelector(
        `[data-title=${originalUnderlineLocation}] a`
      ) as HTMLAnchorElement

      const originalMenuItemIndex = originalMenuItem.getAttribute(
        'data-index'
      ) as string

      // meganav extra wide ("mn_xxl" breakpoint)
      if (window.innerWidth >= 1965) {
        let left = originalMenuItem?.offsetLeft ?? 0
        left -= 30
        setLineLeft(left)
      } else {
        const targetLeft = originalMenuItem?.offsetLeft
        const parentLeft = topMeganavNode?.offsetLeft ?? 0
        let left = targetLeft - parentLeft
        left -= 30
        setLineLeft(parseInt(originalMenuItemIndex, 10) === 0 ? 1 : left)
      }

      setLineWidth(originalMenuItem.getBoundingClientRect().width)
      setLineOpacity(1)
    }
  }

  const activateMegaNav = (
    target: HTMLLIElement,
    title: string,
    index: number
  ) => {
    if (target) {
      setInsideMegaNav(true)

      // Reveal the appropriate mega nav section
      setActiveSubNav(title)

      // Align, size and show animated line
      const menuItemTarget = target?.querySelector('.menu-item') as HTMLElement

      // meganav extra wide ("mn_xxl" breakpoint)
      if (window.innerWidth >= 1965) {
        let left = menuItemTarget?.offsetLeft ?? 0
        left -= 30
        setLineLeft(left)
      } else {
        const targetLeft = menuItemTarget?.offsetLeft
        const parentLeft = target?.parentElement?.offsetLeft ?? 0
        const left = targetLeft - parentLeft
        setLineLeft(index === 0 ? 1 : left)
      }

      setLineWidth(target.children[0].getBoundingClientRect().width)
      setLineOpacity(1)
    }
  }

  const onMouseEnterItem = (
    target: HTMLLIElement,
    title: string,
    index: number
  ) => {
    if (insideMegaNav) {
      activateMegaNav(target, title, index)
    } else {
      debouncedActivateMegaNav = debounce(
        (target, title, index) => activateMegaNav(target, title, index),
        300
      )
      debouncedActivateMegaNav(target, title, index)
    }
  }

  const onMouseLeaveItem = () => {
    if (originalUnderlineLocation === '') {
      setLineOpacity(0)
    }
  }

  const onMouseLeaveContainer = () => {
    if (debouncedActivateMegaNav) {
      debouncedActivateMegaNav.cancel()
    }
    setInsideMegaNav(false)
    setActiveSubNav('')
  }

  return (
    <nav
      aria-label="Main Menu"
      ref={megaNavRef}
      className={cn(
        'mn_xxl:relative text-white px-[30px] flex flex-row',
        classOverrides?.wrapper
      )}
      onMouseLeave={onMouseLeaveContainer}
    >
      <ul>
        {items.map(
          ({ title, originalTitle, slug, sections, language }, index) => {
            const navLanguage = language

            // This needs to be done for the queryselector to work
            const transformedTitle = originalTitle
              .toLowerCase()
              .replace(' ', '-')
            const offsetTop = Math.floor(
              megaNavRef?.current?.getBoundingClientRect?.()?.bottom || 0
            )

            return (
              <li
                data-title={transformedTitle}
                data-index={index}
                key={transformedTitle + index}
                onMouseEnter={(e) => {
                  const target = e.currentTarget as HTMLLIElement
                  onMouseEnterItem(target, originalTitle, index)
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    const target = e.currentTarget as HTMLLIElement
                    activateMegaNav(target, originalTitle, index)
                  }
                  if (e.key === 'Escape') {
                    setInsideMegaNav(false)
                    setActiveSubNav('')
                    onMouseLeaveItem()
                  }
                }}
                onMouseLeave={onMouseLeaveItem}
                className={cn(
                  'cursor-default px-7 first:pl-0 last:pr-0 inline-block',
                  classOverrides?.navItem
                )}
              >
                <a
                  aria-expanded={insideMegaNav}
                  className="block pt-2 mb-1 font-bold leading-none hover:no-underline menu-item pb-[0.4375rem] w-max"
                  href={`/${locale}${slug}`}
                >
                  {title}
                </a>
                <div
                  style={{ height: `calc(100vh - ${offsetTop}px)` }}
                  className={cn(
                    // Note: must compare to the "originalTitle" text here otherwise the mega nav won't show
                    activeSubNav === originalTitle ? '' : 'hidden',
                    'absolute left-0 mn_xl:left-0 mn_xxl:left-[30px] w-full h-screen z-meganavOverlay overflow-y-auto',
                    isOnHomepage ? 'pt-4' : 'pt-3'
                  )}
                >
                  <div
                    className={cn(
                      'bg-black-0 px-[40px] pb-12 mn_lg:w-[900px] mn_xl:w-[1120px] mn_xl:px-[80px] mn_lg:mx-auto mn_xl:mx-auto mn_xxl:mx-0'
                    )}
                    onMouseEnter={() => setActiveSubNav(originalTitle)}
                    onMouseLeave={() => setActiveSubNav('')}
                  >
                    <h2 className="py-5 border-b border-white text-heading-large font-header mb-[28px]">
                      {title}
                    </h2>

                    <div
                      className={cn(
                        'grid gap-4',
                        originalTitle === productTabTitle.en
                          ? 'grid-cols-4'
                          : 'grid-cols-5'
                      )}
                    >
                      {sections.map(({ blocks }, i) => {
                        return (
                          blocks &&
                          blocks[0]?.title && (
                            <ConditionalWrap
                              condition={
                                blocks[0]?.type === featureColumnType.en
                              }
                              wrap={(children) => (
                                <div
                                  key={title + i}
                                  className="pl-10 border-l border-stroke-darkbg-dark col-span-2"
                                >
                                  {children}
                                </div>
                              )}
                            >
                              {renderBlocks(
                                blocks,
                                setActiveSubNav,
                                setForceHideUnderline,
                                navLanguage
                              )}
                            </ConditionalWrap>
                          )
                        )
                      })}
                    </div>
                  </div>
                </div>
              </li>
            )
          }
        )}
      </ul>
      {/* Animated hover line */}
      <div
        className={cn(
          'absolute mn_xxl:top-[1.9375rem]',
          isOnHomepage ? 'top-[2.9375rem]' : 'top-[2.6875rem]'
        )}
      >
        <div
          className="relative z-meganavOverlay h-[4px] bg-orange-0 transition-nav duration-300"
          style={{
            opacity: forceHideUnderline ? 0 : lineOpacity,
            left: `${lineLeft}px`,
            width: `${lineWidth}px`,
          }}
        ></div>
      </div>
    </nav>
  )
}
