import { OrganisationsForContactQuery } from '@abcam-web/shared/data-access/ecommerce-schema'

export enum MultipleOrgLocation {
  BUY_BOX = 'buy-box',
  BASKET = 'basket',
  CHECKOUT = 'checkout',
}

export type OrganisationSelectorDropdownProps = {
  className?: string
  title?: string
  selectedOrganisationRegistryId?: string | null
  organisations?: OrganisationsForContactQuery['organisationsForContact']
  location?: MultipleOrgLocation
  fullWidth?: boolean
  onChange: (
    selectedOrganisationName?: string,
    selectedOrganisationRegistryId?: string
  ) => void
}

export type Organisation = {
  organisationRegistryId?: string | null
  organisationName?: string | null
}
