import {
  ClickId,
  DrawerSubtype,
  DrawerType,
  EventName,
  getBaseWebEngagementProperties,
  LocationID,
} from '@abcam-web/shared/data-access/tracking'
import { TrackClickEventParams } from '@abcam-web/auth-shared/types/gtm-tracking.types'
import { Routes } from '@abcam-web/auth-shared/constants/navMenuItems'

const mapMenuItemToClickId = (menuItem: Routes | undefined) => {
  switch (menuItem) {
    case 'account':
      return ClickId.MY_ACCOUNT
    case 'orders':
      return ClickId.ORDERS
    case 'profile':
      return ClickId.PROFILE
    case 'reviews':
      return ClickId.REVIEWS
    case 'rewardPoints':
      return ClickId.REWARD_POINTS
    case 'inquiries':
      return ClickId.INQUIRIES
    default:
      return ''
  }
}

export const trackLogoutClick = ({
  gtm,
  pathName,
  destinationPath,
}: TrackClickEventParams) => {
  const trackingParameters = {
    ...getBaseWebEngagementProperties(),
    click_id: ClickId.LOGOUT,
    page_path: pathName,
    location_id: LocationID.OVERLAY,
    drawer_subtype: DrawerSubtype.MY_ACCOUNT,
    destination_path: destinationPath,
  }

  gtm.track(trackingParameters)
}

export const trackAccountMenuClick = ({
  gtm,
  pathName,
  isLoggedIn,
}: TrackClickEventParams) => {
  const trackingParameters = {
    ...getBaseWebEngagementProperties(),
    click_id: ClickId.MY_ACCOUNT,
    page_path: pathName,
    location_id: LocationID.OVERLAY,
    drawer_subtype: isLoggedIn
      ? DrawerSubtype.MY_ACCOUNT
      : DrawerSubtype.SIGN_IN,
    destination_path: pathName,
  }

  gtm.track(trackingParameters)
}

export const trackAccountMenuItemClick = ({
  gtm,
  pathName,
  destinationPath,
  isLoggedIn,
  menuItem,
}: TrackClickEventParams) => {
  const trackingParameters = {
    event: EventName.LINK_CLICK,
    click_id: mapMenuItemToClickId(menuItem),
    page_path: pathName,
    location_id: LocationID.DRAWER,
    drawer_type: DrawerType.CUSTOMER_ACCOUNTS,
    drawer_subtype: isLoggedIn
      ? DrawerSubtype.MY_ACCOUNT
      : DrawerSubtype.SIGN_IN,
    destination_path: destinationPath,
  }

  gtm.track(trackingParameters)
}

export const trackSignInClick = ({
  gtm,
  pathName,
  destinationPath,
}: TrackClickEventParams) => {
  const trackingParameters = {
    event: EventName.LINK_CLICK,
    click_id: ClickId.SIGNIN,
    location_id: LocationID.DRAWER,
    page_path: pathName,
    destination_path: destinationPath,
    drawer_subtype: DrawerSubtype.SIGN_IN,
  }

  gtm.track(trackingParameters)
}

export const trackCreateAccountClick = ({
  gtm,
  pathName,
  destinationPath,
}: TrackClickEventParams) => {
  const trackingParameters = {
    event: EventName.LINK_CLICK,
    click_id: ClickId.CREATE_ACCOUNT,
    location_id: LocationID.DRAWER,
    destination_path: destinationPath,
    page_path: pathName,
    drawer_subtype: DrawerSubtype.SIGN_IN,
  }

  gtm.track(trackingParameters)
}
