import { components } from './types.d'

export type PriceModel = components['schemas']['Price']
export type PriceAdjustmentModel = components['schemas']['PriceAdjustment']

export type AssetModel = components['schemas']['Asset']
export type AssetDefinitionModel = components['schemas']['AssetDefinition']

export type ContactAddressesModel =
  components['schemas']['AddressesSearchResult']

export type BasketItemModel = components['schemas']['BasketItem']
export type BasketSummaryModel = components['schemas']['BasketSummary']
export type BasketModel = components['schemas']['Basket']
export type BasketChangeItemModel = components['schemas']['BasketChangeItem']
export type BasketChangeResultModel =
  components['schemas']['BasketChangeResult']
export type BasketChangeFailuresModel =
  components['schemas']['BasketChangeFailures']

export type OrderCreationRequestModel =
  components['schemas']['OrderCreationRequest']
export type OrderRequestModel = components['schemas']['OrderRequest']
export type OrderResponseModel = components['schemas']['OrderResponse']
export type OrderAddressModel = components['schemas']['Address']
export type OrderChangeStatusModel = components['schemas']['OrderChangeStatus']

export type OrderResponseSoldToContact = components['schemas']['soldToContact']
export type OrderResponseShipToContact = components['schemas']['shipToContact']
export type OrderResponseBillToContact = components['schemas']['billToContact']

export type QuoteSendResponse = OrderResponseModel
export type QuotePreviewResponse = components['schemas']['QuotePreviewResponse']
export type QuoteValidateResponse = OrderResponseModel
export type QuoteValidationErrorResponse = {
  data: components['schemas']['QuoteValidationErrorResponse']
  error: string
}

export type QuoteValidationErrorType =
  components['schemas']['QuoteValidationErrorType']

export type QuoteSendByEmailRequest =
  components['schemas']['QuoteSendByEmailRequest']
export type QuoteSendByEmailResponse = OrderResponseModel

export type MarketInfoModel = components['schemas']['MarketInfo']
export type MarketType = components['schemas']['MarketType']
export type PurchaseType = components['schemas']['PurchaseType']
export type StocksDetails = components['schemas']['StockDetails']
export type StockAvailability = components['schemas']['StockAvailability']
export type AddressesSearchResult =
  components['schemas']['AddressesSearchResult']
export type AddressDetails = components['schemas']['AddressDetails']
export type OrganisationDetails = components['schemas']['OrganisationDetails']
export type OrganisationsResponse =
  components['schemas']['OrganisationsResponse']
export type RestServiceError = components['schemas']['RestServiceError']
export type Contact = components['schemas']['Contact']
export type ContactsResponse = components['schemas']['ContactsResponse']
export type InquiryType = components['schemas']['InquiryType']
export type InquiryAsset = components['schemas']['InquiryAsset']
export type DistributorInquiryModel =
  components['schemas']['DistributorInquiryRequest']
export type LotSpecificOrder = components['schemas']['LotSpecificOrder']
export type StockNotesCode = components['schemas']['StockNotesCode']
export type StockNotesType = components['schemas']['StockNotesType']
export type StockNotes = components['schemas']['StockNote'][]

export type CheckDeliveryDatesRequest =
  components['schemas']['BasketDeliveryDatesRequest']
export type CheckDeliveryDatesResponse =
  components['schemas']['BasketDeliveryDatesResponse']

export type UpdateDeliveryDatesRequest =
  components['schemas']['BasketDeliveryDatesRequest']
export type UpdateDeliveryDatesResponse = components['schemas']['Basket']

export const StockNotesCodeValue: Record<string, StockNotesCode> = {
  Unknown_Product_Availability: '101 - Unknown Product Availability',
  Supplier_Delays_and_Disruptions: '102 - Supplier Delays and Disruptions',
  Products_with_Incomplete_Data_Setup:
    '103 - Products with Incomplete Data Setup',
  Manufacturing_Issues: '104 - Manufacturing Issues',
  Conjugates_on_Demand: '105 - Conjugates on Demand',
  Quarantine: '106 - Quarantine',
  Made_to_Order_Cell_Lines: '108 - Made to Order Cell Lines',
  Info_on_Moving_from_Non_recombinants_to_Recombinants:
    '109 - Info on Moving from Non-recombinants to Recombinants',
}

export const StockNotesTypeValue: Record<StockNotesType, StockNotesType> = {
  Override: 'Override',
  Adjacent: 'Adjacent',
} as const

export const MarketTypeEnum: Record<string, MarketType> = {
  Mixed: 'Mixed',
  Direct: 'Direct',
  Distributor: 'Distributor',
} as const

export const PurchaseTypeEnum: Record<string, PurchaseType> = {
  Checkout: 'checkout',
  Distributor: 'distributor',
  Mixed: 'mixed',
} as const

export const InquiryTypeAsEnum: Record<InquiryType, InquiryType> = {
  TechSupport: 'TechSupport',
  General: 'General',
  Purchasing: 'Purchasing',
  Quote: 'Quote',
} as const
