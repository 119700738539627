import { SignInController } from '@abcam-web/ecommerce/components'
import { useBasketSummaryPrice } from '@abcam-web/ecommerce/utilities'
import {
  BasketModel,
  PurchaseType,
  PurchaseTypeEnum,
} from '@abcam-web/shared/data-access/ecommerce-schema'
import {
  getBasketFormattedPrices,
  getStockDetails,
  useShoppingExperience,
} from '@abcam-web/shared/ecommerce/utilities'
import { useCountry } from '@abcam-web/shared/utilities/country'
import { getLanguageFromLocale } from '@abcam-web/shared/utilities/localisation'
import { formatCurrency } from '@currency-formatter'
import { Button } from '@lego/ui/button'
import { Bin, Plus, SpinnerOffset } from '@lego/ui/icons'
import { Cart } from '@lego/ui/icons'
import classNames from 'classnames'
import { FC, useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import styles from './floating-basket.module.css'

export interface OnDeleteTrackingInfo {
  assetDefinitionNumber: string
  quantity: number | undefined
  size?: {
    value: string
    unit: string
  }
  price: string
}

interface FloatingBasketProps {
  shoppingBasket: BasketModel | undefined
  purchaseType: PurchaseType | undefined
  disableInteraction?: boolean
  onContactDistributor: () => void
  onCheckoutLinkClick: () => void
  onBasketLinkClick: () => void
  onQuickAddClick: () => void
  onDeleteItem?: (
    lineNumber: number,
    onDeleteTrackingInfo: OnDeleteTrackingInfo
  ) => void
  checkingOutExternal?: boolean
}

const FloatingBasket: FC<FloatingBasketProps> = ({
  shoppingBasket,
  purchaseType,
  disableInteraction = false,
  onContactDistributor = () => null,
  onDeleteItem = () => null,
  onCheckoutLinkClick = () => null,
  onQuickAddClick = () => null,
  onBasketLinkClick = () => null,
  checkingOutExternal,
}) => {
  const { formatMessage, locale } = useIntl()
  const [checkingOut, setCheckingOut] = useState(checkingOutExternal)
  const itemsLength = shoppingBasket?.items?.length || 0
  const itemsCount =
    itemsLength > 1
      ? formatMessage(
          { id: 'floatingBasket.multipleItemsCount' },
          { itemsLength }
        )
      : formatMessage({ id: 'floatingBasket.singleItemCount' })
  const { country } = useCountry()
  const language = getLanguageFromLocale(locale) as string

  const { getPrice, doUseBasePrice } = useBasketSummaryPrice({
    summary: shoppingBasket?.summary,
  })

  // update internal state if the external state
  useEffect(() => {
    setCheckingOut(checkingOutExternal)
  }, [checkingOutExternal])

  const { ui } = useShoppingExperience()

  const distributorFlow = purchaseType === PurchaseTypeEnum.Distributor

  const basketTitle = distributorFlow
    ? formatMessage({ id: 'floatingBasket.basketTitle.distributor' })
    : formatMessage({ id: 'floatingBasket.basketTitle.nonDistributor' })

  const emptyBasketPlaceholder = distributorFlow
    ? formatMessage({ id: 'floatingBasket.emptyBasketPlaceholder.distributor' })
    : formatMessage({
        id: 'floatingBasket.emptyBasketPlaceholder.nonDistributor',
      })

  const basketLinkMessage = distributorFlow
    ? formatMessage({ id: 'floatingBasket.basketLink.distributor' })
    : formatMessage({ id: 'floatingBasket.basketLink.nonDistributor' })

  const subtotalPriceFormatted =
    formatCurrency({
      locale: { language, country },
      value: getPrice('subtotal')?.value,
      currency: getPrice('subtotal')?.currency,
    }) || 'N/A'

  const handleCheckout = useCallback(() => {
    setCheckingOut(true)
    onCheckoutLinkClick()
  }, [onCheckoutLinkClick])

  const emptyBasketBody = (
    <div
      data-testid="empty-basket"
      className="font-normal text-body-small text-grey-20"
    >
      <div className={styles.emptyBasketIco}>
        <span className="mx-auto my-7">
          <Cart />
        </span>
      </div>
      <div className="text-center whitespace-pre-line">
        {emptyBasketPlaceholder}
      </div>
      <hr className="mt-4 -mx-4" />
      <Button
        dataCy="view-basket"
        dark={true}
        onClick={onBasketLinkClick}
        fullWidth
        className="mt-3"
        size="medium"
        bodyClassName="text-black-0"
        variant="textOnly"
      >
        {basketLinkMessage}
      </Button>
      <Button
        size="medium"
        onClick={onQuickAddClick}
        iconLeft={<Plus />}
        fullWidth
        className="mt-2"
        variant="primary"
      >
        {formatMessage({ id: 'floatingBasket.quickAdd' })}
      </Button>
    </div>
  )

  const basketBody = (
    <>
      <div
        data-testid="basket-body"
        style={{ overflowY: 'auto', maxHeight: '23.5rem' }}
      >
        {shoppingBasket?.items?.map((item, index) => {
          const {
            itemTotalWithDiscountFormatted,
            itemTotalWithoutDiscountFormatted,
            itemBaseTotalFormatted,
          } = getBasketFormattedPrices(item, language, country)

          const stockDetails = getStockDetails(
            item?.availableQuantity as number,
            item?.quantity,
            formatMessage,
            item?.status
          )

          const onDeleteTrackingInfo = {
            assetDefinitionNumber: item?.assetDefinitionNumber,
            quantity: item?.quantity,
            size: item?.size,
            price: itemTotalWithDiscountFormatted,
          }

          const hasAdjustmentPrice =
            itemTotalWithDiscountFormatted !== itemTotalWithoutDiscountFormatted

          return (
            <div
              className={classNames('my-5 font-normal', {
                'opacity-20 pointer-events-none': disableInteraction,
              })}
              data-cy="item"
              key={index}
            >
              <div className="font-semibold lowercase text-grey-20 text-body-x-small">
                {item?.assetDefinitionNumber}
              </div>
              <div className="flex text-body-xmedium">
                <span className="w-2/3">{item?.lineDescription}</span>
                <span data-cy="item-price" className="ml-auto">
                  {!distributorFlow && (
                    <div>
                      {hasAdjustmentPrice && (
                        <div className="font-normal line-through text-list-small">
                          {itemTotalWithoutDiscountFormatted}
                        </div>
                      )}
                      <div className="font-semibold text-ui-medium">
                        {doUseBasePrice
                          ? itemBaseTotalFormatted
                          : itemTotalWithDiscountFormatted}
                      </div>
                    </div>
                  )}
                </span>
              </div>
              <div className="flex text-body-xmedium">
                <span className="mr-3">
                  <span className="mr-1 font-semibold">
                    {formatMessage({ id: 'floatingBasket.item.size' })}:
                  </span>
                  <span data-cy="item-size">
                    {item?.size?.value}
                    {item?.size?.unit}
                  </span>
                </span>
                <span className="mr-1 font-semibold">
                  {formatMessage({ id: 'floatingBasket.item.quantity' })}:
                </span>
                <span data-cy="item-quantity">{item?.quantity}</span>
                <span
                  data-cy="bin-icon"
                  data-testid="bin-icon"
                  onClick={() => {
                    if (item?.lineNumber && !disableInteraction) {
                      onDeleteItem(item.lineNumber, onDeleteTrackingInfo)
                    }
                  }}
                  className="ml-auto cursor-pointer"
                >
                  <Bin />
                </span>
              </div>
              {!distributorFlow && (
                <div
                  className={classNames(
                    'text-ui-small mt-3 font-semibold',
                    stockDetails.color
                  )}
                >
                  {stockDetails.text}
                </div>
              )}
            </div>
          )
        })}
      </div>
      <hr className="mb-2 -mx-4" />
      {purchaseType &&
        [PurchaseTypeEnum.Mixed, PurchaseTypeEnum.Checkout].includes(
          purchaseType
        ) && (
          <>
            <div className="flex text-body-medium">
              <span>
                {formatMessage({ id: 'floatingBasket.subtotal' })} {itemsCount}
              </span>
              <span data-cy="sub-total-price" className="ml-auto">
                {subtotalPriceFormatted}
              </span>
            </div>
            {ui.showShippingInfomation && (
              <div className="font-normal text-grey-20 text-body-x-small">
                {formatMessage({
                  id: 'floatingBasket.excludesShippingAndTax',
                })}
              </div>
            )}
          </>
        )}
      <div className="flex flex-wrap mt-1">
        <Button
          dataCy="view-basket"
          dark={true}
          onClick={onBasketLinkClick}
          fullWidth
          className="mt-3"
          size="medium"
          bodyClassName="text-black-0"
          variant="textOnly"
        >
          {formatMessage({ id: 'floatingBasket.basketLink.nonDistributor' })}
        </Button>
        {!distributorFlow && (
          <Button
            dataCy="proceed-to-checkout"
            disabled={checkingOut}
            onClick={handleCheckout}
            size="medium"
            fullWidth
            className="mt-2"
            variant="primary"
            iconLeft={
              checkingOut ? (
                <SpinnerOffset className="animate-spin" />
              ) : undefined
            }
          >
            {ui.checkoutButtonLabel}
          </Button>
        )}
        {purchaseType &&
          [PurchaseTypeEnum.Mixed, PurchaseTypeEnum.Distributor].includes(
            purchaseType
          ) && (
            <Button
              dataCy="contact-distributor"
              onClick={onContactDistributor}
              size="medium"
              fullWidth
              className="mt-3"
              variant={
                purchaseType === PurchaseTypeEnum.Mixed
                  ? 'secondary'
                  : 'primary'
              }
            >
              {formatMessage({ id: 'floatingBasket.contactDistributor' })}
            </Button>
          )}
      </div>
      <hr className="my-4 -mx-4" />
      <div className="flex flex-wrap">
        <Button
          size="medium"
          onClick={onQuickAddClick}
          iconLeft={<Plus />}
          fullWidth
          className="mt-0"
          dataCy="quick-add"
          variant="tertiaryFilled"
        >
          {formatMessage({ id: 'floatingBasket.quickAdd' })}
        </Button>
      </div>
    </>
  )

  return (
    <div
      style={{ width: '23rem' }}
      className="p-4 font-semibold bg-white rounded text-black-0 shadow-elevation-2"
    >
      <div className="mb-4 text-heading-small">
        {basketTitle} {!distributorFlow && itemsCount}
      </div>
      <hr className="mt-4 -mx-4" />
      {!itemsLength ? emptyBasketBody : basketBody}
      {distributorFlow && <SignInController classNames="px-12 mt-5" />}
    </div>
  )
}

export default FloatingBasket
