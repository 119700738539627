import { ChevronLeft, ChevronRight } from '@lego/ui/icons'
import { FC } from 'react'
import DatePickerBase from 'react-datepicker'

import { DatePickerCalendarProps } from '../types'
import styles from './date-picker-calendar.module.css'

export const DatePickerCalendar: FC<DatePickerCalendarProps> = ({
  selectedDate,
  highlightDates,
  dateFormat,
  onChange,
  filterDate,
  minDate,
  maxDate,
}) => {
  return (
    <div className={'relative w-full'}>
      <DatePickerBase
        key={`${String(selectedDate)}`}
        calendarClassName={styles.datePickerCalendar}
        selected={selectedDate}
        inline
        nextMonthButtonLabel={<ChevronRight />}
        onChange={onChange}
        previousMonthButtonLabel={<ChevronLeft />}
        dateFormat={dateFormat}
        filterDate={filterDate}
        minDate={minDate}
        maxDate={maxDate}
        highlightDates={highlightDates}
        calendarStartDay={1}
      />
    </div>
  )
}
