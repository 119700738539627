import { useState, useRef, useCallback } from 'react'
import { useResizeObserver } from '@lego/hooks/resize-observer'

export const useElementHeight = ({
  offset = 0,
}: { offset?: number } | undefined = {}) => {
  const elementRef = useRef<HTMLDivElement>(null)
  const [elementHeight, setElementHeight] = useState(offset)

  const calculateTabHeaderHeight = useCallback(
    () =>
      setElementHeight(
        offset + (elementRef?.current ? elementRef.current.offsetHeight : 0)
      ),
    [offset]
  )

  useResizeObserver(elementRef, calculateTabHeaderHeight)

  return [elementRef, elementHeight] as const
}
