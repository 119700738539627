import {
  GetOrderOptionsQuery,
  OrderResponseModel,
  ShippingChargesType,
} from '@abcam-web/shared/data-access/ecommerce-schema'
import {
  ClickId,
  getBaseWebEngagementProperties,
  GTMPageType,
  LocationID,
  PageSubtype,
  useGtm,
} from '@abcam-web/shared/data-access/tracking'
import {
  getDateFnsLocale,
  useMarketInfo,
} from '@abcam-web/shared/ecommerce/utilities'
import { useCountry } from '@abcam-web/shared/utilities/country'
import { getLanguageFromLocale } from '@abcam-web/shared/utilities/localisation'
import { formatCurrency } from '@currency-formatter'
import { InfoIcon } from '@lego/ui/icons'
import { Notification } from '@lego/ui/notification'
import { RadioInput } from '@lego/ui/radio-input'
import { TextInput } from '@lego/ui/text-input'
import classNames from 'classnames'
import { format } from 'date-fns'
import { getDefaultDeliveryOption } from 'libs/ecommerce/pages/order/src/lib/helpers'
import { components } from 'libs/shared/data-access/ecommerce-schema/src/rest/types/types'
import { isNil } from 'lodash'
import { useRouter } from 'next/router'
import { FC, ReactNode, useMemo, useState } from 'react'
import { IntlShape, useIntl } from 'react-intl'

import { RowsLoader } from '../rows-loader/rows-loader'

export enum DeliveryType {
  STANDARD = 'Standard',
  OWN_COURIER = 'OwnCourier',
  PICKUP = 'PickUp',
}

const priceErrorString = 'N/A'

export interface DeliveryOptionsProps {
  includePickupOption?: boolean
  loading: boolean
  shipping?: Partial<OrderResponseModel['shipping']>
  leftLabel?: boolean
  deliveryOptions: GetOrderOptionsQuery | undefined
  hideNotification?: boolean
  disableInteractions?: boolean
  onChange: (partialShipping: Partial<OrderResponseModel['shipping']>) => void
  freeShipping?: boolean
  flatShippingCosts?: number
  partnerType?: string | null
  basketId?: string
  isQuote?: boolean
}

const DeliveryInputLabel: FC<{
  title: string
  dataCy: string
  price: string
  description: string
  info?: string
  freeShipping?: boolean
  priceOverride?: string
}> = ({
  title,
  description,
  info,
  price,
  freeShipping,
  priceOverride,
  dataCy,
}) => (
  <>
    <div data-cy={dataCy} className="font-semibold text-grey-dark">
      {title}{' '}
      <span
        data-cy={`${dataCy}-price`}
        className={classNames({
          'line-through text-grey-20': !!freeShipping || !!priceOverride,
        })}
      >
        {price}
      </span>
      {!!freeShipping && ' FREE'}
      {!!priceOverride && ` ${priceOverride}`}
    </div>
    <div className="font-normal text-grey-20 text-body-small">
      {description}
    </div>
    {info && (
      <div className="font-normal text-green text-body-small">{info}</div>
    )}
  </>
)

export const getDeliveryOptionMessage = (
  rawDate: string | null | undefined,
  numberOfDeliveries: number,
  locale: string | undefined,
  formatMessage: IntlShape['formatMessage']
): string | undefined => {
  if (isNil(rawDate)) {
    return undefined
  }
  const firstDeliveryDate = new Date(rawDate || '')
  const formattedDate = formatMessage(
    { id: 'deliveryOptions.deliveryMessage.dateFormat' },
    {
      dayOfWeek: format(firstDeliveryDate, 'iiii', {
        locale: getDateFnsLocale(locale),
      }),
      dayOfMonth: format(firstDeliveryDate, 'do', {
        locale: getDateFnsLocale(locale),
      }),
      fullMonth: format(firstDeliveryDate, 'MMMM', {
        locale: getDateFnsLocale(locale),
      }),
      oneDigitMonth: format(firstDeliveryDate, 'M', {
        locale: getDateFnsLocale(locale),
      }),
      oneDigitDay: format(firstDeliveryDate, 'd', {
        locale: getDateFnsLocale(locale),
      }),
      fourDigitYear: format(firstDeliveryDate, 'yyyy', {
        locale: getDateFnsLocale(locale),
      }),
    }
  )

  const moreDeliveries = numberOfDeliveries > 1

  const deliveryMessage = moreDeliveries
    ? formatMessage(
        { id: 'deliveryOptions.deliveryMessage.multipleDeliveries' },
        { numberOfDeliveries, date: formattedDate }
      )
    : formatMessage(
        { id: 'deliveryOptions.deliveryMessage.singleDelivery' },
        { date: formattedDate }
      )

  return deliveryMessage
}

const DeliveryOptions: FC<DeliveryOptionsProps> = ({
  includePickupOption = false,
  loading,
  shipping,
  deliveryOptions,
  hideNotification = false,
  disableInteractions = false,
  onChange,
  freeShipping,
  leftLabel = false,
  flatShippingCosts = undefined,
  partnerType = undefined,
  basketId,
  isQuote = false,
}) => {
  const { country, countryName } = useCountry()
  const router = useRouter()
  const { formatMessage } = useIntl()
  const { locale } = router
  const [courierAccountNumber, setCourierAccountNumber] = useState(
    shipping?.courierAccountNumber
  )

  // This is about allow the user to see own courier radio button selected.
  // The value will be submitted after account number is added on blur.
  const [isOwnCourierSelected, setIsCourierSelected] = useState(
    shipping?.deliveryType === DeliveryType.OWN_COURIER
  )
  const gtm = useGtm()
  const marketInfo = useMarketInfo()
  const standardCharge = deliveryOptions?.delivery?.charges?.find(
    (charge) => charge?.type === ShippingChargesType.Standard
  )
  const handlingOnlyCharge = deliveryOptions?.delivery?.charges?.find(
    (charge) => charge?.type === ShippingChargesType.HandlingOnly
  )

  const language = getLanguageFromLocale(locale) || ''

  const standardChargePrice =
    formatCurrency({
      locale: { language, country },
      value: standardCharge?.cost?.value,
      currency: standardCharge?.cost?.currency,
    }) || priceErrorString

  const handlingOnlyChargePrice =
    formatCurrency({
      locale: { language, country },
      value: handlingOnlyCharge?.cost?.value,
      currency: handlingOnlyCharge?.cost?.currency,
    }) || priceErrorString

  const flatShippingPriceFormatted =
    formatCurrency({
      locale: { language, country },
      value: flatShippingCosts,
      currency: standardCharge?.cost?.currency,
    }) || priceErrorString

  const numberOfDeliveries = deliveryOptions?.delivery?.deliveries?.length || 0

  const deliveryMessage = useMemo(
    () =>
      getDeliveryOptionMessage(
        deliveryOptions?.delivery?.deliveries?.[0]?.scheduledArrivalDate,
        numberOfDeliveries,
        /**
         * we pass locale and not language-country as we use format api to localise dates
         * This api expects a specific format of locale which we import from date-fns and map from the following locales
         * en-us, zh-cn, ja-jp
         */
        locale,
        formatMessage
      ),
    [
      deliveryOptions?.delivery?.deliveries,
      formatMessage,
      locale,
      numberOfDeliveries,
    ]
  )

  const abcamDelivery = useMemo(
    () =>
      [
        ...new Set(
          deliveryOptions?.delivery?.deliveries?.map(
            (item) =>
              `${item?.courier}-${
                item?.deliveryType
                  ? formatMessage({
                      id: `deliveryOptions.type.${item.deliveryType}`,
                      defaultMessage: item?.deliveryType,
                    })
                  : ''
              }`
          )
        ),
      ].join(', '),
    [deliveryOptions?.delivery?.deliveries, formatMessage]
  )

  const messages = {
    header: formatMessage({ id: 'deliveryOptions.header' }),
    notification: formatMessage(
      { id: 'deliveryOptions.notification' },
      { bold: (chunks: ReactNode) => <strong>{chunks}</strong> }
    ),
    standardOptionLabel: formatMessage({
      id: 'deliveryOptions.standard.label',
    }),
    pickupOptionLabel: formatMessage({ id: 'deliveryOptions.pickup.label' }),
    ownCourierOptionLabel: formatMessage({
      id: 'deliveryOptions.ownCourier.label',
    }),
    pickupOptionDescription: formatMessage({
      id: 'deliveryOptions.pickup.description',
    }),
    ownCourierOptionDescription: formatMessage({
      id: 'deliveryOptions.ownCourier.description',
    }),
    accountNumberRequiredError: formatMessage({
      id: 'deliveryOptions.ownCourier.accountNumber.requiredError',
    }),
  }

  const allowOwnCouriers =
    !!deliveryOptions?.delivery?.deliveries?.length &&
    deliveryOptions?.delivery?.deliveries?.every(
      (delivery) => delivery?.ownCourier
    )

  const deliveryType =
    shipping?.deliveryType ||
    getDefaultDeliveryOption(
      shipping,
      standardCharge?.cost.value,
      handlingOnlyCharge?.cost.value
    )

  const trackDeliveryTypeSelected = () => {
    gtm.track({
      ...getBaseWebEngagementProperties(),
      click_id: ClickId.SELECT_DELIVERY_OPTION,
      location_id: LocationID.PAGE,
      page_path: router.asPath,
      page_type: GTMPageType.CHECKOUT,
      page_subtype: PageSubtype.REVIEW_AND_PAY,
      price: standardChargePrice,
      item_cta: abcamDelivery,
      main_country_selection: countryName,
      market_type: marketInfo.data?.marketType,
      partner_type: partnerType ?? '',
      basket_id: basketId ?? '',
    })
  }

  return (
    <div className={classNames({ 'grid grid-cols-2': leftLabel })}>
      <div className={classNames({ 'col-span-1': leftLabel })}>
        <h3 className="font-semibold text-heading-x-small text-black-0">
          {messages.header}
        </h3>
      </div>
      <div className={classNames({ 'col-span-1': leftLabel })}>
        {loading ? (
          <RowsLoader
            containerClassName="pr-6 pb-6"
            uniqueKey="delivery-options-loader"
          />
        ) : (
          <>
            {!hideNotification && (
              <div>
                <Notification variant="cautionary">
                  <div className="flex">
                    <span className="my-auto mr-3">
                      <InfoIcon />
                    </span>
                    <div className="text-body-small font-primary-regular">
                      {messages.notification}
                    </div>
                  </div>
                </Notification>
              </div>
            )}
            <div
              className={classNames({
                'cursor-not-allowed opacity-30': disableInteractions,
              })}
            >
              <RadioInput
                data-testid="standard-delivery"
                checked={
                  !isOwnCourierSelected &&
                  deliveryType === DeliveryType.STANDARD
                }
                fullWidth
                disabled={disableInteractions}
                value={DeliveryType.STANDARD}
                onChecked={() => {
                  if (!disableInteractions) {
                    const shippingCosts = flatShippingCosts
                      ? flatShippingCosts
                      : standardCharge?.cost?.value ?? 0
                    setIsCourierSelected(false)
                    if (
                      shippingCosts !== shipping?.shippingCosts ||
                      shipping.deliveryType !== DeliveryType.STANDARD
                    ) {
                      trackDeliveryTypeSelected()
                      onChange({
                        shippingCosts,
                        courier: undefined,
                        courierAccountNumber: undefined,
                        deliveryType: DeliveryType.STANDARD,
                      })
                    }
                  }
                }}
                displayValue={
                  <DeliveryInputLabel
                    dataCy="standard"
                    freeShipping={freeShipping}
                    price={standardChargePrice}
                    title={messages.standardOptionLabel}
                    description={`(${abcamDelivery})`}
                    info={deliveryMessage}
                    priceOverride={
                      flatShippingCosts ? flatShippingPriceFormatted : undefined
                    }
                  />
                }
              />
              {includePickupOption && (
                <RadioInput
                  data-testid="pickup-delivery"
                  checked={
                    !isOwnCourierSelected &&
                    deliveryType === DeliveryType.PICKUP
                  }
                  disabled={disableInteractions}
                  fullWidth
                  value={DeliveryType.PICKUP}
                  onChecked={() => {
                    if (!disableInteractions) {
                      setCourierAccountNumber(undefined)
                      setIsCourierSelected(false)
                      const shippingCosts = handlingOnlyCharge?.cost.value
                      if (
                        shippingCosts !== shipping?.shippingCosts ||
                        shipping?.deliveryType !== DeliveryType.PICKUP
                      ) {
                        trackDeliveryTypeSelected()
                        onChange({
                          shippingCosts,
                          courier: undefined,
                          courierAccountNumber: undefined,
                          deliveryType: DeliveryType.PICKUP,
                        })
                      }
                    }
                  }}
                  displayValue={
                    <DeliveryInputLabel
                      dataCy="pickup"
                      title={messages.pickupOptionLabel}
                      price={handlingOnlyChargePrice}
                      freeShipping={freeShipping}
                      description={messages.pickupOptionDescription}
                    />
                  }
                />
              )}

              {allowOwnCouriers && (
                <>
                  <RadioInput
                    data-testid="own-courier-delivery"
                    checked={
                      isOwnCourierSelected ||
                      deliveryType === DeliveryType.OWN_COURIER
                    }
                    disabled={disableInteractions}
                    fullWidth
                    onChecked={() => {
                      if (!disableInteractions) {
                        trackDeliveryTypeSelected()
                        setCourierAccountNumber(undefined)
                        setIsCourierSelected(true)
                      }
                      if (isQuote) {
                        onChange({
                          shippingCosts: handlingOnlyCharge?.cost.value,
                          courier: deliveryOptions.delivery?.deliveries?.[0]
                            ?.courier as components['schemas']['Courier'],
                          courierAccountNumber: courierAccountNumber,
                          deliveryType: DeliveryType.OWN_COURIER,
                        })
                      }
                    }}
                    value={DeliveryType.OWN_COURIER}
                    displayValue={
                      <DeliveryInputLabel
                        dataCy="owncourier"
                        freeShipping={freeShipping}
                        price={handlingOnlyChargePrice}
                        title={messages.ownCourierOptionLabel}
                        description={messages.ownCourierOptionDescription}
                      />
                    }
                  />
                  {isOwnCourierSelected &&
                    deliveryOptions?.delivery?.deliveries?.[0]?.courier && (
                      <div className="ml-[51px]">
                        <TextInput
                          data-testid="own-courier-account-number-input"
                          className="text-sm font-primary-semibold"
                          label={
                            isQuote
                              ? `${deliveryOptions.delivery?.deliveries[0].courier} account number (optional):`
                              : formatMessage(
                                  {
                                    id: 'deliveryOptions.ownCourier.accountNumber.label',
                                  },
                                  {
                                    courier:
                                      deliveryOptions.delivery?.deliveries[0]
                                        .courier,
                                  }
                                )
                          }
                          required={isQuote ? false : true}
                          value={courierAccountNumber}
                          disabled={disableInteractions}
                          name="own-courier-account-number-input"
                          onChange={({ value }) => {
                            setCourierAccountNumber(value)
                          }}
                          onBlur={() => {
                            if (!disableInteractions) {
                              onChange({
                                shippingCosts: handlingOnlyCharge?.cost.value,
                                courier: deliveryOptions.delivery
                                  ?.deliveries?.[0]
                                  ?.courier as components['schemas']['Courier'],
                                courierAccountNumber: courierAccountNumber,
                                deliveryType: DeliveryType.OWN_COURIER,
                              })
                            }
                          }}
                          errorText={messages.accountNumberRequiredError}
                        />
                      </div>
                    )}
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export { DeliveryOptions }
