import { Popover, Transition } from '@headlessui/react'
import { Fragment, LegacyRef } from 'react'
import type { PropsWithChildren } from 'react'

interface Props {
  cta: React.ReactNode
  displayPosition?: 'left' | 'right' | 'mobile'
  className?: string
  panelClassname?: string
  panelStyle?: React.CSSProperties
  popoverButtonRef?: LegacyRef<any>
  onPopoverButtonClick?: () => void
}

const PopoverComponent = ({
  cta,
  children,
  displayPosition = 'left',
  className = '',
  panelClassname = '',
  panelStyle = {},
  popoverButtonRef,
  onPopoverButtonClick,
}: PropsWithChildren<Props>) => {
  const style = {
    left: 'left-0 absolute',
    right: 'right-0 absolute',
    mobile: 'fixed inset-2',
  }

  return (
    <Popover className={`relative ${className}`}>
      <Popover.Button
        ref={popoverButtonRef}
        as="div"
        onClick={onPopoverButtonClick}
      >
        {cta}
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="ease-out duration-200 origin-top"
        enterFrom="opacity-50 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-150 origin-top"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          className={`mt-1 ${style[displayPosition]} z-drawer transform ${panelClassname}`}
          style={panelStyle}
        >
          {children}
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
const Button = Popover.Button
export { PopoverComponent as Popover, Button }
