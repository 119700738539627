import {
  OrganisationDetails,
  OrganisationType,
} from '@abcam-web/shared/data-access/ecommerce-schema'
import { SearchSelect } from '@abcam-web/shared/ecommerce/components'
import {
  MenuListHeader,
  menuListOptions,
} from '@abcam-web/shared/ecommerce/components'
import { isNotNull } from '@abcam-web/shared/ecommerce/utilities'
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import {
  components,
  FormatOptionLabelMeta,
  MenuListProps,
  MultiValue,
  SingleValue,
} from 'react-select'

import resourceStrings from './en.json'

const DEFAULT_OPTIONS_LENGTH = 1

function isSingleValue<T>(
  value: MultiValue<T> | SingleValue<T>
): value is SingleValue<T> {
  return !Array.isArray(value)
}

export interface Props {
  organisations?: OrganisationDetails[]
  onChange: (option?: OrganisationDetails) => void
  onClickReset?: () => void
  onMenueScrollCallback?: () => void
  menuListHeader?: OrganisationDetails
  optionClassName?: string
  isSearchable?: boolean
  required?: boolean
  label?: string
  loading?: boolean
  disableable?: boolean
  selectedOrg?: OrganisationDetails
  organisationType: (typeof OrganisationType)[keyof typeof OrganisationType]
  className?: string
  'data-testid'?: string
  isClearable?: boolean
  id?: string
  name?: string
  showPleaseSelect?: boolean
}

const getAddedToOrderOption = (
  option?: OrganisationDetails,
  optionClassName?: string
): ReactNode => {
  const {
    organisationRegistryId = 'ORG ID Missing',
    localLanguageName = 'ORG Name Missing',
  } = option || {}

  return (
    <div>
      <p
        className={`${optionClassName ? optionClassName : 'text-body-medium'}`}
      >
        {localLanguageName + ' (' + organisationRegistryId + ')'}
      </p>
      <p
        className={`mt-3 font-semibold ${
          optionClassName ? optionClassName : 'text-body-medium'
        }`}
      >
        {'(Added to order)'}
      </p>
    </div>
  )
}

const getOptionValue = (option: OrganisationDetails) => {
  const {
    organisationRegistryId = 'ORG ID Missing',
    localLanguageName = 'ORG Name Missing',
  } = option || {}

  return `${localLanguageName} (${organisationRegistryId})`
}

export const MultipleOrgDropdownV2: FC<Props> = ({
  organisations,
  onChange,
  onClickReset,
  onMenueScrollCallback,
  label,
  menuListHeader,
  optionClassName,
  isSearchable = false,
  selectedOrg,
  disableable = true,
  loading,
  className,
  'data-testid': dataTestId,
  organisationType,
  required = false,
  isClearable = false,
  showPleaseSelect = true,
  ...rest
}) => {
  const [placeholderValue, setPlaceholderValue] = useState('')

  const mappedOrgs = useMemo(() => {
    const validQueriedOrgs = organisations?.filter(isNotNull)

    const filteredOrgs =
      validQueriedOrgs?.filter(
        ({ organisationRegistryId, localLanguageName }) =>
          Boolean(organisationRegistryId || localLanguageName)
      ) || []

    if (showPleaseSelect) {
      const resetOption: OrganisationDetails = {
        organisationRegistryId: undefined,
        localLanguageName: undefined,
      }

      return [resetOption, ...filteredOrgs]
    }

    return filteredOrgs
  }, [organisations])

  const isValidQueriedOrgs =
    mappedOrgs?.length > (showPleaseSelect ? DEFAULT_OPTIONS_LENGTH : 0)

  useEffect(() => {
    if (loading) {
      setPlaceholderValue(resourceStrings['placeholder.loading'])
      return
    }

    if (!isValidQueriedOrgs) {
      setPlaceholderValue(resourceStrings['placeholder.noOption'])
      return
    }

    setPlaceholderValue(resourceStrings['placeholder.closed'])
  }, [isValidQueriedOrgs, loading])

  const onChangeHandler = useCallback(
    (
      data: MultiValue<OrganisationDetails> | SingleValue<OrganisationDetails>
    ) => {
      if (data && isSingleValue<OrganisationDetails>(data)) {
        onChange(data as OrganisationDetails)
      } else {
        onClickReset?.()
        onChange(undefined)
      }
    },
    [onChange]
  )

  const onMenuFocusCallback = useCallback(() => {
    isSearchable && setPlaceholderValue(resourceStrings['placeholder.open'])
  }, [isSearchable])

  const onMenuCloseCallback = useCallback(() => {
    setPlaceholderValue(resourceStrings['placeholder.closed'])
  }, [])

  const MenuList = useCallback(
    (props: MenuListProps<OrganisationDetails>) => {
      const value = getAddedToOrderOption(menuListHeader, optionClassName)

      return (
        <components.MenuList {...props}>
          {value && <MenuListHeader>{value}</MenuListHeader>}
          {props.children}
        </components.MenuList>
      )
    },
    [menuListHeader, optionClassName]
  )

  const formatOptionLabelCallback = useCallback(
    (
      option: OrganisationDetails,
      meta: FormatOptionLabelMeta<OrganisationDetails>
    ) => {
      const value = getOptionValue(option)
      const selectedOption = selectedOrg && getOptionValue(selectedOrg)

      if (showPleaseSelect) {
        const isResetOption = Boolean(
          !option.localLanguageName && !option.organisationRegistryId
        )

        if (isResetOption) {
          const Option = menuListOptions.optionReset
          return (
            <Option contentClassName={optionClassName} onClick={onClickReset}>
              {resourceStrings['placeholder.closed']}
            </Option>
          )
        }
      }

      const isSelected = selectedOption === value
      const isOptionSelected = isSelected && meta.context === 'menu'

      const variant = isOptionSelected ? 'selectedOption' : 'default'

      const Option = menuListOptions[variant]

      return <Option contentClassName={optionClassName}>{value}</Option>
    },
    [onClickReset, optionClassName, selectedOrg]
  )

  const warningMessage = useMemo(() => {
    const { organisationRegistryId, localLanguageName } = selectedOrg || {}

    if (loading) {
      return ''
    }

    if (!isValidQueriedOrgs) {
      return resourceStrings['validationText.notFound']
    }

    if (!organisationRegistryId && !localLanguageName && required) {
      return resourceStrings['validationText.requiredOrganisation'].replace(
        '{type}',
        organisationType
      )
    }

    if (!organisationRegistryId || !localLanguageName) {
      return resourceStrings['validationText.pleaseSelect']
    }

    return ''
  }, [isValidQueriedOrgs, loading, organisationType, required, selectedOrg])

  const userError = Boolean(warningMessage) && isValidQueriedOrgs

  return (
    <div data-testid={dataTestId} className={className}>
      <SearchSelect<OrganisationDetails>
        styles={{
          placeholder: (baseStyles) => ({
            ...baseStyles,
            color:
              placeholderValue === resourceStrings['placeholder.closed']
                ? 'inherit'
                : baseStyles.color,
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: state.isSelected
              ? '#EDF6F7'
              : baseStyles.backgroundColor,
            color: 'black',
            '&:first-child': {
              backgroundColor: 'transparent',
            },
          }),
          menuList: (base, props) => ({
            ...base,
            paddingTop: '0px',
            position: 'relative',
          }),
          control: (base, state) => ({
            ...base,
            height: '48px',
            backgroundColor:
              state.menuIsOpen || userError
                ? base.backgroundColor
                : 'rgba(39, 63, 63, 0.05)',
            boxShadow:
              userError && !state.menuIsOpen
                ? '0 0 0 3px #d43737'
                : state.menuIsOpen || state.isFocused
                ? '0 0 0 3px #6DB9C1'
                : 'transparent',
            borderColor: 'transparent',
          }),
        }}
        components={{ MenuList }}
        onFocus={onMenuFocusCallback}
        onMenuOpen={onMenuFocusCallback}
        onMenuClose={onMenuCloseCallback}
        getOptionValue={getOptionValue}
        formatOptionLabel={formatOptionLabelCallback}
        onChange={onChangeHandler}
        onMenuScrollToBottom={onMenueScrollCallback}
        value={selectedOrg}
        options={mappedOrgs}
        isDisabled={disableable && (loading || !isValidQueriedOrgs)}
        instanceId={label}
        key={label}
        label={label}
        placeholder={placeholderValue}
        isSearchable={isSearchable}
        isClearable={isClearable}
        required={required}
        {...rest}
      />
      {Boolean(warningMessage) && (
        <p className="mt-2 text-negative text-body-small">{warningMessage}</p>
      )}
    </div>
  )
}
