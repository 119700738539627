import { differenceInDays } from 'date-fns'
import { GTMPageType, PageSubtype } from './gtm.enums'

const localePrefix = '(?:/[a-z]{2}-[a-z]{2})?'

const pageTypeMap: Partial<{ [key in GTMPageType]: RegExp }> = {
  [GTMPageType.CONTENT]: new RegExp(
    `^${localePrefix}/(?:events|legal|technical-resources)`
  ),
  [GTMPageType.PDP]: new RegExp(`^${localePrefix}/(?:printed/|products/)`),
  [GTMPageType.HOMEPAGE]: new RegExp(`^${localePrefix}/$`),
  [GTMPageType.SEARCH]: new RegExp(
    `^${localePrefix}/(?:search|category/|hot-species/)`
  ),
  [GTMPageType.ACCOUNT]: new RegExp(`^/(?:auth/|my-account/)`),
  [GTMPageType.CHECKOUT]: new RegExp(`^${localePrefix}/(?:shopping-basket/)`),
  //TODO: add more page types as analytics figure out the correct mapping from the path
}

export const getDynamicPageType = (path: string): GTMPageType | '' => {
  const res = Object.entries(pageTypeMap).find(([_, regex]) =>
    regex.test(path)
  ) as [GTMPageType, RegExp]
  return res ? res[0] : ''
}

export const getDynamicPageSubType = (asPath: string): PageSubtype | '' => {
  switch (true) {
    case asPath.includes('#datasheet'):
      return PageSubtype.DATA_SHEET
    case asPath.includes('#support'):
      return PageSubtype.SUPPORT
    case asPath.includes('#all'):
      return PageSubtype.OVERVIEW
    case asPath.includes('-basket'):
      return PageSubtype.BASKET
    //TODO: add more page subtypes as analytics figure out the correct mapping from the path
    default:
      return ''
  }
}

export function getItemSize(size?: { value: string; unit: string }): string {
  return `${size?.value} ${size?.unit}`
}

export function getDaysToDeliver(scheduledArrivalDate: Date): number {
  return differenceInDays(scheduledArrivalDate, new Date())
}
