import { imageOverlay } from './pdp/image-overlay'
import { secondaries } from './pdp/secondaries'
import { unpublished } from './pdp/unpublished'
import { datasheet } from './pdp/datasheet'
import { drawer } from './pdp/drawer'
import { commonTags } from './commonTags/commonTags'
import { pdp } from './pdp/pdp'
import { abReviews } from './pdp/abreviews'
import { homePage } from './homePage/homePage'
import { srp } from './srp/srp'

export const tags = {
  imageOverlay,
  secondaries,
  unpublished,
  datasheet,
  commonTags,
  pdp,
  drawer,
  abReviews,
  homePage,
  srp,
}
