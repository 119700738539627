import {
  ApolloClient,
  concat,
  HttpLink,
  InMemoryCache,
  from,
} from '@apollo/client'
import fetch from 'isomorphic-fetch'
import type { NormalizedCacheObject } from '@apollo/client'
import {
  basicErrorLink,
  basicHeaderLink,
  basicRetryLink,
} from '@abcam-web/shared/data-access/apollo'

const apolloClient = (
  uri: string,
  includeLink = true
): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: includeLink
      ? from([
          basicRetryLink,
          basicErrorLink,
          concat(basicHeaderLink, new HttpLink({ uri, fetch })),
        ])
      : undefined,
    uri,
  })
}

export { apolloClient }
