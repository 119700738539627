import getConfig from 'next/config'

const proxyGatewayUrl = getConfig()?.publicRuntimeConfig?.PROXY_GATEWAY_URL

export const getProxyGatewayHost = {
  local: proxyGatewayUrl || 'https://proxy-gateway-dev.abcam.com',
  test: 'https://proxy-gateway-dev.abcam.com',
  dev: 'https://proxy-gateway-dev.abcam.com',
  staging: 'https://proxy-gateway-staging.abcam.com',
  preprod: 'https://proxy-gateway-preprod.abcam.com',
  prod: 'https://proxy-gateway.abcam.com',
  author_prod: 'https://proxy-gateway.abcam.com',
}
