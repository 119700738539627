const cache: Record<string, unknown> = {}

function isCacheEnabled() {
  // We do not want a cache when running unit tests (we often use fixed IDs), it's better
  // to have it here than forcing each test to call `clearCache()` before each run.
  return process.env.JEST_WORKER_ID === undefined
}

export async function cachedValue<T>(
  id: string,
  factory: () => Promise<T>
): Promise<T> {
  if (!isCacheEnabled()) {
    return await factory()
  }

  let entry = cache[id]

  if (entry === undefined) {
    entry = await factory()
    cache[id] = entry
  }

  return entry as T
}
