import {
  ClickId,
  EventName,
  getCheckoutEngagementParameters,
  GTMPageType,
  LocationID,
  PageSubtype,
  useGtm,
} from '@abcam-web/shared/data-access/tracking'
import { useCountry } from '@abcam-web/shared/utilities/country'
import { useRouter } from 'next/router'

import { useBasketId } from './use-basket-id'

export function useCheckoutTracking({
  pageSubType,
}: {
  pageSubType: PageSubtype
}) {
  const gtm = useGtm()
  const router = useRouter()
  const { countryName } = useCountry()
  const [basketId] = useBasketId()

  const trackLinkClick = ({
    item_cta,
    click_id,
  }: {
    item_cta: string
    click_id: ClickId
  }) => {
    gtm.track({
      page_type: GTMPageType.CHECKOUT,
      page_subtype: pageSubType,
      location_id: LocationID.PAGE,
      click_id,
      event: EventName.LINK_CLICK,
      page_path: router?.asPath,
      item_cta,
      countryName,
      outbound: 'FALSE',
      button_language: 'en',
    })
  }

  const trackEngagement = ({
    click_id,
    item_cta = '',
  }: {
    click_id: ClickId
    item_cta?: string
  }) => {
    gtm.track(
      getCheckoutEngagementParameters({
        click_id,
        page_path: router?.asPath,
        item_cta,
        basket_id: basketId,
        page_subtype: pageSubType,
        country_name: countryName,
      })
    )
  }

  return { trackEngagement, trackLinkClick }
}
