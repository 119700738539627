import {
  BillingNotes,
  Cache,
  OrderRequestModel,
  OrderResponseModel,
  ShippingNotes,
} from '@abcam-web/shared/data-access/ecommerce-schema'
import { Paths } from '@abcam-web/shared/ecommerce/utilities'
import { cloneDeep, set } from 'lodash'
import { createContext, ReactNode, useContext, useReducer } from 'react'

export type ActionPath = Paths<{
  order: OrderResponseModel | OrderRequestModel
  cache: Cache
}>

export type Action = {
  path: ActionPath
  value:
    | OrderResponseModel
    | OrderResponseModel[keyof OrderResponseModel]
    | OrderResponseModel['meta']['incidentNumbersWithReasons']
    | Partial<OrderResponseModel['addresses']['shipping']>
    | Partial<OrderResponseModel['addresses']['billing']>
    | OrderResponseModel['addresses']['shipping']
    | OrderResponseModel['addresses']['billing']
    | OrderResponseModel['customers']['shipTo']
    | OrderResponseModel['customers']['billTo']
    | OrderResponseModel['billing']
    | OrderResponseModel['billing'][keyof OrderResponseModel['billing']]
    | OrderResponseModel['shipping']
    | OrderResponseModel['shipping'][keyof OrderResponseModel['shipping']]
    | OrderRequestModel['billing']
    | ShippingNotes[keyof ShippingNotes]
    | BillingNotes
    | BillingNotes[keyof BillingNotes]
    | OrderResponseModel['meta']['holds']
    | Cache[keyof Cache]
}

const orderInitialState = {
  order: {} as OrderResponseModel,
  cache: {} as Cache,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setContext: (_actions: Action[]) => {},
}

const orderReducer = (state: typeof orderInitialState, actions: Action[]) => {
  const newState = cloneDeep(state)

  ;(actions || []).forEach((action) => {
    set(newState, action.path, action.value)
  })
  return newState
}

const OrderContext = createContext(orderInitialState)

const useOrderContext = () => useContext(OrderContext)

function OrderProvider({
  initialState = orderInitialState,
  children,
}: {
  initialState?: typeof orderInitialState
  children: ReactNode[] | ReactNode
}) {
  const [state, dispatch] = useReducer(orderReducer, initialState)

  const { order, cache } = state

  const value = {
    order,
    cache,
    setContext: dispatch,
  }

  return <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
}

export {
  OrderContext,
  orderInitialState,
  OrderProvider,
  orderReducer,
  useOrderContext,
}
