import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { usePunchout } from '@abcam-web/auth-shared/aws-cognito/hooks/usePunchout'
import { EventName, useGtm } from '@abcam-web/shared/data-access/tracking'

/**
 * Track start of Punchout session in Browse web app.
 */
export const useTrackPunchoutSessionStart = () => {
  const { track } = useGtm()
  const router = useRouter()
  const { sessionId, punchoutId } = usePunchout()
  const { startOfPunchoutSession } = router.query

  useEffect(() => {
    if (!sessionId) {
      return
    }

    if (!startOfPunchoutSession) {
      return
    }

    const newQuery = { ...router.query }
    delete newQuery.startOfPunchoutSession

    router.push({
      pathname: router.pathname,
      query: newQuery,
    })

    track({
      event: EventName.PUNCHOUT_SESSION_STARTED_IN_BROWSE,
      integration_type: 'punchout',
      integration_id: punchoutId,
      integration_session_id: sessionId,
    })

    // only changes in sessionId or punchoutId should trigger this effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, punchoutId])
}
