import { POSTCODE_REGEXES } from '../data'
import { BaseAddressSchema } from './base'

export const BRAddressSchema = {
  ...BaseAddressSchema,

  postcode: {
    type: 'string',
    required: true,
    // combining all regexes in logical OR
    pattern: new RegExp(
      POSTCODE_REGEXES['BR'].map((exp) => `^${exp}$`).join('|'),
      'i'
    ),
  },

  state: {
    type: 'string',
    required: true,
    oneOf: [
      'AC',
      'AL',
      'AM',
      'AP',
      'BA',
      'CE',
      'DF',
      'ES',
      'GO',
      'MA',
      'MG',
      'MS',
      'MT',
      'PA',
      'PB',
      'PE',
      'PI',
      'PR',
      'RJ',
      'RN',
      'RO',
      'RR',
      'RS',
      'SC',
      'SE',
      'SP',
      'TO',
    ],
  },
} as const

export type BRAddressSchemaType = typeof BRAddressSchema

export default BRAddressSchema
